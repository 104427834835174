import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTableDataApi = (query) =>
  api.get(`/LooseItemTentParts?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemsCountsApi = (query) =>
  api.get(`/LooseItems/Counting?${queryToString(query)}`).then((res) => res.data);

export const getLocationsWithSublocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);

export const getStatusesApi = (query) =>
  api.get(`/LooseItemLocationStatuses?${queryToString(query)}`).then((res) => res.data);

export const getCategoriesApi = (query) =>
  api.get(`/LooseItemCategories/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemsApi = () => api.get('/LooseItems/Shorted').then((res) => res.data);

export const getLoostItemCustomFieldsApi = (query) =>
  api.get(`/LooseItemCustomFields?${queryToString(query)}`).then((res) => res.data);

export const updateLooseItemPartApi = (params) =>
  api.put(`/LooseItemTentPart`, params).then((res) => res.data);

export const getSingleLooseItemApi = (id) => api.get(`/LooseItem?id=${id}`).then((res) => res.data);

export const createItemApi = (params, query) =>
  api.post(`/LooseItemTentPart?${queryToString(query)}`, params).then((res) => res.data);

export const deleteLooseItemApi = (id) => api.delete(`/LooseItem?id=${id}`).then((res) => res.data);

export const updateLooseItemApi = (params) => api.put(`/LooseItem`, params).then((res) => res.data);

export const getFilterCriteriaApi = () =>
  api.get('LooseItems/FilterCriteria').then((res) => res.data);
