import React from 'react';
import styles from './ToggleBars.module.scss';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import clsx from 'clsx';

export default function ToggleBar({ buttons, selectedTab, onChange, hideTab, classes }) {
  const changeTab = (e, value) => value && onChange(value);

  const isTabAvailable = (tabValue) => {
    if (!hideTab) return true;

    return hideTab(tabValue);
  };

  return (
    <ToggleButtonGroup
      className={clsx(classes && classes, styles.bar)}
      value={selectedTab}
      exclusive
      onChange={changeTab}>
      {buttons.map(
        ({ id, value, label, isDisabled }) =>
          isTabAvailable(value) && (
            <ToggleButton
              key={id}
              className={clsx(styles.bar__button, isDisabled && styles.disabled)}
              classes={{ selected: styles.selected }}
              value={value}>
              {label}
            </ToggleButton>
          )
      )}
    </ToggleButtonGroup>
  );
}
