import React, { useEffect, useState } from 'react';
import styles from './Selector.module.scss';
import clsx from 'clsx';

import { RESET_FILTER_PARAMS } from 'constants/dialogPopupsData';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { useDispatch, useSelector } from 'react-redux';
import { selectFieldsData, selectPrefixesData } from '../selectors';
import { getFields, getPrefixes, setPrefilterData } from 'actions/overviewReportActions';

import Header from './components/Header';
import Footer from './components/Footer';
import Chips from './components/Chips';
import AddHeaderButton from './components/AddHeaderButton';
import Separator from 'components/Separator/Separator';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import DeleteSectionButton from './components/DeleteSectionButton';

function Selector({ prefilter, onGenerate }) {
  const dispatch = useDispatch();
  const prefixes = useSelector(selectPrefixesData());
  const allFields = useSelector(selectFieldsData());

  const methods = useForm({
    defaultValues: { headers: [{ header: '', prefixIds: [] }], fieldIds: [] },
    mode: 'onChange'
  });
  const { control, getValues, setError, reset, formState, setValue, clearErrors } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers'
  });

  const [fieldsOptions, setFieldsOptions] = useState([]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  useEffect(() => {
    dispatch(getPrefixes({ filters: { hasAssets: true } }));
    dispatch(getFields({ filters: { assetFieldTypeNames: ['Int'] } }));
  }, []);

  useEffect(() => {
    if (!!Object.values(prefilter).flat().length && !!prefixes?.length && !!allFields?.length) {
      reset({ ...prefilter });
      const headers = prefilter?.['headers'] || [];
      const idsOfPrefixes = headers.map(({ prefixIds }) => prefixIds).flat();
      updateFields(idsOfPrefixes);
    }
  }, [prefilter, prefixes, allFields]);

  const clearFields = () => {
    setFieldsOptions([]);
    setValue('fieldIds', []);
  };
  const updateFields = (idsOfPrefixes) => {
    const ids = [...new Set(idsOfPrefixes)];
    const arr = allFields.filter(({ prefixIds }) => ids.some((id) => prefixIds.includes(id)));
    setFieldsOptions(arr);
    setValue('fieldIds', getFilteredFields(arr.map(({ id }) => id)));
  };

  const refreshFields = () => {
    const headers = getValues('headers') || [];
    const idsOfPrefixes = headers.map(({ prefixIds }) => prefixIds).flat();

    if (!idsOfPrefixes.length) {
      clearFields();
    } else {
      updateFields(idsOfPrefixes);
    }
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const handleReset = () => setDialogModalData(RESET_FILTER_PARAMS);
  const closeModal = () => setDialogModalData({ isOpened: false });
  const handleGenerate = () => validateForm() && onGenerate(getValues());
  const handleAddClick = () => append({ header: '', prefixIds: [] });
  const getFilteredFields = (items) => getValues('fieldIds').filter((id) => items.includes(id));

  const handleSectionDeleteClick = (i) => {
    ['header', 'prefixIds'].forEach((name) => clearErrors(`headers[${i}].${name}`));
    remove(i);
    refreshFields();
  };

  const onAgree = () => {
    reset({ headers: [{ header: '', prefixIds: [] }], fieldIds: [] });
    dispatch(setPrefilterData({ headers: [], fieldIds: [] }));
    closeModal();
  };

  const headerProperties = [
    { name: 'header', inputType: 'text', errorMessage: 'Header Name field is required' },
    { name: 'prefixIds', inputType: 'array', errorMessage: 'Prefixes field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    headerProperties.forEach(({ name, inputType, errorMessage }) => {
      getValues('headers').forEach((row, index) => {
        if (isParameterInvalid(row[name], inputType)) {
          setError(`headers[${index}].${name}`, getErrorsProperties(errorMessage));
          isFormValid = false;
        }
      });
    });
    if (!getValues('fieldIds').length) {
      if (isParameterInvalid(getValues('fieldIds'), 'array')) {
        setError('fieldIds', getErrorsProperties('Asset Fields field is required'));
        isFormValid = false;
      }
    }
    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      <Header />
      <FormProvider {...methods}>
        <section className={styles.headersContainer}>
          {fields.map((item, index) => (
            <div key={item.id} className={styles.headersBlock}>
              <div className={styles.headersBlock__header}>
                <section className={styles.form}>
                  <label>Header Name*</label>
                  <FormInputText
                    name={`headers[${index}].header`}
                    options={{ max: 250, rule: allKeyboardLatSymbols }}
                    classes={clsx(styles.special)}
                  />
                </section>
                {fields.length > 1 && (
                  <DeleteSectionButton onClick={() => handleSectionDeleteClick(index)} />
                )}
              </div>
              <section className={styles.form}>
                <label>Prefixes*</label>
                <FormAutoMultiSelect
                  name={`headers[${index}].prefixIds`}
                  menuItems={prefixes}
                  options={{
                    label: 'prefix',
                    selectAll: false,
                    disableByObjectTracker: true,
                    extraAction: refreshFields
                  }}
                  classes={clsx(styles.special)}
                />
              </section>
              <Chips
                name={`headers[${index}].prefixIds`}
                items={prefixes}
                options={{ label: 'prefix', extraAction: refreshFields }}
              />
              {index === fields.length - 1 && <AddHeaderButton onClick={handleAddClick} />}
            </div>
          ))}
        </section>
        <Separator classes={styles.separator} />
        <div className={styles.fieldsBlock}>
          <section className={styles.form}>
            <label>Asset Fields*</label>
            <FormAutoMultiSelect
              name="fieldIds"
              menuItems={fieldsOptions}
              options={{
                label: 'displayName',
                selectAll: false,
                disableByObjectTracker: true,
                isDisabled: !fieldsOptions?.length
              }}
              classes={clsx(styles.special)}
            />
          </section>
          <Chips name="fieldIds" items={allFields} options={{ label: 'displayName' }} />
        </div>
      </FormProvider>
      <Footer onReset={handleReset} onGenerate={handleGenerate} isDisabled={!!isFormInvalid()} />
    </section>
  );
}

export default Selector;
