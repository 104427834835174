import React, { useEffect } from 'react';

import SearchWrapper from './components/Wrapper';
import SearchContent from './components/Content';

import { useSearchActions } from 'hooks/Search';

import { getQuery } from './helpers';

import { useLocation } from 'react-router-dom';

export default function Search() {
  const { searchMatchesAction, setDataLoadedFlagAction, setSearchQueryAction, clearStateAction } =
    useSearchActions();

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('searchQuery');

    if (searchQuery !== null) {
      setDataLoadedFlagAction(false);
      setSearchQueryAction(searchQuery);
      searchMatchesAction(getQuery(searchQuery));
    }
  }, [location.search]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <SearchWrapper>
      <SearchContent />
    </SearchWrapper>
  );
}
