import React from 'react';
import styles from './SimplePieChart.module.scss';
import ReactApexChart from 'react-apexcharts';
import { getOptions } from './PieOptions';

export default function SimplePieChart({ title, series, labels, colors, fileName, hideToolbar }) {
  const options = getOptions(labels, title, colors, fileName, hideToolbar);

  return (
    <div className={styles.pie}>
      <ReactApexChart width="100%" height="100%" type="pie" options={options} series={series} />
    </div>
  );
}
