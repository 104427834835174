import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Marker, Tooltip, useMapEvents } from 'react-leaflet';
import blueMarkerIcon from 'assets/images/markers/bluemarker.svg';
import styles from './MapComponent.module.scss';

const pickerMarker = L.icon({
  iconUrl: blueMarkerIcon,
  iconSize: [25, 31],
  iconAnchor: [12.5, 25],
  popupAnchor: [0, 0]
});

export default function LocationMarker({ inputData, address, onPick }) {
  const [position, setPosition] = useState(null);

  const getAddressFromCoords = (inputData) => {
    const provider = new OpenStreetMapProvider({
      params: {
        'accept-language': 'en',
        addressdetails: 1
      }
    });
    return provider.search({ query: `${inputData.lat}, ${inputData.lng}` });
  };

  useEffect(() => {
    if (inputData) {
      getAddressFromCoords(inputData).then((res) =>
        setPosition({ ...inputData, address: res[0]?.raw?.address })
      );
    }
  }, [inputData]);

  const map = useMapEvents({
    click(e) {
      setPositionAndZoom(e.latlng);
    }
  });

  const setPositionAndZoom = (latlng) => {
    map.flyTo(latlng, map.getZoom());
    getAddressFromCoords(latlng).then((res) => {
      setPosition({ ...latlng, address: res[0]?.raw?.address });
      onPick({ ...latlng, address: res[0]?.raw?.address });
    });
  };

  if (address && !position && !inputData) {
    const provider = new OpenStreetMapProvider();
    provider
      .search({
        query: address
      })
      .then((res) => {
        if (res.length) {
          const latlng = { lat: res[0]?.y || null, lng: res[0]?.x || null };
          setPositionAndZoom(latlng);
        } else return;
      });
  }

  return position === null ? null : (
    <Marker position={position} icon={pickerMarker} opacity={1}>
      <Tooltip className={styles.popupStyle} permanent classes={{ root: styles.popupStyle }}>
        <p className={`${styles.popup__content} ${styles.info__tooltip}`}>
          <b>Latitude:</b>
          <br />
          {position.lat}
        </p>
        <p className={`${styles.popup__content} ${styles.info__tooltip}`}>
          <b>Longitude:</b>
          <br />
          {position.lng}
        </p>
      </Tooltip>
    </Marker>
  );
}
