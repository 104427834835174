import {
  SET_UPLOAD_BOL_LOGS_CONFIGURATION,
  CLEAR_UPLOAD_BOL_LOGS_STATE
} from 'constants/reduceConstants';

const initialState = {
  configuration: {}
};

const uploadBoLLogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_UPLOAD_BOL_LOGS_CONFIGURATION:
      return { ...state, configuration: payload };
    case CLEAR_UPLOAD_BOL_LOGS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default uploadBoLLogsReducer;
