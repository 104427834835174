import api from '../api';
import {
  CLEAR_BATCH_ITEMS_UPDATES_STATE,
  SAVE_BATCH_ITEMS_UPDATES_FORM_STATE,
  SET_BATCH_ITEMS_UPDATES_CATEGORIES,
  SET_BATCH_ITEMS_UPDATES_FIELDS,
  SET_BATCH_ITEMS_UPDATES_LOOSE_ITEMS
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const getCategories = () => (dispatch) => {
  return api.batchItemUpdates
    .getCategoriesApi()
    .then((res) => {
      dispatch({ type: SET_BATCH_ITEMS_UPDATES_CATEGORIES, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getCustomFields = () => (dispatch) => {
  return api.batchItemUpdates
    .getCustomFieldsApi()
    .then((res) => {
      dispatch({ type: SET_BATCH_ITEMS_UPDATES_FIELDS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLooseItems = () => (dispatch) => {
  return api.batchItemUpdates
    .getLooseItemsApi()
    .then((res) => {
      dispatch({ type: SET_BATCH_ITEMS_UPDATES_LOOSE_ITEMS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const updateLooseItems = (data) => () => {
  return api.batchItemUpdates
    .updateLooseItemsApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });
};

export const saveFormState = (payload) => (dispatch) => {
  dispatch({ type: SAVE_BATCH_ITEMS_UPDATES_FORM_STATE, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_BATCH_ITEMS_UPDATES_STATE });
};
