import React from 'react';
import styles from './controls.module.scss';

import { Button } from '@material-ui/core';

import ProjectScheduleFilter from './Filter/ProjectScheduleFilter';
import ScheduleActions from '../ScheduleActions/ScheduleActions';

import { getCollectedState } from '../../helpers';

import { useUserConfig } from 'hooks/useUserConfig';
import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';

export default function TableControls() {
  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const isMoreButtonAvailable = isAdminUser || isPersonnelManagerUser;

  const { filter, isMasterSelected, trackerTableData } = useTrackerSelector();
  const { getTrackerDataAction, setTrackerTableDataAction } = useTrackerActions();

  const loadMore = () => {
    const filterWithNextPage = {
      ...filter,
      filters: { ...filter.filters, columnPage: filter.filters.columnPage + 1 }
    };

    getTrackerDataAction(filterWithNextPage, { isNextPage: true, isMasterSelected }).then((res) => {
      setTrackerTableDataAction(getCollectedState(isMasterSelected, res, trackerTableData));
    });
  };

  const isLoadMoreDisabled = () => filter.filters?.endDate !== null;

  return (
    <div className={styles.controls}>
      <ProjectScheduleFilter />

      <div className={styles.controls__extra}>
        <Button
          className={styles.controls__extra_load}
          onClick={loadMore}
          classes={{ disabled: styles.disabled }}
          disabled={isLoadMoreDisabled()}>
          Load more data
        </Button>
        {isMoreButtonAvailable && <ScheduleActions />}
      </div>
    </div>
  );
}
