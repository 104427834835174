import React from 'react';
import styles from './InfoText.module.scss';

export default function InfoText({ text }) {
  return (
    <div className={styles.info}>
      <span>{text}</span>
    </div>
  );
}
