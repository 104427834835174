const colors = {
  lineColor1: '#7EC37E',
  lineColor2: '#A8D5FF',
  strokeColor: '#fff',
  dataLabelColor: '#000',
  gridLineColor: '#DCDCDC'
};
export const getHorizontalBarOptions = (fileName, csvColumnName, externalColors) => {
  return {
    chart: {
      type: 'bar',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      toolbar: {
        export: {
          csv: {
            filename: fileName,
            headerCategory: csvColumnName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    colors: externalColors || [colors.lineColor1, colors.lineColor2],
    dataLabels: {
      enabled: true,
      textAnchor: 'end',
      style: {
        fontSize: '10px',
        colors: [colors.dataLabelColor]
      },
      formatter: (val) => val || ''
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '14px',
      fontWeight: 400
    },
    stroke: { show: true, width: 1, colors: [colors.strokeColor] },
    tooltip: { shared: true, intersect: false },
    grid: {
      show: true,
      borderColor: colors.gridLineColor,
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        }
      }
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          opacity: 0.4,
          fontWeight: 400
        }
      }
    },
    responsive: [
      {
        breakpoint: 1181,
        options: {
          yaxis: {
            labels: {
              maxWidth: 100
            }
          }
        }
      }
    ]
  };
};
