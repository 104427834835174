import React, { useEffect, useState } from 'react';
import styles from './RequestEntities.module.scss';

import clsx from 'clsx';

import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';

import { useRequestOrderSelector } from 'hooks/RequestOrder';

import EntityChip from './EntityChip';

export default function RequestLooseItemEntities({ index }) {
  const { creationFormData } = useRequestOrderSelector();
  const { looseItemCategories, looseItems } = creationFormData;

  const [looseItemOptions, setLooseItemOptions] = useState([]);

  useEffect(() => {
    setLooseItemOptions(looseItems || []);
  }, [looseItems]);

  const handleCategorySelect = (_, value) => {
    if (value?.length) {
      const filteredItems = looseItems.filter(
        ({ looseItemCategories }) =>
          !!looseItemCategories.filter(({ id }) => value.includes(id)).length
      );
      setLooseItemOptions(filteredItems);
    } else {
      setLooseItemOptions(looseItems);
    }
  };

  return (
    <>
      <div className={styles.entities__block_row}>
        <label>Narrow by Category</label>
        <FormAutoMultiSelect
          name={`requestEntityData[${index}].narrowCategoryIds`}
          menuItems={looseItemCategories || []}
          options={{
            label: 'name',
            disableByObjectTracker: true,
            disableLabel: true,
            extraAction: handleCategorySelect
          }}
        />
      </div>
      <div className={clsx(styles.entities__block_row, styles.wider)}>
        <label>Loose Item name*</label>
        <FormAutoMultiSelect
          name={`requestEntityData[${index}].requestOrderLooseItems`}
          menuItems={looseItemOptions}
          options={{
            label: 'name',
            selectAll: false,
            disableByObjectTracker: false,
            hideTags: true
          }}
          classes={clsx(styles.special)}
        />
      </div>
      <FormChipsContainer
        fieldName={`requestEntityData[${index}].requestOrderLooseItems`}
        ChipComponent={(chipId, chip, chipIndex) => (
          <EntityChip
            key={chipId}
            Chip={chip}
            quantityName={`requestEntityData[${index}].requestOrderLooseItems[${chipIndex}.quantity]`}
            resourcesName={`requestEntityData[${index}].requestOrderLooseItems[${chipIndex}.resources]`}
          />
        )}
        direction="column"
      />
    </>
  );
}
