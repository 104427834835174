import React from 'react';
import styles from './GroupedHorizontalBar.module.scss';
import ReactApexChart from 'react-apexcharts';
import { getHorizontalBarOptions } from './BarOptions';

export default function GroupedHorizontalBar({
  series,
  categories,
  fileName,
  csvColumnName,
  colors
}) {
  const options = {
    ...getHorizontalBarOptions(fileName, csvColumnName, colors),
    labels: categories
  };

  return (
    <div className={styles.bar}>
      <ReactApexChart height="100%" width="100%" type="bar" options={options} series={series} />
    </div>
  );
}
