import { useDispatch } from 'react-redux';

import {
  getDashboardConfig,
  updateTabConfig,
  getFilterCriteria,
  setTab,
  updateSectionConfig,
  getVerificationStatistic,
  getInspectionStatistic,
  clearState,
  clearTabState,
  getAssetOptions,
  getAssetsDashboardsCsvResource
} from 'actions/assetsDashboardsActions';

export function useAssetsDashboardsActions() {
  const dispatch = useDispatch();

  const getDashboardConfigAction = (q) => dispatch(getDashboardConfig(q));
  const updateTabConfigAction = (data) => dispatch(updateTabConfig(data));

  const updateSectionConfigAction = (data) => dispatch(updateSectionConfig(data));

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());
  const getAssetOptionsAction = (q) => dispatch(getAssetOptions(q));

  const getVerificationStatisticAction = () => dispatch(getVerificationStatistic());
  const getInspectionStatisticAction = () => dispatch(getInspectionStatistic());

  const setTabAction = (tab) => dispatch(setTab(tab));

  const getCsvResourceAction = (q) => dispatch(getAssetsDashboardsCsvResource(q));

  const clearTabStateAction = (data) => dispatch(clearTabState(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    getDashboardConfigAction,
    updateTabConfigAction,
    updateSectionConfigAction,
    getFilterCriteriaAction,
    getAssetOptionsAction,
    getVerificationStatisticAction,
    getInspectionStatisticAction,
    setTabAction,
    getCsvResourceAction,
    clearTabStateAction,
    clearStateAction
  };
}
