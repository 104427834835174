import { useDispatch } from 'react-redux';

import {
  clearProjectListState,
  deleteProject,
  getProjectLocationList,
  getSingleProject,
  updateProjectLocations
} from 'actions/projectActions';

import { setActiveProject } from 'actions/locationsActions';

export function useProjectActions() {
  const dispatch = useDispatch();

  const getProjectAction = (id) => dispatch(getSingleProject(id));
  const deleteProjectAction = (id) => dispatch(deleteProject(id));
  const clearStateAction = () => dispatch(clearProjectListState());
  const getProjectLocationAction = (filter) => dispatch(getProjectLocationList(filter));
  const updateProjectLocationsAction = (data) => dispatch(updateProjectLocations(data));
  const setActiveProjectAction = (project) => dispatch(setActiveProject(project));

  return {
    getProjectAction,
    deleteProjectAction,
    setActiveProjectAction,
    getProjectLocationAction,
    updateProjectLocationsAction,
    clearStateAction
  };
}
