import React, { useRef, useState } from 'react';
import styles from './Content.module.scss';

import { StickyTableCell } from 'components/StyledComponents';
import PopperWrapper from './PopperWrapper';
import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function TotalFooterCell() {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const hide = () => setOpen(false);

  const iconClick = () => setOpen(!open);

  return (
    <StickyTableCell className={styles.totalTitle} colSpan={2}>
      <PopperWrapper open={open} setOpen={setOpen} iconRef={iconRef}>
        <div className={styles.content}>
          <div className={styles.content__row}>
            <b>Total Available</b> is <b>Fields values sum</b> of Available Units
          </div>
        </div>
      </PopperWrapper>

      <div className={styles.wrapper} onBlur={hide} tabIndex="0">
        TOTAL AVAILABLE
        <IconButton ref={iconRef} onClick={iconClick}>
          <InfoOutlinedIcon />
        </IconButton>
      </div>
    </StickyTableCell>
  );
}
