import React from 'react';
import styles from './AssetDetails.module.scss';

import AssetFiles from './Files/AssetFiles';

import AssetGeneralDetails from './General/AssetGeneralDetails';
import AssetPrefixDetails from './Prefix/AssetPrefixDetails';
import LicenseDetails from './License/LicenseDetails';
import AssetContents from './Contents/AssetContents';
import AssetNotes from './Notes/AssetNotes';

export default function AssetDetails() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <AssetGeneralDetails />
        <AssetPrefixDetails />
        <LicenseDetails />
        <AssetContents />
        <AssetNotes />
      </div>
      <div className={styles.files}>
        <AssetFiles />
      </div>
    </div>
  );
}
