export const userListColumns = [
  {
    id: 1,
    label: 'Name',
    isSortable: true,
    parameter: 'lastName'
  },
  {
    id: 2,
    label: 'Email',
    isSortable: true,
    parameter: 'email'
  },
  {
    id: 3,
    label: 'Role',
    isSortable: true,
    parameter: 'userRole.role'
  },
  {
    id: 4,
    label: 'Block/unblock',
    isSortable: false,
    parameter: null
  },
  {
    id: 5,
    label: 'New',
    isSortable: true,
    parameter: 'isNew'
  }
];
