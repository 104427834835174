import React from 'react';
import styles from './Map.module.scss';
import PropTypes from 'prop-types';

import clsx from 'clsx';

/**
 * @typedef {Object} MapLegendProps
 * @property {[]} markers
 */

/**
 * @param {MapLegendProps} props
 */

export const MapLegend = ({ className, markers }) => {
  return (
    <div className={clsx(className && className, styles.legend)}>
      {markers.map(({ label, icon }, index) => (
        <div key={index} className={styles.legend__item}>
          <div className={styles.legend__item_icon}>
            <img src={icon} alt="legend-marker" />
          </div>
          <div className={styles.legend__item_label}>{label}</div>
        </div>
      ))}
    </div>
  );
};

MapLegend.propTypes = {
  className: PropTypes.string,
  markers: PropTypes.array.isRequired
};
