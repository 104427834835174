import React from 'react';

export const generateErrorMessage = ({ errors }) => {
  const errorsArray = Object.values(errors || {});

  if (!errorsArray?.length) {
    return <span>{errors?.title || 'An error occured'}</span>;
  }

  return (
    <>
      {errorsArray.map((el, index) => (
        <>
          <span key={index}>{el || ''}</span>
          {index !== errorsArray.length - 1 && <br />}
        </>
      ))}
    </>
  );
};
