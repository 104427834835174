import React from 'react';
import styles from './BodyCells.module.scss';

import { StyledTableCell } from 'components/StyledComponents';
import clsx from 'clsx';

export default function CapacityCell({ capacity, isFirstRow }) {
  return (
    <StyledTableCell className={clsx(styles.capacityCell, isFirstRow && styles.biggerTopBorder)}>
      {capacity?.name || ''}
    </StyledTableCell>
  );
}
