import { useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest, graphConfig } from 'msConfig';
import axios from 'axios';
import { useCalendarActions } from './useCalendarActions';

export const useMicrosoftCalendarApi = (teamMembers) => {
  const token = useRef(null);
  const callMicrosoftGraph = () =>
    axios.create({
      baseURL: graphConfig.graphMeEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token.current}`
      }
    });

  const { instance, accounts } = useMsal();
  const { getCalendars } = useCalendarActions(callMicrosoftGraph, teamMembers);
  const request = { ...loginRequest, account: accounts[0] };
  const handleSuccessResponse = (response) => (token.current = response.accessToken);

  const handleFailureResponse = (err) => console.log(err);

  const shareEventsWithMicrosoft = () => {
    initializeToken().then(() => getCalendars());
  };

  const initializeToken = () =>
    instance.acquireTokenPopup(request).then(handleSuccessResponse).catch(handleFailureResponse);

  return { shareEventsWithMicrosoft };
};
