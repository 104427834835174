import React from 'react';
import styles from './SectionHeader.module.scss';

export default function SectionHeader({ title }) {
  return (
    <div className={styles.header}>
      <h2 className={styles.header__title}>{title}</h2>
    </div>
  );
}
