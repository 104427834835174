import React, { createRef } from 'react';
import styles from './SignaturePopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import SignaturePanel from 'components/Signature/Panel/SignaturePanel';
import { MainButton } from 'components/StyledComponents';

export default function SignaturePopup({ open, setOpen, onApply }) {
  const signatureRef = createRef();

  const closePopup = () => setOpen(false);
  const handleApplyClick = () => {
    const str = signatureRef.current.isEmpty()
      ? null
      : signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    onApply(str);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <section className={styles.dialog}>
        <DialogHeader onClose={closePopup} title="Put Your Signature"></DialogHeader>
        <div className={styles.signature}>
          <SignaturePanel signatureRef={signatureRef} />
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton text="Apply" action={handleApplyClick} type="primary" />
        </div>
      </section>
    </DialogWrapper>
  );
}
