import React, { useRef } from 'react';
import styles from './Tables.module.scss';

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';

import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';
import { getChangedByPageFilter, getChangedBySortFilter } from 'helpers/SummaryPagesHelpers';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';

import { ASSET_INSPECTION_HISTORY_TABLE_PARAMETER } from 'constants/configTableConstants';

import clsx from 'clsx';

const tableHeadConfig = [
  { id: 1, label: 'Action', isSortable: true, parameter: 'status' },
  { id: 2, label: 'Date', isSortable: true, parameter: 'reportDate' },
  { id: 3, label: 'Location(Sublocation)', isSortable: true, parameter: 'location' },
  { id: 4, label: 'Reporter', isSortable: true, parameter: 'reportedByUser.firstName' },
  { id: 5, label: 'Triggered By', isSortable: true, parameter: 'trigger' }
];

export default function AssetInspectionHistory() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const { getTableLimit } = useUserConfig();

  const { inspectionHistory, inspectionHistoryFilter } = useAssetSelector();
  const { getInspectionHistory } = useAssetActions();

  const emptyResponse = () =>
    !inspectionHistory?.items?.length &&
    inspectionHistoryFilter?.pagination?.page === inspectionHistory?.pageNumber;

  const changePage = (page, param) =>
    getInspectionHistory(getChangedByPageFilter(inspectionHistoryFilter, page), param);
  const sortColumn = (rowName) =>
    getInspectionHistory(getChangedBySortFilter(inspectionHistoryFilter, rowName));

  const getUpdatedByLimitFilter = () => ({
    ...inspectionHistoryFilter,
    pagination: {
      ...inspectionHistoryFilter.pagination,
      page: 1,
      limit: getTableLimit(ASSET_INSPECTION_HISTORY_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  return (
    <>
      <TableContainer className={clsx(styles.table, styles.inspection)} component={Paper}>
        <Table>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={inspectionHistoryFilter.sortRules}
            onSortApply={sortColumn}
          />
          <TableBody>
            {inspectionHistory?.items?.length ? (
              inspectionHistory.items.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.status?.displayName || ''}</StyledTableCell>
                  <StyledTableCell>
                    {row['reportDate'] ? formatDate(row['reportDate'], dateTimeFormat) : ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.location?.siteCode}
                    {row?.sublocation?.name && ' (' + row.sublocation.name + ')'}
                  </StyledTableCell>
                  <StyledTableCell>{getUserFullName(row['reportedByUser'])}</StyledTableCell>
                  <StyledTableCell>{row?.trigger?.displayName || ''}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={10} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={inspectionHistory?.items?.length}
          currentPage={inspectionHistoryFilter?.pagination?.page}
          totalPages={inspectionHistoryFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={inspectionHistoryFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={ASSET_INSPECTION_HISTORY_TABLE_PARAMETER}
          getTableData={() => getInspectionHistory(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
