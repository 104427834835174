import {
  SET_ACTIVE_LOCATIONS_MAP_FILTER,
  SET_ACTIVE_LOCATIONS_MAP_FILTER_CRITERIA,
  CLEAR_ACTIVE_LOCATIONS_MAP_STATE
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  filterCriteria: {}
};

const activeLocationsMapReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_LOCATIONS_MAP_FILTER:
      return { ...state, filter: payload };
    case SET_ACTIVE_LOCATIONS_MAP_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case CLEAR_ACTIVE_LOCATIONS_MAP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default activeLocationsMapReducer;
