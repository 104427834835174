import React from 'react';
import styles from './FilterComponents.module.scss';
import BackButton from '../BackButton/BackButton';

export default function FilterHeader({ onBack, title }) {
  const handleFilterClose = () => onBack();

  return (
    <section className={styles.header}>
      <h1>{title || 'Filters'}</h1>
      <BackButton onCancel={handleFilterClose} />
    </section>
  );
}
