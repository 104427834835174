import { useSelector } from 'react-redux';

import {
  selectBaseStatisticsData,
  selectTabConfigData,
  selectTabData,
  selectFilterCriteriaData,
  selectTurnoverStatisticsData,
  selectFlowStatisticsData
} from 'pages/AssetTickets/Dashboard/selectors';

export function useTicketsDashboardSelector() {
  const tab = useSelector(selectTabData());
  const tabConfig = useSelector(selectTabConfigData());

  const filterCriteria = useSelector(selectFilterCriteriaData());

  const baseStatistics = useSelector(selectBaseStatisticsData());

  const turnoverStatistics = useSelector(selectTurnoverStatisticsData());

  const flowStatistics = useSelector(selectFlowStatisticsData());

  return {
    tab,
    tabConfig,
    filterCriteria,
    baseStatistics,
    turnoverStatistics,
    flowStatistics
  };
}
