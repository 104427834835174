import React from 'react';
import styles from './CategoryOption.module.scss';

import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { useUserConfig } from 'hooks/useUserConfig';

export default function CategoryOption({ option, selected, onEdit }) {
  const { isAdminUser, isOperationsManagerUser } = useUserConfig();

  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit(option);
  };

  return (
    <div className={styles.option}>
      <CustomCheckbox value={selected} />
      <div className={styles.option__controls}>
        <span>{option?.name || ''}</span>
        {(isAdminUser || isOperationsManagerUser) && (
          <IconButton className={styles.option__controls_edit} onClick={handleEditClick}>
            <CreateIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
