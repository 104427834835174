import React from 'react';
import { StyledTableCell, StyledTableRow } from '../../StyledComponents';
import styles from './EmptyTableView.module.scss';

export default function EmptyTableView({ responseReceived, emptyFilters }) {
  return (
    <StyledTableRow>
      {responseReceived && (
        <StyledTableCell colSpan={10} className={styles.emptyResponseTable}>
          {emptyFilters() ? 'No available data in the table.' : 'No matches in the table.'}
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}
