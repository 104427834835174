import React from 'react';
import styles from './BackButton.module.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';

export default function BackButton({ onCancel }) {
  return (
    <IconButton className={styles.back} onClick={onCancel}>
      <ChevronLeftIcon />
    </IconButton>
  );
}
