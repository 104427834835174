import {
  CLEAR_REPORT_TEMPLATES_STATE,
  SET_REPORT_TEMPLATES_FILTER,
  SET_REPORT_TEMPLATES_TYPES
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  reportTypes: []
};

const reportTemplatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REPORT_TEMPLATES_FILTER:
      return { ...state, filter: payload };
    case SET_REPORT_TEMPLATES_TYPES:
      return { ...state, reportTypes: payload };
    case CLEAR_REPORT_TEMPLATES_STATE:
      return { ...initialState, filter: state.filter };
    default:
      return state;
  }
};

export default reportTemplatesReducer;
