import React from 'react';
import styles from './components.module.scss';
import BackButton from 'components/BackButton/BackButton';

function DetailsHeaderWrapper({ title, onBack, isMobile, children }) {
  const handleBackClick = () => onBack();

  return (
    <section className={styles.header}>
      {!isMobile && <h1>{title}</h1>}
      <div className={styles.header__controls}>
        {children}
        {isMobile && <BackButton onCancel={handleBackClick} />}
      </div>
    </section>
  );
}

export default DetailsHeaderWrapper;
