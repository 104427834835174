import React from 'react';
import styles from './PaginationButtons.module.scss';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

export default function Default({ page, isSelected, isLast, onClick }) {
  return (
    <>
      <IconButton
        className={clsx(styles.page_button, isSelected && styles.active)}
        onClick={onClick}>
        {page}
      </IconButton>
      {!isLast && <div className={styles.page_divider}></div>}
    </>
  );
}
