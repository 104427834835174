import React, { useState } from 'react';
import styles from './EditorForm.module.scss';
import StatusMarker from './StatusMarker/StatusMarker';
import { IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import { allKeyboardLatSymbols, reformatInputValue } from 'helpers/AppHelpers';
import { useDispatch } from 'react-redux';
import {
  deleteEmploymentStatus,
  getEmploymentStatuses,
  updateEmploymentStatus
} from 'actions/employmentStatusEditorActions';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { DELETE_EMPLOYMENT_STATUS } from 'constants/dialogPopupsData';
import ColorPicker from './ColorPicker';

function EditorFormRow({ status, isStatusNameExist }) {
  const dispatch = useDispatch();

  const [mode, setMode] = useState('display');
  const [values, setValues] = useState({ ...status });
  const [error, setError] = useState('');
  const [modalData, setModalData] = useState({ isOpened: false });

  const prepareStates = (mode) => {
    setMode(mode);
    error && setError('');
    setValues({ ...status });
  };

  const handlePencilClick = () => prepareStates('edit');
  const handleCancelClick = () => setMode('display');
  const handleBasketClick = () => setModalData({ isOpened: true, ...DELETE_EMPLOYMENT_STATUS });

  const isDisplayMode = () => mode === 'display';

  const onAgree = () =>
    dispatch(deleteEmploymentStatus(values.id)).then((res) => {
      if (res === 200) {
        dispatch(getEmploymentStatuses());
      }
    });
  const onDismiss = () => setModalData({ isOpened: false });

  const onColorChange = (color) => setValues({ ...values, color: color.hex });

  const handleCheckClick = () => {
    if (!validateForm()) return;
    dispatch(updateEmploymentStatus(values)).then((res) => {
      if (res === 200) {
        setMode('display');
        dispatch(getEmploymentStatuses());
      }
    });
  };

  const onInput = (event) => {
    if (error) validateForm('name');
    const value = reformatInputValue(event.target.value, 60, allKeyboardLatSymbols);
    setValues({ ...values, name: value });
  };
  const validateForm = (key) => {
    if (key) {
      setError('');
      return;
    }
    let isValid = true;
    if (!values?.name) {
      setError('This field is required');
      isValid = false;
    }
    if (isStatusNameExist(values.name, values.id)) {
      setError('Status with such name already exists');
      isValid = false;
    }
    return isValid;
  };

  return (
    <div className={styles.status}>
      <DialogPopup data={modalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {isDisplayMode() ? (
        <>
          <div className={clsx(styles.status_field, isDisplayMode() && styles.hidden)}>
            {status.name}
          </div>
          <div className={styles.status_controls}>
            <StatusMarker color={status.color} />
            <IconButton className={styles.status_controls__edit} onClick={handlePencilClick}>
              <CreateIcon />
            </IconButton>
            <IconButton className={styles.status_controls__remove} onClick={handleBasketClick}>
              <DeleteIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <div className={styles.status_field}>
            <TextField
              name="name"
              className={clsx(styles.status_field__input, error && styles.error)}
              variant="outlined"
              value={values.name || ''}
              onChange={onInput}
            />
            {!!error && <span>{error}</span>}
          </div>
          <div className={styles.status_controls}>
            <ColorPicker color={values.color} onChange={onColorChange} />
            <IconButton className={styles.status_controls__check} onClick={handleCheckClick}>
              <CheckCircleIcon />
            </IconButton>
            <IconButton className={styles.status_controls__cancel} onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}

export default EditorFormRow;
