import React, { forwardRef, useCallback } from 'react';
import styles from './Templates.module.scss';

import { SnackbarContent, useSnackbar } from 'notistack';

import { Card } from '@material-ui/core';

import clsx from 'clsx';

import { generateErrorMessage } from './helpers';

export const ErrorTemplate = forwardRef(function ErrorTemplate({ id, message, ...props }, ref) {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Card className={clsx(styles.card, styles.error)} onClick={handleDismiss}>
        <div className={styles.snackbar}>
          {message ? <span>{message}</span> : generateErrorMessage(props)}
        </div>
      </Card>
    </SnackbarContent>
  );
});
