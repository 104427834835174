import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_FILE_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styles from './UploadFileButton.module.scss';

const FILES_LIMIT = 1;
const MAX_FILE_SIZE = 10485760;
const FILE_MATCH =
  /(pdf|csv|png|jpg|jpeg|raw|tiff|bmp|gif|msword|text|excel|powerpoint|openxmlformats|xml)/;
const FILE_TITLE =
  'PDF, CSV, PNG, JPEG, RAW, TIFF, BMP, GIF, DOC, DOCX, TXT, XLS, XLSX , XML, PPTX, PPT';

const FILE_MIME_TYPES = `image/png,image/jpg,image/jpeg,image/jpg,image/raw,image/tiff,image/bmp,image/gif,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,
    text/csv,application/pdf,text/plain,application/excel,application/powerpoint,application/xhtml+xml,.csv`;

const Input = <AddCircleOutlineIcon />;

const Layout = ({ input, dropzoneProps }) => {
  return (
    <div className={styles.dzu_layoutWrapper}>
      <div {...dropzoneProps}>{input}</div>
    </div>
  );
};

export default function UploadFileButton({ onUploadFile, picklistAsset }) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const getUploadParams = () => {
    if (getToken()) {
      return {
        url: UPLOAD_FILE_URL,
        headers: {
          authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
        }
      };
    }
  };

  const handleChangeStatus = (fileWithMeta, status, files) => {
    const { meta } = fileWithMeta;

    if (!meta.type.match(FILE_MATCH)) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed:
                ${FILE_TITLE}`,
        isOpened: true
      });
    } else if (meta.size > MAX_FILE_SIZE) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" exceeds maximum allowed upload size of 10MB`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (status === 'done') {
      const filesArray = files.map((file) => JSON.parse(file.xhr?.response || null));
      onUploadFile(picklistAsset, filesArray[0]);
      fileWithMeta.remove();
    }
  };

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className={styles.dropzoneWrapper}>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={Input}
          inputWithFilesContent={Input}
          LayoutComponent={(props) => <Layout {...props} />}
          maxSizeBytes={MAX_FILE_SIZE}
          accept={FILE_MIME_TYPES}
          maxFiles={FILES_LIMIT}
        />
      </section>
    </>
  );
}
