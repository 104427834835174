import React, { useEffect } from 'react';
import styles from './components.module.scss';

import { useParams } from 'react-router-dom';

import { useAssetActions, useAssetSelector } from 'hooks/Asset';

export default function AssetDetailsWrapper({ children }) {
  const { id } = useParams();

  const { currentAsset } = useAssetSelector();
  const { getAssetAction, clearStateAction } = useAssetActions();

  useEffect(() => {
    if (id) {
      getAssetAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => clearStateAction();
  }, []);

  return (
    <section className={styles.wrapper}>{currentAsset?.id === +id && <>{children}</>}</section>
  );
}
