import React from 'react';
import styles from './RequestOrderWrapper.module.scss';
import BackButton from 'components/BackButton/BackButton';

export default function Header({ title, onCancel }) {
  const handleClick = () => onCancel();

  return (
    <section className={styles.header}>
      <h1>{title}</h1>
      <BackButton onCancel={handleClick} />
    </section>
  );
}
