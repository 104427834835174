import React from 'react';
import styles from './DeliveryBlocks.module.scss';

import { FormLabel, FormRow } from 'components/FormComponents';
import FormInputWithCreation from 'components/FormComponents/FormInputWithCreation/FormInputWithCreation';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import Subhead from 'components/DetailsComponents/Subhead';

import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import { useFormContext } from 'react-hook-form';

export function NonDratItems() {
  const { clearErrors, formState } = useFormContext();
  const { isValid } = formState;

  const clearAtLeastError = () => !isValid && clearErrors();

  return (
    <div className={styles.container}>
      <Subhead>Non-Drat Items</Subhead>

      <div className={styles.other}>
        <FormRow type="column">
          <FormLabel>Enter Non deployed resources owned Item</FormLabel>
          <FormInputWithCreation
            disableErrorMessage
            className={styles.expandedField}
            name="nonDratItems"
            itemLabel="name"
            hintText={`After entering Item press “Enter” button`}
            modifyText={(str) => str.replace(allKeyboardLatSymbols, '').substring(0, 200)}
            transformItem={(str) => ({ name: str.trim() })}
            onKeyDownTriggered={clearAtLeastError}
          />
        </FormRow>
        <FormChipsContainer
          fieldName="nonDratItems"
          keyParam="name"
          label="name"
          deleteParameter="name"
        />
      </div>
    </div>
  );
}
