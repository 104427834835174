import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import PrefixEditWrapper from './components/PrefixEditWrapper';
import PrefixEditForm from './components/Form/PrefixEditForm';

export default function PrefixEdit() {
  const methods = useForm({
    defaultValues: {
      active: true,
      assetCategory: {},
      assetSubcategories: [{}],
      assetFields: [],
      description: '',
      name: '',
      prefix: '',
      prefixType: {}
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <PrefixEditWrapper>
        <PrefixEditForm />
      </PrefixEditWrapper>
    </FormProvider>
  );
}
