import {
  SET_TM_DASHBOARD_TAB_CONFIG,
  SET_TM_DASHBOARD_FILTER_CRITERIA,
  SET_TM_DASHBOARD_TAB,
  SET_TM_DASHBOARD_R_AND_R_DASHBOARD,
  CLEAR_TM_DASHBOARD_STATE,
  CLEAR_TM_DASHBOARD_TAB_STATE,
  SET_TM_DASHBOARD_LEVELS_DASHBOARD,
  SET_TM_DASHBOARD_SNAPSHOT_DASHBOARD
} from 'constants/reduceConstants';
import api from '../api';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

export const getDashboardConfig = (query) => (dispatch) =>
  api.teamMemberDashboard
    .getDashboardConfigApi(query)
    .then((res) => {
      dispatch({ type: SET_TM_DASHBOARD_TAB_CONFIG, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateTabConfig = (data) => (dispatch) =>
  api.teamMemberDashboard
    .updateTabConfigApi(data)
    .then((res) => {
      if (isSuccessfulStatus(res.status)) {
        dispatch({ type: SET_TM_DASHBOARD_TAB_CONFIG, payload: res.data });
      }
      return res.data;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const updateSectionConfig = (data) => () =>
  api.teamMemberDashboard
    .updateSectionConfigApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err?.request?.response);
      return errors;
    });

export const getFilterCriteria = () => (dispatch) =>
  api.teamMemberDashboard
    .getFilterCriteria()
    .then((res) => dispatch({ type: SET_TM_DASHBOARD_FILTER_CRITERIA, payload: res }))
    .catch((err) => console.log(err));

export const getRandRDashboard = () => (dispatch) =>
  api.teamMemberDashboard
    .getRandRDashboardApi()
    .then((res) => {
      dispatch({ type: SET_TM_DASHBOARD_R_AND_R_DASHBOARD, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getLevelsDashboard = () => (dispatch) =>
  api.teamMemberDashboard
    .getLevelsDashboardApi()
    .then((res) => {
      dispatch({ type: SET_TM_DASHBOARD_LEVELS_DASHBOARD, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getSnapshotDashboard = () => (dispatch) =>
  api.teamMemberDashboard
    .getSnapshotDashboardApi()
    .then((res) => {
      dispatch({ type: SET_TM_DASHBOARD_SNAPSHOT_DASHBOARD, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getRandRDashboardCsvResource = (q) => () =>
  api.teamMemberDashboard
    .getRandRDashboardCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setTab = (tab) => (dispatch) => {
  dispatch({ type: SET_TM_DASHBOARD_TAB, payload: tab });
};

export const clearTabState = (payload) => (dispatch) => {
  dispatch({ type: CLEAR_TM_DASHBOARD_TAB_STATE, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_TM_DASHBOARD_STATE });
};
