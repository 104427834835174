import React from 'react';
import styles from './Layout.module.scss';
import clsx from 'clsx';
import { FILES_LIMIT } from '../../UploaderConfig';
import { Input } from '../InputComponent/InputComponent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IconButton } from '@material-ui/core';

export default function Layout({ input, videos, onChange, previews, dropzoneProps, index }) {
  const handleDeleteClick = (id) => {
    const filtered = videos.filter((resource) => resource.id !== id);
    onChange(filtered, index);
  };

  return (
    <div className={styles.layoutWrapper}>
      <div
        className={clsx(videos.length >= FILES_LIMIT && styles.deprecatedInput)}
        {...dropzoneProps}>
        {videos.length < FILES_LIMIT ? (
          input
        ) : (
          <>
            <label className={clsx(styles.inputLabel, styles.deprecatedInput)}>{Input}</label>
            <p>Upload limit exceeded</p>
          </>
        )}
      </div>

      {!!videos.length && (
        <div className={styles.container}>
          {videos.map((video) => (
            <div key={video.id} className={styles.container__video}>
              <span>{video.name}</span>
              <IconButton
                onClick={() => handleDeleteClick(video.id)}
                className={styles.container__video_delete}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          ))}
          {previews}
        </div>
      )}
    </div>
  );
}
