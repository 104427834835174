import React from 'react';
import styles from './PreviewForm.module.scss';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCreateAssetsBatchSelector } from 'hooks/CreateBatchOfAssets';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import { allKeyboardLatSymbols, enrichNameToId } from 'helpers/AppHelpers';

export default function PreviewForm() {
  const { control, setValue, getValues } = useFormContext();
  const { fields, update } = useFieldArray({
    control,
    name: 'assets'
  });

  const { creationForm } = useCreateAssetsBatchSelector();

  const { rentalStatuses, locations } = creationForm;

  const selectLocation = (_, value, index) => {
    const rowObject = getValues('assets')[index];
    rowObject.lastLocationHistory.location = value;
    rowObject.lastLocationHistory.sublocation = null;
    update(index, rowObject);
  };

  const selectOption = ({ name, value }) => setValue(name, value, { shouldDirty: true });

  const getSublocations = (locationId) => {
    return locations?.find(({ id }) => id === locationId)?.sublocations || [];
  };

  return (
    <div className={styles.form}>
      {!!fields?.length && (
        <TableContainer className={styles.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>DR-ID</StyledTableCell>
                <StyledTableCell>Location</StyledTableCell>
                <StyledTableCell>Sublocation</StyledTableCell>
                <StyledTableCell>Rental Status</StyledTableCell>
                <StyledTableCell>Short Note</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row?.drCode || ''}</StyledTableCell>
                  <StyledTableCell>
                    <FormSearchInput
                      name={`assets[${index}].lastLocationHistory.location`}
                      options={locations || []}
                      onSelect={selectLocation}
                      index={index}
                      classes={styles.inputs_location}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormSelectInput
                      clearable
                      name={`assets[${index}].lastLocationHistory.sublocation`}
                      options={getSublocations(row?.lastLocationHistory?.location?.id)}
                      onSelect={selectOption}
                      classes={styles.inputs_select}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormSelectInput
                      name={`assets[${index}].rental.rentalStatus`}
                      options={enrichNameToId(rentalStatuses) || []}
                      onSelect={selectOption}
                      classes={styles.inputs_select}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormTextArea
                      name={`assets[${index}].shortNote`}
                      options={{
                        rule: allKeyboardLatSymbols,
                        max: 250,
                        minRows: 1,
                        disableCounter: true,
                        disableError: true
                      }}
                      classes={styles.inputs_note}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
