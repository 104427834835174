import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTableDataApi = (query) =>
  api.get(`/RequestOrders?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = (query) =>
  api.get(`/RequestOrders/FilterCriteria?${queryToString(query)}`).then((res) => res.data);

export const getRequestOrderApi = (id) => api.get(`/RequestOrder?id=${id}`).then((res) => res.data);

export const getCreationFormDataApi = (q) =>
  api.get(`/RequestOrder/CreationForm?${queryToString(q)}`).then((res) => res.data);

export const createRequestApi = (params) =>
  api.post(`/RequestOrder`, params).then((res) => res.data);

export const updateEntityApi = (params) =>
  api.put(`/RequestOrders/RequestEntityData`, params).then((res) => res.data);

export const updateEntitiesApi = (params) =>
  api.put('/RequestOrders/RequestEntityDatas', params).then((res) => res);

export const updateRequestOrderStatusApi = (params) =>
  api.put('/RequestOrders/Status', params).then((res) => res);

export const deleteEntityApi = (query) =>
  api.delete(`/RequestOrders/RequestEntityDatas?${queryToString(query)}`).then((res) => res);

export const deleteRequestOrderApi = (id) =>
  api.delete(`/RequestOrder?id=${id}`).then((res) => res.data);
