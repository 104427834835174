import React from 'react';
import styles from './TeamMemberModals.module.scss';

import { getUserFullName } from 'helpers/AppHelpers';

export function RewriteStatusContent({ teamMembers, displayList }) {
  const list = teamMembers?.length ? teamMembers.map(getUserFullName).join(', ') : '';

  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        {`Team member${displayList ? 's' : ''} selected has existing scheduling statuses.`}
      </div>
      {displayList && list && (
        <div className={styles.modal__content}>
          <div className={styles.modal__content_label}>
            <b>Team member(s) with existing scheduling statuses:</b> {list}.
          </div>
        </div>
      )}
      <div className={styles.modal__footer}>Do you want to rewrite status?</div>
    </div>
  );
}
