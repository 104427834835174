import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ROOT_PATH } from 'constants/routeConstants';
import { useUserConfig } from 'hooks/useUserConfig';

function RoleGuard({ component, rolesAllowed }) {
  const history = useHistory();

  const { isConfigReceived, userRoleId } = useUserConfig();

  const suitableRole = rolesAllowed.includes(userRoleId);

  useEffect(() => {
    if (!isConfigReceived) return;

    if (!suitableRole) {
      history.push(ROOT_PATH);
    }
  }, [isConfigReceived]);

  return isConfigReceived && suitableRole ? component : null;
}

export default withRouter(RoleGuard);
