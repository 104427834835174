import React from 'react';
import { StyledTableCell, StyledTableRow } from '../../StyledComponents';
import styles from './NoDataTableRow.module.scss';

export default function NoDataTableRow({ label, isTableFiltered }) {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={100} className={styles.empty_row}>
        {label
          ? label
          : isTableFiltered
          ? 'No matches in the table.'
          : 'No available data in the table.'}
      </StyledTableCell>
    </StyledTableRow>
  );
}
