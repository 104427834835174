import React from 'react';
import styles from './CustomDetails.module.scss';

import Subtitle from './TemplateSection/Subtitle';

export default function ResourceSection({ field }) {
  return (
    <div className={styles.resource_section}>
      <div className={styles.image}>
        <img src={field.data.resource.link} alt="" />
      </div>
      {!!field.data.text && <Subtitle text={field.data.text} />}
    </div>
  );
}
