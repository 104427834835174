import React from 'react';
import styles from './Notes.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../selectors';
import Subtitle from 'components/DetailsComponents/Subtitle';

export default function Notes() {
  const { notes } = useSelector(selectCurrentTeamMemberData());

  return (
    <section className={styles.notes}>
      <Subtitle>Notes</Subtitle>
      <p className={styles.notes__value}>{notes || ''}</p>
    </section>
  );
}
