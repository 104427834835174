import React, { useRef } from 'react';
import { Backdrop } from '@material-ui/core';
import styles from './FilterComponents.module.scss';
import ChipsComponent from './ChipsComponent';
import FilterButton from './FilterButton';
import PopperWrapper from './PopperWrapper';
import FilterActions from './FilterActions';
import FilterHeader from './FilterHeader';
import clsx from 'clsx';

export default function FilterWrapper({
  isMobile,
  open,
  chips,
  onClick,
  onChipDelete,
  onApply,
  onCancel,
  onClear,
  onBack,
  children,
  chipLabel,
  disableChipDelete,
  disableSeparateChipDelete,
  isApplyDisabled,
  transparentBackDrop,
  enableChipsForMobile,
  areChipsAvailable = true,
  isFilterActive
}) {
  const iconRef = useRef();

  const shouldItLightUp = () =>
    isFilterActive ? isFilterActive() : !isMobile ? open || chips?.length : chips?.length;

  const isMainChipsAvailable = () => (enableChipsForMobile ? false : isMobile);
  const isSecondaryChipsAvailable = () => (enableChipsForMobile ? true : !isMobile);

  return (
    <div className={clsx(styles.wrapper, open && styles.private_z_index)}>
      {!isMobile && (
        <Backdrop open={open} className={clsx(transparentBackDrop && styles.transparentBackDrop)} />
      )}
      <FilterButton onClick={onClick} iconRef={iconRef} onChangeColor={shouldItLightUp} />

      <PopperWrapper open={open} iconRef={iconRef}>
        {isMobile && <FilterHeader onBack={onBack} />}
        {children}
        {areChipsAvailable && isMainChipsAvailable() && (
          <ChipsComponent
            disableDelete={disableChipDelete}
            disableSeparateChipDelete={disableSeparateChipDelete}
            label={chipLabel || 'displayName'}
            chips={chips}
            onChipDelete={onChipDelete}
          />
        )}
        <FilterActions
          onCancel={onCancel}
          onClear={onClear}
          onApply={onApply}
          isApplyDisabled={isApplyDisabled}
        />
      </PopperWrapper>

      {areChipsAvailable && isSecondaryChipsAvailable() && (
        <ChipsComponent
          disableDelete={disableChipDelete}
          disableSeparateChipDelete={disableSeparateChipDelete}
          label={chipLabel || 'displayName'}
          chips={chips}
          onChipDelete={onChipDelete}
          enableForMobile={enableChipsForMobile}
        />
      )}
    </div>
  );
}
