import {
  SET_IS_LOGGED_IN,
  SET_IS_SIGN_OUT,
  SET_AUTH_LINK
} from '../constants/reduceConstants';

const initialState = {
  isLoggedIn: null,
  isSignOut: false,
  authLink: {}
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload
      };
    case SET_IS_SIGN_OUT:
      return {
        ...state,
        isSignOut: payload
      };
    case SET_AUTH_LINK:
      return {
        ...state,
        authLink: payload
      };
    default:
      return state;
  }
};

export default authReducer;
