import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_IMG_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import styles from './UploadImagesField.module.scss';
import AddPictureIcon from 'assets/images/add_picture.svg';

const MAX_FILE_SIZE = 10485760;
const FILE_MATCH = /(jpg|jpeg|png|gif|bmp|webp)/;
const FILE_TITLE = 'JPG, JPEG, PNG, GIF, BMP, WEBP';

const Input = (
  <div className={styles.input}>
    <img src={AddPictureIcon} alt="add-picture" />
    <span>Upload Picture</span>
  </div>
);

const Layout = ({ input, dropzoneProps }) => {
  return (
    <div className={styles.layoutWrapper}>
      <div {...dropzoneProps}>{input}</div>
    </div>
  );
};

export default function UploadImagesField({ onUploadFile }) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const getUploadParams = () => {
    if (getToken()) {
      return {
        url: UPLOAD_IMG_URL,
        headers: {
          authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
        }
      };
    }
  };

  const handleChangeStatus = (fileWithMeta, status) => {
    const { meta } = fileWithMeta;

    if (!meta.type.match(FILE_MATCH)) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed:
                ${FILE_TITLE}`,
        isOpened: true
      });
    } else if (meta.size > MAX_FILE_SIZE) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" exceeds maximum allowed upload size of 10MB`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (status === 'done') {
      const needFile = JSON.parse(fileWithMeta.xhr?.response || null);
      onUploadFile(needFile);
      fileWithMeta.remove();
    }
  };

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className={styles.dropzoneWrapper}>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={Input}
          inputWithFilesContent={Input}
          LayoutComponent={(props) => <Layout {...props} />}
          maxSizeBytes={MAX_FILE_SIZE}
          accept="image/jpg,image/jpeg,image/png,image/gif,image/bmp,image/webp"
        />
      </section>
    </>
  );
}
