import React, { useState, useEffect, useRef } from 'react';
import PieChart, {
  Series,
  Font,
  Legend,
  Label,
  Connector
} from 'devextreme-react/pie-chart';

const customizePoint = (pointInfo) => {
  switch (pointInfo.argument) {
    case 'Operational':
      return { color: '#7ec37e' };
    case 'Critical':
      return { color: '#da9898' };
    case 'NonCritical':
      return { color: '#e1cd80' };
    case 'BeyondRepair':
      return { color: '#0c0c0c' };
    default:
      break;
  }
};

const customizeLabel = (e) => {
  if (e.originalValue) return ` ${e.originalValue} (${(e.percent * 100).toFixed(1)}%) `;
};

const CenterTemplate = (totalValue) => {
  return (
    <svg>
      <text textAnchor="middle" x="75" y="45" style={{ fontSize: 18, fill: '#494949' }}>
        <tspan
          x="100"
          style={{
            fontWeight: 500,
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontSize: '12px',
            height: '14px',
            color: '#000'
          }}>
          Total
        </tspan>
        <tspan
          x="100"
          dy="15px"
          style={{
            fontWeight: 500,
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontSize: '12px',
            height: '14px',
            color: '#000'
          }}>
          {totalValue}
        </tspan>
      </text>
    </svg>
  );
};

export default function DashboardPiechart({ pieData, reRender }) {
  const ref = useRef(null);
  const [graphPieData, setGraphPieData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    if (pieData?.length) {
      setGraphPieData(pieData);
      setTotalValue(
        pieData?.map(({ count }) => count).reduce((sum, current) => sum + current, 0)
      );
    }
    return () => {
      setGraphPieData([]);
      setTotalValue(0);
    };
  }, [pieData]);

  useEffect(() => {
    if (reRender) {
      ref.current.instance.refresh();
    }
  }, [reRender]);

  const onPieDrawn = () => {
    // ref.current.instance.endUpdate();
  };

  return (
    <PieChart
      ref={ref}
      dataSource={graphPieData}
      resolveLabelOverlapping="shift"
      customizePoint={customizePoint}
      sizeGroup="piesGroup"
      innerRadius={0.7}
      centerRender={() => CenterTemplate(totalValue)}
      size={{ height: 150, width: 280 }}
      startAngle={45}
      segmentsDirection="anticlockwise"
      redrawOnResize={false}
      animation={false}
      onDone={() => onPieDrawn()}
      type="doughnut">
      <Series argumentField="name" valueField="count">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
          position="inline"
          backgroundColor="none">
          <Font
            size={12}
            color={'#000'}
            family={['Montserrat', 'sans-serif'].join(',')}
          />
          <Connector visible={true} width={1}></Connector>
        </Label>
      </Series>
      <Legend visible={false}></Legend>
    </PieChart>
  );
}
