import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectMapFilterCriteriaData, selectMapAdvancedPrefilterData } from '../selectors';
import {
  getMapFilterCriteria,
  getNearestMapLocations,
  setMapAdvancedPrefilter,
  clearAdvancedFilterState
} from 'actions/locationsActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { enrichNameToId, reformatInputValue, enqueueErrorSnackbar } from 'helpers/AppHelpers';
import { searchLatLngByData } from 'helpers/GeoCodingHelpers';
import SelectInput from 'components/SelectInput/SelectInput';
import Multiselect from 'components/Multiselect/Multiselect';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { Chip, Fade, Button, IconButton, Paper, Popper, TextField } from '@material-ui/core';
import { MainButton } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import styles from './ActiveLocationsAdvancedFilter.module.scss';
import { ERROR_FIND_LOCATION } from 'constants/infoSnackbarData';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import TabPanel from 'components/ToggleBars/TabPanel';

const FILTER_TABS = [
  { id: 1, label: 'Asset' },
  { id: 2, label: 'Loose Item' }
];

function ActiveLocationsAdvancedFilter({
  isMobile,
  filter,
  setFilter,
  openFilter,
  setOpenFilter,
  setCustomMarker,
  mapAdvancedPrefilter,
  setMapAdvancedPrefilterAction,
  mapFilterCriteria,
  getMapFilterCriteriaAction,
  getNearestMapLocationsAction,
  clearAdvancedFilterStateAction
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chips, setChips] = useState([]);
  const [searchByTab, setSearchByTab] = useState('assets');
  const [values, setValues] = useState({});

  const isAssetTabSelected = values?.selectedPanelTab === 0;

  const {
    assetPrefixes,
    ticketSeverities,
    locations,
    rentalStatuses,
    siteType,
    looseItemLocationStatuses,
    looseItemCategories,
    looseItems
  } = mapFilterCriteria;

  const createChips = (filterValues) => {
    const chipsArray = [];

    const isAssetTab = filterValues?.selectedPanelTab === 0;

    const inputFilter = isAssetTab ? filterValues?.asset : filterValues?.looseItem;

    if (isAssetTab) {
      chipsArray.push(inputFilter?.location, ...inputFilter.assetPrefixes);
    } else {
      chipsArray.push(inputFilter?.location);
    }

    if (inputFilter?.amountOfAssets)
      chipsArray.push({ name: `Amount of assets: ${inputFilter.amountOfAssets}` });
    if (inputFilter?.rentalStatuses?.length) chipsArray.push(...inputFilter.rentalStatuses);
    if (inputFilter?.ticketSeverities?.length) chipsArray.push(...inputFilter.ticketSeverities);
    if (inputFilter?.address) chipsArray.push({ name: inputFilter?.address });
    if (inputFilter?.looseItemCategories?.length)
      chipsArray.push(...inputFilter.looseItemCategories);
    if (inputFilter?.selectedFields?.length)
      chipsArray.push(
        ...inputFilter.selectedFields.map(({ assetField: { displayName }, amountOfItem }) => {
          return { name: `${displayName}: ${amountOfItem}` };
        })
      );
    if (inputFilter?.selectedLooseItems?.length)
      chipsArray.push(
        ...inputFilter.selectedLooseItems.map(({ looseItem: { name }, amountOfItem }) => {
          return { name: `${name}: ${amountOfItem}` };
        })
      );
    if (inputFilter?.siteType?.id) chipsArray.push(inputFilter.siteType);

    const chipsValues = chipsArray?.filter(Boolean) || [];

    if (chipsValues?.length) {
      chipsValues.unshift({ name: isAssetTab ? 'Asset:' : 'Loose Item:' });
    }

    setChips(chipsValues);
  };

  useEffect(() => {
    getMapFilterCriteriaAction();
  }, []);

  useEffect(() => {
    if (
      Object.values(mapAdvancedPrefilter?.asset || {}).length &&
      mapAdvancedPrefilter?.selectedPanelTab === 0
    ) {
      const sendData = {
        ...mapAdvancedPrefilter.asset,
        state: { id: 1, name: 'AA' },
        siteType:
          mapAdvancedPrefilter?.asset?.siteType?.name === 'All'
            ? null
            : mapAdvancedPrefilter?.asset?.siteType || null
      };
      getNearestMapLocationsAction(sendData).then((res) => {
        if (res?.nearestLocations?.length) {
          createChips(mapAdvancedPrefilter);
          setCustomMarker({});
          setOpenFilter(false);
        }
        setFilter(mapAdvancedPrefilter);
        setValues(mapAdvancedPrefilter);
        setSearchByTab(mapAdvancedPrefilter?.asset?.selectedFields?.length ? 'fields' : 'assets');
      });
    }
    if (
      Object.values(mapAdvancedPrefilter?.looseItem || {}).length &&
      mapAdvancedPrefilter?.selectedPanelTab === 1
    ) {
      const sendData = {
        ...mapAdvancedPrefilter.looseItem,
        state: { id: 1, name: 'AA' },
        siteType:
          mapAdvancedPrefilter?.looseItem?.siteType?.name === 'All'
            ? null
            : mapAdvancedPrefilter?.looseItem?.siteType || null
      };
      getNearestMapLocationsAction(sendData).then((res) => {
        if (res?.nearestLocations?.length) {
          createChips(mapAdvancedPrefilter);
          setCustomMarker({});
          setOpenFilter(false);
        }
        setFilter(mapAdvancedPrefilter);
        setValues(mapAdvancedPrefilter);
      });
    }
  }, [mapAdvancedPrefilter]);

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filter);
  };

  const applyFilter = () => {
    if (isAssetTabSelected) {
      const assetCopy = { ...values.asset };
      if (searchByTab === 'assets') {
        assetCopy.selectedFields = [];
      } else {
        assetCopy.amountOfAssets = 0;
      }
      setMapAdvancedPrefilterAction({
        ...values,
        asset: { ...assetCopy, isLoadMore: false },
        looseItem: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } }
      });
      setFilter({
        ...values,
        asset: { ...assetCopy },
        looseItem: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } }
      });
    } else {
      setMapAdvancedPrefilterAction({
        ...values,
        asset: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
        looseItem: { ...values.looseItem, isLoadMore: false }
      });
      setFilter({
        ...values,
        asset: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
        looseItem: { ...values.looseItem }
      });
    }
  };

  const onCustomAreaBlur = () => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';

    searchLatLngByData(values[key].address).then((res) => {
      if (res.length) {
        setCustomMarker({ lat: res[0].y, lng: res[0].x });
        setValues({
          ...values,
          [key]: {
            ...values[key],
            latitude: res[0].y,
            longitude: res[0].x
          }
        });
      } else {
        enqueueErrorSnackbar(ERROR_FIND_LOCATION);
      }
    });
  };

  const onInput = (event) => {
    const key = event.target.name;
    let value = event.target.value;

    const paramKey = isAssetTabSelected ? 'asset' : 'looseItem';

    if (value) {
      if (key === 'amountOfAssets') {
        value = reformatInputValue(value, 1000, /[^0-9]/g);
      }
    }

    setValues({ ...values, [paramKey]: { ...values[paramKey], [key]: value } });
  };

  const onSelect = (selectedItem, value) => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';
    setValues({ ...values, [key]: { ...values[key], [selectedItem]: value } });
  };

  const onSiteTypeSelect = ({ name, value }) => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';
    setValues({ ...values, [key]: { ...values[key], [name]: value } });
  };

  const onItemInput = (event) => {
    const mainKey = isAssetTabSelected ? 'asset' : 'looseItem';
    const param = isAssetTabSelected ? 'selectedFields' : 'selectedLooseItems';

    const fieldsCopy = [...values?.[mainKey]?.[param]];
    const key = event.target.name;
    let value = event.target.value;

    if (value) {
      value = reformatInputValue(value, 1000, /[^0-9]/g);
    }

    fieldsCopy[+key].amountOfItem = +value;
    setValues({ ...values, [mainKey]: { ...values[mainKey], [param]: fieldsCopy } });
  };

  const onFieldSelect = (selectedItem) => {
    const fieldsCopy = [...values?.asset?.selectedFields];
    fieldsCopy[+selectedItem.name].assetField = selectedItem.value;
    setValues({ ...values, asset: { ...values.asset, selectedFields: fieldsCopy } });
  };
  const onItemSelect = (name, value) => {
    const fieldsCopy = [...values?.looseItem?.selectedLooseItems];
    fieldsCopy[+name].looseItem = value;
    setValues({ ...values, looseItem: { ...values.looseItem, selectedLooseItems: fieldsCopy } });
  };

  const onMultiselect = (e, value) => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';

    if (e.target) {
      setValues({
        ...values,
        [key]: {
          ...values[key],
          [e.target.name]: [
            ...e.target.value?.map((id) =>
              enrichNameToId(mapFilterCriteria[e.target.name])?.find((item) => item.id === id)
            )
          ]
        }
      });
    } else {
      setValues({ ...values, [key]: { ...values[key], [e]: [...value] } });
    }
  };

  useEffect(() => {
    if (!isAssetTabSelected) return;
    if (values?.asset?.assetPrefixes?.length) {
      const selectedFields = values?.asset?.selectedFields || [];
      const availableFieldsIds = values?.asset?.assetPrefixes
        ?.flatMap(({ assetFields }) => assetFields)
        .map(({ id }) => id);
      const newFields = selectedFields.filter((el) => {
        if (el?.assetField?.id) {
          return availableFieldsIds.includes(el.assetField.id);
        }
        return true;
      });
      setValues({ ...values, asset: { ...values.asset, selectedFields: newFields } });
    } else {
      setValues({ ...values, asset: { ...values.asset, selectedFields: [] } });
    }
  }, [values?.asset?.assetPrefixes]);

  const onButtonToggle = (e, value) => {
    const selectedTab = isAssetTabSelected
      ? values?.asset?.selectedTab
      : values?.looseItem?.selectedTab;
    if (value && value !== selectedTab) {
      const entityCopy = isAssetTabSelected ? { ...values.asset } : { ...values.looseItem };
      delete entityCopy.location;
      setCustomMarker({});
      setValues({
        ...values,
        [isAssetTabSelected ? 'asset' : 'looseItem']: {
          ...entityCopy,
          selectedTab: value,
          address: '',
          longitude: null,
          latitude: null
        }
      });
    }
  };

  const onPanelTabToggle = (tabIndex) => {
    setValues({
      ...values,
      selectedPanelTab: tabIndex
    });
  };

  const onToggle = (e, value) => {
    if (value && value !== searchByTab) {
      setSearchByTab(value);
    }
  };

  const cancelFilter = () => {
    setFilter({
      ...filter,
      asset: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
      looseItem: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } }
    });
    setCustomMarker({});
    setChips([]);
    clearAdvancedFilterStateAction();
    setMapAdvancedPrefilterAction({});
    setOpenFilter(false);
  };

  const closeFilterHandle = () => {
    setOpenFilter(!openFilter);
  };

  const addItemHandle = () => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';
    const param = isAssetTabSelected ? 'assetField' : 'looseItem';
    const arrayKey = isAssetTabSelected ? 'selectedFields' : 'selectedLooseItems';

    const emptyField = { amountOfItem: 0, [param]: {} };

    setValues({
      ...values,
      [key]: {
        ...values[key],
        [arrayKey]: values?.[key]?.[arrayKey]
          ? [...values?.[key]?.[arrayKey], emptyField]
          : [emptyField]
      }
    });
  };

  const deleteItemHandle = (index) => {
    const key = isAssetTabSelected ? 'asset' : 'looseItem';
    const param = isAssetTabSelected ? 'selectedFields' : 'selectedLooseItems';

    const fieldsCopy = [...values?.[key]?.[param]];
    fieldsCopy.splice(index, 1);
    setValues({ ...values, [key]: { ...values[key], [param]: fieldsCopy } });
  };

  const getFieldsOptions = (field) => {
    const selectedFieldIdArray = values?.asset?.selectedFields.map(
      ({ assetField }) => assetField.id
    );
    return (
      values?.asset?.assetPrefixes
        ?.flatMap(({ assetFields }) => assetFields)
        // delete selected options:
        ?.filter(({ id }) => !selectedFieldIdArray.includes(id))
        // delete duplicates:
        .filter(
          (currentAsset, index, assetsArray) =>
            assetsArray.findIndex((asset) => asset.id === currentAsset.id) === index
        )
        // add already selected option for current field:
        ?.concat([field?.assetField?.id ? field?.assetField : null])
        ?.filter(Boolean) || []
    );
  };
  const getLooseItemOptions = (looseItem) => {
    const idsOfSelectedItems =
      values?.looseItem?.selectedLooseItems
        ?.map(({ looseItem }) => looseItem?.id)
        ?.filter(Boolean) || [];

    const itemsToExclude = idsOfSelectedItems.filter((id) => id !== looseItem?.id);

    const selectedCategoriesIds = values?.looseItem?.looseItemCategories?.map(({ id }) => id) || [];

    if (!selectedCategoriesIds.length) {
      return looseItems.filter(({ id }) => !itemsToExclude.includes(id));
    } else {
      const options = looseItems
        .filter(({ id }) => !itemsToExclude.includes(id))
        .filter(
          ({ looseItemCategories }) =>
            !!looseItemCategories.filter(({ id }) => selectedCategoriesIds.includes(id)).length
        );

      if (options?.length && looseItem?.id && !options.map(({ id }) => id).includes(looseItem.id)) {
        return options.concat(looseItem).sort((a, b) => a.id > b.id);
      } else return options;
    }
  };

  const isApplyDisabled = () => {
    if (isAssetTabSelected) {
      const isAllFieldsDataPresent = () => {
        let isValid = true;
        values?.asset?.selectedFields?.forEach((field) => {
          if (!field.amountOfItem || !field.assetField.id) isValid = false;
        });
        return values?.asset?.selectedFields?.length && isValid;
      };

      return (
        !(values?.asset?.location?.id || values?.asset?.longitude) ||
        !values?.asset?.assetPrefixes?.length ||
        !(
          (searchByTab === 'assets' && values?.asset?.amountOfAssets) ||
          (isAllFieldsDataPresent() && searchByTab === 'fields')
        )
      );
    } else {
      const isAllItemsDataPresent = () => {
        let isValid = true;
        values?.looseItem?.selectedLooseItems?.forEach((item) => {
          if (!item.amountOfItem || !item.looseItem.id) isValid = false;
        });
        return values?.looseItem?.selectedLooseItems?.length && isValid;
      };

      return (
        !(values?.looseItem?.location?.id || values?.looseItem?.longitude) ||
        !values?.looseItem?.selectedLooseItems?.length ||
        !isAllItemsDataPresent()
      );
    }
  };

  const addAllParameter = (arr) => {
    return arr.concat({ id: 'All', name: 'All' });
  };

  return (
    <div className={styles.filterWrapper}>
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <SearchIcon />
      </IconButton>
      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {!isMobile && <h2>Searching on the Map</h2>}
              {isMobile && <FilterHeader title="Searching" onBack={closeFilterHandle} />}

              <section className={styles.formColumn}>
                <div>
                  <TabPanel
                    tabs={FILTER_TABS}
                    value={values.selectedPanelTab}
                    onChange={onPanelTabToggle}
                  />
                  <section className={styles.formCell}>
                    <div className={styles.inputWrapper}>
                      <ToggleButtonGroup
                        className={styles.toggleButtonsBlock}
                        value={
                          isAssetTabSelected
                            ? values.asset?.selectedTab
                            : values.looseItem?.selectedTab
                        }
                        exclusive
                        onChange={(e, value) => onButtonToggle(e, value)}>
                        <ToggleButton classes={{ selected: styles.selectedTab }} value="location">
                          Active Locations
                        </ToggleButton>
                        <ToggleButton classes={{ selected: styles.selectedTab }} value="custom">
                          New Area
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </section>
                  {(isAssetTabSelected
                    ? values?.asset?.selectedTab === 'location'
                    : values?.looseItem?.selectedTab === 'location') && (
                    <section className={styles.formCell}>
                      <label>Destination*</label>
                      <div className={styles.inputWrapper}>
                        <InputSearchWithMultiselect
                          name="location"
                          defaultValue={
                            isAssetTabSelected
                              ? values?.asset?.location?.id || ''
                              : values?.looseItem?.location?.id || ''
                          }
                          options={locations || []}
                          onFilterSelect={onSelect}
                          multiselect={false}
                        />
                      </div>
                    </section>
                  )}
                  {(isAssetTabSelected
                    ? values?.asset?.selectedTab === 'custom'
                    : values?.looseItem?.selectedTab === 'custom') && (
                    <>
                      <section className={styles.formCell}>
                        <label>Address*</label>
                        <div className={styles.inputWrapper}>
                          <TextField
                            name="address"
                            className={styles.textInput}
                            variant="outlined"
                            value={
                              isAssetTabSelected
                                ? values?.asset?.address || ''
                                : values?.looseItem?.address || ''
                            }
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={onInput}
                            onBlur={onCustomAreaBlur}
                          />
                        </div>
                      </section>
                    </>
                  )}
                  <section className={styles.formCell}>
                    <label>Location type</label>
                    <div className={styles.inputWrapper}>
                      <SelectInput
                        name="siteType"
                        value={
                          isAssetTabSelected
                            ? values?.asset?.siteType?.id || ''
                            : values?.looseItem?.siteType?.id || ''
                        }
                        menuItems={addAllParameter(siteType) || []}
                        onSelect={onSiteTypeSelect}
                      />
                    </div>
                  </section>
                  {isAssetTabSelected && (
                    <>
                      <section className={styles.formCell}>
                        <label>Asset prefixes*</label>
                        <div className={styles.inputWrapper}>
                          <InputSearchWithMultiselect
                            name="assetPrefixes"
                            defaultValue={values?.asset?.assetPrefixes?.map(({ id }) => id) || []}
                            options={assetPrefixes || []}
                            onFilterSelect={onMultiselect}
                            multiselect={true}
                          />
                        </div>
                      </section>
                      <section className={styles.formCell}>
                        <label>Rental</label>
                        <div className={styles.inputWrapper}>
                          <Multiselect
                            name="rentalStatuses"
                            valuesArray={values?.asset?.rentalStatuses?.map(({ id }) => id) || []}
                            options={enrichNameToId(rentalStatuses) || []}
                            onFilterSelect={onMultiselect}
                          />
                        </div>
                      </section>
                      <section className={styles.formCell}>
                        <label>Status</label>
                        <div className={styles.inputWrapper}>
                          <Multiselect
                            name="ticketSeverities"
                            valuesArray={values?.asset?.ticketSeverities?.map(({ id }) => id) || []}
                            options={enrichNameToId(ticketSeverities) || []}
                            onFilterSelect={onMultiselect}
                          />
                        </div>
                      </section>
                      <section className={styles.formCell}>
                        <div className={styles.inputWrapper}>
                          <ToggleButtonGroup
                            className={styles.toggleButtonsBlock}
                            value={searchByTab}
                            exclusive
                            onChange={(e, value) => onToggle(e, value)}>
                            <ToggleButton classes={{ selected: styles.selectedTab }} value="assets">
                              Search by Assets
                            </ToggleButton>
                            <ToggleButton classes={{ selected: styles.selectedTab }} value="fields">
                              Search by Fields
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </section>
                      {searchByTab === 'assets' && (
                        <section className={styles.formCell}>
                          <label>Amount of assets*</label>
                          <div className={styles.inputWrapper}>
                            <TextField
                              name="amountOfAssets"
                              className={styles.textInput}
                              variant="outlined"
                              value={values?.asset?.amountOfAssets || ''}
                              onChange={onInput}
                            />
                          </div>
                        </section>
                      )}
                      {searchByTab === 'fields' && (
                        <section className={styles.formCell}>
                          <label>Fields*</label>
                          <div className={styles.inputWrapper}>
                            {!!values?.asset?.selectedFields?.length &&
                              values.asset.selectedFields.map((field, index) => (
                                <div key={index} className={styles.fieldContainer}>
                                  <SelectInput
                                    name={`${index}`}
                                    value={field?.assetField?.id || ''}
                                    menuItems={getFieldsOptions(field) || []}
                                    onSelect={onFieldSelect}
                                  />
                                  <TextField
                                    name={`${index}`}
                                    className={styles.textInput}
                                    variant="outlined"
                                    value={field?.amountOfItem || ''}
                                    onChange={onItemInput}
                                    inputProps={{ autoComplete: 'off' }}
                                    autocomplete="off"
                                  />
                                  <IconButton
                                    className={styles.deleteButton}
                                    onClick={() => deleteItemHandle(index)}>
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              ))}
                            <Button
                              className={styles.addFieldButton}
                              onClick={addItemHandle}
                              disabled={!values?.asset?.assetPrefixes?.length}>
                              <AddIcon />
                            </Button>
                          </div>
                        </section>
                      )}
                    </>
                  )}

                  {!isAssetTabSelected && (
                    <>
                      <section className={styles.formCell}>
                        <label>Status</label>
                        <div className={styles.inputWrapper}>
                          <Multiselect
                            name="looseItemLocationStatuses"
                            valuesArray={
                              values?.looseItem?.looseItemLocationStatuses?.map(({ id }) => id) ||
                              []
                            }
                            options={enrichNameToId(looseItemLocationStatuses) || []}
                            onFilterSelect={onMultiselect}
                          />
                        </div>
                      </section>
                      <section className={styles.formCell}>
                        <label>Category</label>
                        <div className={styles.inputWrapper}>
                          <InputSearchWithMultiselect
                            name="looseItemCategories"
                            defaultValue={
                              values?.looseItem?.looseItemCategories?.map(({ id }) => id) || []
                            }
                            options={looseItemCategories || []}
                            onFilterSelect={onMultiselect}
                            multiselect={true}
                          />
                        </div>
                      </section>
                      <section className={styles.formCell}>
                        <label>Loose Item*</label>
                        <div className={styles.inputWrapper}>
                          {!!values?.looseItem?.selectedLooseItems?.length &&
                            values.looseItem.selectedLooseItems.map((item, index) => (
                              <div key={index} className={styles.fieldContainer}>
                                <InputSearchWithMultiselect
                                  name={`${index}`}
                                  defaultValue={item?.looseItem?.id || ''}
                                  options={getLooseItemOptions(item.looseItem) || []}
                                  onFilterSelect={onItemSelect}
                                  multiselect={false}
                                />

                                <TextField
                                  name={`${index}`}
                                  className={styles.textInput}
                                  variant="outlined"
                                  value={item?.amountOfItem || ''}
                                  onChange={onItemInput}
                                  inputProps={{ autoComplete: 'off' }}
                                  autocomplete="off"
                                />
                                <IconButton
                                  className={styles.deleteButton}
                                  onClick={() => deleteItemHandle(index)}>
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            ))}
                          <Button className={styles.addFieldButton} onClick={addItemHandle}>
                            <AddIcon />
                          </Button>
                        </div>
                      </section>
                    </>
                  )}

                  {isMobile && (
                    <div className={styles.chipsBlock}>
                      {!!chips?.length &&
                        chips.map((item, index) => (
                          <Chip
                            classes={{ icon: styles.chipIcon }}
                            key={index}
                            label={item.siteCode || item.prefix || item.displayName || item.name}
                            deleteIcon={<></>}
                            className={styles.fieldsChip}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <section className={styles.buttonWrapper}>
                  <ClearFilterButton onClick={cancelFilter} />
                  <div className={styles.primary}>
                    <MainButton
                      text="Cancel"
                      action={closeFilterHandle}
                      type="secondary"
                      size="filter_popup"
                    />
                    <MainButton
                      text="Apply"
                      action={applyFilter}
                      type="primary"
                      size="filter_popup"
                      isDisabled={isApplyDisabled()}
                    />
                  </div>
                </section>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item, index) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={index}
                label={item.siteCode || item.prefix || item.displayName || item.name}
                deleteIcon={<></>}
                className={styles.fieldsChip}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  mapFilterCriteria: selectMapFilterCriteriaData(),
  mapAdvancedPrefilter: selectMapAdvancedPrefilterData()
});

const mapDispatchToProps = {
  getMapFilterCriteriaAction: getMapFilterCriteria,
  getNearestMapLocationsAction: getNearestMapLocations,
  setMapAdvancedPrefilterAction: setMapAdvancedPrefilter,
  clearAdvancedFilterStateAction: clearAdvancedFilterState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ActiveLocationsAdvancedFilter);
