import { useDispatch } from 'react-redux';
import {
  clearTMHistoryState,
  getTMHistoryData,
  getTMHistoryFilterCriteria,
  getTMHistorySecondaryFilterCriteria,
  getTMHistoryUserConfig,
  setHistoryFilterCriteria,
  setTMHistoryListFilter,
  updateHistoryConfig
} from 'actions/teamMemberHistoryActions';

export function useHistoryActions() {
  const dispatch = useDispatch();

  const getHistoryConfigAction = () => dispatch(getTMHistoryUserConfig());

  const updateHistoryConfigAction = (q) => dispatch(updateHistoryConfig(q));

  const setHistoryFilterAction = (data) => dispatch(setTMHistoryListFilter(data));

  const setHistoryFilterCriteriaAction = (data) => dispatch(setHistoryFilterCriteria(data));

  const getHistoryDataAction = (q) => dispatch(getTMHistoryData(q));

  const getFilterCriteriaAction = (q) => dispatch(getTMHistoryFilterCriteria(q));
  const getSecondaryFilterCriteriaAction = (q) => dispatch(getTMHistorySecondaryFilterCriteria(q));

  const clearStateAction = () => dispatch(clearTMHistoryState());

  return {
    getHistoryConfigAction,
    updateHistoryConfigAction,
    setHistoryFilterAction,
    getHistoryDataAction,
    getFilterCriteriaAction,
    getSecondaryFilterCriteriaAction,
    setHistoryFilterCriteriaAction,
    clearStateAction
  };
}
