import { useDispatch } from 'react-redux';
import {
  clearState,
  createItem,
  deleteLooseItem,
  getCategories,
  getCustomFields,
  getLocationsForCreateScreen,
  getLooseItem,
  setCustomColumns,
  updateLooseItem
} from 'actions/looseItemsAndPartsListActions';

import { setUnsavedFormData } from 'actions/commonActions';

export function useLooseItemActions() {
  const dispatch = useDispatch();

  const getItemAction = (id) => dispatch(getLooseItem(id));
  const createItemAction = (data, q) => dispatch(createItem(data, q));
  const updateLooseItemAction = (data) => dispatch(updateLooseItem(data));
  const deleteItemAction = (id) => dispatch(deleteLooseItem(id));
  const clearStateAction = () => dispatch(clearState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getLocationListAction = (query) => dispatch(getLocationsForCreateScreen(query));
  const getCategoriesAction = (query, flag) => dispatch(getCategories(query, flag));
  const getLooseItemFieldsAction = (query, options) => dispatch(getCustomFields(query, options));

  const setCustomColumnsAction = (arr) => dispatch(setCustomColumns(arr));

  return {
    getItemAction,
    createItemAction,
    updateLooseItemAction,
    deleteItemAction,
    clearStateAction,
    setUnsavedFormDataAction,
    getLocationListAction,
    getCategoriesAction,
    getLooseItemFieldsAction,
    setCustomColumnsAction
  };
}
