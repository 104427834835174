import React, { useEffect, useState } from 'react';
import styles from './ManageGroupPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { MainButton } from 'components/StyledComponents';
import { AddFormRow, EditableFormRow } from 'components/EditableComponents';

import {
  CONFIRM_DELETE_CERTIFICATION,
  CONFIRM_DELETE_CERTIFICATION_TYPE,
  DELETE_TRAINING_GROUP
} from 'constants/dialogPopupsData';

import { useTrainingsActions } from 'hooks/Trainings';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

export default function ManageGroupPopup({ open, setOpen }) {
  const [responseReceived, setReceived] = useState(false);
  const [groups, setGroups] = useState([]);

  const [modalData, setModalData] = useState({});

  const { getGroupsAction, createGroupAction, updateGroupAction, deleteGroupAction } =
    useTrainingsActions();

  const getGroups = () =>
    getGroupsAction({ isGroup: true }).then((res) => {
      setGroups(res);
    });

  useEffect(() => {
    if (!open) return;

    getGroupsAction({ isGroup: true }).then((res) => {
      setGroups(res);
      setReceived(true);
    });
  }, [open]);

  const handleClose = () => setOpen(false);

  const handleEdit = ({ values, setFieldMode }) => {
    updateGroupAction(values).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        setFieldMode('display');
        getGroups();
      }
    });
  };

  const handleRemove = (id) => {
    setModalData({ isOpened: true, deletedId: id, ...DELETE_TRAINING_GROUP });
  };

  const isDuplicate = (groupName, editedGroupId) => {
    const modifyString = (str) => str.trimEnd().toLowerCase();

    const needGroup = groups.find(({ name }) => modifyString(name) === modifyString(groupName));
    return !!(needGroup?.id && needGroup?.id !== editedGroupId);
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    const allowDelete = modalData.type === CONFIRM_DELETE_CERTIFICATION_TYPE;

    deleteGroupAction({ id: modalData.deletedId, allowDelete }).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        getGroups();
        setModalData({});
        return;
      }

      if (res.status === 400) {
        const errorMessage = res?.errors?.Id[0] || '';
        if (errorMessage.indexOf('This parameter is used') >= 0) {
          setModalData({ ...modalData, ...CONFIRM_DELETE_CERTIFICATION });
        }
      }
    });
  };

  const addGroup = ({ value, setFieldValue, setFieldError }) => {
    const payload = { editable: true, group: true, name: value };

    createGroupAction(payload).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        setFieldValue('');
        getGroups();
      }

      if (res.status === 400 && res?.errors && !!res?.errors?.Name?.length) {
        setFieldError(res.errors.Name[0]);
        return;
      }
    });
  };

  return (
    responseReceived && (
      <DialogWrapper open={open} onClose={handleClose}>
        <div className={styles.popup}>
          <DialogHeader onClose={handleClose} title="Manage Group" />
          <div className={styles.content}>
            {groups.map((group) => (
              <EditableFormRow
                key={group.id}
                data={group}
                label="name"
                max={60}
                isDuplicate={isDuplicate}
                duplicateError="Group with such name exists"
                onEdit={handleEdit}
                onRemove={handleRemove}
              />
            ))}
            <AddFormRow
              placeholder="Add new group"
              max={60}
              isDuplicate={isDuplicate}
              duplicateError="Group with such name exists"
              onAdd={addGroup}
            />
          </div>
          <div className={styles.footer}>
            <MainButton text="Close" action={handleClose} type="secondary" />
          </div>
        </div>
        <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      </DialogWrapper>
    )
  );
}
