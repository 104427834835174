import React, { useMemo } from 'react';
import styles from './Map.module.scss';
import PropTypes from 'prop-types';

import { Marker, Popup } from 'react-leaflet';
import { icon } from 'leaflet';

const generateMarker = (iconUrl) =>
  icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
  });

/**
 * @typedef {Object} MarkerProps
 * @property {[number, number]} position
 * @property {string} icon
 * @property {{}} info
 */

/**
 * @param {MarkerProps} props
 */

export function MapMarker({ position, icon, info, popupContent }) {
  const markerIcon = useMemo(() => generateMarker(icon), [icon]);

  return (
    <Marker position={position} icon={markerIcon}>
      {typeof popupContent === 'function' ? (
        <Popup className={styles.popup}>{popupContent(info)}</Popup>
      ) : null}
    </Marker>
  );
}

MapMarker.propTypes = {
  position: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  info: PropTypes.object
};
