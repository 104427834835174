import {
  SET_ROOT_CAUSES_FILTER,
  SET_ROOT_CAUSE_CREATION_FORM,
  CLEAR_ROOT_CAUSES_STATE
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  creationForm: {}
};

const ticketRootCausesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ROOT_CAUSES_FILTER:
      return { ...state, filter: payload };
    case SET_ROOT_CAUSE_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_ROOT_CAUSES_STATE:
      return {
        ...initialState,
        filter: state.filter
      };
    default:
      return state;
  }
};

export default ticketRootCausesReducer;
