import { createSelector } from 'reselect';

const userData = (state) => state.userData;

export const selectSingleUserData = () => createSelector([userData], (state) => state.currentUser);

export const selectUserRoleListData = () =>
  createSelector([userData], (state) => state.userRoleList);

export const selectActiveLocationsData = () =>
  createSelector([userData], (state) => state.activeLocations);

export const selectDepartmentsData = () => createSelector([userData], (state) => state.departments);

export const selectUserListFilter = () => createSelector([userData], (state) => state.filter);

export const selectUserListFilterCriteria = () =>
  createSelector([userData], (state) => state.filterCriteria);

export const selectUserEditTab = () => createSelector([userData], (state) => state.userEditTab);

export const selectUserPrefixList = () => createSelector([userData], (state) => state.prefixList);
