import React from 'react';
import styles from './AddBomTable.module.scss';

export default function FieldValidationMessage({ errorText }) {
  return (
    !!errorText && (
      <div className={styles.error}>
        <span>{errorText}</span>
      </div>
    )
  );
}
