import React from 'react';
import styles from './Map.module.scss';
import PropTypes from 'prop-types';

import clsx from 'clsx';

export const MapSidebar = ({ className, children }) => {
  return <div className={clsx(className && className, styles.sidebar)}>{children}</div>;
};

MapSidebar.propTypes = {
  className: PropTypes.string
};

MapSidebar.defaultProps = {
  className: ''
};
