import React from 'react';
import styles from './Form.module.scss';

import GeneralDetails from './Details/General';
import LicenseDetails from './Details/License';
import PrefixDetails from './Details/Prefix';

import { useFormContext } from 'react-hook-form';

import { isLicenseBlockAvailable } from '../../helpers';

export default function Form() {
  const { watch } = useFormContext();

  const prefixWatcher = watch('assetPrefix');

  return (
    <div className={styles.form}>
      <GeneralDetails />
      {!!prefixWatcher?.prefixType?.id && !!prefixWatcher?.assetFields?.length && <PrefixDetails />}
      {isLicenseBlockAvailable(prefixWatcher?.prefixType?.name) && <LicenseDetails />}
    </div>
  );
}
