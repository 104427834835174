import React from 'react';
import styles from './ListItemBase.module.scss';
import clsx from 'clsx';

export function ListItemBase({ icon, label, isActive, onClick, isInCollapse, children }) {
  return (
    <div
      className={clsx(styles.item, isActive && styles.active, isInCollapse && styles.inCollapse)}
      onClick={onClick}>
      <div className={styles.item__icon}>{icon && <img src={icon} alt="list-item" />}</div>

      <div className={styles.item__label}>{label}</div>

      <div className={styles.item__controls}>{children}</div>
    </div>
  );
}
