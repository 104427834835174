import { createSelector } from 'reselect';

const data = (state) => state.overviewReportData;

export const selectDashboardFieldsData = () =>
  createSelector([data], (state) => state.dashboardData);

export const selectPrefilterData = () => createSelector([data], (state) => state.prefilter);

export const selectPrefixesData = () => createSelector([data], (state) => state.prefixes);

export const selectFieldsData = () => createSelector([data], (state) => state.fields);
