import React from 'react';
import {
  Title,
  Subtitle,
  TextInfo,
  DateField,
  TextAreaField,
  TextInputField
} from './AssetTemplateFields';

import { ASSET_REPORT_VALIDATION_RULES } from '../validationRules';

export default function AssetTemplateSection({ field, index, onSave, onBlur, onFocus }) {
  const isFieldRequired = field.data.required;

  const commonName = `generalReportFields[${index}].data.templates`;

  const getFieldSymbolLimit = (fieldId) =>
    ASSET_REPORT_VALIDATION_RULES.find(({ id }) => id === fieldId)?.maxSymbols || 0;
  const getFieldRegexp = (fieldId) =>
    ASSET_REPORT_VALIDATION_RULES.find(({ id }) => id === fieldId)?.regexp;

  return (
    <>
      {field.data?.templates?.length &&
        field.data.templates
          .sort((a, b) => a.order - b.order)
          .map((item, fieldIndex) => (
            <React.Fragment key={fieldIndex}>
              {(() => {
                switch (item.type) {
                  case 'Title':
                    return <Title isRequired={isFieldRequired}>{item.text}</Title>;
                  case 'Subtitle':
                    return <Subtitle>{item.text}</Subtitle>;
                  case 'TextInfo':
                    return <TextInfo label={item.label}>{item.value}</TextInfo>;
                  case 'Date':
                    return (
                      <DateField
                        name={commonName + `[${fieldIndex}].value`}
                        label={item.label}
                        isRequired={isFieldRequired}
                        onSave={onSave}
                      />
                    );
                  case 'Textfield':
                    return (
                      <TextInputField
                        name={commonName + `[${fieldIndex}].value`}
                        max={getFieldSymbolLimit(item.id)}
                        rule={getFieldRegexp(item.id)}
                        label={item.label}
                        isRequired={isFieldRequired}
                        onBlur={onBlur}
                        onFocus={onFocus}
                      />
                    );
                  case 'Textarea':
                    return (
                      <TextAreaField
                        name={commonName + `[${fieldIndex}].value`}
                        label={item.label}
                        max={getFieldSymbolLimit(item.id)}
                        rule={getFieldRegexp(item.id)}
                        isRequired={isFieldRequired}
                        onBlur={onBlur}
                        onFocus={onFocus}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </React.Fragment>
          ))}
    </>
  );
}
