import React, { useState } from 'react';
import styles from './AddCapacityField.module.scss';

import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';

import clsx from 'clsx';

import { Button } from '@material-ui/core';

export function AddCapacityField({ options, onAdd }) {
  const [items, setItems] = useState([]);

  const selectCapacity = (_, value) => setItems(value);

  const handleAddClick = () => {
    onAdd(items);
    setItems([]);
  };

  return (
    <div className={styles.adding}>
      <div className={styles.adding__field}>
        <AutocompleteWithSelectAll
          label="name"
          value={items || []}
          options={options || []}
          onSelect={selectCapacity}
          selectAll={false}
          disableByObjectTracker={false}
        />
      </div>
      <Button
        className={clsx(styles.adding__button, !items?.length && styles.disabled)}
        onClick={handleAddClick}>
        + Add
      </Button>
    </div>
  );
}
