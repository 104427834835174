import React from 'react';
import styles from './SidebarWrapper.module.scss';

import { Drawer, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useMobileViewport } from 'hooks/useMobileViewport';

import clsx from 'clsx';

export function SidebarWrapper({ open, setOpen, children }) {
  const isMobile = useMobileViewport();

  const openSidebar = () => setOpen(true);
  const closeSidebar = () => setOpen(false);

  return (
    <div className={styles.wrapper}>
      {isMobile && (
        <IconButton className={styles.toggle} onClick={openSidebar}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            styles.paper,
            open && styles.opened,
            !open && styles.closed,
            !isMobile && !open && styles.desktopClosed,
            isMobile && !open && styles.mobileClosed
          )
        }}
        onMouseEnter={openSidebar}
        onMouseLeave={closeSidebar}>
        {children}
      </Drawer>
    </div>
  );
}
