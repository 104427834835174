import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getUserListApi = (query) =>
  api.get(`/Users?${queryToString(query)}`).then((res) => res.data);

export const getSingleUserApi = (id) => api.get(`/User?id=${id}`).then((res) => res.data);

export const getUserRoleListApi = () => api.get('/UserRoles').then((res) => res.data);

export const getDepartmentsApi = () => api.get('/TeamMemberDepartments').then((res) => res.data);

export const getPrefixListApi = (query) =>
  api.get(`/AssetPrefixes?${queryToString(query)}`).then((res) => res.data);

export const getRequestOrderRolesApi = () => api.get('/RequestOrderRoles').then((res) => res.data);

export const updateUserApi = (params) => api.put(`/User`, params).then((res) => res.data);

export const deleteUserApi = (id) => api.delete(`/User?id=${id}`).then((res) => res.data);

export const getFilterCriteriaApi = () => api.get('/Users/FilterCriteria').then((res) => res.data);
