import React from 'react';
import styles from './AssetContents.module.scss';

import Subtitle from 'components/DetailsComponents/Subtitle';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { useAssetSelector } from 'hooks/Asset';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

export default function AssetContents() {
  const { currentAsset } = useAssetSelector();
  const { assetPrefix, contents } = currentAsset;

  const forbiddenStatuses = ['Containable'];
  const areContentsAllowed = () => !forbiddenStatuses.includes(assetPrefix?.prefixType?.name);

  return (
    areContentsAllowed() && (
      <div className={styles.wrapper}>
        <Subtitle>Contents</Subtitle>
        {!!contents?.length && (
          <div className={styles.contents}>
            {contents.map((asset, index) => (
              <React.Fragment key={index}>
                <LinkComponent
                  name={asset?.drCode || ''}
                  path={`${ASSET_DETAILED_PATH}/${asset?.id}`}
                />
                {index < contents.length - 1 && ', '}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    )
  );
}
