import { useSelector } from 'react-redux';
import { selectFilterData } from 'pages/PicklistAndLeavelist/LeavelistSummary/selectors';

export function useLeavelistSelector() {
  const filter = useSelector(selectFilterData());

  return {
    filter
  };
}
