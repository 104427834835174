import api from '../api';
import { API_BASE_ADDRESS } from 'api/api';
import { queryToString } from 'helpers/AppHelpers';

export const getUserConfigApi = () => api.get(`/User/Config`).then((res) => res.data);

export const updateUserConfigApi = (params) =>
  api.put(`/User/Config`, params).then((res) => res.data);

export const UPLOAD_IMG_URL = `${API_BASE_ADDRESS}Resource?resourceTypeId=1`;
export const UPLOAD_FILE_URL = `${API_BASE_ADDRESS}Resource?resourceTypeId=2`;
export const UPLOAD_VIDEO_URL = `${API_BASE_ADDRESS}Resource?resourceTypeId=3`;

export const deleteFileApi = (id) => api.delete(`/Resource?id=${id}`).then((res) => res);

export const updateImageResourceApi = (data) => api.put('/Resource', data).then((res) => res);

export const getResourceViaBytesApi = (data) =>
  api.post('/GeneralReport/Signature', data).then((res) => res);

export const searchAssetsApi = (query) =>
  api.get(`/Asset/Search?${queryToString(query)}`).then((res) => res.data);

export const searchUsersApi = (query) =>
  api.get(`/User/Search?${queryToString(query)}`).then((res) => res.data);

export const scanAssetApi = (id) => api.get(`/Asset?id=${id}`).then((res) => res.data);
