import { createSelector } from 'reselect';

const data = (state) => state.requestOrderData;

export const selectFilterData = () => createSelector([data], (state) => state.listFilter);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

export const selectCurrentRequestOrderData = () =>
  createSelector([data], (state) => state.currentRequest);

export const selectRequestOrderCreationFormData = () =>
  createSelector([data], (state) => state.creationForm);
