import React from 'react';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import styles from './HeadRows.module.scss';
import NoteCell from './NoteCell';
import { useTrackerSelector } from 'hooks/TeamMemberTracker';
import clsx from 'clsx';

export default function NoteRow() {
  const { trackerTableData } = useTrackerSelector();
  const { header = [] } = trackerTableData;

  return (
    <StyledTableRow>
      <StyledTableCell className={clsx(styles.initialCell, styles.note)}></StyledTableCell>
      {header.map((cell, index) => (
        <NoteCell cell={cell} key={index} />
      ))}
    </StyledTableRow>
  );
}
