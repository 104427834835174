import React, { useEffect, useState } from 'react';
import styles from './MovedLooseItems.module.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectMovedLooseItemsData } from './selectors';
import LooseItemsTable from './LooseItemsTable/LooseItemsTable';
import { enqueueErrorSnackbar, getRandomId } from 'helpers/AppHelpers';
import { getLocationsWithSublocations } from 'actions/looseItemsAndPartsListActions';
import { MoveLooseItemsPopup } from 'components/Popups';
import MainButton from 'components/StyledComponents/MainButton';
import {
  clearMovedItemsState,
  getLooseItemsLocations,
  moveItems
} from 'actions/looseItemDashboardActions';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  CANCEL_THE_MOVING_LOOSE_ITEMS,
  EXIT_WITHOUT_SAVING,
  MOVE_LOOSE_ITEMS,
  MOVE_LOOSE_ITEMS_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { LOOSE_ITEM_DASHBOARD, LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import {
  isBaaQtyMoreThanMove,
  isBaaQtyValid,
  isDestinationEmpty,
  isDestinationValid,
  isMoveQtyValid,
  shouldItemsBeMoved
} from './helpers';
import { setUnsavedFormData } from 'actions/commonActions';
import { WARNING_LOOSE_ITEMS_WILL_BE_MOVED } from 'constants/infoSnackbarData';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { BlueButton } from 'components/Buttons';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import { saveMovedItems } from 'actions/moveLooseItemsActions';

function MovedLooseItems({
  movedLooseItems,
  getLocationListAction,
  getLooseItemsLocationsAction,
  moveItemsAction,
  setUnsavedFormDataAction,
  saveMovedItemsAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const [warnings, setWarnings] = useState({});
  const [firstWarningsCheck, setFirstWarningsCheck] = useState(true);

  const [options, setOptions] = useState({});
  const [dialogModalData, setDialogModalData] = useState({ isOpened: false });

  const [openMovePopup, setOpenMovePopup] = useState(false);

  const clearValidations = () => {
    setValidationErrors({});
    setWarnings({});
    setFirstWarningsCheck(true);
  };

  useEffect(() => {
    clearValidations();
    getLooseItemsLocationsAction({
      filters: {
        looseItemIds: [...movedLooseItems.map(({ looseItem }) => looseItem.id)],
        statusNames: ['Available']
      }
    }).then((res) => {
      const findLocation = (itemId) => res.find(({ id }) => id === itemId);

      setOptions({ ...options, itemsLocations: res });
      setTableData([
        ...movedLooseItems.map((el) => {
          return {
            ...el,
            rowId: getRandomId(),
            fakeLocation: findLocation(el?.id)
          };
        })
      ]);
    });
  }, [movedLooseItems]);

  useEffect(() => {
    if (!options?.locations?.length) {
      getLocationListAction({ filters: { active: true, includeUserCheck: true } }, true).then(
        (res) => setOptions({ ...options, locations: res })
      );
    }
  }, [options]);

  useEffect(() => {
    if (!movedLooseItems.length) {
      history.push(LOOSE_ITEM_DASHBOARD);
    }
  }, []);

  useEffect(() => {
    setUnsavedFormDataAction(true);
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [tableData]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const { locations, itemsLocations } = options;

  const formIsInvalid = () => {
    let isInvalid = false;
    for (const key in validationErrors) {
      if (Object.values(validationErrors[key]).filter(Boolean).length) isInvalid = true;
    }
    return isInvalid;
  };

  const onAgree = () => {
    if (dialogModalData.type === MOVE_LOOSE_ITEMS_TYPE) {
      moveItemsAction(prepareData());
    } else if (dialogModalData.type === WITHOUT_SAVING_TYPE) {
      history.push(`${LOOSE_ITEM_DETAILED_PATH}/${dialogModalData.itemId || ''}`);
    } else {
      history.goBack();
    }
  };

  const onCancel = () => {
    setDialogModalData({ isOpened: true, ...CANCEL_THE_MOVING_LOOSE_ITEMS });
  };

  const prepareData = () => {
    return { moveLooseItems: [...tableData] };
  };

  const validateForm = () => {
    let errors = { ...validationErrors };
    tableData.forEach((el) => {
      errors = {
        ...errors,
        [el.rowId]: {
          ...errors[el.rowId],
          destinationLocation: isDestinationEmpty(el.destinationLocation)
            ? 'The “Destination” value is required.'
            : isDestinationValid(
                el?.location,
                el?.sublocation,
                el?.destinationLocation,
                el?.destinationSublocation
              )
            ? ''
            : 'The “Destination” and “Sublocation” values must be different from “Current Location(Sublocation)” value',
          moveQuantity: isMoveQtyValid(el.quantity, el.moveQuantity)
            ? ''
            : 'The “From BAA* QTY” value must not exceed “Available QTY(BAA*)” value in the brackets.',
          moveByAheadAccount: !isBaaQtyValid(el?.byAheadAccount || 0, el?.moveByAheadAccount || 0)
            ? 'The “Move BAA* QTY” value must not exceed “Available QTY(BAA*)” value in the brackets.'
            : isBaaQtyMoreThanMove(el?.moveByAheadAccount || 0, el?.moveQuantity || 0)
            ? '“From BAA*” value must not exceed “Move Total QTY”'
            : ''
        }
      };
    });
    setValidationErrors(errors);
    let isValid = true;
    for (const key in errors) {
      if (Object.values(errors[key]).filter(Boolean).length) isValid = false;
    }
    return isValid;
  };

  const areWarningsExist = () => {
    let warns = { ...warnings };
    tableData.forEach((el) => {
      warns = {
        ...warns,
        [el.rowId]: shouldItemsBeMoved(
          el?.moveQuantity || 0,
          el?.moveByAheadAccount || 0,
          el?.quantity || 0,
          el?.byAheadAccount || 0
        )
          ? 'Loose items from Buy Ahead Account will be moved with Move Total QTY loose items.'
          : ''
      };
    });
    setWarnings(warns);
    let isWarning = false;
    for (const key in warns) {
      if (Object.values(warns[key]).filter(Boolean).length) isWarning = true;
    }
    return isWarning;
  };

  const onCreate = () => {
    if (!validateForm()) {
      return;
    }
    if (areWarningsExist() && firstWarningsCheck) {
      setFirstWarningsCheck(false);
      enqueueErrorSnackbar(WARNING_LOOSE_ITEMS_WILL_BE_MOVED);
      return;
    }
    setDialogModalData({ isOpened: true, ...MOVE_LOOSE_ITEMS });
  };

  const handleItemClick = (id) => {
    setDialogModalData({ isOpened: true, ...EXIT_WITHOUT_SAVING, itemId: id });
  };

  const saveMovedLooseItems = (items) => saveMovedItemsAction(items);

  return (
    <section className={styles.pageContainer}>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={() => setDialogModalData({ isOpened: false })}
      />

      {openMovePopup && (
        <MoveLooseItemsPopup
          open={openMovePopup}
          setOpen={setOpenMovePopup}
          screen="Move Loose Items"
          movedItems={tableData}
          onMove={saveMovedLooseItems}
        />
      )}

      <section className={styles.pageContent}>
        <div className={styles.pageHeader}>
          <h1>Move Loose Items</h1>
        </div>
        <div className={styles.mainButton}>
          {isMobile && (
            <MainButton
              text="move"
              type="primary"
              action={onCreate}
              isDisabled={!!formIsInvalid()}
            />
          )}
        </div>
        <LooseItemsTable
          tableData={tableData}
          locations={locations}
          itemsLocations={itemsLocations}
          setTableData={setTableData}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          warnings={warnings}
          setWarnings={setWarnings}
          isMobile={isMobile}
          handleItemClick={handleItemClick}
        />
        <BlueButton
          className={clsx(styles.add_btn, !!formIsInvalid() && styles.disabled)}
          onClick={() => setOpenMovePopup(true)}>
          <AddIcon />
        </BlueButton>
      </section>
      <section className={styles.footerControls}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        {!isMobile && (
          <MainButton text="move" type="primary" action={onCreate} isDisabled={!!formIsInvalid()} />
        )}
      </section>
    </section>
  );
}
const mapStateToProps = createStructuredSelector({
  movedLooseItems: selectMovedLooseItemsData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getLocationListAction: getLocationsWithSublocations,
  getLooseItemsLocationsAction: getLooseItemsLocations,
  moveItemsAction: moveItems,
  saveMovedItemsAction: saveMovedItems,
  clearStateAction: clearMovedItemsState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MovedLooseItems);
