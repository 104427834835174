import React from 'react';
import styles from './components.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';

function CommonDetailsForm() {
  const { control, formState, setValue } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = errors?.dateOfShipment?.message || errors?.jobNumber?.message;

  const handleCellPhoneType = (name, value) => {
    if (formState?.touchedFields?.driverPhoneNumber) return;
    setValue('driverPhoneNumber', value);
  };

  const handleTruckSelect = (name, value) => {
    setValue(name, value);
    setValue('truckNumber', value?.drCode === 'Third party truck' ? '' : value?.drCode || '');
  };

  return (
    <AccordionWrapper title="Common Details" isFormInvalid={isFormInvalid}>
      <div className={styles.common}>
        <div className={styles.common__block}>
          <div className={styles.common__block_row}>
            <label>Freight Bill</label>
            <FormInputText name="freightBill" options={{ max: 100 }} />
          </div>
          <div className={styles.common__block_row}>
            <label>Date of Shipment*</label>
            <FormDatePicker name="dateOfShipment" />
          </div>
          <div className={styles.common__block_row}>
            <label>Name of Carrier/DOT#</label>
            <FormInputText name="nameOFCarrierOrDOTNumber" options={{ max: 100 }} />
          </div>
        </div>
        <div className={styles.common__block}>
          <div className={styles.common__block_row}>
            <label>Driver Phone Number</label>
            <FormInputText
              name="cellPhone"
              options={{ max: 100, extraAction: handleCellPhoneType }}
            />
          </div>
          <div className={styles.common__block_row}>
            <label>Truck Number</label>
            <FormServerAssetSearch
              name="truck"
              max={100}
              onSelect={handleTruckSelect}
              getLabel={(option) => option?.drCode || ''}
              getQuery={(searchQuery) => ({
                pagination: { limit: 39, page: 1 },
                filters: {
                  isDeleted: false,
                  includeThirdPartyTruck: true,
                  equipmentIds: [9, 10],
                  searchQuery
                }
              })}
            />
          </div>
          <Controller
            name="truck"
            control={control}
            render={({ field: { value } }) =>
              value?.drCode === 'Third party truck' ? (
                <div className={styles.common__block_row}>
                  <label>Custom Truck Number</label>
                  <FormInputText name="truckNumber" options={{ max: 100 }} />
                </div>
              ) : null
            }
          />
          <div className={styles.common__block_row}>
            <label>Project Number*</label>
            <FormInputText name="jobNumber" options={{ max: 100 }} />
          </div>
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default CommonDetailsForm;
