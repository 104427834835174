import React, { useEffect } from 'react';

import { useRootCausesActions } from 'hooks/RootCauses';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Wrapper from './Wrapper';
import Form from './Form';

export default function TicketRootCauseEdit() {
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      active: true,
      assetPrefixes: [],
      description: '',
      name: '',
      isSelectedAllAssetPrefixes: false
    },
    mode: 'onChange'
  });
  const { reset } = methods;

  const { getRootCauseAction, getCreationFormAction, clearStateAction } = useRootCausesActions();

  useEffect(() => {
    if (id) {
      getRootCauseAction({ rootCauseId: id }).then((res) => reset(res));
    }
  }, [id]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
