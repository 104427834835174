import React from 'react';
import styles from '../Specifications.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../../selectors';
import SkillChip from '../SkillChip';

export default function Departments() {
  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const { departments } = currentTeamMember;

  return (
    <div className={styles.specification}>
      <label>Department:</label>
      <div className={styles.specification__block}>
        {!!departments.length &&
          departments.map((item) => <SkillChip key={item.id} label={item.name || ''} />)}
      </div>
    </div>
  );
}
