import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import DeliveryItemsTable from './DeliveryItemsTable';

function VehicleInstructionsDetails({ isMobile, data }) {
  const { tableData, deficiencies, totalWeight } = data;
  return (
    <AccordionWrapper title="Items for Delivery" isMobile={isMobile}>
      <div className={styles.deliveryItems}>
        <DeliveryItemsTable data={tableData} total={totalWeight} />
        <div className={styles.details}>
          <div className={styles.details__record}>
            <div className={styles.details__record_item}>
              <label>Deficiencies upon delivery:</label>
              <span>{deficiencies}</span>
            </div>
          </div>
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default VehicleInstructionsDetails;
