import React, { useEffect, useState } from 'react';
import styles from './FormInputRadio.module.scss';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import StyledRadio from 'components/StyledComponents/StyledRadio';
import clsx from 'clsx';

export default function FormInputRadio({
  name,
  label,
  options,
  relatedFieldNames,
  onSelectTriggered,
  classes
}) {
  const [selected, setSelected] = useState('');

  const { control, setValue, clearErrors, formState, getValues } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    if (options?.length) {
      if (getValues(name)?.displayName) {
        setSelected(getValues(name)?.displayName);
      } else {
        setSelected(options[0].displayName);
        setValue(name, options[0]);
      }
    }
  }, [options, getValues(name)]);

  const handleRadioChange = (e, valueDisplayName) => {
    const value = e.target.value;
    errors[name]?.message && clearErrors(name);
    if (relatedFieldNames && relatedFieldNames?.length) {
      relatedFieldNames.forEach((key) => {
        errors[key]?.message && clearErrors(key);
        !!getValues(key) && setValue(key, '');
      });
    }
    const option = options.find(({ displayName }) => displayName === value);
    setValue(name, option, { shouldDirty: true });
    onSelectTriggered && onSelectTriggered(name, option);
    setSelected(valueDisplayName);
  };

  return (
    <FormControl component="fieldset" className={clsx(classes && classes, styles.fieldset)}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={() => (
          <RadioGroup row value={selected} onChange={handleRadioChange}>
            {options.map((singleItem) => (
              <FormControlLabel
                key={singleItem.name}
                value={singleItem.displayName}
                control={<StyledRadio />}
                label={singleItem.displayName}
                classes={{ root: styles.radio, label: styles.label }}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
