import { getDifferenceInDays } from 'helpers/AppHelpers';

const PROJECT_NAME = 'Project';

const CHECK_CLEARANCE_TYPE = 'CHECK_CLEARANCE_TYPE';
const STATUS_REWRITE_TYPE = 'STATUS_REWRITE_TYPE';

const SCREENS = Object.freeze({
  CALENDAR: 'Team Member Details Calendar',
  SCHEDULE: 'Project Schedule'
});

const calculateDayCount = (start, end) => {
  if (!start || !end) return 0;

  const count = getDifferenceInDays(start, end);
  return count < 0 ? count : count + 1;
};

export { PROJECT_NAME, SCREENS, calculateDayCount, CHECK_CLEARANCE_TYPE, STATUS_REWRITE_TYPE };
