import React from 'react';
import styles from './Footer.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

export default function Footer({ onBack, onApprove }) {
  return (
    <div className={styles.footer}>
      <MainButton text="back" type="secondary" action={onBack} />
      <MainButton text="approve" type="primary" action={onApprove} />
    </div>
  );
}
