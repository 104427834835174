import React, { useRef } from 'react';
import styles from './QRCodeGenerator.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import QRLogo from 'assets/images/qrLogo.png';

import { QRCode } from 'react-qrcode-logo';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function QRCodeGenerator({ open, setOpen, fileName }) {
  const isMobile = useMobileViewport();
  const canvasEl = useRef(null);

  const closePopup = () => setOpen(false);

  const downloadQR = () => {
    const link = document.createElement('a');
    link.href = document.getElementById('react-qrcode-logo')?.toDataURL('image/png');
    link.download = `${fileName || window.location.pathname}_QR.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    closePopup();
  };

  const qrParams = {
    ecLevel: 'Q',
    qrStyle: 'dots',
    enableCORS: true,
    size: 320,
    quietZone: 25,
    logoImage: QRLogo,
    logoWidth: 114,
    value: window.location.href
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <div className={styles.header}>
          {isMobile && <h2>QR Code</h2>}
          {!isMobile ? (
            <IconButton className={styles.header__close} onClick={closePopup}>
              <CloseIcon />
            </IconButton>
          ) : (
            <BackButton onCancel={closePopup} />
          )}
        </div>
        <div className={styles.content}>
          <QRCode ref={canvasEl} {...qrParams} />
          <MainButton text="Download" action={downloadQR} type="primary" />
        </div>
      </div>
    </DialogWrapper>
  );
}
