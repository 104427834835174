import React from 'react';
import styles from './QuickAccessButton.module.scss';
import { Button } from '@material-ui/core';

export default function QuickAccessButton({ title, onClick }) {
  const handleClick = () => onClick();

  return (
    <Button className={styles.quickButton} onClick={handleClick}>
      {title}
    </Button>
  );
}
