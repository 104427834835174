import React from 'react';
import styles from './FilterButton.module.scss';

import { IconButton } from '@material-ui/core';

import FilterIcon from 'assets/images/filter.svg';

import clsx from 'clsx';

export default function FilterButton({ onClick, isActive }) {
  return (
    <IconButton className={clsx(styles.filter, isActive && styles.active)} onClick={onClick}>
      <img src={FilterIcon} alt="filter" />
    </IconButton>
  );
}
