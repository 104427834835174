import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormDayPicker, FormLabel } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { enrichNameToId, sortByParam } from 'helpers/AppHelpers';

import { defaultFilter } from '../helpers';

export function Filter({ open, setOpen, onApply, filter, filterCriteria }) {
  const isMobile = useMobileViewport();

  const [sublocationOptions, setSublocationsOptions] = useState([]);

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch } = methods;

  const { locationsWithSublocations, projects, statuses } = filterCriteria;

  const refreshSublocationOptions = (locationIds) => {
    if (locationIds?.length) {
      const options = locationsWithSublocations
        .filter(({ id }) => locationIds.includes(id))
        .flatMap(({ sublocations }) => sublocations);
      const sorted = sortByParam(options, 'name');
      setSublocationsOptions(sorted);
    } else {
      setSublocationsOptions([]);
    }
  };

  useEffect(() => {
    if (open) {
      reset(filter.filters);
      refreshSublocationOptions(filter.filters?.locationIds || []);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const refreshSublocationsValue = () => {
    if (!getValues('sublocationIds')?.length) return;

    if (!getValues('locationIds').length) {
      setValue('sublocationIds', []);
      return;
    }

    const locationIds = getValues('locationIds');
    const sublocationIds = getValues('sublocationIds');

    const availableSublocationIds = locationsWithSublocations
      .filter(({ id }) => locationIds.includes(id))
      .flatMap(({ sublocations }) => sublocations)
      .map(({ id }) => id);

    const filteredSublocations = sublocationIds.filter((id) =>
      availableSublocationIds.includes(id)
    );

    setValue('sublocationIds', filteredSublocations);
  };

  const handleLocationSelect = (_, value) => {
    refreshSublocationsValue();

    refreshSublocationOptions(value);
  };

  const createdFromWatcher = watch('createdFrom');
  const createdToWatcher = watch('createdTo');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locationsWithSublocations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleLocationSelect
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Sublocation</FormLabel>
                <FormAutoMultiSelect
                  name="sublocationIds"
                  menuItems={sublocationOptions || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    isDisabled: !getValues('locationIds')?.length
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locationsWithSublocations || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="statusNames"
                  options={enrichNameToId(statuses) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Project</FormLabel>
                <FormAutoMultiSelect
                  name="projectIds"
                  menuItems={projects || []}
                  options={{
                    labelType: 'project',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created from</FormLabel>
                <FormDayPicker name="createdFrom" max={createdToWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created to</FormLabel>
                <FormDayPicker name="createdTo" min={createdFromWatcher} max={new Date()} />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
