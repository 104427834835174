import { useSelector } from 'react-redux';

import {
  selectActiveProject,
  selectProjectsListData,
  selectSingleLocationData,
  selectSiteTypesListData,
  selectStatesListData
} from 'pages/LocationManagement/LocationSummary/selectors';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export function useLocationSelector() {
  const currentLocation = useSelector(selectSingleLocationData());

  const siteTypes = useSelector(selectSiteTypesListData());
  const statesList = useSelector(selectStatesListData());
  const projectsList = useSelector(selectProjectsListData());
  const activeProject = useSelector(selectActiveProject());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    currentLocation,
    siteTypes,
    statesList,
    projectsList,
    activeProject,
    unsavedFormData
  };
}
