import React from 'react';
import styles from './FormChipsContainer.module.scss';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

export default function FormChipsContainer({
  fieldName,
  ChipComponent,
  direction,
  label,
  getLabel,
  deleteParameter,
  getClasses,
  keyParam
}) {
  const { control, setValue, getValues } = useFormContext();

  const handleChipDelete = (chip) => {
    setValue(
      fieldName,
      getValues(fieldName).filter(
        (el) => el[deleteParameter || 'name'] !== chip[deleteParameter || 'name']
      ),
      { shouldDirty: true }
    );
  };

  const ChipElement = ({ item }) => (
    <Chip
      label={getLabel ? getLabel(item) : item?.[label] || item?.name || ''}
      deleteIcon={<CloseIcon />}
      className={clsx(styles.chips__item, getClasses && getClasses(item))}
      onDelete={() => handleChipDelete(item)}
    />
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value } }) => (
        <>
          {!!value?.length && (
            <div className={clsx(styles.chips, direction === 'column' && styles.flexColumn)}>
              {value.map((item, index) =>
                ChipComponent ? (
                  ChipComponent(
                    item.id,
                    <ChipElement
                      key={item[keyParam] || item.email || item.id || item.keyId}
                      item={item}
                    />,
                    index
                  )
                ) : (
                  <ChipElement
                    key={item[keyParam] || item.email || item.id || item.keyId}
                    item={item}
                  />
                )
              )}
            </div>
          )}
        </>
      )}
    />
  );
}
