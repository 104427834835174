import { createSelector } from 'reselect';

const addLooseItemsPopupData = (state) => state.addLooseItemsPopupData;

export const selectLooseItemsData = () =>
  createSelector([addLooseItemsPopupData], (state) => state.looseItems);

export const selectLocationsData = () =>
  createSelector([addLooseItemsPopupData], (state) => state.locations);

export const selectListOfPicklistData = () =>
  createSelector([addLooseItemsPopupData], (state) => state.listOfPicklist);
