import React from 'react';
import styles from './FilterComponents.module.scss';
import { Fade, Paper, Popper } from '@material-ui/core';

export default function PopperWrapper({ iconRef, open, children }) {
  return (
    <Popper
      open={open}
      anchorEl={() => iconRef.current}
      placement="right-start"
      transition
      className={styles.popper}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper classes={{ root: styles.popper__container }}>{children}</Paper>
        </Fade>
      )}
    </Popper>
  );
}
