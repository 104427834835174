import {
  CLEAR_SEARCH_RESULTS_STATE,
  SET_SEARCH_DATA_LOADED_FLAG,
  SET_SEARCH_QUERY,
  SET_SEARCH_RESULTS
} from 'constants/reduceConstants';

const initialState = {
  searchQuery: '',
  results: {},
  isDataLoaded: null
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: payload };
    case SET_SEARCH_RESULTS:
      return { ...state, results: payload, isDataLoaded: true };
    case SET_SEARCH_DATA_LOADED_FLAG:
      return { ...state, isDataLoaded: payload };
    case CLEAR_SEARCH_RESULTS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default searchReducer;
