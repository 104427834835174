import React from 'react';
import styles from './BodyCells.module.scss';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { getProjectName } from 'helpers/AppHelpers';

export default function ProjectRow({ project }) {
  return (
    <StyledTableRow id="project_row">
      <StyledTableCell className={styles.projectCell} colSpan={10000}>
        <div className={styles.projectBlock}>
          <span>{getProjectName(project)}</span>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}
