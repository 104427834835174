import React from 'react';
import styles from './FormCheckbox.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export default function FormLabeledCheckbox({ name, label, extraAction }) {
  const { control } = useFormContext();

  const handleClick = (e, onChange) => {
    onChange(e);
    extraAction && extraAction(e);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: styles.checkbox, checked: styles.checked }}
              checked={!!value}
              onChange={(e) => handleClick(e, onChange)}
            />
          }
          label={label}
        />
      )}
    />
  );
}
