import { useSelector } from 'react-redux';

import {
  selectIsEditModeData,
  selectEntityData
} from 'pages/PicklistAndLeavelist/ManageLooseItems/selectors';

export function useManageLooseItemsSelector() {
  const entityData = useSelector(selectEntityData());

  const isEditMode = useSelector(selectIsEditModeData());

  return {
    entityData,
    isEditMode
  };
}
