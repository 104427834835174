import { useHistory, useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserConfig } from '../pages/commonSelectors';
import { useEffect } from 'react';
import { PERSONNEL_PROFILE_PATH, ROOT_PATH } from '../constants/routeConstants';

function PersonnelProfileGuard({ component, rolesAllowed }) {
  const history = useHistory();
  const { id } = useParams();
  const { teamMember, userRole } = useSelector(selectUserConfig());

  const suitableRole = rolesAllowed.includes(userRole?.id);
  const isPersonnelProfile = +id === teamMember?.id;

  useEffect(() => {
    if (userRole) {
      if (isPersonnelProfile) {
        history.push(PERSONNEL_PROFILE_PATH);
      } else if (!suitableRole) {
        history.push(ROOT_PATH);
      }
    }
  }, [userRole]);

  return suitableRole ? component : null;
}

export default withRouter(PersonnelProfileGuard);
