import React from 'react';
import styles from './components.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

export default function TotalCount() {
  return (
    <div className={styles.total}>
      <label className={styles.total__label}>Total weight, lbs:</label>
      <FormInputText
        name="totalWeight"
        options={{ focus: true, type: 'number' }}
        classes={styles.total__field}
      />
    </div>
  );
}
