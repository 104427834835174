import React from 'react';
import styles from './Header.module.scss';

import { useAssetSelector } from 'hooks/Asset';

export default function AssetTitle() {
  const { currentAsset } = useAssetSelector();
  const { drCode, assetPrefix } = currentAsset;

  return (
    <div className={styles.header}>
      <div className={styles.title}>{drCode || ''}</div>
      <div className={styles.subtitle}>{assetPrefix?.description || ''}</div>
    </div>
  );
}
