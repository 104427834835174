import React, { useEffect, forwardRef, useState, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Checkbox,
  Slide,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { MainButton } from 'components/StyledComponents/';
import styles from './DashboardAddTablePopup.module.scss';
import clsx from 'clsx';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BackButton from 'components/BackButton/BackButton';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DashboardAddTablePopup({
  isMobile,
  open,
  setOpen,
  tablesList,
  deletedTablesList,
  onViewChange
}) {
  const [selectedTables, setSelectedTables] = useState([]);
  const [popupData, setPopupData] = useState([]);

  const tableListIds = useMemo(() => tablesList?.map(({ id }) => id) || [], [tablesList, open]);

  const clearModal = () => {
    setOpen(false);
    setPopupData([]);
  };

  useEffect(() => {
    if ((tablesList.length || deletedTablesList.length) && open) {
      const selected = [
        ...deletedTablesList
          .filter(({ isHidden }) => !isHidden)
          .map(({ looseItemCategory }) => looseItemCategory.id)
      ];
      setSelectedTables(selected);
      setPopupData(deletedTablesList);
    }
  }, [open]);

  const onAgree = () => {
    const newState = popupData.map((el) => ({
      ...el,
      isHidden: !selectedTables.includes(el.looseItemCategory.id)
    }));
    onViewChange(newState);
    clearModal();
  };

  const onDismiss = () => clearModal();

  const onChange = (e) => {
    if (e.target.name === 'all') {
      const tables = selectedTables.length === popupData.length ? [] : [...tableListIds];
      setSelectedTables(tables);
    } else {
      const newSelected = selectedTables.includes(+e.target.name)
        ? [...selectedTables.filter((tableId) => tableId !== +e.target.name)]
        : [...selectedTables, +e.target.name];
      setSelectedTables(newSelected);
    }
  };

  const swap = (indexA, indexB) => {
    const arrayCopy = [...popupData];
    const temp = arrayCopy[indexA];
    arrayCopy[indexA] = arrayCopy[indexB];
    arrayCopy[indexB] = temp;
    setPopupData(
      arrayCopy.map((table, index) => {
        return { ...table, order: index };
      })
    );
  };

  const upClickHandle = (tableIndex) => {
    if (tableIndex === 0) return;
    swap(tableIndex, tableIndex - 1);
  };

  const downClickHandle = (tableIndex) => {
    if (tableIndex === popupData.length - 1) return;
    swap(tableIndex, tableIndex + 1);
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.popupContainer }}
      BackdropProps={{ classes: { root: styles.backDrop } }}
      TransitionComponent={Transition}
      disableBackdropClick
      keepMounted
      onClose={() => setOpen(false)}>
      <DialogTitle classes={{ root: styles.popupTitle }}>
        <div className={styles.headerTitle}>
          <h2>Add table</h2>
          {isMobile && <BackButton onCancel={onDismiss} />}
        </div>
      </DialogTitle>

      <DialogContent classes={{ root: styles.popupText }}>
        <FormControl className={styles.formControl}>
          <section className={styles.formCell}>
            <FormControlLabel
              control={
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  name="all"
                  onChange={onChange}
                  value={selectedTables.length === popupData.length}
                  checked={selectedTables.length === popupData.length}
                />
              }
              label="Select all"
            />
          </section>
          {popupData.map((table, tableIndex) => (
            <section key={tableIndex} className={styles.formCell}>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ root: styles.checkbox, checked: styles.checked }}
                    name={table.looseItemCategory.id}
                    onChange={onChange}
                    value={!!selectedTables.includes(+table.looseItemCategory.id)}
                    checked={!!selectedTables.includes(+table.looseItemCategory.id)}
                  />
                }
                label={table.looseItemCategory.name}
              />
              <div className={styles.sortControls}>
                <span
                  onClick={() => upClickHandle(tableIndex)}
                  className={clsx(styles.upButton, tableIndex === 0 && styles.disabled)}>
                  <ExpandMore />
                </span>
                <span
                  onClick={() => downClickHandle(tableIndex)}
                  className={clsx(
                    styles.downButton,
                    tableIndex === popupData.length - 1 && styles.disabled
                  )}>
                  <ExpandMore />
                </span>
              </div>
            </section>
          ))}
        </FormControl>
      </DialogContent>

      <DialogActions classes={{ root: styles.popupControls }}>
        <MainButton text="Cancel" action={onDismiss} type="secondary" size="popup" />
        <MainButton text="Update dashboard" action={onAgree} type="primary" size="popup" />
      </DialogActions>
    </Dialog>
  );
}

export default DashboardAddTablePopup;
