import { createSelector } from 'reselect';

const projectData = (state) => state.projectData;

export const selectProjectListData = () =>
  createSelector([projectData], (state) => state.projectList);

export const selectProjectsFilter = () =>
  createSelector([projectData], (state) => state.projectFilter);

export const selectSingleProjectData = () =>
  createSelector([projectData], (state) => state.currentProject);

export const selectProjectStatesData = () =>
  createSelector([projectData], (state) => state.states);
