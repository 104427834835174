const colors = {
  lineColor1: '#A8D5FF',
  lineColor2: '#7EC37E',
  lineColor3: '#FFD83B',
  lineColor4: '#EEA6A2',
  strokeColor: '#fff',
  dataLabelColor: '#000',
  gridLineColor: '#DCDCDC'
};

export const getVerticalBarOptions = (
  fileName,
  legendPosition,
  columnWidth,
  externalColors,
  hideToolbar
) => {
  return {
    chart: {
      type: 'bar',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      toolbar: {
        show: !hideToolbar,
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        dataLabels: {
          position: 'top'
        },
        columnWidth: columnWidth || '85%'
      }
    },
    colors: externalColors || [
      colors.lineColor1,
      colors.lineColor2,
      colors.lineColor3,
      colors.lineColor4
    ],
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '10px',
        colors: [colors.dataLabelColor]
      },
      formatter: (val) => val || ''
    },
    legend: {
      position: legendPosition || 'right',
      horizontalAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      markers: {
        width: 8,
        height: 8,
        radius: 50
      },
      showForSingleSeries: true
    },
    stroke: { show: true, width: 2, colors: [colors.strokeColor] },
    tooltip: { shared: true, intersect: false },
    grid: {
      show: true,
      borderColor: colors.gridLineColor,
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        }
      }
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          opacity: 0.4,
          fontWeight: 400
        }
      }
    },
    responsive: [
      {
        breakpoint: 1181,
        options: {
          legend: {
            position: 'top'
          }
        }
      },
      {
        breakpoint: 700,
        options: {
          stroke: { width: 1 }
        }
      },
      {
        breakpoint: 390,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '88%'
            }
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                rotateAlways: true,
                hideOverlappingLabels: false
              }
            }
          }
        }
      },
      {
        breakpoint: 370,
        options: {
          legend: {
            offsetX: 20
          }
        }
      },
      {
        breakpoint: 361,
        options: {
          legend: {
            width: 300,
            horizontalAlign: 'left'
          }
        }
      }
    ]
  };
};
