import React, { useRef, useState } from 'react';
import styles from './FileListPopper.module.scss';

import { Button, ClickAwayListener, Fade, IconButton, Paper, Popper } from '@material-ui/core';

import { fileDownload } from 'helpers/AppHelpers';

import DotsIcon from 'assets/images/moreDots.svg';

export function FileListPopper({ resources }) {
  const files = resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  if (!files?.length) return null;

  const elRef = useRef();
  const [open, setOpen] = useState(false);

  const show = () => setOpen(true);
  const hide = () => setOpen(false);

  const onFileDownload = (file) => {
    fileDownload(file);
    hide();
  };

  return (
    <div className={styles.wrapper}>
      <Button ref={elRef} onClick={show} className={styles.btn}>
        <img src={DotsIcon} alt="dots" />
      </Button>

      <Popper open={open} anchorEl={() => elRef.current} transition placement="bottom-end">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper classes={{ root: styles.paper }}>
              <ClickAwayListener onClickAway={hide}>
                <div className={styles.files}>
                  {files.map((file) => (
                    <IconButton
                      className={styles.files_item}
                      key={file.id}
                      onClick={() => onFileDownload(file)}>
                      {file.name}
                    </IconButton>
                  ))}
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
