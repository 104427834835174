import React, { useEffect, useRef, useState } from 'react';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import styles from './LooseItemCreateWrapper.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import {
  CREATE_NEW_ITEM,
  CREATE_NEW_ITEM_TYPE,
  EXIT_WITHOUT_SAVING,
  THE_LOOSE_ITEM_ALREADY_EXIST,
  THE_LOOSE_ITEM_ALREADY_EXIST_TYPE,
  UPDATE_LOOSE_ITEM,
  UPDATE_LOOSE_ITEM_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { LOOSE_ITEM_DETAILED_PATH, LOOSE_ITEMS_LIST_PATH } from 'constants/routeConstants';
import { useHistory, useParams } from 'react-router-dom';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import MainButton from 'components/StyledComponents/MainButton';
import { useFormContext } from 'react-hook-form';
import { enqueueErrorSnackbar, getBackendErrors } from 'helpers/AppHelpers';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { ERROR_LOOSE_ITEM_VALIDATE_TABLE } from 'constants/infoSnackbarData';

export default function LooseItemCreateWrapper({ children }) {
  const { id } = useParams();
  const isMobile = useMobileViewport();
  const history = useHistory();

  const breadcrumbs = useRef([{ name: 'Add new item' }]);

  const { formState, setError, getValues } = useFormContext();
  const { isDirty, errors } = formState;

  const {
    setUnsavedFormDataAction,
    createItemAction,
    updateLooseItemAction,
    clearStateAction,
    getLocationListAction,
    getCategoriesAction,
    getLooseItemFieldsAction
  } = useLooseItemActions();
  const { unsavedFormData, currentLooseItem } = useLooseItemSelector();

  const [dialogModalData, setDialogModalData] = useState({});

  useEffect(() => {
    if (id && currentLooseItem?.id) {
      breadcrumbs.current = [
        {
          path: `${LOOSE_ITEM_DETAILED_PATH}/${id}`,
          name: currentLooseItem?.name || ''
        },
        { name: 'Edit loose item' }
      ];
    }
  }, [currentLooseItem]);

  useEffect(() => {
    getLocationListAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    getCategoriesAction('', true);
  }, []);

  useEffect(() => {
    getLooseItemFieldsAction();
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const handleBackClick = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      history.push(id ? `${LOOSE_ITEM_DETAILED_PATH}/${id}` : LOOSE_ITEMS_LIST_PATH);
    }
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const getValidationRules = () => {
    const rulesForCreation = [
      { name: 'name', inputType: 'text', errorMessage: 'Item name field is required' },
      { name: 'quantity', inputType: 'notEmpty', errorMessage: 'Quantity field is required' },
      { name: 'location', inputType: 'object', errorMessage: 'Location field is required' },
      {
        name: 'looseItemCategories',
        inputType: 'array',
        errorMessage: 'Category field is required'
      },
      { name: 'description', inputType: 'text', errorMessage: 'Description field is required' }
    ];
    const rulesForEditing = [
      { name: 'name', inputType: 'text', errorMessage: 'Item name field is required' },
      {
        name: 'looseItemCategories',
        inputType: 'array',
        errorMessage: 'Category field is required'
      },
      { name: 'description', inputType: 'text', errorMessage: 'Description field is required' }
    ];
    return id ? rulesForEditing : rulesForCreation;
  };

  const isBaaInvalid = (baa = 0, qty = 0) => +baa > +qty;

  const validateForm = () => {
    let isFormValid = true;
    getValidationRules().forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues()[name], inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    if (id) {
      let isTableInvalid = false;
      getValues('locations').forEach((row, index) => {
        if (isBaaInvalid(row?.byAheadAccount, row?.quantity)) {
          setError(
            `locations[${index}].byAheadAccount`,
            getErrorsProperties(
              'The “Buy Ahead Account” value must not exceed “Current QTY” value.'
            ),
            {
              shouldFocus: true
            }
          );
          isFormValid = false;
          isTableInvalid = true;
        }
      });
      if (isTableInvalid) {
        enqueueErrorSnackbar(ERROR_LOOSE_ITEM_VALIDATE_TABLE);
      }
    } else {
      if (getValues('byAheadAccount') > getValues('quantity')) {
        setError('byAheadAccount', getErrorsProperties('BAA could not be more than Quantity'), {
          shouldFocus: true
        });
        isFormValid = false;
      }
    }
    return isFormValid;
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;
    if (id) {
      setDialogModalData({ ...UPDATE_LOOSE_ITEM, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_ITEM, isOpened: true });
    }
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case CREATE_NEW_ITEM_TYPE:
        closeModal();
        createItemAction(getValues(), { isAddQty: false }).then((err) => {
          if (err?.errors) {
            const modifiedErrors = getBackendErrors(err);
            modifiedErrors.forEach(
              ({ name, type, message }) => name !== 'name' && setError(name, { type, message })
            );

            // check if app get only name error
            if (
              modifiedErrors?.length === 1 &&
              modifiedErrors?.find(({ name }) => name === 'name')
            ) {
              setDialogModalData(THE_LOOSE_ITEM_ALREADY_EXIST);
            }
          }
        });
        break;
      case THE_LOOSE_ITEM_ALREADY_EXIST_TYPE: {
        closeModal();
        createItemAction(getValues(), { isAddQty: true }).then((err) => {
          if (err?.errors) {
            getBackendErrors(err).forEach(({ name, type, message }) =>
              setError(name, { type, message })
            );
          }
        });
        break;
      }
      case UPDATE_LOOSE_ITEM_TYPE: {
        closeModal();
        updateLooseItemAction(getValues()).then((err) => {
          if (err?.errors) {
            getBackendErrors(err).forEach(({ name, type, message }) =>
              setError(name, { type, message })
            );
          }
        });
        break;
      }
      case WITHOUT_SAVING_TYPE:
        history.push(id ? `${LOOSE_ITEM_DETAILED_PATH}/${id}` : LOOSE_ITEMS_LIST_PATH);
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(dialogModalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const closeModal = () => setDialogModalData({ isOpened: false });

  return (
    <div className={styles.wrapper}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setDialogModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit loose item' : 'Add new item'}</h1>
          <div className={styles.header__block_controls}>
            {isMobile && <BackButton onCancel={handleBackClick} />}
          </div>
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </div>
  );
}
