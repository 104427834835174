import React, { useState } from 'react';
import DetailsHeader from 'components/DetailsComponents/Header';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';
import { PROJECT_EDIT_PATH, PROJECT_LIST_PATH } from 'constants/routeConstants';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import { useUserConfig } from 'hooks/useUserConfig';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { useProjectActions } from '../../../hooks/useProjectActions';
import { DELETE_PROJECT } from 'constants/dialogPopupsData';

export default function Header() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const [dialogModalData, setDialogModalData] = useState({});

  const { id, name } = useSelector(selectSingleProjectData());
  const { deleteProjectAction } = useProjectActions();

  const { isAdminUser, isOperationsManagerUser } = useUserConfig();

  const breadcrumbs = [{ name: name }];

  const handleEditClick = () => history.push(`${PROJECT_EDIT_PATH}/${id}`);
  const handleRemoveClick = () => setDialogModalData({ ...DELETE_PROJECT, isOpened: true });
  const handleBackClick = () => history.push(PROJECT_LIST_PATH);

  const onAgree = () => deleteProjectAction(id);
  const closeModal = () => setDialogModalData({ isOpened: false });

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      <DetailsHeader breadcrumbs={breadcrumbs} title={name}>
        {(isAdminUser || isOperationsManagerUser) && (
          <HeaderButton onClick={handleEditClick}>
            <CreateIcon />
            {!isMobile && <span>Edit this project</span>}
          </HeaderButton>
        )}
        {isAdminUser && (
          <HeaderButton onClick={handleRemoveClick}>
            <DeleteIcon />
            {!isMobile && <span>Remove this project</span>}
          </HeaderButton>
        )}
        {isMobile && (
          <HeaderButton onClick={handleBackClick}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
      </DetailsHeader>
    </>
  );
}
