import React, { useState } from 'react';
import styles from './DownloadCSVButton.module.scss';
import csvIcon from 'assets/images/csvIcon.svg';
import { refFileDownload, wait } from 'helpers/AppHelpers';
import { useUserConfig } from 'hooks/useUserConfig';
import clsx from 'clsx';

const DISABLING_TIME = 15000;

export default function DownloadCSVButton({ endpoint, filter, includePagination, includeUserId }) {
  const [isDisabled, setDisabled] = useState(false);

  const { userId } = useUserConfig();

  const handleClick = () => {
    refFileDownload(endpoint, {
      ...filter,
      pagination: includePagination ? filter.pagination : null,
      userId: includeUserId ? userId : null
    });
    setDisabled(true);
    wait(DISABLING_TIME).then(() => setDisabled(false));
  };

  return (
    <button
      className={clsx(styles.downloadButton, isDisabled && styles.disabled)}
      onClick={handleClick}>
      <img src={csvIcon} alt="" />
      Download CSV file
    </button>
  );
}
