import React from 'react';
import styles from './LineChart.module.scss';

import ReactApexChart from 'react-apexcharts';

import { getOptions } from './options';

export default function LineChart({ series, categories, colors, fileName, hideToolbar }) {
  const options = getOptions(categories, colors, fileName, hideToolbar);

  return (
    <div className={styles.chart}>
      <ReactApexChart width="100%" height="100%" type="line" options={options} series={series} />
    </div>
  );
}
