import React from 'react';
import styles from './FormManager.module.scss';

import { FormLabel, FormSelectInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';

import { useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

export function LogForm({ index }) {
  const { setValue } = useFormContext();

  const { creationForm } = useBillOfLadingLogsSelector();
  const { types } = creationForm;

  const selectType = ({ name, value }) => setValue(name, value);

  const getFieldName = (name) => `items[${index}].${name}`;

  return (
    <div className={styles.form}>
      <div className={styles.form__block}>
        <div className={styles.form__block_row}>
          <FormLabel required>{`Log's Type`}</FormLabel>
          <FormSelectInput
            name={getFieldName('type')}
            options={types || []}
            onSelect={selectType}
          />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Origin Location</FormLabel>
          <FormInputText name={getFieldName('originLocation')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Destination</FormLabel>
          <FormInputText name={getFieldName('destination')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Date</FormLabel>
          <FormInputText name={getFieldName('date')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Carrier</FormLabel>
          <FormInputText name={getFieldName('carrier')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Vendor</FormLabel>
          <FormInputText name={getFieldName('vendor')} options={{ max: 250 }} />
        </div>
      </div>
      <div className={styles.form__block}>
        <div className={styles.form__block_row}>
          <FormLabel>Item</FormLabel>
          <FormInputText name={getFieldName('item')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>QTY</FormLabel>
          <FormInputText name={getFieldName('qty')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Package</FormLabel>
          <FormInputText name={getFieldName('package')} options={{ max: 250 }} />
        </div>
        <div className={styles.form__block_row}>
          <FormLabel>Pallet</FormLabel>
          <FormInputText name={getFieldName('pallet')} options={{ max: 250 }} />
        </div>
        <div className={clsx(styles.form__block_row, styles.alignTop)}>
          <FormLabel>File</FormLabel>
          <FormFilesUpload name={getFieldName('resources')} />
        </div>
      </div>
    </div>
  );
}
