import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './DataTable.module.scss';

import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';

import clsx from 'clsx';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { Tooltip } from 'react-tooltip';

export function DataTable({
  enableRowSelection,
  setSelectedRows,
  columns,
  data,
  isNoMatches,
  isLoading,
  loadMore,
  tableKey,
  isMorePagesAvailable,
  setColumnWidths
}) {
  const isMobile = useMobileViewport();

  const scrollRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);

  const [rowSelection, setRowSelection] = useState({});

  const { getTableLimit, isAllLimitEnabled } = useUserConfig();

  const getRowId = React.useCallback((row) => row.id, []);

  useEffect(() => {
    if (!setSelectedRows) return;

    const handleSelectionState = (selections) => {
      setSelectedRows((prev) =>
        Object.keys(selections).map(
          (key) =>
            table.getSelectedRowModel().rowsById[key]?.original ||
            prev.find((row) => row._id === key)
        )
      );
    };

    handleSelectionState(rowSelection);
  }, [rowSelection]);

  const table = useReactTable({
    data,
    defaultColumn: {
      minSize: 52
    },
    enableColumnResizing: !isMobile,
    columns,
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId,
    enableRowSelection: (row) => enableRowSelection(row),
    state: {
      rowSelection
    },
    onRowSelectionChange: setRowSelection
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i = i + 1) {
      const header = headers[i];
      const size = header.getSize();
      colSizes[`--header-${header.id}-size`] = size;
      colSizes[`--col-${header.column.id}-size`] = size;
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing, columns]);

  const loadMoreData = () => {
    return new Promise((resolve) => {
      loadMore();
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const handleScroll = useCallback(() => {
    if (!tableKey) return;

    const limit = getTableLimit(tableKey);
    const allLimitEnabled = isAllLimitEnabled(tableKey);

    if (limit === 100 && allLimitEnabled && isMorePagesAvailable) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 200 && !isFetching) {
        setIsFetching(true);
        loadMoreData()
          .then(() => {
            setIsFetching(false);
          })
          .catch(() => {
            setIsFetching(false);
          });
      }
    }
  }, [tableKey, getTableLimit, isAllLimitEnabled, isMorePagesAvailable, isFetching, loadMoreData]);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    currentScrollRef.addEventListener('scroll', handleScroll);

    return () => {
      currentScrollRef.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    return () => {
      if (!setColumnWidths) return;
      const currentSizing = table.getState().columnSizing;
      setColumnWidths(currentSizing);
    };
  }, [table, setColumnWidths]);

  return (
    <div ref={scrollRef} className={styles.container}>
      <Tooltip
        id="tooltip-id"
        offset={-4}
        className={styles.tooltip}
        noArrow
        place="bottom"
        delayShow={500}
        hidden={isMobile}
      />

      <div className={styles.table} style={{ ...columnSizeVars, width: table.getTotalSize() }}>
        <Header table={table} />

        {table.getState().columnSizingInfo.isResizingColumn ? (
          <MemoizedTableBody table={table} isNoMatches={isNoMatches} isLoading={isLoading} />
        ) : (
          <TableBody table={table} isNoMatches={isNoMatches} isLoading={isLoading} />
        )}
      </div>
    </div>
  );
}

const Header = ({ table }) => (
  <div className={styles.head}>
    {table.getHeaderGroups().map((headerGroup) => {
      return (
        <div key={headerGroup.id} className={styles.row}>
          {headerGroup.headers.map((header) => (
            <div
              key={header.id}
              className={clsx(
                styles.th,
                header.column.columnDef?.centerContent && styles.centered,
                header.column.columnDef?.sticky && styles.sticky
              )}
              style={{
                width: `calc(var(--header-${header?.id}-size) * 1px)`
              }}>
              {flexRender(header.column.columnDef.header, header.getContext())}
              <div
                className={clsx(styles.resizer, header.column.getIsResizing() && styles.isResizing)}
                style={{
                  transform: header.column.getIsResizing()
                    ? `translateX(${1 * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                    : ''
                }}
                onDoubleClick={() => header.column.resetSize()}
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
              />
            </div>
          ))}
        </div>
      );
    })}
  </div>
);

function TableBody({ table, isNoMatches, isLoading }) {
  const isCellEditable = (cell) => {
    if (!cell.column.columnDef?.editable) return false;

    const { editable } = cell.column.columnDef;

    if (typeof editable === 'boolean') {
      return !!editable;
    }

    const { original } = cell.row;

    return editable(original);
  };

  return (
    <div className={styles.body}>
      {table.getRowModel().rows?.length ? (
        table.getRowModel().rows.map((row) => (
          <div key={row.id} className={styles.row}>
            {row.getVisibleCells().map((cell) => (
              <div
                key={cell.id}
                className={clsx(
                  styles.td,
                  cell.column.columnDef?.centerContent && styles.centered,
                  cell.column.columnDef?.sticky && styles.sticky,
                  isCellEditable(cell) && styles.editable
                )}
                style={{
                  width: `calc(var(--col-${cell.column.id}-size) * 1px)`
                }}
                onClick={() => {
                  if (isCellEditable(cell) && cell.column.columnDef?.onEditClick) {
                    cell.column.columnDef.onEditClick(cell.row.original, cell.row.index);
                  }
                }}
                data-tooltip-id="tooltip-id"
                data-tooltip-content={
                  cell.column.columnDef?.tooltipValueGetter
                    ? cell.column.columnDef?.tooltipValueGetter(cell.row.original)
                    : ''
                }>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </div>
        ))
      ) : isLoading ? null : (
        <div className={styles.emptyRow}>
          <div className={styles.emptyTd}>
            {isNoMatches ? 'No matches in the table' : 'No available data in the table.'}
          </div>
        </div>
      )}
    </div>
  );
}

export const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
);
