import React from 'react';
import clsx from 'clsx';
import styles from './StatusIndicator.module.scss';

export default function StatusIndicator({ status, leftSpace }) {
  return (
    <span
      className={clsx(
        styles.statusIndicator,
        styles[status],
        leftSpace && styles.leftSpace
      )}></span>
  );
}
