import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Chip } from '@material-ui/core';

export default function AutocompleteChip({ onDelete, label, chipId, chipOptions }) {
  const handleChipDelete = () => onDelete(chipId);

  return (
    <Chip
      {...chipOptions}
      deleteIcon={<CloseIcon />}
      variant="outlined"
      label={label}
      onDelete={handleChipDelete}
    />
  );
}
