import React, { useEffect, useState } from 'react';
import styles from './PreviewPopup.module.scss';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { IconButton } from '@material-ui/core';
import { useMobileViewport } from 'hooks/useMobileViewport';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MainButton } from 'components/StyledComponents';
import { useDispatch } from 'react-redux';
import { updateImageResource } from 'actions/commonActions';

const ROTATION_ANGLES = [0, 90, 180, 270];

export default function PreviewPopup({ open, setOpen, data, onSave }) {
  const dispatch = useDispatch();
  const isMobile = useMobileViewport();
  const [rotationValue, setRotationValue] = useState(0);
  const [initialValue, setInitialValue] = useState(0);

  useEffect(() => {
    if (data?.rotationAngle && open) {
      setRotationValue(data.rotationAngle);
      setInitialValue(data.rotationAngle);
    }
  }, [open]);

  const hasFormChanged = () => initialValue !== rotationValue;

  const handleCancelClick = () => {
    setOpen(false);
    setRotationValue(0);
  };

  const handleSaveClick = () => {
    const newImageValue = { ...data, rotationAngle: rotationValue };
    dispatch(updateImageResource(newImageValue)).then((res) => {
      if (res.status !== 200) return;
      onSave(res.data);
      setOpen(false);
    });
  };

  const handleLeftClick = () => {
    const index = ROTATION_ANGLES.findIndex((angle) => angle === rotationValue);
    if (index === 0) {
      const angle = ROTATION_ANGLES[ROTATION_ANGLES.length - 1];
      setRotationValue(angle);
    } else {
      const angle = ROTATION_ANGLES[index - 1];
      setRotationValue(angle);
    }
  };

  const handleRightClick = () => {
    const index = ROTATION_ANGLES.findIndex((angle) => angle === rotationValue);
    if (index === ROTATION_ANGLES.length - 1) {
      const angle = ROTATION_ANGLES[0];
      setRotationValue(angle);
    } else {
      const angle = ROTATION_ANGLES[index + 1];
      setRotationValue(angle);
    }
  };

  return (
    <DialogWrapper open={open} onClose={handleCancelClick}>
      <section className={styles.wrapper}>
        <div className={styles.header}>
          {isMobile && <h1>Edit photo</h1>}
          <div className={styles.header__controls}>
            {hasFormChanged() && !isMobile && (
              <IconButton onClick={handleSaveClick}>
                <SaveIcon />
              </IconButton>
            )}
            <IconButton onClick={handleLeftClick}>
              <RotateLeftIcon />
            </IconButton>
            <IconButton onClick={handleRightClick}>
              <RotateRightIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              {isMobile ? <ChevronLeftIcon /> : <CloseIcon />}
            </IconButton>
          </div>
        </div>
        <div className={styles.image}>
          <img src={data.link} alt="" className={styles[`rotation${rotationValue}`]} />
        </div>

        <div className={styles.footer}>
          {isMobile && hasFormChanged() && (
            <MainButton text="Save" action={handleSaveClick} type="primary" size="filter_popup" />
          )}
        </div>
      </section>
    </DialogWrapper>
  );
}
