import React, { useEffect, useState } from 'react';
import styles from './AddAssetTickets.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { tabs, defaultTicketModel, getUpdatedTabValues, getOtherTabNames } from '../helpers';

import { ADD_ASSET_TICKET } from 'constants/dialogPopupsData';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { AddNewButton, BlueButton } from 'components/Buttons';
import Separator from 'components/Separator/Separator';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormVideosUpload from 'components/FormComponents/FormVideosUpload/FormVideosUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import {
  FormMultipleAssetSelect,
  FormSelectInput,
  FormLabel,
  FormRow,
  FormServerUserSearch,
  FormDayPicker
} from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { focusErrorField, getErrorsProperties } from 'helpers/ErrorValidator';
import {
  enqueueErrorSnackbar,
  enrichNameToId,
  getUserFullName,
  isSuccessfulStatus,
  pluck
} from 'helpers/AppHelpers';
import Subhead from 'components/DetailsComponents/Subhead';
import { useUserConfig } from 'hooks/useUserConfig';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormInputText_v2 from 'components/FormComponents/FormInputText/FormInputText_v2';
import { ERROR_ENTITY_EXIST } from 'constants/infoSnackbarData';

export default function AddAssetTickets() {
  const tabName = tabs.addAssetTicket;
  const formArrayName = 'createServiceTickets';
  const getFieldName = (name, index) => `${tabName}.${formArrayName}[${index}].${name}`;

  const { isBasicRoleUser, isPersonnelManagerUser } = useUserConfig();

  const { control, setValue, formState, getValues, clearErrors, setError } = useFormContext();
  const { errors } = formState;
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: `${tabName}.${formArrayName}`
  });
  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const clearFieldError = (name, index) =>
    errors?.[tabName]?.[formArrayName]?.[index]?.[name]?.message &&
    clearErrors(getFieldName(name, index));
  const getSpecificTicketValues = (index) => getValues(`${tabName}.${formArrayName}[${index}]`);

  const { creationForm } = useBatchAssetUpdatesSelector();
  const { getTicketTypesAction, getRootCausesAction, createTicketsAction } =
    useBatchAssetUpdatesActions();

  const {
    severities,
    locations,
    reopenTicketFrequencies,
    assetPrefixes,
    looseItemCategories,
    looseItems
  } = creationForm;

  const [modalData, setModalData] = useState({});
  const [renderCount, setRenderCount] = useState(0);

  const getFieldOptions = (assets, index) => {
    const query = { assetsId: pluck(assets, 'id') };

    getTicketTypesAction(query).then((ticketTypeList) => {
      getRootCausesAction(query).then((rootCauseList) => {
        setValue(getFieldName('ticketTypeList', index), ticketTypeList);
        setValue(getFieldName('ticketType', index), null);
        setValue(getFieldName('rootCauseList', index), rootCauseList);
        setValue(getFieldName('rootCause', index), null);
        setValue(getFieldName('severity', index), {});
        setValue(getFieldName('severityList', index), severities);
        setRenderCount(renderCount + 1);
      });
    });
  };

  useEffect(() => {
    if (!severities?.length) return;

    if (getValues(getFieldName('assets', 0))?.length) {
      getFieldOptions(getValues(getFieldName('assets', 0)), 0);
    } else {
      setValue(getFieldName('severityList', 0), severities);
    }
  }, [severities]);

  const validateForm = () => {
    const setFieldError = (name, index, errorMessage) =>
      setError(getFieldName(name, index), getErrorsProperties(errorMessage));

    let isValid = true;
    let firstError;

    const { createServiceTickets } = getValues(tabName);

    createServiceTickets.forEach((ticket, ticketIndex) => {
      if (!ticket?.assets?.length) {
        setFieldError('assets', ticketIndex, 'Assets field is required');
        isValid = false;
        if (!firstError) {
          firstError = `input[name="${getFieldName('assets', ticketIndex)}"]`;
        }
      }
      if (!ticket?.severity?.name) {
        setFieldError('severity', ticketIndex, 'Severity field is required');
        isValid = false;
        if (!firstError) {
          firstError = `input[name="${getFieldName('severity', ticketIndex)}"]`;
        }
      }
      if (!ticket?.description) {
        setFieldError('description', ticketIndex, 'Description field is required');
        isValid = false;
        if (!firstError) {
          firstError = `textarea[name="${getFieldName('description', ticketIndex)}"]`;
        }
      }
    });

    firstError && focusErrorField(firstError);

    return isValid;
  };

  const clearForm = () => replace([defaultTicketModel]);

  const removeTicket = (index) => {
    if (index === 0) setCommonAssets(getValues(getFieldName('assets', 1)));
    remove(index);
  };

  const addTicket = () => {
    if (!validateForm()) return;
    append({ ...defaultTicketModel, severityList: severities });
  };

  const setCommonAssets = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    createTicketsAction(getValues(tabName)).then((res) => {
      if (!isSuccessfulStatus(res.status)) return;

      setCommonAssets([]);
      clearForm();
    });
    closeModal();
  };

  const handleAssetSelect = (assets, { index }) => {
    index === 0 && setCommonAssets(assets);
    clearFieldError('assets', index);

    if (!assets?.length) {
      update(index, {
        ...defaultTicketModel,
        severityList: severities,
        filterLocationIds: getValues(getFieldName('filterLocationIds', index))
      });

      clearFieldError('severity', index);
      clearFieldError('description', index);
      return;
    }

    getFieldOptions(assets, index);
  };

  const selectCheckbox = () => setRenderCount(renderCount + 1);

  const selectOption = (name, value) => {
    setValue(name, value);
    setRenderCount(renderCount + 1);
  };

  const selectFrequency = ({ name, value, index }) => {
    setValue(name, value);
    setValue(getFieldName('reopenOnSpecificDate', index), null);
    setRenderCount(renderCount + 1);
  };

  const selectType = ({ name, value, index }) => {
    setValue(name, value);

    const severityName = getFieldName('severity', index);
    const severityValue =
      value?.severities?.length === 1
        ? { id: value.severities[0].name, ...value.severities[0] }
        : {};
    selectSeverity({ name: severityName, value: severityValue, index });
    setValue(
      getFieldName('severityList', index),
      value?.severities?.length ? value.severities : severities
    );

    const descriptionName = getFieldName('description', index);
    setValue(descriptionName, value?.description || getValues(descriptionName) || '');
    clearFieldError('description', index);

    setRenderCount(renderCount + 1);
  };

  const selectRootCause = ({ name, value }) => setValue(name, value);

  const selectSeverity = ({ name, value, index }) => {
    clearFieldError('severity', index);
    setValue(name, value);
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;
    setModalData({ ...ADD_ASSET_TICKET, isOpened: true });
  };

  const handleLocationSelect = () => setRenderCount(renderCount + 1);

  const conditions = {
    assigneeBlock: !isBasicRoleUser && !isPersonnelManagerUser,
    notificationReminder: (index) =>
      getValues(getFieldName('assignedToUser', index))?.id &&
      getValues(getFieldName('assignedAtUtc', index)),
    reopenSettings: (index) =>
      !isBasicRoleUser &&
      !isPersonnelManagerUser &&
      getValues(getFieldName('ticketType', index))?.reopening
  };

  const handleCheckboxClick = (name, _, index) => {
    switch (name) {
      case 'includeAssets': {
        update(index, { ...getSpecificTicketValues(index), assetPrefixes: [] });
        break;
      }
      case 'includeLooseItems': {
        update(index, {
          ...getSpecificTicketValues(index),
          narrowCategoryIds: [],
          looseItems: [],
          looseItemList: looseItems
        });
        break;
      }
      case 'includeOthers': {
        update(index, { ...getSpecificTicketValues(index), otherItemName: '', otherItems: [] });
        break;
      }
    }
  };

  const handleCategorySelect = (_, value, index) => {
    if (value?.length) {
      const filteredItems = looseItems.filter(
        ({ looseItemCategories }) =>
          !!looseItemCategories.filter(({ id }) => value.includes(id)).length
      );
      setValue(getFieldName('looseItemList', index), filteredItems);
    } else {
      setValue(getFieldName('looseItemList', index), looseItems);
    }
    setRenderCount(renderCount + 1);
  };

  const addCustomField = (entityName, index) => {
    const items = getValues(getFieldName('otherItems', index));
    const names = items.map((el) => el.entityName.toLowerCase());

    if (names.includes(entityName.toLowerCase())) {
      enqueueErrorSnackbar(ERROR_ENTITY_EXIST);
      return;
    }

    items.push({ entityName });
    setValue(getFieldName('otherItems', index), items);
    setValue(getFieldName('otherItemName', index), '');
  };

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        {!!fields?.length &&
          fields.map((ticket, index) => (
            <div key={ticket.id} className={styles.ticket}>
              <div className={styles.ticket__assets}>
                <div className={styles.ticket__assets_filter}>
                  <FormLabel>Narrow Assets by location</FormLabel>
                  <FormAutoMultiSelect
                    name={getFieldName('filterLocationIds', index)}
                    menuItems={locations || []}
                    options={{
                      label: 'siteCode',
                      disableByObjectTracker: true,
                      disableLabel: true,
                      extraAction: handleLocationSelect
                    }}
                  />
                </div>
                <div className={styles.ticket__assets_field}>
                  <FormLabel required>Asset</FormLabel>
                  <FormMultipleAssetSelect
                    withQr
                    name={getFieldName('assets', index)}
                    onSelectTriggered={handleAssetSelect}
                    index={index}
                    queryOptions={{
                      locationIds: getValues(getFieldName('filterLocationIds', index))
                    }}
                  />
                </div>
              </div>

              <div className={styles.section}>
                <Subhead>General Details</Subhead>
                <div className={styles.container}>
                  <div className={styles.container__block}>
                    <FormRow>
                      <FormLabel>Ticket Type</FormLabel>
                      <FormSelectInput
                        clearable
                        name={getFieldName('ticketType', index)}
                        options={getValues(getFieldName('ticketTypeList', index)) || []}
                        onSelect={selectType}
                        index={index}
                      />
                    </FormRow>
                    <FormRow>
                      <FormLabel>Root Cause</FormLabel>
                      <FormSelectInput
                        clearable
                        name={getFieldName('rootCause', index)}
                        options={getValues(getFieldName('rootCauseList', index)) || []}
                        onSelect={selectRootCause}
                        index={index}
                      />
                    </FormRow>
                    <FormRow>
                      <FormLabel required>Severity</FormLabel>
                      <FormSelectInput
                        name={getFieldName('severity', index)}
                        options={enrichNameToId(getValues(getFieldName('severityList', index)))}
                        onSelect={selectSeverity}
                        index={index}
                      />
                    </FormRow>
                    {conditions.reopenSettings(index) && (
                      <>
                        <FormRow>
                          <FormLabel>Reopen Frequency</FormLabel>
                          <FormSelectInput
                            clearable
                            index={index}
                            name={getFieldName('reopenTicketFrequency', index)}
                            options={reopenTicketFrequencies || []}
                            onSelect={selectFrequency}
                          />
                        </FormRow>
                        <FormRow>
                          <FormLabel>Reopen on specific date</FormLabel>
                          <FormDayPicker
                            name={getFieldName('reopenOnSpecificDate', index)}
                            min={new Date()}
                            disabled={
                              getValues(getFieldName('reopenTicketFrequency', index))?.name !==
                              'OneTime'
                            }
                          />
                        </FormRow>
                      </>
                    )}
                  </div>
                  <div className={styles.container__block}>
                    <FormRow type="checkbox">
                      <FormLabel>{`Ticket's Status Open`}</FormLabel>
                      <FormCheckbox name={getFieldName('isOpened', index)} />
                    </FormRow>
                    {conditions.assigneeBlock && (
                      <>
                        <FormRow>
                          <FormLabel>Assignee</FormLabel>
                          <FormServerUserSearch
                            name={getFieldName('assignedToUser', index)}
                            getLabel={(option) => getUserFullName(option)}
                            getQuery={(searchQuery) => ({
                              pagination: { limit: 39, page: 1 },
                              filters: { shouldIncludeCurrentUser: true, searchQuery }
                            })}
                            onSelect={selectOption}
                          />
                        </FormRow>
                        <FormRow>
                          <FormLabel>Due Date</FormLabel>
                          <FormDayPicker
                            clearable
                            name={getFieldName('assignedAtUtc', index)}
                            min={new Date()}
                            onSelectTriggered={selectCheckbox}
                          />
                        </FormRow>
                      </>
                    )}
                    {conditions.assigneeBlock && conditions.notificationReminder(index) && (
                      <FormRow type="checkbox">
                        <FormLabel>Notification Reminder</FormLabel>
                        <FormCheckbox name={getFieldName('notificationReminder', index)} />
                      </FormRow>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <Subhead>Description*</Subhead>
                <div className={styles.container}>
                  <div className={styles.container__block}>
                    <FormRow type="column">
                      <FormTextArea
                        name={getFieldName('description', index)}
                        options={{ max: 1000 }}
                      />
                    </FormRow>
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <Subhead>Necessary Items</Subhead>
                <div className={styles.container}>
                  <div className={styles.container__block}>
                    <FormRow type="checkbox">
                      <FormLabel>Asset</FormLabel>
                      <FormCheckbox
                        name={getFieldName('includeAssets', index)}
                        onSelectTriggered={(_, value) =>
                          handleCheckboxClick('includeAssets', value, index)
                        }
                      />
                    </FormRow>
                    {getValues(getFieldName('includeAssets', index)) && (
                      <>
                        <FormRow>
                          <FormLabel>Prefix</FormLabel>
                          <FormAutoMultiSelect
                            name={getFieldName('assetPrefixes', index)}
                            menuItems={assetPrefixes || []}
                            options={{
                              label: 'prefix',
                              disableByObjectTracker: false,
                              disableLabel: true,
                              hideTags: true
                            }}
                          />
                        </FormRow>
                        <FormChipsContainer
                          fieldName={getFieldName('assetPrefixes', index)}
                          label="prefix"
                          deleteParameter="id"
                        />
                      </>
                    )}

                    <FormRow type="checkbox">
                      <FormLabel>Loose Items</FormLabel>
                      <FormCheckbox
                        name={getFieldName('includeLooseItems', index)}
                        onSelectTriggered={(_, value) =>
                          handleCheckboxClick('includeLooseItems', value, index)
                        }
                      />
                    </FormRow>
                    {getValues(getFieldName('includeLooseItems', index)) && (
                      <>
                        <FormRow>
                          <FormLabel>Narrow by Category</FormLabel>
                          <FormAutoMultiSelect
                            name={getFieldName('narrowCategoryIds', index)}
                            menuItems={looseItemCategories || []}
                            options={{
                              label: 'name',
                              disableByObjectTracker: true,
                              disableLabel: true,
                              extraAction: (name, value) => handleCategorySelect(name, value, index)
                            }}
                          />
                        </FormRow>
                        <FormRow>
                          <FormLabel>Loose Item name</FormLabel>
                          <FormAutoMultiSelect
                            name={getFieldName('looseItems', index)}
                            menuItems={getValues(getFieldName('looseItemList', index)) || []}
                            options={{
                              label: 'name',
                              disableByObjectTracker: false,
                              hideTags: true
                            }}
                          />
                        </FormRow>
                        <FormChipsContainer
                          fieldName={getFieldName('looseItems', index)}
                          label="name"
                          deleteParameter="id"
                        />
                      </>
                    )}

                    <FormRow type="checkbox">
                      <FormLabel>Other</FormLabel>
                      <FormCheckbox
                        name={getFieldName('includeOthers', index)}
                        onSelectTriggered={(_, value) =>
                          handleCheckboxClick('includeOthers', value, index)
                        }
                      />
                    </FormRow>
                    {getValues(getFieldName('includeOthers', index)) && (
                      <>
                        <FormRow className={styles.otherRow}>
                          <FormLabel>Name</FormLabel>
                          <div className={styles.otherField}>
                            <FormInputText_v2
                              name={getFieldName('otherItemName', index)}
                              options={{ max: 100 }}
                            />
                            <span className={styles.tip}>Write to add a new field</span>
                            <Controller
                              control={control}
                              name={getFieldName('otherItemName', index)}
                              render={({ field: { value } }) => (
                                <AddNewButton
                                  onClick={() => addCustomField(value, index)}
                                  isDisabled={!value?.length}
                                />
                              )}
                            />
                          </div>
                        </FormRow>
                        <FormChipsContainer
                          fieldName={getFieldName('otherItems', index)}
                          label="entityName"
                          deleteParameter="entityName"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <Subhead>Attachments</Subhead>
                <div className={styles.container}>
                  <div className={styles.container__block}>
                    <FormRow type="file">
                      <FormLabel>Photo</FormLabel>
                      <FormImagesUpload name={getFieldName('resources', index)} />
                    </FormRow>
                    <FormRow type="file">
                      <FormLabel>File</FormLabel>
                      <FormFilesUpload name={getFieldName('resources', index)} />
                    </FormRow>
                  </div>
                  <div className={styles.container__block}>
                    <FormRow type="file">
                      <FormLabel>Video</FormLabel>
                      <FormVideosUpload name={getFieldName('resources', index)} />
                    </FormRow>
                  </div>
                </div>
              </div>

              {fields.length > 1 && (
                <div className={styles.delete_button}>
                  <BlueButton label="Delete" onClick={() => removeTicket(index)} fullOnMobile>
                    <DeleteIcon />
                  </BlueButton>
                </div>
              )}
              {fields.length > 1 && <Separator />}
            </div>
          ))}

        <BlueButton label="Add ticket" onClick={addTicket} fullOnMobile>
          <AddIcon />
        </BlueButton>
      </div>
      <div className={styles.footer}>
        <MainButton
          text="Submit all asset tickets"
          type="primary"
          action={handleUpdateClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
