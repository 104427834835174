import {
  SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA,
  CLEAR_TEAM_MEMBER_TRACKER_STATE,
  SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB,
  SET_TRACKER_MASTER_FILTER_DATA,
  SET_TRACKER_BREAKDOWN_FILTER_DATA,
  SET_TRACKER_FILTER_DATA,
  SET_TRACKER_RESPONSE_RECEIVED,
  SET_TRACKER_TABLE_DATA
} from 'constants/reduceConstants';

const defaultPagination = {
  limit: 50,
  page: 1,
  totalPages: 1
};
const defaultFilters = {
  projectIds: [],
  locationIds: [],
  teamMemberStatusIds: [],
  teamMemberCapacityIds: [],
  startDate: null,
  endDate: null,
  teamMemberLicenseIds: [],
  teamMemberSkillsetIds: [],
  employmentTypeIds: [],
  teamMemberIds: [],
  teamMemberDepartmentIds: [],
  clearanceTypeIds: [],
  columnLimit: 30,
  columnPage: 1
};
const defaultMasterValues = {
  filters: { ...defaultFilters },
  pagination: defaultPagination
};
const defaultBreakdownValues = {
  filters: { ...defaultFilters },
  pagination: defaultPagination
};

const initialState = {
  filterCriteria: {},
  masterFilterData: { ...defaultMasterValues },
  breakdownFilterData: { ...defaultBreakdownValues },
  selectedToggleTab: '',
  trackerTableData: {},
  isResponseReceived: false
};

const teamMemberTrackerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA:
      return {
        ...state,
        filterCriteria: payload
      };
    case SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB:
      return {
        ...state,
        selectedToggleTab: payload
      };
    case SET_TRACKER_MASTER_FILTER_DATA:
      return {
        ...state,
        masterFilterData: payload
      };
    case SET_TRACKER_BREAKDOWN_FILTER_DATA:
      return {
        ...state,
        breakdownFilterData: payload
      };
    case SET_TRACKER_RESPONSE_RECEIVED:
      return {
        ...state,
        isResponseReceived: payload
      };
    case CLEAR_TEAM_MEMBER_TRACKER_STATE:
      return {
        ...initialState,
        masterFilterData: { ...state.masterFilterData },
        breakdownFilterData: { ...state.breakdownFilterData },
        selectedToggleTab: state.selectedToggleTab
      };
    case SET_TRACKER_FILTER_DATA: {
      const isMaster = state.selectedToggleTab === 'Master';
      const name = isMaster ? 'masterFilterData' : 'breakdownFilterData';
      return { ...state, [name]: { ...state[name], ...payload } };
    }
    case SET_TRACKER_TABLE_DATA:
      return { ...state, trackerTableData: payload };
    default:
      return state;
  }
};

export default teamMemberTrackerReducer;
