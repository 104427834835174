import React, { useState } from 'react';
import styles from './EditableFormRow.module.scss';

import { IconButton, TextField } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { reformatInputValue } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function EditableFormRow({
  data,
  label,
  max,
  isDuplicate,
  duplicateError,
  onEdit,
  onRemove,
  uniquePropertyName = 'id',
  className,
  disableDelete
}) {
  const [mode, setMode] = useState('display');

  const [values, setValues] = useState({});
  const [error, setError] = useState('');

  const isDisplayMode = mode === 'display';

  const handleEditClick = () => {
    setMode('edit');
    error && setError('');
    setValues({ ...data });
  };

  const handleCancelClick = () => setMode('display');
  const handleRemoveClick = () => onRemove(data[uniquePropertyName]);

  const validateForm = () => {
    let isValid = true;
    if (!values?.[label]) {
      setError('This field is required');
      isValid = false;
    }
    if (isValid && isDuplicate && isDuplicate(values?.[label], values[uniquePropertyName])) {
      setError(duplicateError);
      isValid = false;
    }
    return isValid;
  };

  const changeInputValue = (event) => {
    if (error) setError('');
    const value = reformatInputValue(event.target.value, max);
    setValues({ ...values, [label]: value });
  };

  const handleCheckClick = () => {
    if (!validateForm()) return;

    onEdit({ values, setFieldMode: setMode });
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter' || !values?.[label]) return;

    handleCheckClick();
  };

  return (
    <div className={clsx(className, styles.row)}>
      <div className={styles.row__field}>
        {isDisplayMode ? (
          <div className={styles.row__field_label}>{data?.[label] || ''}</div>
        ) : (
          <div className={styles.row__field_input}>
            <TextField
              name="name"
              className={clsx(styles.textInput, error && styles.error)}
              variant="outlined"
              value={values.name || ''}
              onChange={changeInputValue}
              onKeyDown={handleKeyDown}
            />
            {!!error && <span>{error}</span>}
          </div>
        )}
      </div>
      <div className={styles.row__controls}>
        {isDisplayMode ? (
          <>
            <IconButton
              className={clsx(styles.row__controls_button, styles.edit)}
              onClick={handleEditClick}>
              <CreateIcon />
            </IconButton>
            {!disableDelete && (
              <IconButton
                className={clsx(styles.row__controls_button, styles.remove)}
                onClick={handleRemoveClick}>
                <DeleteIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <IconButton
              className={clsx(styles.row__controls_button, styles.check)}
              onClick={handleCheckClick}>
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              className={clsx(styles.row__controls_button, styles.cancel)}
              onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
}
