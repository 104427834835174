import React from 'react';
import styles from './FormRow.module.scss';
import PropTypes from 'prop-types';

import clsx from 'clsx';

/**
 * @typedef {Object} FormRowProps
 * @property {'checkbox' | 'file' | 'default' | 'column'} type
 */

/**
 * @param {FormRowProps} props
 */

function FormRow({ type, className, children }) {
  return (
    <div
      className={clsx(
        className && className,
        styles.formRow,
        type === 'file' && styles.file,
        type === 'checkbox' && styles.checkbox,
        type === 'column' && styles.column
      )}>
      {children}
    </div>
  );
}

FormRow.propTypes = {
  type: PropTypes.string,
  className: PropTypes.any
};

FormRow.defaultProps = {
  type: 'default',
  className: ''
};

export { FormRow };
