export const getTicketsHeadConfig = (type) => [
  { id: 1, label: 'Asset', isSortable: true, parameter: 'asset.drCode' },
  { id: 2, label: 'Ticket ID', isSortable: true, parameter: 'id' },
  { id: 3, label: 'Ticket Type', isSortable: true, parameter: 'type' },
  { id: 4, label: 'Ticket Severity', isSortable: false },
  { id: 5, label: 'Ticket Description', isSortable: true, parameter: 'description' },
  {
    id: 6,
    label: `Ticket ${type === 'closed' ? 'Closed' : 'Opened'} By`,
    isSortable: true,
    parameter: type === 'closed' ? 'closedBy' : 'openedBy'
  },
  {
    id: 7,
    label: `Ticket ${type === 'closed' ? 'Closed' : 'Opened'} On`,
    isSortable: true,
    parameter: type === 'closed' ? 'closedAtUtc' : 'openedAtUtc'
  }
];
