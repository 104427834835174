import React, { useEffect } from 'react';
import styles from './LooseItemDetails.module.scss';

import { useParams } from 'react-router-dom';

import { looseItemsCellsStructure } from '../../tableConfigs';

import LooseItemsTable from '../../components/Table/LooseItemsTable';
import GeneralDetails from '../../components/GeneralDetails';
import Header from '../../components/LooseItemDetailsHeader';

import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';

export default function LooseItemDetails() {
  const { id } = useParams();

  const { currentLooseItem } = useLooseItemSelector();
  const { getItemAction, clearStateAction } = useLooseItemActions();

  useEffect(() => {
    if (id) {
      getItemAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <section className={styles.pageContainer}>
      {currentLooseItem?.id === +id && (
        <div>
          <Header />
          <GeneralDetails />
          <LooseItemsTable
            tableBodyData={currentLooseItem?.locations}
            cells={looseItemsCellsStructure}
          />
          <div className={styles.totalBlock}>
            <label className={styles.hintLabel}>[1]Buy Ahead Account</label>
            <span className={styles.totalText}>Total: {currentLooseItem?.quantity || 0}</span>
          </div>
        </div>
      )}
    </section>
  );
}
