import React from 'react';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';

import styles from './LooseItemsTable.module.scss';

const LooseItemsTable = ({ currentConfiguration }) => {
  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.firstRow}>Loose Item</StyledTableCell>
            <StyledTableCell className={styles.lastRow}>Quantity</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentConfiguration?.looseItems?.length ? (
            currentConfiguration.looseItems.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell className={styles.cell}>
                  <LinkComponent path={`${LOOSE_ITEM_DETAILED_PATH}/${row.id}`} name={row.name} />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span>{row.quantity || 0}</span>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={2} className={styles.emptyTable}>
                No available data in the table.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LooseItemsTable;
