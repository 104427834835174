import api from '../api';
import {
  SET_SEARCH_RESULTS,
  SET_SEARCH_QUERY,
  CLEAR_SEARCH_RESULTS_STATE,
  SET_SEARCH_DATA_LOADED_FLAG
} from 'constants/reduceConstants';

export const searchMatches = (q) => (dispatch) => {
  api.search
    .searchMatchesApi(q)
    .then((res) => dispatch({ type: SET_SEARCH_RESULTS, payload: res || {} }))
    .catch((err) => console.log(err));
};

export const setSearchQuery = (payload) => (dispatch) => {
  dispatch({ type: SET_SEARCH_QUERY, payload });
};

export const setDataLoadedFlag = (payload) => (dispatch) => {
  dispatch({ type: SET_SEARCH_DATA_LOADED_FLAG, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_SEARCH_RESULTS_STATE });
};
