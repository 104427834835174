import React from 'react';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import styles from './SettingsButton.module.scss';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, MenuItem, MenuList, Paper } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import switcherIcon from 'assets/images/tableSwitcher.svg';

export default function SettingsButton({
  index,
  categoryName,
  onDelete,
  onMenuOpen,
  onPieChartOpen,
  onSwitch,
  pieData
}) {
  const handlePieChartClick = () =>
    onPieChartOpen({
      open: true,
      categoryName,
      data: [...pieData]
    });

  const handleMenuClick = () => onMenuOpen(index);
  const handleDeleteClick = () => onDelete(index);

  const trigger = (
    <Button className={styles.dotsButton}>
      <MoreHorizIcon />
    </Button>
  );

  const tooltip = (
    <Paper classes={{ root: styles.paper }}>
      <MenuList>
        <MenuItem onClick={handlePieChartClick}>
          <InfoOutlinedIcon />
          Table pie chart
        </MenuItem>
        <MenuItem onClick={handleMenuClick}>
          <SettingsIcon />
          Add columns
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon />
          Remove the table
        </MenuItem>
        <MenuItem onClick={() => onSwitch()}>
          <img src={switcherIcon} alt="switcher" />
          Switch row/column
        </MenuItem>
      </MenuList>
    </Paper>
  );

  return <TooltipWrapper tooltip={tooltip} trigger={trigger} />;
}
