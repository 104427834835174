import { createSelector } from 'reselect';

const assetsDashboardsData = (state) => state.assetsDashboardsData;

export const selectTabData = () => createSelector([assetsDashboardsData], (state) => state.tab);

export const selectTabConfigData = () =>
  createSelector([assetsDashboardsData], (state) => state.tabConfig);

export const selectFilterCriteriaData = () =>
  createSelector([assetsDashboardsData], (state) => state.filterCriteria);

export const selectVerificationStatisticsData = () =>
  createSelector([assetsDashboardsData], (state) => state.verificationStatistic);

export const selectInspectionStatisticsData = () =>
  createSelector([assetsDashboardsData], (state) => state.inspectionStatistic);
