import React, { useEffect, useRef, useState } from 'react';
import styles from './AssetFiles.module.scss';

import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import QRIcon from 'assets/images/qrIcon.svg';
import InspectedIcon from 'assets/images/inspectedIco.svg';
import ToInspectIcon from 'assets/images/toInspectIco.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { Button, MenuItem } from '@material-ui/core';

import { ASSET_EDIT_PATH } from 'constants/routeConstants';

import { QRCodeGenerator } from 'components/QR';
import MenuPopper from 'components/MenuPopper/MenuPopper';

export default function AssetActions() {
  const history = useHistory();
  const anchorRef = useRef(null);

  const { isBasicRoleUser } = useUserConfig();

  const {
    getAssetAction,
    setAssetInspectionStatusAction,
    getInspectionHistory,
    getLocationHistory,
    getVerificationHistory,
    getInspectionStatusesAction
  } = useAssetActions();
  const {
    currentAsset,
    assetInspectionStatuses,
    verificationHistoryFilter,
    inspectionHistoryFilter,
    locationHistoryFilter
  } = useAssetSelector();
  const { id, drCode, assetPrefix } = currentAsset;

  const [openMenu, setOpenMenu] = useState(false);
  const [openQRPopup, setOpenQRPopup] = useState(false);

  useEffect(() => {
    getInspectionStatusesAction();
  }, []);

  const handleClick = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);

  const addAsset = () => {
    const queryParams = new URLSearchParams({
      activePrefixId: assetPrefix.id,
      drId: id,
      drCode
    });

    history.push(`${ASSET_EDIT_PATH}?${queryParams.toString()}`);
  };

  const openQR = () => {
    setOpenQRPopup(true);
    closeMenu();
  };

  const setInspectionStatus = (statusName) => {
    closeMenu();
    const status = assetInspectionStatuses.find(({ name }) => name === statusName);
    setAssetInspectionStatusAction({
      assetId: currentAsset.id,
      inspectionStatus: status
    }).then(() => {
      getAssetAction(id);
      getInspectionHistory(inspectionHistoryFilter);
      getVerificationHistory(verificationHistoryFilter);
      getLocationHistory(locationHistoryFilter);
    });
  };

  const markAsInspected = () => setInspectionStatus('Inspected');
  const markAsNeedsToBeInspected = () => setInspectionStatus('NeedToBeInspected');
  const markAsVerified = () => setInspectionStatus('Verified');

  return (
    <div className={styles.actions}>
      <QRCodeGenerator fileName={drCode || ''} open={openQRPopup} setOpen={setOpenQRPopup} />
      <Button className={styles.actions__control} ref={anchorRef} onClick={handleClick}>
        Actions
        <ExpandMore className={clsx(openMenu && styles.opened)} />
      </Button>
      <MenuPopper open={openMenu} setOpen={setOpenMenu} anchorRef={anchorRef}>
        {!isBasicRoleUser && (
          <MenuItem onClick={addAsset}>
            <AddIcon />
            Add a New Asset
          </MenuItem>
        )}
        <MenuItem onClick={openQR}>
          <img src={QRIcon} alt="" />
          Generate Asset QR Code
        </MenuItem>
        <MenuItem onClick={markAsInspected}>
          <img src={InspectedIcon} alt="" />
          Mark as Inspected
        </MenuItem>
        {!isBasicRoleUser && (
          <MenuItem onClick={markAsNeedsToBeInspected}>
            <img src={ToInspectIcon} alt="" />
            Needs to be Inspected
          </MenuItem>
        )}
        <MenuItem onClick={markAsVerified}>
          <CheckCircleIcon />
          Mark as verified
        </MenuItem>
      </MenuPopper>
    </div>
  );
}
