import { useSelector } from 'react-redux';

import { selectUnsavedFormData } from 'pages/commonSelectors';
import {
  selectFilterCriteriaData,
  selectFilterData,
  selectRequestOrderCreationFormData
} from 'pages/RequestOrder/selectors';

export function useRequestOrderSelector() {
  const creationFormData = useSelector(selectRequestOrderCreationFormData());

  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    creationFormData,
    filter,
    filterCriteria,
    unsavedFormData
  };
}
