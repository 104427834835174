import React from 'react';
import styles from './GeneralDetails.module.scss';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';
import Subtitle from '../Subtitle';
import { usePrefixSelector } from 'hooks/Prefix';
import DetailedRow from 'components/DetailsComponents/DetailedRow';
import clsx from 'clsx';

export default function GeneralDetails() {
  const { currentPrefix } = usePrefixSelector();
  const { name, prefix, active, prefixType, assetCategory, assetSubcategories } = currentPrefix;

  const details = [
    { id: 1, label: 'Name', value: name || '' },
    { id: 4, label: 'Type', value: prefixType?.name || '' },
    { id: 2, label: 'Prefix', value: prefix || '' },
    { id: 5, label: 'Category', value: assetCategory?.name || '' },
    { id: 3, label: 'Active?', value: active || '', type: 'checkbox' },
    {
      id: 6,
      label: 'Subcategory',
      value: assetSubcategories?.length ? assetSubcategories.map(({ name }) => name).join(', ') : ''
    }
  ];

  return (
    <section className={styles.wrapper}>
      <Subtitle>General details</Subtitle>
      <div className={styles.general}>
        {details.map(({ id, label, value, type }) => (
          <div
            className={clsx(styles.general__row, type === 'checkbox' && styles['last'])}
            key={id}>
            {type === 'checkbox' ? (
              <div className={styles.general__row_checkbox}>
                <label>{label}</label>
                <StyledCheckbox disabled checked={value} />
              </div>
            ) : (
              <DetailedRow label={label} value={value} disableFlex />
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
