import React, { useState } from 'react';
import styles from './MultiSelectWithCreation.module.scss';

import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { enqueueExistPrefixWarning } from 'actions/prefixActions';
import { useDispatch } from 'react-redux';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { reformatInputValue } from 'helpers/AppHelpers';
import clsx from 'clsx';

import HiddenSelectField from './HiddenSelectField';

export default function MultiSelectWithCreation({
  name,
  optionLabel,
  options,
  values,
  onChange,
  maxInputLength,
  isInvalid,
  invalidError,
  OptionComponent,
  InputLabelComponent,
  isCreationAvailable,
  inputLabel,
  CustomElement,
  onAdd
}) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [customOptions, setCustomOptions] = useState({});

  const handleArrowClick = () => setOpen(!open);

  const handleCustomAdd = (data) => {
    onAdd(data, customOptions);
    setInputValue('');
    setCustomOptions({});
  };

  const handleAddClick = () => {
    const modifyStr = (str) => str.toLowerCase().trim();

    const isInputNameAlreadyExistIsSelectedValues = values
      .map((el) => modifyStr(el[optionLabel]))
      .includes(modifyStr(inputValue));
    if (isInputNameAlreadyExistIsSelectedValues) {
      dispatch(enqueueExistPrefixWarning());
      return;
    }

    const optionWithSameName = options.find(
      (el) => modifyStr(el[optionLabel]) === modifyStr(inputValue)
    );

    if (optionWithSameName?.id) {
      if (onAdd) {
        handleCustomAdd(optionWithSameName);
        return;
      }
      onChange(name, [...values, optionWithSameName]);
      setInputValue('');
      return;
    }

    const newOption = { [optionLabel]: inputValue };
    if (onAdd) {
      handleCustomAdd(newOption);
      return;
    }
    onChange(name, [...values, newOption]);
    setInputValue('');
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    value = reformatInputValue(value, maxInputLength);
    setInputValue(value);
  };

  const handleOptionClick = (option) => {
    if (values.find(({ id }) => id === option.id)) {
      onChange(
        name,
        values.filter(({ id }) => id !== option.id)
      );
    } else {
      onChange(name, [...values, option]);
    }
  };

  const handleKeyPress = (e) =>
    isCreationAvailable && inputValue?.length && e.key === 'Enter' && handleAddClick();

  return (
    <section className={styles.wrapper}>
      <div className={clsx(styles.field, isInvalid && styles.invalid)}>
        <TextField
          className={styles.field__input}
          variant="outlined"
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleArrowClick}>
                  <ExpandMoreIcon classes={{ root: `${styles.expand} ${open && styles.opened}` }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>

      {isCreationAvailable && (
        <Button
          className={clsx(styles.add_button, !inputValue.length && styles.disabled)}
          onClick={handleAddClick}>
          <AddIcon />
          {!isMobile && 'Add new'}
        </Button>
      )}

      <div className={styles.tip}>
        {InputLabelComponent ? (
          InputLabelComponent(isInvalid, <span className={styles.tip_error}>{invalidError}</span>)
        ) : (
          <>
            {!isInvalid ? (
              <span className={styles.tip_text}>
                {inputLabel || 'Select from the list or write to add a new field'}
              </span>
            ) : (
              <span className={styles.tip_error}>{invalidError}</span>
            )}
          </>
        )}
      </div>

      {!!inputValue.length && CustomElement && CustomElement(customOptions, setCustomOptions)}

      <HiddenSelectField
        open={open}
        setOpen={setOpen}
        options={options || []}
        values={values || []}
        optionLabel={optionLabel}
        onChange={handleOptionClick}
        OptionComponent={OptionComponent}
      />
    </section>
  );
}
