import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTeamMemberListApi = (query) =>
  api.get(`/TeamMembers?${queryToString(query)}`).then((res) => res.data);

export const getTeamMemberCalendarApi = (query) =>
  api.get(`/TeamMemberCalendar?${queryToString(query)}`).then((res) => res.data);

export const getSingleTeamMemberApi = (id) =>
  api.get(`/TeamMember?id=${id}`).then((res) => res.data);

export const getCreationFormApi = () => api.get('/TeamMember/CreationForm').then((res) => res.data);

export const getTeamMemberFilterCriteriaApi = (q) =>
  api.get(`/TeamMembers/FilterCriteria?${queryToString(q)}`).then((res) => res.data);

export const createTeamMemberApi = (params) =>
  api.post('/TeamMember', params).then((res) => res.data);

export const updateTeamMemberApi = (params) =>
  api.put('/TeamMember', params).then((res) => res.data);

export const deleteTeamMemberApi = (id) =>
  api.delete(`/TeamMember?id=${id}`).then((res) => res.data);

export const restoreTeamMemberApi = (id) =>
  api.put(`/TeamMember/Restore?teamMemberId=${id}`).then((res) => res.data);

export const deleteTeamMemberParameterApi = (query, prefix) =>
  api.delete(`/TeamMember${prefix}?${queryToString(query)}`).then((res) => res);

export const updateTeamMemberParameterApi = (params, prefix) =>
  api.put(`/TeamMember${prefix}`, params).then((res) => res);

export const getPermissionToCancelLastOperationApi = () =>
  api.get('/TeamMemberEmploymentStatus/CancelAction').then((res) => res.data);

export const cancelLastOperationApi = () =>
  api.post('/TeamMemberEmploymentStatus/CancelAction').then((res) => res);

// Edit Capacities Popup
export const getTeamMemberCapacitiesApi = () =>
  api.get('/TeamMemberCapacities').then((res) => res.data);

export const getCapacityListApi = () =>
  api.get('/TeamMemberCapacities/CreationForm').then((res) => res.data);

export const updateTeamMemberCapacitiesApi = (params) =>
  api.put('/TeamMemberCapacities', params).then((res) => res.data);
