import React from 'react';
import styles from './Details.module.scss';

import { useUserSelector } from 'hooks/UserManagement';

import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';
import Separator from 'components/Separator/Separator';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

import SectionTitle from './Title';

import { joinWithComma, pluck } from 'helpers/AppHelpers';

const allSelectedLabel = 'All Selected';

export default function UserNotificationsInfo() {
  const { currentUser } = useUserSelector();
  const {
    isGeneralReportRecipient,
    isBAARecipient,
    isTeamMemberStatusesRecipient,
    notificationAssetReportPrefixes,
    notificationAssetReportLocations,
    notificationTicketAndAssetUpdateLocations,
    notificationTicketAndAssetUpdatePrefixes,
    notificationReorderRecipientLocations,
    notificationLocationReportLocations,
    isSelectedAllAssetReportLocations,
    isSelectedAllAssetReportPrefixes,
    isSelectedAllLocationReportLocations,
    isSelectedAllTicketAndAssetUpdateLocations,
    isSelectedAllTicketAndAssetUpdatePrefixes,
    isSelectedAllReorderRecipientLocations
  } = currentUser;

  return (
    <div className={styles.details}>
      <SectionTitle>Asset Report</SectionTitle>
      <div className={styles.details__block}>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Location"
            value={
              isSelectedAllAssetReportLocations
                ? allSelectedLabel
                : joinWithComma(pluck(notificationAssetReportLocations, 'siteCode')) || ''
            }
            disableFlex
          />
        </div>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Prefix"
            value={
              isSelectedAllAssetReportPrefixes
                ? allSelectedLabel
                : joinWithComma(pluck(notificationAssetReportPrefixes, 'prefix')) || ''
            }
            disableFlex
          />
        </div>
      </div>

      <Separator />

      <SectionTitle>Location Report</SectionTitle>
      <div className={styles.details__block}>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Location"
            value={
              isSelectedAllLocationReportLocations
                ? allSelectedLabel
                : joinWithComma(pluck(notificationLocationReportLocations, 'siteCode')) || ''
            }
            disableFlex
          />
        </div>
      </div>

      <Separator />

      <SectionTitle>General Report</SectionTitle>
      <div className={styles.checkboxRow}>
        <label>Is general report recipient</label>
        <StyledCheckbox disabled checked={!!isGeneralReportRecipient} />
      </div>

      <Separator />

      <SectionTitle>Ticket & Asset Update</SectionTitle>
      <div className={styles.details__block}>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Location"
            value={
              isSelectedAllTicketAndAssetUpdateLocations
                ? allSelectedLabel
                : joinWithComma(pluck(notificationTicketAndAssetUpdateLocations, 'siteCode')) || ''
            }
            disableFlex
          />
        </div>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Prefix"
            value={
              isSelectedAllTicketAndAssetUpdatePrefixes
                ? allSelectedLabel
                : joinWithComma(pluck(notificationTicketAndAssetUpdatePrefixes, 'prefix')) || ''
            }
            disableFlex
          />
        </div>
      </div>

      <Separator />

      <SectionTitle>Loose Items</SectionTitle>
      <div className={styles.details__block}>
        <div className={styles.checkboxRow}>
          <label>Is Buy Ahead Account recipient</label>
          <StyledCheckbox disabled checked={!!isBAARecipient} />
        </div>
        <div className={styles.details__block_row}>
          <DetailedRow
            label="Re-order Recipient"
            value={
              isSelectedAllReorderRecipientLocations
                ? allSelectedLabel
                : joinWithComma(pluck(notificationReorderRecipientLocations, 'siteCode')) || ''
            }
            disableFlex
          />
        </div>
      </div>

      {['Personnel manager', 'Admin'].includes(currentUser?.userRole?.role) && (
        <>
          <Separator />

          <SectionTitle>Team Members</SectionTitle>
          <div className={styles.checkboxRow}>
            <label>Is Team member status recipient</label>
            <StyledCheckbox disabled checked={isTeamMemberStatusesRecipient} />
          </div>
        </>
      )}
    </div>
  );
}
