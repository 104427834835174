import React from 'react';
import styles from './GeneralReportTableRow.module.scss';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { USER_DETAILED_PATH } from 'constants/routeConstants';

import { getUserFullName } from 'helpers/AppHelpers';
import { NON_DRAT_USER_NAME } from '../../../helpers';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function GeneralReportTableRow({ row, onRemove }) {
  return (
    <StyledTableRow className={styles.tableRow}>
      <StyledTableCell className={styles.nameCell}>
        {row?.firstName ? (
          <LinkComponent path={`${USER_DETAILED_PATH}/${row?.id}`} name={getUserFullName(row)} />
        ) : (
          NON_DRAT_USER_NAME
        )}
      </StyledTableCell>
      <StyledTableCell className={styles.emailCell}>{row?.email || ''}</StyledTableCell>
      <StyledTableCell className={styles.roleCell}>{row?.userRole?.role || ''}</StyledTableCell>
      <StyledTableCell className={styles.removeCell}>
        <IconButton className={styles.icon_button} onClick={() => onRemove(row.id)}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}
