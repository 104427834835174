import {
  SET_CURRENT_PREFIX,
  SET_PREFIX_TYPES,
  SET_ASSET_FIELDS,
  CLEAR_CATEGORY_LIST_STATE,
  CLEAR_PREFIX_STATE
} from 'constants/reduceConstants';

const initialState = {
  currentPrefix: {},
  prefixTypes: [],
  assetFields: []
};

const prefixReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PREFIX:
      return {
        ...state,
        currentPrefix: payload
      };
    case SET_PREFIX_TYPES:
      return {
        ...state,
        prefixTypes: payload
      };
    case SET_ASSET_FIELDS:
      return {
        ...state,
        assetFields: payload
      };
    case CLEAR_CATEGORY_LIST_STATE:
    case CLEAR_PREFIX_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default prefixReducer;
