import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export function Input({ placeholder = 'Upload Files' }) {
  return (
    <>
      <AddCircleOutlineIcon />
      {placeholder}
    </>
  );
}
