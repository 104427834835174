import React, { useEffect, useState } from 'react';
import styles from './ParameterEditPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { MainButton } from 'components/StyledComponents';
import HeaderButton from 'components/DetailsComponents/HeaderButton';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import DeleteIcon from '@material-ui/icons/Delete';

export default function ParameterEditPopup({ open, setOpen, data, options }) {
  const isMobile = useMobileViewport();
  const [modalData, setModalData] = useState({});

  const methods = useForm({
    defaultValues: { name: '' },
    mode: 'onChange'
  });
  const { getValues, setError, reset, formState } = methods;
  const { errors } = formState;

  useEffect(() => {
    if (open) {
      reset(data);
    }
  }, [open]);

  const closeModal = () => setModalData({ isOpened: false });
  const closePopup = () => setOpen(false);

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;
  const errorProperties = [
    { name: 'name', inputType: 'text', errorMessage: options.field.errorMessage }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleApplyClick = () => {
    if (!validateForm()) return;
    options.buttons.onUpdate(getValues());
  };
  const handleDeleteClick = () => {
    if (options?.modalData?.delete) {
      setModalData(options.modalData.delete);
    } else {
      agreeModal();
    }
  };
  const agreeModal = () => {
    options.buttons.onDelete(getValues());
    closeModal();
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <section className={styles.wrapper}>
        <DialogHeader onClose={closePopup} title={isMobile ? '' : options.title}>
          {isMobile && options.buttons.isDeleteAvailable && (
            <HeaderButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </HeaderButton>
          )}
        </DialogHeader>
        {isMobile && <h2>{options.title}</h2>}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__input}>
              {options?.field?.label && <label>{options.field.label}*</label>}
              <FormInputText
                name="name"
                options={{ max: options.field.maxLength }}
                classes={styles.form__input_field}
              />
            </div>
          </div>
        </FormProvider>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" size="popup" />
          <MainButton
            text="Apply"
            action={handleApplyClick}
            type="primary"
            size="popup"
            isDisabled={isFormInvalid()}
          />
          {data?.id && !isMobile && options.buttons.isDeleteAvailable && (
            <MainButton text="Delete" action={handleDeleteClick} type="extra" size="popup" />
          )}
        </div>
      </section>
    </DialogWrapper>
  );
}
