import React, { useState } from 'react';
import styles from './GeoMap.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import MapComponent from 'components/MapComponent/MapComponent';
import MainButton from 'components/StyledComponents/MainButton';
import BackButton from 'components/BackButton/BackButton';

import { useMobileViewport } from 'hooks/useMobileViewport';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

export default function GeoMap({ open, setOpen, mapPreData, onSelect }) {
  const isMobile = useMobileViewport();
  const [mapData, setMapData] = useState({});

  const closeMap = () => setOpen(false);

  const selectPoint = () => {
    onSelect(mapData);
    closeMap();
  };

  return (
    <DialogWrapper open={open} onClose={closeMap}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {isMobile && <h2>Select on the map</h2>}
          {!isMobile ? (
            <IconButton className={styles.header__close} onClick={closeMap}>
              <CloseIcon />
            </IconButton>
          ) : (
            <BackButton onCancel={closeMap} />
          )}
        </div>
        <div className={styles.content}>
          <MapComponent
            inputLatLng={{
              lat: mapData?.lat || mapPreData?.lat || null,
              lng: mapData?.lng || mapPreData?.lng || null
            }}
            address={mapPreData.address}
            picker="true"
            onPick={setMapData}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <MainButton
            text="Ok"
            action={selectPoint}
            isDisabled={!mapData?.lat && !mapData?.lng}
            type="primary"
          />
        </div>
      </div>
    </DialogWrapper>
  );
}
