import { useSelector } from 'react-redux';

import {
  selectTabData,
  selectTabConfigData,
  selectFilterCriteriaData,
  selectVerificationStatisticsData,
  selectInspectionStatisticsData
} from 'pages/AssetsDashboards/VerificationAndInspection/selectors.js';

export function useAssetsDashboardsSelector() {
  const tab = useSelector(selectTabData());
  const tabConfig = useSelector(selectTabConfigData());

  const filterCriteria = useSelector(selectFilterCriteriaData());

  const verificationStatistic = useSelector(selectVerificationStatisticsData());

  const inspectionStatistic = useSelector(selectInspectionStatisticsData());

  return {
    tab,
    tabConfig,
    filterCriteria,
    verificationStatistic,
    inspectionStatistic
  };
}
