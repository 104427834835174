import DetailsHeader from 'components/DetailsComponents/Header';
import { useSelector } from 'react-redux';
import { selectSingleLocationData } from '../../../selectors';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useState } from 'react';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { LOCATION_EDIT_PATH, LOCATIONS_LIST_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { DELETE_LOCATION } from 'constants/dialogPopupsData';
import { useLocationActions } from '../../../hooks/useLocationActions';
import styles from './Header.module.scss';

export default function Header() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const { isAdminUser, isOperationsManagerUser, isLocationInConfig } = useUserConfig();

  const { id, siteCode, siteType } = useSelector(selectSingleLocationData());
  const { deleteLocationAction } = useLocationActions();

  const [dialogModalData, setDialogModalData] = useState({});

  const handleEditClick = () => history.push(`${LOCATION_EDIT_PATH}/${id}`);
  const handleDeleteClick = () => setDialogModalData(DELETE_LOCATION);
  const handleBackClick = () => history.push(LOCATIONS_LIST_PATH);

  const closeModal = () => setDialogModalData({});
  const agreeModal = () => deleteLocationAction(id);

  const TitleComponent = (
    <div className={styles.header}>
      <div className={styles.title}>{siteCode}</div>
      <div className={styles.subtitle}>{siteType?.name || ''}</div>
    </div>
  );

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <DetailsHeader breadcrumbs={[{ name: siteCode }]} TitleComponent={TitleComponent}>
        {(isAdminUser || (isOperationsManagerUser && isLocationInConfig(id))) && (
          <HeaderButton onClick={handleEditClick}>
            <CreateIcon />
            {!isMobile && <span>Edit this location</span>}
          </HeaderButton>
        )}
        {isAdminUser && (
          <HeaderButton onClick={handleDeleteClick}>
            <DeleteIcon />
            {!isMobile && <span>Remove this location</span>}
          </HeaderButton>
        )}
        {isMobile && (
          <HeaderButton onClick={handleBackClick}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
      </DetailsHeader>
    </>
  );
}
