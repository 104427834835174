import React from 'react';
import styles from './ClearFilterButton.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

export default function ClearFilterButton({ onClick }) {
  const handleClearClick = () => onClick();
  return (
    <IconButton className={styles.clear} onClick={handleClearClick}>
      Clear all
      <CloseIcon />
    </IconButton>
  );
}
