import React from 'react';
import { StickyTableCell, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import styles from './Content.module.scss';

function CategoryRowsBlock({ data, onPrefixClick }) {
  const getRows = () => {
    return data.assetPrefixes;
  };

  const getCounts = (row) => {
    return row.assetFields.map(({ value }) => value);
  };

  return (
    <>
      <StyledTableRow>
        <StickyTableCell className={styles.header} colSpan={10000}>
          <span>{data?.header || ''}</span>
        </StickyTableCell>
      </StyledTableRow>
      {getRows().map((el, index) => (
        <StyledTableRow key={index}>
          <StickyTableCell className={styles.white}>
            <span onClick={() => onPrefixClick(el?.prefix?.id)} className={styles.rowName}>
              {el?.prefix?.prefix || ''}
            </span>
          </StickyTableCell>
          <StyledTableCell className={styles.white}>{el?.['unitsAvailable'] || 0}</StyledTableCell>
          {getCounts(el).map((count, i) => (
            <StyledTableCell className={styles.white} key={i}>
              {count === undefined ? '-' : count || 0}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </>
  );
}

export default CategoryRowsBlock;
