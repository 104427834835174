import React, { useRef } from 'react';
import styles from './TicketTables.module.scss';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useLocationSelector } from '../../../../../hooks/useLocationSelector';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { useLocationActions } from '../../../../../hooks/useLocationActions';
import { useUserConfig } from 'hooks/useUserConfig';
import { getChangedByPageFilter, getChangedBySortFilter } from 'helpers/SummaryPagesHelpers';
import TicketTableRow from './TicketTableRow';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { getTicketsHeadConfig } from './tableConfig';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';

const TICKET_PARAM = 'closed';

export default function ClosedTicketsTable() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const { getTableLimit } = useUserConfig();

  const { closedTicketsList, closedTicketsListFilter } = useLocationSelector();
  const { getClosedTickets } = useLocationActions();

  const emptyResponse = () =>
    !closedTicketsList?.items?.length &&
    closedTicketsListFilter?.pagination?.page === closedTicketsList?.pageNumber;

  const changePage = (page, param) =>
    getClosedTickets(getChangedByPageFilter(closedTicketsListFilter, page), param);
  const sortColumn = (name) =>
    getClosedTickets(getChangedBySortFilter(closedTicketsListFilter, name));
  const getUpdatedByLimitFilter = () => ({
    ...closedTicketsListFilter,
    pagination: {
      ...closedTicketsListFilter.pagination,
      page: 1,
      limit: getTableLimit(LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  return (
    <>
      {!isMobile && (
        <div className={styles.header}>
          <DownloadCSVButton
            endpoint="ServiceTickets/Serialized"
            filter={{
              ...closedTicketsListFilter,
              filters: { ...closedTicketsListFilter.filters, isOpened: false },
              pagination: null,
              isCSVByLocation: true
            }}
          />
        </div>
      )}
      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={getTicketsHeadConfig(TICKET_PARAM)}
            onSortApply={sortColumn}
            sortRules={closedTicketsListFilter.sortRules}
          />
          <TableBody>
            {closedTicketsList?.items?.length && closedTicketsListFilter?.isDataReceived ? (
              closedTicketsList.items.map((row, index) => (
                <TicketTableRow key={index} row={row} type={TICKET_PARAM} />
              ))
            ) : (
              <StyledTableRow>
                {!!closedTicketsListFilter?.isDataReceived && (
                  <StyledTableCell colSpan={1000} className={styles.empty}>
                    No available data in the table.
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={closedTicketsList?.items?.length}
          currentPage={closedTicketsListFilter?.pagination?.page}
          totalPages={closedTicketsListFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={closedTicketsListFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER}
          getTableData={() => getClosedTickets(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
