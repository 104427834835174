import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import styles from './ActiveLocationsFilterSidebar.module.scss';
import { getSum } from 'helpers/AppHelpers';
import AddLooseItemsPopup from 'components/AddLooseItemsPopup/AddLooseItemsPopup';
import BackButton from 'components/BackButton/BackButton';
import { AddPicklistAssetPopup } from 'components/Popups';

function ActiveLocationsFilterSidebar({
  filter,
  setFilter,
  nearestLocationsData,
  getNearestMapLocationsAction,
  hideSidebar,
  isMobile
}) {
  const { nearestLocations } = nearestLocationsData;
  const [selectedItems, setSelectedItems] = useState([]);
  const [openAssetsPopup, setOpenAssetsPopup] = useState(false);
  const [openItemsPopup, setOpenItemsPopup] = useState(false);

  const isAssetTabSelected = filter?.selectedPanelTab === 0;

  useEffect(() => {
    setSelectedItems([]);
  }, [nearestLocationsData]);

  const getFieldValuesString = (fieldValues) => {
    if (fieldValues?.length) {
      const fieldsString = fieldValues
        .filter(Boolean)
        .map(({ assetField, value }) => `${value} ${assetField.displayName}`)
        .join(', ');

      return `: ${fieldsString}`;
    } else return '';
  };

  const getTotalValuesString = (assets) => {
    if (assets[0]?.assetFieldValues?.length) {
      const accumObj = {};
      const accumArray = [];
      const valuesArray = assets.flatMap(({ assetFieldValues }) =>
        assetFieldValues.filter(Boolean).map(({ assetField: { displayName }, value }) => {
          return { displayName, value };
        })
      );
      valuesArray.forEach(({ displayName, value }) => {
        displayName && value && (accumObj[displayName] = (accumObj[displayName] || 0) + +value);
      });
      for (const key in accumObj) {
        accumArray.push(`Total ${key}: ${accumObj[key]}`);
      }
      return accumArray.map((string, index) => (
        <div key={index}>
          <b>{string}</b>
        </div>
      ));
    } else return <b>Total: {assets.length}</b>;
  };
  const getLooseItemTotalValue = (items) => (
    <b>Total: {getSum(items.map(({ quantity }) => quantity || 0))}</b>
  );

  const onChange = (event) => {
    const value = +event.target.value;
    const assetIds = selectedItems.includes(value)
      ? selectedItems.filter((id) => id !== value)
      : [...selectedItems, value];
    setSelectedItems(assetIds);
  };

  const getAssets = () =>
    nearestLocations
      .flatMap(({ assetWithFields }) => assetWithFields.map(({ asset }) => asset))
      .filter(({ id }) => selectedItems.includes(+id));

  const onAddAssetsToPicklist = () => {
    if (isAssetTabSelected) {
      setOpenAssetsPopup(true);
    } else {
      setOpenItemsPopup(true);
    }
  };

  const updateTable = () => {
    setSelectedItems([]);
    const sendFilter = isAssetTabSelected
      ? {
          ...filter.asset,
          siteType: filter?.asset?.siteType?.name === 'All' ? null : filter?.asset?.siteType || null
        }
      : {
          ...filter.looseItem,
          siteType:
            filter?.looseItem?.siteType?.name === 'All' ? null : filter?.looseItem?.siteType || null
        };
    getNearestMapLocationsAction(sendFilter, false);
  };

  const onLoadMoreClick = () => {
    const entityValues = isAssetTabSelected
      ? {
          ...filter.asset,
          isLoadMore: true,
          siteType: filter?.asset?.siteType?.name === 'All' ? null : filter?.asset?.siteType || null
        }
      : {
          ...filter.looseItem,
          isLoadMore: true,
          siteType:
            filter?.looseItem?.siteType?.name === 'All' ? null : filter?.looseItem?.siteType || null
        };
    getNearestMapLocationsAction(entityValues);
    if (isAssetTabSelected) {
      setFilter({ ...filter, asset: { ...filter.asset, isLoadMore: true } });
    } else {
      setFilter({ ...filter, looseItem: { ...filter.looseItem, isLoadMore: true } });
    }
  };

  const isLoadMoreAvailable = () =>
    isAssetTabSelected ? !filter?.asset?.isLoadMore : !filter?.looseItem?.isLoadMore;

  return (
    <section className={styles.sidebarContainer}>
      {openAssetsPopup && (
        <AddPicklistAssetPopup
          open={openAssetsPopup}
          setOpen={setOpenAssetsPopup}
          screen="Map"
          assets={getAssets() || []}
          onSuccess={updateTable}
        />
      )}
      {openItemsPopup && (
        <AddLooseItemsPopup
          isMobile={isMobile}
          open={openItemsPopup}
          setOpen={setOpenItemsPopup}
          data={{ selectedItems, mode: 'picklist', disableEditingAndAdding: true }}
          callback={updateTable}
        />
      )}
      <section className={styles.sidebarContainer__pageHeader}>
        <h1>Locations</h1>
        {isMobile && <BackButton onCancel={hideSidebar} />}
        {!!selectedItems.length && (
          <Button className={styles.addAssetsButton} onClick={onAddAssetsToPicklist}>
            Add to picklist
          </Button>
        )}
      </section>
      <section className={styles.sidebarContainer__wrapper}>
        {!!nearestLocations?.length &&
          nearestLocations.map(
            ({ location, distanceInMiles, assetWithFields, locationLooseItems }, index) => (
              <div key={index} className={styles.sidebarContainer__locationBlock}>
                <h3>
                  {index + 1} {location?.siteCode}{' '}
                  <span>- {Math.round(distanceInMiles)} miles</span>
                </h3>
                <p>{location?.siteType?.name}</p>
                <div className={styles.sidebarContainer__locationBlock__assetsBlock}>
                  {!!assetWithFields?.length &&
                    assetWithFields.map(({ asset, assetFieldValues, isInPicklist }, assetIndex) => (
                      <FormControlLabel
                        key={assetIndex}
                        className={styles.checkboxItem}
                        control={
                          <Checkbox
                            classes={{ root: styles.checkbox, checked: styles.checked }}
                            value={asset.id}
                            onChange={onChange}
                            checked={!!selectedItems.includes(+asset.id)}
                          />
                        }
                        label={`${asset?.drCode}${getFieldValuesString(assetFieldValues)}`}
                        disabled={
                          !(asset.rentalStatus === 'Available' && isInPicklist?.name === 'No')
                        }
                      />
                    ))}
                  {!!locationLooseItems?.length &&
                    locationLooseItems.map(
                      (
                        { locationLooseItemId, looseItem, status, quantity, picklist },
                        itemIndex
                      ) => (
                        <FormControlLabel
                          key={itemIndex}
                          className={styles.checkboxItem}
                          control={
                            <Checkbox
                              classes={{ root: styles.checkbox, checked: styles.checked }}
                              value={locationLooseItemId}
                              onChange={onChange}
                              checked={!!selectedItems.includes(+locationLooseItemId)}
                            />
                          }
                          label={`${looseItem?.name}: ${quantity}${
                            status.name === 'InPicklist' ? ` (${picklist?.name || ''})` : ''
                          }`}
                          disabled={!(status.name === 'Available')}
                        />
                      )
                    )}
                </div>
                <div className={styles.sidebarContainer__locationBlock__summaryBlock}>
                  {isAssetTabSelected && getTotalValuesString(assetWithFields)}
                  {!isAssetTabSelected && getLooseItemTotalValue(locationLooseItems)}
                </div>
              </div>
            )
          )}
      </section>
      {isLoadMoreAvailable() && (
        <Button onClick={onLoadMoreClick} className={styles.sidebarContainer__loadMoreButton}>
          Load more
        </Button>
      )}
    </section>
  );
}

export default ActiveLocationsFilterSidebar;
