import { createSelector } from 'reselect';

const authData = (state) => state.authData;

export const selectIsLoggedIn = () =>
  createSelector([authData], (state) => state.isLoggedIn);

export const selectIsSignOut = () =>
  createSelector([authData], (state) => state.isSignOut);

export const selectAuthLink = () => createSelector([authData], (state) => state.authLink);
