import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';

export const addAssetsToPicklist = (data) => () =>
  api.addAssetsToPicklistPopup.addAssetsToPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        `The assets are successfully added to the picklist ${data?.pickList?.name || ''}`
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const addAssetsToLeavelist = (data) => () =>
  api.addAssetsToPicklistPopup.addAssetsToLeavelistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        `The assets are successfully added to the leavelist ${data?.leavelist?.name || ''}`
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const checkLocation = (query) => () =>
  api.addAssetsToPicklistPopup
    .checkLocationApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getPicklists = (query) => () =>
  api.addAssetsToPicklistPopup
    .getPicklistsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLocations = (query) => () =>
  api.addAssetsToPicklistPopup
    .getLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
