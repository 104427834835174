import React, { useCallback, useRef, useEffect, useState } from 'react';
import styles from './ExternalTrainings.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { BlueButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import ManageGroupPopup from 'components/ManageGroupPopup';
import EditCertificationPopup from 'components/EditCertificationPopup';

import { IconButton, Table, TableBody } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTrainingsActions, useTrainingsSelector } from 'hooks/Trainings';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

import { EXTERNAL_TRAININGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import {
  DELETE_CERTIFICATION,
  CONFIRM_DELETE_CERTIFICATION,
  CONFIRM_DELETE_CERTIFICATION_TYPE
} from 'constants/dialogPopupsData';

import {
  tableConfig,
  generateChips,
  generateInitialQuery,
  parseQuery,
  removeChip
} from './helpers';
import FilterModal from './Filter';

export function ExternalTrainings() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [modalData, setModalData] = useState({});
  const [openManageGroupPopup, setOpenManageGroupPopup] = useState(false);
  const [openEditCertificationPopup, setOpenEditCertificationPopup] = useState(false);
  const [editingCertification, setEditingCertification] = useState({});

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useTrainingsSelector();
  const {
    getTrainingsAction,
    getFilterCriteriaAction,
    setFilterAction,
    deleteCertificationAction,
    clearStateAction
  } = useTrainingsActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const getTrainings = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getTrainingsAction(parseQuery(query)).then((tableData) => {
        setTrainings(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getTrainingsAction]
  );

  const setTrainings = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = { getTableLimit, isAllLimitEnabled, filter };
      getTrainings(generateInitialQuery(q));
      setChips(generateChips(criteria, filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getTrainings(getChangedBySearchFilter(filter, value)),
    [filter, getTrainings]
  );
  const sortTable = useCallback(
    (rowName) => getTrainings(getChangedBySortFilter(filter, rowName)),
    [filter, getTrainings]
  );
  const applyFilter = useCallback(
    (values) => {
      getTrainings(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getTrainings]
  );
  const changeLimit = useCallback(
    () =>
      getTrainings(
        getChangedByLimitFilter(filter, getTableLimit(EXTERNAL_TRAININGS_TABLE_PARAMETER))
      ),
    [filter, getTableLimit, getTrainings]
  );
  const changePage = useCallback(
    (page, param) => getTrainings(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getTrainings]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const manageGroups = useCallback(() => setOpenManageGroupPopup(true), [setOpenManageGroupPopup]);

  const isCreatedSource = useCallback((source) => source?.name === 'Created', []);

  const handleEditCertificationClick = useCallback(
    (row) => {
      setEditingCertification(row);
      setOpenEditCertificationPopup(true);
    },
    [setEditingCertification, setOpenEditCertificationPopup]
  );

  const refreshTable = useCallback(() => getTrainings(filter), [getTrainings]);

  const createCertification = useCallback(
    () => setOpenEditCertificationPopup(true),
    [setOpenEditCertificationPopup]
  );

  const deleteCertification = useCallback(
    (deletedRow) => setModalData({ isOpened: true, deletedRow, ...DELETE_CERTIFICATION }),
    [setModalData]
  );

  const closeModal = useCallback(() => setModalData({}), [setModalData]);
  const agreeModal = useCallback(() => {
    const data = modalData.deletedRow;
    const allowDelete = modalData.type === CONFIRM_DELETE_CERTIFICATION_TYPE;

    deleteCertificationAction({ id: data.id, allowDelete }).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        setModalData({});
        refreshTable();
        return;
      }

      if (res.status === 400) {
        const errorMessage = res?.errors?.Id[0] || '';
        if (errorMessage.indexOf('This parameter is used') >= 0) {
          setModalData({ ...modalData, ...CONFIRM_DELETE_CERTIFICATION });
        }
      }
    });
  }, [modalData, deleteCertificationAction, setModalData, refreshTable]);

  const HeaderControls = () => (
    <div className={styles.header_controls}>
      <BlueButton
        label="Create Certification"
        fullOnMobile
        height="30px"
        onClick={createCertification}
      />
      <BlueButton label="Manage Groups" fullOnMobile height="30px" onClick={manageGroups} />
    </div>
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title={isMobile ? 'Certifications' : 'Training & Certifications'}>
          {!isMobile && <HeaderControls />}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <HeaderControls />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={index} className={styles.row_hover}>
                  <StyledTableCell className={styles.nameCell}>{row?.name || ''}</StyledTableCell>
                  <StyledTableCell className={styles.sourceCell}>
                    {row?.source?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.availableCell}>
                    {'available' in row && <CustomCheckbox value={row['available']} isDisabled />}
                  </StyledTableCell>
                  <StyledTableCell className={styles.groupCell}>
                    {row?.group?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.controls}>
                      <IconButton
                        className={styles.icon_button}
                        onClick={() => handleEditCertificationClick(row)}>
                        <CreateIcon />
                      </IconButton>

                      {isCreatedSource(row.source) && (
                        <IconButton
                          className={styles.icon_button}
                          onClick={() => deleteCertification(row)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={EXTERNAL_TRAININGS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      {openManageGroupPopup && (
        <ManageGroupPopup open={openManageGroupPopup} setOpen={setOpenManageGroupPopup} />
      )}

      {openEditCertificationPopup && (
        <EditCertificationPopup
          open={openEditCertificationPopup}
          setOpen={setOpenEditCertificationPopup}
          data={editingCertification}
          setData={setEditingCertification}
          refreshTable={refreshTable}
        />
      )}

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
