import React from 'react';

import RequestOrderGeneralDetails from './General';
import RequestOrderEntitiesDetails from './RequestEntities/Entities';

export default function RequestOrderCreateForm() {
  return (
    <>
      <RequestOrderGeneralDetails />
      <RequestOrderEntitiesDetails />
    </>
  );
}
