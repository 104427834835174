import React from 'react';
import styles from './FilterBlocks.module.scss';

import { FormDatePicker, FormLabel } from 'components/FormComponents';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import RemoveIcon from '@material-ui/icons/Remove';

import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';
import { formatDate } from 'helpers/AppHelpers';

export default function FilterDurations() {
  const { watch, setValue } = useFormContext();

  const date1FromWatcher = watch(`durations[0].dateFrom`);
  const date1ToWatcher = watch(`durations[0].dateTo`);
  const date2FromWatcher = watch(`durations[1].dateFrom`);
  const date2ToWatcher = watch(`durations[1].dateTo`);

  const handleCheckboxSelect = (value, index) => {
    if (!value) return;

    setValue(`durations[${index}].dateTo`, formatDate(new Date()));
  };

  return (
    <>
      <div className={styles.block__cell}>
        <FormLabel required>Duration 1</FormLabel>
        <div className={styles.block__cell_dates}>
          <FormDatePicker name={`durations[0].dateFrom`} small max={date1ToWatcher} />
          <RemoveIcon />
          <FormDatePicker name={`durations[0].dateTo`} small min={date1FromWatcher} />
        </div>
      </div>
      <div className={clsx(styles.block__cell, styles.checkbox)}>
        <div className={styles.block__cell_label}>
          <FormLabel>Set End Date as Current</FormLabel>
          <InfoTooltip text="By selecting the checkbox, the end date and data in the widget will be updated in accordance with the current date each day." />
        </div>

        <FormCheckbox
          name={`durations[0].endDateAsCurrent`}
          onSelectTriggered={(_, value) => handleCheckboxSelect(value, 0)}
        />
      </div>
      <div className={styles.block__cell}>
        <div className={styles.separator} />
        <FormLabel>Duration 2</FormLabel>
        <div className={styles.block__cell_dates}>
          <FormDatePicker name={`durations[1].dateFrom`} small max={date2ToWatcher} />
          <RemoveIcon />
          <FormDatePicker name={`durations[1].dateTo`} small min={date2FromWatcher} />
        </div>
      </div>
      <div className={clsx(styles.block__cell, styles.checkbox)}>
        <div className={styles.block__cell_label}>
          <FormLabel>Set End Date as Current</FormLabel>
          <InfoTooltip text="By selecting the checkbox, the end date and data in the widget will be updated in accordance with the current date each day." />
        </div>
        <FormCheckbox
          name={`durations[1].endDateAsCurrent`}
          onSelectTriggered={(_, value) => handleCheckboxSelect(value, 1)}
        />
      </div>
    </>
  );
}
