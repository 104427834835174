import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import { ROOT_PATH } from '../constants/routeConstants';
import { useSelector } from 'react-redux';
import { selectUserConfig } from 'pages/commonSelectors';
import { isAdminUser, isPersonnelManagerUser } from 'helpers/AppHelpers';

function ReadOnlyRightUsersGuard({ component }) {
  const history = useHistory();
  const { userRole, teamMember, departments } = useSelector(selectUserConfig());

  const suitableRole =
    isAdminUser(userRole) ||
    isPersonnelManagerUser(userRole) ||
    (!teamMember?.id && !!departments?.length);

  useEffect(() => {
    if (userRole) {
      if (!suitableRole) {
        history.push(ROOT_PATH);
      }
    }
  }, [userRole]);

  return suitableRole ? component : null;
}

export default withRouter(ReadOnlyRightUsersGuard);
