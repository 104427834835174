import React from 'react';
import styles from './components.module.scss';
import fileIcon from 'assets/images/fileIcon.svg';
import pdfIcon from 'assets/images/downloadPdfMobile.svg';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function DownloadPdfButton({ onClick }) {
  const handleClick = () => onClick();
  const isMobile = useMobileViewport();

  return (
    <button className={styles.download_button} onClick={handleClick}>
      <img src={!isMobile ? fileIcon : pdfIcon} alt="file" />
      <span>Download PDF File</span>
    </button>
  );
}
