import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ImagesUpload from 'components/FilesUploader/ImagesUpload/ImagesUpload';

export default function FormImagesUpload({ name = 'resources', format }) {
  const { control, setValue, getValues } = useFormContext();

  const handleChange = (files) => {
    const resources = getValues(name) || [];
    const otherFiles = resources?.filter((resource) => resource['resourceType']?.id !== 1) || [];
    const result = [...otherFiles, ...files];
    setValue(name, result, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <ImagesUpload
          images={value?.filter((resource) => resource['resourceType']?.id === 1) || []}
          onChange={handleChange}
          formatType={format}
        />
      )}
    />
  );
}
