import React, { useEffect } from 'react';

import { useTicketTypesActions } from 'hooks/TicketTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Wrapper from './Wrapper';
import Form from './Form';

import { enrichNameToId } from 'helpers/AppHelpers';

export default function TicketTypeEdit() {
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      active: true,
      assetPrefixes: [],
      description: '',
      name: '',
      reopening: false,
      severities: [],
      isSelectedAllAssetPrefixes: false
    },
    mode: 'onChange'
  });
  const { reset } = methods;

  const { getTicketTypeAction, getCreationFormAction, clearStateAction } = useTicketTypesActions();

  useEffect(() => {
    if (id) {
      getTicketTypeAction({ ticketTypeId: id }).then((res) =>
        reset({ ...res, severities: enrichNameToId(res?.severities || []) })
      );
    }
  }, [id]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
