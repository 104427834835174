import React from 'react';
import styles from './Buttons.module.scss';

import TransparentButton from './TransparentButton';

import csvIcon from 'assets/images/csvIcon.svg';

export default function DownloadCSVButton({ onClick }) {
  return (
    <TransparentButton label="Download CSV File" className={styles.csvButton} onClick={onClick}>
      <img src={csvIcon} alt="" />
    </TransparentButton>
  );
}
