import {
  enrichNameToId,
  getProjectName,
  getRandomId,
  getTeamMemberFullName
} from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name, prop) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id) {
      return {
        ...obj,
        key: key,
        displayName:
          key === 'teamMember'
            ? getTeamMemberFullName(obj)
            : key === 'project'
            ? getProjectName(obj)
            : obj[name] || '',
        chipId: getRandomId(),
        propertyOfValues: prop
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const {
    teamMembers,
    teamMemberDepartments,
    teamMemberCapacities,
    teamMemberStatuses,
    projects,
    timeOnProjects
  } = options;

  let chipsArray = [];

  if (inputFilter?.teamMemberIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMembers,
        inputFilter.teamMemberIds,
        'teamMember',
        'lastName',
        'teamMemberIds'
      )
    );
  }
  if (inputFilter?.teamMemberDepartmentIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberDepartments,
        inputFilter['teamMemberDepartmentIds'],
        'department',
        'name',
        'teamMemberDepartmentIds'
      )
    );
  }
  if (inputFilter?.teamMemberCapacityIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberCapacities,
        inputFilter['teamMemberCapacityIds'],
        'capacity',
        'name',
        'teamMemberCapacityIds'
      )
    );
  }
  if (inputFilter?.teamMemberStatusIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberStatuses,
        inputFilter['teamMemberStatusIds'],
        'employment',
        'name',
        'teamMemberStatusIds'
      )
    );
  }
  if (inputFilter?.projectIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(projects, inputFilter['projectIds'], 'project', 'name', 'projectIds')
    );
  }
  if (inputFilter?.timeOnProjectNames?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        enrichNameToId(timeOnProjects),
        inputFilter['timeOnProjectNames'],
        'timeOnProject',
        'displayName',
        'timeOnProjectNames'
      )
    );
  }

  let isDayInTwoDatesSelected = false;
  if (inputFilter?.fromDayIn && inputFilter?.toDayIn) {
    isDayInTwoDatesSelected = true;
    chipsArray.push({
      key: 'dayInFromTo',
      displayName: 'Date In: ' + inputFilter.fromDayIn + ' - ' + inputFilter.toDayIn,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.fromDayIn && !isDayInTwoDatesSelected) {
    chipsArray.push({
      key: 'dayInFrom',
      displayName: 'Date In: from ' + inputFilter.fromDayIn,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.toDayIn && !isDayInTwoDatesSelected) {
    chipsArray.push({
      key: 'dayInTo',
      displayName: 'Date In: to ' + inputFilter.toDayIn,
      chipId: getRandomId()
    });
  }

  let isDayOutTwoDatesSelected = false;
  if (inputFilter?.fromDayOut && inputFilter?.toDayOut) {
    isDayOutTwoDatesSelected = true;
    chipsArray.push({
      key: 'dayOutFromTo',
      displayName: 'Date Out: ' + inputFilter.fromDayOut + ' - ' + inputFilter.toDayOut,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.fromDayOut && !isDayOutTwoDatesSelected) {
    chipsArray.push({
      key: 'dayOutFrom',
      displayName: 'Date Out: from ' + inputFilter.fromDayOut,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.toDayOut && !isDayOutTwoDatesSelected) {
    chipsArray.push({
      key: 'dayOutTo',
      displayName: 'Date Out: to ' + inputFilter.toDayOut,
      chipId: getRandomId()
    });
  }

  if (inputFilter?.showAllHistory) {
    chipsArray.push({
      key: 'showAllHistory',
      displayName: 'See all history',
      chipId: getRandomId()
    });
  }

  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'dayInFromTo':
      return { ...values, fromDayIn: null, toDayIn: null };
    case 'dayInFrom':
      return { ...values, fromDayIn: null };
    case 'dayInTo':
      return { ...values, toDayIn: null };
    case 'dayOutFromTo':
      return { ...values, fromDayOut: null, toDayOut: null };
    case 'dayOutFrom':
      return { ...values, fromDayOut: null };
    case 'dayOutTo':
      return { ...values, toDayOut: null };
    case 'showAllHistory':
      return { ...values, showAllHistory: false };
    default:
      return {
        ...values,
        [chip.propertyOfValues]: values[chip.propertyOfValues].filter((id) => id !== chip.id)
      };
  }
};
