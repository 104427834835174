import React from 'react';
import styles from '../../components.module.scss';

import { usePicklistSelector } from 'hooks/Picklist';

import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';

export default function CountingTable() {
  const { assetsCounting } = usePicklistSelector();

  const { slicedPicklistAssets, total } = assetsCounting;
  const { assetCount, looseItemCount, looseItemQty } = total;

  return (
    !!slicedPicklistAssets?.length && (
      <div className={styles.counting}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Loose items (qty)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedPicklistAssets.map(
              ({ assetCount, looseItemCount, looseItemQty, status }, index) => (
                <TableRow key={index}>
                  <TableCell>{status.displayName || ''}</TableCell>
                  <TableCell>{assetCount || 0}</TableCell>
                  <TableCell>{(looseItemCount || 0) + ' (' + (looseItemQty || 0) + ')'}</TableCell>
                </TableRow>
              )
            )}
            <TableRow>
              <TableCell>Totals</TableCell>
              <TableCell>{assetCount || 0}</TableCell>
              <TableCell>{(looseItemCount || 0) + ' (' + (looseItemQty || 0) + ')'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  );
}
