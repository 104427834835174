import React from 'react';
import styles from '../../LooseItemDashboard.module.scss';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { LOCATION_DETAILED_PATH, LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import clsx from 'clsx';
import { emptyValue } from 'helpers/AppHelpers';

function LooseItemDashboardTable({ isMobile, tableData, isTableReversed, selectedItems }) {
  const getLocations = () => tableData.locations.filter(({ id }) => id);

  const getLooseItemCells = () =>
    tableData.locations[0].looseItems.filter(({ id }) => selectedItems.includes(id));

  const getTotalCountersRow = () => {
    const tableCounters = tableData.locations
      .filter(({ id }) => id)
      .map(({ looseItems }) => looseItems.filter(({ id }) => selectedItems.includes(id)));
    const totalCountersRow = tableCounters[0].map(() => [0]);

    tableCounters.forEach((row) => {
      row.forEach((cell, cellIndex) => {
        totalCountersRow[cellIndex] = totalCountersRow[cellIndex].concat(cell?.quantity || 0);
      });
    });
    return totalCountersRow.map((array) => array.reduce((sum, current) => sum + +current || 0, 0));
  };

  const getReversedTotalCounter = (locationIndex) => {
    const countArray = tableData.locations[locationIndex].looseItems
      .filter(({ id }) => selectedItems.includes(id))
      .map(({ quantity }) => quantity);
    return countArray.reduce((sum, current) => sum + current, 0);
  };

  const isReversed = () => !isTableReversed;

  const getCellCount = (locationIndex, looseItemId) => {
    const item = tableData.locations[locationIndex].looseItems.find(({ id }) => id === looseItemId);
    const baa = item?.byAheadAccount ? `${' (' + item.byAheadAccount + ')'}` : '';
    return item.quantity + baa;
  };

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}>
              {emptyValue}
            </StyledTableCell>
            {isReversed()
              ? getLocations().map(({ id, siteCode, deleted }, index) => (
                  <StyledTableCell key={index} align="center">
                    <LinkComponent
                      path={`${LOCATION_DETAILED_PATH}/${id}`}
                      name={siteCode}
                      isRedirectAvailable={!deleted}
                    />
                  </StyledTableCell>
                ))
              : getLooseItemCells().map(({ id, name }, headerIndex) => (
                  <StyledTableCell key={headerIndex} align="center">
                    <LinkComponent path={`${LOOSE_ITEM_DETAILED_PATH}/${id}`} name={name} />
                  </StyledTableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isReversed() ? (
            <>
              {getLooseItemCells().map((looseItem, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell
                    className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                    scope="row">
                    <LinkComponent
                      path={`${LOOSE_ITEM_DETAILED_PATH}/${looseItem.id}`}
                      name={looseItem.name || ''}
                    />
                  </StyledTableCell>
                  {getLocations().map((_, index) => (
                    <StyledTableCell key={index} scope="row" align="center">
                      {getCellCount(index, looseItem.id)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell
                  className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                  scope="row">
                  <b>TOTALS</b>
                </StyledTableCell>
                {getLocations().map((_, locationIndex) => (
                  <StyledTableCell key={locationIndex} scope="row" align="center">
                    <b>{getReversedTotalCounter(locationIndex)}</b>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </>
          ) : (
            <>
              {getLocations().map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell
                    className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                    scope="row">
                    <LinkComponent
                      path={`${LOCATION_DETAILED_PATH}/${row.id}`}
                      name={row.siteCode}
                      isRedirectAvailable={!row.deleted}
                    />
                  </StyledTableCell>
                  {row?.looseItems
                    ?.filter(({ id }) => selectedItems.includes(id))
                    ?.map((item, itemIndex) => (
                      <StyledTableCell key={itemIndex} scope="row" align="center">
                        {item.quantity}
                        {!!item?.byAheadAccount && `${' (' + item.byAheadAccount + ')'}`}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell
                  className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                  scope="row">
                  <b>TOTALS</b>
                </StyledTableCell>
                {getTotalCountersRow().map((cell, totalIndex) => (
                  <StyledTableCell key={totalIndex} scope="row" align="center">
                    <b>{cell}</b>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LooseItemDashboardTable;
