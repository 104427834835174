import React from 'react';
import styles from './LooseItemsCounts.module.scss';
import clsx from 'clsx';

export default function LooseItemsCounts({ counts }) {
  const { total, slicedLooseItems } = counts;

  const getCountByStatus = (status) =>
    slicedLooseItems?.find((el) => el?.status?.displayName === status)?.count || 0;

  const availableCount = getCountByStatus('Available');
  const usedInAssemblyCount = getCountByStatus('Used in assembly');
  const rentedCount = getCountByStatus('Rented');
  const inPicklistCount = getCountByStatus('In Picklist');
  const inLeavelistCount = getCountByStatus('In Leavelist');

  return (
    <div className={styles.counts}>
      <div className={styles.counts__row}>
        <span className={styles.counts__row_status}>Available:</span>
        <span className={styles.counts__row_number}>{availableCount}</span>
      </div>
      <div className={styles.counts__row}>
        <span className={styles.counts__row_status}>Rented:</span>
        <span className={styles.counts__row_number}>{rentedCount}</span>
      </div>
      <div className={styles.counts__row}>
        <span className={styles.counts__row_status}>Used in assembly:</span>
        <span className={styles.counts__row_number}>{usedInAssemblyCount}</span>
      </div>
      <div className={styles.counts__row}>
        <span className={styles.counts__row_status}>In picklist:</span>
        <span className={styles.counts__row_number}>{inPicklistCount}</span>
      </div>
      <div className={styles.counts__row}>
        <span className={styles.counts__row_status}>In leavelist:</span>
        <span className={styles.counts__row_number}>{inLeavelistCount}</span>
      </div>
      <div className={styles.counts__row}>
        <span className={clsx(styles.counts__row_status, styles.total)}>Total:</span>
        <span className={styles.counts__row_number}>{total || 0}</span>
      </div>
    </div>
  );
}
