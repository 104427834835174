import React, { useEffect } from 'react';
import styles from './components.module.scss';
import { useParams } from 'react-router-dom';
import { usePrefixActions, usePrefixSelector } from 'hooks/Prefix';

export default function PrefixDetailsWrapper({ children }) {
  const { id } = useParams();

  const { currentPrefix } = usePrefixSelector();
  const { getPrefixAction } = usePrefixActions();

  useEffect(() => {
    if (id) {
      getPrefixAction(id);
    }
  }, [id]);

  return (
    <section className={styles.wrapper}>{currentPrefix?.id === +id && <>{children}</>}</section>
  );
}
