import React, { useState } from 'react';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import LooseItemsTable from './LooseItemsTable/LooseItemsTable';
import AssetPrefixesTable from './AssetPrefixesTable/AssetPrefixesTable';

import styles from './ConfigurationItemsTable.module.scss';

export default function ConfigurationItemsTable() {
  const [currentTab, setCurrentTab] = useState('loose_items');

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'loose_items', label: 'Loose Items' },
    { id: 2, value: 'assets', label: 'Assets' }
  ];

  const onClickTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div className={styles.wrapper}>
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        onChange={onClickTab}
        selectedTab={currentTab}
        classes={styles.toggle}
      />

      {currentTab === 'loose_items' ? <LooseItemsTable /> : <AssetPrefixesTable />}
    </div>
  );
}
