import React from 'react';
import styles from './LocationInfo.module.scss';
import MainInfo from './MainInfo';
import ExtraInfo from './ExtraInfo';

export default function LocationInfo() {
  return (
    <section className={styles.wrapper}>
      <MainInfo />
      <ExtraInfo />
    </section>
  );
}
