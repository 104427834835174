import React, { useEffect, useState } from 'react';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import UserGeneralDetails from './General';
import UserScheduleInfo from './ScheduleInfo';
import UserNotificationsInfo from './NotificationsInfo';
import RequestOrderInfo from './RequestOrderInfo';

import { useUserSelector } from 'hooks/UserManagement';

const TOGGLE_BUTTONS = [
  { id: 1, value: 'general', label: 'General' },
  { id: 2, value: 'schedule', label: 'Team Schedule' },
  { id: 3, value: 'requestOrder', label: 'Request Order' },
  { id: 4, value: 'notifications', label: 'Notifications' }
];

export default function UserDetailsInfo() {
  const { currentUser } = useUserSelector();
  const { userRole } = currentUser;

  const [selectedTab, setSelectedTab] = useState('general');
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const userRoleIsSuperUser = userRole?.role === 'Super user';

    const filteredTabs = TOGGLE_BUTTONS.filter((tab) =>
      userRoleIsSuperUser ? tab.value !== 'requestOrder' : true
    );

    setTabs(filteredTabs);
  }, [currentUser]);

  const clickTab = (tab) => setSelectedTab(tab);

  return (
    <>
      <ToggleBar buttons={tabs} onChange={clickTab} selectedTab={selectedTab} />

      {selectedTab === 'general' && <UserGeneralDetails />}

      {selectedTab === 'schedule' && <UserScheduleInfo />}

      {selectedTab === 'requestOrder' && <RequestOrderInfo />}

      {selectedTab === 'notifications' && <UserNotificationsInfo />}
    </>
  );
}
