import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLoadMoreFlag } from 'pages/commonSelectors';
import { IconButton, Select, MenuItem } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from './TablePagination.module.scss';

export default function TablePagination({
  isMobile,
  rows,
  currentPage,
  totalPages,
  onPageSelect,
  selectAllOption,
  pageLimit,
  onLimitChange,
  hide,
  hideLimitSelector
}) {
  const rowsPerPageOptions = [10, 25, 50, selectAllOption && !isMobile ? 100 : null].filter(
    Boolean
  );
  const loadMoreFlag = useSelector(selectLoadMoreFlag());

  const pagesArray = Array(+totalPages || 1)
    .fill(null)
    .map((_, i) => i + 1);

  let start,
    end,
    resultsArray = [];
  const pagesCutOff = isMobile ? 1 : 3,
    ceiling = Math.ceil(pagesCutOff / 2),
    floor = Math.floor(pagesCutOff / 2);
  const minPagesBeforeCut = isMobile ? 4 : 6;
  const minUncutPagesCount = isMobile ? 3 : 5;

  const buildPagination = () => {
    if (pagesArray.length < pagesCutOff) {
      start = 0;
      end = pagesArray.length;
    } else if (currentPage >= 1 && currentPage <= ceiling) {
      start = 0;
      end = pagesCutOff;
    } else if (currentPage + floor >= pagesArray.length) {
      start = pagesArray.length - pagesCutOff;
      end = pagesArray.length;
    } else {
      start = currentPage - ceiling;
      end = currentPage + floor;
    }

    if (totalPages > minPagesBeforeCut) {
      if (start >= 0 && start <= 1) {
        resultsArray.push(...pagesArray.slice(0, minUncutPagesCount));
      } else {
        resultsArray.push(pagesArray[0], '...');
      }

      if (end >= totalPages - 2 && end <= totalPages) {
        resultsArray.push(...pagesArray.slice(totalPages - 5, totalPages));
      } else if (start >= 2) {
        resultsArray.push(
          ...pagesArray.slice(start, end),
          '...',
          pagesArray[pagesArray.length - 1]
        );
      } else {
        resultsArray.push('...', pagesArray[pagesArray.length - 1]);
      }
    } else {
      resultsArray.push(...pagesArray.slice(0, minPagesBeforeCut));
    }
  };

  const allNotSelected = () => {
    return !isMobile && pageLimit !== 100 ? true : isMobile;
  };

  const showLimitSelector = () => {
    return !hideLimitSelector && !(rows <= 10 && currentPage === 1 && totalPages === 1);
  };

  const showPageSelector = () => {
    return totalPages !== 1 && allNotSelected();
  };

  buildPagination();

  useEffect(() => {
    resultsArray = [];
    buildPagination();
  }, [currentPage]);

  useEffect(() => {
    if (pageLimit === 100 && loadMoreFlag && currentPage < totalPages) {
      onPageSelect(currentPage + 1);
    }
  }, [loadMoreFlag]);

  return (
    <>
      {!hide && (
        <section
          className={`${styles.paginationWrapper} ${!allNotSelected() && styles.fixedPosition}`}>
          {showLimitSelector() && (
            <section className={styles.pageLimitContainer}>
              <span>Rows per page:</span>
              <Select
                classes={{
                  root: styles.selectInput
                }}
                value={pageLimit || ''}
                onChange={onLimitChange}
                variant="outlined">
                {rowsPerPageOptions.length &&
                  rowsPerPageOptions.map((option, ind) => (
                    <MenuItem
                      classes={{
                        root: styles.selectInput__menu,
                        icon: styles.selectInput__icon
                      }}
                      value={option}
                      key={`${ind}_${option}`}>
                      {option === 100 ? 'All' : option}
                    </MenuItem>
                  ))}
              </Select>
            </section>
          )}
          {showPageSelector() && (
            <section className={styles.paginationContainer}>
              <IconButton
                className={styles.pageButton}
                onClick={() => onPageSelect(currentPage > 1 ? currentPage - 1 : 1)}
                component="span">
                <NavigateBeforeIcon />
              </IconButton>
              {resultsArray.map((page, index) => (
                <React.Fragment key={index}>
                  {typeof page !== 'string' ? (
                    <IconButton
                      className={`${styles.pageButton} ${
                        page === currentPage ? styles.active : ''
                      } ${styles.pageButton__number}`}
                      onClick={() => onPageSelect(page)}>
                      {page}
                    </IconButton>
                  ) : (
                    <IconButton className={styles.ellipsisButton}>{page}</IconButton>
                  )}
                  {index < resultsArray.length - 1 ? (
                    <div className={styles.pageDivider}></div>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              ))}
              <IconButton
                className={styles.pageButton}
                onClick={() =>
                  onPageSelect(currentPage < totalPages ? currentPage + 1 : totalPages)
                }
                component="span">
                <NavigateNextIcon />
              </IconButton>
            </section>
          )}
        </section>
      )}
    </>
  );
}
