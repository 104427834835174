import React from 'react';
import styles from './Tag.module.scss';
import CloseIcon from '@material-ui/icons/Close';

export default function Tag({ label, tagId, onDelete, ...props }) {
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(tagId);
  };

  return (
    <div className={styles.tag} {...props}>
      <span>{label}</span>
      <CloseIcon onClick={(e) => handleDeleteClick(e)} />
    </div>
  );
}
