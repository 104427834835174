import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const addAssetsToPicklistApi = (params) =>
  api.post('/PicklistAssets', params).then((res) => res);

export const addAssetsToLeavelistApi = (params) =>
  api.post('/LeavelistAssets', params).then((res) => res);

export const getPicklistsApi = () => api.get('/ShortedPicklists').then((res) => res.data);

export const checkLocationApi = (q) =>
  api.get(`/PicklistAssets/LocationCheck?${queryToString(q)}`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);
