import { getWeatherConditionsByCode } from 'helpers/WeatherHelpers';

export const getWeatherValue = (res) => {
  const temperature_max = res?.daily ? Math.floor(res.daily.temperature_2m_max[0]) : '';
  const temperature_min = res?.daily ? Math.floor(res.daily.temperature_2m_min[0]) : '';
  const windSpeed = res?.daily ? Math.floor(res.daily.windspeed_10m_max[0]) : '';
  const weatherConditions = res?.daily ? getWeatherConditionsByCode(res.daily.weathercode[0]) : '';

  return res?.daily
    ? `${weatherConditions}, H:${temperature_max}, L:${temperature_min}, ${windSpeed} m/h`
    : '';
};

export const getWeatherDetails = (fields) => {
  const fieldIndex = fields?.findIndex(({ data }) => data?.templates?.some(({ id }) => id === 1));
  const templateIndex = fields[fieldIndex]?.data?.templates?.findIndex(({ id }) => id === 1);

  const doesWeatherExist = !!fields[fieldIndex]?.data?.templates[templateIndex]?.id;

  const fieldName = `generalReportFields[${fieldIndex}].data.templates[${templateIndex}].value`;

  return { fieldName, doesWeatherExist };
};
