import React from 'react';
import styles from './ChipsContainer.module.scss';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useFormContext } from 'react-hook-form';

export default function ChipsContainer() {
  const { control, setValue, getValues } = useFormContext();

  const handleChipDelete = (chip) => {
    setValue(
      'looseItemCategories',
      getValues('looseItemCategories').filter(({ name }) => name !== chip.name),
      { shouldDirty: true }
    );
  };

  return (
    <Controller
      name="looseItemCategories"
      control={control}
      render={({ field: { value } }) => (
        <>
          {!!value?.length && (
            <div className={styles.chips}>
              {value.map((item) => (
                <Chip
                  key={item.id || item.keyId}
                  label={item?.name || ''}
                  deleteIcon={<CloseIcon />}
                  className={styles.chips__item}
                  onDelete={() => handleChipDelete(item)}
                />
              ))}
            </div>
          )}
        </>
      )}
    />
  );
}
