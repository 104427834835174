import { useSelector } from 'react-redux';
import {
  selectHistoryFilterCriteriaData,
  selectHistorySecondaryFilterCriteriaData,
  selectTMHistoryFilterData,
  selectTMHistoryTableData
} from 'pages/TeamSchedule/TeamMemberHistory/selectors';

export function useHistorySelector() {
  const historyList = useSelector(selectTMHistoryTableData());

  const filter = useSelector(selectTMHistoryFilterData());

  const initialFilterCriteria = useSelector(selectHistoryFilterCriteriaData());
  const secondaryFilterCriteria = useSelector(selectHistorySecondaryFilterCriteriaData());

  return { filter, historyList, initialFilterCriteria, secondaryFilterCriteria };
}
