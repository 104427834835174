import React, { useState } from 'react';
import styles from './Wrapper.module.scss';

import DetailsHeader from 'components/DetailsComponents/Header';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { StyledButton } from 'components/Buttons';

import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory, useParams } from 'react-router-dom';

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  REQUEST_ORDER_EDIT_PATH,
  TICKET_EDIT_PATH,
  TICKETS_LIST_PATH
} from 'constants/routeConstants';
import { DELETE_TICKET } from 'constants/dialogPopupsData';

export default function TicketDetailsHeader() {
  const { id } = useParams();
  const isMobile = useMobileViewport();
  const history = useHistory();

  const [modalData, setModalData] = useState({});

  const { isAdminUser } = useUserConfig();

  const { currentTicket } = useTicketSelector();
  const { deleteTicketAction } = useTicketActions();

  const { isOpened, assetPrefixes, looseItems, otherItems } = currentTicket;

  const breadcrumbs = [{ name: `Ticket #${id} details` }];
  const title = `Asset Ticket #${id}${isOpened ? '' : ' (Closed)'}`;

  const canCreateRequestOrder =
    !!assetPrefixes?.length || !!looseItems?.length || !!otherItems?.length;

  const handleEditClick = () => history.push(`${TICKET_EDIT_PATH}/${id}`);
  const handleRemoveClick = () => setModalData(DELETE_TICKET);
  const handleBackClick = () => history.push(TICKETS_LIST_PATH);

  const agreeModal = () => deleteTicketAction(id);
  const closeModal = () => setModalData({});

  const createRequestClick = () => history.push(`${REQUEST_ORDER_EDIT_PATH}?ticketId=${id}`);

  return (
    <>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <DetailsHeader breadcrumbs={breadcrumbs} title={title}>
        <HeaderButton onClick={handleEditClick}>
          <CreateIcon />
          {!isMobile && <span>Edit ticket</span>}
        </HeaderButton>
        {isAdminUser && (
          <HeaderButton onClick={handleRemoveClick}>
            <DeleteIcon />
            {!isMobile && <span>Remove ticket</span>}
          </HeaderButton>
        )}
        {isMobile && (
          <HeaderButton onClick={handleBackClick}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
        {isOpened && canCreateRequestOrder && !isMobile && (
          <StyledButton
            label="Create request"
            onClick={createRequestClick}
            classes={styles.create_request_button}
          />
        )}
      </DetailsHeader>
      {isOpened && canCreateRequestOrder && isMobile && (
        <StyledButton
          label="Create request"
          onClick={createRequestClick}
          classes={styles.create_request_button}
        />
      )}
    </>
  );
}
