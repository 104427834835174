import React from 'react';
import { useHistory } from 'react-router-dom';
import { routesList } from 'constants/breadcrumbRoutes';
import styles from './BreadcrumbsNav.module.scss';
import { Link, Breadcrumbs, Typography } from '@material-ui/core';
import { EXIT_WITHOUT_SAVING_BY_NAV } from 'constants/dialogPopupsData';
import { BASE_ADDRESS } from 'api/api';

export default function BreadcrumbsNav({
  itemsArray,
  formIsChanged,
  setDialogModalData,
  onLinkClick,
  setRouteUrl
}) {
  const history = useHistory();
  const routesArray = window.location.pathname.split('/');
  let breadcrumbsArray = routesArray
    .map((item) => routesList.find((route) => route.link === item))
    .filter(Boolean);

  if (itemsArray) {
    breadcrumbsArray = [...breadcrumbsArray, ...itemsArray];
  }

  const handleClick = (event, item) => {
    event.preventDefault();
    if (onLinkClick) {
      onLinkClick(item);
    } else if (setDialogModalData && formIsChanged) {
      setDialogModalData({
        ...EXIT_WITHOUT_SAVING_BY_NAV,
        isOpened: true,
        selectedRouteUrl: item.path
      });
      setRouteUrl && setRouteUrl(item.path);
    } else {
      history.push(item.path);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <Breadcrumbs
        itemsBeforeCollapse={0}
        itemsAfterCollapse={3}
        separator="›"
        maxItems={3}
        aria-label="breadcrumb">
        {!!breadcrumbsArray.length &&
          breadcrumbsArray.map((item, index) =>
            index < breadcrumbsArray.length - 1 ? (
              <Link
                className={styles.mainContainer__item}
                color="inherit"
                href={`${BASE_ADDRESS}${item.path}`}
                onClick={(e) => handleClick(e, item)}
                key={index}>
                {item.name}
              </Link>
            ) : (
              <Typography className={styles.mainContainer__item} color="textPrimary" key={index}>
                <b>{item.name}</b>
              </Typography>
            )
          )}
      </Breadcrumbs>
    </div>
  );
}
