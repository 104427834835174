import React, { useEffect, useState, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectProjectListData, selectProjectsFilter } from './selectors';
import {
  getProjectList,
  clearProjectListState,
  setProjectListFilter
} from 'actions/projectActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { formatDate } from 'helpers/AppHelpers';
import styles from './ProjectList.module.scss';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { PROJECT_DETAILED_PATH, PROJECT_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import ProjectListFilter from './Filter/ProjectListFilter';
import {
  LOCATION_LIST_TABLE_PARAMETER,
  PROJECT_LIST_TABLE_PARAMETER
} from 'constants/configTableConstants';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { tableHeadConfig } from './tableConfig';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { useUserConfig } from 'hooks/useUserConfig';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import { useMobileViewport } from 'hooks/useMobileViewport';

function ProjectList({
  projectListData,
  getProjectListAction,
  filter,
  setFilterAction,
  clearProjectListAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isUser, isConfigReceived, getTableLimit } = useUserConfig();

  const urlQuery = new URLSearchParams(window.location.search);
  const searchValue = urlQuery.get('searchQuery');

  const [openFilter, setOpenFilter] = useState(false);
  const [tableState, setTableState] = useState([]);

  const { filters, pagination, sortRules, responseReceived } = filter;

  const generateInitialQuery = () => {
    const queryFilter = {
      ...filter,
      filters: { ...filter.filters, searchQuery: searchValue || '' },
      pagination: { ...pagination, limit: getTableLimit(LOCATION_LIST_TABLE_PARAMETER) }
    };
    if (searchValue) {
      urlQuery.delete('searchQuery');
      history.replace({ search: urlQuery.toString() });
    }
    return queryFilter;
  };

  const setProjects = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  const getProjects = (query, isAutoload) => {
    setFilterAction(query);

    getProjectListAction(query).then((tableData) => {
      setProjects(tableData, query, isAutoload);
    });
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getProjects(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearProjectListAction();
    };
  }, []);

  const emptyResponse = () =>
    !projectListData?.items?.length && pagination?.page === projectListData?.pageNumber;

  const emptyFilters = () => {
    return (
      !projectListData?.items?.length &&
      filters.searchQuery === '' &&
      filters.active === true &&
      !filters.locationIds.length &&
      !filters.locationJobNumberIds.length &&
      pagination?.page === projectListData?.pageNumber
    );
  };

  const findMatches = (value) => getProjects(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getProjects(getChangedBySortFilter(filter, rowName));
  const applyFilter = (values) => getProjects(getChangedByApplyFilter(filter, values));
  const changePage = (page, param) =>
    getProjects(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const changeLimit = () =>
    getProjects(getChangedByLimitFilter(filter, getTableLimit(PROJECT_LIST_TABLE_PARAMETER)));

  const isButtonAvailable = () => isUser('Admin') || isUser('OperationalManager');

  return (
    <section
      className={clsx(styles.pageWrapper, !isButtonAvailable() && isMobile && styles.fitWrapper)}>
      <div className={styles.pageContainer}>
        <section className={styles.headerWrapper}>
          <SummaryHeader title="Project Summary">
            {isButtonAvailable() && (
              <MainButton
                text="Add new project"
                action={() => history.push(PROJECT_EDIT_PATH)}
                type="primary"
              />
            )}
          </SummaryHeader>
          <div
            className={clsx(
              styles.tableControls,
              !isButtonAvailable() && isMobile && styles.fitControls
            )}>
            <ProjectListFilter
              filter={filter}
              onApply={applyFilter}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              isMobile={isMobile}
            />
            <SearchTextFieldRefactored
              inputValue={filters.searchQuery}
              validationRule={/[А-Яа-я]/g}
              updateSearchParam={findMatches}
              rightSpace={isMobile}
            />
          </div>
        </section>
        {!(isMobile && openFilter) && (
          <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
            <Table stickyHeader={!isMobile}>
              <TableHeadComponent
                config={tableHeadConfig}
                sortRules={sortRules}
                onSortApply={sortTable}
              />
              <TableBody>
                {tableState?.length ? (
                  tableState.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <LinkComponent
                          path={`${PROJECT_DETAILED_PATH}/${row.id}`}
                          name={row.name}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.jobNumber}</StyledTableCell>
                      <StyledTableCell>{row['contractNumber']}</StyledTableCell>
                      <StyledTableCell>
                        {row.contractDate ? formatDate(row.contractDate) : ''}
                      </StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {!(isMobile && openFilter) && (
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={tableState?.length}
          currentPage={pagination.page}
          totalPages={pagination.totalPages}
          selectAllOption={true}
          onPageSelect={changePage}
          pageLimit={pagination.limit}
          hide={emptyResponse()}
          tableListParameter={PROJECT_LIST_TABLE_PARAMETER}
          getTableData={changeLimit}
          tableRef={tableRef}
        />
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  filter: selectProjectsFilter(),
  projectListData: selectProjectListData()
});

const mapDispatchToProps = {
  setFilterAction: setProjectListFilter,
  getProjectListAction: getProjectList,
  clearProjectListAction: clearProjectListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProjectList);
