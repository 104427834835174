import React, { useEffect, useState } from 'react';
import styles from './AddWidgetPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import { AddFormRow, EditableFormRow } from 'components/EditableComponents';

import MenuIcon from '@material-ui/icons/Menu';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { getUniqueID } from 'helpers/AppHelpers';

export default function AddWidgetPopup({ open, setOpen, data, dashboardType, onApply }) {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (open) {
      setFields(data.map((el) => ({ ...el, uniqueId: getUniqueID() })));
    }
  }, [open]);

  const closePopup = () => setOpen(false);
  const handleApply = () => {
    closePopup();
    onApply(fields);
  };

  const setGrabbingCursor = () => (document.body.style.cursor = 'grabbing');
  const setDefaultCursor = () => (document.body.style.cursor = 'default');

  const endDrag = ({ oldIndex, newIndex }) => {
    setDefaultCursor();
    const result = Array.from(fields);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
    setFields(result);
  };

  const SortableContainer = sortableContainer(({ children }) => {
    return <div className={styles.fields}>{children}</div>;
  });

  const SortableItem = sortableElement(({ value }) => (
    <div className={styles.fields__row}>
      <MenuIcon />
      <EditableFormRow
        data={value}
        label="name"
        max={60}
        onEdit={editField}
        onRemove={removeField}
        uniquePropertyName="uniqueId"
        disableDelete={fields?.length <= 1}
        className={styles.field_row}
      />
    </div>
  ));

  const addField = ({ value, setFieldValue }) => {
    setFieldValue('');
    setFields([...fields, { name: value, uniqueId: getUniqueID(), dashboardType }]);
  };

  const removeField = (id) => setFields(fields.filter(({ uniqueId }) => uniqueId !== id));

  const editField = ({ values, setFieldMode }) => {
    setFieldMode('display');
    setFields(fields.map((field) => (field.uniqueId === values.uniqueId ? values : field)));
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader title="Config Widget" onClose={closePopup} />
        <div className={styles.content}>
          <SortableContainer
            onSortStart={setGrabbingCursor}
            onSortEnd={endDrag}
            axis="y"
            distance={1}>
            {!!fields?.length &&
              fields.map((field, index) => (
                <SortableItem key={field.uniqueId} index={index} value={field} />
              ))}
          </SortableContainer>
          <AddFormRow placeholder="Add new widget" max={60} onAdd={addField} />
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton text="Apply" action={handleApply} type="primary" />
        </div>
      </div>
    </DialogWrapper>
  );
}
