import React, { useEffect, useRef, useState } from 'react';
import styles from './FormServerAssetSearch.module.scss';

import { Controller, useFormContext } from 'react-hook-form';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClickAwayListener, TextField } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { reformatInputValue, wait } from 'helpers/AppHelpers';

import { searchAssets } from 'actions/commonActions';

import clsx from 'clsx';

export default function FormServerAssetSearch({
  name,
  getLabel,
  getQuery,
  max = 100,
  rule,
  onSelect,
  isDisabled
}) {
  const dispatch = useDispatch();
  const { control, getValues, watch } = useFormContext();

  const isInputChange = useRef(false);

  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const watcher = watch(name);

  const handleInputChange = (e, value) => {
    if (e?.type === 'change' || e?.type === 'click') {
      isInputChange.current = e?.type === 'change';
      let interValue = e?.target?.value || value || '';
      interValue = reformatInputValue(interValue, max, rule);
      setInputValue(interValue);
    }
  };

  useEffect(() => {
    setInputValue(getLabel(getValues(name)));
  }, [watcher]);

  useEffect(async () => {
    if (!isInputChange.current) return;

    if (!debouncedInputValue) {
      closeDropdown();
      setOptions([]);
      onSelect(name, null);
      return;
    }

    const query = getQuery(debouncedInputValue);
    const assets = await dispatch(searchAssets(query));
    setOptions(assets?.items || []);
    assets?.items?.length && setOpen(true);
  }, [debouncedInputValue]);

  const closeDropdown = () => setOpen(false);
  const handleOpen = () => inputValue && options?.length && setOpen(true);

  const handleOptionSelect = (e, value) => {
    onSelect(name, value);
    wait(1).then(closeDropdown);
  };

  const handleBlur = async (e, value) => {
    if (inputValue !== getLabel(value)) {
      setInputValue(getLabel(value));
      isInputChange.current = false;
      const query = getQuery(getLabel(value));
      const assets = await dispatch(searchAssets(query));
      setOptions(assets?.items || []);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, fieldState: { error } }) => (
        <ClickAwayListener onClickAway={closeDropdown}>
          <div className={styles.wrapper} onClick={handleOpen}>
            <Autocomplete
              open={open}
              classes={{
                inputRoot: clsx(
                  styles.input_root,
                  error && styles.invalid,
                  isDisabled && styles.disabled
                ),
                popper: styles.popper,
                noOptions: styles.no_options,
                option: styles.option,
                listbox: styles.listbox
              }}
              options={options}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={handleOptionSelect}
              disableClearable
              onBlur={(e) => handleBlur(e, value)}
              getOptionLabel={getLabel}
              getOptionSelected={(option) => option.id === value?.id}
              value={value}
              popupIcon={null}
              disabled={isDisabled}
              filterOptions={(items) => items}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            {!!error && <span>{error.message}</span>}
          </div>
        </ClickAwayListener>
      )}
    />
  );
}
