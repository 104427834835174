import { emailRegexp, isDate } from './AppHelpers';

export const isParameterInvalid = (value, type) => {
  switch (type) {
    case 'text':
      return !value || !value.trim();
    case 'email':
      if (value) {
        return !emailRegexp.test(value);
      }
      return;
    case 'email-required':
      if (value) {
        return !emailRegexp.test(value);
      } else return true;
    case 'object':
      return Object.keys(value || {}).length === 0;
    case 'notEmpty':
      return value === undefined || value === null || value === '';
    case 'date-required':
      return value ? !isDate(new Date(value)) : true;
    case 'quantity':
      return !value || isNaN(value);
    case 'quantity-required':
      return !+value || isNaN(+value) || +value === 0;
    case 'array':
      return !value?.length;
    case 'boolean':
      return typeof value !== 'boolean';
    default:
      break;
  }
};

export const getErrorsProperties = (message) => ({ type: 'required', message });

export const focusFieldByError = (errorField, selector) => {
  if (errorField) {
    const el = document.querySelector(selector);
    el?.focus();
  }
};

export const focusErrorField = (selector) => {
  const el = document.querySelector(selector);
  el?.focus();
};
