import React from 'react';
import styles from './ReportTemplates.module.scss';

import clsx from 'clsx';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

import { IconButton, TextField } from '@material-ui/core';

import { reformatInputValue } from 'helpers/AppHelpers';

import { MainButton } from '../StyledComponents';

import UploadImagesField from './Uploader/UploadImagesField';

export default function ResourceReportTemplate({
  index,
  template,
  dragIsActive,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  published,
  isCopyAvailable
}) {
  const { data } = template;
  const { active, resource } = data;

  const onInput = (event) => {
    let value = event.target.value;

    value = reformatInputValue(value, 65000);

    onUpdate(index, {
      ...template,
      data: { ...template.data, text: value }
    });
  };

  const onSave = () => {
    if (data?.resource?.id) {
      onUpdate(index, { ...template, data: { ...template.data, active: false } });
    } else {
      onDelete(index);
    }
  };

  const onUpload = (file) =>
    onUpdate(index, { ...template, data: { ...template.data, resource: file } });

  const handleClearClick = () =>
    onUpdate(index, { ...template, data: { ...template.data, resource: null } });

  return (
    <section className={clsx(styles.templateWrapper, active ? styles.active : styles.inactive)}>
      {active ? (
        <div className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div></div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <div className={styles.uploadWrapper}>
              {resource?.id ? (
                <div className={styles.uploadWrapper__image}>
                  <div className={styles.imageWrapper}>
                    <img src={resource.link} alt="" />
                    <IconButton className={styles.imageWrapper_clear} onClick={handleClearClick}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <UploadImagesField onUploadFile={onUpload} />
              )}
            </div>
            <div className={styles.inputWrapper}>
              <TextField
                placeholder="You can add any comments to the picture"
                multiline
                rows={4}
                rowsMax={13}
                name="text"
                className={styles.textInput}
                variant="outlined"
                value={data.text || ''}
                onChange={onInput}
              />
              <span className={styles.inputWrapper__counter}>
                {data?.text ? data.text.length : 0}
                /65000
              </span>
            </div>
          </div>

          <div className={styles.activeTemplate__controls}>
            <div></div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={!data?.resource?.id}
            />
          </div>
        </div>
      ) : (
        <div className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div className={clsx(styles.inactiveTemplate__controls, dragIsActive && styles.hidden)}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              {isCopyAvailable() && (
                <button>
                  <FileCopyIcon onClick={() => onCopy(index)} />
                </button>
              )}
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div className={styles.uploadWrapper}>
              <div className={styles.uploadWrapper__image}>
                <img src={resource.link} alt="" />
              </div>
              {data.text && <label>{data.text}</label>}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
