import React, { useEffect, useState } from 'react';
import styles from './TeamMemberDetailed.module.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../selectors';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  CANCEL_LAST_OPERATION,
  CANCEL_LAST_OPERATION_TYPE,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_TYPE,
  RESTORE_TEAM_MEMBER
} from 'constants/dialogPopupsData';
import Calendar from './components/Calendar/Calendar';
import Header from './components/Header';
import GeneralDetails from './components/Details/General';
import ProfessionalDetails from './components/Details/Professional';
import Specifications from './components/Specifications/Specifications';
import Notes from './components/Notes';
import { useUserConfig } from 'hooks/useUserConfig';
import { useTeamMemberActions } from 'hooks/TeamMember';

export default function TeamMemberDetailed() {
  const { id } = useParams();
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const { isAdminUser, isPersonnelManagerUser, teamMemberConfigData } = useUserConfig();

  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const {
    getTeamMemberAction,
    deleteTeamMemberAction,
    restoreTeamMemberAction,
    getUserConfigAction,
    getPermissionToCancelLastOperationAction,
    cancelLastOperationAction,
    clearStateAction
  } = useTeamMemberActions();

  useEffect(() => {
    if (id) {
      getTeamMemberAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (teamMemberConfigData?.id && !id) {
      getTeamMemberAction(teamMemberConfigData?.id);
    }
  }, [teamMemberConfigData?.id]);

  const shouldDisplayContent = () => {
    return currentTeamMember?.id
      ? currentTeamMember.id === teamMemberConfigData?.id || currentTeamMember.id === +id
      : false;
  };

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const onAgree = () => {
    if (dialogModalData.type === DELETE_TEAM_MEMBER_TYPE) {
      deleteTeamMemberAction(currentTeamMember.id, getUserConfigAction);
    } else if (dialogModalData.type === CANCEL_LAST_OPERATION_TYPE) {
      cancelLastOperationAction().then((status) => {
        if (status === 200) {
          dialogModalData.callback && dialogModalData.callback();
          closeModal();
        }
      });
    } else {
      restoreTeamMemberAction(currentTeamMember.id, getUserConfigAction);
      closeModal();
    }
  };

  const closeModal = () => setDialogModalData({ isOpened: false });

  const isTeamMemberDeleted = () => currentTeamMember?.deleted;

  const deleteMember = () => setDialogModalData({ ...DELETE_TEAM_MEMBER, isOpened: true });
  const restoreMember = () => setDialogModalData({ ...RESTORE_TEAM_MEMBER, isOpened: true });

  const cancelAction = (callback) => {
    getPermissionToCancelLastOperationAction().then((res) => {
      if (!res) return;
      setDialogModalData({ ...CANCEL_LAST_OPERATION, isOpened: true, callback });
    });
  };

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      {shouldDisplayContent() && (
        <>
          <Header onDelete={deleteMember} onRestore={restoreMember} />
          <GeneralDetails />
          <ProfessionalDetails />
          <Specifications />
          {(isAdminUser || isPersonnelManagerUser) && <Notes />}
          {!isTeamMemberDeleted() && <Calendar onCancelAction={cancelAction} />}
        </>
      )}
    </section>
  );
}
