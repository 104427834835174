import React from 'react';
import styles from './RequestEntities.module.scss';

import clsx from 'clsx';

import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';

import { useRequestOrderSelector } from 'hooks/RequestOrder';

import EntityChip from './EntityChip';

export default function RequestAssetEntities({ index }) {
  const { creationFormData } = useRequestOrderSelector();
  const { assetPrefixes } = creationFormData;

  return (
    <>
      <div className={clsx(styles.entities__block_row, styles.wider)}>
        <label>Prefix*</label>
        <FormAutoMultiSelect
          name={`requestEntityData[${index}].requestOrderAssetPrefixes`}
          menuItems={assetPrefixes}
          options={{
            label: 'prefix',
            selectAll: false,
            disableByObjectTracker: false,
            hideTags: true
          }}
          classes={clsx(styles.special)}
        />
      </div>
      <FormChipsContainer
        fieldName={`requestEntityData[${index}].requestOrderAssetPrefixes`}
        getLabel={(item) => item?.prefix || ''}
        ChipComponent={(chipId, chip, chipIndex) => (
          <EntityChip
            key={chipId}
            Chip={chip}
            quantityName={`requestEntityData[${index}].requestOrderAssetPrefixes[${chipIndex}.quantity]`}
            resourcesName={`requestEntityData[${index}].requestOrderAssetPrefixes[${chipIndex}.resources]`}
          />
        )}
        direction="column"
      />
    </>
  );
}
