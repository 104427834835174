import React, { useEffect, useState } from 'react';
import styles from './GenerateReportQRCodesPopup.module.scss';

import {
  FormLabel,
  FormMultipleAssetSearch,
  FormRow,
  FormSearchInput
} from 'components/FormComponents';
import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { MainButton } from 'components/StyledComponents';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormInputRadio from 'components/FormComponents/FormInputRadio/FormInputRadio';
import Hint from 'components/Hint';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { fileDownload } from 'helpers/AppHelpers';

import { getReportQRCodesGeneratorCreationForm, generateReportQRCodes } from 'actions/qrActions';

const MAX_QUANTITY = 250;

export function GenerateReportQRCodesPopup({ open, setOpen }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState({});
  const { qrCodeSizes, generalReportTemplates } = options;

  const methods = useForm({
    defaultValues: {
      generalReportTemplate: {},
      assetIds: [],
      assets: [],
      qrCodeSize: null,
      qty: 1
    },
    mode: 'onChange'
  });
  const { getValues, setError, setValue, formState, watch } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  useEffect(() => {
    dispatch(getReportQRCodesGeneratorCreationForm()).then((res) => setOptions(res || {}));
  }, []);

  const validateForm = () => {
    const { generalReportTemplate, assets, qty } = getValues();
    const fieldsToValidate = [
      {
        name: 'generalReportTemplate',
        errorMessage: 'Field is required',
        isInvalid: !generalReportTemplate?.id
      },
      {
        name: 'assets',
        errorMessage: 'Field is required',
        isInvalid: !assets?.length,
        shouldCheck: !!generalReportTemplate?.id
      },
      {
        name: 'qty',
        errorMessage: 'Field is required',
        isInvalid: qty === ''
      },
      {
        name: 'qty',
        errorMessage: `Can't be less than "1" and more than "${MAX_QUANTITY}"`,
        isInvalid: qty < 1 || qty > MAX_QUANTITY,
        shouldCheck: qty !== '' && (qty < 1 || qty > MAX_QUANTITY)
      },
      {
        name: 'qty',
        errorMessage: `Total qty of generated QR codes can't be more than ${MAX_QUANTITY}`,
        isInvalid: (assets?.length || 1) * qty > MAX_QUANTITY,
        shouldCheck:
          qty !== '' &&
          qty >= 1 &&
          qty <= MAX_QUANTITY &&
          (assets?.length || 1) * qty > MAX_QUANTITY
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid, shouldCheck = true }) => {
      if (isInvalid && shouldCheck) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const closePopup = () => setOpen(false);

  const handleGenerateClick = () => {
    if (!validateForm()) return;

    dispatch(generateReportQRCodes(getValues())).then((res) => {
      if ([200, 201].includes(res.status)) {
        fileDownload(res.data);
        closePopup();
      }
    });
  };

  const selectTemplate = (name, value) => {
    setValue(name, value);
    setValue('assets', []);
    setValue('assetIds', []);
  };

  const templateWatcher = watch('generalReportTemplate');

  return (
    !!qrCodeSizes?.length && (
      <DialogWrapper open={open} onClose={closePopup}>
        <div className={styles.dialog}>
          <DialogHeader
            onClose={closePopup}
            title="Generate QR Code for Report"
            classes={styles.title}
          />

          <FormProvider {...methods}>
            <div className={styles.form}>
              <FormRow className={styles.form__row}>
                <FormLabel>Report Name*</FormLabel>
                <FormSearchInput
                  name="generalReportTemplate"
                  options={generalReportTemplates || []}
                  onSelect={selectTemplate}
                />
              </FormRow>

              {!!templateWatcher?.id && (
                <FormRow type="column" className={styles.form__assets}>
                  <FormLabel required>Enter Asset DR Codes; codes must be valid DR Codes</FormLabel>
                  <FormMultipleAssetSearch
                    name="assetIds"
                    listName="assets"
                    className={styles.form__assets_field}
                    getQueryOptions={(searchQuery) => ({
                      pagination: { limit: 40, page: 1 },
                      filters: {
                        isDeleted: false,
                        generalReportTemplateId: getValues('generalReportTemplate').id,
                        searchQuery
                      }
                    })}
                  />
                </FormRow>
              )}

              {!!qrCodeSizes?.length && (
                <FormRow type="column">
                  <FormLabel>Select QR code size</FormLabel>
                  <FormInputRadio name="qrCodeSize" options={qrCodeSizes || []} />
                </FormRow>
              )}

              <FormRow className={styles.form__qty}>
                <FormLabel>Set qty of QR codes per Asset*</FormLabel>
                <FormInputText
                  name="qty"
                  options={{ focus: true, type: 'quantity' }}
                  classes={styles.inputs__qty}
                />
              </FormRow>

              <Hint>{`Total generated quantity of QR codes can't exceed ${MAX_QUANTITY}.`}</Hint>
            </div>
          </FormProvider>

          <div className={styles.footer}>
            <MainButton text="Cancel" action={closePopup} type="secondary" />
            <MainButton
              text="Generate"
              action={handleGenerateClick}
              type="primary"
              isDisabled={isFormInvalid}
            />
          </div>
        </div>
      </DialogWrapper>
    )
  );
}
