import React from 'react';
import styles from './Details.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

import SectionTitle from '../SectionTitle';

import { useFormContext } from 'react-hook-form';
import { useCreateAssetsBatchSelector } from 'hooks/CreateBatchOfAssets';

export default function LicenseDetails() {
  const { setValue } = useFormContext();

  const { creationForm } = useCreateAssetsBatchSelector();
  const { states } = creationForm;

  return (
    <div className={styles.wrapper}>
      <SectionTitle>License Information</SectionTitle>
      <div className={styles.details}>
        <div className={styles.details__block}>
          <div className={styles.details__block_row}>
            <label>State</label>
            <FormSelectInput
              name="assetLicense.licenseState"
              options={states || []}
              onSelect={({ name, value }) => setValue(name, value, { shouldDirty: true })}
            />
          </div>
          <div className={styles.details__block_row}>
            <label>Plate number</label>
            <FormInputText
              name="assetLicense.licensePlate"
              options={{ max: 20, rule: /[^0-9A-Za-z\\-]/g }}
            />
          </div>
          <div className={styles.details__block_row}>
            <label>Registration Expiration</label>
            <div className={styles.details__block_input}>
              <FormDatePicker name="assetLicense.registrationExpirationAtUtc" />
            </div>
          </div>
          <div className={styles.details__block_row}>
            <label>DOT Registration Expiration</label>
            <div className={styles.details__block_input}>
              <FormDatePicker name="assetLicense.dotRegistrationExpirationAtUtc" />
            </div>
          </div>
          <div className={styles.details__block_row}>
            <label>Make</label>
            <FormInputText name="assetLicense.make" options={{ max: 255 }} />
          </div>
          <div className={styles.details__block_row}>
            <label>Model</label>
            <FormInputText name="assetLicense.model" options={{ max: 255 }} />
          </div>
          <div className={styles.details__block_row}>
            <label>Model year</label>
            <div className={styles.details__block_input}>
              <FormDatePicker name="assetLicense.modelYear" format="YYYY" placement="top" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
