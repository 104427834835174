import React from 'react';
import styles from '../components.module.scss';
import AccordionWrapper from '../../../commonComponents/AccordionWrapper';
import { formatDate } from 'helpers/AppHelpers';
import SignaturePicture from './SignaturePicture';

function ResponsibleDetails({ isMobile, data, onUpdate, onDelete }) {
  return (
    <AccordionWrapper title="Responsible for Delivery" isMobile={isMobile}>
      <div className={styles.vertical}>
        {data.map(({ id, name, phone, date, signature, popup }) => (
          <div className={styles.details} key={id}>
            <div className={styles.details__block}>
              <div className={styles.details__block_item}>
                <label>{name.label}:</label>
                <span>{name.value}</span>
              </div>
              {!isMobile && (
                <SignaturePicture
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  signature={signature}
                  popupPreData={popup}
                />
              )}
            </div>
            <div className={styles.details__block}>
              <div className={styles.details__block_item}>
                <label>{date.label}:</label>
                <span>{date?.value && formatDate(date.value)}</span>
              </div>
              <div className={styles.details__block_item}>
                <label>{phone.label}:</label>
                <span>{phone.value}</span>
              </div>
              {isMobile && (
                <SignaturePicture
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  signature={signature}
                  popupPreData={popup}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </AccordionWrapper>
  );
}

export default ResponsibleDetails;
