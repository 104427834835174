import React from 'react';
import styles from './Map.module.scss';

import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';

import { CENTER_POSITION, DEFAULT_ZOOM, MIN_ZOOM, ZOOM_CONTROL_POSITION } from './helpers';
import { MarkerCluster } from './MarkerCluster';

export function Wrapper({ markers, popupContent, children }) {
  return (
    <div className={styles.map_wrapper}>
      <MapContainer
        className={styles.map}
        tap={false}
        zoomControl={false}
        center={CENTER_POSITION}
        zoom={DEFAULT_ZOOM}
        minZoom={MIN_ZOOM}>
        <TileLayer
          attribution="Google Maps"
          url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en"
        />

        <ZoomControl position={ZOOM_CONTROL_POSITION} />

        <MarkerCluster markers={markers} popupContent={popupContent} />
      </MapContainer>

      {children}
    </div>
  );
}
