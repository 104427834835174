import { useSelector } from 'react-redux';

import {
  selectFilterData,
  selectCreationForm
} from 'pages/AssetTickets/TicketRootCausesSummary/selectors';

export function useRootCausesSelector() {
  const filter = useSelector(selectFilterData());

  const creationForm = useSelector(selectCreationForm());

  return {
    filter,
    creationForm
  };
}
