import React from 'react';
import styles from './TurnoverStatisticsChart.module.scss';

import GroupedVerticalBar from 'components/Charts/GroupedVerticalBar/GroupedVerticalBar';

import { dashboardLabels, getColumnName } from '../../../helpers';

export default function TurnoverStatisticsChart({ data }) {
  const { durations, name, severities } = data;

  const series = durations.map(({ dateFrom, dateTo, severityWithCount }) => ({
    name: getColumnName(dateFrom, dateTo),
    data: severityWithCount.map(({ count }) => count)
  }));

  const categories = severities.map(({ displayName }) => displayName);

  return (
    <div className={styles.chart}>
      <div className={styles.chart__block}>
        <GroupedVerticalBar
          hideToolbar
          series={series}
          categories={categories}
          fileName={dashboardLabels.ticketTurnover + ' ' + name}
          legendPosition="top"
          columnWidth="50px"
        />
      </div>
    </div>
  );
}
