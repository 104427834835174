import { useSelector } from 'react-redux';

import {
  selectFilterData,
  selectCreationFormData
} from 'pages/AssetTickets/TicketTypesSummary/selectors';

export function useTicketTypesSelector() {
  const filter = useSelector(selectFilterData());

  const creationForm = useSelector(selectCreationFormData());

  return {
    filter,
    creationForm
  };
}
