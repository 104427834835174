import React from 'react';

import ContentWrapper from './Wrapper';
import ConditionalRender from './ConditionalRender';
import SearchTables from './SearchTables';

export default function SearchContent() {
  return (
    <ContentWrapper>
      <ConditionalRender>
        <SearchTables />
      </ConditionalRender>
    </ContentWrapper>
  );
}
