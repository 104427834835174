import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormRow, FormLabel } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useActiveLocationsMapSelector } from 'hooks/ActiveLocationsMap';

import { defaultFilter } from '../helpers';
import { enrichNameToId } from 'helpers/AppHelpers';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();
  const { filter, filterCriteria } = useActiveLocationsMapSelector();

  const {
    locations,
    assetPrefixes,
    assetFields,
    projects,
    looseItemCategories,
    looseItems,
    looseItemFields,
    siteType,
    rentalStatuses,
    ticketSeverities,
    looseItemLocationStatuses
  } = filterCriteria;

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { reset, setValue, getValues } = methods;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const clearFilter = () => reset(defaultFilter.filters);
  const closePopup = () => setOpen(false);
  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locations || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="assetPrefixIds"
                  menuItems={assetPrefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>{`Asset's Status`}</FormLabel>
                <FormMultiSelect
                  trackById
                  name="rentalIds"
                  options={rentalStatuses || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>{`Asset's Operational Status`}</FormLabel>
                <FormMultiSelect
                  trackById
                  name="severities"
                  options={enrichNameToId(ticketSeverities) || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Numeric Prefix Fields</FormLabel>
                <FormAutoMultiSelect
                  name="assetFieldIds"
                  menuItems={assetFields || []}
                  options={{
                    label: 'displayName',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              {!isMobile && (
                <FormRow type="checkbox">
                  <FormLabel>Non-owned?</FormLabel>
                  <FormCheckbox name="isNonOwned" />
                </FormRow>
              )}
            </div>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Project</FormLabel>
                <FormAutoMultiSelect
                  name="projectIds"
                  menuItems={projects || []}
                  options={{
                    labelType: 'project',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Location Type</FormLabel>
                <FormMultiSelect
                  trackById
                  name="siteTypeIds"
                  options={siteType || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Loose Items Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="looseItemStatusNames"
                  options={enrichNameToId(looseItemLocationStatuses) || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Loose Items Category</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemCategoryIds"
                  menuItems={looseItemCategories || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Loose Items</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemIds"
                  menuItems={looseItems || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Loose Items Fields</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemCustomFieldIds"
                  menuItems={looseItemFields || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              {isMobile && (
                <FormRow type="checkbox">
                  <FormLabel>Non-owned?</FormLabel>
                  <FormCheckbox name="isNonOwned" />
                </FormRow>
              )}
              <FormRow type="checkbox">
                <FormLabel>Show with BAA qty</FormLabel>
                <FormCheckbox name="showWithBaa" />
              </FormRow>
              <FormRow type="checkbox">
                <FormLabel>Show with reorder trigger</FormLabel>
                <FormCheckbox name="showWithReorderTrigger" />
              </FormRow>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
