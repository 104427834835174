import { useSelector } from 'react-redux';

import {
  selectActivePicklistData,
  selectConfigurationsData,
  selectLooseItemsData
} from 'pages/PicklistAndLeavelist/AddBomToPicklist/selectors';

import { selectUnsavedFormData } from 'pages/commonSelectors';

export function useAddBomSelector() {
  const activePicklist = useSelector(selectActivePicklistData());
  const configurations = useSelector(selectConfigurationsData());
  const looseItems = useSelector(selectLooseItemsData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    activePicklist,
    configurations,
    looseItems,
    unsavedFormData
  };
}
