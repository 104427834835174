import {
  SET_NOTIFICATION_CENTER_TAB,
  CLEAR_NOTIFICATION_CENTER_STATE
} from 'constants/reduceConstants';
import api from '../api';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const getNotificationCenterData = (query) => () => {
  return api.notificationCenter
    .getNotificationCenterDataApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getFilterCriteria = (query) => () =>
  api.notificationCenter
    .getFilterCriteriaApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getPopupCreationForm = (q) => () =>
  api.notificationCenter
    .getPopupCreationFormApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const addRecipient = (data) => () => {
  return api.notificationCenter.addRecipientApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const editRecipient = (data) => () => {
  return api.notificationCenter.editRecipientApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteRecipient = (q) => () => {
  return api.notificationCenter.deleteRecipientApi(q).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const checkEmail = (data) => () =>
  api.notificationCenter
    .checkEmailApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setTab = (payload) => (dispatch) => {
  dispatch({ type: SET_NOTIFICATION_CENTER_TAB, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATION_CENTER_STATE });
};
