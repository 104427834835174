import React, { useRef, useEffect, useState } from 'react';
import styles from './Multiselect.module.scss';
import { Checkbox, Select, MenuItem } from '@material-ui/core';
import { StyledSelect } from 'components/StyledComponents/';
import ExpandMore from '@material-ui/icons/ExpandMore';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;

export default function Multiselect({
  name,
  valuesArray,
  options,
  onFilterSelect,
  selectAll,
  isInvalid,
  disabled
}) {
  const selectorRef = useRef(null);
  const [menuProps, setMenuProps] = useState({});

  useEffect(() => {
    if (selectorRef.current && document.body?.offsetHeight) {
      const elemPosition = selectorRef.current.getBoundingClientRect();
      const windowHeight = document.body.offsetHeight;
      const popupHeight =
        ITEM_HEIGHT * (!options?.length ? 1 : options?.length > 8 ? 8 : options?.length) +
        2 * ITEM_PADDING_TOP;
      const isOverlapped = windowHeight - elemPosition.bottom - popupHeight < 0;
      setMenuProps({
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 8 + 2 * ITEM_PADDING_TOP,
            marginTop: isOverlapped ? 0 : 38,
            boxSizing: 'border-box'
          }
        },
        getContentAnchorEl: () => null,
        autoFocus: false
      });
    }
  }, [selectorRef.current, document.body, options]);

  const onSelect = (e) => {
    if (e?.currentTarget?.innerText === 'No available items') {
      return;
    }
    const selectAllOption = e.target.value.indexOf('selectAll');
    if (selectAllOption >= 0) {
      e.target.value = valuesArray.length === options.length ? [] : options.map(({ id }) => id);
    }
    onFilterSelect(e, name);
  };

  const getOptionLabel = (option) => {
    return (
      option?.displayName ||
      option?.name ||
      option?.siteCode ||
      option?.prefix ||
      option?.categoryName ||
      option?.text ||
      option?.role ||
      ''
    );
  };

  return (
    <Select
      classes={{
        root: `${styles.selectInput} ${isInvalid && styles.invalid}`,
        icon: styles.selectInput__icon
      }}
      ref={selectorRef}
      name={name}
      multiple
      MenuProps={menuProps}
      IconComponent={ExpandMore}
      value={valuesArray || []}
      renderValue={(selected) => {
        if (selected.length > 0) {
          return selected
            .map((item) => {
              const option = options.find((option) => option.id === item);
              return getOptionLabel(option);
            })
            .join(', ');
        }
      }}
      onChange={onSelect}
      disabled={disabled}
      input={<StyledSelect />}>
      {selectAll && (
        <MenuItem classes={{ root: styles.selectInput__menu }} value={'selectAll'}>
          <Checkbox
            classes={{
              root: styles.checkbox,
              checked: styles.checked
            }}
            checked={valuesArray.length === options.length}
          />
          Select All
        </MenuItem>
      )}
      {options.length ? (
        options.map((item, index) => (
          <MenuItem classes={{ root: styles.selectInput__menu }} value={item.id} key={index}>
            <Checkbox
              classes={{
                root: styles.checkbox,
                checked: styles.checked
              }}
              checked={!!valuesArray?.find((id) => id === item.id)}
            />
            {getOptionLabel(item)}
          </MenuItem>
        ))
      ) : (
        <MenuItem classes={{ root: styles.selectInput__emptyMenu }}>No available items</MenuItem>
      )}
    </Select>
  );
}
