import React, { useEffect, useState, createRef } from 'react';
import styles from './Dashboard.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import Multiselect from 'components/Multiselect/Multiselect';
import { MainButton } from 'components/StyledComponents/';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';

import DashboardPiechart from './DashboardPiechart';
import DashboardAddTablePopup from './DashboardAddTablePopup/DashboardAddTablePopup';
import DashboardPiechartPopup from './DashboardPiechartPopup/DashboardPiechartPopup';
import SettingsButton from './DashboardTableSection/SettingsButton/SettingsButton';
import StatusCalculations from './DashboardTableSection/StatusCalculations/StatusCalculations';
import DashboardTable from './DashboardTableSection/DashboardTable/DashboardTable';

import {
  Paper,
  Button,
  Popper,
  Fade,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import clsx from 'clsx';

import { selectDashboardConfig, selectDashboardTablesData } from './selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getDashboardConfig,
  updateDashboardConfig,
  getDashboardTablesData,
  clearDashboardData
} from 'actions/dashboardActions';

import { DELETE_TABLE } from 'constants/dialogPopupsData';
import { getFixedCounts } from './helpers';

import switcherIcon from 'assets/images/tableSwitcher.svg';
import allSwitcherIcon from 'assets/images/tableSwitcherFilled.svg';

import { useMobileViewport } from 'hooks/useMobileViewport';

const defaultStatusState = [2, 4, 3, 5];

const selectOptions = [
  { id: 1, name: 'All', value: 'all' },
  { id: 2, name: 'Operational', value: 'Operational' },
  { id: 4, name: 'Non-Critical', value: 'NonCritical' },
  { id: 3, name: 'Critical', value: 'Critical' },
  { id: 5, name: 'Beyond repair', value: 'BeyondRepair' }
];

function Dashboard({
  dashboardConfig,
  getDashboardConfigAction,
  updateDashboardConfigAction,
  dashboardTablesData,
  getDashboardTablesDataAction,
  clearDashboardDataAction
}) {
  const isMobile = useMobileViewport();
  const [elRefs, setElRefs] = useState([]);
  const [openMenu, setOpenMenu] = useState(null);
  const [openAddTablePopup, setOpenAddTablePopup] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });
  const [pieRerender, setPieRerender] = useState(false);
  const [pieChartData, setPieChartData] = useState({ open: false });

  const [selectedStatuses, setSelectedStatuses] = useState(defaultStatusState);
  const [selectedStatusesMatrix, setSelectedStatusesMatrix] = useState([]);

  const [statuslessDashboardState, setStatuslessDashboardState] = useState([]);
  const [dashboardState, setDashboardState] = useState([]);
  const [tableToDelete, setTableToDelete] = useState(null);

  const [prefixOptions, setPrefixOptions] = useState([]);
  const [subPrefixOptions, setSubPrefixOptions] = useState([]);

  const setSelectedMatrix = (tablesList, statuses) => {
    const stateMatrix = tablesList.map(({ order }) => {
      return { order, statusArray: statuses ? statuses : selectedStatuses };
    });
    setSelectedStatusesMatrix(stateMatrix);
  };

  const applyConfigToDashboard = (dashboardTables, config, callback) => {
    const prefilteredArray = config
      .map((config) => {
        return {
          ...config,
          tableData: {
            ...dashboardTables.find((table) => table.assetCategoryId === config.categoryId)
          }
        };
      })
      .filter(({ isHidden }) => !isHidden);
    const filteredArray = prefilteredArray.map(
      ({ tableData, order, prefixes, subcategoryPrefixes }) => {
        return {
          ...tableData,
          order,
          slicedByLocations:
            tableData.slicedByLocations?.map((location) => {
              return {
                ...location,
                slicedByPrefixes: location.slicedByPrefixes.filter((prefix) => {
                  const filteredPrefixes = prefixes?.find(
                    ({ prefixId }) => prefixId === prefix.prefixId
                  );
                  const filterdSubcategories = subcategoryPrefixes?.find(
                    ({ prefixId }) => prefixId === prefix.prefixId
                  );
                  return filteredPrefixes
                    ? !filteredPrefixes?.isHidden
                    : !filterdSubcategories?.isHidden;
                })
              };
            }) || null
        };
      }
    );
    setStatuslessDashboardState(filteredArray);
    if (callback) callback(filteredArray);
  };

  useEffect(() => {
    getDashboardConfigAction();
  }, []);

  useEffect(() => {
    if (!dashboardTablesData.length) {
      getDashboardTablesDataAction();
    }
  }, [dashboardTablesData]);

  useEffect(() => {
    if (dashboardTablesData.length && dashboardConfig.length) {
      applyConfigToDashboard(dashboardTablesData, dashboardConfig, (filteredArray) => {
        setDashboardState(filteredArray);
        setElRefs(filteredArray.map((_, i) => elRefs[i] || createRef()));
        setSelectedMatrix(filteredArray);
      });
    }
  }, [dashboardTablesData, dashboardConfig]);

  useEffect(() => {
    return () => {
      clearDashboardDataAction();
      setDashboardState([]);
    };
  }, []);

  const areAllTablesHidden = dashboardConfig?.length
    ? !dashboardConfig?.filter(({ isHidden }) => !isHidden).length
    : true;

  const applyStatusToSingleTable = (tableData, options, filterArray) => {
    return {
      ...tableData,
      slicedByLocations: tableData.slicedByLocations.map((location) => {
        return {
          ...location,
          slicedByPrefixes: location.slicedByPrefixes.map((prefix) => {
            return {
              ...prefix,
              slicedBySeverities: filterArray.includes(1)
                ? [
                    {
                      severity: 'AllCounters',
                      count:
                        prefix.slicedBySeverities
                          .map(({ count }) => count)
                          .reduce((sum, current) => sum + current, 0) || null
                    }
                  ]
                : [
                    ...prefix.slicedBySeverities.filter(({ severity }) =>
                      options.includes(severity)
                    )
                  ]
            };
          })
        };
      })
    };
  };

  const filterSingleTableData = (filterArray, index) => {
    const filteredOptions = selectOptions
      .filter((x) => filterArray.includes(x.id))
      .map(({ value }) => value);
    const tablesCopy = [...dashboardState];
    applyConfigToDashboard(dashboardTablesData, dashboardConfig, (filteredArray) => {
      if (tablesCopy.length) {
        tablesCopy[index] = applyStatusToSingleTable(
          filteredArray[index],
          filteredOptions,
          filterArray
        );
        setDashboardState(tablesCopy);
      }
    });
  };

  const onCommonStatusFilterSelect = (e) => {
    let selectedValues = [];
    if (e.target.value.length) {
      if (e.target.value.includes(1) && !selectedStatuses.includes(1)) {
        selectedValues = [1];
      } else {
        selectedValues = [...e.target.value].filter((item) => item !== 1);
      }
      const filteredOptions = selectOptions
        .filter((x) => selectedValues.includes(x.id))
        .map(({ value }) => value);
      if (dashboardTablesData.length) {
        applyConfigToDashboard(dashboardTablesData, dashboardConfig, (filteredArray) => {
          const mutatedArray =
            filteredArray?.map((table) =>
              applyStatusToSingleTable(table, filteredOptions, selectedValues)
            ) || [];
          setDashboardState(mutatedArray);
          setSelectedStatuses(selectedValues);
          setSelectedMatrix(dashboardState, selectedValues);
        });
      }
    }
  };

  const onSingleStatusFilterSelect = (e) => {
    const matrixCopy = [...selectedStatusesMatrix];
    const selectedFilterIndex = matrixCopy.findIndex(({ order }) => order === +e.target.name);
    if (e.target.value.length) {
      if (e.target.value.includes(1) && !matrixCopy[selectedFilterIndex].statusArray.includes(1)) {
        matrixCopy[selectedFilterIndex].statusArray = [1];
      } else {
        matrixCopy[selectedFilterIndex].statusArray = [...e.target.value].filter(
          (item) => item !== 1
        );
      }
      setSelectedStatusesMatrix(matrixCopy);
      filterSingleTableData(matrixCopy[selectedFilterIndex].statusArray, selectedFilterIndex);
    }
  };

  const onDashboardViewChange = (configArray) => {
    setPieRerender(true);
    updateDashboardConfigAction(configArray).then((res) => {
      applyConfigToDashboard(dashboardTablesData, res, (filteredArray) => {
        const matrixCopy = filteredArray.map(({ order }) => {
          return {
            order,
            statusArray: defaultStatusState
          };
        });
        setSelectedStatuses(defaultStatusState);
        setSelectedStatusesMatrix(matrixCopy);

        const mutatedArray = [...filteredArray];
        filteredArray.forEach((table, index) => {
          const selectMatrixIndex = matrixCopy.findIndex(({ order }) => order === table.order);
          const filteredOptions = selectOptions
            .filter((x) => matrixCopy[selectMatrixIndex].statusArray.includes(x.id))
            .map(({ value }) => value);

          mutatedArray[index] = applyStatusToSingleTable(
            table,
            filteredOptions,
            matrixCopy[selectMatrixIndex].statusArray
          );
        });
        setDashboardState(mutatedArray);
        setElRefs(filteredArray.map((_, i) => elRefs[i] || createRef()));
      });
      setPieRerender(false);
    });
  };

  const openPrefixMenuHandle = (index) => {
    if (dashboardConfig?.length) {
      setPrefixOptions(dashboardConfig.filter(({ isHidden }) => !isHidden)[index].prefixes);
      setSubPrefixOptions(
        dashboardConfig.filter(({ isHidden }) => !isHidden)[index].subcategoryPrefixes
      );
    }
    setOpenMenu(openMenu === index ? null : index);
  };

  const isSelectAllChecked = () =>
    prefixOptions?.length === prefixOptions?.filter(({ isHidden }) => !isHidden)?.length &&
    subPrefixOptions?.length === subPrefixOptions?.filter(({ isHidden }) => !isHidden)?.length;

  const prefixClickHandle = (e) => {
    let config = [...prefixOptions];
    let subConfig = [...subPrefixOptions];
    const allOptionsClickHandle = (options) =>
      options.map((prefix) => {
        return {
          ...prefix,
          isHidden:
            prefixOptions.length + subPrefixOptions.length ===
            prefixOptions.filter(({ isHidden }) => !isHidden).length +
              subPrefixOptions.filter(({ isHidden }) => !isHidden).length
        };
      });
    if (e.target.name === 'all') {
      config = allOptionsClickHandle(prefixOptions);
      subConfig = allOptionsClickHandle(subPrefixOptions);
      setSubPrefixOptions(subConfig);
    } else {
      const index = prefixOptions.findIndex(({ prefixId }) => prefixId === +e.target.name);
      config[index] = { ...config[index], isHidden: !config[index].isHidden };
    }
    if (!config.filter(({ isHidden }) => !isHidden).length) {
      config[0].isHidden = false;
    }
    setPrefixOptions(config);
  };

  const subPrefixClickHandle = (e) => {
    const config = [...subPrefixOptions];
    const index = subPrefixOptions.findIndex(({ prefixId }) => prefixId === +e.target.name);
    config[index] = { ...config[index], isHidden: !config[index].isHidden };
    setSubPrefixOptions(config);
  };

  const handleSelectorApply = () => {
    const configCopy = [...dashboardConfig.filter(({ isHidden }) => !isHidden)];
    configCopy[openMenu].prefixes = prefixOptions;
    configCopy[openMenu].subcategoryPrefixes = subPrefixOptions;
    onDashboardViewChange(configCopy);
    setOpenMenu(false);
  };

  const handleSelectorCancel = () => {
    setOpenMenu(false);
  };

  const deleteTableHandle = (index) => {
    setDialogModalData({ ...DELETE_TABLE, isOpened: true });
    setTableToDelete(index);
  };

  const handleCommonSwitcherClick = () => {
    const configCopy = dashboardConfig.filter(({ isHidden }) => !isHidden);

    const allTablesReversed = configCopy.every(({ isAssetRowView }) => isAssetRowView === true);
    const shouldReverse = !allTablesReversed;

    const updatedConfig = configCopy.map((table) => ({ ...table, isAssetRowView: shouldReverse }));
    onDashboardViewChange(updatedConfig);
  };

  const switchTableHandle = (categoryOrder) => {
    const configCopy = dashboardConfig.filter(({ isHidden }) => !isHidden);
    const index = configCopy.findIndex(({ order }) => order === categoryOrder);
    configCopy[index].isAssetRowView = !configCopy[index].isAssetRowView;
    onDashboardViewChange(configCopy);
  };

  const onAgree = () => {
    const configCopy = [...dashboardConfig.filter(({ isHidden }) => !isHidden)];
    configCopy[tableToDelete] = { ...configCopy[tableToDelete], isHidden: true };
    onDashboardViewChange(configCopy);
    setDialogModalData({ isOpened: false });
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
    setTableToDelete(null);
  };

  const csvQuery = {
    serializationType: 'Csv',
    filters: { assetCategoryIds: dashboardState.map(({ assetCategoryId }) => assetCategoryId) }
  };

  return (
    <>
      {!!dashboardConfig.length && (
        <section className={styles.pageContainer}>
          <DialogPopup
            data={dialogModalData}
            onAgree={onAgree}
            onDissmiss={onDismiss}
            isMobile={isMobile}
          />
          <DashboardAddTablePopup
            modalOpen={openAddTablePopup}
            setModalOpen={setOpenAddTablePopup}
            dashboardConfig={dashboardConfig}
            onViewChange={onDashboardViewChange}
            isMobile={isMobile}
          />
          <DashboardPiechartPopup
            setPopupData={setPieChartData}
            pieData={pieChartData}
            isMobile={isMobile}
          />
          <section className={styles.pageHeader}>
            <h1>Dashboard</h1>
            <div className={styles.pageHeader__controls}>
              {isMobile && (
                <IconButton
                  onClick={handleCommonSwitcherClick}
                  className={styles.commonControls__configuration_switcher}>
                  <img src={allSwitcherIcon} alt="" />
                </IconButton>
              )}
              {isMobile && (
                <MainButton
                  isMobile={isMobile}
                  text="+"
                  type="primary"
                  action={() => setOpenAddTablePopup(true)}
                />
              )}
              {!isMobile && (
                <DownloadCSVButton endpoint="AssetDashboards/Serialized" filter={csvQuery} />
              )}
            </div>
          </section>
          <section className={styles.commonControls}>
            {!!dashboardState.length && (
              <div className={styles.commonControls__statuses}>
                <label>Common Dashboard Status</label>
                <div className={styles.inputWrapper}>
                  <Multiselect
                    name="statuses"
                    valuesArray={selectedStatuses}
                    options={selectOptions}
                    onFilterSelect={onCommonStatusFilterSelect}
                  />
                </div>
              </div>
            )}
            {!isMobile && (
              <div className={styles.commonControls__configuration}>
                <IconButton
                  onClick={handleCommonSwitcherClick}
                  className={styles.commonControls__configuration_switcher}>
                  <img src={allSwitcherIcon} alt="" />
                </IconButton>
                <MainButton
                  text="Configuration"
                  type="primary"
                  action={() => setOpenAddTablePopup(true)}
                />
              </div>
            )}
          </section>

          {dashboardState.length
            ? dashboardState.map((tableData, index) => (
                <section key={index} className={styles.mainBlock}>
                  <section className={styles.pageTopBlock}>
                    <h3>{tableData?.assetCategoryName}</h3>
                    <section className={styles.pageTopBlock__summary}>
                      <StatusCalculations data={statuslessDashboardState} index={index} />
                      {!isMobile && (
                        <DashboardPiechart
                          pieData={getFixedCounts(statuslessDashboardState, index)}
                          reRender={pieRerender}
                        />
                      )}
                    </section>

                    <section className={clsx(styles.formCell, styles.singleStatusCell)}>
                      <label>Status</label>
                      <div className={styles.inputWrapper}>
                        <Multiselect
                          name={`${tableData.order}`}
                          valuesArray={
                            selectedStatusesMatrix?.find((item) => item.order === tableData.order)
                              ?.statusArray || []
                          }
                          options={selectOptions}
                          onFilterSelect={onSingleStatusFilterSelect}
                        />
                        {isMobile && (
                          <SettingsButton
                            index={index}
                            categoryName={tableData.assetCategoryName}
                            onDelete={deleteTableHandle}
                            onMenuOpen={openPrefixMenuHandle}
                            onPieChartOpen={setPieChartData}
                            onSwitch={() => switchTableHandle(tableData.order)}
                            pieData={getFixedCounts(statuslessDashboardState, index)}
                          />
                        )}
                      </div>
                    </section>
                  </section>
                  <section className={styles.tableWrapper}>
                    <div className={styles.menuButtons}>
                      {!isMobile && (
                        <Button onClick={() => switchTableHandle(tableData.order)}>
                          <img src={switcherIcon} alt="switcher" />
                        </Button>
                      )}
                      {!isMobile && (
                        <Button onClick={() => deleteTableHandle(index)}>
                          <DeleteIcon />
                        </Button>
                      )}
                      {!isMobile && (
                        <Button ref={elRefs[index]} onClick={() => openPrefixMenuHandle(index)}>
                          <SettingsIcon />
                        </Button>
                      )}

                      <Popper
                        open={openMenu === index}
                        anchorEl={elRefs[index]?.current}
                        modifiers={{
                          preventOverflow: {
                            enabled: false
                          }
                        }}
                        transition
                        placement={'bottom-end'}
                        disablePortal
                        className={styles.popper}>
                        {({ TransitionProps, placement }) => (
                          <Fade
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                            }}>
                            <Paper classes={{ root: styles.menuContainer }}>
                              <section className={styles.listWrapper}>
                                {isMobile && (
                                  <div className={styles.pageHeader}>
                                    <h1>Add column</h1>
                                    <div className={styles.pageHeader__controls}>
                                      <button className={styles.chevronStyle}>
                                        <ChevronLeftIcon onClick={() => setOpenMenu(null)} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      classes={{
                                        root: styles.checkbox,
                                        checked: styles.checked
                                      }}
                                      checked={isSelectAllChecked()}
                                      onChange={prefixClickHandle}
                                      name="all"
                                    />
                                  }
                                  label="Select all"
                                />
                                {!!prefixOptions?.length &&
                                  prefixOptions.map((prefix, prefixIndex) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          classes={{
                                            root: styles.checkbox,
                                            checked: styles.checked
                                          }}
                                          checked={!prefix.isHidden}
                                          onChange={prefixClickHandle}
                                          name={prefix.prefixId}
                                        />
                                      }
                                      label={prefix.prefix}
                                      key={prefixIndex}
                                    />
                                  ))}
                                {!!subPrefixOptions?.length && (
                                  <>
                                    <hr />
                                    {subPrefixOptions.map((prefix, prefixIndex) => (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            classes={{
                                              root: styles.checkbox,
                                              checked: styles.checked
                                            }}
                                            checked={!prefix.isHidden}
                                            onChange={subPrefixClickHandle}
                                            name={prefix.prefixId}
                                          />
                                        }
                                        label={prefix.prefix}
                                        key={prefixIndex}
                                      />
                                    ))}
                                  </>
                                )}
                              </section>
                              <section className={styles.menuContainerControls}>
                                <MainButton
                                  text="Cancel"
                                  action={handleSelectorCancel}
                                  type="secondary"
                                  size="info_popup"
                                />
                                <MainButton
                                  text="Apply"
                                  action={handleSelectorApply}
                                  type="primary"
                                  size="info_popup"
                                />
                              </section>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                    <DashboardTable
                      isMobile={isMobile}
                      tableData={tableData}
                      i={index}
                      setPieChartData={setPieChartData}
                      assetCategoryName={dashboardState[index].assetCategoryName}
                      statuslessDashboardState={statuslessDashboardState}
                      isTableReverse={
                        dashboardConfig?.find(({ order }) => order === tableData.order)
                          ?.isAssetRowView
                      }
                    />
                  </section>
                </section>
              ))
            : areAllTablesHidden && (
                <section className={styles.emptyDashboardBlock}>
                  <h3>Please, add the table to the dashboard.</h3>
                </section>
              )}
        </section>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  dashboardConfig: selectDashboardConfig(),
  dashboardTablesData: selectDashboardTablesData()
});

const mapDispatchToProps = {
  getDashboardConfigAction: getDashboardConfig,
  updateDashboardConfigAction: updateDashboardConfig,
  getDashboardTablesDataAction: getDashboardTablesData,
  clearDashboardDataAction: clearDashboardData
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Dashboard);
