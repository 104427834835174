import React, { useEffect, useState } from 'react';
import styles from './DeliveryBlocks.module.scss';

import Subhead from 'components/DetailsComponents/Subhead';
import { FormLabel, FormRow, FormMultipleAssetSelect } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { selectBoLCreationFormData } from 'pages/PicklistAndLeavelist/BillOfLading/selectors';

import { pluck } from 'helpers/AppHelpers';

import { BOL_TYPES } from '../helpers';

export function Assets({ type, excludedOptions, bolId }) {
  const { setValue, getValues, clearErrors, formState } = useFormContext();
  const { isValid } = formState;

  const isPicklist = type === BOL_TYPES.picklist;
  const isCustom = type === BOL_TYPES.custom;

  const { excludedAssetIds } = excludedOptions;

  const [options, setOptions] = useState({
    locations: [],
    sublocations: [],
    prefixes: [],
    assets: []
  });

  const { locations, sublocations, prefixes, assets, allAssets } = options;

  const {
    // locations
    locationsWithSublocations = [],
    picklistAssetOriginLocations = [],
    // assets
    assetItemsForAdding = [],
    // other
    typeOfVehicles = [],
    assetPrefixes = []
  } = useSelector(selectBoLCreationFormData());

  useEffect(() => {
    if (!typeOfVehicles?.length) return;

    const excludedAssetKey = isPicklist ? 'excludedPicklistAssetIds' : 'excludedLeavelistAssetIds';
    const assetKey = isPicklist ? 'picklistAsset' : 'leavelistAsset';
    const locations = isPicklist ? picklistAssetOriginLocations : locationsWithSublocations;

    const assets = assetItemsForAdding.filter(
      (el) => !excludedOptions[excludedAssetKey].includes(el[assetKey].id)
    );

    const allAssets = isCustom ? [] : assets;

    setOptions({
      locations,
      sublocations: [],
      assets: allAssets,
      allAssets,
      prefixes: assetPrefixes
    });
  }, [typeOfVehicles]);

  const conditions = {
    narrowByLocation: isPicklist || isCustom,
    narrowBySublocation: isCustom
  };

  const handleLocationSelect = (_, value) => {
    if (isCustom) {
      const sublocations = value?.flatMap(({ sublocations }) => sublocations) || [];

      const ids = pluck(sublocations, 'id');
      const newValue = getValues('narrowAssetsBySublocationIds').filter((id) => ids.includes(id));
      setValue('narrowAssetsBySublocationIds', newValue);

      setOptions((prev) => ({ ...prev, sublocations }));
    }

    filterAssets();
  };

  const filterAssets = () => {
    if (isCustom) return;

    const selectedLocationIds = getValues('narrowAssetsByLocations')
      .map((el) => el?.locationId || null)
      .filter(Boolean);
    const selectedSublocationIds = getValues('narrowAssetsByLocations')
      .map((el) => el?.sublocationId || null)
      .filter(Boolean);

    const selectedPrefixIds = getValues('narrowAssetsByPrefixIds');

    if (
      selectedLocationIds?.length === 0 &&
      selectedSublocationIds?.length === 0 &&
      selectedPrefixIds?.length === 0
    ) {
      setOptions((prev) => ({ ...prev, assets: allAssets }));
      return;
    }

    const filtered = allAssets.filter((item) => {
      const locationMatch =
        selectedLocationIds?.length === 0 || selectedLocationIds.includes(item.originLocationId);

      const sublocationMatch =
        selectedSublocationIds?.length === 0 ||
        selectedSublocationIds.includes(item.originSublocationId);

      const prefixMatch =
        selectedPrefixIds?.length === 0 || selectedPrefixIds.includes(item.assetPrefixId);

      return locationMatch && sublocationMatch && prefixMatch;
    });

    setOptions((prev) => ({ ...prev, assets: filtered }));
  };

  const clearAtLeastError = () => !isValid && clearErrors();

  return (
    <div className={styles.container}>
      <Subhead>Assets</Subhead>

      <div className={styles.assets}>
        <div className={styles.assets__filter}>
          <div className={styles.assets__filter_block}>
            {conditions.narrowByLocation && (
              <FormRow>
                <FormLabel>
                  {isPicklist ? 'Narrow by Origin Location' : 'Narrow by Location'}
                </FormLabel>
                <FormAutoMultiSelect
                  name="narrowAssetsByLocations"
                  menuItems={locations || []}
                  options={{
                    label: isCustom ? 'siteCode' : 'name',
                    disableByObjectTracker: false,
                    disableLabel: true,
                    extraAction: handleLocationSelect
                  }}
                />
              </FormRow>
            )}

            {!isCustom && (
              <FormRow>
                <FormLabel>Narrow by Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="narrowAssetsByPrefixIds"
                  menuItems={prefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: filterAssets
                  }}
                />
              </FormRow>
            )}

            {conditions.narrowBySublocation && (
              <FormRow>
                <FormLabel>Narrow by Sublocation</FormLabel>
                <FormAutoMultiSelect
                  name="narrowAssetsBySublocationIds"
                  menuItems={sublocations || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    isDisabled: !sublocations?.length
                  }}
                />
              </FormRow>
            )}
          </div>

          <div className={styles.assets__filter_block}>
            {isCustom ? (
              <FormRow>
                <FormLabel>Narrow by Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="narrowAssetsByPrefixIds"
                  menuItems={prefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
            ) : (
              <FormRow>
                <FormLabel>{`${isPicklist ? `Picklist's` : `Leavelist's`} Assets`}</FormLabel>
                <FormAutoMultiSelect
                  name={isPicklist ? 'picklistAssets' : 'leavelistAssets'}
                  menuItems={assets || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: false,
                    disableLabel: true,
                    hideErrorMessage: true,
                    extraAction: clearAtLeastError
                  }}
                />
              </FormRow>
            )}
          </div>
        </div>
        {isCustom && (
          <div className={styles.assets__field}>
            <FormRow type="column" className={styles.assets__field_search}>
              <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes</FormLabel>
              <FormMultipleAssetSelect
                withQr
                name="assets"
                disableErrorMessage
                onSelectTriggered={clearAtLeastError}
                queryOptions={() => ({
                  locationIds: pluck(getValues('narrowAssetsByLocations'), 'id'),
                  sublocationIds: getValues('narrowAssetsBySublocationIds'),
                  prefixIds: getValues('narrowAssetsByPrefixIds'),
                  excludeAssetIds: excludedAssetIds,
                  isBOL: true,
                  BOLId: bolId
                })}
              />
            </FormRow>
          </div>
        )}
      </div>
    </div>
  );
}
