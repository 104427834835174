const columnWidthsCache = {};

const usePersistedColumnWidths = (tableKey) => {
  const getColumnWidths = () => {
    return columnWidthsCache[tableKey] || {};
  };

  const getColumnWidth = (key) => {
    return getColumnWidths()?.[key] || null;
  };

  const setColumnWidths = (newWidths) => {
    const updatedWidths = Object.keys(newWidths).reduce((acc, columnId) => {
      const width = newWidths[columnId];
      acc[columnId] = width < 52 ? 52 : width;
      return acc;
    }, {});

    columnWidthsCache[tableKey] = { ...columnWidthsCache[tableKey], ...updatedWidths };
  };

  return {
    getColumnWidth,
    setColumnWidths,
    columnWidthsCache
  };
};

export default usePersistedColumnWidths;
