import React, { useEffect, useState } from 'react';
import styles from './PicklistDetailedFilter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import Multiselect from 'components/Multiselect/Multiselect';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { enrichNameToId, formatDate, pluck, removeDuplicates } from 'helpers/AppHelpers';
import { getChips, getValuesAfterChipDeleting } from './helpers';

import { useDispatch } from 'react-redux';
import { getPicklistDetailsFilterCriteria } from 'actions/pickListActions';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

const defaultState = {
  billOfLadingIds: [],
  originLocationIds: [],
  originLocationJobNumberIds: [],
  originSublocationIds: [],
  picklistAssetStatusNames: [],
  pickUpDateFrom: null,
  pickUpDateTo: null,
  trackAndTrailerAssetDrCodes: [],
  isAssetsOnly: false,
  isLooseItemsOnly: false
};

export default function PicklistDetailedFilter({
  isMobile,
  onApply,
  filters,
  picklistCreatedDate
}) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [chips, setChips] = useState([]);

  const [options, setOptions] = useState({});
  const [sublocations, setSublocations] = useState([]);

  useEffect(() => {
    dispatch(getPicklistDetailsFilterCriteria({ picklistId: id })).then((res) => setOptions(res));
  }, []);

  useEffect(() => {
    if (open) {
      setValues(filters);
      isMobile && buildChips(filters);
    }
  }, [open]);

  useEffect(() => {
    if (options?.['locationsWithSublocations']?.length) {
      setValues(filters);
      isMobile && buildChips(filters);
    }
  }, [filters, options]);

  const {
    billOfLadings,
    locationsWithSublocations,
    picklistAssetStatuses,
    trackAndTrailerAssetDrCodes
  } = options;

  useEffect(() => {
    if (
      (values?.originLocationIds?.length || values?.originLocationJobNumberIds?.length) &&
      locationsWithSublocations?.length
    ) {
      const { originLocationIds, originLocationJobNumberIds } = values;
      const locationIds = removeDuplicates([...originLocationIds, ...originLocationJobNumberIds]);

      const listOfSublocations = locationsWithSublocations
        ?.filter((item) => locationIds.includes(item.id))
        ?.flatMap(({ sublocations }) => sublocations);
      setSublocations(listOfSublocations || []);
    }
  }, [values, locationsWithSublocations]);

  const handleIconClick = (value) => setOpen(value || !open);

  const buildChips = (filter) => setChips(getChips(filter, { ...options, sublocations }));

  const handleApplyClick = () => {
    onApply(values);
    buildChips(values);
    setOpen(false);
  };
  const handleClearClick = () => {
    setValues({ picklistIds: [id], ...defaultState });
    isMobile && setChips([]);
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);

    const updatedValues = getValuesAfterChipDeleting(values, chip);
    setValues(updatedValues);
    !isMobile && onApply(updatedValues);
  };

  const handleBackClick = () => {
    isMobile && buildChips(filters);
    setOpen(false);
  };

  const selectOption = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && buildChips(updatedValues);
  };

  const selectLocationOption = (name, value) => {
    if (values?.originSublocationIds?.length) {
      let selectedLocationIds = [];
      if (name === 'originLocationIds') {
        selectedLocationIds = removeDuplicates([...value, ...values.originSublocationIds]);
      } else {
        selectedLocationIds = removeDuplicates([...value, ...values.originLocationIds]);
      }
      const currentSublocations = locationsWithSublocations
        .filter(({ id }) => selectedLocationIds.includes(id))
        .flatMap(({ sublocations }) => sublocations);
      const availableSublocationIds = pluck(currentSublocations, 'id');
      const originSublocationIds = values.originSublocationIds.filter((id) =>
        availableSublocationIds.includes(id)
      );
      const updatedValues = { ...values, [name]: value, originSublocationIds };
      setValues(updatedValues);
      isMobile && buildChips(updatedValues);
    } else {
      const updatedValues = { ...values, [name]: value };
      setValues(updatedValues);
      isMobile && buildChips(updatedValues);
    }
  };

  const selectMultiOption = (e) => {
    const updatedValues = { ...values, [e.target.name]: e.target.value };
    setValues(updatedValues);
    isMobile && buildChips(updatedValues);
  };

  const changeDate = (date, name) => {
    const updatedValues = { ...values, [name]: date ? formatDate(date) : null };
    setValues(updatedValues);
    isMobile && buildChips(updatedValues);
  };

  const clickCheckbox = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && buildChips(updatedValues);
  };

  const selectDrCode = (fieldName, value) => {
    const updatedValues = { ...values, [fieldName]: value.map(({ name }) => name) };
    setValues(updatedValues);
    isMobile && buildChips(updatedValues);
  };

  const strToDrCode = (str) => ({ id: str, name: str });
  const getDrCodes = () => trackAndTrailerAssetDrCodes?.map(strToDrCode) || [];

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      onClick={handleIconClick}
      onApply={handleApplyClick}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      onChipDelete={handleChipDelete}>
      <section className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Origin Location</label>
            <div className={styles.form__block_cell_input}>
              <AutocompleteWithSelectAll
                name="originLocationIds"
                label="siteCode"
                value={values?.originLocationIds || []}
                options={locationsWithSublocations || []}
                onSelect={selectLocationOption}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Job Number</label>
            <div className={styles.form__block_cell_input}>
              <AutocompleteWithSelectAll
                name="originLocationJobNumberIds"
                label="locationJobNumber"
                value={values?.['originLocationJobNumberIds'] || []}
                options={locationsWithSublocations || []}
                onSelect={selectLocationOption}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Origin Sublocation</label>
            <div className={styles.form__block_cell_input}>
              <AutocompleteWithSelectAll
                name="originSublocationIds"
                label="name"
                value={values?.originSublocationIds || []}
                options={sublocations || []}
                onSelect={selectOption}
                selectAll={false}
                disableByObjectTracker={true}
                isDisabled={
                  !values?.originLocationIds?.length && !values?.originLocationJobNumberIds?.length
                }
              />
            </div>
          </div>
          {!isMobile && (
            <div className={clsx(styles.form__block_cell, styles.checkbox)}>
              <label className={styles.form__block_cell_label}>Show Assets only</label>
              <CustomCheckbox
                name="isAssetsOnly"
                value={values?.['isAssetsOnly']}
                onChange={clickCheckbox}
              />
            </div>
          )}
          {!isMobile && (
            <div className={clsx(styles.form__block_cell, styles.checkbox)}>
              <label className={styles.form__block_cell_label}>Show Loose Items only</label>
              <CustomCheckbox
                name="isLooseItemsOnly"
                value={values?.['isLooseItemsOnly']}
                onChange={clickCheckbox}
              />
            </div>
          )}
        </div>
        <div className={styles.form__block}>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>{`Item's`} Status</label>
            <div className={styles.form__block_cell_input}>
              <Multiselect
                name="picklistAssetStatusNames"
                valuesArray={values?.['picklistAssetStatusNames']}
                options={enrichNameToId(picklistAssetStatuses) || []}
                onFilterSelect={selectMultiOption}
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Freight Bill</label>
            <div className={styles.form__block_cell_input}>
              <AutocompleteWithSelectAll
                name="billOfLadingIds"
                label="freightBill"
                value={values?.['billOfLadingIds'] || []}
                options={billOfLadings || []}
                onSelect={selectOption}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Truck or Trailer</label>
            <div className={styles.form__block_cell_input}>
              <AutocompleteWithSelectAll
                name="trackAndTrailerAssetDrCodes"
                label="name"
                value={values?.['trackAndTrailerAssetDrCodes']?.map(strToDrCode) || []}
                options={getDrCodes()}
                onSelect={selectDrCode}
                selectAll={false}
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Pick Up date from</label>
            <div className={styles.form__block_cell_input}>
              <StyledDatePicker
                name="pickUpDateFrom"
                value={values?.['pickUpDateFrom'] || null}
                onChange={changeDate}
                min={picklistCreatedDate}
                max={values?.['pickUpDateTo']}
                placement="top"
              />
            </div>
          </div>
          <div className={styles.form__block_cell}>
            <label className={styles.form__block_cell_label}>Pick Up date to</label>
            <div className={styles.form__block_cell_input}>
              <StyledDatePicker
                name="pickUpDateTo"
                value={values?.['pickUpDateTo'] || null}
                onChange={changeDate}
                min={values?.['pickUpDateFrom'] || picklistCreatedDate}
                placement="top"
              />
            </div>
          </div>
          {isMobile && (
            <div className={clsx(styles.form__block_cell, styles.checkbox)}>
              <label className={styles.form__block_cell_label}>Show Assets only</label>
              <CustomCheckbox
                name="isAssetsOnly"
                value={values?.['isAssetsOnly']}
                onChange={clickCheckbox}
              />
            </div>
          )}
          {isMobile && (
            <div className={clsx(styles.form__block_cell, styles.checkbox)}>
              <label className={styles.form__block_cell_label}>Show Loose Items only</label>
              <CustomCheckbox
                name="isLooseItemsOnly"
                value={values?.['isLooseItemsOnly']}
                onChange={clickCheckbox}
              />
            </div>
          )}
        </div>
      </section>
    </FilterWrapper>
  );
}
