import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
  root: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
    height: '40px',
    minWidth: '200px',
    padding: '8px 10px',
    borderRadius: '8px',
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  },
  disabled: {
    backgroundColor: '#e0e0e0 !important',
    color: '#bdbdbd  !important'
  }
})(Button);

const useStyles = makeStyles(() => ({
  primary: {
    backgroundColor: '#ffd83c',
    '&:hover': {
      backgroundColor: '#ffd83c'
    }
  },
  secondary: {
    backgroundColor: '#f6f6f6',
    '&:hover': {
      backgroundColor: '#f6f6f6'
    }
  },
  extra: {
    backgroundColor: '#FFA0A0',
    '&:hover': {
      backgroundColor: '#FFA0A0'
    }
  },
  filter_popup: {
    width: '190px',
    minWidth: '190px'
  },
  popup: {
    width: '160px',
    minWidth: '160px',
    margin: '0 10px'
  },
  info_popup: {
    width: '120px',
    minWidth: '120px',
    margin: '0 10px'
  },
  long: {
    minWidth: '240px',
    width: '240px'
  },
  veryLong: {
    minWidth: '400px',
    width: '400px'
  },
  navigation: {
    minWidth: '50px',
    marginLeft: '20px',
    '& section': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: '0 5px',
      margin: '0 -5px',
      '& > *': {
        margin: '0 5px',
        whiteSpace: 'normal',
        wordBreak: 'break-word'
      }
    }
  },
  table_size: {
    minWidth: '140px',
    width: '140px',
    height: '25px'
  },
  small: {
    minWidth: '90px',
    width: '90px',
    height: '40px'
  },
  tiny: {
    minWidth: '40px',
    width: '40px',
    height: '40px',
    fontSize: '28px'
  },
  blue_type: {
    backgroundColor: '#507a8e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#507a8e'
    }
  },
  mobile: {
    minWidth: '40px',
    fontSize: '28px'
  }
}));

const MainButton = ({ text, type, size, action, isDisabled, width, isMobile }) => {
  const classes = useStyles();
  return (
    <StyledButton
      className={`${type ? classes[type] : ''} ${size ? classes[size] : ''} ${
        width ? classes[width] : ''
      }  ${isMobile ? classes.mobile : ''}`}
      onClick={action}
      variant="contained"
      size="large"
      disabled={isDisabled}>
      {text}
    </StyledButton>
  );
};

export default MainButton;
