import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const StyledSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: '8px !important',
    position: 'relative',
    backgroundColor: '#f7f7f7',
    fontSize: 14,
    width: '250px',
    height: '34px',
    boxSizing: 'border-box',
    padding: '6px 36px 6px 16px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    '&:focus': {
      borderRadius: '8px !important',
      backgroundColor: '#f7f7f7'
    }
  }
}))(InputBase);

export default StyledSelect;
