import { createSelector } from 'reselect';

const ticketsData = (state) => state.ticketsData;

export const selectTicketFilter = () => createSelector([ticketsData], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([ticketsData], (state) => state.filterCriteria);

export const selectCreationFormData = () =>
  createSelector([ticketsData], (state) => state.creationForm);

export const selectSingleTicketData = () =>
  createSelector([ticketsData], (state) => state.currentTicket);

export const selectTicketsCounting = () => createSelector([ticketsData], (state) => state.counting);

export const selectPrefilledAssetData = () =>
  createSelector([ticketsData], (state) => state.prefilledAsset);
