import React, { useEffect } from 'react';
import styles from './Wrapper.module.scss';

import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useParams } from 'react-router-dom';

import Header from './Header';

export function Wrapper({ children }) {
  const { id } = useParams();

  const { currentTicket } = useTicketSelector();
  const { getTicketAction, clearStateAction } = useTicketActions();

  useEffect(() => {
    if (id) {
      getTicketAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    currentTicket.id === +id && (
      <section className={styles.wrapper}>
        <Header />
        {children}
      </section>
    )
  );
}
