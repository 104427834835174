import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Paper,
  Table,
  TableBody,
  TableContainer
} from '@material-ui/core';
import PieChart, { Series, Font, Legend, Label, Connector } from 'devextreme-react/pie-chart';
import { MainButton } from 'components/StyledComponents/';
import styles from './DashboardPiechartPopup.module.scss';
import clsx from 'clsx';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

const customizePoint = (pointInfo) => {
  switch (pointInfo.argument) {
    case 'Operational':
      return { color: '#7ec37e' };
    case 'Critical':
      return { color: '#da9898' };
    case 'Non-Critical':
      return { color: '#e1cd80' };
    case 'Beyond repair':
    case 'All':
      return { color: '#0c0c0c' };
    default:
      break;
  }
};

const customizeLabel = (e) => {
  if (e.originalValue)
    return ` ${e.argument}<br /> ${e.originalValue} (${(e.percent * 100).toFixed(1)}%) `;
};

const CenterTemplate = (totalValue) => {
  return (
    <svg>
      <text textAnchor="middle" x="75" y="45" style={{ fontSize: 18, fill: '#494949' }}>
        <tspan
          x="100"
          style={{
            fontWeight: 500,
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontSize: '18px',

            color: '#000'
          }}>
          Total
        </tspan>
        <tspan
          x="100"
          dy="15px"
          style={{
            fontWeight: 500,
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontSize: '18px',

            color: '#000'
          }}>
          {totalValue}
        </tspan>
      </text>
    </svg>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DashboardPiechartPopup({ setPopupData, pieData, isMobile }) {
  const { open, data, legendData } = pieData;

  const totalValue = data
    ?.map((el) => el.count || 0)
    .reduce((sum, current) => sum + current, 0);

  const onClose = () => setPopupData({ open: false });
  const getCounter = (data, type) => data?.find(({ name }) => name === type).count;

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.popupContainer }}
      BackdropProps={{ classes: { root: styles.backDrop } }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}>
      <DialogTitle classes={{ root: styles.popupTitle }}>
        {!isMobile && (pieData?.title || pieData?.locationName || pieData?.categoryName)}
      </DialogTitle>

      <DialogContent classes={{ root: styles.popupText }}>
        <div className={styles.titleBlock}>
          {isMobile && (pieData?.title || pieData?.locationName || pieData?.categoryName)}
        </div>
        {!!legendData?.length && (
          <TableContainer className={styles.tableContainer} component={Paper}>
            <Table>
              <TableBody>
                <StyledTableRow></StyledTableRow>
                {legendData.map((el) => (
                  <StyledTableRow key={el.prefixId}>
                    <StyledTableCell className={styles.firstCell}>
                      {el?.prefixName || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.secondCell}>
                      {el?.total || 0}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <DialogContentText>
          <PieChart
            id="pie-chart"
            dataSource={data}
            resolveLabelOverlapping="shift"
            customizePoint={customizePoint}
            sizeGroup="popupPiesGroup"
            innerRadius={0.7}
            centerRender={() => CenterTemplate(totalValue)}
            size={{ height: 260, width: isMobile ? '100%' : 440 }}
            startAngle={45}
            segmentsDirection="anticlockwise"
            type="doughnut">
            <Series argumentField="displayName" valueField="count">
              <Label
                visible={true}
                format="fixedPoint"
                customizeText={customizeLabel}
                position="columns"
                backgroundColor="none">
                <Font size={12} color={'#000'} family={['Montserrat', 'sans-serif'].join(',')} />
                <Connector visible={true} width={1}></Connector>
              </Label>
            </Series>
            <Legend visible={false}></Legend>
          </PieChart>
          {isMobile && (
            <div className={styles.summaryCounters}>
              <div className={styles.blockRow}>
                <p>
                  <span className={clsx(styles.statusIndicator, styles.Operational)}></span>
                  Operational:
                </p>
                <b>{getCounter(data, 'Operational')}</b>
              </div>
              <div className={styles.blockRow}>
                <p>
                  <span className={clsx(styles.statusIndicator, styles.NonCritical)}></span>
                  Non-Critical:
                </p>
                <b>{getCounter(data, 'NonCritical')}</b>
              </div>
              <div className={styles.blockRow}>
                <p>
                  <span className={clsx(styles.statusIndicator, styles.Critical)}></span>
                  Critical:
                </p>
                <b>{getCounter(data, 'Critical')}</b>
              </div>
              <div className={styles.blockRow}>
                <p>
                  <span className={clsx(styles.statusIndicator, styles.BeyondRepair)}></span>
                  Beyond repair:
                </p>
                <b>{getCounter(data, 'BeyondRepair')}</b>
              </div>
            </div>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions classes={{ root: styles.popupControls }}>
        <MainButton text="Close" action={onClose} type="secondary" size="popup" />
      </DialogActions>
    </Dialog>
  );
}
