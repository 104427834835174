import React, { useEffect } from 'react';

import { SummaryWrapper, SummaryHeader } from 'components/SummaryComponents';

import DashboardTabs from './components/Tabs';
import BaseStatistics from './components/BaseStatistics';
import TurnoverStatistics from './components/TurnoverStatistics';
import FlowStatistics from './components/FlowStatistics';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { useHistory } from 'react-router-dom';

import { ROOT_PATH } from 'constants/routeConstants';

import { defaultTab, dashboardTypes } from './helpers';

export default function TicketsDashboard() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { tab } = useTicketsDashboardSelector();
  const { setTabAction, getFilterCriteriaAction, clearStateAction } = useTicketsDashboardActions();

  useEffect(() => {
    if (!tab) setTabAction(defaultTab);
  }, []);

  useEffect(() => {
    getFilterCriteriaAction();
  }, []);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <SummaryWrapper>
      <SummaryHeader title="Assets Tickets Dashboards" />

      <DashboardTabs />

      {tab === dashboardTypes.baseStatistics && <BaseStatistics />}

      {tab === dashboardTypes.ticketTurnover && <TurnoverStatistics />}

      {tab === dashboardTypes.flowStatistic && <FlowStatistics />}
    </SummaryWrapper>
  );
}
