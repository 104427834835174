import { EXTERNAL_TRAININGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj } from 'helpers/AppHelpers';
import { handleBooleanFilter, handleIdsFilter } from 'helpers/ChipGenerator';

const defaultFilter = {
  filters: {
    searchQuery: '',
    externalTrainingIds: [],
    groupIds: [],
    sourceIds: [],
    showAvailable: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter }) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(EXTERNAL_TRAININGS_TABLE_PARAMETER);
  const isAll = isAllLimitEnabled(EXTERNAL_TRAININGS_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...defaultFilter,
    filters: { ...defaultFilter.filters, ...filter?.filters },
    pagination: { limit, page },
    sortRules: filter?.sortRules || defaultFilter.sortRules
  };
};

const generateChips = (criteria, filters = {}) => {
  const { externalTrainings, groupTrainings, source } = criteria;

  const { externalTrainingIds, groupIds, sourceIds, showAvailable } = filters;

  let newChips = [];

  const filterConfigs = [
    {
      ids: externalTrainingIds,
      options: externalTrainings,
      getLabel: (el) => el.name,
      key: 'externalTrainingIds'
    },
    {
      ids: groupIds,
      options: groupTrainings,
      getLabel: (el) => el.name,
      key: 'groupIds'
    },
    {
      ids: sourceIds,
      options: source,
      getLabel: (el) => el.displayName,
      key: 'sourceIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (showAvailable) {
    newChips = newChips.concat(handleBooleanFilter('showAvailable', 'Available only'));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;
  switch (key) {
    case 'showAvailable':
      newFilters[key] = false;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }
  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = [
    'searchQuery',
    'externalTrainingIds',
    'groupIds',
    'sourceIds',
    'showAvailable'
  ];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules?.length) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const tableConfig = [
  { id: 1, label: 'Training and Certifications', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Source', isSortable: true, parameter: 'source' },
  { id: 3, label: 'Available', isSortable: true, parameter: 'available' },
  { id: 4, label: 'Group', isSortable: true, parameter: 'group' },
  { id: 5, label: 'Editing', isSortable: false }
];

export { defaultFilter, generateInitialQuery, generateChips, removeChip, parseQuery, tableConfig };
