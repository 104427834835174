import { getUniqueID } from 'helpers/AppHelpers';

export const generateChips = (options, filters) => {
  const filterHandlers = {
    roleIds: handleFilterChips('userRoles', 'role', options),
    assignedLocationIds: handleFilterChips('locations', 'siteCode', options),
    userNotificationTypeIds: handleFilterChips('notificationTypes', 'displayName', options),
    requestOrderRoleIds: handleFilterChips('requestOrderRoles', 'displayName', options),
    readOnlyDepartmentIds: handleFilterChips('departments', 'name', options),
    assignedDepartmentIds: handleFilterChips('departments', 'name', options)
  };

  let newChips = [];

  Object.entries(filterHandlers).forEach(([key, handler]) => {
    const chips = handler(filters[key], key);
    newChips = newChips.concat(chips);
  });

  return newChips.filter(Boolean);
};

const handleFilterChips = (dataKey, labelKey, filterCriteriaData) => (filterIds, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterIds?.length) return [];

  return filterIds.flatMap((id) => {
    const item = data.find((option) => option.id === id);
    if (!item?.id && item?.id !== 0) return [];
    return {
      itemId: item.id,
      label: item[labelKey],
      chipId: getUniqueID(),
      key
    };
  });
};
