import React from 'react';

import DetailsHeader from 'components/DetailsComponents/Header';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

import { ASSETS_LIST_PATH } from 'constants/routeConstants';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function Header({ onLinkClick }) {
  const isMobile = useMobileViewport();

  const crumbs = [
    { name: 'Asset Summary', path: ASSETS_LIST_PATH },
    { name: 'Create Batch of Assets' }
  ];

  const CrumbsComponent = !isMobile && (
    <BreadcrumbsNav itemsArray={crumbs} onLinkClick={onLinkClick} />
  );

  return <DetailsHeader title="Create Batch of Assets" BreadCrumbsComponent={CrumbsComponent} />;
}
