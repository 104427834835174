import { useSelector } from 'react-redux';

import {
  selectCreationFormData,
  selectFilterData,
  selectFilterCriteriaData,
  selectConfigurationData
} from 'pages/PicklistAndLeavelist/BillOfLadingLogs/selectors';

export default function useBillOfLadingLogsSelector() {
  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const creationForm = useSelector(selectCreationFormData());

  const configuration = useSelector(selectConfigurationData());

  return {
    filter,
    filterCriteria,
    creationForm,
    configuration
  };
}
