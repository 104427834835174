import React, { useState } from 'react';
import FolderSpecialSharpIcon from '@material-ui/icons/FolderSpecialSharp';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import DetailsHeader from 'components/DetailsComponents/Header';
import { usePrefixActions, usePrefixSelector } from 'hooks/Prefix';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import {
  ASSETS_LIST_PATH,
  CATEGORY_DETAILED_PATH,
  PREFIX_EDIT_PATH
} from 'constants/routeConstants';
import { DELETE_PREFIX } from 'constants/dialogPopupsData';
import { useHistory } from 'react-router-dom';
import DialogPopup from 'components/DialogPopup/DialogPopup';

export default function Header() {
  const isMobile = useMobileViewport();
  const { isAdminUser } = useUserConfig();
  const history = useHistory();

  const { setAssetsPrefilterAction, deletePrefixAction } = usePrefixActions();
  const { currentPrefix } = usePrefixSelector();
  const { id, prefix, assetCategory } = currentPrefix;

  const [dialogModalData, setDialogModalData] = useState({});

  const handleEditClick = () => history.push(`${PREFIX_EDIT_PATH}/${id}`);
  const handleDeleteClick = () => setDialogModalData({ ...DELETE_PREFIX, isOpened: true });
  const handleBackClick = () => history.push(`${CATEGORY_DETAILED_PATH}/${assetCategory?.id}`);

  const handleViewAssetsClick = () => {
    setAssetsPrefilterAction({ prefixIds: [id] });
    history.push(ASSETS_LIST_PATH);
  };

  const agreeModal = () => deletePrefixAction(currentPrefix.id);
  const closeModal = () => setDialogModalData({ isOpened: false });

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <DetailsHeader
        title={prefix}
        breadcrumbs={[
          {
            path: `${CATEGORY_DETAILED_PATH}/${assetCategory.id}`,
            name: assetCategory.name
          },
          { name: prefix }
        ]}>
        <HeaderButton onClick={handleViewAssetsClick}>
          <FolderSpecialSharpIcon />
          {!isMobile && <span>View assets</span>}
        </HeaderButton>
        {isAdminUser && (
          <HeaderButton onClick={handleEditClick}>
            <CreateIcon />
            {!isMobile && <span>Edit this prefix</span>}
          </HeaderButton>
        )}
        {isAdminUser && (
          <HeaderButton onClick={handleDeleteClick}>
            <DeleteIcon />
            {!isMobile && <span>Remove this prefix</span>}
          </HeaderButton>
        )}
        {isMobile && (
          <HeaderButton onClick={handleBackClick}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
      </DetailsHeader>
    </>
  );
}
