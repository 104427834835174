import React from 'react';
import styles from './Info.module.scss';

import clsx from 'clsx';

export default function Info({ label, value, isCheckbox }) {
  return (
    <div className={clsx(styles.info, isCheckbox && styles.checkboxRow)}>
      <label>{label}</label>
      <span>{value || ''}</span>
    </div>
  );
}
