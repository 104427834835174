import React from 'react';
import styles from './LocationReportEdit.module.scss';

import { useForm, FormProvider } from 'react-hook-form';

import { useReportActions, useReportSelector } from 'hooks/ReportList';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';

import { getWeatherDetails, getWeatherValue } from './helpers';

export default function LocationReportEdit() {
  const { getLocationWeatherAction } = useReportActions();
  const { currentGeneralReport } = useReportSelector();

  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { getValues, setValue } = methods;

  const getWeather = (date, location, onSave) => {
    const lat =
      currentGeneralReport?.location?.latitude ||
      currentGeneralReport?.project?.latitude ||
      location?.latitude ||
      null;
    const lng =
      currentGeneralReport?.location?.longitude ||
      currentGeneralReport?.project?.longitude ||
      location?.longitude ||
      null;
    if (!lat || !lng) return;

    getLocationWeatherAction({ lat, lng, date }).then((res) => {
      const weatherString = getWeatherValue(res);
      const { fieldName, doesWeatherExist } = getWeatherDetails(getValues('generalReportFields'));
      if (doesWeatherExist) {
        setValue(fieldName, weatherString);
      }
      onSave && onSave();
    });
  };

  return (
    <section className={styles.pageContainer}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="location" getWeather={getWeather} />
      </FormProvider>
    </section>
  );
}
