import React from 'react';
import styles from './GroupedVerticalBar.module.scss';
import { getVerticalBarOptions } from './VerticalBarOptions';
import ReactApexChart from 'react-apexcharts';

export default function GroupedVerticalBar({
  series,
  categories,
  fileName,
  legendPosition,
  columnWidth,
  colors,
  hideToolbar
}) {
  const options = {
    ...getVerticalBarOptions(fileName, legendPosition, columnWidth, colors, hideToolbar),
    labels: categories
  };

  return (
    <div className={styles.bar}>
      <ReactApexChart type="bar" width="100%" height="100%" options={options} series={series} />
    </div>
  );
}
