import { getAddress } from 'helpers/AppHelpers';

export const DRAT_COMPANY_NAME = 'Deployed Resources LLC';

export const originFieldNames = [
  'originCompanyName',
  'originAddress',
  'originCity',
  'originZIP',
  'originState',
  'originLocation'
];

export const destinationFieldNames = [
  'destinationCompanyName',
  'destinationAddress',
  'destinationCity',
  'destinationZIP',
  'destinationState'
];

export const OTHER_LOCATION_ID = -1;

export const transformPrefilledData = (prefilled) => {
  const {
    billOfLadingItems,
    destinationLocation,
    originLocation,
    picklist,
    leavelist,
    totalWeight
  } = prefilled;

  const isCreationViaPicklist = !!picklist?.id;

  const formState = {
    billOfLadingItems,
    totalWeight
  };

  if (isCreationViaPicklist) {
    formState.picklist = picklist;
    formState.destinationLocation = destinationLocation?.siteCode || '';
    formState.destinationLocationItem = destinationLocation || {};
    formState.destinationAddress = getAddress(
      destinationLocation?.address1,
      destinationLocation?.address2
    );
    formState.destinationCity = destinationLocation?.city || '';
    formState.destinationZIP = destinationLocation?.zip || '';
    formState.destinationState = destinationLocation?.state?.name || '';
    formState.destinationCompanyName = DRAT_COMPANY_NAME;
    formState.jobNumber = destinationLocation?.project?.jobNumber || '';
  } else {
    formState.leavelist = leavelist;
    formState.originLocation = originLocation?.siteCode || '';
    formState.originLocationItem = originLocation || {};
    formState.originAddress = getAddress(originLocation?.address1, originLocation?.address2);
    formState.originCity = originLocation?.city || '';
    formState.originZIP = originLocation?.zip || '';
    formState.originState = originLocation?.state?.name || '';
    formState.originCompanyName = DRAT_COMPANY_NAME;
    formState.jobNumber = originLocation?.project?.jobNumber || '';
  }

  return formState;
};

export const getTotalWeight = (items) =>
  items
    ?.map(({ weight }) => +weight)
    .reduce((s, i) => s + i, 0)
    .toFixed(2) || 0;
