const defaultForm = {
  assetFields: [],
  assetLicense: {},
  assetPrefix: {},
  containedInAsset: {},
  contents: [],
  drCode: '',
  drCodeStatus: { isAvailable: true, status: 'Available!' },
  grade: {},
  inspectionStatus: {},
  isNonOwned: false,
  labeled: false,
  lastLocationHistory: {
    location: {},
    sublocation: {},
    changeLocation: {},
    changeSublocation: {},
    changeNotes: ''
  },
  notes: '',
  purchasedAtUtc: null,
  purchasedFrom: '',
  rental: {
    client: {},
    dealType: {},
    destination: {},
    rentalStatus: {},
    startDate: null,
    endDate: null,
    project: {}
  },
  resources: [],
  shortNote: ''
};

const preparePayload = ({ values, id, currentAsset }) => {
  const {
    assetPrefix,
    assetFields,
    lastLocationHistory,
    drCode,
    assetLicense,
    containedInAsset,
    contents,
    grade,
    inspectionStatus,
    isNonOwned,
    labeled,
    notes,
    purchasedAtUtc,
    purchasedFrom,
    resources,
    shortNote,
    rental
  } = values;

  const payload = {
    assetFields,
    assetPrefix,
    drCode,
    lastLocationHistory: {},
    rental: {},
    isNonOwned
  };

  if (id && currentAsset?.id) {
    payload.id = currentAsset.id;
  }

  if (id && lastLocationHistory?.id) {
    payload.lastLocationHistory = lastLocationHistory;
  }

  if (lastLocationHistory.changeLocation?.id) {
    payload.lastLocationHistory.id = null;
    payload.lastLocationHistory.location = lastLocationHistory.changeLocation;
    payload.lastLocationHistory.sublocation = null;
  }
  if (lastLocationHistory.changeSublocation?.id) {
    payload.lastLocationHistory.id = null;
    payload.lastLocationHistory.sublocation = lastLocationHistory.changeSublocation;
  }
  if (lastLocationHistory.changeNotes) {
    payload.lastLocationHistory.changeNotes = lastLocationHistory.changeNotes;
  }

  if (assetLicense) {
    payload.assetLicense = assetLicense;
  }

  if (containedInAsset?.id) {
    payload.containedInAsset = containedInAsset;
  }

  if (contents?.length) {
    payload.contents = contents;
  }

  if (grade?.name) {
    payload.grade = grade;
  }

  if (inspectionStatus) {
    payload.inspectionStatus = inspectionStatus;
  }

  if (labeled) {
    payload.labeled = labeled;
  }

  if (notes) {
    payload.notes = notes;
  }

  if (purchasedFrom) {
    payload.purchasedFrom = purchasedFrom;
  }

  if (purchasedAtUtc) {
    payload.purchasedAtUtc = purchasedAtUtc;
  }

  if (resources?.length) {
    payload.resources = resources;
  }

  if (shortNote) {
    payload.shortNote = shortNote;
  }

  payload.rental.rentalStatus = rental.rentalStatus;

  if (rental.rentalStatus?.name === 'Reserved' && rental.project) {
    payload.rental.project = rental.project;
  }

  if (rental.rentalStatus?.name === 'InTransit' && rental.destination) {
    payload.rental.destination = rental.destination;
  }

  const isHoldForStatus = ['HoldForLow', 'HoldForHigh'].includes(rental.rentalStatus?.name);

  if (isHoldForStatus && rental.project) {
    payload.rental.project = rental.project;
  }

  if (isHoldForStatus && rental.endDate) {
    payload.rental.endDate = rental.endDate;
  }

  return payload;
};

const getFieldsToValidate = ({ id, drCode, lastLocationHistory }) => {
  const areDigitsExist = () => {
    const digits = drCode?.split('-')?.at(-1) || '';
    return !!digits?.length;
  };
  const validateDigits = () => {
    const digits = drCode?.split('-')?.at(-1) || '';
    const array = digits.split('');
    return array.every((item) => item === '0');
  };

  const fieldsToValidate = [
    {
      name: 'drCode',
      errorMessage: 'DR Code field is required',
      isInvalid: !drCode,
      selector: `input[name="drCode"]`
    },
    {
      name: 'drCode',
      errorMessage: 'Digital part of the asset is required',
      isInvalid: !drCode?.replace(/[^0-9]/g, ''),
      shouldCheck: !!drCode,
      selector: `input[name="drCode"]`
    },
    {
      name: 'drCode',
      errorMessage: 'The digits cannot consist only of zeros',
      isInvalid: validateDigits(),
      shouldCheck: !!drCode && areDigitsExist(),
      selector: `input[name="drCode"]`
    },
    {
      name: 'lastLocationHistory.changeLocation',
      errorMessage: 'Location field is required',
      isInvalid: !lastLocationHistory?.changeLocation?.id,
      shouldCheck: !id,
      selector: `input[id="lastLocationHistory.changeLocation"]`
    }
  ];

  return fieldsToValidate;
};

export { defaultForm, preparePayload, getFieldsToValidate };
