import { useDispatch } from 'react-redux';

import {
  getRootCauses,
  getRootCause,
  getCreationForm,
  createRootCause,
  updateRootCause,
  setFilter,
  clearState
} from 'actions/ticketRootCausesActions';

export function useRootCausesActions() {
  const dispatch = useDispatch();

  const getRootCausesAction = (f) => dispatch(getRootCauses(f));

  const getRootCauseAction = (q) => dispatch(getRootCause(q));

  const getCreationFormAction = () => dispatch(getCreationForm());

  const createRootCauseAction = (data) => dispatch(createRootCause(data));
  const updateRootCauseAction = (data) => dispatch(updateRootCause(data));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    getRootCausesAction,
    getRootCauseAction,
    getCreationFormAction,
    createRootCauseAction,
    updateRootCauseAction,
    setFilterAction,
    clearStateAction
  };
}
