import React from 'react';
import styles from './Form.module.scss';

import { FormLabel, FormSelectInput } from 'components/FormComponents';

import { useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useFieldArray, useFormContext } from 'react-hook-form';

export function Columns() {
  const { control, setValue } = useFormContext();
  const { fields } = useFieldArray({ control, name: 'columns' });

  const { configuration } = useBillOfLadingLogsSelector();
  const { columns, bolLogColumns } = configuration;

  if (!bolLogColumns?.length) return null;

  const selectOption = ({ name, value }) => setValue(name, value);

  return (
    <div className={styles.columns}>
      {fields.map(({ id, displayName }, index) => (
        <div key={id} className={styles.columns__row}>
          <FormLabel>{displayName || ''}</FormLabel>
          <FormSelectInput
            clearable
            name={`columns[${index}].column`}
            options={columns || []}
            onSelect={selectOption}
            enableHint
            getHint={(v) => (v?.exampleValue ? `e.g. ${v.exampleValue}` : '')}
          />
        </div>
      ))}
    </div>
  );
}
