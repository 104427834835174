import {
  SET_BATCH_ASSET_UPDATES_CREATION_FORM,
  SET_BATCH_ASSET_UPDATES_TAB,
  CLEAR_BATCH_ASSET_UPDATES_STATE
} from 'constants/reduceConstants';

const initialState = {
  selectedTab: '',
  formState: {},
  creationForm: {}
};

const batchAssetUpdatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BATCH_ASSET_UPDATES_TAB:
      return { ...state, selectedTab: payload };
    case SET_BATCH_ASSET_UPDATES_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_BATCH_ASSET_UPDATES_STATE:
      return { ...initialState, formState: payload };
    default:
      return state;
  }
};

export default batchAssetUpdatesReducer;
