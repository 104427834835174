import React from 'react';
import styles from './SchedulePagination.module.scss';

import { usePagination } from '@material-ui/lab/Pagination';

import { Default, Next, Previous, Ellipsis } from './PaginationButtons';
import { MenuItem, Select } from '@material-ui/core';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useUserConfig } from 'hooks/useUserConfig';
import { useDispatch } from 'react-redux';

import { updateUserConfig } from 'actions/commonActions';
import { TEAM_MEMBER_TRACKER_TABLE_PARAMETER } from 'constants/configTableConstants';
import { getEmptyFilters } from '../../helpers';

export default function SchedulePagination() {
  const { getTrackerDataAction } = useTrackerActions();
  const { filter, isMasterSelected, isResponseReceived } = useTrackerSelector();

  const dispatch = useDispatch();
  const { getUpdatedByTablesConfigCopy } = useUserConfig();

  const handlePageChange = (e, page) => {
    const value =
      page < 1 ? 1 : page > filter.pagination.totalPages ? filter.pagination.totalPages : page;
    getTrackerDataAction(
      {
        ...filter,
        filters: { ...filter.filters, columnLimit: 30, columnPage: 1 },
        pagination: { ...filter.pagination, page: value }
      },
      { isMasterSelected }
    );
  };

  const changeLimit = (limit) => {
    const configCopy = getUpdatedByTablesConfigCopy(TEAM_MEMBER_TRACKER_TABLE_PARAMETER, limit);
    dispatch(updateUserConfig(configCopy));
    getTrackerDataAction(
      {
        ...filter,
        filters: { ...filter.filters, columnLimit: 30, columnPage: 1 },
        pagination: { ...filter.pagination, limit, page: 1 }
      },
      { isMasterSelected }
    );
  };

  const { items } = usePagination({
    count: filter.pagination.totalPages,
    onChange: handlePageChange,
    page: filter.pagination.page
  });

  const isLimitSelectorAvailable = () => isMasterSelected && filter.pagination.totalPages > 1;
  const isPageSelectorAvailable = () => filter.pagination.totalPages !== 1;

  const rowsPerPageOptions = [10, 25, 50];

  const isPageSelected = (page) => page === filter.pagination.page;

  const isFilterEmpty = getEmptyFilters(filter);
  const isPaginationAvailable = !isMasterSelected && isFilterEmpty ? false : isResponseReceived;

  return isPaginationAvailable ? (
    <div className={styles.pagination}>
      {isLimitSelectorAvailable() && (
        <div className={styles.pagination__selector}>
          <label>Rows per page:</label>
          <Select
            classes={{ root: styles.select }}
            value={filter.pagination.limit || ''}
            onChange={(e) => changeLimit(e.target.value)}
            variant="outlined">
            {rowsPerPageOptions.length &&
              rowsPerPageOptions.map((option, ind) => (
                <MenuItem
                  classes={{ root: styles.select__root, icon: styles.select__icon }}
                  value={option}
                  key={`${ind}_${option}`}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </div>
      )}
      {isPageSelectorAvailable() && (
        <div className={styles.pagination__container}>
          {items.map(({ page, type, onClick }, index) => (
            <React.Fragment key={index}>
              {(() => {
                switch (type) {
                  case 'start-ellipsis':
                  case 'end-ellipsis':
                    return <Ellipsis />;
                  case 'next':
                    return <Next onClick={onClick} />;
                  case 'last':
                  case 'page':
                    return (
                      <Default page={page} selected={isPageSelected(page)} onClick={onClick} />
                    );
                  case 'previous':
                    return <Previous onClick={onClick} />;
                }
              })()}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  ) : null;
}
