import { STATES } from '../constants/commonConstants';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

const getShortState = (fullState) => Object.keys(STATES).find((key) => STATES[key] === fullState);

export const getAddressByGeoCoding = (geoData, states, { coordinatesOnly }) => {
  const { lat, lng, address } = geoData;

  const getAddress = () =>
    address?.['house_number'] || address?.['building'] || address?.['road'] || address?.['highway']
      ? `${address?.['house_number'] || address?.['building'] || ''} ${
          address?.['road'] || address?.['highway'] || ''
        }`
      : '';

  if (coordinatesOnly) {
    return { latitude: lat, longitude: lng };
  }

  return {
    latitude: lat,
    longitude: lng,
    address1: getAddress(),
    address2: address?.['county'] || '',
    city: address?.['city'] || address?.['town'] || '',
    state: states.find(({ name }) => name === getShortState(address?.state) || ''),
    zip: address?.postcode || ''
  };
};

export const checkMandatoryParametersForGettingCoordinates = ({ address1, state }) => {
  if (!address1?.length) return;
  if (!state?.id) return;
  return true;
};

export const searchLatLngByData = (data) => {
  const provider = new OpenStreetMapProvider({
    params: { 'accept-language': 'en', addressdetails: 1 }
  });
  return provider.search({ query: data });
};

export const getCoordinatesByApi = ({ address1, address2, city, state }) => {
  return searchLatLngByData(
    `${(address1 || '') + ' ' + (address2 || '')}, ${city ? city + ', ' : ''} ${state.name}`
  );
};

export const handleEmptyCoordinates = (lat, long) => {
  // backend doesn't accept coordinates with empty string values
  const latitude = lat === '' ? null : lat;
  const longitude = long === '' ? null : long;

  return [latitude, longitude];
};

export const extractCoordinates = (locations) => {
  const { x, y, raw } = locations[0];

  const latitude = y || raw?.lat || null;
  const longitude = x || raw?.lon || null;

  return [latitude, longitude];
};
