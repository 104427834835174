import React, { useState } from 'react';
import DetailsHeader from 'components/DetailsComponents/Header';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import CreateIcon from '@material-ui/icons/Create';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import { USER_EDIT_PATH, USER_LIST_PATH } from 'constants/routeConstants';
import DeleteIcon from '@material-ui/icons/Delete';
import { DELETE_USER } from 'constants/dialogPopupsData';
import { useUserActions, useUserSelector } from 'hooks/UserManagement';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';

export default function UserDetailsHeader() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { isEntityCreatorUser } = useUserConfig();

  const { deleteUserAction } = useUserActions();
  const { currentUser } = useUserSelector();
  const { id, firstName, lastName, email } = currentUser;

  const [dialogModalData, setDialogModalData] = useState({});

  const agreeModal = () => deleteUserAction(id);
  const closeModal = () => setDialogModalData({ isOpened: false });

  const handleEditClick = () => history.push(`${USER_EDIT_PATH}/${id}`);
  const handleRemoveClick = () => setDialogModalData({ ...DELETE_USER, isOpened: true });
  const handleBackClick = () => history.push(USER_LIST_PATH);

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <DetailsHeader breadcrumbs={[{ name: firstName + ' ' + lastName }]} title="View User">
        <HeaderButton onClick={handleEditClick}>
          <CreateIcon />
          {!isMobile && <span>Edit this user</span>}
        </HeaderButton>
        {!isEntityCreatorUser(email) && (
          <HeaderButton onClick={handleRemoveClick}>
            <DeleteIcon />
            {!isMobile && <span>Remove this user</span>}
          </HeaderButton>
        )}
        {isMobile && (
          <HeaderButton onClick={handleBackClick}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
      </DetailsHeader>
    </>
  );
}
