import React, { useEffect, useState } from 'react';
import styles from './DashboardWrapper.module.scss';

import { MainButton } from 'components/StyledComponents';
import AddWidgetPopup from 'components/AddWidgetPopup';

import { useAssetsDashboardsActions, useAssetsDashboardsSelector } from 'hooks/AssetsDashboards';

import { dashboardTypes } from '../../helpers';

export default function DashboardWrapper({ children }) {
  const [openWidget, setOpenWidget] = useState(false);

  const { tab, tabConfig } = useAssetsDashboardsSelector();
  const {
    getInspectionStatisticAction,
    getVerificationStatisticAction,
    getDashboardConfigAction,
    updateTabConfigAction
  } = useAssetsDashboardsActions();

  const actions = {
    [dashboardTypes.verification]: getVerificationStatisticAction,
    [dashboardTypes.inspection]: getInspectionStatisticAction
  };

  useEffect(() => {
    getDashboardConfigAction({ dashboardType: tab }).then(() => {
      actions[tab]();
    });
  }, []);

  const openWidgetPopup = () => setOpenWidget(true);

  const handleWidgetsUpdate = (items) => {
    updateTabConfigAction({ items }).then(() => {
      actions[tab]();
    });
  };

  return (
    <div className={styles.wrapper}>
      <AddWidgetPopup
        open={openWidget}
        setOpen={setOpenWidget}
        data={tabConfig?.items || []}
        onApply={handleWidgetsUpdate}
        dashboardType={tab}
      />

      <div className={styles.settings}>
        <MainButton text="Config widget" action={openWidgetPopup} type="primary" />
      </div>

      {children}
    </div>
  );
}
