import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_FILE_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import styles from './FilesUploadStyles.module.scss';
import { Input } from './components/Input';
import Layout from './components/Layout';
import Preview from './components/Preview';
import { FILES_LIMIT, MAX_FILE_SIZE } from './helpers';

const AVAILABLE_FORMATS = {
  general: {
    match:
      /(pdf|csv|png|jpg|jpeg|raw|tiff|bmp|gif|msword|text|excel|powerpoint|openxmlformats|xml)/,
    title: 'PDF, CSV, PNG, JPEG, RAW, TIFF, BMP, GIF, DOC, DOCX, TXT, XLS, XLSX , XML, PPTX, PPT'
  },
  looseItems: {
    match: /(csv|excel)/,
    title: 'CSV'
  },
  csvOnly: {
    match: /(csv|excel)/,
    title: 'CSV'
  }
};

const FILE_MIME_TYPES = `image/png,image/jpg,image/jpeg,image/jpg,image/raw,image/tiff,image/bmp,image/gif,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,
    text/csv,application/pdf,text/plain,application/excel,application/powerpoint,application/xhtml+xml,.csv`;

export default function FilesUpload({
  uploadedFiles,
  onChange,
  formatType,
  csvOnly = false,
  max = FILES_LIMIT,
  placeholder
}) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const cachedToken = getToken();
  const generateUploadParams = useCallback(() => {
    return {
      url: UPLOAD_FILE_URL,
      headers: {
        authorization: `Bearer ${cachedToken || ''}`
      }
    };
  }, [cachedToken]);

  const validateFileFormatAndSize = useCallback(
    (fileWithMeta) => {
      const { meta, xhr } = fileWithMeta;

      const selectedFormat = csvOnly
        ? AVAILABLE_FORMATS.csvOnly
        : AVAILABLE_FORMATS[formatType || 'general'];

      if (!meta.type.match(selectedFormat.match)) {
        return {
          valid: false,
          message: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed:
                ${selectedFormat.title}`
        };
      }

      if (meta.size > MAX_FILE_SIZE) {
        return {
          valid: false,
          message: `File "${meta.name}" exceeds maximum allowed upload size of 10MB`
        };
      }

      const uploadedFile = JSON.parse(xhr?.response || 'null');
      const files = [...uploadedFiles, uploadedFile];

      if (files.length > max) {
        return {
          valid: false,
          message: `Upload limit (${max}) exceeded`
        };
      }

      return { valid: true };
    },
    [csvOnly, formatType, max, uploadedFiles]
  );

  const handleFileValidation = useCallback(
    (fileWithMeta) => {
      const { valid, message } = validateFileFormatAndSize(fileWithMeta);

      if (!valid) {
        setPopupData({ ...popupData, isOpened: true, title: message });
      }

      return !valid;
    },
    [validateFileFormatAndSize, popupData]
  );

  const handleChangeStatus = useCallback(
    (fileWithMeta, status) => {
      if (['aborted', 'restarted', 'removed'].includes(status)) return;

      if (status === 'error_validation') {
        fileWithMeta.remove();
        return;
      }

      if (status === 'done') {
        const uploadedFile = JSON.parse(fileWithMeta.xhr?.response || 'null');
        onChange([...uploadedFiles, uploadedFile]);
      }
    },
    [onChange, uploadedFiles]
  );

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className={styles.dropzoneWrapper}>
        <Dropzone
          getUploadParams={generateUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={<Input placeholder={placeholder} />}
          inputWithFilesContent={<Input placeholder={placeholder} />}
          LayoutComponent={(props) => (
            <Layout upFiles={uploadedFiles} onChange={onChange} {...props} />
          )}
          PreviewComponent={Preview}
          maxSizeBytes={MAX_FILE_SIZE}
          accept={FILE_MIME_TYPES}
          validate={handleFileValidation}
          multiple={false}
          classNames={{
            dropzone: styles.dropzone,
            input: styles.dropzone_input,
            inputLabelWithFiles: styles.dzu_inputLabel,
            inputLabel: styles.dzu_inputLabel
          }}
        />
      </section>
    </>
  );
}
