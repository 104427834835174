import React from 'react';
import styles from '../Form.module.scss';

import { FormDayPicker, FormLabel, FormSelectInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { useFormContext } from 'react-hook-form';
import { useAssetSelector } from 'hooks/Asset';

import SectionTitle from '../SectionTitle';

export function License() {
  const { setValue } = useFormContext();

  const { creationForm } = useAssetSelector();
  const { states } = creationForm;

  const selectOption = ({ name, value }) => setValue(name, value, { shouldDirty: true });

  return (
    <div className={styles.general}>
      <SectionTitle>License Information</SectionTitle>
      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_row}>
            <FormLabel>State</FormLabel>
            <FormSelectInput
              clearable
              name="assetLicense.licenseState"
              options={states || []}
              onSelect={selectOption}
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Plate number</FormLabel>
            <FormInputText
              name="assetLicense.licensePlate"
              options={{ max: 20, rule: /[^0-9A-Za-z\\-]/g }}
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Registration Expiration</FormLabel>
            <FormDayPicker name="assetLicense.registrationExpirationAtUtc" />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>DOT Registration Expiration</FormLabel>
            <FormDayPicker name="assetLicense.dotRegistrationExpirationAtUtc" />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Make</FormLabel>
            <FormInputText name="assetLicense.make" options={{ max: 255 }} />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Model</FormLabel>
            <FormInputText name="assetLicense.model" options={{ max: 255 }} />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Model year</FormLabel>
            <FormDayPicker name="assetLicense.modelYear" showYearPicker format="y" />
          </div>
        </div>
      </div>
    </div>
  );
}
