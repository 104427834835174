import api from '../api';

export const getFilterCriteria = (query) => () =>
  api.selectLooseItemsPopup
    .getFilterCriteriaApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLooseItems = (query) => () =>
  api.selectLooseItemsPopup
    .getLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
