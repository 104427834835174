import React from 'react';

import NotificationCenterWrapper from './components/Wrapper';
import NotificationCenterTabs from './components/Tabs';
import NotificationCenterContent from './components/Content';

export default function NotificationCenter() {
  return (
    <NotificationCenterWrapper>
      <NotificationCenterTabs />
      <NotificationCenterContent />
    </NotificationCenterWrapper>
  );
}
