import React from 'react';
import styles from './SidebarContent.module.scss';
import PropTypes from 'prop-types';

import StyledLink from 'components/StyledLink';

import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import { LocationAssets } from './Assets';
import { LocationLooseItems } from './LooseItems';

/**
 * @typedef {Object} MapSidebarProps
 * @property {'ASSETS' | 'LOOSE_ITEMS'} type
 * @property {{}} location
 * @property {() => void} onClose
 */

/**
 * @param {MapSidebarProps} props
 */

function SidebarContent({ type, location, onClose }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <StyledLink
            bold
            isRedirectAvailable={!location.deleted}
            to={`${LOCATION_DETAILED_PATH}/${location.id}`}>
            {location?.siteCode || ''}
          </StyledLink>
        </div>
        <div className={styles.header__controls}>
          <IconButton className={styles.header__controls_btn} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      {type === 'ASSETS' && <LocationAssets location={location} />}

      {type === 'LOOSE_ITEMS' && <LocationLooseItems location={location} />}
    </div>
  );
}

SidebarContent.propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

SidebarContent.defaultProps = {
  type: '',
  location: {}
};

export default SidebarContent;
