import React from 'react';
import styles from './Form.module.scss';

import { FormLabel, FormSelectInput } from 'components/FormComponents';

import { useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useFormContext } from 'react-hook-form';

import { Columns } from './Columns';
import { Prompt } from './Prompt';

export function Form() {
  const { setValue } = useFormContext();

  const { creationForm } = useBillOfLadingLogsSelector();
  const { types } = creationForm;

  const selectType = ({ name, value }) => setValue(name, value, { shouldDirty: true });

  return (
    <div className={styles.form}>
      <div className={styles.type}>
        <FormLabel required>{`Log's Type`}</FormLabel>
        <FormSelectInput
          name="type"
          options={types || []}
          onSelect={selectType}
          enableHint
          hintText={`Select log's type for imported data`}
        />
      </div>

      <Prompt />

      <Columns />
    </div>
  );
}
