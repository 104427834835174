import React from 'react';
import styles from './FieldsContainer.module.scss';
import { IconButton } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

export default function LooseItemFieldsContainer() {
  const { control, setValue, getValues } = useFormContext();

  const handleFieldDelete = (chip) => {
    setValue(
      'customFields',
      getValues('customFields').filter(({ name }) => name !== chip.name),
      { shouldDirty: true }
    );
  };

  return (
    <Controller
      name="customFields"
      control={control}
      render={({ field: { value } }) => (
        <>
          {!!value?.length && (
            <div className={styles.fields}>
              {value.map((item, index) => (
                <div className={styles.fields__row} key={item.name}>
                  <label>{item?.name || ''}</label>
                  <div className={styles.fields__row_input}>
                    <FormInputText name={`customFields[${index}].value`} options={{ max: 200 }} />
                    <IconButton className={styles.clear} onClick={() => handleFieldDelete(item)}>
                      <CancelRoundedIcon className={styles.clearIcon} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    />
  );
}
