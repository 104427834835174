import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';

function OriginLocationDestinationDetails({ isMobile, data, label }) {
  return (
    <AccordionWrapper
      title={label === 'Destination' ? 'Destination' : 'Origin Location'}
      isMobile={isMobile}>
      <div className={styles.details}>
        <div className={styles.details__block}>
          {!isMobile && <p className={styles.details__label}>{label}</p>}
          {data.map((row) => (
            <div key={row.id} className={styles.details__block_item}>
              <label>{row.label}:</label>
              <span>{row.value}</span>
            </div>
          ))}
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default OriginLocationDestinationDetails;
