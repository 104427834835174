import api from '../api';
import qs from 'qs';

export const getDashboardConfigApi = () =>
  api.get(`/User/AssetDashboardsOptions`).then((res) => res.data);

export const updateDashboardConfigApi = (data) =>
  api.put(`/User/AssetDashboardsOptions`, data).then((res) => res.data);

export const getDashboardTablesDataApi = (query) =>
  api
    .get(
      `/AssetDashboards${
        query?.length ? '?' + qs.stringify(query, { allowDots: true, indices: false }) : ''
      }`
    )
    .then((res) => res.data);
