import React, { useEffect, useState } from 'react';
import styles from './SidebarContent.module.scss';

import Separator from 'components/Separator/Separator';
import { SeverityCalculations } from 'components/Counting';
import { BlueButton } from 'components/Buttons';
import { AssetFieldsPopup } from 'components/Popups';

import {
  useActiveLocationsMapActions,
  useActiveLocationsMapSelector
} from 'hooks/ActiveLocationsMap';

import { SidebarError } from './Error/Error';
import AccordionWrapper from './Accordion/AccordionWrapper';

import { generateAssetSummaryLink } from '../helpers';

export function LocationAssets({ location }) {
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState('');
  const [prefixes, setPrefixes] = useState([]);

  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({});

  const { filter } = useActiveLocationsMapSelector();
  const { getLocationAssetsAction, getLocationAssetsFieldsAction } = useActiveLocationsMapActions();

  const displayFields = !!filter?.filters?.assetFieldIds?.length;

  useEffect(() => {
    getLocationAssetsAction({ filters: { ...filter.filters, locationId: location.id } }).then(
      (res) => {
        if (res?.prefixes?.length) {
          setPrefixes(res.prefixes);
        } else {
          setError('Failed to load data. Please try refreshing the page.');
        }
        setResponseReceived(true);
      }
    );
  }, [location]);

  if (!responseReceived) {
    return null;
  }

  if (error) {
    return (
      <div className={styles.content}>
        <SidebarError message={error} />
      </div>
    );
  }

  const seeFields = async ({ id }) => {
    const res = await getLocationAssetsFieldsAction({
      filters: { ...filter.filters, locationId: location.id, assetPrefixId: id }
    });

    if (!res?.assetFields?.length) return;

    setPopupData(res);
    setOpen(true);
  };

  const getCounts = (slicedAssets = [], prefixId) =>
    slicedAssets.map((el) => ({
      ...el,
      link: el.count
        ? generateAssetSummaryLink(filter.filters, {
            prefixId,
            severityName: el.severity.name,
            locationId: location.id
          })
        : null
    }));
  const getTotalLink = (prefixId) =>
    generateAssetSummaryLink(filter.filters, {
      prefixId,
      locationId: location.id
    });

  return (
    <div className={styles.content}>
      {prefixes.map(({ assetPrefix, count, slicedAssets }, index) => (
        <React.Fragment key={assetPrefix.id}>
          <AccordionWrapper
            count={count}
            title={assetPrefix?.prefix || ''}
            link={getTotalLink(assetPrefix.id)}>
            <div className={styles.accordion_content}>
              <SeverityCalculations
                excludeTotal
                counting={getCounts(slicedAssets, assetPrefix.id)}
              />

              {displayFields && (
                <BlueButton
                  className={styles.fields_btn}
                  label="Fields"
                  onClick={() => seeFields(assetPrefix)}
                />
              )}
            </div>
          </AccordionWrapper>

          {index + 1 !== prefixes.length && <Separator />}
        </React.Fragment>
      ))}

      {open && <AssetFieldsPopup open={open} setOpen={setOpen} data={popupData} />}
    </div>
  );
}
