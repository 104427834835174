import React from 'react';
import styles from './components.module.scss';
import { formatDate } from 'helpers/AppHelpers';
import { useFormContext } from 'react-hook-form';
import { useUserConfig } from 'hooks/useUserConfig';
import { useParams } from 'react-router-dom';

export default function GeneralDetails() {
  const { id } = useParams();
  const { getValues } = useFormContext();
  const { userFirstName, userLastName } = useUserConfig();

  const data = [
    { id: 1, label: 'Asset', value: getValues('asset')?.drCode || '' },
    { id: 2, label: 'Report name', value: getValues('generalReportTemplate')?.name || '' },
    {
      id: 3,
      label: 'Date',
      value: getValues('createdAtUtc')
        ? formatDate(getValues('createdAtUtc'))
        : formatDate(new Date())
    },
    {
      id: 4,
      label: 'Created by',
      value: id
        ? getValues('createdByUser')?.firstName + ' ' + getValues('createdByUser')?.lastName || ''
        : userFirstName + ' ' + userLastName
    },
    { id: 5, label: 'Current location', value: getValues('location')?.siteCode || '' }
  ];

  return (
    <div className={styles.general}>
      {data.map((el) => (
        <section key={el.id} className={styles.general_details}>
          <label>{el.label}</label>
          <b>{el.value}</b>
        </section>
      ))}
    </div>
  );
}
