import { cloneObj, getProjectName, pluck } from 'helpers/AppHelpers';
import { handleDateFilter, handleIdsFilter, handleNamesFilter } from 'helpers/ChipGenerator';
import { useSearchParams } from 'hooks/useSearchParams';

const CREATED_PREFIX = 'Created';

const defaultFilter = {
  filters: {
    searchQuery: '',
    locationIds: [],
    sublocationIds: [],
    locationJobNumberIds: [],
    projectIds: [],
    statusNames: [],
    createdFrom: null,
    createdTo: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useSummaryParams = () => {
  return useSearchParams(['searchQuery']);
};

const generateInitialQuery = ({
  tableParam,
  getTableLimit,
  isAllLimitEnabled,
  filter,
  searchQuery
}) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(tableParam);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  if (searchQuery) {
    return setFilters({ searchQuery });
  }

  const isAll = isAllLimitEnabled(tableParam);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page }
  };
};

const generateChips = (criteria, filters = {}) => {
  const { locationsWithSublocations, projects, statuses } = criteria;
  const sublocations = locationsWithSublocations.flatMap(({ sublocations }) => sublocations);

  const {
    locationIds,
    sublocationIds,
    locationJobNumberIds,
    projectIds,
    statusNames,
    createdFrom,
    createdTo
  } = filters;

  let newChips = [];

  const filterConfigs = [
    {
      ids: locationIds,
      options: locationsWithSublocations,
      getLabel: (el) => el.siteCode,
      key: 'locationIds'
    },
    {
      ids: sublocationIds,
      options: sublocations,
      getLabel: (el) => el.name,
      key: 'sublocationIds'
    },
    {
      ids: locationJobNumberIds,
      options: locationsWithSublocations,
      getLabel: (el) => el.locationJobNumber,
      key: 'locationJobNumberIds'
    },
    {
      ids: projectIds,
      options: projects,
      getLabel: (el) => getProjectName(el),
      key: 'projectIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (statusNames?.length) {
    newChips = newChips.concat(handleNamesFilter(statusNames, statuses, 'statusNames'));
  }

  if (createdFrom || createdTo) {
    newChips = newChips.concat(handleDateFilter(createdFrom, createdTo, CREATED_PREFIX));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip, criteria) => {
  const { locationsWithSublocations } = criteria;

  const newFilters = cloneObj(filters);
  const { key, itemName, itemId } = deletedChip;

  switch (key) {
    case 'statusNames':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case 'locationIds': {
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);

      const sublocations = locationsWithSublocations.find(({ id }) => id === itemId)?.sublocations;
      const subIds = pluck(sublocations, 'id');
      if (subIds?.length) {
        newFilters.sublocationIds = newFilters.sublocationIds.filter((id) => !subIds.includes(id));
      }

      break;
    }
    case `duration_${CREATED_PREFIX}`:
      newFilters.createdTo = null;
      newFilters.createdFrom = null;
      break;
    case `dateFrom_${CREATED_PREFIX}`:
      newFilters.createdFrom = null;
      break;
    case `dateTo_${CREATED_PREFIX}`:
      newFilters.createdTo = null;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = Object.keys(defaultFilter.filters);

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const tableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Location', isSortable: true, parameter: 'location.siteCode' },
  { id: 3, label: 'Sublocation', isSortable: true, parameter: 'sublocation.name' },
  { id: 4, label: 'Project Number', isSortable: true, parameter: 'project.jobNumber' },
  { id: 5, label: 'Status', isSortable: true, parameter: 'status.name' },
  { id: 6, label: 'POC Name', isSortable: false, parameter: null },
  { id: 7, label: 'Created by', isSortable: false, parameter: null },
  { id: 8, label: 'Creation date', isSortable: true, parameter: 'createdAtUtc' }
];

export {
  defaultFilter,
  useSummaryParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  tableConfig
};
