import { useSelector } from 'react-redux';

import {
  selectFilterData,
  selectReportTypesData
} from 'pages/ReportLists/ReportTemplateList/selectors.js';

export default function useReportTemplatesSelector() {
  const filter = useSelector(selectFilterData());

  const reportTypes = useSelector(selectReportTypesData());

  return {
    filter,
    reportTypes
  };
}
