import React from 'react';
import styles from './DetailedRow.module.scss';
import clsx from 'clsx';

export default function DetailedRow({
  label,
  value,
  disableColon,
  disableFlex,
  alignCenter,
  children
}) {
  return (
    <div
      className={clsx(
        styles.info,
        alignCenter && styles.alignCenter,
        disableFlex && styles.disableFlex
      )}>
      <label className={styles.info__label}>
        {label}
        {!disableColon && ':'}
      </label>
      <span className={styles.info__span}>{value || ''}</span>
      {children}
    </div>
  );
}
