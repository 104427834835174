import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid #dcdcdc',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      margin: 0,
      marginBottom: -1,
      minHeight: 40
    }
  },
  content: {
    margin: 0,
    alignItems: 'center',
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary);

export default AccordionSummary;
