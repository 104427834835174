import api from '../api';
import { enqueueErrorSnackbar } from 'helpers/AppHelpers';

export const getQRCodesGeneratorCreationForm = () => () =>
  api.qr
    .getQRCodesGeneratorCreationFormApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getReportQRCodesGeneratorCreationForm = () => () =>
  api.qr
    .getReportQRCodesGeneratorCreationFormApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const generateQRCodes = (data) => () => {
  return api.qr.generateQRCodesApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const generateReportQRCodes = (data) => () => {
  return api.qr.generateReportQRCodesApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};
