import React from 'react';
import styles from './ErrorBlock.module.scss';

export default function ErrorBlock({ errorText }) {
  return (
    !!errorText && (
      <div className={styles.errorBlock}>
        <span>{errorText}</span>
      </div>
    )
  );
}
