import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { selectCategoryListData, selectSearchValueData } from './selectors';
import { selectUserConfig } from '../commonSelectors';
import {
  getCategoryList,
  clearCategoryList,
  setActiveCategory,
  setSearchValue
} from 'actions/categoryActions';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import { Button, Table, TableBody, TableContainer, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isAdminUser, isTeamMemberUser, reformatInputValue } from 'helpers/AppHelpers';
import {
  CATEGORY_EDIT_PATH,
  CATEGORY_DETAILED_PATH,
  PREFIX_EDIT_PATH,
  PREFIX_DETAILED_PATH,
  ASSETS_LIST_PATH,
  BATCH_OF_ASSETS_CREATION_PATH
} from 'constants/routeConstants';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StyledTableCell,
  StyledTableRow,
  MainButton
} from 'components/StyledComponents/';
import styles from './CategoryList.module.scss';
import SearchTextField from 'components/SearchTextField/SearchTextField';
import { useMobileViewport } from 'hooks/useMobileViewport';
import LinkComponent from 'components/LinkComponent/LinkComponent';

const NEED_SEARCH_STRING_LENGTH = 2;

function CategoryList({
  userConfig,
  categoryList,
  getCategoryListAction,
  clearCategoryListAction,
  setActiveCategoryAction,
  searchValue,
  setSearchValueAction,
  setAssetsPrefilterAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const { userRole } = userConfig;

  const [categoryData, setCategoryData] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!categoryList.length) {
      getCategoryListAction();
    } else {
      setCategoryData(categoryList);
    }
  }, [categoryList]);

  useEffect(() => {
    return () => {
      clearCategoryListAction();
    };
  }, []);

  useEffect(() => {
    if (!categoryList?.length) return;
    if (searchValue.length < NEED_SEARCH_STRING_LENGTH) {
      setExpanded([]);
      setCategoryData(categoryList);
    }
    if (searchValue.length >= NEED_SEARCH_STRING_LENGTH) {
      const filteredCategories = [];
      const newStateExpanded = [...expanded];
      categoryList.forEach((elem, index) => {
        const findCoincidences = (item) =>
          item.prefix?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
        const category = {
          ...elem,
          assetPrefixes: elem.assetPrefixes?.filter(findCoincidences)
        };
        filteredCategories.push(category);
        newStateExpanded[index] = !!category.assetPrefixes?.length;
      });
      setExpanded(newStateExpanded);
      setCategoryData(filteredCategories);
    }
  }, [searchValue, categoryList]);

  const onInput = (event) => {
    const value = reformatInputValue(event.target.value, 20, /[^A-Za-z0-9 ]/g);
    setSearchValueAction(value);
  };

  const onClearField = () => {
    setSearchValueAction('');
    setExpanded([]);
    setCategoryData(categoryList);
  };

  const handleToggleOne = (index) => {
    const newStateExpanded = [...expanded];
    newStateExpanded[index] = !newStateExpanded[index];
    setExpanded(newStateExpanded);
  };

  const onAddPrefixClick = (category) => {
    setActiveCategoryAction(category);
    history.push(PREFIX_EDIT_PATH);
  };

  const onAssetClick = (prefix) => {
    setAssetsPrefilterAction({ prefixIds: [prefix.id] });
    history.push(ASSETS_LIST_PATH);
  };

  const shouldShowHint = () =>
    searchValue?.length > 1 && !expanded.some((el) => el === true) && categoryList?.length;

  return (
    <section className={styles.pageContainer}>
      <section className={styles.headerWrapper}>
        <div className={styles.pageHeader}>
          <h1>Prefix/category list</h1>
          <div className={styles.headerControls}>
            {!isMobile && !isTeamMemberUser(userRole) && (
              <MainButton
                text="Create batch of assets"
                type="primary"
                action={() => history.push(BATCH_OF_ASSETS_CREATION_PATH)}
              />
            )}
            {isAdminUser(userRole) && (
              <MainButton
                isMobile={isMobile}
                text={isMobile ? '+' : 'Add category'}
                action={() => history.push(CATEGORY_EDIT_PATH)}
                type="primary"
              />
            )}
          </div>
        </div>
        <div className={styles.searchPanel}>
          <SearchTextField
            value={searchValue}
            onFieldInput={onInput}
            onClearField={onClearField}
            shouldShowHint={shouldShowHint}
            disableRightMargin={true}
          />
        </div>
      </section>

      {!!categoryData.length &&
        categoryData.map((category, index) => (
          <Accordion key={index} expanded={!!expanded[index]}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  className={styles.accordionIcon}
                  onClick={() => handleToggleOne(index)}
                />
              }>
              <div className={styles.accordionTitle}>
                <LinkComponent
                  path={`${CATEGORY_DETAILED_PATH}/${category.id}`}
                  name={category.name}
                  boldText
                />
              </div>
              {isAdminUser(userRole) && (
                <Button
                  className={styles.accordionButton}
                  onClick={() => onAddPrefixClick(category)}>
                  Add prefix
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {category.assetPrefixes.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="customized table" className={styles.table}>
                    <TableBody>
                      {category.assetPrefixes?.map((row) => (
                        <StyledTableRow key={row.prefix}>
                          <StyledTableCell className={styles.prefixCell} scope="row">
                            <LinkComponent
                              path={`${PREFIX_DETAILED_PATH}/${row.id}`}
                              name={row.prefix}
                              boldText
                            />
                          </StyledTableCell>
                          <StyledTableCell className={styles.descCell}>
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell className={styles.lastCell} align="right">
                            <a className={styles.rowLink} onClick={() => onAssetClick(row)}>
                              VIEW ASSETS
                            </a>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer component={Paper}>
                  <Table className={styles.table}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          {categoryList[index] && categoryList[index].assetPrefixes.length
                            ? 'No matches'
                            : 'No prefixes'}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  categoryList: selectCategoryListData(),
  searchValue: selectSearchValueData()
});

const mapDispatchToProps = {
  getCategoryListAction: getCategoryList,
  setSearchValueAction: setSearchValue,
  clearCategoryListAction: clearCategoryList,
  setActiveCategoryAction: setActiveCategory,
  setAssetsPrefilterAction: setAssetsPrefilter
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CategoryList);
