import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { ASSET_DETAILED_PATH, TICKET_DETAILED_PATH } from 'constants/routeConstants';
import styles from './TicketTables.module.scss';
import StatusIndicator from 'components/StatusIndicator';
import { dateTimeFormat, formatDate } from 'helpers/AppHelpers';
import React from 'react';

export default function TicketTableRow({ row, type }) {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <LinkComponent
          name={row.asset?.drCode || ''}
          path={`${ASSET_DETAILED_PATH}/${row.asset?.id}`}
        />
      </StyledTableCell>
      <StyledTableCell>
        <LinkComponent name={row?.id || ''} path={`${TICKET_DETAILED_PATH}/${row.id}`} boldText />
      </StyledTableCell>
      <StyledTableCell>{row?.type || ''}</StyledTableCell>
      <StyledTableCell>
        <div className={styles.status}>
          <StatusIndicator status={row.severity?.name} />
        </div>
      </StyledTableCell>
      <StyledTableCell>{row?.description || ''}</StyledTableCell>
      <StyledTableCell>
        {row?.[`${type}ByUser`]
          ? row[`${type}ByUser`].firstName + ' ' + row[`${type}ByUser`].lastName
          : ''}
      </StyledTableCell>
      <StyledTableCell>
        {row?.[`${type}AtUtc`] ? formatDate(row[`${type}AtUtc`], dateTimeFormat) : ''}
      </StyledTableCell>
    </StyledTableRow>
  );
}
