import { useSelector } from 'react-redux';
import {
  selectPicklistAssetsCounting,
  selectSinglePicklistData,
  selectFilterData
} from 'pages/PicklistAndLeavelist/PicklistSummary/selectors';

export function usePicklistSelector() {
  const currentPicklist = useSelector(selectSinglePicklistData());

  const assetsCounting = useSelector(selectPicklistAssetsCounting());

  const filter = useSelector(selectFilterData());

  return {
    filter,
    currentPicklist,
    assetsCounting
  };
}
