import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getMasterTrackerDataApi = (query) =>
  api.get(`/TeamMemberTracker?${queryToString(query)}`).then((res) => res);

export const getTrackerConfigDataApi = (query) =>
  api.get(`/User/TeamMemberTrackerConfig?${queryToString(query)}`).then((res) => res.data);

export const updateTrackerConfigDataApi = (params, query) =>
  api.put(`/User/TeamMemberTrackerConfig?${queryToString(query)}`, params).then((res) => res.data);

export const getBreakdownTrackerDataApi = (query) =>
  api.get(`/TeamMemberTracker/Breakdown?${queryToString(query)}`).then((res) => res);

export const getFilterCriteriaApi = (query) =>
  api.get(`/TeamMemberTracker/FilterCriteria?${queryToString(query)}`).then((res) => res.data);

export const postTrackerDayApi = (params) =>
  api.post(`/TeamMemberTrackerDay`, params).then((res) => res.data);

export const putTrackerDayApi = (params) =>
  api.put(`/TeamMemberTrackerDay`, params).then((res) => res.data);

export const sendGroupNotificationApi = (ids) =>
  api.post(`/TeamMemberTracker/Schedule`, ids).then((res) => res.data);
