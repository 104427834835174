import React, { useEffect } from 'react';
import styles from './LocationDetailed.module.scss';

import { useParams } from 'react-router-dom';

import { useLocationActions } from '../hooks/useLocationActions';
import { useLocationSelector } from '../hooks/useLocationSelector';

import LocationInfo from './components/LocationInfo/LocationInfo';
import Header from './components/Header';
import LocationTables from './components/LocationTables';

import { useUserConfig } from 'hooks/useUserConfig';
import {
  LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER,
  LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER,
  LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER
} from 'constants/configTableConstants';

export default function LocationDetailed() {
  const { id } = useParams();

  const {
    getLocationAction,
    getOpenedTickets,
    getClosedTickets,
    getLooseItems,
    getRentalStatusesAction,
    clearStateAction
  } = useLocationActions();
  const selector = useLocationSelector();
  const { currentLocation } = selector;

  const { getTableLimit, isConfigReceived } = useUserConfig();

  useEffect(() => {
    if (id) {
      getLocationAction(id);
    }
  }, [id]);

  const getQuery = (tableParam, key) => ({
    filters: { ...selector[key].filters, locationIds: [+id], isLocation: true },
    pagination: { ...selector[key].pagination, limit: getTableLimit(tableParam) },
    isDataReceived: false,
    sortRules: []
  });

  useEffect(() => {
    if (!isConfigReceived) return;
    getClosedTickets(
      getQuery(LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER, 'closedTicketsListFilter')
    );
    getOpenedTickets(
      getQuery(LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER, 'openedTicketsListFilter')
    );
    getLooseItems(getQuery(LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER, 'looseItemsFilter'));
  }, [isConfigReceived]);

  useEffect(() => {
    getRentalStatusesAction({ filters: { isHoldFor: false } });
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <section className={styles.pageContainer}>
      {currentLocation?.id === +id && (
        <>
          <Header />
          <LocationInfo />
          <LocationTables />
        </>
      )}
    </section>
  );
}
