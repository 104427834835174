const {
  getUserFullName,
  getUniqueID,
  enrichNameToId,
  formatDate,
  cloneObj
} = require('helpers/AppHelpers');

const createChip = (key, label) => ({ chipId: getUniqueID(), key, label });
const getUsedItems = (ids, items) => items.filter(({ id }) => ids.includes(id));

const handleIdsFilter = (ids, options, getLabel, key) => {
  return getUsedItems(ids, options).map((el) => ({
    ...createChip(key, getLabel(el)),
    itemId: el.id
  }));
};

const handleNamesFilter = (names, options, key) => {
  return getUsedItems(names, enrichNameToId(options)).map((el) => ({
    ...createChip(key, el.displayName),
    itemId: el.name
  }));
};

export const generateChips = (options, filters = {}) => {
  const { userIds, historyEventTypes, startDate, endDate, createdAtUtc, entityId } = filters;

  const { users, eventTypes } = options;

  let newChips = [];

  if (userIds?.length) {
    newChips = newChips.concat(
      handleIdsFilter(userIds, users, (el) => getUserFullName(el), 'userIds')
    );
  }

  if (historyEventTypes?.length) {
    newChips = newChips.concat(
      handleNamesFilter(historyEventTypes, eventTypes, 'historyEventTypes')
    );
  }

  if (startDate || endDate) {
    const isTwoDatesSelected = startDate && endDate;
    newChips.push({
      key: isTwoDatesSelected ? 'startToEnd' : startDate ? 'startDate' : 'endDate',
      label: isTwoDatesSelected
        ? formatDate(startDate) + ' - ' + formatDate(endDate)
        : formatDate(startDate || endDate),
      chipId: getUniqueID()
    });
  }

  if (createdAtUtc && entityId) {
    newChips = newChips.concat([createChip('LastModified', 'Last Modified')]);
  }

  return newChips.filter(Boolean);
};

export const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;

  switch (key) {
    case 'startToEnd':
      newFilters.startDate = null;
      newFilters.endDate = null;
      break;
    case 'startDate':
      newFilters.startDate = null;
      break;
    case 'endDate':
      newFilters.endDate = null;
      break;
    case 'LastModified':
      newFilters.createdAtUtc = null;
      newFilters.entityId = null;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};
