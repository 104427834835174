import React, { useState } from 'react';
import styles from './components.module.scss';

import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import SelectOnMapButton from 'components/GeoMap/SelectOnMapButton';
import GeoMap from 'components/GeoMap/GeoMap';

import { useFormContext } from 'react-hook-form';
import { useLocationSelector } from 'hooks/Location';

import { getAddressByGeoCoding } from 'helpers/GeoCodingHelpers';

import clsx from 'clsx';

export default function LocationPositionDetails() {
  const [openMap, setOpenMap] = useState(false);

  const [mapPreData, setMapPreData] = useState({});

  const { setValue, reset, getValues } = useFormContext();

  const { statesList } = useLocationSelector();

  const selectState = (name, value) => setValue(name, value);

  const onPickData = (data) => {
    reset({
      ...getValues(),
      ...getAddressByGeoCoding(data, statesList, {
        coordinatesOnly: !!getValues('addressAndCoordinatesNotMatched')
      })
    });
  };

  const handleOpenMap = () => {
    const lat = getValues('latitude');
    const lng = getValues('longitude');
    setMapPreData({ lat, lng });
    setOpenMap(true);
  };

  return (
    <div className={styles.details}>
      {openMap && (
        <GeoMap open={openMap} setOpen={setOpenMap} mapPreData={mapPreData} onSelect={onPickData} />
      )}
      <div className={clsx(styles.details__block, styles.separate)}>
        <div className={clsx(styles.details__block_checkbox, styles.full)}>
          <label>Address & Geo Coordinates are not matched</label>
          <FormCheckbox name="addressAndCoordinatesNotMatched" />
        </div>
      </div>
      <div className={styles.details__block}>
        <div className={styles.details__block_field}>
          <label>Address</label>
          <FormInputText name="address1" options={{ max: 255 }} />
        </div>
        <div className={styles.details__block_field}>
          <label></label>
          <FormInputText name="address2" options={{ max: 255 }} />
        </div>
        <div className={styles.details__block_field}>
          <label>City</label>
          <FormInputText name="city" options={{ max: 40 }} />
        </div>
        <div className={styles.details__block_field}>
          <label>State</label>
          <FormSearchInput name="state" options={statesList || []} onSelect={selectState} />
        </div>
        <div className={styles.details__block_field}>
          <label>Zip</label>
          <FormInputText name="zip" options={{ max: 18, rule: /[^0-9_ ]/g }} />
        </div>
      </div>
      <div className={styles.details__block}>
        <section className={styles.details__block_field}>
          <label>Latitude</label>
          <FormInputText name="latitude" options={{ max: 18, rule: /[^0-9.\\-]/g }} />
        </section>
        <section className={styles.details__block_field}>
          <label>Longitude</label>
          <FormInputText name="longitude" options={{ max: 18, rule: /[^0-9.\\-]/g }} />
        </section>
        <section className={clsx(styles.details__block_field, styles.map)}>
          <label></label>
          <SelectOnMapButton onClick={handleOpenMap} />
        </section>
      </div>
    </div>
  );
}
