const colors = {
  sectionColor1: '#EEA6A2',
  sectionColor2: '#7EC37E',
  sectionColor3: '#A8D5FF',
  strokeColor: '#fff'
};

export const getOptions = (labels, title, outerColors, fileName, hideToolbar) => ({
  chart: {
    type: 'pie',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    toolbar: {
      show: !hideToolbar,
      export: {
        csv: {
          filename: fileName
        },
        svg: {
          filename: fileName
        },
        png: {
          filename: fileName
        }
      }
    }
  },
  labels,
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -20
      }
    }
  },
  title: {
    text: title,
    align: 'center',
    margin: 20,
    style: {
      fontSize: '14px',
      fontWeight: 400
    },
    offsetY: -7,
    floating: true
  },
  colors: outerColors || [colors.sectionColor1, colors.sectionColor2, colors.sectionColor3],
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    markers: {
      width: 8,
      height: 8
    },
    itemMargin: {
      horizontal: 14,
      vertical: 1
    }
  },
  stroke: { show: true, width: 1, colors: [colors.strokeColor] },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      fontSize: '10px',
      colors: [colors.dataLabelColor]
    },
    dropShadow: {
      enabled: false
    },
    formatter: (val) => val.toFixed(1) + '%'
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.7
      }
    }
  }
});
