import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';

import { SummaryHeaderWrapper, SummaryPageHeader } from 'components/SummaryComponents';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { buildPanel } from './helpers';
import Card from './Card';

export function Home() {
  const isMobile = useMobileViewport();
  const [panelItems, setPanelItems] = useState([]);

  const config = useUserConfig();
  const { isConfigReceived } = config;

  useEffect(() => {
    if (isConfigReceived) {
      setPanelItems(() => buildPanel(config, isMobile));
    }
  }, [isConfigReceived, isMobile]);

  return (
    <div className={styles.wrapper}>
      {isMobile && (
        <SummaryHeaderWrapper className={styles.header}>
          <SummaryPageHeader title="Welcome to DRAT"></SummaryPageHeader>
        </SummaryHeaderWrapper>
      )}

      {!isMobile && <p className={styles.title}>Welcome to Deployed Resources Asset Tracker</p>}

      <div className={styles.panel}>
        {panelItems.map((screen) => (
          <Card key={screen.id} screen={screen} />
        ))}
      </div>
    </div>
  );
}
