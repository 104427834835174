import React from 'react';
import styles from './ProjectScheduleTable.module.scss';

import { useTrackerSelector } from 'hooks/TeamMemberTracker';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { StyledTableRow } from 'components/StyledComponents';

import { MonthRow, DayRow, NoteRow } from './Head';
import {
  EmploymentCell,
  TeamMemberCell,
  ProjectRow,
  CapacityCell,
  CapacityDayCell,
  TotalRow
} from './Body';

import { getEmptyFilters } from '../../helpers';
import { getProjectName } from 'helpers/AppHelpers';

export default function ProjectScheduleTable() {
  const { trackerTableData, isMasterSelected, filter, isResponseReceived } = useTrackerSelector();

  const { teamMembers, projects } = trackerTableData;

  const isFilterEmpty = () => getEmptyFilters(filter);

  const isTableAvailable = () => (isMasterSelected ? !!teamMembers?.length : !!projects?.length);

  return isTableAvailable() ? (
    <TableContainer component={Paper} className={styles.table}>
      <Table>
        <TableHead>
          <MonthRow />
          <DayRow />
          <NoteRow />
        </TableHead>
        {isMasterSelected ? (
          <TableBody>
            {teamMembers.map((row, index) => (
              <StyledTableRow key={index}>
                <TeamMemberCell data={row || {}} />
                {row.employmentByDays.map((data) => (
                  <EmploymentCell
                    data={data}
                    teamMember={row.teamMember}
                    key={data.day}
                    isContractSCA={row.isContractSCA}
                  />
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {projects.map((row, index) => (
              <React.Fragment key={index}>
                <ProjectRow project={row.project} />
                {row.teamMembers.map((memberData, tmIndex) => (
                  <StyledTableRow key={tmIndex}>
                    <TeamMemberCell data={memberData || {}} />
                    {memberData.employmentByDays.map((data) => (
                      <EmploymentCell
                        data={data}
                        teamMember={memberData.teamMember}
                        isContractSCA={memberData.isContractSCA}
                        key={data.day}
                      />
                    ))}
                  </StyledTableRow>
                ))}
                {row.capacities.map((capacityData, capacityIndex) => (
                  <StyledTableRow key={capacityIndex}>
                    <CapacityCell
                      capacity={capacityData.capacity}
                      isFirstRow={capacityIndex === 0}
                    />
                    {capacityData.capacityByDate.map(({ count, day, teamMembers }) => (
                      <CapacityDayCell
                        key={day}
                        isFirstRow={capacityIndex === 0}
                        projectName={getProjectName(row.project)}
                        count={count}
                        day={day}
                        teamMembers={teamMembers}
                        capacityName={capacityData.capacity?.name || ''}
                      />
                    ))}
                  </StyledTableRow>
                ))}
                <TotalRow total={row.total} />
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  ) : (
    isResponseReceived && (
      <div className={styles.emptyTable}>
        <span>
          {!isMasterSelected && isFilterEmpty()
            ? 'Set filter parameters to generate Project Schedule'
            : isFilterEmpty()
            ? 'No available data.'
            : 'No matches in the table'}
        </span>
      </div>
    )
  );
}
