import React, { useEffect, useRef, useState } from 'react';
import styles from './Wrapper.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';

import {
  CREATE_BOL_LOG,
  CREATE_BOL_LOG_TYPE,
  EXIT_WITHOUT_SAVING,
  UPDATE_BOL_LOG,
  UPDATE_BOL_LOG_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { BILL_OF_LADING_LOGS_PATH } from 'constants/routeConstants';

import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useBillOfLadingLogsActions } from 'hooks/BillOfLadingLogs';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import { getBackendErrors } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

export default function Wrapper({ children }) {
  const history = useHistory();
  const { id } = useParams();

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { createLogAction, updateLogAction } = useBillOfLadingLogsActions();

  const breadcrumbs = useRef([{ name: id ? 'Edit BOL Log' : 'Add BOL Log' }]);

  const { formState, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const closeModal = () => setModalData({});
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;
  const getBack = () => history.push(BILL_OF_LADING_LOGS_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const handleResponse = (err) => {
    if (!err?.errors) return;

    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case CREATE_BOL_LOG_TYPE:
        closeModal();
        createLogAction(getValues('items')).then(handleResponse);
        break;
      case UPDATE_BOL_LOG_TYPE:
        closeModal();
        updateLogAction(getValues('items')[0]).then(handleResponse);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    setModalData(id ? UPDATE_BOL_LOG : CREATE_BOL_LOG);
  };

  const validateForm = () => {
    let isFormValid = true;
    const items = getValues('items');

    items.forEach(({ type }, index) => {
      if (!type?.id) {
        setError(`items[${index}].type`, getErrorsProperties('Field is required'));
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.header}>
        <BreadcrumbsNav
          itemsArray={breadcrumbs.current}
          setDialogModalData={setModalData}
          formIsChanged={isDirty}
        />

        <div className={styles.header__block}>
          <h1>{id ? 'Edit' : 'Add'} BOL Log</h1>
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'Save' : 'Add'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid}
        />
      </div>
    </section>
  );
}
