import { getRandomId } from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id || obj?.id === 0) {
      return {
        ...obj,
        key: key,
        displayName: obj[name] || '',
        chipId: getRandomId()
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const { assetPrefixes, locations, userRoles, requestOrderRoles, departments } = options;
  let chipsArray = [];
  if (inputFilter?.locationIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(locations, inputFilter.locationIds, 'location', 'siteCode')
    );
  }
  if (inputFilter?.locationJobNumberIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        locations,
        inputFilter.locationJobNumberIds,
        'jobNumber',
        'locationJobNumber'
      )
    );
  }
  if (inputFilter?.prefixIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(assetPrefixes, inputFilter['prefixIds'], 'prefix', 'prefix')
    );
  }
  if (inputFilter?.userRoleIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(userRoles, inputFilter['userRoleIds'], 'role', 'role')
    );
  }
  if (inputFilter?.requestOrderRoleIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        requestOrderRoles,
        inputFilter['requestOrderRoleIds'],
        'requestOrderRole',
        'displayName'
      )
    );
  }
  if (inputFilter?.departmentIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(departments, inputFilter['departmentIds'], 'department', 'name')
    );
  }
  if (inputFilter?.isBaaRecipient) {
    chipsArray.push({
      key: 'isBaaRecipient',
      displayName: 'Buy Ahead Account recipient',
      chipId: getRandomId()
    });
  }

  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'location':
      return {
        ...values,
        locationIds: values.locationIds.filter((id) => id !== chip.id)
      };
    case 'jobNumber':
      return {
        ...values,
        locationJobNumberIds: values.locationJobNumberIds.filter((id) => id !== chip.id)
      };
    case 'prefix':
      return {
        ...values,
        prefixIds: values.prefixIds.filter((id) => id !== chip.id)
      };
    case 'role':
      return {
        ...values,
        userRoleIds: values.userRoleIds.filter((id) => id !== chip.id)
      };
    case 'requestOrderRole':
      return {
        ...values,
        requestOrderRoleIds: values.requestOrderRoleIds.filter((id) => id !== chip.id)
      };
    case 'department':
      return {
        ...values,
        departmentIds: values.departmentIds.filter((id) => id !== chip.id)
      };
    case 'isBaaRecipient':
      return {
        ...values,
        isBaaRecipient: false
      };
    default:
      break;
  }
};
