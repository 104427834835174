import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  ERROR_IMPORT_LOOSE_ITEMS,
  SUCCESS_CREATE_LOOSE_ITEMS
} from '../constants/infoSnackbarData';

export const deserializeLooseItems = (data) => () =>
  api.importFilesPopup.deserializeLooseItemsApi(data).then(
    (res) => {
      if (res?.data?.length) {
        const successResp = res?.data?.filter(({ statusCode }) => statusCode === 200);
        const failureResp = res?.data?.filter(({ statusCode }) => statusCode !== 200);
        if (successResp?.length) {
          enqueueSuccessSnackbar(SUCCESS_CREATE_LOOSE_ITEMS);
        }
        if (failureResp?.length) {
          const names = failureResp.map(({ itemName }) => itemName).join(', ');
          enqueueErrorSnackbar(ERROR_IMPORT_LOOSE_ITEMS + ' ' + names);
        }
      }

      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const getTemplate = () => () =>
  api.importFilesPopup.getTemplateApi().then(
    (res) => res,
    (err) => console.log(err)
  );
