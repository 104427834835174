import React, { useEffect, useState } from 'react';
import styles from './TicketComponents.module.scss';

import { FormLabel, FormRow } from 'components/FormComponents';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import { AddNewButton } from 'components/Buttons';

import { useTicketSelector } from 'hooks/Ticket';
import { useFormContext, Controller } from 'react-hook-form';

import { enqueueErrorSnackbar } from 'helpers/AppHelpers';

import { ERROR_ENTITY_EXIST } from 'constants/infoSnackbarData';

import Subhead from './Subhead';

export function NecessaryItems() {
  const [looseItemOptions, setLooseItemOptions] = useState([]);

  const { control, watch, getValues, setValue } = useFormContext();

  const { creationForm } = useTicketSelector();
  const { assetPrefixes, looseItemCategories, looseItems } = creationForm;

  useEffect(() => {
    setLooseItemOptions(looseItems || []);
  }, [looseItems]);

  const assetsWatcher = watch('includeAssets');
  const looseItemsWatcher = watch('includeLooseItems');
  const othersWatcher = watch('includeOthers');

  const handleCategorySelect = (_, value) => {
    if (value?.length) {
      const filteredItems = looseItems.filter(
        ({ looseItemCategories }) =>
          !!looseItemCategories.filter(({ id }) => value.includes(id)).length
      );
      setLooseItemOptions(filteredItems);
    } else {
      setLooseItemOptions(looseItems);
    }
  };

  const addCustomField = (entityName) => {
    const items = getValues('otherItems');
    const names = items.map((el) => el.entityName.toLowerCase());

    if (names.includes(entityName.toLowerCase())) {
      enqueueErrorSnackbar(ERROR_ENTITY_EXIST);
      return;
    }

    items.push({ entityName });
    setValue('otherItems', items);
    setValue('otherItemName', '');
  };

  const handleCheckboxClick = (name, value) => {
    if (value) return;

    switch (name) {
      case 'includeAssets': {
        setValue('assetPrefixes', []);
        break;
      }
      case 'includeLooseItems': {
        setValue('narrowCategoryIds', []);
        setValue('looseItems', []);
        break;
      }
      case 'includeOthers': {
        setValue('otherItemName', '');
        setValue('otherItems', []);
        break;
      }
    }
  };

  return (
    <div className={styles.section}>
      <Subhead>Necessary Items</Subhead>

      <div className={styles.container}>
        <div className={styles.container__block}>
          <FormRow type="checkbox">
            <FormLabel>Asset</FormLabel>
            <FormCheckbox name="includeAssets" onSelectTriggered={handleCheckboxClick} />
          </FormRow>
          {!!assetsWatcher && (
            <>
              <FormRow>
                <FormLabel>Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="assetPrefixes"
                  menuItems={assetPrefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: false,
                    disableLabel: true,
                    hideTags: true
                  }}
                />
              </FormRow>
              <FormChipsContainer fieldName="assetPrefixes" label="prefix" deleteParameter="id" />
            </>
          )}

          <FormRow type="checkbox">
            <FormLabel>Loose Items</FormLabel>
            <FormCheckbox name="includeLooseItems" onSelectTriggered={handleCheckboxClick} />
          </FormRow>
          {!!looseItemsWatcher && (
            <>
              <FormRow>
                <FormLabel>Narrow by Category</FormLabel>
                <FormAutoMultiSelect
                  name="narrowCategoryIds"
                  menuItems={looseItemCategories || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleCategorySelect
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Loose Item name</FormLabel>
                <FormAutoMultiSelect
                  name="looseItems"
                  menuItems={looseItemOptions || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: false,
                    hideTags: true
                  }}
                />
              </FormRow>
              <FormChipsContainer fieldName="looseItems" label="name" deleteParameter="id" />
            </>
          )}

          <FormRow type="checkbox">
            <FormLabel>Other</FormLabel>
            <FormCheckbox name="includeOthers" onSelectTriggered={handleCheckboxClick} />
          </FormRow>
          {!!othersWatcher && (
            <>
              <FormRow className={styles.otherRow}>
                <FormLabel>Name</FormLabel>
                <div className={styles.otherField}>
                  <FormInputText name="otherItemName" options={{ max: 100 }} />
                  <span className={styles.tip}>Write to add a new field</span>
                  <Controller
                    control={control}
                    name="otherItemName"
                    render={({ field: { value } }) => (
                      <AddNewButton
                        onClick={() => addCustomField(value)}
                        isDisabled={!value?.length}
                      />
                    )}
                  />
                </div>
              </FormRow>
              <FormChipsContainer
                fieldName="otherItems"
                label="entityName"
                deleteParameter="entityName"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
