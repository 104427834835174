import React from 'react';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import styles from './Content.module.scss';
import TotalFooterCell from './TotalFooterCell';
import TotalOnRentCell from './TotalOnRentCell';

function TotalRowsBlock({ available, rent }) {
  return (
    <>
      <StyledTableRow>
        <StyledTableCell className={styles.total} colSpan={10000}>
          <span>Total</span>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TotalFooterCell />
        {available.map((count, i) => (
          <StyledTableCell key={i} className={styles.totalCount}>
            {count}
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow>
        <TotalOnRentCell />
        {rent.map((count, i) => (
          <StyledTableCell key={i} className={styles.totalCount}>
            {count}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );
}

export default TotalRowsBlock;
