import {
  SET_DASHBOARD_CONFIG,
  SET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA
} from 'constants/reduceConstants';

const initialState = {
  dashboardConfig: {},
  dashboardTablesData: {}
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DASHBOARD_CONFIG:
      return {
        ...state,
        dashboardConfig: payload
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardTablesData: payload
      };
    case CLEAR_DASHBOARD_DATA:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
