import React from 'react';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import { MainButton } from 'components/StyledComponents/';
import { TextField } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

export default function TitleReportTemplate({
  index,
  template,
  dragIsActive,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  published
}) {
  const { data } = template;
  const { active } = data;

  const onInput = (event) => {
    let value = event.target.value;

    value = value.substr(0, 500);
    value = value.replace(allKeyboardLatSymbols, '');

    onUpdate(index, {
      ...template,
      data: { ...template.data, text: value }
    });
  };

  const onSave = () => {
    if (data.text) {
      onUpdate(index, { ...template, data: { ...template.data, active: false } });
    } else {
      onDelete(index);
    }
  };

  return (
    <section
      className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Title</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <TextField
              placeholder="Enter the title text"
              name="text"
              className={styles.textInput}
              variant="outlined"
              value={data.text || ''}
              onChange={onInput}
            />
          </div>

          <div className={styles.activeTemplate__controls}>
            <div></div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={!data.text?.length}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div
              className={`${styles.inactiveTemplate__controls} ${
                dragIsActive ? styles.hidden : ''
              }`}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              <button>
                <FileCopyIcon onClick={() => onCopy(index)} />
              </button>
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div>
              <h1>{data.text || ''}</h1>
            </div>
          </div>
        </section>
      )}
    </section>
  );
}
