import { mobileViewport } from 'constants/commonConstants';
import { useState, useEffect } from 'react';

export const useMobileViewport = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileViewport);

  useEffect(() => {
    const handleWindowResize = () => setIsMobile(window.innerWidth < mobileViewport);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return isMobile;
};
