import React from 'react';
import styles from './SearchTables.module.scss';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { cellsConfig } from '../../../helpers';
import { StyledTableCell } from 'components/StyledComponents';

export default function TableWrapper({ tableName, children }) {
  return (
    <TableContainer component={Paper} className={clsx(styles.table, styles[tableName])}>
      <Table>
        <TableHead>
          <TableRow>
            {cellsConfig[tableName].map((cell) => (
              <StyledTableCell key={cell.id}>{cell.name}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
