import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormLabel } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';
import { useTeamMemberActions, useTeamMemberSelector } from 'hooks/TeamMember';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { doDriveId, doNotDriveId, driveStatuses, isEqual } from 'helpers/AppHelpers';

import clsx from 'clsx';

import { defaultFilter, DEPARTMENT_TOOLTIP_TEXT } from '../helpers';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();
  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, filterCriteria } = useTeamMemberSelector();
  const { getFilterCriteriaAction } = useTeamMemberActions();

  const {
    teamMemberDepartments,
    teamMembers,
    clearanceTypes,
    teamMemberLicenses,
    teamMemberCapacities,
    teamMemberSkillsets,
    readOnlyTeamMemberDepartments
  } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const handleClosePopup = () => {
    closePopup();

    if (
      !isEqual(filter.filters?.teamMemberDepartmentIds || [], getValues('teamMemberDepartmentIds'))
    ) {
      getFilterCriteriaAction({ departmentIds: filter.filters?.teamMemberDepartmentIds || [] });
    }
  };

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const isDepartmentFieldFilled = !!getValues('teamMemberDepartmentIds')?.length;

    const defaultState = {
      ...defaultFilter.filters,
      searchQuery: getValues('searchQuery')
    };
    reset(defaultState);

    isDepartmentFieldFilled && getFilterCriteriaAction();
  };

  const handleDriveStatusSelect = (_, value = []) => {
    setValue('doDrive', value.includes(doDriveId));
    setValue('doNotDrive', value.includes(doNotDriveId));
  };

  const handleDepartmentSelect = (_, value = []) => {
    getFilterCriteriaAction({ departmentIds: value }).then(() => {
      const resetState = {
        ...getValues(),
        teamMemberDepartmentIds: value,
        teamMemberIds: [],
        clearanceTypeIds: [],
        teamMemberLicenseIds: [],
        teamMemberCapacityIds: [],
        teamMemberSkillsetIds: []
      };
      reset(resetState);
    });
  };

  return (
    <DialogWrapper open={open} onClose={handleClosePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={handleClosePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <div className={styles.form__block_cell_label}>
                  <FormLabel>Department</FormLabel>
                  <InfoTooltip text={DEPARTMENT_TOOLTIP_TEXT} />
                </div>
                <FormAutoMultiSelect
                  name="teamMemberDepartmentIds"
                  menuItems={teamMemberDepartments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleDepartmentSelect
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Team member</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberIds"
                  menuItems={teamMembers || []}
                  options={{
                    labelType: 'teamMember',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Clearance</FormLabel>
                <FormAutoMultiSelect
                  name="clearanceTypeIds"
                  menuItems={clearanceTypes || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Trainings & Certifications</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberLicenseIds"
                  menuItems={teamMemberLicenses || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Drive Status</FormLabel>
                <FormAutoMultiSelect
                  name="driveStatusIds"
                  menuItems={driveStatuses || []}
                  options={{
                    label: 'displayName',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleDriveStatusSelect
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Capacity</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberCapacityIds"
                  menuItems={teamMemberCapacities || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Skillset</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberSkillsetIds"
                  menuItems={teamMemberSkillsets || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Read-only Schedules</FormLabel>
                <FormAutoMultiSelect
                  name="readOnlyTeamMemberDepartmentIds"
                  menuItems={readOnlyTeamMemberDepartments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              {(isAdminUser || isPersonnelManagerUser) && (
                <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                  <FormLabel>Show removed Team Members</FormLabel>
                  <FormCheckbox name="showDeleted" />
                </div>
              )}
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={handleClosePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
