import React from 'react';
import styles from './SignatureDetails.module.scss';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Signature from 'components/Signature/Signature/Signature';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import TitleSection from '../CustomDetails/TitleSection';

export default function SignatureForm({ index, onRemove, onSave, onBlur, onFocus }) {
  const removeSignature = () => onRemove(index);

  return (
    <div className={styles.form}>
      <div className={styles.form__header}>
        <TitleSection>Signature</TitleSection>
        <div className={styles.form__header_delete}>
          <IconButton onClick={removeSignature}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <div className={styles.form__body}>
        <div className={styles.form__body_input}>
          <label>Name</label>
          <FormInputText
            name={`signatures[${index}].name`}
            options={{ max: 500, onBlur, onFocus }}
          />
        </div>
        <div className={styles.form__body_input}>
          <label>Position / Status</label>
          <FormInputText
            name={`signatures[${index}].position`}
            options={{ max: 500, onBlur, onFocus }}
          />
        </div>
        <div className={styles.form__body_input}>
          <label>Additional Info</label>
          <FormInputText
            name={`signatures[${index}].additionalInfo`}
            options={{ max: 500, onBlur, onFocus }}
          />
        </div>
        <div className={styles.form__body_input}>
          <Signature name={`signatures[${index}].resource`} onApplyTriggered={onSave} />
        </div>
      </div>
    </div>
  );
}
