import React, { useEffect, useState } from 'react';
import styles from './ImportCsvPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import { StyledButton, TransparentButton } from 'components/Buttons';
import { FormLabel } from 'components/FormComponents';

import { FormProvider, useForm } from 'react-hook-form';

import { fileDownload } from 'helpers/AppHelpers';

import downloadIcon from 'assets/images/downloadIcon.svg';

export function ImportCsvPopup({ open, setOpen, getTemplate, onApply }) {
  const methods = useForm({ defaultValues: { resources: [] }, mode: 'onChange' });
  const { watch, getValues } = methods;

  const [template, setTemplate] = useState({});

  useEffect(() => {
    if (getTemplate) {
      getTemplate().then((res) => setTemplate(res?.data));
    }
  }, []);

  const closePopup = () => setOpen(false);
  const handleApply = () => {
    closePopup();
    onApply && onApply(getValues('resources')[0]);
  };

  const downloadTemplate = () => {
    if (!template?.id) return;

    fileDownload(template);
  };

  const fileWatcher = watch('resources');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader title="Import BOL Log" onClose={closePopup} />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.content__field}>
              <FormLabel bold>Import CSV file</FormLabel>
              <FormFilesUpload csvOnly max={1} placeholder="Upload File" />
            </div>
          </FormProvider>
          <div className={styles.content__template}>
            <FormLabel bold>File example</FormLabel>
            {template?.id && (
              <TransparentButton
                label={template.name}
                onClick={downloadTemplate}
                className={styles.downloadButton}>
                <img src={downloadIcon} alt="bol-csv-template" />
              </TransparentButton>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <StyledButton label="Cancel" color="gray" onClick={closePopup} />
          <StyledButton
            label="Import BoL Log"
            onClick={handleApply}
            disabled={!fileWatcher?.length}
          />
        </div>
      </div>
    </DialogWrapper>
  );
}
