import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import {
  UPLOAD_BOL_LOGS,
  UPLOAD_BOL_LOGS_TYPE,
  EXIT_WITHOUT_SAVING
} from 'constants/dialogPopupsData';
import { BILL_OF_LADING_LOGS_PATH } from 'constants/routeConstants';

import { getErrorsProperties } from 'helpers/ErrorValidator';

import Header from './Header';
import Footer from './Footer';
import { useBillOfLadingLogsActions } from 'hooks/BillOfLadingLogs';

export function Wrapper({ children }) {
  const history = useHistory();

  const { formState, getValues, setError } = useFormContext();
  const { isDirty } = formState;

  const { setUnsavedFormDataAction } = useCommonActions();
  const { unsavedFormData } = useCommonSelector();
  const { uploadLogsAction } = useBillOfLadingLogsActions();

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const goToLogs = () => history.push(BILL_OF_LADING_LOGS_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      goToLogs();
    }
  };

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: 'type',
        errorMessage: 'Field is required',
        isInvalid: !getValues('type')?.id
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    setModalData(UPLOAD_BOL_LOGS);
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    if (modalData?.type === UPLOAD_BOL_LOGS_TYPE) {
      uploadLogsAction(getValues());
      return;
    }
    goToLogs();
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <Header onBack={handleBackClick} setModalData={setModalData} isDirty={isDirty} />
      {children}
      <Footer onCancel={handleBackClick} onAdd={handleCreateClick} />
    </div>
  );
}
