import React, { useState } from 'react';
import styles from './form.module.scss';

import MultiSelectWithCreation from 'components/MultiSelectWithCreation/MultiSelectWithCreation';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import ParameterEditPopup from 'components/ParameterPopups/ParameterEditPopup/ParameterEditPopup';
import DialogPopup from 'components/DialogPopup/DialogPopup';

import SkillOption from './SkillOption';
import LicenseChip from './LicenseChip';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useTeamMemberActions, useTeamMemberSelector } from 'hooks/TeamMember';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import { DELETE_TEAM_MEMBER_PARAMETER } from 'constants/dialogPopupsData';

export default function ProfessionalDetails() {
  const { id } = useParams();

  const { control, setValue, getValues, clearErrors } = useFormContext();

  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({});

  const { deleteParameterAction, updateParameterAction, getCreationFormAction } =
    useTeamMemberActions();
  const { creationFormData, currentTeamMember } = useTeamMemberSelector();
  const { departments, skillsets, licenses, readOnlyDepartments, statuses, capacities } =
    creationFormData;
  const { hideReadOnlyProjectScheduleField } = currentTeamMember;

  const changeParameter = (action, parameter, fieldName) => {
    getCreationFormAction();
    const isParameterSelected = !!getValues(fieldName)?.find(({ id }) => id === parameter.id)?.id;
    if (!isParameterSelected) return;

    const newParamValues = getValues(fieldName).filter(({ id }) => id !== parameter.id);
    if (action === 'UPDATE') {
      newParamValues.push(parameter);
    }
    setValue(fieldName, newParamValues);
  };

  const handleSaveParameter = (data, fieldName, queryPrefix) => {
    updateParameterAction(data, queryPrefix).then((res) => {
      if (res.status === 200) {
        changeParameter('UPDATE', res.data, fieldName);
        setOpen(false);
      }
    });
  };
  const handleDeleteParameter = (data, fieldName, queryPrefix) => {
    deleteParameterAction(data, queryPrefix).then((res) => {
      if (res.status === 200) {
        changeParameter('REMOVE', { id: data.id }, fieldName);
        setOpen(false);
      }
      if (res.status === 400 && queryPrefix !== 'Status') {
        const errorMessage = res?.errors?.Id[0] || '';
        if (errorMessage.indexOf('This parameter is used') >= 0) {
          setDialogModalData({
            ...DELETE_TEAM_MEMBER_PARAMETER,
            isOpened: true,
            options: { data, fieldName, queryPrefix }
          });
        }
      }
    });
  };

  const handleEditOption = (option, fieldName, title, queryPrefix) => {
    setData({
      formData: option,
      options: {
        title,
        field: { maxLength: 60, errorMessage: 'Name field is required' },
        buttons: {
          onUpdate: (values) => handleSaveParameter(values, fieldName, queryPrefix),
          onDelete: (values) => handleDeleteParameter(values, fieldName, queryPrefix),
          isDeleteAvailable: true
        }
      }
    });
    setOpen(true);
  };

  const handleRadioSelect = (name, option, isInvalid) => {
    isInvalid && clearErrors(name);
    setValue(name, [option], { shouldDirty: true });
  };

  const agreeModal = () => {
    const { data, fieldName, queryPrefix } = dialogModalData.options;
    handleDeleteParameter({ ...data, allowDelete: true }, fieldName, queryPrefix);
    closeModal();
  };
  const closeModal = () => setDialogModalData({});

  const handleAddOption = (name, value, isInvalid) => {
    isInvalid && clearErrors(name);
    setValue(name, [value], { shouldDirty: true });
  };

  const isProjectsFieldVisible = id ? !hideReadOnlyProjectScheduleField : true;

  const checkSelectAllReadOnlyDepartments = (_, value) => {
    setValue('isSelectedAllReadOnlyDepartments', value?.length === readOnlyDepartments?.length);
  };

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      {open && (
        <ParameterEditPopup
          open={open}
          setOpen={setOpen}
          data={data.formData}
          options={data.options}
        />
      )}

      <h2>Professional Details</h2>
      <div className={styles.professional}>
        <div className={styles.professional__cell}>
          <label>Department*</label>
          <Controller
            name="departments"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <MultiSelectWithCreation
                name="departments"
                options={departments || []}
                values={value}
                optionLabel="name"
                onChange={(name, arr) => onChange(arr)}
                maxInputLength={60}
                isCreationAvailable
                isInvalid={!!error}
                invalidError={error?.message}
                OptionComponent={(option, setSelectOpen, isSelected, onSelect) => (
                  <SkillOption
                    key={option.id}
                    option={option}
                    setSelectOpen={setSelectOpen}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    onOptionClick={(option) =>
                      handleEditOption(option, 'departments', 'Change Department', 'Department')
                    }
                    multiple
                  />
                )}
              />
            )}
          />
        </div>
        <FormChipsContainer fieldName="departments" />
        <div className={styles.professional__cell}>
          <label>Employment*</label>
          <Controller
            name="status"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <MultiSelectWithCreation
                name="status"
                options={statuses || []}
                values={value}
                optionLabel="name"
                onChange={(name, arr) => onChange(arr)}
                maxInputLength={60}
                isCreationAvailable
                isInvalid={!!error}
                invalidError={error?.message}
                onAdd={(value) => handleAddOption('status', value, !!error)}
                OptionComponent={(option, setSelectOpen, isSelected) => (
                  <SkillOption
                    key={option.id}
                    option={option}
                    setSelectOpen={setSelectOpen}
                    isSelected={isSelected}
                    onSelect={(o) => handleRadioSelect('status', o, !!error)}
                    onOptionClick={(option) =>
                      handleEditOption(option, 'status', 'Change Employment', 'Status')
                    }
                  />
                )}
                InputLabelComponent={(isInvalid, error) => (
                  <>
                    {!isInvalid ? (
                      <span>Select from the list or write to add a new field</span>
                    ) : (
                      error
                    )}
                    <div className={styles.inputs__tip}>
                      <ErrorOutlineIcon />
                      <span>one field is possible</span>
                    </div>
                  </>
                )}
              />
            )}
          />
        </div>
        <FormChipsContainer fieldName="status" />
        <div className={styles.professional__cell}>
          <label>Capacity*</label>
          <Controller
            name="capacity"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <MultiSelectWithCreation
                name="capacity"
                options={capacities || []}
                values={value}
                optionLabel="name"
                onChange={(name, arr) => onChange(arr)}
                maxInputLength={60}
                isCreationAvailable
                isInvalid={!!error}
                invalidError={error?.message}
                onAdd={(value) => handleAddOption('capacity', value, !!error)}
                OptionComponent={(option, setSelectOpen, isSelected) => (
                  <SkillOption
                    key={option.id}
                    option={option}
                    setSelectOpen={setSelectOpen}
                    isSelected={isSelected}
                    onSelect={(o) => handleRadioSelect('capacity', o, !!error)}
                    onOptionClick={(option) =>
                      handleEditOption(option, 'capacity', 'Change Capacity', 'Capacity')
                    }
                  />
                )}
                InputLabelComponent={(isInvalid, error) => (
                  <>
                    {!isInvalid ? (
                      <span>Select from the list or write to add a new field</span>
                    ) : (
                      error
                    )}
                    <div className={styles.inputs__tip}>
                      <ErrorOutlineIcon />
                      <span>one field is possible</span>
                    </div>
                  </>
                )}
              />
            )}
          />
        </div>
        <FormChipsContainer fieldName="capacity" />
        <div className={styles.professional__cell}>
          <label>Skillset</label>
          <Controller
            name="skillsets"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiSelectWithCreation
                name="skillsets"
                options={skillsets || []}
                values={value}
                optionLabel="name"
                onChange={(name, arr) => onChange(arr)}
                maxInputLength={60}
                isCreationAvailable
                OptionComponent={(option, setSelectOpen, isSelected, onSelect) => (
                  <SkillOption
                    key={option.id}
                    option={option}
                    setSelectOpen={setSelectOpen}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    onOptionClick={(option) =>
                      handleEditOption(option, 'skillsets', 'Change Skillset', 'Skillset')
                    }
                    multiple
                  />
                )}
              />
            )}
          />
        </div>
        <FormChipsContainer fieldName="skillsets" />
        <div className={styles.professional__cell}>
          <label>Trainings & Certifications</label>
          <Controller
            name="licenses"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiSelectWithCreation
                name="licenses"
                options={licenses || []}
                values={value}
                optionLabel="name"
                onChange={(name, arr) => onChange(arr)}
                maxInputLength={60}
                isCreationAvailable
                OptionComponent={(option, setSelectOpen, isSelected, onSelect) => (
                  <SkillOption
                    option={option}
                    setSelectOpen={setSelectOpen}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    isOptionEditable={(option) => !!option?.editable}
                    onOptionClick={(option) =>
                      handleEditOption(
                        option,
                        'licenses',
                        'Change Trainings & Certifications',
                        'License'
                      )
                    }
                    multiple
                  />
                )}
              />
            )}
          />
        </div>
        <FormChipsContainer
          fieldName="licenses"
          ChipComponent={(chipId, chip, index) => (
            <LicenseChip key={chipId} Chip={chip} dateName={`licenses[${index}].expirationDate`} />
          )}
          direction="column"
        />
        {isProjectsFieldVisible && (
          <>
            <div className={clsx(styles.professional__cell, styles.project)}>
              <label>Read-only Project Schedules</label>
              <FormAutoMultiSelect
                name="readOnlyDepartments"
                menuItems={readOnlyDepartments || []}
                options={{
                  label: 'name',
                  selectAll: true,
                  inputLabel: 'If no department is listed, team members see their schedule only.',
                  hideTags: true,
                  extraAction: checkSelectAllReadOnlyDepartments
                }}
                classes={styles.inputs__project}
              />
            </div>
            <FormChipsContainer fieldName="readOnlyDepartments" deleteParameter="id" />
          </>
        )}
      </div>
    </>
  );
}
