import React, { useEffect } from 'react';

import PreviewWrapper from './components/Wrapper';
import PreviewForm from './components/Form';

import { FormProvider, useForm } from 'react-hook-form';
import {
  useCreateAssetsBatchActions,
  useCreateAssetsBatchSelector
} from 'hooks/CreateBatchOfAssets';

import { modifyPreviewAssets } from './helpers';

export default function BatchOfAssetsPreview() {
  const { previewAssets } = useCreateAssetsBatchSelector();
  const { setUnsavedFormDataAction, toggleScreenAction } = useCreateAssetsBatchActions();

  const methods = useForm({
    defaultValues: { assets: modifyPreviewAssets(previewAssets) },
    mode: 'onChange'
  });

  useEffect(() => {
    setUnsavedFormDataAction(true);
  }, []);

  useEffect(() => {
    if (!previewAssets?.length) {
      toggleScreenAction();
    }
  }, [previewAssets]);

  useEffect(() => {
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <PreviewWrapper>
        <PreviewForm />
      </PreviewWrapper>
    </FormProvider>
  );
}
