import React from 'react';
import styles from './TeamMemberModals.module.scss';

import { getUserFullName } from 'helpers/AppHelpers';

export function CheckClearanceContent({ teamMembers, displayList }) {
  const list = teamMembers.length ? teamMembers.map(getUserFullName).join(', ') : '';

  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        {`Team member${displayList ? 's' : ''} doesn't have an active Clearance.`}
      </div>

      {displayList && list && (
        <div className={styles.modal__content}>
          <div className={styles.modal__content_label}>
            <b>Team member(s) without clearance:</b> {list}.
          </div>
        </div>
      )}

      <div className={styles.modal__footer}>Would you like to proceed?</div>
    </div>
  );
}
