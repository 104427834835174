import React from 'react';
import styles from './AvailabilityCharts.module.scss';

import SimplePieChart from 'components/Charts/SimplePieChart/SimplePieChart';

import { AVAILABILITY_PIE_LABELS, getPieFileName } from '../../../../helpers';

export default function AvailabilityCharts({ dashboard }) {
  if (
    !('total' in dashboard) ||
    (dashboard?.total?.duration1Value === 0 && dashboard?.total?.duration2Value === 0)
  )
    return null;

  const { totalUnavailable, available, availableNotSubmitted } = dashboard;

  const getPieTitle = (index) => dashboard[`duration${index}ColumnName`];
  const getPieSeries = (index) => [
    totalUnavailable[`duration${index}Value`],
    available[`duration${index}Value`],
    availableNotSubmitted[`duration${index}Value`]
  ];

  return (
    <div className={styles.charts}>
      <div className={styles.charts__item}>
        <SimplePieChart
          title={getPieTitle(1)}
          series={getPieSeries(1)}
          labels={AVAILABILITY_PIE_LABELS}
          fileName={getPieFileName(dashboard['duration1ColumnName'])}
        />
      </div>
      <div className={styles.charts__item}>
        <SimplePieChart
          title={getPieTitle(2)}
          series={getPieSeries(2)}
          labels={AVAILABILITY_PIE_LABELS}
          fileName={getPieFileName(dashboard['duration2ColumnName'])}
        />
      </div>
    </div>
  );
}
