import React, { useEffect } from 'react';
import styles from './Form.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

import SubcategoryBlock from './SubcategoryBlock';

import { usePrefixSelector } from 'hooks/Prefix';
import { useFormContext } from 'react-hook-form';

export default function GeneralDetails() {
  const { setValue, clearErrors, formState } = useFormContext();
  const { errors } = formState;
  const { prefixTypes, categoryList, activeCategory } = usePrefixSelector();

  useEffect(() => {
    if (activeCategory?.id) {
      setValue('assetCategory', activeCategory);
    }
  }, [activeCategory]);

  useEffect(()=>{

  },[])

  const selectType = ({ name, value }) => {
    setValue(name, value, { shouldDirty: true });
    errors?.[name] && clearErrors(name);
  };
  const selectCategory = (name, value) => {
    setValue(name, value);
    setValue('assetSubcategories', [{}]);
  };

  return (
    <>
      <h2>General Details</h2>
      <div className={styles.general}>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>Name*</label>
            <FormInputText name="name" options={{ max: 50, rule: /[^A-Za-z0-9 ]/g }} />
          </div>
          <div className={styles.general__block_row}>
            <label>Type*</label>
            <FormSelectInput name="prefixType" options={prefixTypes || []} onSelect={selectType} />
          </div>
          <div className={styles.general__block_row}>
            <label>Prefix*</label>
            <FormInputText name="prefix" options={{ max: 25, rule: /[^A-Za-z0-9 ]/g }} />
          </div>
          <div className={styles.general__block_row}>
            <label>Category*</label>
            <FormSearchInput
              name="assetCategory"
              options={categoryList || []}
              onSelect={selectCategory}
            />
          </div>
          <div className={styles.general__block_checkbox}>
            <label>Active?</label>
            <FormCheckbox name="active" />
          </div>
          <SubcategoryBlock />
        </div>
      </div>
      <h2>Description*</h2>
      <div className={styles.description}>
        <FormTextArea name="description" options={{ max: 255 }} />
      </div>
    </>
  );
}
