import React from 'react';
import styles from './templateFields.module.scss';

export default function Title({ isRequired, children }) {
  return (
    <h1 className={styles.title}>
      {children}
      {isRequired && '*'}
    </h1>
  );
}
