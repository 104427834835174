import React, { useEffect, useState } from 'react';
import styles from './SearchTextFieldRefactored.module.scss';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { makeStyles } from '@material-ui/core/styles';
import { reformatInputValue } from 'helpers/AppHelpers';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid #999',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)'
      }
    }
  }
}));

export default function SearchTextFieldRefactored({
  inputValue,
  validationRule,
  updateSearchParam,
  rightSpace
}) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(inputValue);
  }, [inputValue]);

  const handleInput = (event) => {
    const value = reformatInputValue(event.target.value, 100, validationRule);
    setSearchValue(value);
    if (event.key === 'Enter' || (searchValue.length && !value)) {
      updateSearchParam(value, 'input');
    }
  };

  const handleClear = () => {
    setSearchValue('');
    updateSearchParam('', 'clear');
  };

  return (
    <div className={clsx(styles.searchPanel, rightSpace && styles.rightSpace)}>
      <TextField
        className={clsx(styles.searchInput, classes.searchBarStyle)}
        type="text"
        placeholder="Search"
        variant="outlined"
        onChange={handleInput}
        onKeyDown={handleInput}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <IconButton aria-label="toggle password visibility" onClick={handleClear}>
              <CancelRoundedIcon fontSize="small" className={styles.clearIcon} />
            </IconButton>
          )
        }}
      />
    </div>
  );
}
