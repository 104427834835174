import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AddLooseItemsPopup.module.scss';

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';

import PopupHeader from './components/PopupHeader';
import PopupActions from './components/PopupActions';
import PopupTableRow from './components/PopupTableRow';
import Comment from './components/Comment';
import AddRowButton from './components/AddRowButton';
import { tableHeadConfig } from './components/tableHeadConfig';
import {
  addLooseItemsToPicklist,
  checkPicklistItemsLocation,
  clearAddLooseItemsPopupState,
  editLooseItemOfPicklist,
  getLooseItemLocations,
  getShortedLooseItems,
  getShortedPicklists
} from 'actions/addLooseItemsPopupActions';
import { selectListOfPicklistData, selectLocationsData, selectLooseItemsData } from './selectors';

import { enqueueErrorSnackbar, getRandomId, pluck } from 'helpers/AppHelpers';
import {
  checkTableRow,
  getFirstAvailableLocation,
  getRowLocations,
  getRowLooseItems
} from './helpers';
import { checkBuyAheadAccount, checkMoveQuantity, checkRowForWarnings } from './validation';

import {
  ADD_ITEMS_TO_PICKLIST,
  ADD_ITEMS_TO_PICKLIST_TYPE,
  EDIT_ITEM_OF_PICKLIST_TYPE,
  EDIT_ITEMS_OF_PICKLIST,
  EXIT_WITHOUT_SAVING,
  PICKLIST_ITEMS_LOCATION_CHECK,
  PICKLIST_ITEMS_LOCATION_CHECK_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import InputSearchWithMultiselect from '../InputSearchWithMultiselect/InputSearchWithMultiselect';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import MatchingLocation from 'components/ModalContents/MatchingLocation';
import { WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY } from 'constants/infoSnackbarData';

function AddLooseItemsPopup({ isMobile, open, setOpen, data, callback }) {
  const dispatch = useDispatch();
  const isFirstWarning = useRef(true);
  const wasTableChanged = useRef(false);

  const locations = useSelector(selectLocationsData());
  const looseItems = useSelector(selectLooseItemsData());
  const listOfPicklist = useSelector(selectListOfPicklistData());

  const [tableState, setTableState] = useState([{ rowId: getRandomId() }]);
  const [dialogModalData, setDialogModalData] = useState({ isOpened: false });

  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});

  const [picklist, setPicklist] = useState({});
  const [isPicklistValid, setIsPicklistValid] = useState(true);

  const { mode, isEditing, disableEditingAndAdding, editableItemId, importantId } = data;

  const query = data?.id ? { filters: { picklistId: data.id } } : null;
  if (isEditing) {
    query.filters.locationLooseItemIds = [editableItemId];
    query.filters.picklistAssetId = importantId;
  }

  const title = `${isEditing ? 'Edit' : 'Add'} loose items ${isEditing ? 'of' : 'to'} ${mode}`;

  useEffect(() => {
    if (!isEditing) return;
    if (open && locations.length) {
      prepareTableForEditing({
        name: 'location',
        value: locations.find(({ id }) => id === editableItemId),
        index: 0,
        moveQuantity: data.moveQuantity,
        moveByAheadAccount: data.moveByAheadAccount
      });
    }
  }, [open, locations]);

  useEffect(() => {
    if (data?.id) return;
    if (open && locations?.length) {
      const values = locations.filter(({ id }) => data.selectedItems.includes(id));

      const state = [];

      values.forEach((el) => {
        const row = { rowId: getRandomId(), locationLooseItem: el };
        state.push(row);
      });

      setTableState(state);
    }
  }, [open, locations]);

  useEffect(() => {
    !isEditing && dispatch(getShortedLooseItems(query));
  }, []);

  useEffect(() => {
    dispatch(getLooseItemLocations(query));
  }, []);

  useEffect(() => {
    !data?.id && dispatch(getShortedPicklists());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearAddLooseItemsPopupState());
    };
  }, []);

  const validateTable = () => {
    let validations = { ...errors };

    tableState.forEach((el) => {
      validations = { ...validations, [el.rowId]: checkTableRow(el) };
    });
    setErrors(validations);

    let isValid = true;
    for (const key in validations) {
      if (Object.values(validations[key]).filter(Boolean).length) isValid = false;
    }

    if (!data?.id) {
      if (!picklist?.id) {
        setIsPicklistValid(false);
        isValid = false;
      }
    }

    return isValid;
  };

  const validateRow = (rowId) => {
    let validations = { ...errors };
    validations = { ...validations, [rowId]: {} };
    setErrors(validations);
  };

  const removeWarning = (name, rowId) => {
    if (name !== 'moveQuantity') return;
    if (!warnings[rowId]) return;

    isFirstWarning.current = true;
    let warns = { ...warnings };
    warns = { ...warns, [rowId]: '' };
    setWarnings(warns);
  };

  const validateSingleCell = (name, index, value) => {
    const key = tableState[index].rowId;
    const checkErrors = { ...errors };

    switch (name) {
      case 'moveQuantity': {
        const qty = tableState[index].locationLooseItem.quantity;
        const byAheadAccount = tableState[index].locationLooseItem.byAheadAccount;
        const moveByAheadAccount = tableState[index].moveByAheadAccount;

        checkErrors[key] = {
          ...checkErrors[key],
          [name]: checkMoveQuantity(qty, value),
          moveByAheadAccount: checkBuyAheadAccount(byAheadAccount, moveByAheadAccount, value)
        };
        break;
      }
      case 'moveByAheadAccount': {
        const moveQuantity = tableState[index].moveQuantity;
        const byAheadAccount = tableState[index].locationLooseItem?.byAheadAccount || 0;

        checkErrors[key] = {
          ...checkErrors[key],
          [name]: checkBuyAheadAccount(byAheadAccount, value, moveQuantity)
        };
        break;
      }
    }

    setErrors(checkErrors);
  };

  const areWarningsExist = () => {
    let warns = { ...warnings };
    tableState.forEach((el) => {
      warns = { ...warns, [el.rowId]: checkRowForWarnings(el) };
    });
    setWarnings(warns);
    let isWarning = false;
    for (const key in warns) {
      if (Object.values(warns[key]).filter(Boolean).length) isWarning = true;
    }
    return isWarning;
  };

  const isErrorExist = (rowId) => !!Object.keys(rowId in errors ? errors[rowId] : {})?.length;

  const handleRowChange = (data) => {
    const { index, name, value } = data;
    let state = [...tableState];

    switch (name) {
      case 'looseItem':
        state[index] = {
          rowId: state[index].rowId,
          locationLooseItem: getAvailableLocation(value)
        };
        isErrorExist(state[index].rowId) && validateRow(state[index].rowId);
        break;
      case 'location':
        state[index] = { rowId: state[index].rowId, locationLooseItem: { ...value } };
        isErrorExist(state[index].rowId) && validateRow(state[index].rowId);
        break;
      case 'add':
        state.push({ rowId: getRandomId() });
        break;
      case 'remove':
        isErrorExist(state[index].rowId) && validateRow(state[index].rowId);
        state = state.filter((_, i) => i !== index);
        break;
      default:
        removeWarning(name, state[index].rowId);
        validateSingleCell(name, index, +value);
        state[index][name] = +value;
        break;
    }
    setTableState(state);
    !wasTableChanged.current && (wasTableChanged.current = true);
  };

  const prepareTableForEditing = (data) => {
    const { index, value, moveQuantity, moveByAheadAccount } = data;
    const state = [...tableState];
    state[index] = {
      rowId: state[index].rowId,
      locationLooseItem: { ...value },
      moveByAheadAccount,
      moveQuantity
    };
    setTableState(state);
  };

  const handleCancelClick = () => {
    if (wasTableChanged.current) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      setOpen(false);
    }
  };
  const handleAddClick = () => {
    if (!validateTable()) return;

    if (isFirstWarning.current) {
      if (areWarningsExist()) {
        isFirstWarning.current = false;
        enqueueErrorSnackbar(WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY);
        return;
      }
    }

    if (isEditing) {
      const modalTitle = `Do you want to edit items of the picklist ${data.name}?`;
      setDialogModalData({ isOpened: true, ...EDIT_ITEMS_OF_PICKLIST, title: modalTitle });
    } else {
      const modalTitle = `Do you want to add items to the picklist ${data.name || picklist.name}?`;
      setDialogModalData({ isOpened: true, ...ADD_ITEMS_TO_PICKLIST, title: modalTitle });
    }
  };

  const isAddButtonDisabled = !tableState[tableState.length - 1]?.locationLooseItem?.looseItem?.id;

  const getLocations = (index) => getRowLocations(tableState, index, locations);
  const getLooseItems = (index) => getRowLooseItems(tableState, index, looseItems, locations);
  const getAvailableLocation = (id) => getFirstAvailableLocation(id, tableState, locations);

  const isTableInvalid = () => {
    let isInvalid = false;
    for (const key in errors) {
      if (Object.values(errors[key]).filter(Boolean).length) isInvalid = true;
    }
    return isInvalid;
  };

  const addItemsToPicklist = (itemsToExclude = []) => {
    const excludedItemIds = pluck(itemsToExclude, 'id');
    const params = {
      createPicklistLooseItemDtos: tableState.filter(
        ({ locationLooseItem }) => !excludedItemIds.includes(locationLooseItem.id)
      ),
      picklist: data?.id ? data : picklist
    };

    if (!params?.createPicklistLooseItemDtos?.length) return;

    dispatch(addLooseItemsToPicklist(params)).then(onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    if (res.status === 200) {
      callback && callback();
      setOpen(false);
    }
  };
  const onFailure = (err) => console.log(err);
  const agreeModal = () => {
    switch (dialogModalData.type) {
      case ADD_ITEMS_TO_PICKLIST_TYPE: {
        const query = {
          picklistId: data.id || picklist.id,
          locationLooseItemIds: tableState.map(({ locationLooseItem }) => locationLooseItem.id)
        };

        dispatch(checkPicklistItemsLocation(query)).then((res) => {
          if (!res?.locationLooseItems?.length) {
            addItemsToPicklist();
          } else {
            setDialogModalData({
              ...PICKLIST_ITEMS_LOCATION_CHECK,
              itemsToExclude: res.locationLooseItems,
              content: (
                <MatchingLocation
                  items={res.locationLooseItems}
                  options={{
                    itemType: 'looseItem',
                    documentType: 'picklist',
                    endpointType: res['isPicklistWithSublocation'] ? 'sublocation' : 'location'
                  }}
                />
              )
            });
          }
        });
        break;
      }
      case PICKLIST_ITEMS_LOCATION_CHECK_TYPE:
        addItemsToPicklist();
        break;
      case EDIT_ITEM_OF_PICKLIST_TYPE: {
        const picklist = { id: data.id, name: data.name };
        const picklistAssetId = importantId;
        const params = { createPicklistLooseItemDto: tableState[0], picklist, picklistAssetId };
        dispatch(editLooseItemOfPicklist(params)).then(onSuccess, onFailure);
        break;
      }
      case WITHOUT_SAVING_TYPE:
        setDialogModalData({ isOpened: false });
        setOpen(false);
        break;
      default:
        break;
    }
  };
  const dismissModal = () => {
    if (dialogModalData.type === PICKLIST_ITEMS_LOCATION_CHECK_TYPE) {
      addItemsToPicklist(dialogModalData.itemsToExclude);
    }
    setDialogModalData({ isOpened: false });
  };

  const onSelect = (name, value) => {
    setPicklist(value);
    !isPicklistValid && setIsPicklistValid(true);
  };

  return (
    <DialogWrapper open={open} onClose={handleCancelClick}>
      <ConfirmationPopup data={dialogModalData} onAgree={agreeModal} onDismiss={dismissModal} />
      <section className={styles.wrapper}>
        <div className={styles.wrapper_container}>
          <PopupHeader isMobile={isMobile} title={title} onCancel={handleCancelClick} />

          {!data?.id && (
            <div className={styles.formRow}>
              <label>Picklist*</label>
              <div className={styles.inputWrapper}>
                <InputSearchWithMultiselect
                  name="picklist"
                  defaultValue={picklist?.id || ''}
                  options={listOfPicklist || []}
                  onFilterSelect={onSelect}
                  multiselect={false}
                  isInvalid={!isPicklistValid}
                />
                {!isPicklistValid && <span>Picklist field is required</span>}
              </div>
            </div>
          )}

          <TableContainer component={Paper} className={styles.wrapper_container__table}>
            <Table aria-label="customized table">
              <TableHeadComponent config={tableHeadConfig} />
              <TableBody>
                {tableState.map((row, index) => (
                  <PopupTableRow
                    key={row.rowId}
                    index={index}
                    data={row}
                    onRowChange={handleRowChange}
                    locations={getLocations(index)}
                    looseItems={getLooseItems(index)}
                    isRemoveAvailable={tableState?.length !== 1}
                    errors={errors[row.rowId]}
                    warnings={warnings[row.rowId]}
                    isEditing={disableEditingAndAdding}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Comment label="[1]Buy Ahead Account" />
          {!disableEditingAndAdding && (
            <AddRowButton onClick={handleRowChange} disabled={isAddButtonDisabled} />
          )}
        </div>
        <PopupActions
          onCancel={handleCancelClick}
          onCreate={handleAddClick}
          isCreateButtonDisabled={
            data?.id ? !!isTableInvalid() : !!isTableInvalid() || !isPicklistValid
          }
          isEditMode={isEditing}
        />
      </section>
    </DialogWrapper>
  );
}

export default AddLooseItemsPopup;
