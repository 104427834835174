import React from 'react';
import styles from './ProjectScheduleFilter.module.scss';

import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import { MainButton } from 'components/StyledComponents';
import { useFormContext } from 'react-hook-form';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';

import { defaultFilterState, getEmptyFilters } from '../../../helpers';
import { isEqual } from 'helpers/AppHelpers';

export default function ScheduleFilterFooter({ setOpen }) {
  const { reset, getValues } = useFormContext();

  const { filter, isMasterSelected } = useTrackerSelector();
  const {
    getTrackerDataAction,
    updateTrackerConfigDataAction,
    getFilterCriteriaAction,
    setTrackerTableDataAction,
    setFilterAction
  } = useTrackerActions();

  const clearFilter = () => {
    reset(defaultFilterState);
    getFilterCriteriaAction({ isMaster: isMasterSelected, departmentIds: [] });
  };

  const closeFilter = () => setOpen(false);

  const cancelFilter = () => {
    closeFilter();
    if (!isEqual(filter.filters.teamMemberDepartmentIds, getValues('teamMemberDepartmentIds'))) {
      getFilterCriteriaAction({
        isMaster: isMasterSelected,
        departmentIds: filter.filters.teamMemberDepartmentIds
      });
    }
  };

  const applyFilter = () => {
    closeFilter();
    const values = {
      ...filter,
      filters: { ...getValues(), columnLimit: 30, columnPage: 1 },
      pagination: { limit: filter.pagination.limit, page: 1 }
    };
    updateTrackerConfigDataAction(values.filters, { isMaster: isMasterSelected });

    const isFilterEmpty = getEmptyFilters(values);

    if (!isMasterSelected && isFilterEmpty) {
      setFilterAction(values);
      setTrackerTableDataAction({});
    } else {
      getTrackerDataAction(values, { isMasterSelected });
    }
  };

  return (
    <section className={styles.footer}>
      <ClearFilterButton onClick={clearFilter} />
      <div className={styles.footer__controls}>
        <MainButton text="Cancel" type="secondary" size="filter_popup" action={cancelFilter} />
        <MainButton text="Apply" type="primary" size="filter_popup" action={applyFilter} />
      </div>
    </section>
  );
}
