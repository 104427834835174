import { browserHistory } from 'browserHistory';
import api from '../api';
import {
  CLEAR_REPORT_TEMPLATES_STATE,
  SET_REPORT_TEMPLATES_TYPES,
  SET_REPORT_TEMPLATES_FILTER,
  SET_LOAD_MORE_FLAG,
  SET_ACTIVE_REPORT_TEMPLATE
} from 'constants/reduceConstants';
import { REPORT_TEMPLATE_EDITOR } from 'constants/routeConstants';
import { enqueueErrorSnackbar } from 'helpers/AppHelpers';

export const getTemplates = (query) => (dispatch) =>
  api.reportTemplates
    .getTemplatesApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getReportTypes = () => (dispatch) =>
  api.reportTemplates
    .getReportTypesApi()
    .then((res) => {
      dispatch({ type: SET_REPORT_TEMPLATES_TYPES, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const removeTemplate = (q) => () =>
  api.reportTemplates.removeTemplateApi(q).then(
    (res) => {
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

export const copyTemplate = (id) => (dispatch) => {
  api.reportTemplates
    .getTemplateApi(id)
    .then((res) => {
      const responseCopy = { ...res, name: '', published: false };
      delete responseCopy.id;
      delete responseCopy.publishedAtUtc;
      dispatch({ type: SET_ACTIVE_REPORT_TEMPLATE, payload: responseCopy });
      browserHistory.push(REPORT_TEMPLATE_EDITOR);
    })
    .catch((err) => console.log(err));
};

export const setFilter = (data) => (dispatch) =>
  dispatch({ type: SET_REPORT_TEMPLATES_FILTER, payload: data });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_REPORT_TEMPLATES_STATE });
