import React, { useEffect, useRef, useState } from 'react';
import styles from './TeamMemberHistory.module.scss';

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistoryActions, useHistorySelector } from 'hooks/TeamMemberHistory';

import { TEAM_MEMBER_HISTORY_TABLE_PARAMETER } from 'constants/configTableConstants';
import { TEAM_MEMBER_DETAILED_PATH } from 'constants/routeConstants';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { formatDate, getProjectName, removeCharacterZ } from 'helpers/AppHelpers';

import { getTableHeadConfig } from './tableConfig';

import clsx from 'clsx';

import TeamMemberHistoryFilter from './Filter/TeamMemberHistoryFilter';
import FullTimeTag from './FullTimeTag';

export default function TeamMemberHistory() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const { isConfigReceived, getTableLimit, isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const { filter, historyList } = useHistorySelector();
  const {
    getHistoryConfigAction,
    setHistoryFilterAction,
    getHistoryDataAction,
    getFilterCriteriaAction,
    clearStateAction
  } = useHistoryActions();

  const [tableState, setTableState] = useState([]);

  const { filters, sortRules, pagination, responseReceived } = filter;

  const generateInitialQuery = (config) => {
    const query = {
      ...filter,
      pagination: { ...pagination, limit: getTableLimit(TEAM_MEMBER_HISTORY_TABLE_PARAMETER) }
    };
    query.filters.teamMemberDepartmentIds = config.departmentIds || [];
    query.filters.projectIds = config.projectIds || [];
    return query;
  };

  const getHistoryTableData = (query, isAutoload) => {
    setHistoryFilterAction(query);

    getHistoryDataAction(query).then((tableData) => {
      setHistoryTableData(tableData, query, isAutoload);
    });
  };

  const setHistoryTableData = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setHistoryFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getHistoryConfigAction().then((config) => {
      if (!('projectIds' in config)) return;

      getFilterCriteriaAction({ departmentIds: config?.departmentIds || [] });
      getHistoryTableData(generateInitialQuery(config));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const updateSearchParam = (value) => getHistoryTableData(getChangedBySearchFilter(filter, value));
  const handleSortClick = (rowName) => getHistoryTableData(getChangedBySortFilter(filter, rowName));
  const handlePageSelect = (page, param) =>
    getHistoryTableData(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const handleLimitChange = () =>
    getHistoryTableData(
      getChangedByLimitFilter(filter, getTableLimit(TEAM_MEMBER_HISTORY_TABLE_PARAMETER))
    );
  const handleFilterApply = (values) =>
    getHistoryTableData(getChangedByApplyFilter(filter, values));

  const emptyResponse = () =>
    !historyList?.items?.length && pagination?.page === historyList?.pageNumber;

  const emptyFilters = () => {
    return (
      !historyList?.items?.length &&
      filters.searchQuery === '' &&
      !filters?.teamMemberCapacityIds?.length &&
      !filters?.timeOnProjectNames?.length &&
      !filters?.teamMemberDepartmentIds?.length &&
      !filters?.teamMemberIds?.length &&
      !filters?.teamMemberStatusIds?.length &&
      !filters?.projectIds?.length &&
      filters?.showAllHistory === false &&
      filters?.fromDayIn === null &&
      filters?.toDayIn === null &&
      filters?.fromDayOut === null &&
      filters?.toDayOut === null &&
      pagination?.page === historyList?.pageNumber
    );
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <SummaryHeader title="Team Member History">
          {!isMobile && (
            <DownloadCSVButton
              filter={filter}
              endpoint="TeamMemberHistory/Serialized"
              includePagination
              includeUserId
            />
          )}
        </SummaryHeader>
        <div className={styles.header__controls}>
          <TeamMemberHistoryFilter onApply={handleFilterApply} />
          <SearchTextFieldRefactored
            inputValue={filters.searchQuery}
            updateSearchParam={updateSearchParam}
            rightSpace={isMobile}
          />
        </div>
      </div>
      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={getTableHeadConfig(isMobile)}
            sortRules={sortRules}
            onSortApply={handleSortClick}
          />
          <TableBody>
            {tableState.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <div>
                      <LinkComponent
                        path={`${TEAM_MEMBER_DETAILED_PATH}/${row.teamMember.id}`}
                        name={row?.teamMember?.lastName + ', ' + row?.teamMember?.firstName}
                        boldText={true}
                        isRedirectAvailable={isAdminUser || isPersonnelManagerUser}
                      />
                      {row?.['isFullTime'] && <FullTimeTag />}
                    </div>
                  </StyledTableCell>
                  {isMobile && (
                    <>
                      <StyledTableCell
                        className={clsx(
                          row?.['timeOnProject']?.name &&
                            styles[row['timeOnProject'].name.toLowerCase()]
                        )}>
                        {row?.['daysOnRoad'] || 0}
                      </StyledTableCell>
                      <StyledTableCell
                        className={clsx(
                          row?.['timeOnProject']?.name &&
                            styles[row['timeOnProject'].name.toLowerCase()]
                        )}>
                        {row?.['weeksOnRoad'] || 0}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>{formatDate(removeCharacterZ(row?.['dateIn']))}</StyledTableCell>
                  <StyledTableCell>
                    {formatDate(removeCharacterZ(row?.['dateOut']))}
                  </StyledTableCell>
                  <StyledTableCell>{getProjectName(row?.project)}</StyledTableCell>
                  {!isMobile && (
                    <>
                      <StyledTableCell
                        className={clsx(
                          row?.['timeOnProject']?.name &&
                            styles[row['timeOnProject'].name.toLowerCase()]
                        )}>
                        {row?.['daysOnRoad'] || 0}
                      </StyledTableCell>
                      <StyledTableCell
                        className={clsx(
                          row?.['timeOnProject']?.name &&
                            styles[row['timeOnProject'].name.toLowerCase()]
                        )}>
                        {row?.['weeksOnRoad'] || 0}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>{row?.['timeSchedule'] || 0}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationRefactored
        isMobile={isMobile}
        rows={tableState?.length}
        currentPage={pagination.page}
        totalPages={pagination.totalPages}
        selectAllOption
        onPageSelect={handlePageSelect}
        pageLimit={pagination.limit}
        hide={emptyResponse()}
        tableListParameter={TEAM_MEMBER_HISTORY_TABLE_PARAMETER}
        getTableData={handleLimitChange}
        tableRef={tableRef}
      />
    </section>
  );
}
