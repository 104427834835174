import React from 'react';
import styles from '../Form.module.scss';

import { FormLabel } from 'components/FormComponents';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';

import clsx from 'clsx';

import SectionTitle from '../SectionTitle';

export function Files() {
  return (
    <div className={styles.general}>
      <SectionTitle>Files</SectionTitle>
      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={clsx(styles.form__block_row, styles.file)}>
            <FormLabel>Upload Photo</FormLabel>
            <FormImagesUpload />
          </div>
        </div>

        <div className={styles.form__block}>
          <div className={clsx(styles.form__block_row, styles.file)}>
            <FormLabel>Upload File</FormLabel>
            <FormFilesUpload />
          </div>
        </div>
      </div>
    </div>
  );
}
