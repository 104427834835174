import React from 'react';
import styles from './FormManager.module.scss';

import { BlueButton } from 'components/Buttons';
import Separator from 'components/Separator/Separator';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { LogForm } from './LogForm';

import { defaultForm } from '../helpers';

export function FormManager() {
  const { id } = useParams();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  const removeItem = (index) => remove(index);
  const addItem = () => append(defaultForm);

  return (
    <div className={styles.form_manager}>
      {!!fields?.length &&
        fields.map((form, index) => (
          <div key={form.id} className={styles.form_manager__item}>
            <LogForm index={index} />

            {fields.length > 1 && (
              <div className={styles.form_manager__item_delete}>
                <BlueButton label="Delete" onClick={() => removeItem(index)} fullOnMobile>
                  <DeleteIcon />
                </BlueButton>
              </div>
            )}
            {fields.length > 1 && <Separator />}
          </div>
        ))}

      {!id && fields?.length <= 20 && (
        <BlueButton label="Add bol log" onClick={addItem} fullOnMobile>
          <AddIcon />
        </BlueButton>
      )}
    </div>
  );
}
