import { allKeyboardLatSymbols } from 'helpers/AppHelpers';

export const locationFormConfig = [
  { id: 1, label: 'Company Name*', param: 'originCompanyName', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 2, label: 'Address*', param: 'originAddress', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 3, label: 'Address 2', param: 'originAddress2', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 4, label: 'City*', param: 'originCity', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 5, label: 'ZIP*', param: 'originZIP', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 6, label: 'State*', param: 'originState', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 7, label: 'Point of Contact', param: 'originPointOfContact', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 8, label: 'POC Phone', param: 'originPOCPhone', maxLength: 100, rule: allKeyboardLatSymbols }
];

export const destinationFormConfig = [
  { id: 1, label: 'Company Name*', param: 'destinationCompanyName', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 2, label: 'Address*', param: 'destinationAddress', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 3, label: 'Address 2', param: 'destinationAddress2', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 4, label: 'City*', param: 'destinationCity', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 5, label: 'ZIP*', param: 'destinationZIP', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 6, label: 'State*', param: 'destinationState', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 7, label: 'Point of Contact', param: 'destinationPointOfContact', maxLength: 100, rule: allKeyboardLatSymbols },
  { id: 8, label: 'POC Phone', param: 'destinationPOCPhone', maxLength: 100, rule: allKeyboardLatSymbols }
];

export const responsibleForDeliveryFormConfig = [
  {
    id: 1,
    name: { param: 'shippedBy', label: 'Shipped by', max: 100, rule: allKeyboardLatSymbols },
    date: { param: 'shippedDate', label: 'Date' },
    phone: { param: 'shippedPhoneNumber', label: 'Phone Number', max: 100, rule: allKeyboardLatSymbols }
  },
  {
    id: 2,
    name: { param: 'receivedBy', label: 'Received by', max: 100, rule: allKeyboardLatSymbols },
    date: { param: 'receivedDate', label: 'Date' },
    phone: { param: 'receivedPhoneNumber', label: 'Phone Number', max: 100, rule: allKeyboardLatSymbols }
  },
  {
    id: 3,
    name: { param: 'driverName', label: 'Driver Name', max: 100, rule: allKeyboardLatSymbols },
    date: { param: 'driverDate', label: 'Date', position: 'top' },
    phone: { param: 'driverPhoneNumber', label: 'Phone Number', max: 100, rule: allKeyboardLatSymbols }
  }
];

export const radioOptions = [
  { displayName: 'Dry Van', name: 'DryVan' },
  { displayName: 'Flat Bed', name: 'FlatBed' },
  { displayName: 'Step Deck', name: 'StepDeck' },
  { displayName: 'Power Only', name: 'PowerOnly' },
  { displayName: 'Hot Shot', name: 'HotShot' },
  { displayName: 'Other', name: 'Other' }
];

export const validationRules = [
  { name: 'dateOfShipment', inputType: 'date-required', errorMessage: 'This field is required' },
  { name: 'jobNumber', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'originCompanyName', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'originAddress', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'originCity', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'originZIP', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'originState', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'destinationCompanyName', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'destinationAddress', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'destinationCity', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'destinationZIP', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'destinationState', inputType: 'text', errorMessage: 'This field is required' }
];
export const tableValidationRules = [
  { name: 'description', inputType: 'text', errorMessage: 'This field is required' },
  { name: 'qty', inputType: 'quantity-required', errorMessage: 'This field is required' }
];

export const itemsForDeliveryTableHeadConfig = [
  { id: 1, label: 'Item', isSortable: false },
  { id: 2, label: 'Description*', isSortable: false },
  { id: 3, label: 'QTY*', isSortable: false },
  { id: 4, label: 'Weight lbs', isSortable: false },
  { id: 5, label: '', isSortable: false }
];
