import React from 'react';
import styles from './Header.module.scss';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssetSelector } from 'hooks/Asset';

import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

import AssetNavigationControls from './NavigationControls';

export default function AssetNavigation() {
  const isMobile = useMobileViewport();

  const { currentAsset } = useAssetSelector();
  const { drCode } = currentAsset;

  return (
    <div className={styles.navigation}>
      {!isMobile && <BreadcrumbsNav itemsArray={[{ name: drCode }]} />}
      <AssetNavigationControls />
    </div>
  );
}
