import React from 'react';
import styles from './templateFields.module.scss';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

export default function TextAreaField({ name, max, rule, label, isRequired, onBlur, onFocus }) {
  return (
    <section className={styles.textarea_field}>
      {label && (
        <h1 className={styles.textarea_title}>
          {label}
          {isRequired && '*'}
        </h1>
      )}
      <div className={styles.textarea_input}>
        <FormTextArea name={name} options={{ max, rule, onBlur, onFocus }} />
      </div>
    </section>
  );
}
