import { createSelector } from 'reselect';

const batchAssetUpdatesData = (state) => state.batchAssetUpdatesData;

export const selectBatchAssetUpdatesCreationFormData = () =>
  createSelector([batchAssetUpdatesData], (state) => state.creationForm);

export const selectBatchAssetUpdatesSelectedTab = () =>
  createSelector([batchAssetUpdatesData], (state) => state.selectedTab);

export const selectBatchAssetUpdatesFormState = () =>
  createSelector([batchAssetUpdatesData], (state) => state.formState);
