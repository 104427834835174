import {
  CLEAR_STATE_ADD_BOM,
  SET_ACTIVE_PICKLIST_ADD_BOM,
  SET_CONFIGURATION_DETAILS_ADD_BOM,
  SET_CONFIGURATION_LIST_ADD_BOM
} from 'constants/reduceConstants';

const initialState = {
  activePicklist: {},
  configurations: [],
  looseItems: []
};

const pickListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_PICKLIST_ADD_BOM:
      return { ...state, activePicklist: payload };
    case SET_CONFIGURATION_LIST_ADD_BOM:
      return { ...state, configurations: payload };
    case SET_CONFIGURATION_DETAILS_ADD_BOM:
      return { ...state, looseItems: payload?.['looseItems'] || [] };
    case CLEAR_STATE_ADD_BOM:
      return initialState;
    default:
      return state;
  }
};

export default pickListReducer;
