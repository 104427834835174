import React from 'react';
import styles from './Details.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';

import SectionTitle from '../SectionTitle';

import { useFormContext } from 'react-hook-form';
import { useCreateAssetsBatchSelector } from 'hooks/CreateBatchOfAssets';

import { getLicenseValue, modifyFields } from '../../../helpers';
import { enrichNameToId, yesterdayDate } from 'helpers/AppHelpers';

export default function GeneralDetails() {
  const { creationForm } = useCreateAssetsBatchSelector();

  const { grades, rentalStatuses, assetPrefixes, locations, projects } = creationForm;

  const { watch, setValue, clearErrors, formState, getValues } = useFormContext();
  const { errors } = formState;

  const checkError = (name) => {
    if (name === 'rental.rentalStatus') {
      errors?.rental?.['rentalStatus']?.message && clearErrors(name);
    } else if (name === 'lastLocationHistory.location') {
      errors?.lastLocationHistory?.['location']?.message && clearErrors(name);
    } else if (errors?.[name]?.message && clearErrors(name)) {
      clearErrors(name);
    }
  };

  const selectPrefix = (name, value) => {
    checkError(name);
    setValue(name, value, { shouldDirty: true });
    setValue('assetFields', modifyFields(value?.assetFields || []), { shouldDirty: true });
    setValue('assetLicense', getLicenseValue(value?.prefixType?.name, { shouldDirty: true }));
  };
  const selectLocation = (name, value) => {
    checkError(name);
    setValue('lastLocationHistory.sublocation', null, { shouldDirty: true });
    setValue(name, value, { shouldDirty: true });
  };

  const selectOption = ({ name, value }) => {
    checkError(name);
    setValue(name, value, { shouldDirty: true });
  };

  const selectProject = (name, value) => setValue(name, value, { shouldDirty: true });

  const locationWatcher = watch('lastLocationHistory.location');
  const rentalStatusWatcher = watch('rental.rentalStatus');

  return (
    <div className={styles.wrapper}>
      <SectionTitle>General Details for Batch of Assets</SectionTitle>
      <div className={styles.details}>
        <div className={styles.details__block}>
          <div className={styles.details__block_row}>
            <label>Prefix*</label>
            <FormSearchInput
              name="assetPrefix"
              options={assetPrefixes || []}
              onSelect={selectPrefix}
            />
          </div>
          <div className={styles.details__block_row}>
            <label>Short Note</label>
            <FormInputText name="shortNote" options={{ max: 250 }} />
          </div>
          <div className={styles.details__block_row}>
            <label>Grade</label>
            <FormSelectInput
              clearable
              name="grade"
              options={enrichNameToId(grades) || []}
              onSelect={selectOption}
            />
          </div>
          <div className={styles.details__block_row}>
            <label>Rental Status*</label>
            <FormSelectInput
              name="rental.rentalStatus"
              options={enrichNameToId(rentalStatuses) || []}
              onSelect={selectOption}
            />
          </div>
          {rentalStatusWatcher?.name === 'InTransit' && (
            <div className={styles.details__block_row}>
              <label>Destination</label>
              <FormSelectInput
                name="rental.destination"
                options={locations || []}
                onSelect={selectOption}
              />
            </div>
          )}
          {rentalStatusWatcher?.name === 'Reserved' && (
            <div className={styles.details__block_row}>
              <label>Project</label>
              <FormSearchInput
                name="rental.project"
                options={projects || []}
                onSelect={selectProject}
              />
            </div>
          )}
          {(rentalStatusWatcher?.name === 'HoldForHigh' ||
            rentalStatusWatcher?.name === 'HoldForLow') && (
            <>
              <div className={styles.details__block_row}>
                <label>Project Name</label>
                <FormSearchInput
                  name="rental.project"
                  options={projects || []}
                  onSelect={selectProject}
                />
              </div>
              <div className={styles.details__block_row}>
                <label>Duration</label>
                <FormDatePicker name="rental.endDate" min={yesterdayDate} />
              </div>
            </>
          )}
          <div className={styles.details__block_checkbox}>
            <label>Non-owned</label>
            <FormCheckbox name="isNonOwned" />
          </div>
          <div className={styles.details__block_row}>
            <div className={styles.details__block_label}>
              <label>QTY*</label>
              <InfoTooltip
                text="Set qty of creating assets, min qty is 1, max - 100"
                classes={styles.tooltip}
              />
            </div>
            <FormInputText name="qty" options={{ focus: true, type: 'quantity' }} />
          </div>
        </div>
        <div className={styles.details__block}>
          <div className={styles.details__block_row}>
            <label>Purchase Date</label>
            <FormDatePicker name="purchasedAtUtc" />
          </div>
          <div className={styles.details__block_checkbox}>
            <label>Labeled?</label>
            <FormCheckbox name="labeled" />
          </div>
          <div className={styles.details__block_row}>
            <label>Purchased From</label>
            <FormInputText name="purchasedFrom" options={{ max: 40 }} />
          </div>
          <div className={styles.details__block_row}>
            <label>Location*</label>
            <FormSearchInput
              name="lastLocationHistory.location"
              options={locations || []}
              onSelect={selectLocation}
            />
          </div>
          <div className={styles.details__block_row}>
            <label>Sublocation</label>
            <FormSelectInput
              clearable
              name="lastLocationHistory.sublocation"
              options={getValues('lastLocationHistory.location')?.sublocations || []}
              onSelect={selectOption}
              isDisabled={!locationWatcher?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
