import React from 'react';
import styles from './Chart.module.scss';

import LineChart from 'components/Charts/LineChart/LineChart';
import { dashboardLabels, colors } from 'pages/AssetTickets/Dashboard/helpers';

export default function Chart({ data }) {
  const { durations, severities, name } = data;

  const severityNames = severities.map(({ name }) => name);
  const severitiesWithCounts = durations.flatMap(({ severityWithCount }) => severityWithCount);

  const series = severities
    .map(({ displayName }) => displayName)
    .map((severityName) => ({
      name: severityName,
      data: severitiesWithCounts
        .filter(({ ticketSeverity }) => ticketSeverity.displayName === severityName)
        .map(({ count }) => count)
    }));

  const categories = durations.map(({ formattedDate }) => formattedDate);

  return (
    <div style={{ width: categories.length * 80 }} className={styles.chart}>
      <LineChart
        hideToolbar
        series={series}
        categories={categories}
        colors={colors
          .filter(({ ticketSeverityName }) => severityNames.includes(ticketSeverityName))
          .map(({ color }) => color)}
        fileName={dashboardLabels.flowStatistic + ' ' + name}
      />
    </div>
  );
}
