import React from 'react';
import styles from './Buttons.module.scss';

import TransparentButton from './TransparentButton';

import importCsvIcon from 'assets/images/importCsvIcon.svg';

export default function ImportCsvButton({ label, onClick }) {
  return (
    <TransparentButton label={label} className={styles.importButton} onClick={onClick}>
      <img src={importCsvIcon} alt="" />
    </TransparentButton>
  );
}
