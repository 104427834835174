import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getAssembleListApi = (query) =>
  api.get(`/AssembledItems?${queryToString(query)}`).then((res) => res.data);

export const getLocationsListApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getLocationsWithSublocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);

export const getStatusListApi = () =>
  api.get('/AssembledLooseItemStatuses').then((res) => res.data);

export const getConfigurationListApi = () =>
  api.get('/ShortedLooseItemConfigurations').then((res) => res.data);

export const getAssembledLooseItemsApi = (query) =>
  api.get(`/AssembledLooseItems?${queryToString(query)}`).then((res) => res.data);

export const getSingleAssembleApi = (id) =>
  api.get(`/AssembledItem?id=${id}`).then((res) => res.data);

export const createAssembleApi = (params) =>
  api.post('/AssembledItem', params).then((res) => res.data);

export const updateAssembleApi = (params) =>
  api.put('/AssembledItem', params).then((res) => res.data);

export const deleteAssembleApi = (id) =>
  api.delete(`/AssembledItem?id=${id}`).then((res) => res.data);
