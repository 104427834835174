import React from 'react';
import styles from './DetailsSection.module.scss';
import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

function DetailsSection({ requestData }) {
  const {
    urgencyLevel,
    endUser,
    endUserNumber,
    location,
    project,
    deliveryAddress,
    status,
    createdByUser,
    createdAtUtc,
    deadlineAtUtc,
    notes
  } = requestData;

  const data = [
    {
      id: 1,
      label: 'Urgency Level',
      value: urgencyLevel?.displayName || ''
    },
    {
      id: 2,
      label: 'Deadline',
      value: formatDate(deadlineAtUtc),
      isAvailable: !!deadlineAtUtc
    },
    { id: 3, label: 'End User', value: getUserFullName(endUser) },
    { id: 4, label: 'End User Email', value: endUser?.email || '' },
    { id: 5, label: 'Phone Number', value: endUserNumber || '' },
    { id: 6, label: 'Delivery Location', value: location?.siteCode || '' },
    { id: 7, label: 'Project Number', value: project || '' },
    { id: 8, label: 'Delivery Address', value: deliveryAddress || '' },
    { id: 9, label: 'Status', value: status?.displayName || '' },
    { id: 10, label: 'Created by', value: getUserFullName(createdByUser) },
    {
      id: 11,
      label: 'Created',
      value: createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : ''
    },
    { id: 12, label: 'Notes', value: notes || '' }
  ];

  return (
    <div className={styles.details}>
      {data.map(
        ({ id, label, value, isAvailable = true }) =>
          isAvailable && <DetailedRow key={id} label={label} value={value} disableFlex />
      )}
    </div>
  );
}

export default DetailsSection;
