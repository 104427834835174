import React, { useMemo } from 'react';
import styles from './AssetFields.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { TableWrapper } from 'components/SummaryComponents';
import { Indicator } from 'components/Counting';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { Table, TableBody, TableHead } from '@material-ui/core';

import { getSeverityColor } from 'helpers/AppHelpers';

import clsx from 'clsx';

/**
 * @typedef {Object} AssetFieldsPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {{}} data
 */

/**
 * @param {AssetFieldsPopupProps} props
 */

function AssetFieldsPopup({ open, setOpen, data }) {
  const isMobile = useMobileViewport();
  
  const { assetPrefix, assetFields } = data;

  const closePopup = () => setOpen(false);

  const headerStatuses = useMemo(() => {
    const severities = assetFields[0].severityWithValues.map(
      ({ ticketSeverity }) => ticketSeverity
    );

    severities.push({ name: 'Total', displayName: 'Total' });

    return severities.map(({ name, displayName }) => (
      <StyledTableCell key={name} colSpan={2}>
        <div className={styles.status_header}>
          {name !== 'Total' && <Indicator color={getSeverityColor(name)} />}
          {displayName || ''}
        </div>
      </StyledTableCell>
    ));
  }, [assetFields]);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader
          classes={styles.title}
          title={assetPrefix?.prefix || ''}
          onClose={closePopup}
        />
        <div className={styles.content}>
          <TableWrapper>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell rowSpan={2}>Field</StyledTableCell>
                  {headerStatuses}
                </StyledTableRow>
                <StyledTableRow>
                  {headerStatuses.map((_, index) => (
                    <React.Fragment key={index}>
                      <StyledTableCell>Assets</StyledTableCell>
                      <StyledTableCell>Fields</StyledTableCell>
                    </React.Fragment>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {assetFields.map(
                  ({ id, displayName, severityWithValues, totalAssetCount, totalFieldValue }) => (
                    <StyledTableRow key={id} className={styles.asset_field_row}>
                      <StyledTableCell className={styles.nameCell}>
                        {displayName || ''}
                      </StyledTableCell>

                      {severityWithValues.map(({ assetCount, fieldValue, ticketSeverity }) => (
                        <React.Fragment key={ticketSeverity.name}>
                          <StyledTableCell className={styles.countCell}>
                            {assetCount || 0}
                          </StyledTableCell>
                          <StyledTableCell className={styles.countCell}>
                            {fieldValue || 0}
                          </StyledTableCell>
                        </React.Fragment>
                      ))}

                      <StyledTableCell className={clsx(styles.countCell, styles.bold)}>
                        {totalAssetCount || 0}
                      </StyledTableCell>
                      <StyledTableCell className={clsx(styles.countCell, styles.bold)}>
                        {totalFieldValue || 0}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableWrapper>
        </div>
        {!isMobile && (
          <div className={styles.footer}>
            <MainButton text="Close" action={closePopup} type="secondary" />
          </div>
        )}
      </div>
    </DialogWrapper>
  );
}

AssetFieldsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

AssetFieldsPopup.defaultProps = {
  open: false,
  data: { assetPrefix: {}, assetFields: [] }
};

export { AssetFieldsPopup };
