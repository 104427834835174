import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getConfigurationsApi = (query) =>
  api.get(`/ShortedLooseItemConfigurations?${queryToString(query)}`).then((res) => res.data);

export const getConfigurationDetailsApi = (query) =>
  api.get(`/AssembledLooseItems?${queryToString(query)}`).then((res) => res.data);

export const addLooseItemsToPicklistApi = (params) =>
  api.post('/PicklistLooseItems', params).then((res) => res);
