import React from 'react';
import styles from './ItemsTableBody.module.scss';
import { Checkbox, Link, TableBody } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { BASE_ADDRESS } from 'api/api';
import { LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';
import LocationCell from './Cells/LocationCell';
import QuantityCell from './Cells/QuantityCell';
import RemoveIcon from 'assets/images/deleteIcon.svg';

function ItemsTableBody({
  isMobile,
  tableData,
  locations,
  itemsLocations,
  updateTable,
  checked,
  setChecked,
  validationErrors,
  warnings,
  itemsCellRef,
  handleItemClick
}) {
  const onRemoveRow = (index) => updateTable('remove', index);

  const handleCheckbox = (e, rowId) => {
    let newCheckedArray = [...checked];
    if (e.target.checked) {
      newCheckedArray.push(rowId);
    } else {
      newCheckedArray = newCheckedArray.filter((id) => id !== rowId);
    }
    setChecked(newCheckedArray);
  };

  const onLooseItemClick = (e, id) => {
    e.preventDefault();
    handleItemClick(id);
  };

  const getLocations = (itemId, rowId) => {
    const filterByLooseItemId = ({ looseItem }) => looseItem.id === itemId;
    const itemsCount = tableData.filter(filterByLooseItemId).length;

    if (itemsCount !== 1) {
      const usedLocationIds = tableData.filter(filterByLooseItemId).map(({ id }) => id);
      return itemsLocations
        ?.filter(filterByLooseItemId)
        .filter(({ id }) => (id === rowId ? true : !usedLocationIds.includes(id)));
    }
    return itemsLocations?.filter(filterByLooseItemId);
  };

  const getSublocations = (locationId) => {
    return locationId ? locations?.find(({ id }) => id === locationId)?.sublocations || [] : [];
  };

  return (
    <TableBody>
      {tableData.map((row, index) => (
        <StyledTableRow key={row.rowId}>
          <StyledTableCell className={`${styles.checkboxCell}`}>
            <Checkbox
              classes={{ root: styles.checkbox, checked: styles.checked }}
              onChange={(e) => handleCheckbox(e, row.rowId)}
              value={row.id}
              checked={!!checked?.find((id) => id === row.rowId)}
            />
          </StyledTableCell>
          <StyledTableCell className={`${styles.itemsCell}`} scope="row">
            <Link
              href={`${BASE_ADDRESS}${LOOSE_ITEM_DETAILED_PATH}/${row?.looseItem?.id || ''}`}
              onClick={(e) => onLooseItemClick(e, row.looseItem.id)}
              className={styles.rowName}
              style={{
                maxWidth: itemsCellRef?.current
                  ? itemsCellRef.current.offsetWidth - 50 - 24 - 2
                  : '100%',
                minWidth: isMobile ? 250 : null
              }}>
              {row?.looseItem?.name || ''}
            </Link>
          </StyledTableCell>
          <LocationCell
            name="location"
            options={getLocations(row.looseItem.id, row?.id) || []}
            index={index}
            defaultValue={row.fakeLocation}
            updateTable={updateTable}
            optionType="locationWithSublocation"
          />
          <StyledTableCell className={`${styles.commonCell}`}>
            {row?.quantity || 0}
            {!!row?.byAheadAccount && `${' (' + row.byAheadAccount + ')'}`}
          </StyledTableCell>
          <LocationCell
            name="destinationLocation"
            options={locations || []}
            index={index}
            defaultValue={row?.destinationLocation}
            updateTable={updateTable}
            isInvalid={validationErrors[row.rowId]?.destinationLocation}
            errorText={validationErrors[row.rowId]?.destinationLocation}
          />
          <LocationCell
            name="destinationSublocation"
            options={getSublocations(row?.destinationLocation?.id)}
            index={index}
            defaultValue={row?.destinationSublocation}
            updateTable={updateTable}
            isInvalid={validationErrors[row.rowId]?.destinationLocation}
          />
          <QuantityCell
            value={row?.moveQuantity || 0}
            index={index}
            name="moveQuantity"
            updateTable={updateTable}
            isInvalid={validationErrors[row.rowId]?.moveQuantity}
            isWarning={!!warnings[row.rowId]}
            errorText={validationErrors[row.rowId]?.moveQuantity}
          />
          <QuantityCell
            value={row?.moveByAheadAccount || 0}
            index={index}
            name="moveByAheadAccount"
            updateTable={updateTable}
            isInvalid={validationErrors[row.rowId]?.moveByAheadAccount}
            errorText={validationErrors[row.rowId]?.moveByAheadAccount}
          />
          <StyledTableCell className={`${styles.removeCell}`}>
            {tableData?.length !== 1 && (
              <div className={styles.removeBlock} onClick={() => onRemoveRow(index)}>
                <img src={RemoveIcon} alt="remove" />
              </div>
            )}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
}

export default ItemsTableBody;
