import { SUCCESS_CREATE_TICKET_TYPE, SUCCESS_UPDATE_TICKET_TYPE } from 'constants/infoSnackbarData';
import api from '../api';
import {
  SET_TICKET_TYPES_FILTER,
  CLEAR_TICKET_TYPES_STATE,
  SET_LOAD_MORE_FLAG,
  SET_TICKET_TYPE_CREATION_FORM
} from 'constants/reduceConstants';
import { TICKET_TYPES_PATH } from 'constants/routeConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';
import { browserHistory } from 'browserHistory';

export const getTicketTypes = (query) => (dispatch) =>
  api.ticketTypes
    .getTicketTypesApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getTicketType = (q) => () =>
  api.ticketTypes
    .getSingleTicketTypeApi(q)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(TICKET_TYPES_PATH);
    });

export const getCreationForm = () => (dispatch) =>
  api.ticketTypes
    .getCreationFormApi()
    .then((res) => {
      dispatch({ type: SET_TICKET_TYPE_CREATION_FORM, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const createTicketType = (data) => () =>
  api.ticketTypes
    .createTicketTypeApi(data)
    .then(() => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_TICKET_TYPE);
      browserHistory.push(TICKET_TYPES_PATH);
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const updateTicketType = (data) => () =>
  api.ticketTypes
    .updateTicketTypeApi(data)
    .then(() => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_TICKET_TYPE);
      browserHistory.push(TICKET_TYPES_PATH);
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_TICKET_TYPES_FILTER, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_TICKET_TYPES_STATE });
};
