import React from 'react';
import styles from './components.module.scss';

import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

export default function LocationNotes() {
  return (
    <>
      <h3 className={styles.title}>Notes</h3>
      <div className={styles.details}>
        <div className={styles.details__block}>
          <FormTextArea name="notes" options={{ max: 1000 }} />
        </div>
      </div>
    </>
  );
}
