import React, { useState } from 'react';
import styles from './CapacityField.module.scss';

import { IconButton, TextField } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { reformatInputValue } from 'helpers/AppHelpers';

import clsx from 'clsx';

import ColorPicker from 'components/EmploymentEditPopup/EditorForm/ColorPicker';
import StatusMarker from 'components/EmploymentEditPopup/EditorForm/StatusMarker/StatusMarker';

export default function EditableFormRow({ data, onEdit, onRemove, fields }) {
  const [mode, setMode] = useState('display');

  const [values, setValues] = useState({});
  const [error, setError] = useState('');

  const isDisplayMode = mode === 'display';

  const handleEditClick = () => {
    setMode('edit');
    error && setError('');
    setValues({ ...data });
  };

  const handleCancelClick = () => setMode('display');
  const handleRemoveClick = () => onRemove(data);

  const checkDuplicates = (fieldName) => {
    const modifyString = (str) => str.trimEnd().toLowerCase();

    const needField = fields.find(({ name }) => modifyString(name) === modifyString(fieldName));
    return !!(needField?.id && needField?.id !== data.id);
  };

  const validateForm = () => {
    let isValid = true;
    if (!values?.name) {
      setError('Name is required');
      isValid = false;
    }
    if (isValid && checkDuplicates(values?.name)) {
      setError('Capacity with such name exists');
      isValid = false;
    }
    return isValid;
  };

  const changeInputValue = (event) => {
    if (error) setError('');
    const value = reformatInputValue(event.target.value, 60);
    setValues({ ...values, name: value });
  };

  const handleCheckClick = () => {
    if (!validateForm()) return;

    onEdit(values);
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter' || !values?.name) return;

    handleCheckClick();
  };

  const onColorChange = (color) => setValues({ ...values, color: color.hex });

  return (
    <div className={styles.row}>
      <div className={styles.row__field}>
        {isDisplayMode ? (
          <div className={styles.row__field_label}>{data?.name || ''}</div>
        ) : (
          <div className={styles.row__field_input}>
            <TextField
              name="name"
              className={clsx(styles.textInput, error && styles.error)}
              variant="outlined"
              value={values.name || ''}
              onChange={changeInputValue}
              onKeyDown={handleKeyDown}
            />
            {!!error && <span>{error}</span>}
          </div>
        )}
      </div>
      <div
        className={clsx(
          styles.row__controls,
          fields?.length === 1 && isDisplayMode && styles.reduced
        )}>
        {isDisplayMode ? (
          <>
            <StatusMarker color={data.color} />
            <IconButton
              className={clsx(styles.row__controls_button, styles.edit)}
              onClick={handleEditClick}>
              <CreateIcon />
            </IconButton>
            {fields?.length > 1 && (
              <IconButton
                className={clsx(styles.row__controls_button, styles.remove)}
                onClick={handleRemoveClick}>
                <DeleteIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <ColorPicker color={values.color} onChange={onColorChange} />
            <IconButton
              className={clsx(styles.row__controls_button, styles.check)}
              onClick={handleCheckClick}>
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              className={clsx(styles.row__controls_button, styles.cancel)}
              onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
}
