import React from 'react';
import styles from './MenuPopper.module.scss';

import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';

export default function MenuPopper({ open, setOpen, anchorRef, classes, placement, children }) {
  const clickAway = () => setOpen(false);
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      modifiers={{ preventOverflow: { enabled: false } }}
      transition
      placement={placement}
      disablePortal>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper classes={{ root: clsx(styles.menu, classes && classes) }}>
            <ClickAwayListener onClickAway={clickAway}>
              <MenuList autoFocusItem={open}>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
