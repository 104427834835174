import React from 'react';

import RequestOrderCreateWrapper from './components/Wrapper/RequestOrderCreateWrapper';

import { FormProvider, useForm } from 'react-hook-form';
import RequestOrderCreateForm from './components/Form/RequestOrderCreateForm';

export default function RequestOrderCreate() {
  const methods = useForm({
    defaultValues: {
      urgencyLevel: {},
      deadlineAtUtc: null,
      endUser: null,
      endEmail: null,
      endUserNumber: '',
      location: {},
      project: '',
      deliveryAddress: '',
      notes: '',
      requestEntityData: [
        {
          requestEntity: {},
          looseItemCategory: null,
          requestOrderAssetPrefixes: null,
          requestOrderLooseItems: null
        }
      ]
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <RequestOrderCreateWrapper>
        <RequestOrderCreateForm />
      </RequestOrderCreateWrapper>
    </FormProvider>
  );
}
