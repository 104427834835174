import React, { useEffect, useRef, useState } from 'react';
import styles from './PicklistEdit.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import { getBackendErrors } from 'helpers/AppHelpers';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectLocationsData, selectProjects, selectSinglePicklistData } from '../selectors';
import {
  clearState,
  createPicklist,
  getLocations,
  getProjects,
  getSinglePicklist,
  updatePicklist
} from 'actions/pickListActions';
import MainButton from 'components/StyledComponents/MainButton';
import { useHistory, useParams } from 'react-router-dom';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  CREATE_NEW_PICKLIST,
  EXIT_WITHOUT_SAVING,
  NEW_PICKLIST_TYPE,
  UPDATE_PICKLIST,
  UPDATE_PICKLIST_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { PICKLIST_DETAILED_PATH, PICKLIST_SUMMARY_PATH } from 'constants/routeConstants';
import { setUnsavedFormData } from 'actions/commonActions';
import { useForm, FormProvider } from 'react-hook-form';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from '../../../../components/BackButton/BackButton';

function PicklistEdit({
  locationList,
  getLocationListAction,
  projectList,
  getProjectListAction,
  currentPicklist,
  getPicklistAction,
  createPicklistAction,
  updatePicklistAction,
  setUnsavedFormDataAction,
  unsavedFormData,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const containerRef = useRef(null);
  const routeUrl = useRef(null);
  const breadcrumbs = useRef([{ name: 'Create Picklist' }]);

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      pocName: '',
      pocNumber: '',
      location: {},
      sublocation: null,
      project: {}
    },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, reset, clearErrors, setError, watch } = methods;
  const { isDirty, errors } = formState;

  const changeInputValue = (name, value) => setValue(name, value, { shouldDirty: true });

  useEffect(() => {
    getProjectListAction({ includeUserCheck: true });
    getLocationListAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    if (id && currentPicklist?.id) {
      breadcrumbs.current = [
        {
          path: `${PICKLIST_DETAILED_PATH}/${id}`,
          name: currentPicklist.name
        },
        { name: 'Edit Picklist' }
      ];
    } else {
      breadcrumbs.current = [{ name: 'Create Picklist' }];
    }
  }, [currentPicklist?.id]);

  useEffect(() => {
    if (!id) return;
    if (projectList?.length && locationList?.length && currentPicklist?.id) {
      reset(currentPicklist);
    }
  }, [projectList, currentPicklist, locationList]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    if (id) {
      getPicklistAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const onInput = (key, value) => {
    errors[key]?.message && validateForm(key);
    changeInputValue(key, value);
  };

  const selectLocation = (name, value) => {
    changeInputValue(name, value);
    changeInputValue('pocName', value?.pocName || '');
    changeInputValue('pocNumber', value?.pocNumber || '');
    errors?.location?.message && validateForm(name);
  };

  const selectSearchOption = (name, value) => changeInputValue(name, value);

  const selectProject = (name, value) => {
    changeInputValue(name, value);
    changeInputValue('location', {});
    changeInputValue('sublocation', null);
    errors?.project?.message && validateForm(name);
    errors?.location?.message && validateForm('location');
  };

  const errorProperties = [
    { name: 'name', inputType: 'text', errorMessage: 'Name field is required' },
    { name: 'project', inputType: 'object', errorMessage: 'Project Number field is required' },
    { name: 'location', inputType: 'object', errorMessage: 'Location field is required' }
  ];

  const validateForm = (key) => {
    if (key) {
      clearErrors(key);
      return;
    }
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues()[name], inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;

  const handleErrors = (err) => {
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    containerRef.current.scrollIntoView();
  };

  const getCheckedValues = () => {
    const data = getValues();
    if (data?.sublocation?.name === '-') {
      data.sublocation = null;
    }
    return data;
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case NEW_PICKLIST_TYPE:
        closeModal();
        createPicklistAction(getCheckedValues()).then((err) => err?.errors && handleErrors(err));
        break;
      case UPDATE_PICKLIST_TYPE:
        closeModal();
        updatePicklistAction(getCheckedValues(), false, true).then(
          (err) => err?.errors && handleErrors(err)
        );
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl.current);
        break;
      default:
        break;
    }
  };

  const getBack = () =>
    id ? history.push(`${PICKLIST_DETAILED_PATH}/${id}`) : history.push(PICKLIST_SUMMARY_PATH);

  const closeModal = () => setDialogModalData({ isOpened: false });

  const onCancel = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const onCreate = () => {
    if (!validateForm()) {
      return;
    }
    if (id) {
      setDialogModalData({ ...UPDATE_PICKLIST, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_PICKLIST, isOpened: true });
    }
  };

  const getSublocations = () => {
    if (!getValues()?.location?.id) return [];

    const sublocations =
      locationList?.find(({ id }) => id === getValues().location.id)?.sublocations || [];
    const areSublocationsExist = !!sublocations?.length;

    return areSublocationsExist ? sublocations : [];
  };

  const filterLocationsByProjectId = (options) => {
    if (!getValues()?.project?.id) return [];
    return options.filter(({ projectId }) => projectId === getValues().project.id);
  };
  const enrichLocationOptions = () => {
    if (
      locationList?.length &&
      getValues()?.location?.id &&
      !locationList.map(({ id }) => id).includes(getValues().location.id)
    ) {
      return locationList.concat({ ...getValues()?.location }).sort((a, b) => a.id > b.id);
    } else return locationList;
  };

  const setSelectedRoute = (path) => (routeUrl.current = path);

  const projectWatcher = watch('project');
  const locationWatcher = watch('location');

  return (
    <section className={styles.pageContainer} ref={containerRef}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      <div>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setDialogModalData}
            formIsChanged={isDirty}
            setRouteUrl={setSelectedRoute}
          />
        )}
        <div className={styles.headerBlock}>
          <h1>{id ? 'Edit Picklist' : 'Create Picklist'}</h1>
          {isMobile && <BackButton onCancel={onCancel} />}
        </div>
        <FormProvider {...methods}>
          <section className={styles.formWrapper}>
            <section className={styles.formRow}>
              <label>Name*</label>
              <FormInputText name="name" onChange={onInput} max={40} />
            </section>
            <section className={styles.formRow}>
              <label>Project Number*</label>
              <FormSearchInput
                name="project"
                options={projectList || []}
                onSelect={selectProject}
              />
            </section>
            <section className={styles.formRow}>
              <label>Location*</label>
              <FormSearchInput
                name="location"
                options={filterLocationsByProjectId(enrichLocationOptions()) || []}
                onSelect={selectLocation}
                isDisabled={!projectWatcher?.id}
              />
            </section>
            <section className={styles.formRow}>
              <label>Sublocation</label>
              <FormSearchInput
                clearable
                name="sublocation"
                options={getSublocations() || []}
                onSelect={selectSearchOption}
                isDisabled={!locationWatcher?.id}
              />
            </section>
            <section className={styles.formRow}>
              <label>POC Name</label>
              <FormInputText name="pocName" onChange={onInput} max={40} />
            </section>
            <section className={styles.formRow}>
              <label>POC Number</label>
              <FormInputText name="pocNumber" onChange={onInput} max={40} />
            </section>
          </section>
        </FormProvider>
      </div>
      <div className={styles.footerControls}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={onCreate}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentPicklist: selectSinglePicklistData(),
  locationList: selectLocationsData(),
  projectList: selectProjects(),
  unsavedFormData: selectUnsavedFormData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getPicklistAction: getSinglePicklist,
  getLocationListAction: getLocations,
  getProjectListAction: getProjects,
  createPicklistAction: createPicklist,
  updatePicklistAction: updatePicklist,
  clearStateAction: clearState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PicklistEdit);
