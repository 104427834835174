import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import LooseItemCreateWrapper from './components/LooseItemCreateWrapper';
import CreationForm from './components/CreationForm';

import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';

export default function LooseItemEdit() {
  const { id } = useParams();

  const { getItemAction, clearStateAction } = useLooseItemActions();
  const { currentLooseItem } = useLooseItemSelector();

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      location: {},
      sublocation: null,
      quantity: 0,
      byAheadAccount: 0,
      minimumQuantity: 0,
      looseItemCategories: [],
      resources: [],
      customFields: []
    },
    mode: 'onChange'
  });
  const { reset } = methods;

  useEffect(() => {
    if (id) {
      getItemAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentLooseItem?.id) {
      reset(currentLooseItem);
    }
  }, [currentLooseItem]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <LooseItemCreateWrapper>
        <CreationForm />
      </LooseItemCreateWrapper>
    </FormProvider>
  );
}
