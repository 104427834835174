import React from 'react';
import styles from './FieldsContainer.module.scss';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import MenuIcon from '@material-ui/icons/Menu';

import CapacityField from './CapacityField';

const SortContainer = SortableContainer(({ children }) => {
  return <div className={styles.fields}>{children}</div>;
});

const DragHandle = SortableHandle(() => <MenuIcon />);

export function FieldsContainer({ fields, setFields, onRemove }) {
  const setGrabbingCursor = () => (document.body.style.cursor = 'grabbing');
  const setDefaultCursor = () => (document.body.style.cursor = 'default');

  const endDrag = ({ oldIndex, newIndex }) => {
    setDefaultCursor();
    const result = Array.from(fields);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
    setFields(result);
  };

  const editField = (values) => {
    setFields(fields.map((field) => (field.id === values.id ? values : field)));
  };

  const removeCapacity = (field) => {
    const deletedField = fields.find(({ id }) => id === field.id);
    setFields(fields.filter(({ id }) => id !== field.id));
    onRemove(deletedField);
  };

  const SortableItem = SortableElement(({ value, sortIndex }) => (
    <div className={styles.fields__row}>
      <DragHandle sortIndex={sortIndex} />
      <CapacityField data={value} onEdit={editField} onRemove={removeCapacity} fields={fields} />
    </div>
  ));

  return (
    <SortContainer
      useDragHandle
      onSortStart={setGrabbingCursor}
      onSortEnd={endDrag}
      axis="y"
      distance={1}
      helperClass={styles.helper}>
      {!!fields?.length &&
        fields.map((field, index) => <SortableItem key={field.id} index={index} value={field} />)}
    </SortContainer>
  );
}
