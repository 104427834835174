import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styles from './NeedsToBeInspectedPopup.module.scss';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import { useMobileViewport } from 'hooks/useMobileViewport';

function NeedsToBeInspectedPopup() {
  const isMobile = useMobileViewport();

  const trigger = (
    <div className={styles.button}>
      <InfoOutlinedIcon />
    </div>
  );

  const tooltip = (
    <div className={styles.tooltip}>
      <span>Needs to be inspected</span>
    </div>
  );

  return (
    <TooltipWrapper
      trigger={trigger}
      tooltip={tooltip}
      options={{ trigger: isMobile ? 'click' : 'hover', placement: 'right-end' }}
    />
  );
}

export default NeedsToBeInspectedPopup;
