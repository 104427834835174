import { useDispatch } from 'react-redux';

import {
  createTicket,
  deleteTicket,
  getSingleTicket,
  getCreationFrom,
  setPrefilledAsset,
  getTicketsFilterCriteria,
  updateTicket,
  getTicketsCountings,
  getServiceTicketsList,
  setTicketListFilter,
  clearState
} from 'actions/ticketsActions';

export default function useTicketActions() {
  const dispatch = useDispatch();

  const getCountingAction = (q) => dispatch(getTicketsCountings(q));

  const getTicketListAction = (q) => dispatch(getServiceTicketsList(q));

  const setFilterAction = (data) => dispatch(setTicketListFilter(data));

  const clearStateAction = () => dispatch(clearState());

  const getTicketAction = (q) => dispatch(getSingleTicket(q));
  const deleteTicketAction = (id) => dispatch(deleteTicket(id));

  const getCreationFromAction = (q) => dispatch(getCreationFrom(q));
  const getFilterCriteriaAction = () => dispatch(getTicketsFilterCriteria());
  const setPrefilledAssetAction = (asset) => dispatch(setPrefilledAsset(asset));

  const createTicketAction = (data) => dispatch(createTicket(data));
  const updateTicketAction = (data) => dispatch(updateTicket(data));

  return {
    getCountingAction,
    getTicketListAction,
    setFilterAction,
    clearStateAction,
    getCreationFromAction,
    setPrefilledAssetAction,
    getFilterCriteriaAction,
    getTicketAction,
    deleteTicketAction,
    createTicketAction,
    updateTicketAction
  };
}
