import { useHistory, useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserConfig } from '../pages/commonSelectors';
import { useEffect } from 'react';
import { ROOT_PATH } from 'constants/routeConstants';

function LooseItemEditGuard({ component }) {
  const history = useHistory();
  const { id } = useParams();

  const rolesAllowed = id ? [1, 2, 3, 4, 5] : [1, 2, 4, 5];

  const { userRole } = useSelector(selectUserConfig());
  const suitableRole = rolesAllowed.includes(userRole?.id);

  useEffect(() => {
    if (userRole) {
      if (!suitableRole) {
        history.push(ROOT_PATH);
      }
    }
  }, [userRole]);

  return suitableRole ? component : null;
}

export default withRouter(LooseItemEditGuard);
