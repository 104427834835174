import React from 'react';
import styles from '../CustomDetails.module.scss';
import FormLabeledCheckbox from 'components/FormComponents/FormCheckbox/FormLabeledCheckbox';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import SectionLabel from 'pages/ReportLists/commonComponents/SectionLabel';

export default function TextSection({ field, index, onSave, onBlur, onFocus }) {
  return (
    <div className={styles.text_section}>
      <SectionLabel>
        {field.data.text}
        {field.data.required && '*'}
      </SectionLabel>

      {field.data.createTicket?.needCreation && (
        <div className={styles.checkbox}>
          <FormLabeledCheckbox
            name={`generalReportFields[${index}].data.createTicket.isSelected`}
            label="Create a ticket?"
            extraAction={onSave}
          />
        </div>
      )}
      <FormTextArea
        name={`generalReportFields[${index}].data.value`}
        options={{
          max: 65000,
          rule: field.data.compare ? /[^0-9]/g : allKeyboardLatSymbols,
          onBlur,
          onFocus
        }}
      />
    </div>
  );
}
