import React, { useEffect, useState } from 'react';
import styles from './TeamMemberHistoryFilter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import Multiselect from 'components/Multiselect/Multiselect';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import TooltipSelectingDepartment from 'components/InfoTooltip/InfoTooltip';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistoryActions, useHistorySelector } from 'hooks/TeamMemberHistory';

import { enrichNameToId, formatDate, isEqual } from 'helpers/AppHelpers';
import { getChips, getValuesAfterChipDeleting } from './helpers';

import moment from 'moment';

export default function TeamMemberHistoryFilter({ onApply }) {
  const isMobile = useMobileViewport();

  const { filter, initialFilterCriteria, secondaryFilterCriteria } = useHistorySelector();
  const { updateHistoryConfigAction, getSecondaryFilterCriteriaAction } = useHistoryActions();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [chips, setChips] = useState([]);

  const updateConfig = (changedFilter) => updateHistoryConfigAction(changedFilter);
  const returnValues = () => {
    setValues(filter.filters);
    if (!isEqual(filter.filters.teamMemberDepartmentIds, values['teamMemberDepartmentIds'])) {
      getSecondaryFilterCriteriaAction({
        departmentIds: filter.filters.teamMemberDepartmentIds
      });
    }
  };

  useEffect(() => {
    if (!filter.responseReceived) return;
    if (!initialFilterCriteria?.projects?.length) return;

    setValues(filter.filters);
    createChips(filter.filters);
  }, [filter, initialFilterCriteria]);

  const handleClearClick = () => {
    const defaultState = {
      ...values,
      teamMemberCapacityIds: [],
      timeOnProjectNames: [],
      teamMemberDepartmentIds: [],
      teamMemberIds: [],
      teamMemberStatusIds: [],
      projectIds: [],
      fromDayIn: null,
      toDayIn: null,
      fromDayOut: null,
      toDayOut: null,
      showAllHistory: false
    };
    setValues(defaultState);
    isMobile && setChips([]);
    getSecondaryFilterCriteriaAction({ departmentIds: [] });
  };

  const handleBackClick = () => {
    isMobile && createChips(filter.filters);
    setOpen(false);
    returnValues();
  };
  const handleIconClick = (value) => {
    setOpen(value || !open);
    open && returnValues();
  };

  const handleSelect = (name, value) => {
    if (name === 'teamMemberDepartmentIds') {
      getSecondaryFilterCriteriaAction({ departmentIds: value }).then(() => {
        const resetValues = {
          ...values,
          teamMemberDepartmentIds: value,
          teamMemberCapacityIds: [],
          teamMemberIds: []
        };
        setValues(resetValues);
        isMobile && createChips(resetValues);
      });
    } else {
      const updatedValues = { ...values, [name]: [...value] };
      setValues(updatedValues);
      isMobile && createChips(updatedValues);
    }
  };
  const handleTimeSelect = (e) => {
    const updatedValues = { ...values, [e.target.name]: [...e.target.value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };
  const handleDateChange = (date, name) => {
    const updatedValues = { ...values, [name]: date ? formatDate(date) : null };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };
  const handleCheckboxClick = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const handleClickApply = () => {
    onApply(values);
    createChips(values);
    updateConfig(values);
    setOpen(false);
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);
    const newValues = getValuesAfterChipDeleting(values, chip);
    setValues(newValues);
    if (!isMobile) {
      onApply(newValues);
      updateConfig(newValues);
    }
  };

  const createChips = (inputFilter) => {
    const chipsArray = getChips(inputFilter, initialFilterCriteria);
    setChips(chipsArray);
  };

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      onChipDelete={handleChipDelete}
      disableSeparateChipDelete={(chip) => chip.key === 'department'}
      areChipsAvailable={!!initialFilterCriteria?.projects?.length}>
      <section className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <div className={styles.block__cell_label}>
              Department
              <TooltipSelectingDepartment text="By selecting Department, lists of Capacity, Team Member will be narrowed." />
            </div>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberDepartmentIds"
                label="name"
                value={values?.teamMemberDepartmentIds || []}
                options={
                  secondaryFilterCriteria?.teamMemberDepartments ||
                  initialFilterCriteria?.teamMemberDepartments ||
                  []
                }
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Team Member</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberIds"
                label="lastName"
                labelType="teamMember"
                value={values?.teamMemberIds || []}
                options={
                  secondaryFilterCriteria?.teamMembers || initialFilterCriteria?.teamMembers || []
                }
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Capacity</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberCapacityIds"
                label="name"
                value={values?.teamMemberCapacityIds || []}
                options={
                  secondaryFilterCriteria?.teamMemberCapacities ||
                  initialFilterCriteria?.teamMemberCapacities ||
                  []
                }
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Employment</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberStatusIds"
                label="name"
                value={values?.teamMemberStatusIds || []}
                options={
                  secondaryFilterCriteria?.teamMemberStatuses ||
                  initialFilterCriteria?.teamMemberStatuses ||
                  []
                }
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <label>Project</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="projectIds"
                labelType="project"
                label="name"
                value={values?.projectIds || []}
                options={secondaryFilterCriteria?.projects || initialFilterCriteria?.projects || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Time on Project</label>
            <div className={styles.block__cell_input}>
              <Multiselect
                name="timeOnProjectNames"
                valuesArray={values?.['timeOnProjectNames']}
                options={
                  enrichNameToId(
                    secondaryFilterCriteria?.timeOnProjects || initialFilterCriteria?.timeOnProjects
                  ) || []
                }
                onFilterSelect={handleTimeSelect}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Date In:</label>
            <div className={styles.block__cell_dates}>
              <div className={styles.block__cell_dates_row}>
                <label>from</label>
                <div className={styles.block__cell_dates_row_picker}>
                  <StyledDatePicker
                    name="fromDayIn"
                    value={values?.fromDayIn || null}
                    onChange={handleDateChange}
                    min={moment().add(-15, 'year')}
                    max={values?.toDayIn || moment().add(15, 'year')}
                    placement={isMobile && 'top'}
                  />
                </div>
              </div>
              <div className={styles.block__cell_dates_row}>
                <label>to</label>
                <div className={styles.block__cell_dates_row_picker}>
                  <StyledDatePicker
                    name="toDayIn"
                    value={values?.toDayIn || null}
                    onChange={handleDateChange}
                    min={values?.fromDayIn || moment().add(-15, 'year')}
                    max={moment().add(15, 'year')}
                    placement={isMobile && 'top'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.block__cell}>
            <label>Date Out:</label>
            <div className={styles.block__cell_dates}>
              <div className={styles.block__cell_dates_row}>
                <label>from</label>
                <div className={styles.block__cell_dates_row_picker}>
                  <StyledDatePicker
                    name="fromDayOut"
                    value={values?.fromDayOut || null}
                    onChange={handleDateChange}
                    min={moment().add(-15, 'year')}
                    max={values?.toDayOut || moment().add(15, 'year')}
                    placement={isMobile && 'top'}
                  />
                </div>
              </div>
              <div className={styles.block__cell_dates_row}>
                <label>to</label>
                <div className={styles.block__cell_dates_row_picker}>
                  <StyledDatePicker
                    name="toDayOut"
                    value={values?.toDayOut || null}
                    onChange={handleDateChange}
                    min={values?.fromDayOut || moment().add(-15, 'year')}
                    max={moment().add(15, 'year')}
                    placement={isMobile && 'top'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.block__checkbox}>
            <div className={styles.block__checkbox_wrapper}>
              <label>See all history</label>
              <CustomCheckbox
                name="showAllHistory"
                value={values?.showAllHistory}
                onChange={handleCheckboxClick}
              />
            </div>
          </div>
        </div>
      </section>
    </FilterWrapper>
  );
}
