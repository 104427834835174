import React from 'react';
import styles from './Content.module.scss';
import { Fade, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';

export default function PopperWrapper({ open, setOpen, iconRef, size, children }) {
  return (
    <Popper open={!!open} anchorEl={() => iconRef.current} placement="bottom-start" transition>
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
          timeout={0}>
          <Paper>
            <div className={clsx(styles.popper, size === 'large' && styles.larger)}>
              <div className={styles.popper__cross} onClick={() => setOpen(false)}>
                x
              </div>
              {children}
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
