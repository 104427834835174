import React from 'react';
import styles from './StatusCalculations.module.scss';
import clsx from 'clsx';
import { getFixedCounts } from '../../helpers';

export default function StatusCalculations({ data, index }) {
  const severities = getFixedCounts(data, index);
  const getCounter = (type) => {
    return severities.find(({ name }) => type === name)['count'] || 0;
  };

  return (
    <div className={styles.summaryCounters}>
      <div className={styles.blockRow}>
        <p>
          <span className={clsx(styles.statusIndicator, styles.Operational)}></span>
          Operational:
        </p>
        <b>{getCounter('Operational')}</b>
      </div>
      <div className={styles.blockRow}>
        <p>
          <span className={clsx(styles.statusIndicator, styles.NonCritical)}></span>
          Non-Critical:
        </p>
        <b>{getCounter('NonCritical')}</b>
      </div>
      <div className={styles.blockRow}>
        <p>
          <span className={clsx(styles.statusIndicator, styles.Critical)}></span>
          Critical:
        </p>
        <b>{getCounter('Critical')}</b>
      </div>
      <div className={styles.blockRow}>
        <p>
          <span className={clsx(styles.statusIndicator, styles.BeyondRepair)}></span>
          Beyond repair:
        </p>
        <b>{getCounter('BeyondRepair')}</b>
      </div>
    </div>
  );
}
