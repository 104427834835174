import React from 'react';
import styles from './NotificationCenterWrapper.module.scss';

import Header from './Header';

export default function NotificationCenterWrapper({ children }) {
  return (
    <div className={styles.wrapper}>
      <Header />
      {children}
    </div>
  );
}
