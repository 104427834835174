import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './TeamList.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import { StyledButton } from 'components/Buttons';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';

import { Table, TableBody } from '@material-ui/core';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useTeamMemberActions, useTeamMemberSelector } from 'hooks/TeamMember';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useHistory } from 'react-router-dom';

import {
  getChangedByLimitFilter,
  getChangedByApplyFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { pluck, joinWithComma } from 'helpers/AppHelpers';

import { TEAM_MEMBER_DETAILED_PATH, TEAM_MEMBER_EDIT_PATH } from 'constants/routeConstants';
import { TEAM_MEMBERS_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  generateChips,
  generateInitialQuery,
  parseQuery,
  useTeamMembersParams,
  tableHeadConfig,
  removeChip
} from './helpers';
import FilterModal from './Filter';

export function TeamList() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [tableState, setTableState] = useState([]);
  const [chips, setChips] = useState([]);

  const {
    isConfigReceived,
    getTableLimit,
    isAllLimitEnabled,
    isAdminUser,
    isPersonnelManagerUser
  } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const urlParams = useTeamMembersParams();

  const { filter, filterCriteria } = useTeamMemberSelector();
  const { getTeamMembersAction, getFilterCriteriaAction, setFilterAction, clearStateAction } =
    useTeamMemberActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const getTeamMembers = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getTeamMembersAction(parseQuery(query)).then((tableData) => {
        setTeamMembers(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getTeamMembersAction]
  );

  const setTeamMembers = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    const query = { departmentIds: filters?.teamMemberDepartmentIds || [] };
    getFilterCriteriaAction(query).then((criteria) => {
      const q = { getTableLimit, isAllLimitEnabled, filter, ...urlParams };
      const resultFilter = generateInitialQuery(q);
      getTeamMembers(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getTeamMembers(getChangedBySearchFilter(filter, value)),
    [filter, getTeamMembers]
  );
  const sortTable = useCallback(
    (rowName) => getTeamMembers(getChangedBySortFilter(filter, rowName)),
    [filter, getTeamMembers]
  );
  const applyFilter = useCallback(
    (values) => {
      getTeamMembers(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getTeamMembers]
  );
  const changeLimit = useCallback(
    () =>
      getTeamMembers(getChangedByLimitFilter(filter, getTableLimit(TEAM_MEMBERS_TABLE_PARAMETER))),
    [filter, getTableLimit, getTeamMembers]
  );
  const changePage = useCallback(
    (page, param) => getTeamMembers(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getTeamMembers]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const createTeamMember = useCallback(() => history.push(TEAM_MEMBER_EDIT_PATH), [history]);

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const MainButton = () => (
    <StyledButton
      label="Create team member"
      onClick={createTeamMember}
      classes={styles.main_button}
    />
  );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Team List">
          {!isMobile && (isAdminUser || isPersonnelManagerUser) && (
            <div className={styles.controls}>
              <DownloadCSVButton
                filter={filter}
                endpoint="TeamMembers/Serialized"
                includePagination
                includeUserId
              />
              <MainButton />
            </div>
          )}
        </SummaryPageHeader>

        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && (
              <FilterChips
                chips={chips}
                onDelete={deleteChip}
                canDelete={(chip) => chip.key !== 'teamMemberDepartmentIds'}
              />
            )}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={sortTable}
          />

          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={index} className={styles.team_member_row}>
                  <StyledTableCell className={styles.teamMemberCell}>
                    <StyledLink
                      bold
                      to={`${TEAM_MEMBER_DETAILED_PATH}/${row.id}`}
                      isRedirectAvailable={isAdminUser || isPersonnelManagerUser}>
                      {row.lastName + ', ' + row.firstName}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.numberCell}>
                    {row?.idNumber || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.departmentCell}>
                    {row.departments?.length ? joinWithComma(pluck(row.departments, 'name')) : ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.readOnlyDepCell}>
                    {row?.isSelectedAllReadOnlyDepartments
                      ? 'All Selected'
                      : row.readOnlyDepartments?.length
                      ? joinWithComma(pluck(row.readOnlyDepartments, 'name'))
                      : ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.capacityCell}>
                    {row?.capacity?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.emailCell}>{row.email || ''}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        withOption100
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={TEAM_MEMBERS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
