import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useParams, useHistory } from 'react-router-dom';
import { selectSingleCategoryData } from '../selectors';
import { selectUserConfig } from 'pages/commonSelectors';
import { getSingleCategory, deleteCategory, setActiveCategory } from 'actions/categoryActions';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import { isAdminUser } from 'helpers/AppHelpers';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Button,
  FormControl,
  TextField
} from '@material-ui/core';
import styles from './CategoryDetailed.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StyledTableCell,
  StyledTableRow
} from 'components/StyledComponents/';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CATEGORY_LIST_PATH,
  CATEGORY_EDIT_PATH,
  PREFIX_DETAILED_PATH,
  PREFIX_EDIT_PATH,
  ASSETS_LIST_PATH
} from 'constants/routeConstants';
import { DELETE_CATEGORY } from 'constants/dialogPopupsData';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';

function CategoryDetails({
  userConfig,
  currentCategory,
  getCategoryAction,
  deleteCategoryAction,
  setActiveCategoryAction,
  setAssetsPrefilterAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const { userRole } = userConfig;

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  useEffect(() => {
    if (id) {
      getCategoryAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (currentCategory) {
      setBreadcrumbs([{ name: currentCategory.name }]);
    }
  }, [currentCategory]);

  const onAgree = () => deleteCategoryAction(currentCategory.id);
  const onDismiss = () => setDialogModalData({ isOpened: false });

  const onAddPrefixClick = (category) => {
    setActiveCategoryAction(category);
    history.push(PREFIX_EDIT_PATH);
  };

  const onAssetClick = (prefix) => {
    setAssetsPrefilterAction({ prefixIds: [prefix.id] });
    history.push(ASSETS_LIST_PATH);
  };

  const handleBackClick = () => history.push(CATEGORY_LIST_PATH);
  const handleDeleteClick = () => setDialogModalData({ ...DELETE_CATEGORY, isOpened: true });
  const handleEditClick = () => history.push(`${CATEGORY_EDIT_PATH}/${currentCategory.id}`);

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {currentCategory.id === +id && (
        <div>
          {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
          <section className={styles.formWrapper}>
            <div className={styles.headerBlock}>
              {!isMobile && <h1>{currentCategory.name}</h1>}
              <div className={styles.headerBlock__controls}>
                {isAdminUser(userRole) && (
                  <button onClick={handleEditClick}>
                    <CreateIcon />
                    {!isMobile && <span>Edit category</span>}
                  </button>
                )}
                {isAdminUser(userRole) && (
                  <button onClick={handleDeleteClick}>
                    <DeleteIcon />
                    {!isMobile && <span>Remove category</span>}
                  </button>
                )}
                {isMobile && <BackButton onCancel={handleBackClick} />}
              </div>
            </div>
            {isMobile && <h1>{currentCategory.name}</h1>}
            <FormControl>
              <h3>Description</h3>
              <TextField
                name="description"
                value={currentCategory.description}
                className={styles.multiTextInput}
                multiline
                rows={4}
                rowsMax={13}
                variant="outlined"
                disabled
              />
            </FormControl>
            <h3 className={styles.margedHeader}>Prefix list</h3>
            <Accordion expanded>
              <AccordionSummary
                classes={{ root: styles.expandedRow }}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                {isAdminUser(userRole) && (
                  <Button
                    className={styles.accordionButton}
                    onClick={() => onAddPrefixClick(currentCategory)}>
                    Add prefix
                  </Button>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table" className={styles.table}>
                    <TableBody>
                      {currentCategory.assetPrefixes?.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>
                            <LinkComponent
                              name={row.prefix}
                              path={`${PREFIX_DETAILED_PATH}/${row.id}`}
                              boldText
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>{row.description}</span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className={styles.rowLink} onClick={() => onAssetClick(row)}>
                              VIEW ASSETS
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </section>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  currentCategory: selectSingleCategoryData()
});

const mapDispatchToProps = {
  getCategoryAction: getSingleCategory,
  deleteCategoryAction: deleteCategory,
  setActiveCategoryAction: setActiveCategory,
  setAssetsPrefilterAction: setAssetsPrefilter
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CategoryDetails);
