export const tableConfig = [
  {
    id: 1,
    label: 'Name',
    isSortable: true,
    parameter: 'name'
  },
  {
    id: 2,
    label: 'Severity',
    isSortable: false
  },
  {
    id: 3,
    label: 'Reopening',
    isSortable: true,
    parameter: 'reopening'
  },
  {
    id: 4,
    label: 'Description',
    isSortable: false,
    parameter: null
  },
  {
    id: 5,
    label: 'Status',
    isSortable: true,
    parameter: 'active'
  },
  {
    id: 6,
    label: 'Prefix',
    isSortable: false
  },
  {
    id: 7,
    label: '',
    isSortable: false
  }
];
