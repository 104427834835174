import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_FILE_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './UploadFilesStyles.scss';

const FILES_LIMIT = 20;
const MAX_FILE_SIZE = 10485760;

const AVAILABLE_FORMATS = {
  general: {
    match: /(pdf|csv|png|jpg|jpeg|raw|tiff|bmp|gif|msword|text|excel|powerpoint|openxmlformats|xml)/,
    title: 'PDF, CSV, PNG, JPEG, RAW, TIFF, BMP, GIF, DOC, DOCX, TXT, XLS, XLSX , XML, PPTX, PPT'
  },
  looseItems: {
    match: /(csv|excel)/,
    title: 'CSV'
  }
};

const FILE_MIME_TYPES = `image/png,image/jpg,image/jpeg,image/jpg,image/raw,image/tiff,image/bmp,image/gif,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,
    text/csv,application/pdf,text/plain,application/excel,application/powerpoint,application/xhtml+xml,.csv`;

const Input = (
  <>
    <AddCircleOutlineIcon />
    Upload files
  </>
);

const Layout = ({ input, uploadedFiles, onDeleteFile, previews, dropzoneProps, index }) => {
  return (
    <div className="dzu-layoutWrapper">
      <div
        className={uploadedFiles.length >= FILES_LIMIT ? 'deprecatedInput' : ''}
        {...dropzoneProps}>
        {uploadedFiles.length < FILES_LIMIT ? (
          input
        ) : (
          <>
            <label className="dzu-inputLabel deprecatedInput">{Input}</label>
            <p>Upload limit exceeded</p>
          </>
        )}
      </div>

      {!!uploadedFiles.length &&
        uploadedFiles.map((file) => (
          <div className="dzu-fileWrapper" key={file.id}>
            <span>{file.name}</span>
            <HighlightOffIcon
              onClick={() => onDeleteFile(file, index)}
              className="dzu-deleteButton"
            />
          </div>
        ))}

      {previews}
    </div>
  );
};

const Preview = (props) => {
  const {
    meta: { name, percent, status }
  } = props;

  return (
    <>
      {status !== 'done' && status !== 'error_file_size' && status !== 'aborted' && (
        <div className="dzu-previewFileWrapper">
          <span className={`${status !== 'done' ? 'dzu-fileLoading' : ''}`}>{name}</span>
          <CircularProgress
            className="dzu-uploadSpinner"
            variant="determinate"
            value={Math.round(percent)}
          />
        </div>
      )}
    </>
  );
};

export default function UploadFiles({
  uploadedFiles,
  onUploadFile,
  onDeleteFile,
  formatType,
  index
}) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const getUploadParams = () => {
    if (getToken()) {
      return {
        url: UPLOAD_FILE_URL,
        headers: {
          authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
        }
      };
    }
  };

  const handleChangeStatus = (fileWithMeta, status, files) => {
    const { meta } = fileWithMeta;

    const uniqueFileArray = [
      ...uploadedFiles,
      ...files?.map((file) => JSON.parse(file.xhr?.response || null))?.filter(Boolean)
    ].filter(
      (currentFile, index, filesArray) =>
        filesArray.findIndex((file) => file.id === currentFile.id) === index
    );

    if (!meta.type.match(AVAILABLE_FORMATS[formatType || 'general'].match)) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed:
                ${AVAILABLE_FORMATS[formatType || 'general'].title}`,
        isOpened: true
      });
    } else if (meta.size > MAX_FILE_SIZE) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" exceeds maximum allowed upload size of 10MB`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (uniqueFileArray.length > FILES_LIMIT) {
      setPopupData({
        ...popupData,
        title: `Upload limit (${FILES_LIMIT}) exceeded`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (status === 'done') {
      const filesArray = files.map((file) => JSON.parse(file.xhr?.response || null));
      onUploadFile(filesArray.filter(Boolean), index);
      fileWithMeta.remove();
    }
  };

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className="dropzoneWrapper">
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={Input}
          inputWithFilesContent={Input}
          LayoutComponent={(props) => (
            <Layout
              uploadedFiles={uploadedFiles}
              onUploadFile={onUploadFile}
              onDeleteFile={onDeleteFile}
              index={index}
              {...props}
            />
          )}
          PreviewComponent={Preview}
          maxSizeBytes={MAX_FILE_SIZE}
          accept={FILE_MIME_TYPES}
        />
      </section>
    </>
  );
}
