import { createSelector } from 'reselect';

const assembledTentData = (state) => state.assembleBOMData;

export const selectAssembleListData = () =>
  createSelector([assembledTentData], (state) => state.assembleList);

export const selectLocationListData = () =>
  createSelector([assembledTentData], (state) => state.locationList);

export const selectLooseItemConfigurationsData = () =>
  createSelector([assembledTentData], (state) => state.looseItemConfigurationList);

export const selectConfigOptionsData = () =>
  createSelector([assembledTentData], (state) => state.configOptions);

export const selectLocationListFilterData = () =>
  createSelector([assembledTentData], (state) => state.locationListFilter);

export const selectStatusListFilterData = () =>
  createSelector([assembledTentData], (state) => state.statusListFilter);

export const selectConfigurationListFilterData = () =>
  createSelector([assembledTentData], (state) => state.configurationListFilter);

export const selectAssembledTentsFilter = () =>
  createSelector([assembledTentData], (state) => state.assembleFilter);

export const selectSingleAssembleData = () =>
  createSelector([assembledTentData], (state) => state.currentAssemble);
