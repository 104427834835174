import React from 'react';
import styles from './TicketDetails.module.scss';

import Wrapper from './Wrapper';
import { General, Description, NecessaryItems, Files, Remarks } from './components';

export default function TicketDetailed() {
  return (
    <Wrapper>
      <div className={styles.content}>
        <div className={styles.content__info}>
          <General />
          <Description />
          <NecessaryItems />
        </div>
        <Files />
        <Remarks />
      </div>
    </Wrapper>
  );
}
