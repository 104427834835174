import React, { useEffect } from 'react';
import styles from './TurnoverStatistics.module.scss';

import Separator from 'components/Separator/Separator';
import { TransparentButton } from 'components/Buttons';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';

import TurnoverStatisticsTable from './Table';
import TurnoverStatisticsChart from './Chart';
import TurnoverStatisticsFilter from './Filter';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { fileDownload } from 'helpers/AppHelpers';

import csvIcon from 'assets/images/csvIcon.svg';

export default function TurnoverStatistics() {
  const { turnoverStatistics, tabConfig } = useTicketsDashboardSelector();
  const { getTurnoverStatisticCsvResourceAction, clearTabStateAction } =
    useTicketsDashboardActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'turnoverStatistics' });
    };
  }, []);

  const getFilterData = (sectionId) => tabConfig?.items?.find(({ id }) => id === sectionId) || {};

  const shouldDisplayChart = (data) => data?.durations?.some(({ total }) => total !== 0);

  const downloadCsv = (dashboardId) => {
    getTurnoverStatisticCsvResourceAction({ dashboardId }).then((res) => fileDownload(res));
  };

  return (
    <DashboardWrapper>
      {!!turnoverStatistics?.length && (
        <div className={styles.statistics}>
          {turnoverStatistics.map((sectionData, index) => (
            <div key={sectionData.id} className={styles.statistics__dashboard}>
              <SectionHeader title={sectionData.name || ''} />
              <TurnoverStatisticsFilter filter={getFilterData(sectionData.id)} />
              <TransparentButton
                label="Download CSV File"
                className={styles.csvButton}
                onClick={() => downloadCsv(sectionData.id)}>
                <img src={csvIcon} alt="" />
              </TransparentButton>
              <div className={styles.statistics__dashboard_info}>
                <TurnoverStatisticsTable data={sectionData} />
                {shouldDisplayChart(sectionData) && <TurnoverStatisticsChart data={sectionData} />}
              </div>
              {turnoverStatistics.length > 1 && index !== turnoverStatistics.length - 1 && (
                <Separator />
              )}
            </div>
          ))}
        </div>
      )}
    </DashboardWrapper>
  );
}
