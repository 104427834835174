import React, { useEffect, useState } from 'react';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectSingleConfigurationData } from '../selectors';
import {
  clearConfigurationState,
  deleteConfiguration,
  getSingleConfiguration
} from 'actions/looseItemConfigurationActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  BOM_CONFIGURATION_EDIT_PATH,
  BOM_CONFIGURATIONS_LIST_PATH
} from 'constants/routeConstants';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  DELETE_TENT_CONFIGURATION,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_TYPE
} from 'constants/dialogPopupsData';
import { fileDownload, isAdminUser, isOperationsManagerUser } from 'helpers/AppHelpers';
import { selectUserConfig } from 'pages/commonSelectors';
import { useMobileViewport } from 'hooks/useMobileViewport';
import ToggleBar from 'components/ToggleBars/ToggleBar';

import LooseItemsTable from './LooseItemsTable/LooseItemsTable';
import AssetPrefixesTable from './AssetPrefixesTable/AssetPrefixesTable';

import styles from './BOMConfigurationDetailed.module.scss';

function BOMConfigurationDetailed({
  userConfig,
  currentConfiguration,
  getConfigurationAction,
  deleteConfigurationAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const [currentTab, setCurrentTab] = useState('loose_items');

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'loose_items', label: 'Loose Items' },
    { id: 2, value: 'assets', label: 'Assets' }
  ];

  const onClickTab = (tab) => {
    setCurrentTab(tab);
  };

  const { userRole } = userConfig;

  useEffect(() => {
    if (id) {
      getConfigurationAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (currentConfiguration?.id) {
      setBreadcrumbs([{ name: currentConfiguration.name }]);
    }
    return () => {
      if (currentConfiguration?.id) {
        clearStateAction();
      }
    };
  }, [currentConfiguration]);

  const onAgree = () => {
    switch (dialogModalData.type) {
      case DOWNLOAD_FILE_TYPE:
        setDialogModalData({ isOpened: false });
        fileDownload(selectedFile);
        break;
      default:
        deleteConfigurationAction(currentConfiguration.id);
        break;
    }
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const getFiles =
    currentConfiguration?.resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  const onFileDownload = (file) => {
    setDialogModalData({ ...DOWNLOAD_FILE, isOpened: true });
    setSelectedFile(file);
  };

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {currentConfiguration?.id === +id && (
        <div>
          {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
          <section className={styles.headerBlock}>
            {!isMobile && <h1>{currentConfiguration.name}</h1>}
            <div className={styles.headerBlock__controls}>
              {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && (
                <button
                  onClick={() =>
                    history.push(`${BOM_CONFIGURATION_EDIT_PATH}/${currentConfiguration.id}`)
                  }>
                  <CreateIcon />
                  {!isMobile && <span>Edit configuration</span>}
                </button>
              )}
              {isAdminUser(userRole) && (
                <button
                  onClick={() =>
                    setDialogModalData({ ...DELETE_TENT_CONFIGURATION, isOpened: true })
                  }>
                  <DeleteIcon />
                  {!isMobile && <span>Remove configuration</span>}
                </button>
              )}
              {isMobile && (
                <button onClick={() => history.push(BOM_CONFIGURATIONS_LIST_PATH)}>
                  <ChevronLeftIcon />
                </button>
              )}
            </div>
          </section>
          <section className={styles.contentWrapper}>
            {isMobile && <h1>{currentConfiguration.name}</h1>}
            <div className={styles.descriptionBlock}>{currentConfiguration?.description || ''}</div>
            <div className={styles.filesBlock}>
              <h1>Files</h1>
              <div className={styles.filesWrapper}>
                {getFiles.length
                  ? getFiles.map((file) => (
                      <p
                        key={file.id}
                        onClick={() => onFileDownload(file)}
                        className={styles.fileName}>
                        {file.name}
                      </p>
                    ))
                  : ''}
              </div>
            </div>
            <ToggleBar
              buttons={TOGGLE_BUTTONS}
              onChange={onClickTab}
              selectedTab={currentTab}
              classes={styles.toggle}
            />

            {currentTab === 'loose_items' ? (
              <LooseItemsTable currentConfiguration={currentConfiguration} />
            ) : (
              <AssetPrefixesTable currentConfiguration={currentConfiguration} />
            )}
          </section>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentConfiguration: selectSingleConfigurationData(),
  userConfig: selectUserConfig()
});

const mapDispatchToProps = {
  getConfigurationAction: getSingleConfiguration,
  deleteConfigurationAction: deleteConfiguration,
  clearStateAction: clearConfigurationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BOMConfigurationDetailed);
