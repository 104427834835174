import React from 'react';
import styles from './FormLabel.module.scss';

import clsx from 'clsx';

export default function FormLabel({ required, bold, children }) {
  return (
    <label className={clsx(styles.formLabel, bold && styles.bold)}>
      {children}
      {required && '*'}
    </label>
  );
}
