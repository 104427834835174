import React from 'react';
import styles from './components.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { useFormContext } from 'react-hook-form';
import { useLocationSelector } from 'hooks/Location';

import { statusTypes } from './helpers';

export default function LocationGeneralDetails() {
  const { setValue, clearErrors, formState } = useFormContext();
  const { errors } = formState;

  const { siteTypes } = useLocationSelector();

  const selectItem = ({ name, value }) => {
    setValue(name, value);
    name === 'status' && setValue('active', value.booleanValue);
    errors?.[name]?.message && clearErrors(name);
  };

  return (
    <div className={styles.details}>
      <div className={styles.details__block}>
        <div className={styles.details__block_field}>
          <label>Site code*</label>
          <FormInputText name="siteCode" options={{ max: 500 }} />
        </div>
        <div className={styles.details__block_field}>
          <label>Site type*</label>
          <FormSelectInput name="siteType" onSelect={selectItem} options={siteTypes || []} />
        </div>
        <div className={styles.details__block_field}>
          <label>Status*</label>
          <FormSelectInput name="status" options={statusTypes} onSelect={selectItem} />
        </div>
        <div className={styles.details__block_field}>
          <label>POC Name</label>
          <FormInputText name="pocName" options={{ max: 40 }} />
        </div>
        <div className={styles.details__block_field}>
          <label>POC Number</label>
          <FormInputText name="pocNumber" options={{ max: 40 }} />
        </div>
      </div>
      <div className={styles.details__block}>
        <div className={styles.details__block_field}>
          <label>Job Number*</label>
          <FormInputText name="locationJobNumber" options={{ max: 500 }} />
        </div>
        <div className={styles.details__block_field}>
          <label>Description</label>
          <FormInputText name="description" options={{ max: 255 }} />
        </div>
        <div className={styles.details__block_checkbox}>
          <label>Base Camp?</label>
          <FormCheckbox name="isBaseCamp" />
        </div>
        <div className={styles.details__block_checkbox}>
          <label>Food Service?</label>
          <FormCheckbox name="hasFoodService" />
        </div>
      </div>
    </div>
  );
}
