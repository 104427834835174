import React from 'react';
import styles from './RequestOrderButtons.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';

export default function RemoveEntityButton({ onClick }) {
  const handleClick = () => onClick();

  return (
    <Button className={styles.remove_button} onClick={handleClick}>
      <DeleteIcon />
      <label>Delete</label>
    </Button>
  );
}
