import { useSelector } from 'react-redux';

import {
  selectBatchAssetUpdatesSelectedTab,
  selectBatchAssetUpdatesFormState,
  selectBatchAssetUpdatesCreationFormData
} from 'pages/BatchUpdates/Asset/selectors';

export function useBatchAssetUpdatesSelector() {
  const selectedTab = useSelector(selectBatchAssetUpdatesSelectedTab());

  const creationForm = useSelector(selectBatchAssetUpdatesCreationFormData());

  const formState = useSelector(selectBatchAssetUpdatesFormState());

  return {
    creationForm,
    selectedTab,
    formState
  };
}
