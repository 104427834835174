import React from 'react';
import styles from './form.module.scss';

import { FormControlLabel, IconButton, MenuItem } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import StyledRadio from 'components/StyledComponents/StyledRadio';

export default function SkillOption({
  option,
  setSelectOpen,
  onOptionClick,
  isSelected,
  onSelect,
  multiple,
  isOptionEditable
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    setSelectOpen(false);
    onOptionClick(option);
  };

  const isEditable = isOptionEditable ? isOptionEditable(option) : true;

  return (
    <MenuItem value={option.id} key={option.id} onClick={() => onSelect(option)}>
      {multiple ? (
        <CustomCheckbox name="active" value={isSelected} />
      ) : (
        <FormControlLabel
          checked={isSelected}
          classes={{ root: styles.radio }}
          control={<StyledRadio />}
          label=""
        />
      )}
      <div className={styles.option}>
        <span>{option?.name || ''}</span>
        {isEditable && (
          <IconButton className={styles.edit_option_button} onClick={handleClick}>
            <CreateIcon />
          </IconButton>
        )}
      </div>
    </MenuItem>
  );
}
