import { useDispatch } from 'react-redux';

import {
  getTemplates,
  getReportTypes,
  setFilter,
  removeTemplate,
  copyTemplate,
  clearState
} from 'actions/reportTemplatesActions';

export default function useReportTemplatesActions() {
  const dispatch = useDispatch();

  const getTemplatesAction = (q) => dispatch(getTemplates(q));

  const getReportTypesAction = () => dispatch(getReportTypes());
  const setFilterAction = (data) => dispatch(setFilter(data));

  const removeTemplateAction = (q) => dispatch(removeTemplate(q));

  const copyTemplateAction = (id) => dispatch(copyTemplate(id));

  const clearStateAction = () => dispatch(clearState());

  return {
    getTemplatesAction,
    getReportTypesAction,
    setFilterAction,
    removeTemplateAction,
    copyTemplateAction,
    clearStateAction
  };
}
