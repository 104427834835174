import { useSelector } from 'react-redux';

import {
  selectTabData,
  selectTabConfigData,
  selectFilterCriteriaData,
  selectRRDashboardData,
  selectLevelsDashboardData,
  selectSnapshotDashboardData
} from 'pages/TeamSchedule/Dashboard/selectors.js';

export function useTeamMemberDashboardSelector() {
  const tab = useSelector(selectTabData());
  const tabConfig = useSelector(selectTabConfigData());

  const filterCriteria = useSelector(selectFilterCriteriaData());

  const RRDashboard = useSelector(selectRRDashboardData());
  const levelsDashboard = useSelector(selectLevelsDashboardData());
  const snapshotDashboard = useSelector(selectSnapshotDashboardData());

  return {
    tab,
    tabConfig,
    filterCriteria,
    RRDashboard,
    levelsDashboard,
    snapshotDashboard
  };
}
