import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import styles from './Header.module.scss';
import React from 'react';
import { useMobileViewport } from 'hooks/useMobileViewport';
import clsx from 'clsx';

export default function DetailsHeader({
  breadcrumbs,
  title,
  BreadCrumbsComponent,
  TitleComponent,
  breakWordOnly,
  children
}) {
  const isMobile = useMobileViewport();

  return (
    <div className={clsx(styles.header, breakWordOnly && styles.breakWord)}>
      {BreadCrumbsComponent
        ? BreadCrumbsComponent
        : !isMobile && !!breadcrumbs?.length && <BreadcrumbsNav itemsArray={breadcrumbs} />}
      <div className={styles.header__block}>
        {!isMobile && (
          <>{TitleComponent ? TitleComponent : <h1 className={styles.header__title}>{title}</h1>}</>
        )}
        <div className={styles.header__block_controls}>{children}</div>
      </div>
      {isMobile && (
        <>{TitleComponent ? TitleComponent : <h1 className={styles.header__title}>{title}</h1>}</>
      )}
    </div>
  );
}
