import React from 'react';
import styles from './components.module.scss';
import Subtitle from './Subtitle';
import { usePrefixSelector } from 'hooks/Prefix';

export default function DescriptionDetails() {
  const { currentPrefix } = usePrefixSelector();
  const { description } = currentPrefix;

  return (
    <section className={styles.description}>
      <Subtitle>Description</Subtitle>
      <label>{description}</label>
    </section>
  );
}
