import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import FormInputRadio from 'components/FormComponents/FormInputRadio/FormInputRadio';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { useFormContext } from 'react-hook-form';
import { radioOptions } from './formConfigs';

function VehicleInstructionsForm() {
  const { watch, formState } = useFormContext();
  const { errors } = formState;
  const vehicleTypeWatcher = watch('typeOfVehicle');

  const isFormInvalid = errors?.otherVehicle?.message;

  return (
    <AccordionWrapper title="Vehicle & Instructions" isFormInvalid={isFormInvalid}>
      <div className={styles.vehicle}>
        <div className={styles.radio}>
          <label>Type of vehicle*</label>
          <div className={styles.radio__inputs}>
            <FormInputRadio
              name="typeOfVehicle"
              options={radioOptions}
              relatedFieldNames={['otherVehicle']}
            />
            {vehicleTypeWatcher?.displayName === 'Other' && (
              <FormInputText
                name="otherVehicle"
                options={{ max: 500, rule: allKeyboardLatSymbols }}
              />
            )}
          </div>
        </div>
        <div className={styles.record}>
          <label>Special instructions</label>
          <div className={styles.record__textarea}>
            <FormTextArea
              name="specialInstructions"
              options={{ rule: allKeyboardLatSymbols, max: 1000 }}
            />
          </div>
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default VehicleInstructionsForm;
