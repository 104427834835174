import React, { useEffect } from 'react';

import CreationForm from './CreationForm';
import Preview from './Preview';

import {
  useCreateAssetsBatchActions,
  useCreateAssetsBatchSelector
} from 'hooks/CreateBatchOfAssets';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { ASSETS_LIST_PATH } from 'constants/routeConstants';

export default function CreateBatchOfAssets() {
  const history = useHistory();
  const isMobile = useMobileViewport();

  const { isCreationOpened } = useCreateAssetsBatchSelector();
  const { clearStateAction } = useCreateAssetsBatchActions();

  useEffect(() => {
    if (isMobile) {
      history.push(ASSETS_LIST_PATH);
    }
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return isCreationOpened ? <CreationForm /> : <Preview />;
}
