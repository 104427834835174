import React, { useState } from 'react';
import styles from './components.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useMobileViewport } from 'hooks/useMobileViewport';

function AccordionWrapper({ title, isFormInvalid, children }) {
  const isMobile = useMobileViewport();
  const [open, setOpen] = useState(false);

  const handleIconClick = () => setOpen(!open);

  return (
    <div className={styles.accordion}>
      <Accordion expanded={isMobile ? open : true}>
        {isMobile && (
          <AccordionSummary
            classes={{ root: styles.accordion__summary }}
            expandIcon={<ExpandMoreIcon onClick={handleIconClick} />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <h2>{title}</h2>
            {isFormInvalid && <ErrorOutlineIcon />}
          </AccordionSummary>
        )}
        <AccordionDetails classes={{ root: styles.accordion__details }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccordionWrapper;
