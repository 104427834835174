import React from 'react';
import styles from './CustomCheckbox.module.scss';
import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';

export default function CustomCheckbox({
  name,
  value,
  onChange,
  isDisabled,
  makeRed,
  small,
  sendEvent
}) {
  const handleClick = (e) => {
    if (!onChange) return;

    if (sendEvent) {
      onChange(e);
    } else {
      onChange(name, !value, e);
    }
  };

  return (
    <Checkbox
      classes={{
        root: clsx(styles.checkbox, small && styles.small),
        checked: clsx(styles.checked, makeRed && styles.red)
      }}
      className={clsx(!!isDisabled && styles.disabled)}
      onChange={handleClick}
      checked={value}
    />
  );
}
