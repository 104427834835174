import { createSelector } from 'reselect';

const tentConfigurationData = (state) => state.tentConfigurationData;

export const selectConfigurationsData = () =>
  createSelector([tentConfigurationData], (state) => state.tentConfigurationList);

export const selectSingleConfigurationData = () =>
  createSelector([tentConfigurationData], (state) => state.currentConfiguration);

export const selectSearchValueData = () =>
  createSelector([tentConfigurationData], (state) => state.searchValue);

export const selectConfigurationCreationFormData = () =>
  createSelector([tentConfigurationData], (state) => state.creationForm);
