import React from 'react';
import styles from './components.module.scss';
import { MainButton } from 'components/StyledComponents';

export default function PopupActions({ onCancel, onCreate, isCreateButtonDisabled, isEditMode }) {
  const handleCancelClick = () => onCancel();
  const handleAddClick = () => onCreate();

  return (
    <div className={styles.controls}>
      <MainButton text="Cancel" action={handleCancelClick} type="secondary" size="popup" />
      <MainButton
        text={isEditMode ? 'Apply' : 'Add'}
        action={handleAddClick}
        type="primary"
        size="popup"
        isDisabled={isCreateButtonDisabled}
      />
    </div>
  );
}
