import { getRandomId } from 'helpers/AppHelpers';

const mapItemToChip = (data, name) => {
  return data.map((el) => {
    return { displayName: el[name] || '', chipId: getRandomId() };
  });
};

export const generateChips = (data) => {
  const { assets, locations, prefixes, rootCauses, ticketTypes, severities } = data;

  let chipsArray = [];

  if (assets?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(assets, 'drCode'));
  }
  if (locations?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(locations, 'siteCode'));
  }
  if (prefixes?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(prefixes, 'prefix'));
  }
  if (rootCauses?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(rootCauses, 'name'));
  }
  if (ticketTypes?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(ticketTypes, 'name'));
  }
  if (severities?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(severities, 'displayName'));
  }

  const openedObj = {
    chipId: getRandomId(),
    displayName: data?.opened ? 'Show Opened Tickets' : 'Show Closed Tickets'
  };
  chipsArray.push(openedObj);

  return chipsArray;
};

export const radios = [
  { id: 1, displayName: 'Show Opened Tickets', name: 'Show Opened Tickets', value: true },
  { id: 2, displayName: 'Show Closed Tickets', name: 'Show Closed Tickets', value: false }
];
