import React from 'react';
import styles from './Details.module.scss';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';
import DetailedRow from 'components/DetailsComponents/DetailedRow';
import { formatDate } from 'helpers/AppHelpers';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';

export default function GeneralDetails() {
  const {
    name,
    jobNumber,
    contractNumber,
    contractDate,
    active,
    onSiteReport,
    description,
    mobilizationDate,
    operationalDateFrom,
    operationalDateTo,
    demobilizationDate,
    customer,
    customerPoc,
    customerPhone,
    customerEmail,
    alternatePoc,
    alternatePhone,
    alternateEmail
  } = useSelector(selectSingleProjectData());

  const getOperationalDatesValue = () => {
    if (operationalDateFrom && operationalDateTo) {
      return `${formatDate(operationalDateFrom)} to ${formatDate(operationalDateTo)}`;
    } else {
      return `${operationalDateFrom ? formatDate(operationalDateFrom) : ''}${
        operationalDateTo ? 'To ' + formatDate(operationalDateTo) : ''
      }`;
    }
  };

  const details = [
    [
      { id: 1, label: 'Project Name', value: name || '' },
      { id: 2, label: 'Project Number', value: jobNumber || '' },
      { id: 3, label: 'Contract №', value: contractNumber || '' },
      { id: 4, label: 'Contract Date', value: contractDate ? formatDate(contractDate) : '' },
      { id: 5, label: 'Is active?', value: active, type: 'checkbox' }
    ],
    [
      { id: 6, label: 'Description', value: description || '' },
      {
        id: 7,
        label: 'Mobilization Date',
        value: mobilizationDate ? formatDate(mobilizationDate) : ''
      },
      { id: 8, label: 'Operational Dates', value: getOperationalDatesValue() || '' },
      {
        id: 9,
        label: 'Demobilization Dates',
        value: demobilizationDate ? formatDate(demobilizationDate) : ''
      },
      { id: 10, label: 'Display on daily site report form', value: onSiteReport, type: 'checkbox' }
    ],
    [
      { id: 11, label: 'Customer', value: customer || '' },
      { id: 12, label: 'POC', value: customerPoc || '' },
      { id: 13, label: 'Customer Phone', value: customerPhone || '' },
      { id: 14, label: 'Customer Email', value: customerEmail || '' }
    ],
    [
      { id: 15, label: 'Alternative POC', value: alternatePoc || '' },
      { id: 16, label: 'Phone', value: alternatePhone || '' },
      { id: 17, label: 'Email', value: alternateEmail || '' }
    ]
  ];

  return (
    <section className={styles.general}>
      {details.map((block, index) => (
        <div className={styles.general__block} key={index}>
          {block.map(({ id, label, value, type }) => (
            <React.Fragment key={id}>
              {type === 'checkbox' ? (
                <div className={styles.general__block_checkbox}>
                  <label>{label}</label>
                  <StyledCheckbox disabled={true} checked={value} />
                </div>
              ) : (
                <DetailedRow label={label} value={value} disableFlex />
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
    </section>
  );
}
