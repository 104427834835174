import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectLeaveListAssetStatuses, selectLocationsData } from '../../../selectors';
import { getLeaveListAssetStatuses, getLocations } from 'actions/leaveListActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './LeaveListDetailedEditor.module.scss';
import { Fade, IconButton, Paper, Popper } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { enrichNameToId } from 'helpers/AppHelpers';
import { MainButton } from 'components/StyledComponents';
import SelectInput from 'components/SelectInput/SelectInput';

function LeaveListDetailedEditor({
  isMobile,
  locationList,
  getLocationListAction,
  statusList,
  getStatusListAction,
  onApply,
  open,
  setOpen,
  isOpenStatus
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState({});
  const [key, setKey] = useState(new Date().getTime() + Math.random());

  const handleOpen = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
    setValues({});
  };

  const handleApply = () => {
    setOpen(!open);
    onApply({ ...values });
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const onLocationSelect = (name, value) => {
    setKey(new Date().getTime() + Math.random());
    setValues({ ...values, [name]: value });
  };

  const onStatusSelect = (selectedItem) => {
    setValues({ ...values, [selectedItem.name]: selectedItem.value });
  };

  useEffect(() => {
      getLocationListAction({
        filters: {
          active: true,
          includeUserCheck: true
        }
      });
  }, []);

  useEffect(() => {
    if (!statusList?.length) {
      getStatusListAction();
    }
  }, [statusList]);

  return (
    <div className={styles.filterWrapper}>
      <IconButton
        className={`${styles.filterButton} ${open ? styles.filterButton__opened : ''}`}
        onClick={handleOpen}
        component="span">
        {!isMobile ? (
          <CreateIcon />
        ) : isOpenStatus ? (
          'change location'
        ) : (
          'change location and status'
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && (
                <section className={styles.headerWrapper}>
                  <div className={styles.pageHeader}>
                    <h1>Change assets</h1>
                    <button onClick={handleClose}>
                      <ChevronLeftIcon />
                    </button>
                  </div>
                </section>
              )}
              <section className={styles.formColumn}>
                {!isOpenStatus && (
                  <section className={styles.formCell}>
                    <label>Status</label>
                    <div className={styles.inputWrapper}>
                      <SelectInput
                        name="status"
                        value={values?.status?.id || ''}
                        menuItems={enrichNameToId(statusList) || []}
                        onSelect={onStatusSelect}
                      />
                    </div>
                  </section>
                )}
                <section className={styles.formCell}>
                  <label>Destination</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="location"
                      defaultValue={values?.location?.id || ''}
                      options={locationList || []}
                      onFilterSelect={onLocationSelect}
                      multiselect={false}
                      refreshKey={key}
                    />
                  </div>
                </section>
              </section>
              <section className={styles.buttonWrapper}>
                <MainButton
                  text="Cancel"
                  action={handleClose}
                  type="secondary"
                  size="filter_popup"
                />
                <MainButton text="Apply" action={handleApply} type="primary" size="filter_popup" />
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  locationList: selectLocationsData(),
  statusList: selectLeaveListAssetStatuses()
});

const mapDispatchToProps = {
  getLocationListAction: getLocations,
  getStatusListAction: getLeaveListAssetStatuses
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LeaveListDetailedEditor);
