import api from '../api';
import {
  SET_USER_LOGS_FILTER,
  CLEAR_USER_LOGS_STATE,
  SET_LOAD_MORE_FLAG
} from '../constants/reduceConstants';

export const getUserLogs = (query) => (dispatch) =>
  api.userLogs
    .getUserLogsApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getUsers = (query) => () =>
  api.userLogs
    .getUsersApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getHistoryEventTypes = () => () =>
  api.userLogs
    .getHistoryEventTypesApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_LOGS_FILTER, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_LOGS_STATE });
};
