import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadMoreFlag, selectUserConfig } from 'pages/commonSelectors';
import { IconButton, Select, MenuItem } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from './TablePaginationRefactored.module.scss';
import clsx from 'clsx';
import { setTopPosition, updateUserConfig } from 'actions/commonActions';
import { isAllParameterEnabled, getPagesLimit } from 'helpers/AppHelpers';

export default function TablePaginationRefactored({
  isMobile,
  rows,
  currentPage,
  totalPages,
  onPageSelect,
  selectAllOption,
  pageLimit,
  hide,
  hideLimitSelector,
  tableListParameter,
  getTableData,
  withOptionOneHundred = false,
  tableRef
}) {
  const loadMoreFlag = useSelector(selectLoadMoreFlag());
  const userConfig = useSelector(selectUserConfig());
  const dispatch = useDispatch();

  const isAutoloadEnabled = () =>
    isAllParameterEnabled(userConfig, tableListParameter) &&
    getPagesLimit(userConfig, tableListParameter, isMobile) === 100 &&
    !isMobile;

  const rowsPerPageOptions = [
    10,
    25,
    50,
    withOptionOneHundred && 100,
    selectAllOption && !isMobile ? 'All' : null
  ].filter(Boolean);

  const pagesArray = Array(+totalPages || 1)
    .fill(null)
    .map((_, i) => i + 1);

  let start,
    end,
    resultsArray = [];
  const pagesCutOff = isMobile ? 1 : 3,
    ceiling = Math.ceil(pagesCutOff / 2),
    floor = Math.floor(pagesCutOff / 2);
  const minPagesBeforeCut = isMobile ? 4 : 6;
  const minUncutPagesCount = isMobile ? 3 : 5;

  const buildPagination = () => {
    if (pagesArray.length < pagesCutOff) {
      start = 0;
      end = pagesArray.length;
    } else if (currentPage >= 1 && currentPage <= ceiling) {
      start = 0;
      end = pagesCutOff;
    } else if (currentPage + floor >= pagesArray.length) {
      start = pagesArray.length - pagesCutOff;
      end = pagesArray.length;
    } else {
      start = currentPage - ceiling;
      end = currentPage + floor;
    }

    if (totalPages > minPagesBeforeCut) {
      if (start >= 0 && start <= 1) {
        resultsArray.push(...pagesArray.slice(0, minUncutPagesCount));
      } else {
        resultsArray.push(pagesArray[0], '...');
      }

      if (end >= totalPages - 2 && end <= totalPages) {
        resultsArray.push(...pagesArray.slice(totalPages - minUncutPagesCount, totalPages));
      } else if (start >= 2) {
        resultsArray.push(
          ...pagesArray.slice(start, end),
          '...',
          pagesArray[pagesArray.length - 1]
        );
      } else {
        resultsArray.push('...', pagesArray[pagesArray.length - 1]);
      }
    } else {
      resultsArray.push(...pagesArray.slice(0, minPagesBeforeCut));
    }
  };

  const allNotSelected = () => !isAutoloadEnabled();
  const showLimitSelector = () =>
    !hideLimitSelector && !(rows <= 10 && currentPage === 1 && totalPages === 1);
  const showPageSelector = () => totalPages !== 1 && allNotSelected();

  buildPagination();

  useEffect(() => {
    resultsArray = [];
    buildPagination();
  }, [currentPage]);

  useEffect(() => {
    if (isAutoloadEnabled() && loadMoreFlag && currentPage < totalPages) {
      handlePageSelect(currentPage + 1, 'AUTOLOAD');
    }
  }, [loadMoreFlag]);

  const handleScrollToTheTop = () => {
    if (!isAutoloadEnabled()) {
      const elemRectData = tableRef.current?.getBoundingClientRect();
      dispatch(setTopPosition(elemRectData?.y + (isMobile ? -100 : 0)));
    }
  };

  const handleLimitChange = (event) => {
    const limit = event.target.value;
    const userConfigCopy = { ...userConfig };
    const tableIndex = userConfigCopy['tables'].findIndex(
      ({ name }) => name === tableListParameter
    );
    userConfigCopy['tables'][tableIndex].fetchRowsCount = limit === 'All' ? 100 : limit;
    userConfigCopy['tables'][tableIndex].isAll = limit === 'All';
    dispatch(updateUserConfig(userConfigCopy)).then(() => {
      if (pageLimit === 100 && limit === 'All') {
        handlePageSelect(currentPage === 1 ? currentPage + 1 : 1);
      } else {
        getTableData();
      }
    });

    if (limit !== 'All' || (limit === 'All' && currentPage !== 1)) {
      const elemRectData = tableRef.current?.getBoundingClientRect();
      dispatch(setTopPosition(elemRectData?.y + (isMobile ? -100 : 0)));
    }
  };

  const handlePageSelect = (page, param) => {
    onPageSelect(page, param);
    handleScrollToTheTop();
  };

  return (
    <>
      {!hide && (
        <section
          className={clsx(
            styles.paginationWrapper,
            !allNotSelected() && showLimitSelector() && styles.fixedPosition
          )}>
          {showLimitSelector() && (
            <section
              className={clsx(
                styles.pageLimitContainer,
                showPageSelector() && !isMobile && styles.rightSpace
              )}>
              <span>Rows per page:</span>
              <Select
                classes={{ root: styles.selectInput }}
                value={isAutoloadEnabled() ? 'All' : pageLimit || ''}
                onChange={handleLimitChange}
                variant="outlined">
                {rowsPerPageOptions.length &&
                  rowsPerPageOptions.map((option, ind) => (
                    <MenuItem
                      classes={{
                        root: styles.selectInput__menu,
                        icon: styles.selectInput__icon
                      }}
                      value={option}
                      key={`${ind}_${option}`}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </section>
          )}
          {showPageSelector() && (
            <section className={styles.paginationContainer}>
              <IconButton
                className={styles.pageButton}
                onClick={() => handlePageSelect(currentPage > 1 ? currentPage - 1 : 1)}
                component="span">
                <NavigateBeforeIcon />
              </IconButton>
              {resultsArray.map((page, index) => (
                <React.Fragment key={index}>
                  {typeof page !== 'string' ? (
                    <IconButton
                      className={clsx(
                        styles.pageButton,
                        page === currentPage && styles.active,
                        styles.pageButton__number
                      )}
                      onClick={() => handlePageSelect(page)}>
                      {page}
                    </IconButton>
                  ) : (
                    <IconButton className={styles.ellipsisButton}>{page}</IconButton>
                  )}
                  {index < resultsArray.length - 1 && <div className={styles.pageDivider}></div>}
                </React.Fragment>
              ))}
              <IconButton
                className={styles.pageButton}
                onClick={() =>
                  handlePageSelect(currentPage < totalPages ? currentPage + 1 : totalPages)
                }
                component="span">
                <NavigateNextIcon />
              </IconButton>
            </section>
          )}
        </section>
      )}
    </>
  );
}
