import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectInput from 'components/SelectInput/SelectInput';
import { MainButton } from 'components/StyledComponents/';
import { Checkbox, TextField } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

export default function TemplatesReportTemplate({
  index,
  template,
  presetData,
  dragIsActive,
  onDrag,
  onAdd,
  onDelete,
  published
}) {
  const { data } = template;
  const { active } = template.data;
  const [templates, setTemplates] = useState([...presetData]);

  useEffect(() => {
    if (presetData?.length) {
      setTemplates(presetData);
    } else {
      active && onDelete(index);
    }
  }, [presetData]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(templates, result.source.index, result.destination.index);
    setTemplates(items);
  };

  const onUpdateOption = (i, data) => {
    const optionsArr = [...templates];
    optionsArr[i] = data;
    setTemplates(optionsArr);
  };

  const isAddDisabled = () => {
    return !templates.some(({ selected }) => selected);
  };

  const addClickHandle = () => {
    onAdd(
      index,
      templates.filter(({ selected }) => selected)
    );
  };

  return (
    <section className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Templates</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <section className={styles.selectOptions}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`droppableOptions-${index}`}>
                  {(provided) => (
                    <div
                      className={styles.optionsBlock}
                      ref={provided.innerRef}
                      {...provided.droppableProps}>
                      {!!templates?.length &&
                        templates.map((option, index) => (
                          <Draggable
                            key={`${option.id}-${index}`}
                            draggableId={`${option.id}-${index}`}
                            index={index}>
                            {(provided) => (
                              <section
                                key={option.id}
                                className={styles.selectOptions__option}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <MenuIcon className={styles.dragIcon} />
                                <SelectOption
                                  key={index}
                                  index={index}
                                  option={option}
                                  onUpdate={onUpdateOption}
                                />
                              </section>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </section>
          </div>

          <div className={styles.activeTemplate__controls}>
            <div></div>
            <MainButton
              text="Add"
              action={addClickHandle}
              type="primary"
              size="popup"
              isDisabled={isAddDisabled()}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div
              className={`${styles.inactiveTemplate__controls} ${
                dragIsActive ? styles.hidden : ''
              }`}>
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            {data.templates?.length &&
              data.templates
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item.type) {
                        case 'Title':
                          return (
                            <h1 className={styles.templateTitle}>
                              {item.text}
                              {data.required && '*'}
                            </h1>
                          );
                        case 'Subtitle':
                          return (
                            <label className={styles.templateSubtext}>
                              {item.text}
                              {data.required && data.templates.length === 2 && '*'}
                            </label>
                          );
                        case 'Textfield':
                          return (
                            <section className={styles.formCell}>
                              {item.label && <label>{item.label}</label>}
                              <div className={styles.inputWrapper}>
                                <TextField
                                  className={styles.textInput}
                                  variant="outlined"
                                  value={''}
                                  disabled
                                />
                              </div>
                            </section>
                          );
                        case 'Textarea':
                          return (
                            <div className={styles.textareaWrapper}>
                              <div className={styles.multiTextInput}>
                                <TextField
                                  multiline
                                  rows={2}
                                  variant="outlined"
                                  value={''}
                                  disabled
                                />
                                <span>0/{[6, 7, 8, 9].includes(item.id) ? 65000 : 2000}</span>
                              </div>
                            </div>
                          );
                        case 'Select':
                          return (
                            <section className={styles.formCell}>
                              {item.label && <label>{item.label}</label>}
                              <div className={styles.inputWrapper}>
                                <SelectInput value={''} menuItems={[]} disabled={true} />
                              </div>
                            </section>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </React.Fragment>
                ))}
          </div>
        </section>
      )}
    </section>
  );
}

function SelectOption({ index, option, onUpdate }) {
  const selectTemplateCheck = () => {
    onUpdate(index, {
      ...option,
      selected: !option?.selected
    });
  };
  return (
    <>
      <section className={styles.checkboxWrapper}>
        <Checkbox
          classes={{ root: styles.checkbox, checked: styles.checked }}
          onChange={selectTemplateCheck}
          value={option?.selected}
          checked={!!option?.selected}
        />
      </section>
      <TextField
        placeholder="Enter option text"
        name="text"
        className={styles.textInput}
        variant="outlined"
        value={option.data?.text || ''}
        disabled={true}
      />
    </>
  );
}
