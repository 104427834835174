import {
  isAdminUser,
  isDecisionMakerUser,
  isOperationsManagerUser,
  isResponsibleForAccountUser
} from '../../helpers/AppHelpers';

export const getParams = (entityName) => {
  if (entityName === 'Loose Item') {
    return {
      name: 'requestOrderLooseItems',
      itemName: 'looseItem',
      label: 'Loose Item name*'
    };
  }
  if (entityName === 'Asset') {
    return {
      name: 'requestOrderAssetPrefixes',
      itemName: 'assetPrefix',
      label: 'Prefix*'
    };
  }
  if (entityName === 'Others') {
    return {
      name: 'requestOrderCustomEntities',
      itemName: 'customEntity',
      options: [],
      label: 'Name*'
    };
  }
  return {};
};

export const getErrorNames = (index) => {
  return [
    'checkEntitiesLength',
    `requestEntity-${index}`,
    `assetPrefix-${index}`,
    `category-${index}`,
    `looseItem-${index}`,
    `customEntity-${index}`
  ];
};

export const getObjWithoutEmptyValues = (obj) => {
  const valuesObj = { ...obj };
  for (const key in valuesObj) {
    if (!valuesObj[key]) {
      delete valuesObj[key];
    }
  }
  return valuesObj;
};

export const getDefaultState = () => ({
  transportationTimeline: '',
  purchasingTimeline: '',
  transportationCost: '',
  purchasingCost: '',
  transportationQTY: '',
  purchasingQTY: '',
  notes: '',
  quantity: ''
});

export const getPermissions = (
  requestOrderRole,
  userRole,
  isStatus,
  isDeleted,
  isROCreatorUser
) => {
  if (isDecisionMakerUser(requestOrderRole)) {
    return {
      isCheckboxColumnAvailable: isDeleted ? false : isStatus('Pending') || isStatus('InDelivery'),
      isQTYColumnAvailable: true,
      isETAColumnAvailable: !isStatus('Pending'),
      isETAEditable: isDeleted ? false : isStatus('Approved') || isStatus('InDelivery'),
      isEditColumnAvailable: isDeleted
        ? false
        : isStatus('Pending') || isStatus('Approved') || isStatus('InDelivery'),
      isDeleteColumnAvailable: isDeleted
        ? false
        : isStatus('Approved')
        ? isAdminUser(userRole)
        : isStatus('Pending'),
      isStatusColumnAvailable: isStatus('InDelivery') || isStatus('Closed'),
      isEditStatusColumnAvailable: isStatus('InDelivery'),
      // popup
      isQuantityFieldAvailable: isStatus('Pending') || isStatus('Approved'),
      isTransportCostFieldAvailable: isStatus('Pending'),
      isTransportTimelineFieldAvailable: isStatus('Pending'),
      isPurchaseCostFieldAvailable: isStatus('Pending'),
      isPurchaseTimelineFieldAvailable: isStatus('Pending'),
      isTransportQuantityFieldAvailable: isStatus('Pending'),
      isPurchaseQuantityFieldAvailable: isStatus('Pending'),
      isNoteFieldAvailable: isStatus('Pending'),
      isNoteAfterApprovalFieldAvailable: isStatus('Approved') || isStatus('InDelivery'),
      isFileFieldAvailable: !isStatus('Closed'),
      isPictureFieldAvailable: !isStatus('Closed')
    };
  }
  if (isResponsibleForAccountUser(requestOrderRole)) {
    return {
      isCheckboxColumnAvailable: isDeleted ? false : isStatus('Pending') || isStatus('InDelivery'),
      isQTYColumnAvailable: !isStatus('Pending'),
      isETAColumnAvailable: !isStatus('Pending'),
      isETAEditable: isDeleted ? false : isStatus('Approved') || isStatus('InDelivery'),
      isEditColumnAvailable: isDeleted
        ? false
        : isStatus('Pending') || isStatus('Approved') || isStatus('InDelivery'),
      isDeleteColumnAvailable: false,
      isStatusColumnAvailable: isStatus('InDelivery') || isStatus('Closed'),
      isEditStatusColumnAvailable: isStatus('InDelivery'),
      // popup
      isQuantityFieldAvailable: isStatus('Pending') || isStatus('Approved'),
      isTransportCostFieldAvailable: isStatus('Pending'),
      isTransportTimelineFieldAvailable: isStatus('Pending'),
      isPurchaseCostFieldAvailable: isStatus('Pending'),
      isPurchaseTimelineFieldAvailable: isStatus('Pending'),
      isTransportQuantityFieldAvailable: false,
      isPurchaseQuantityFieldAvailable: false,
      isNoteFieldAvailable: isStatus('Pending'),
      isNoteAfterApprovalFieldAvailable: isStatus('Approved') || isStatus('InDelivery'),
      isFileFieldAvailable: !isStatus('Closed'),
      isPictureFieldAvailable: !isStatus('Closed')
    };
  }
  if (isOperationsManagerUser(userRole)) {
    return {
      isCheckboxColumnAvailable: false,
      isQTYColumnAvailable: !isStatus('Pending'),
      isETAColumnAvailable: !isStatus('Pending'),
      isETAEditable: isDeleted ? false : isStatus('Approved') || isStatus('InDelivery'),
      isEditColumnAvailable: false,
      isDeleteColumnAvailable: false,
      isStatusColumnAvailable: isStatus('InDelivery') || isStatus('Closed'),
      isEditStatusColumnAvailable: false,
      // popup
      isQuantityFieldAvailable: false,
      isTransportCostFieldAvailable: false,
      isTransportTimelineFieldAvailable: false,
      isPurchaseCostFieldAvailable: false,
      isPurchaseTimelineFieldAvailable: false,
      isTransportQuantityFieldAvailable: false,
      isPurchaseQuantityFieldAvailable: false,
      isNoteFieldAvailable: false,
      isNoteAfterApprovalFieldAvailable: false,
      isFileFieldAvailable: false,
      isPictureFieldAvailable: false
    };
  }
  return {
    isCheckboxColumnAvailable: isROCreatorUser
      ? isStatus('Pending') || isStatus('Approved') || isStatus('InDelivery')
      : false,
    isQTYColumnAvailable: !isStatus('Pending'),
    isETAColumnAvailable: !isStatus('Pending'),
    isETAEditable: isROCreatorUser ? isStatus('InDelivery') : false,
    isEditColumnAvailable: isROCreatorUser
      ? isStatus('Pending') || isStatus('Approved') || isStatus('InDelivery')
      : false,
    isDeleteColumnAvailable: false,
    isStatusColumnAvailable: isStatus('InDelivery') || isStatus('Closed'),
    isEditStatusColumnAvailable: isStatus('InDelivery') && isROCreatorUser,
    // popup
    isQuantityFieldAvailable: isROCreatorUser ? isStatus('Pending') || isStatus('Approved') : false,
    isTransportCostFieldAvailable: isROCreatorUser ? isStatus('Pending') : false,
    isTransportTimelineFieldAvailable: isROCreatorUser ? isStatus('Pending') : false,
    isPurchaseCostFieldAvailable: isROCreatorUser ? isStatus('Pending') : false,
    isPurchaseTimelineFieldAvailable: isROCreatorUser ? isStatus('Pending') : false,
    isTransportQuantityFieldAvailable: false,
    isPurchaseQuantityFieldAvailable: false,
    isNoteFieldAvailable: isROCreatorUser ? isStatus('Pending') : false,
    isNoteAfterApprovalFieldAvailable: isROCreatorUser
      ? isStatus('Approved') || isStatus('InDelivery')
      : false,
    isFileFieldAvailable: isROCreatorUser ? !isStatus('Closed') : false,
    isPictureFieldAvailable: isROCreatorUser ? !isStatus('Closed') : false
  };
};

export const getTableHeadConfig = ({ isAdminUser, isDeleted }) => {
  return [
    { id: 1, label: 'Request ID', isSortable: true, parameter: 'id', styleName: 'request-id-th' },
    {
      id: 2,
      label: 'Status',
      isSortable: true,
      parameter: 'status'
    },
    {
      id: 3,
      label: 'End User',
      isSortable: true,
      parameter: 'endUser.firstName'
    },
    {
      id: 4,
      label: 'Location',
      isSortable: true,
      parameter: 'location.siteCode'
    },
    { id: 5, label: 'Entity', isSortable: false, parameter: null },
    {
      id: 6,
      label: 'Created By',
      isSortable: false,
      parameter: null
    },
    {
      id: 7,
      label: 'Creation date',
      isSortable: false,
      parameter: null
    },
    {
      id: 8,
      label: '',
      isSortable: false,
      parameter: null,
      isColumnAvailable: isAdminUser && !isDeleted
    }
  ];
};

export const itemStatuses = [
  { id: 'InDelivery', name: 'InDelivery', displayName: 'In delivery' },
  { id: 'Received', name: 'Received', displayName: 'Received' }
];
