import React from 'react';
import styles from './components.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import RemoveIcon from 'assets/images/deleteIcon.svg';
import { IconButton } from '@material-ui/core';
import EditingToggle from 'components/EditingToggle/EditingToggle';
import clsx from 'clsx';
import ErrorBlock from './ErrorBlock';

export default function PopupTableRow({
  index,
  data,
  onRowChange,
  isRemoveAvailable,
  looseItems,
  locations,
  errors,
  warnings,
  isEditing
}) {
  const handleRemoveRow = () => onRowChange({ name: 'remove', index });

  const handleSelect = (name, value) => {
    switch (name) {
      case 'looseItem':
        onRowChange({ name: 'looseItem', value: value.id, index });
        break;
      case 'id':
        onRowChange({ name: 'location', value, index });
        break;
      default:
        onRowChange({ name, value, index });
        break;
    }
  };

  return (
    <StyledTableRow>
      <StyledTableCell className={clsx(!!errors?.looseItem && styles.error)}>
        {!isEditing ? (
          <InputSearchWithMultiselect
            name="looseItem"
            defaultValue={data?.locationLooseItem?.looseItem?.id || ''}
            options={looseItems || []}
            onFilterSelect={handleSelect}
            multiselect={false}
          />
        ) : (
          <span>{data?.locationLooseItem?.looseItem?.name || ''}</span>
        )}
        <ErrorBlock errorText={errors?.looseItem} />
      </StyledTableCell>
      <StyledTableCell className={clsx(!!errors?.location && styles.error)}>
        {!isEditing ? (
          <InputSearchWithMultiselect
            name="id"
            defaultValue={data?.locationLooseItem?.id || ''}
            options={locations || []}
            onFilterSelect={handleSelect}
            multiselect={false}
            optionType="locationWithSublocation"
            disabled={!data?.locationLooseItem?.looseItem?.id}
          />
        ) : (
          <span>
            {data?.locationLooseItem?.sublocation?.id
              ? data.locationLooseItem.location?.siteCode +
                ' (' +
                data.locationLooseItem.sublocation.name +
                ')'
              : data?.locationLooseItem?.location?.siteCode || ''}
          </span>
        )}
        <ErrorBlock errorText={errors?.location} />
      </StyledTableCell>
      <StyledTableCell>
        {data?.locationLooseItem?.id ? data?.locationLooseItem.quantity : ''}
        {!!data?.locationLooseItem?.byAheadAccount &&
          `${' (' + data.locationLooseItem.byAheadAccount + ')'}`}
      </StyledTableCell>
      <StyledTableCell
        className={clsx(!!errors?.moveQuantity && styles.error, !!warnings && styles.warning)}>
        <EditingToggle
          name="moveQuantity"
          inputValue={data?.moveQuantity}
          onAccept={handleSelect}
          disabled={!data?.locationLooseItem?.looseItem?.id}
        />
        <ErrorBlock errorText={errors?.moveQuantity} />
      </StyledTableCell>
      <StyledTableCell className={clsx(!!errors?.moveByAheadAccount && styles.error)}>
        <EditingToggle
          name="moveByAheadAccount"
          inputValue={data?.moveByAheadAccount}
          onAccept={handleSelect}
          disabled={!data?.locationLooseItem?.looseItem?.id}
        />
        <ErrorBlock errorText={errors?.moveByAheadAccount} />
      </StyledTableCell>
      <StyledTableCell>
        {isRemoveAvailable && (
          <IconButton onClick={handleRemoveRow}>
            <img src={RemoveIcon} alt="remove" />
          </IconButton>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
}
