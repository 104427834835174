import React, { useState } from 'react';
import styles from './InputSearchWithCreation.module.scss';

import clsx from 'clsx';
import { reformatInputValue, pluck, enqueueErrorSnackbar } from 'helpers/AppHelpers';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ERROR_PREFIX_EXIST } from 'constants/infoSnackbarData';

const inputClasses = {
  inputRoot: styles.input_root,
  noOptions: styles.no_options,
  popper: styles.popper,
  popupIndicator: styles.popup_indicator
};

export default function InputSearchWithCreation({
  name,
  value,
  onSelect,
  onAdd,
  max,
  rule,
  isInvalid,
  invalidError,
  options,
  tipLabel,
  getOptionLabel,
  customLabel,
  disableCreation,
  validateManualInput,
  snackbarError,
  sameItemExistError,
  OptionComponent,
  InputLabelComponent,
  CustomElement
}) {
  const isMobile = useMobileViewport();

  const [inputValue, setInputValue] = useState('');
  const [customOptions, setCustomOptions] = useState({});

  const changeInput = (e, fieldValue) => {
    let interValue = e?.target?.value || fieldValue || '';
    interValue = reformatInputValue(interValue, max, rule);
    setInputValue(interValue);
  };

  // selection type: "create-option", "select-option", "remove-option", "blur" or "clear".
  const changeOption = (e, fieldValue, selectionType, processedOption) => {
    if (selectionType === 'remove-option' && e.type === 'keydown') return; // bug fix (DRT-8035)
    onSelect(name, processedOption.option, selectionType);
  };

  const handleAddClick = () => {
    const modify = (str) => str.toLowerCase().trim();
    const isNameExist = value.map((el) => modify(el[customLabel])).includes(modify(inputValue));

    if (isNameExist) {
      enqueueErrorSnackbar(sameItemExistError || ERROR_PREFIX_EXIST);
      return;
    }

    if (validateManualInput && validateManualInput(inputValue)) {
      enqueueErrorSnackbar(snackbarError);
      return;
    }

    const existedOption = options.find((el) => modify(el[customLabel]) === modify(inputValue));

    const isExisted = !!existedOption?.id;
    const newOption = isExisted ? existedOption : { [customLabel]: inputValue };

    onAdd(newOption, customOptions, isExisted ? 'existed' : 'custom');
    setInputValue('');
    setCustomOptions({});
  };

  const handleKeyPress = (e) =>
    !disableCreation && inputValue?.length && e.key === 'Enter' && handleAddClick();

  return (
    <div className={clsx(styles.wrapper, disableCreation && styles.shorten)}>
      <div className={styles.component}>
        <div className={clsx(styles.field, isInvalid && styles.invalid)}>
          <Autocomplete
            classes={inputClasses}
            multiple
            disableCloseOnSelect
            disableClearable
            value={value}
            options={options}
            noOptionsText={inputValue.length ? 'No matches' : 'No available items'}
            inputValue={inputValue}
            onInputChange={changeInput}
            onChange={changeOption}
            onKeyDown={handleKeyPress}
            popupIcon={<ExpandMore />}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option) => pluck(value, 'id').includes(option.id)}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            renderTags={() => null}
            renderOption={(option, { selected }) =>
              OptionComponent ? (
                OptionComponent(option, selected)
              ) : (
                <div className={styles.field__option}>
                  <CustomCheckbox value={selected} />
                  <div className={styles.field__option_label}>{getOptionLabel(option)}</div>
                </div>
              )
            }
          />
          <div className={styles.field__tip}>
            {InputLabelComponent ? (
              InputLabelComponent()
            ) : (
              <>
                {!isInvalid ? (
                  <span className={styles.field__tip_text}>
                    {tipLabel || 'Select from the list or write to add a new field'}
                  </span>
                ) : invalidError ? (
                  <span className={styles.field__tip_error}>{invalidError}</span>
                ) : (
                  <span className={styles.field__tip_text}>
                    {tipLabel || 'Select from the list or write to add a new field'}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        {!disableCreation && (
          <Button
            className={clsx(styles.add_button, !inputValue.length && styles.disabled)}
            onClick={handleAddClick}>
            <AddIcon />
            {!isMobile && 'Add new'}
          </Button>
        )}
        {CustomElement && CustomElement(customOptions, setCustomOptions, inputValue)}
      </div>
    </div>
  );
}
