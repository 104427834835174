import React, { useRef, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { dateFormat, formatDate, getDatePickerRange } from 'helpers/AppHelpers';
import clsx from 'clsx';
import styles from './StyledDatePicker.module.scss';
import EventIcon from '@material-ui/icons/Event';
import { ClickAwayListener } from '@material-ui/core';
import moment from 'moment';

const CalendarView = (mode, renderDefault, onClick, isClearable) => (
  <div className={styles.calendar}>
    {renderDefault()}
    {isClearable && (
      <div className={styles.controls}>
        <button onClick={onClick}>Clear</button>
      </div>
    )}
  </div>
);

const StyledDatePicker = ({
  name,
  format,
  value,
  onChange,
  min,
  max,
  isDisabled,
  isInvalid,
  placement,
  customPositionClasses,
  isClearable = true
}) => {
  const pickerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    !open && format !== 'YYYY' && pickerRef.current._showView('days');
    setOpen(!open);
  };

  const handleDateSelect = (date) => {
    onChange(date, name);
    setOpen(!open);
  };

  const handleClickAway = () => open && setOpen(false);
  const handleClearClick = () => pickerRef.current.props.onChange(null);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={clsx(styles.picker, isDisabled && styles.disabled)}>
        <Datetime
          open={open}
          ref={pickerRef}
          value={value ? moment(value) : ''}
          dateFormat={format || dateFormat}
          onChange={handleDateSelect}
          timeFormat={false}
          isValidDate={(curr) => getDatePickerRange(curr, min, max)}
          readOnly={true}
          className={clsx(
            customPositionClasses && customPositionClasses,
            styles.popover,
            placement === 'top' && styles.top
          )}
          inputProps={{
            disabled: isDisabled,
            readOnly: true,
            className: clsx(styles.root, isDisabled && styles.disabled, isInvalid && styles.invalid)
          }}
          renderInput={(props) => {
            return <input {...props} value={value ? formatDate(value, format) : ''} />;
          }}
          renderView={(mode, renderDefault) =>
            CalendarView(mode, renderDefault, handleClearClick, isClearable)
          }
        />
        <EventIcon
          className={clsx(styles.eventIcon, isDisabled && styles.disabled)}
          onClick={handleIconClick}
        />
      </div>
    </ClickAwayListener>
  );
};

export default StyledDatePicker;
