import React from 'react';
import styles from './components.module.scss';
import BackButton from 'components/BackButton/BackButton';

export default function PopupHeader({ isMobile, title, onCancel }) {
  return (
    <div className={styles.header}>
      <h2 className={styles.header__title}>{title}</h2>
      {isMobile && <BackButton onCancel={onCancel} />}
    </div>
  );
}
