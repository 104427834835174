import React from 'react';
import styles from './GeneralReportEdit.module.scss';

import { FormProvider, useForm } from 'react-hook-form';

import GeneralReportDetails from './components/GeneralDetails';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';

export default function GeneralReportEdit() {
  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });

  return (
    <section className={styles.wrapper}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="general">
          <GeneralReportDetails />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
