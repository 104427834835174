import React from 'react';
import styles from './PageSelector.module.scss';

import { Default, Ellipsis, Next, Previous } from './Buttons';

import { usePagination } from '@material-ui/lab/Pagination';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function PageSelector({ currentPage, totalPages, onChange }) {
  const isMobile = useMobileViewport();

  const changePage = (e, page) => {
    if (page < 1 || page > totalPages) return;
    onChange(page);
  };

  const { items } = usePagination({
    count: totalPages,
    onChange: changePage,
    page: currentPage || 1,
    siblingCount: isMobile ? 0 : 1
  });

  const isPageSelected = (page) => page === currentPage;

  const isPageLast = (index) => items.length - 2 === index;

  return (
    <div className={styles.selector}>
      {items.map(({ page, type, onClick }, index) => (
        <React.Fragment key={index}>
          {(() => {
            switch (type) {
              case 'start-ellipsis':
              case 'end-ellipsis':
                return <Ellipsis />;
              case 'previous':
                return <Previous onClick={onClick} />;
              case 'next':
                return <Next onClick={onClick} />;
              case 'last':
              case 'page':
                return (
                  <Default
                    page={page}
                    isSelected={isPageSelected(page)}
                    isLast={isPageLast(index)}
                    onClick={onClick}
                  />
                );
            }
          })()}
        </React.Fragment>
      ))}
    </div>
  );
}
