import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAssetActions } from 'hooks/Asset';
import { useUserConfig } from 'hooks/useUserConfig';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'hooks/useSearchParams';

import { ASSET_DETAILED_PATH, ROOT_PATH } from 'constants/routeConstants';

import Wrapper from './Wrapper';
import Form from './Form';
import { defaultForm } from './helpers';

export function AssetEdit() {
  const { id } = useParams();
  const history = useHistory();

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const { activePrefixId, drCode, drId } = useSearchParams(['activePrefixId', 'drCode', 'drId']);

  const { isBasicRoleUser, isConfigReceived } = useUserConfig();

  const methods = useForm({
    defaultValues: defaultForm,
    mode: 'onChange'
  });
  const { reset } = methods;

  const { getCreationFormAction, getAvailableDRCodeAction, getAssetAction, clearStateAction } =
    useAssetActions();

  useEffect(() => {
    const fetchData = async () => {
      if (!isConfigReceived) return;

      if (!id && (isBasicRoleUser || !activePrefixId)) {
        history.push(ROOT_PATH);
        return;
      }

      try {
        const criteria = await getCreationFormAction();

        if (id) {
          const asset = await getAssetAction(id);
          setBreadcrumbs([
            { path: `${ASSET_DETAILED_PATH}/${asset.id}`, name: asset.drCode },
            { name: `Edit Asset` }
          ]);
          reset({ ...asset, drCodeStatus: { isAvailable: true, status: 'Available!' } });
        } else {
          const { assetPrefixes } = criteria;
          const activePrefix = assetPrefixes.find(({ id }) => id === +activePrefixId);
          const assetFields = activePrefix.assetFields.map((item) => ({
            assetField: item,
            value: null
          }));

          const { availableDrCode } = await getAvailableDRCodeAction({ prefixId: activePrefix.id });

          const secondCrumb =
            drCode && drId ? { path: `${ASSET_DETAILED_PATH}/${drId}`, name: drCode } : null;

          setBreadcrumbs([secondCrumb, { name: 'Add Asset' }].filter(Boolean));

          reset({
            assetPrefix: { ...activePrefix },
            drCode: availableDrCode,
            drCodeStatus: { isAvailable: true, status: 'Available!' },
            assetFields,
            rental: {
              rentalStatus: { name: 'Available', displayName: 'Available' }
            },
            labeled: true,
            isInPicklist: { id: 0, name: 'No' },
            isInLeavelist: { id: 0, name: 'No' }
          });
        }
      } catch {
        history.push(ROOT_PATH);
      }
    };

    fetchData();
  }, [id, isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper breadcrumbs={breadcrumbs}>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
