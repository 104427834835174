import React, { useState } from 'react';
import styles from './AddingStatusField.module.scss';
import { Button, TextField } from '@material-ui/core';
import { allKeyboardLatSymbols, reformatInputValue } from 'helpers/AppHelpers';
import clsx from 'clsx';
import {
  createEmploymentStatus,
  getEmploymentStatuses
} from 'actions/employmentStatusEditorActions';
import { useDispatch } from 'react-redux';

export default function AddingStatusField({ isStatusNameExist }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const onInput = (event) => {
    if (error) setError('');
    const value = reformatInputValue(event.target.value, 60, allKeyboardLatSymbols);
    setName(value);
  };

  const isDisabled = () => !name;

  const handleAddClick = () => {
    if (isStatusNameExist(name)) {
      setError('Status with such name already exists');
      return;
    }
    dispatch(createEmploymentStatus({ name, color: '#ffffff' })).then((res) => {
      if (res.status === 400 && res?.errors && !!res?.errors?.EmploymentType?.length) {
        setError(res.errors.EmploymentType[0]);
      }
      if (res.status === 200) {
        setName('');
        dispatch(getEmploymentStatuses());
      }
    });
  };

  return (
    <div className={styles.component}>
      <div className={styles.component_field}>
        <TextField
          className={clsx(styles.component_field__input, error && styles.error)}
          variant="outlined"
          value={name}
          onChange={onInput}
          placeholder="Add new status"
        />
        {!!error && <span>{error}</span>}
      </div>
      <Button
        className={clsx(styles.component_button, isDisabled() && styles.disabled)}
        disabled={isDisabled()}
        onClick={handleAddClick}>
        + Add
      </Button>
    </div>
  );
}
