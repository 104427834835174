import { getProjectName, getRandomId } from 'helpers/AppHelpers';

const mapItemToChip = (data, name) => {
  return data.map((el) => {
    return {
      displayName: name === 'project' ? getProjectName(el) : el[name] || '',
      chipId: getRandomId()
    };
  });
};

export const generateChips = (data) => {
  const { prefixes, assets, rentalStatuses, projects, locations, statuses } = data;

  let chipsArray = [];

  if (assets?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(assets, 'drCode'));
  }
  if (locations?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(locations, 'siteCode'));
  }
  if (prefixes?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(prefixes, 'prefix'));
  }
  if (rentalStatuses?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(rentalStatuses, 'displayName'));
  }
  if (projects?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(projects, 'project'));
  }
  if (statuses?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(statuses, 'displayName'));
  }

  return chipsArray;
};
