export const getTableHeadConfig = (isMobile) =>
  isMobile
    ? [
        { id: 1, label: 'Team Member', isSortable: true, parameter: 'lastName' },
        { id: 2, label: 'Days on Road', isSortable: true, parameter: 'daysOnRoad' },
        { id: 3, label: 'Weeks on Road', isSortable: true, parameter: 'weeksOnRoad' },
        { id: 4, label: 'Date In', isSortable: true, parameter: 'dateIn' },
        { id: 5, label: 'Date Out', isSortable: true, parameter: 'dateOut' },
        { id: 6, label: 'Project', isSortable: true, parameter: 'project' },
        { id: 7, label: 'Scheduled', isSortable: true, parameter: 'timeSchedule' }
      ]
    : [
        { id: 1, label: 'Team Member', isSortable: true, parameter: 'lastName' },
        { id: 2, label: 'Date In', isSortable: true, parameter: 'dateIn' },
        { id: 3, label: 'Date Out', isSortable: true, parameter: 'dateOut' },
        { id: 4, label: 'Project', isSortable: true, parameter: 'project' },
        { id: 5, label: 'Days on Road', isSortable: true, parameter: 'daysOnRoad' },
        { id: 6, label: 'Weeks on Road', isSortable: true, parameter: 'weeksOnRoad' },
        { id: 7, label: 'Scheduled', isSortable: true, parameter: 'timeSchedule' }
      ];
