import React from 'react';
import styles from '../components.module.scss';

import GeneralDetails from './General';
import AssetsCounting from './Counting/AssetsCounting';

export default function Details() {
  return (
    <div className={styles.details}>
      <GeneralDetails />
      <AssetsCounting />
    </div>
  );
}
