import React from 'react';
import styles from './RequestOrderButtons.module.scss';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

export default function AddEntityButton({ onClick, isDisabled }) {
  const handleAddClick = () => onClick();

  return (
    <Button className={styles.add_button} onClick={handleAddClick} disabled={isDisabled}>
      <AddIcon />
      <label>Add item</label>
    </Button>
  );
}
