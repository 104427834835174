import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import LocationMarker from './LocationMarker';
import AdvancedFilterMarkers from './AdvancedFilterMarkers';
import styles from './MapComponent.module.scss';
import greenMarkerIcon from 'assets/images/markers/greenmarker.svg';
import { generateMapMarker } from './helpers';
import MapMarker from './MapMarker';

const greenMarker = generateMapMarker(greenMarkerIcon);

export default function MapComponent({
  locations,
  picker,
  inputLatLng,
  address,
  onPick,
  customMarker,
  advancedFilterApplied,
  nearestLocationsData
}) {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && customMarker?.lat && customMarker?.lng) {
      map.flyTo([customMarker.lat, customMarker.lng]);
    }
  }, [customMarker]);

  return (
    <MapContainer
      className={styles.map}
      center={[inputLatLng?.lat || 35.9183423, inputLatLng?.lng || -95.163601, 5.28]}
      whenCreated={setMap}
      zoom={5}
      minZoom={3}
      key={advancedFilterApplied}
      tap={false}
      zoomControl={false}>
      <TileLayer
        attribution="Google Maps"
        url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en "
      />
      <ZoomControl position="bottomright" />

      {!advancedFilterApplied && (
        <MarkerClusterGroup maxClusterRadius={20}>
          {locations?.map((el) => el.latitude && el.longitude && <MapMarker key={el.id} el={el} />)}
        </MarkerClusterGroup>
      )}

      {customMarker?.lat && customMarker?.lng && (
        <Marker
          position={[customMarker.lat, customMarker.lng]}
          icon={greenMarker}
          zIndexOffset={5}></Marker>
      )}
      {advancedFilterApplied && <AdvancedFilterMarkers inputData={nearestLocationsData} />}
      {picker && <LocationMarker inputData={inputLatLng} address={address} onPick={onPick} />}
    </MapContainer>
  );
}
