import React, { useEffect, useState } from 'react';
import styles from './CommonRecipientsFilter.module.scss';

import { useMobileViewport } from 'hooks/useMobileViewport';
import {
  useNotificationCenterActions,
  useNotificationCenterSelector
} from 'hooks/NotificationCenter';

import { getChips, getValuesAfterChipDeleting } from './helpers';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import Multiselect from 'components/Multiselect/Multiselect';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { tabs } from '../../../../../helpers';

import clsx from 'clsx';

export default function CommonRecipientsFilter({ filter, onApply }) {
  const isMobile = useMobileViewport();

  const { selectedTab } = useNotificationCenterSelector();
  const { getFilterCriteriaAction } = useNotificationCenterActions();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  const [chips, setChips] = useState([]);

  const createChips = (inputFilter) => {
    const chipsArray = getChips(inputFilter, options);
    setChips(chipsArray);
  };

  useEffect(() => {
    getFilterCriteriaAction({ notificationCenterTab: selectedTab }).then((res) => setOptions(res));
  }, []);

  useEffect(() => {
    if (open) {
      setValues(filter.filters);
      isMobile && createChips(filter.filters);
    }
  }, [open]);

  useEffect(() => {
    if (options?.['locations']?.length) {
      setValues(filter.filters);
      createChips(filter.filters);
    }
  }, [filter, options]);

  const { assetPrefixes, locations, userRoles, requestOrderRoles, departments } = options;

  const handleClearClick = () => {
    const defaultState = {
      ...values,
      locationIds: [],
      locationJobNumberIds: [],
      prefixIds: [],
      userRoleIds: [],
      requestOrderRoleIds: [],
      departmentIds: [],
      isBaaRecipient: false
    };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const handleBackClick = () => {
    isMobile && createChips(filter.filters);
    setOpen(false);
    if (isMobile) {
      document.body.style.overflow = '';
    }
  };
  const handleIconClick = (value) => {
    setOpen(value || !open);
    if (isMobile) {
      document.body.style.overflow = value || !open ? 'hidden' : '';
    }
  };

  const handleClickApply = () => {
    onApply(values);
    createChips(values);
    setOpen(false);
    if (isMobile) {
      document.body.style.overflow = '';
    }
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);
    const newValues = getValuesAfterChipDeleting(values, chip);
    setValues(newValues);
    if (!isMobile) {
      onApply(newValues);
    }
  };

  const selectRole = (e) => {
    const updatedFilter = { ...values, [e.target.name]: [...e.target.value] };
    setValues(updatedFilter);
    isMobile && createChips(updatedFilter);
  };
  const handleSelect = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };
  const handleCheckbox = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const conditions = {
    isLocationBlockAvailable: ![tabs.generalReport, tabs.teamMemberStatus].includes(selectedTab),
    isPrefixFieldAvailable: [tabs.assetReport, tabs.ticketAndAssetTab].includes(selectedTab),
    isRoleFieldAvailable: selectedTab !== tabs.teamMemberStatus,
    isBAACheckboxAvailable: selectedTab === tabs.looseItems,
    isRequestOrderRoleAvailable: selectedTab === tabs.requestOrder,
    isDepartmentFieldAvailable: selectedTab === tabs.teamMemberStatus,
    isFilterNarrowed: [tabs.generalReport, tabs.teamMemberStatus].includes(selectedTab)
  };

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      onChipDelete={handleChipDelete}>
      <section className={clsx(styles.wrapper, conditions.isFilterNarrowed && styles.narrowed)}>
        {conditions.isLocationBlockAvailable && (
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Location</label>
              <div className={styles.block__cell_input}>
                <AutocompleteWithSelectAll
                  name="locationIds"
                  label="siteCode"
                  value={values?.locationIds || []}
                  options={locations || []}
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={true}
                />
              </div>
            </div>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Job Number</label>
              <div className={styles.block__cell_input}>
                <AutocompleteWithSelectAll
                  name="locationJobNumberIds"
                  label="locationJobNumber"
                  value={values?.locationJobNumberIds || []}
                  options={locations || []}
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={true}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.block}>
          {conditions.isPrefixFieldAvailable && (
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Prefix</label>
              <div className={styles.block__cell_input}>
                <AutocompleteWithSelectAll
                  name="prefixIds"
                  label="prefix"
                  value={values?.prefixIds || []}
                  options={assetPrefixes || []}
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={true}
                />
              </div>
            </div>
          )}
          {conditions.isRoleFieldAvailable && (
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>{`User's Role`}</label>
              <div className={styles.block__cell_input}>
                <Multiselect
                  name="userRoleIds"
                  valuesArray={values?.userRoleIds || []}
                  options={userRoles || []}
                  onFilterSelect={selectRole}
                />
              </div>
            </div>
          )}
          {conditions.isBAACheckboxAvailable && (
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Buy Ahead Account recipient</label>
              <CustomCheckbox
                name="isBaaRecipient"
                value={values.isBaaRecipient || false}
                onChange={handleCheckbox}
              />
            </div>
          )}
          {conditions.isRequestOrderRoleAvailable && (
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Request Order Role</label>
              <div className={styles.block__cell_input}>
                <Multiselect
                  name="requestOrderRoleIds"
                  valuesArray={values?.requestOrderRoleIds}
                  options={requestOrderRoles || []}
                  onFilterSelect={selectRole}
                />
              </div>
            </div>
          )}
          {conditions.isDepartmentFieldAvailable && (
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Department</label>
              <div className={styles.block__cell_input}>
                <AutocompleteWithSelectAll
                  name="departmentIds"
                  label="name"
                  value={values?.departmentIds || []}
                  options={departments || []}
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={true}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </FilterWrapper>
  );
}
