import React, { useEffect, useState } from 'react';
import styles from './EditCertificationPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import { FormLabel, FormSearchInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { useForm, FormProvider } from 'react-hook-form';

import { useTrainingsActions } from 'hooks/Trainings';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function EditCertificationPopup({ open, setOpen, data, setData, refreshTable }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { name: '' },
    mode: 'onChange'
  });
  const { reset, setValue, getValues, setError, formState } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const [groups, setGroups] = useState([]);

  const { getGroupsAction, updateCertificationAction, createCertificationAction } =
    useTrainingsActions();

  const isCreatedSource = data?.source?.name !== 'KPA';
  const isCreateMode = !data?.id;

  useEffect(() => {
    if (open && data?.id) {
      reset(data);
    }
  }, [open, data]);

  useEffect(() => {
    if (!open || isCreatedSource) return;

    getGroupsAction({ isGroup: true }).then((res) => setGroups(res));
  }, [open, data]);

  const changeGroup = (name, value) => setValue(name, value);

  const closePopup = () => {
    setOpen(false);
    setData({});
  };

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('name')) {
      setError('name', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleApply = async () => {
    if (!validateForm()) return;

    const action = isCreateMode ? createCertificationAction : updateCertificationAction;

    const res = await action(getValues());

    if (isSuccessfulStatus(res.status)) {
      closePopup();
      refreshTable();
    } else if (res.status === 400 && res?.errors && !!res?.errors?.Name?.length) {
      setError('name', getErrorsProperties('Certification with such name exists'));
    }
  };

  const getTitle = () => {
    const action = isCreateMode ? 'Create' : 'Edit';
    const item = isMobile ? '' : ' Training & Certification';
    return `${action}${item}`;
  };

  return (
    (isCreatedSource || !!groups.length) && (
      <DialogWrapper open={open} onClose={closePopup}>
        <div className={styles.popup}>
          <DialogHeader title={getTitle()} onClose={closePopup} classes={styles.title} />
          <div className={styles.content}>
            <FormProvider {...methods}>
              <div className={styles.form}>
                {isCreatedSource ? (
                  <div className={styles.form__item}>
                    <FormLabel required>Name</FormLabel>
                    <FormInputText name="name" options={{ max: 60 }} />
                  </div>
                ) : (
                  <div className={clsx(styles.form__item, styles.details)}>
                    <FormLabel bold>Name:</FormLabel>
                    <div className={styles.form__item_name}>
                      <span>{data?.name || ''}</span>
                    </div>
                  </div>
                )}
                {!isCreatedSource && (
                  <>
                    <div className={clsx(styles.form__item, styles.checkbox)}>
                      <FormLabel>Available</FormLabel>
                      <FormCheckbox name="available" />
                    </div>
                    <div className={styles.form__item}>
                      <FormLabel>Group</FormLabel>
                      <FormSearchInput
                        name="group"
                        clearable
                        onSelect={changeGroup}
                        options={groups || []}
                      />
                    </div>
                  </>
                )}
              </div>
            </FormProvider>
          </div>
          <div className={styles.footer}>
            <MainButton text="Cancel" action={closePopup} type="secondary" />
            <MainButton
              text="Apply"
              action={handleApply}
              type="primary"
              isDisabled={isFormInvalid}
            />
          </div>
        </div>
      </DialogWrapper>
    )
  );
}
