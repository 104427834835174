import React from 'react';
import styles from './PaginationButtons.module.scss';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

export default function Default({ page, selected, onClick }) {
  return (
    <>
      <IconButton className={clsx(styles.page_button, selected && styles.active)} onClick={onClick}>
        {page}
      </IconButton>
      <div className={styles.page_divider}></div>
    </>
  );
}
