import React, { useState } from 'react';
import styles from './AddFormRow.module.scss';

import { reformatInputValue } from 'helpers/AppHelpers';

import clsx from 'clsx';

import { TextField, Button } from '@material-ui/core';

export default function AddFormRow({ placeholder, max, isDuplicate, duplicateError, onAdd }) {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const changeInputValue = (event) => {
    if (error) setError('');
    const value = reformatInputValue(event.target.value, max);
    setValue(value);
  };

  const handleAddClick = () => {
    if (isDuplicate && isDuplicate(value)) {
      setError(duplicateError);
      return;
    }

    onAdd({ value, setFieldValue: setValue, setFieldError: setError });
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter' || !value) return;

    handleAddClick();
  };

  const isDisabled = !value;

  return (
    <div className={styles.row}>
      <div className={styles.row__field}>
        <TextField
          className={clsx(styles.row__field_input, error && styles.error)}
          variant="outlined"
          value={value}
          onChange={changeInputValue}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
        {!!error && <span>{error}</span>}
      </div>
      <Button
        className={clsx(styles.row__button, isDisabled && styles.disabled)}
        onClick={handleAddClick}>
        + Add
      </Button>
    </div>
  );
}
