import React from 'react';
import styles from './TicketForm.module.scss';

import { useParams } from 'react-router-dom';

import { General, Description, Remarks, NecessaryItems, Attachments } from './components';

export function TicketForm() {
  const { id } = useParams();

  return (
    <div className={styles.form}>
      <General />

      <Description />

      <NecessaryItems />

      <Attachments />

      {id && <Remarks />}
    </div>
  );
}
