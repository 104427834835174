import React from 'react';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import styles from './BodyCells.module.scss';

export default function TotalRow({ total }) {
  return (
    <StyledTableRow id="total_row">
      <StyledTableCell className={styles.initialCell}>Total</StyledTableCell>
      {total.map(({ count, day }) => (
        <StyledTableCell key={day} className={styles.totalCell}>
          <div className={styles.totalBlock}>
            <span>{count || 0}</span>
          </div>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
