import api from '../api';
import {
  CLEAR_BOL_LOGS_STATE,
  CLEAR_UPLOAD_BOL_LOGS_STATE,
  SET_BOL_LOGS_CREATION_FORM,
  SET_BOL_LOGS_FILTER_CRITERIA,
  SET_BOL_LOGS_FILTER,
  SET_LOAD_MORE_FLAG,
  SET_UPLOAD_BOL_LOGS_CONFIGURATION
} from 'constants/reduceConstants';
import { browserHistory } from '../browserHistory';
import {
  BILL_OF_LADING_LOGS_PATH,
  UPLOAD_BOL_LOGS_FROM_CSV_PATH
} from '../constants/routeConstants';
import {
  SUCCESS_CREATE_BOL_LOG,
  SUCCESS_DELETE_BOL_LOG,
  SUCCESS_UPDATE_BOL_LOG
} from 'constants/infoSnackbarData';
import {
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  isSuccessfulStatus
} from 'helpers/AppHelpers';

export const getLogs = (query) => (dispatch) => {
  return api.billOfLadingLogs
    .getListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getCreationForm = (query) => (dispatch) =>
  api.billOfLadingLogs
    .getCreationFormApi(query)
    .then((res) => {
      dispatch({ type: SET_BOL_LOGS_CREATION_FORM, payload: res.data });
    })
    .catch((err) => console.log(err));

export const getFilterCriteria = () => (dispatch) =>
  api.billOfLadingLogs
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_BOL_LOGS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getCsvResource = (q) => () =>
  api.billOfLadingLogs
    .getCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLog = (q) => () => {
  return api.billOfLadingLogs
    .getLogApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const createLog = (data) => () => {
  return api.billOfLadingLogs.createLogApi(data).then(
    () => {
      browserHistory.push(BILL_OF_LADING_LOGS_PATH);
      enqueueSuccessSnackbar(SUCCESS_CREATE_BOL_LOG);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateLog = (data) => () => {
  return api.billOfLadingLogs.updateLogApi(data).then(
    () => {
      browserHistory.push(BILL_OF_LADING_LOGS_PATH);
      enqueueSuccessSnackbar(SUCCESS_UPDATE_BOL_LOG);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const removeLog = (id) => () => {
  return api.billOfLadingLogs.removeLogApi(id).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_BOL_LOG);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getCsvTemplate = () => () =>
  api.billOfLadingLogs
    .getCsvTemplateApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const importCsv = (file) => (dispatch) =>
  api.billOfLadingLogs
    .importCsvApi(file)
    .then((res) => {
      if (isSuccessfulStatus(res.status)) {
        dispatch({ type: SET_UPLOAD_BOL_LOGS_CONFIGURATION, payload: res.data });
        browserHistory.push(UPLOAD_BOL_LOGS_FROM_CSV_PATH);
      }
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    });

export const uploadLogs = (data) => () =>
  api.billOfLadingLogs.uploadLogsApi(data).then(
    (res) => {
      if (isSuccessfulStatus(res.status)) {
        browserHistory.push(BILL_OF_LADING_LOGS_PATH);
        enqueueSuccessSnackbar(SUCCESS_CREATE_BOL_LOG);
      }
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const setFilter = (data) => (dispatch) =>
  dispatch({ type: SET_BOL_LOGS_FILTER, payload: data });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_BOL_LOGS_STATE });

export const clearUploadLogsState = () => (dispatch) =>
  dispatch({ type: CLEAR_UPLOAD_BOL_LOGS_STATE });
