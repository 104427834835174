import api from '../api';
import {
  CLEAR_ADD_LOOSE_ITEMS_POPUP_STATE,
  SET_LOCATIONS_FOR_ADD_LOOSE_ITEMS_POPUP,
  SET_LOOSE_ITEMS_FOR_ADD_LOOSE_ITEMS_POPUP,
  SET_PICKLISTS_FOR_ADD_LOOSE_ITEMS_POPUP
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST,
  SUCCESS_EDIT_LOOSE_ITEMS_WITHIN_PICKLIST
} from 'constants/infoSnackbarData';

export const getShortedLooseItems = (query) => (dispatch) =>
  api.addLooseItemsPopup
    .getShortedLooseItemsApi(query)
    .then((res) => dispatch({ type: SET_LOOSE_ITEMS_FOR_ADD_LOOSE_ITEMS_POPUP, payload: res }))
    .catch((err) => console.log(err));

export const getShortedPicklists = () => (dispatch) =>
  api.addLooseItemsPopup
    .getShortedPicklistsApi()
    .then((res) => dispatch({ type: SET_PICKLISTS_FOR_ADD_LOOSE_ITEMS_POPUP, payload: res }))
    .catch((err) => console.log(err));

export const getLooseItemLocations = (query) => (dispatch) =>
  api.addLooseItemsPopup
    .getLooseItemLocationsApi(query)
    .then((res) => dispatch({ type: SET_LOCATIONS_FOR_ADD_LOOSE_ITEMS_POPUP, payload: res }))
    .catch((err) => console.log(err));

export const addLooseItemsToPicklist = (data) => () => {
  return api.addLooseItemsPopup.addLooseItemsToPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST + ' ' + data?.picklist?.name || ''
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const checkPicklistItemsLocation = (query) => () =>
  api.addLooseItemsPopup
    .checkPicklistItemsLocationApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const editLooseItemOfPicklist = (data) => () => {
  return api.addLooseItemsPopup.editLooseItemOfPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        SUCCESS_EDIT_LOOSE_ITEMS_WITHIN_PICKLIST + ' ' + data?.picklist?.name || ''
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const clearAddLooseItemsPopupState = () => (dispatch) =>
  dispatch({ type: CLEAR_ADD_LOOSE_ITEMS_POPUP_STATE });
