import React from 'react';
import styles from './Buttons.module.scss';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';

export default function AddButton({ onClick, disabled, children }) {
  const handleClick = () => onClick();

  return (
    <Button className={clsx(styles.add, disabled && styles.add_disabled)} onClick={handleClick}>
      <AddIcon />
      <label>{children}</label>
    </Button>
  );
}
