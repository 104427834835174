import { getRandomId } from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id || obj?.id === 0) {
      return {
        ...obj,
        key: key,
        displayName: obj[name] || '',
        chipId: getRandomId()
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const { picklists, leavelists, originLocations, destinationLocations } = options;
  let chipsArray = [];
  if (inputFilter?.picklistIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(picklists, inputFilter.picklistIds, 'picklist', 'name')
    );
  }
  if (inputFilter?.leavelistIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(leavelists, inputFilter.leavelistIds, 'leavelist', 'name')
    );
  }
  if (inputFilter?.originLocationIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(originLocations, inputFilter['originLocationIds'], 'origin', 'siteCode')
    );
  }
  if (inputFilter?.destinationLocationIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        destinationLocations,
        inputFilter['destinationLocationIds'],
        'destination',
        'siteCode'
      )
    );
  }

  let isTwoDatesSelected = false;
  if (inputFilter?.createdFrom && inputFilter?.createdTo) {
    isTwoDatesSelected = true;
    chipsArray.push({
      key: 'dateFromTo',
      displayName: 'Created ' + inputFilter.createdFrom + ' - ' + inputFilter.createdTo,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.createdFrom && !isTwoDatesSelected) {
    chipsArray.push({
      key: 'createdFrom',
      displayName: 'Created from ' + inputFilter.createdFrom,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.createdTo && !isTwoDatesSelected) {
    chipsArray.push({
      key: 'createdTo',
      displayName: 'Created to ' + inputFilter.createdTo,
      chipId: getRandomId()
    });
  }

  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'picklist':
      return {
        ...values,
        picklistIds: values.picklistIds.filter((id) => id !== chip.id)
      };
    case 'leavelist':
      return {
        ...values,
        leavelistIds: values.leavelistIds.filter((id) => id !== chip.id)
      };
    case 'origin':
      return {
        ...values,
        originLocationIds: values.originLocationIds.filter((id) => id !== chip.id)
      };
    case 'destination':
      return {
        ...values,
        destinationLocationIds: values.destinationLocationIds.filter((id) => id !== chip.id)
      };
    case 'dateFromTo':
      return { ...values, createdFrom: null, createdTo: null };
    case 'createdFrom':
      return { ...values, createdFrom: null };
    case 'createdTo':
      return { ...values, createdTo: null };
    default:
      break;
  }
};
