import React, { useState } from 'react';
import styles from './TableEditor.module.scss';
import { Fade, IconButton, Paper, Popper, TextField } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { MainButton } from 'components/StyledComponents';
import { reformatNumberValue } from 'helpers/AppHelpers';

let key = new Date().getTime() + Math.random();

function TableEditor({ isMobile, locationList, onApply }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState({});

  const handleOpen = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
    setValues({});
  };

  const handleApply = () => {
    setOpen(!open);
    onApply(values.location, values.quantity);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const onLocationSelect = (name, value) => {
    key = new Date().getTime() + Math.random();
    setValues({ ...values, [name]: value });
  };

  const onInput = (event) => {
    const inputValue = reformatNumberValue(event.target.value);
    setValues({ ...values, quantity: inputValue });
  };

  return (
    <div className={styles.filterWrapper}>
      <IconButton
        className={`${styles.filterButton} ${open ? styles.filterButton__opened : ''}`}
        onClick={handleOpen}
        component="span">
        {!isMobile ? <CreateIcon /> : 'change Destination and QTY'}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && (
                <section className={styles.headerWrapper}>
                  <div className={styles.pageHeader}>
                    <h1>Change loose items</h1>
                  </div>
                </section>
              )}
              <section className={styles.formBlock}>
                <section className={styles.formRow}>
                  <label>Destination</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="location"
                      defaultValue={values?.location?.id || ''}
                      options={locationList || []}
                      onFilterSelect={onLocationSelect}
                      multiselect={false}
                      refreshKey={key}
                    />
                  </div>
                </section>
                <section className={styles.formRow}>
                  <label>QTY</label>
                  <div className={styles.inputWrapper}>
                    <TextField
                      name="quantity"
                      className={styles.textInput}
                      variant="outlined"
                      value={values.quantity}
                      onChange={onInput}
                    />
                  </div>
                </section>
              </section>
              <section className={styles.buttonWrapper}>
                <MainButton
                  text="Cancel"
                  action={handleClose}
                  type="secondary"
                  size="filter_popup"
                />
                <MainButton text="Apply" action={handleApply} type="primary" size="filter_popup" />
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default TableEditor;
