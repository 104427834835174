import React, { useEffect, useState } from 'react';
import styles from './TicketEditWrapper.module.scss';

import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import { MainButton } from 'components/StyledComponents';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { TICKET_DETAILED_PATH, TICKETS_LIST_PATH } from 'constants/routeConstants';
import {
  CLOSE_TICKET,
  CLOSE_TICKET_TYPE,
  STOP_TICKET,
  STOP_TICKET_TYPE,
  CREATE_NEW_TICKET,
  NEW_TICKET_TYPE,
  EXIT_WITHOUT_SAVING,
  RE_OPEN_TICKET,
  RE_OPEN_TICKET_TYPE,
  UPDATE_TICKET,
  UPDATE_TICKET_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';

import { getBackendErrors } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

export default function TicketEditWrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [modalData, setModalData] = useState({});

  const { isBasicRoleUser, isPersonnelManagerUser, isLocationInConfig, isEntityCreatorUser } =
    useUserConfig();

  const { setError, formState, getValues } = useFormContext();
  const { isDirty, errors } = formState;

  const { createTicketAction, updateTicketAction } = useTicketActions();
  const { currentTicket } = useTicketSelector();

  useEffect(() => {
    if (id) {
      setBreadcrumbs([
        { path: `${TICKET_DETAILED_PATH}/${id}`, name: `Ticket #${id}` },
        { name: `Edit ticket` }
      ]);
    } else {
      setBreadcrumbs([{ name: `Create ticket` }]);
    }
  }, [id]);

  const handleResponse = (err) =>
    err?.errors &&
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));

  const closeModal = () => setModalData({});

  const agreeModal = () => {
    let newValues = getValues();

    newValues = {
      ...newValues,
      remarks: [...newValues.remarks, { description: getValues('remarksMessage') || '' }]
    };

    closeModal();

    switch (modalData.type) {
      case NEW_TICKET_TYPE:
        createTicketAction(newValues).then(handleResponse);
        break;
      case UPDATE_TICKET_TYPE:
        updateTicketAction(newValues).then(handleResponse);
        break;
      case RE_OPEN_TICKET_TYPE:
        updateTicketAction({ ...newValues, isOpened: true }).then(handleResponse);
        break;
      case CLOSE_TICKET_TYPE:
        updateTicketAction({ ...newValues, isOpened: false, systemReopen: true }).then(
          handleResponse
        );
        break;
      case STOP_TICKET_TYPE:
        updateTicketAction({ ...newValues, isOpened: false, systemReopen: false }).then(
          handleResponse
        );
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const getBack = () =>
    id ? history.push(`${TICKET_DETAILED_PATH}/${id}`) : history.push(TICKETS_LIST_PATH);

  const handleCancel = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const isButtonAvailable = () =>
    isBasicRoleUser || isPersonnelManagerUser
      ? isEntityCreatorUser(currentTicket?.openedByUser?.email) ||
        isLocationInConfig(currentTicket?.asset?.['currentLocationId'])
      : true;

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: 'asset',
        errorMessage: 'Asset field is required',
        isInvalid: !getValues('asset')?.id
      },
      {
        name: 'severity',
        errorMessage: 'Severity field is required',
        isInvalid: !getValues('severity')?.name
      },
      {
        name: 'description',
        errorMessage: 'Description field is required',
        isInvalid: !getValues('description')
      },
      {
        name: 'remarksMessage',
        errorMessage: 'Remarks field is required',
        isInvalid: !getValues('remarksMessage'),
        checkCondition: !!id
      },
      {
        name: 'reopenOnSpecificDate',
        errorMessage: 'This field is required',
        isInvalid: !getValues('reopenOnSpecificDate'),
        checkCondition: getValues('reopenTicketFrequency')?.name === 'OneTime'
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid, checkCondition = true }) => {
      if (checkCondition && isInvalid) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  const handleCreate = (param) => {
    if (!validateForm()) return;

    const modalData = {
      create: CREATE_NEW_TICKET,
      save: UPDATE_TICKET,
      reOpen: RE_OPEN_TICKET,
      close: CLOSE_TICKET,
      stop: STOP_TICKET
    };
    setModalData(modalData[param]);
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit' : 'Create'} ticket</h1>
          {isMobile && <BackButton onCancel={handleCancel} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleCancel} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={() => handleCreate(id ? 'save' : 'create')}
          isDisabled={isFormInvalid()}
        />
        {isButtonAvailable() && id && (
          <MainButton
            text={`${getValues('isOpened') ? 'Close Ticket' : 'Re-open Ticket'}`}
            type="primary"
            action={() => handleCreate(getValues('isOpened') ? 'close' : 'reOpen')}
            isDisabled={isFormInvalid()}
          />
        )}
        {isButtonAvailable() &&
          id &&
          !getValues('isOpened') &&
          !!getValues('reopenTicketFrequency')?.id &&
          !!getValues('systemReopen') && (
            <MainButton
              text="Stop reopening ticket"
              type="extra"
              action={() => handleCreate('stop')}
              isDisabled={isFormInvalid()}
            />
          )}
      </div>
    </div>
  );
}
