import { queryToString } from 'helpers/AppHelpers';
import api from '../api';

export const getFilterCriteriaApi = () =>
  api.get(`/Locations/MapFilterCriteria?mapFilter=true`).then((res) => res.data);

export const getLocationsApi = (q) =>
  api.get(`/Locations/Map?${queryToString(q)}`).then((res) => res.data);

export const getLocationAssetsApi = (q) =>
  api.get(`/Locations/Map/Assets?${queryToString(q)}`).then((res) => res.data);

export const getLocationAssetsFieldsApi = (q) =>
  api.get(`/Locations/Map/AssetFields?${queryToString(q)}`).then((res) => res.data);

export const getLocationLooseItemsApi = (q) =>
  api.get(`/Locations/Map/LooseItems?${queryToString(q)}`).then((res) => res.data);
