import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';

import ScheduleFilterWrapper from './ScheduleFilterWrapper';
import ScheduleFilterFooter from './ScheduleFilterFooter';
import ScheduleFilterForm from './ScheduleFilterForm';

import { defaultFilterState } from '../../../helpers';
import { cloneObj, doDriveId, doNotDriveId } from 'helpers/AppHelpers';

export default function ProjectScheduleFilter() {
  const [open, setOpen] = useState(false);

  const { filter, selectedTab, isMasterSelected } = useTrackerSelector();
  const { getFilterCriteriaAction } = useTrackerActions();

  const methods = useForm({
    defaultValues: defaultFilterState,
    mode: 'onChange'
  });
  const { reset } = methods;

  useEffect(() => {
    const { doNotDrive, doDrive } = filter.filters;
    const transformedValues = cloneObj(filter.filters);

    transformedValues.driveStatusIds =
      doNotDrive && doDrive
        ? [doNotDriveId, doDriveId]
        : doNotDrive
        ? [doNotDriveId]
        : doDrive
        ? [doDriveId]
        : [];

    reset(transformedValues);
  }, [open]);

  useEffect(() => {
    if (!selectedTab) return;

    getFilterCriteriaAction({
      isMaster: isMasterSelected,
      departmentIds: filter.filters.teamMemberDepartmentIds || []
    });
  }, [selectedTab]);

  return (
    <FormProvider {...methods}>
      <ScheduleFilterWrapper open={open} setOpen={setOpen}>
        <ScheduleFilterForm />
        <ScheduleFilterFooter setOpen={setOpen} />
      </ScheduleFilterWrapper>
    </FormProvider>
  );
}
