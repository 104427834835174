import React from 'react';

import {
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryWrapper
} from 'components/SummaryComponents';

export function Sandbox() {
  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Sandbox" />
      </SummaryHeaderWrapper>
    </SummaryWrapper>
  );
}
