import React, { useEffect, useState } from 'react';
import styles from './DashboardWrapper.module.scss';

import { MainButton } from 'components/StyledComponents';
import AddWidgetPopup from 'components/AddWidgetPopup';

import Hint from 'components/Hint';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { dashboardTypes } from '../../helpers';

export default function DashboardWrapper({ children }) {
  const [openWidget, setOpenWidget] = useState(false);

  const { tab, tabConfig } = useTicketsDashboardSelector();
  const {
    getBaseStatisticsAction,
    getTurnoverStatisticsAction,
    getFlowStatisticsAction,
    getDashboardConfigAction,
    updateTabConfigAction
  } = useTicketsDashboardActions();

  const actions = {
    [dashboardTypes.baseStatistics]: getBaseStatisticsAction,
    [dashboardTypes.ticketTurnover]: getTurnoverStatisticsAction,
    [dashboardTypes.flowStatistic]: getFlowStatisticsAction
  };

  const getHintMessage = () => {
    const obj = {
      [dashboardTypes.baseStatistics]:
        'Widget shows the quantity of opened (re-opened) or closed tickets within the date range.',
      [dashboardTypes.ticketTurnover]:
        'Widget shows average time when ticket was opened and closed within the selected date range.',
      [dashboardTypes.flowStatistic]:
        'Widget shows quantity of tickets were opened or closed in each month for the last 12 month.'
    };

    return obj[tab];
  };

  useEffect(() => {
    getDashboardConfigAction({ dashboardType: tab }).then(() => {
      actions[tab]();
    });
  }, []);

  const openWidgetPopup = () => setOpenWidget(true);

  const handleWidgetsUpdate = (items) => {
    updateTabConfigAction({ items }).then(() => {
      actions[tab]();
    });
  };

  return (
    <div className={styles.wrapper}>
      {openWidget && (
        <AddWidgetPopup
          open={openWidget}
          setOpen={setOpenWidget}
          data={tabConfig?.items || []}
          onApply={handleWidgetsUpdate}
          dashboardType={tab}
        />
      )}

      <div className={styles.settings}>
        <Hint>{getHintMessage()}</Hint>
        <MainButton text="Config widget" action={openWidgetPopup} type="primary" />
      </div>

      {children}
    </div>
  );
}
