import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormLabel, FormSelectInput } from 'components/FormComponents';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import { FilterActions } from 'components/FilterComponentsV2';

import { FormProvider, useForm } from 'react-hook-form';
import useReportTemplatesSelector from 'hooks/ReportTemplates/useReportTemplatesSelector';

import { enrichNameToId } from 'helpers/AppHelpers';

import { statuses, defaultFilter } from '../helpers';

export function Filter({ open, setOpen, onApply }) {
  const methods = useForm({
    defaultValues: {
      generalReportTypeName: [],
      showPublished: null,
      statusIds: []
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, reportTypes } = useReportTemplatesSelector();

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);
  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const selectStatus = ({ name, value }) => {
    const boolValue = value?.length ? [1].includes(value[0]) : null;
    setValue(name, value);
    setValue('showPublished', boolValue);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Report type</FormLabel>
                <FormMultiSelect
                  trackById
                  name="generalReportTypeName"
                  options={enrichNameToId(reportTypes) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Status</FormLabel>
                <FormSelectInput
                  name="statusIds"
                  clearable
                  arrayValue
                  options={statuses || []}
                  onSelect={selectStatus}
                />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
