import React from 'react';
import styles from './SearchControl.module.scss';

import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';

import { useHistory } from 'react-router-dom';

import { useSearchSelector } from 'hooks/Search';
import { SEARCH_PATH } from 'constants/routeConstants';

export default function SearchControl() {
  const history = useHistory();
  const { searchQuery } = useSearchSelector();

  const handleChange = (value) => {
    if (!value) return;

    history.replace(`${SEARCH_PATH}?searchQuery=${value}`);
  };

  return (
    <div className={styles.control}>
      <SearchTextFieldRefactored inputValue={searchQuery} updateSearchParam={handleChange} />
    </div>
  );
}
