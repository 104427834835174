import React, { useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { setUnsavedFormData } from 'actions/commonActions';
import { selectSingleCategoryData } from '../selectors';
import { getSingleCategory, createCategory, updateCategory } from 'actions/categoryActions';
import styles from './CategoryEdit.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { CATEGORY_DETAILED_PATH, CATEGORY_LIST_PATH } from 'constants/routeConstants';
import {
  NEW_CATEGORY_TYPE,
  WITHOUT_SAVING_TYPE,
  CREATE_NEW_CATEGORY,
  UPDATE_CATEGORY,
  EXIT_WITHOUT_SAVING,
  UPDATE_CATEGORY_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE
} from 'constants/dialogPopupsData';
import { getBackendErrors } from 'helpers/AppHelpers';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import { FormProvider, useForm } from 'react-hook-form';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';

function CategoryEdit({
  setUnsavedFormDataAction,
  unsavedFormData,
  currentCategory,
  getCategoryAction,
  createCategoryAction,
  updateCategoryAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();

  const containerRef = useRef(null);
  const breadcrumbs = useRef([{ name: 'New category' }]);
  const routeUrl = useRef(null);

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const methods = useForm({
    defaultValues: { name: '', description: '' },
    mode: 'onChange'
  });
  const { reset, formState, setError, getValues } = methods;
  const { isDirty, errors } = formState;

  useEffect(() => {
    if (id) {
      getCategoryAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentCategory?.id) {
      const { id, name } = currentCategory;
      breadcrumbs.current = [
        { path: `${CATEGORY_DETAILED_PATH}/${id}`, name },
        { name: 'Edit category' }
      ];
      reset(currentCategory);
    }
  }, [currentCategory?.id]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;

  const errorProperties = [
    { name: 'name', inputType: 'text', errorMessage: 'Name field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues()[name], inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleErrors = (err) => {
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    containerRef.current.scrollIntoView();
  };
  const onAgree = () => {
    switch (dialogModalData.type) {
      case NEW_CATEGORY_TYPE:
        closeModal();
        createCategoryAction(getValues()).then((err) => err?.errors && handleErrors(err));
        break;
      case UPDATE_CATEGORY_TYPE:
        closeModal();
        updateCategoryAction(getValues()).then((err) => err?.errors && handleErrors(err));
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl.current);
        break;
      default:
        break;
    }
  };

  const getBack = () =>
    id ? history.push(`${CATEGORY_DETAILED_PATH}/${id}`) : history.push(CATEGORY_LIST_PATH);
  const closeModal = () => setDialogModalData({ isOpened: false });
  const setSelectedRoute = (path) => (routeUrl.current = path);

  const handleSaveClick = () => {
    if (!validateForm()) return;
    if (id) {
      setDialogModalData({ ...UPDATE_CATEGORY, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_CATEGORY, isOpened: true });
    }
  };

  const handleBackClick = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  return (
    <section className={styles.wrapper} ref={containerRef}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={breadcrumbs.current}
          setDialogModalData={setDialogModalData}
          formIsChanged={isDirty}
          setRouteUrl={setSelectedRoute}
        />
      )}
      <div className={styles.header}>
        <h1>{id ? 'Edit category' : 'Add new category'}</h1>
        {isMobile && <BackButton onCancel={handleBackClick} />}
      </div>
      <h3>General details</h3>
      <FormProvider {...methods}>
        <section className={styles.formRow}>
          <label>Name*</label>
          <FormInputText name="name" options={{ max: 40, rule: /[^A-Za-z0-9 ]/g }} />
        </section>
        <h3>Description</h3>
        <div className={styles.formTextarea}>
          <FormTextArea name="description" options={{ max: 255 }} />
        </div>
      </FormProvider>
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleSaveClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentCategory: selectSingleCategoryData(),
  unsavedFormData: selectUnsavedFormData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getCategoryAction: getSingleCategory,
  createCategoryAction: createCategory,
  updateCategoryAction: updateCategory
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CategoryEdit);
