import { createSelector } from 'reselect';

const data = (state) => state.tmDashboardData;

export const selectTabData = () => createSelector([data], (state) => state.tab);

export const selectTabConfigData = () => createSelector([data], (state) => state.tabConfig);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

export const selectRRDashboardData = () => createSelector([data], (state) => state.RRDashboard);

export const selectLevelsDashboardData = () =>
  createSelector([data], (state) => state.levelsDashboard);

export const selectSnapshotDashboardData = () =>
  createSelector([data], (state) => state.snapshotDashboard);
