import React from 'react';
import styles from './SignaturePanel.module.scss';
import eraserIcon from 'assets/images/eraser.svg';
import SignaturePad from 'react-signature-canvas';
import { IconButton } from '@material-ui/core';

export default function SignaturePanel({ signatureRef }) {
  const handleEraserClick = () => signatureRef.current.clear();

  return (
    <>
      <label className={styles.signatureLabel}>Sign here</label>
      <div className={styles.signature}>
        <SignaturePad canvasProps={{ className: styles.pad }} ref={signatureRef} />
        <IconButton className={styles.eraser} onClick={handleEraserClick}>
          <img src={eraserIcon} alt="eraser" />
        </IconButton>
      </div>
    </>
  );
}
