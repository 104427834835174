import React from 'react';
import styles from './RequestEntities.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';

export default function EntityChip({ Chip, quantityName, resourcesName }) {
  return (
    <div className={styles.field}>
      <div className={styles.field__chip}>{Chip}</div>
      <div className={styles.field__quantity}>
        <label>Quantity*</label>
        <FormInputText
          name={quantityName}
          options={{ focus: true, type: 'quantity' }}
          classes={styles.small}
        />
      </div>
      <div className={styles.field__resources}>
        <div className={styles.images}>
          <label>Picture</label>
          <FormImagesUpload name={resourcesName} />
        </div>
        <div className={styles.files}>
          <label>File</label>
          <FormFilesUpload name={resourcesName} />
        </div>
      </div>
    </div>
  );
}
