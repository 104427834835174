import {
  SET_MANAGE_LOOSE_ITEMS_ACTIVE_ENTITY_DATA,
  CLEAR_MANAGE_LOOSE_ITEMS_STATE
} from 'constants/reduceConstants';

const initialState = {
  entityData: {
    locationLooseItemDetails: {},
    activeEntity: {},
    isPicklist: false
  },
  isEditMode: false
};

const manageLooseItemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MANAGE_LOOSE_ITEMS_ACTIVE_ENTITY_DATA:
      return {
        ...state,
        entityData: {
          locationLooseItemDetails: payload?.locationLooseItemDetails || {},
          activeEntity: payload?.activeEntity || {},
          isPicklist: payload?.isPicklist || false
        },
        isEditMode: !!payload?.locationLooseItemDetails?.id
      };
    case CLEAR_MANAGE_LOOSE_ITEMS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default manageLooseItemsReducer;
