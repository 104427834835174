import React from 'react';
import styles from './components.module.scss';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { tableHeadConfig } from './tableHeadConfig';

export default function DeliveryItemsTable({ data, total }) {
  return (
    <>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHeadComponent config={tableHeadConfig} />
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.rowId}>
                <StyledTableCell>{row?.name || ''}</StyledTableCell>
                <StyledTableCell>{row?.description || ''}</StyledTableCell>
                <StyledTableCell>{row?.qty || 0}</StyledTableCell>
                <StyledTableCell>{row?.weight || ''}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.total}>
        <span>Total weight, lbs: {total}</span>
      </div>
    </>
  );
}
