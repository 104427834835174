import { useSelector } from 'react-redux';

import { selectNotificationCenterTabData } from 'pages/UserManagement/NotificationCenter/selectors';

export function useNotificationCenterSelector() {
  const selectedTab = useSelector(selectNotificationCenterTabData());

  return {
    selectedTab
  };
}
