import React from 'react';
import styles from './FilterActions.module.scss';

import { StyledButton } from 'components/Buttons';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';

export default function FilterActions({ onClear, onCancel, onApply, isApplyDisabled }) {
  return (
    <div className={styles.actions}>
      <ClearFilterButton onClick={onClear} />
      <div className={styles.buttons}>
        <StyledButton label="Cancel" color="gray" onClick={onCancel} />
        <StyledButton label="Apply" onClick={onApply} disabled={!!isApplyDisabled} />
      </div>
    </div>
  );
}
