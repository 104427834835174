import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTMHistoryDataApi = (query) =>
  api.get(`/TeamMemberHistory?${queryToString(query)}`).then((res) => res.data);

export const getTMHistoryUserConfigApi = () =>
  api.get(`/User/TeamMemberHistoryConfig`).then((res) => res.data);

export const getTMHistoryFilterCriteriaApi = (q) =>
  api.get(`/TeamMemberHistory/FilterCriteria?${queryToString(q)}`).then((res) => res.data);

export const updateUserConfigApi = (params) =>
  api.put('/User/TeamMemberHistoryConfig', params).then((res) => res.data);
