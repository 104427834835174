import React from 'react';
import styles from './LocationInfo.module.scss';
import { formatDate } from 'helpers/AppHelpers';
import { MainButton } from 'components/StyledComponents';
import { PROJECT_DETAILED_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { useLocationSelector } from '../../../hooks/useLocationSelector';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

export default function MainInfo() {
  const history = useHistory();

  const { currentLocation } = useLocationSelector();
  const {
    project,
    city,
    state,
    zip,
    latitude,
    longitude,
    sublocations,
    active,
    isBaseCamp,
    hasFoodService,
    description,
    pocName,
    pocNumber,
    locationJobNumber
  } = currentLocation;

  const addressString = `${city || ''}${city && state?.id ? ', ' : ''}${state?.name || ''} ${
    zip || ''
  }`;

  const handleFindLocationByAddress = () => {
    const url = `https://maps.google.com/maps?q=loc:${
      (currentLocation?.address1 || '') + ' ' + (currentLocation?.address2 || '')
    } ${addressString || ''}`;
    window.open(url, '_blank');
  };
  const handleFindLocationByCoordinates = () => {
    const url = `https://www.google.com/maps/place/${latitude},${longitude}/@${latitude},${longitude},13z`;
    window.open(url, '_blank');
  };

  const getOperationalDatesLabel = () =>
    project?.operationalDateFrom && project?.operationalDateTo
      ? `${formatDate(project.operationalDateFrom)} to ${formatDate(project.operationalDateTo)}`
      : project?.operationalDateFrom || project?.operationalDateTo
      ? `${project?.operationalDateFrom ? formatDate(project.operationalDateFrom) : ''}${
          project?.operationalDateTo ? 'To ' + formatDate(project.operationalDateTo) : ''
        }`
      : '';

  const mainInfo = [
    { id: 1, label: 'Status', value: active ? 'Active' : 'Inactive' },
    { id: 2, label: 'Base Camp', value: isBaseCamp ? 'Yes' : 'No' },
    { id: 3, label: 'Food Service', value: hasFoodService ? 'Yes' : 'No' },
    { id: 4, label: 'Job Number', value: locationJobNumber || '' },
    { id: 5, label: 'Description', value: description || '' },
    { id: 6, label: 'POC Name', value: pocName || '' },
    { id: 7, label: 'POC Number', value: pocNumber || '' }
  ];

  const projectInfo = [
    { id: 1, label: 'Project Name', value: project?.name || '' },
    { id: 2, label: 'Project Number', value: project?.jobNumber || '' },
    { id: 3, label: 'Contract №', value: project?.contractNumber || '' },
    {
      id: 4,
      label: 'Mobilization Date',
      value: project?.mobilizationDate ? formatDate(project.mobilizationDate) : ''
    },
    { id: 5, label: 'Operational Dates', value: getOperationalDatesLabel() },
    {
      id: 6,
      label: 'Demobilization Dates',
      value: project?.demobilizationDate ? formatDate(project.demobilizationDate) : ''
    },
    { id: 7, label: 'Project Description', value: project?.description || '' }
  ];

  return (
    <section className={styles.main}>
      <div className={styles.main__info}>
        {mainInfo.map(({ id, label, value }) => (
          <DetailedRow key={id} label={label} value={value} disableFlex />
        ))}
      </div>
      <h3>Project Information</h3>
      {!!project?.id && (
        <div className={styles.main__info}>
          {projectInfo.map(({ id, label, value }) => (
            <DetailedRow key={id} label={label} value={value} disableFlex />
          ))}
          <MainButton
            text="View project information"
            action={() => history.push(`${PROJECT_DETAILED_PATH}/${project.id}`)}
            type="primary"
          />
        </div>
      )}

      <div className={styles.main__position}>
        {!!currentLocation?.addressAndCoordinatesNotMatched && (
          <div className={styles.main__position_mismatched}>
            <span>Address & Geo Coordinates are not matched</span>
          </div>
        )}
        <div className={styles.main__position_info}>
          <h3>Address</h3>
          {(currentLocation?.address1 || currentLocation?.address2 || addressString.trim()) && (
            <div>
              {!!currentLocation?.address1 && <p>{currentLocation.address1}</p>}
              {!!currentLocation?.address2 && <p>{currentLocation.address2}</p>}
              {!!addressString.trim() && <p>{addressString}</p>}
              {!!(currentLocation.length || addressString.trim().length) && (
                <span onClick={handleFindLocationByAddress}>Find address on Google Maps</span>
              )}
            </div>
          )}
        </div>
        <div className={styles.main__position_info}>
          <h3>Geo Coordinates</h3>
          {(latitude || longitude) && (
            <div>
              {!!latitude && <p>Latitude: {latitude}</p>}
              {!!longitude && <p>Longitude: {longitude}</p>}
              {!!latitude && !!longitude && (
                <span onClick={handleFindLocationByCoordinates}>Find location on Google Maps</span>
              )}
            </div>
          )}
        </div>
      </div>

      <section className={styles.main__sublocations}>
        <h3>Sublocations</h3>
        {!!sublocations.length && <p>{sublocations.map(({ name }) => name).join(', ')}</p>}
      </section>

      <div className={styles.main__notes}>
        <h3>Notes</h3>
        <p>{currentLocation.notes}</p>
      </div>
    </section>
  );
}
