import React, { useEffect, useState } from 'react';
import styles from './SnapshotDashboard.module.scss';

import Separator from 'components/Separator/Separator';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';

import {
  useTeamMemberDashboardActions,
  useTeamMemberDashboardSelector
} from 'hooks/TeamMemberDashboard';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';

import FilterModal from './Filter';
import Bar from './Bar';

export default function SnapshotDashboard() {
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { snapshotDashboard, tabConfig } = useTeamMemberDashboardSelector();
  const { clearTabStateAction } = useTeamMemberDashboardActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'snapshotDashboard' });
    };
  }, []);

  const handleFilterButtonClick = (sectionId) => {
    const filterData = tabConfig?.items?.find(({ id }) => id === sectionId) || {};

    if (!('id' in filterData)) return;

    setFilterData(filterData);
    setOpen(true);
  };

  return (
    <DashboardWrapper>
      <FilterModal open={open} setOpen={setOpen} filter={filterData} />
      {!!snapshotDashboard?.length && (
        <div className={styles.statistics}>
          {snapshotDashboard.map((sectionData, index) => (
            <div key={sectionData.id} className={styles.statistics__dashboard}>
              <SectionHeader title={sectionData.name || ''} />

              <FilterWrapper>
                <FilterButton
                  onClick={() => handleFilterButtonClick(sectionData.id)}
                  isActive={!!sectionData?.department?.id}
                />
                {!!sectionData?.department?.id && (
                  <FilterChips
                    chips={[{ chipId: 100, label: sectionData?.department?.name || '' }]}
                  />
                )}
              </FilterWrapper>

              <Bar dashboard={sectionData} />

              {snapshotDashboard.length > 1 && index !== snapshotDashboard.length - 1 && (
                <Separator />
              )}
            </div>
          ))}
        </div>
      )}
    </DashboardWrapper>
  );
}
