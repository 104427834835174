import React from 'react';
import styles from './components.module.scss';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

export default function AddHeaderButton({ onClick }) {
  const handleClick = () => onClick();

  const isDisabled = false;

  return (
    <Button
      className={clsx(styles.add_button, isDisabled && styles.add_button__disabled)}
      onClick={handleClick}>
      <AddIcon />
      <label>Add header</label>
    </Button>
  );
}
