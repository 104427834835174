import React from 'react';
import styles from './form.module.scss';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function LicenseChip({ Chip, dateName }) {
  const isMobile = useMobileViewport();
  return (
    <div className={styles.license}>
      <div className={styles.license__chip}>{Chip}</div>
      <div className={styles.license__dates}>
        {!isMobile && <label>Expiration Date</label>}
        <FormDatePicker name={dateName} />
      </div>
    </div>
  );
}
