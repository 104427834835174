import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { useLocationActions } from '../../../../../../hooks/useLocationActions';
import { useLocationSelector } from '../../../../../../hooks/useLocationSelector';

import { displayNameToName, enrichNameToId } from 'helpers/AppHelpers';
import { FormProvider, useForm } from 'react-hook-form';

export default function Filter({ onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { sublocationIds: [], rentalIds: [], prefixIds: [], severities: [] },
    mode: 'onChange'
  });
  const { setValue, getValues, reset } = methods;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({});

  const [filterValues, setFilterValues] = useState({});

  const { getAssetsFilterCriteriaAction } = useLocationActions();
  const {
    currentLocation: { id }
  } = useLocationSelector();

  useEffect(() => {
    getAssetsFilterCriteriaAction().then((res) => {
      const assetPrefixes = res.equipments.map((item) => item.assetPrefixes) || [];
      const prefixes = [].concat.apply([], assetPrefixes);
      const sortedPrefixes = prefixes.sort((a, b) => a.prefix.localeCompare(b.prefix));
      setOptions({
        ...res,
        sublocations: res?.locations?.find((loc) => loc.id === id)?.sublocations || [],
        prefixes: sortedPrefixes
      });
    });
  }, []);

  useEffect(() => {
    if (open) {
      reset(filterValues);
    }
  }, [open]);

  const handleClearClick = () => {
    setValue('sublocationIds', []);
    setValue('rentalIds', []);
    setValue('prefixIds', []);
    setValue('severities', []);
  };

  const handleBackClick = () => setOpen(false);

  const handleIconClick = (value) => setOpen(value || !open);

  const handleMultiSelect = (name, value) => setValue(name, value);

  const handleClickApply = () => {
    onApply(getValues());
    setOpen(false);
    setFilterValues(getValues());
  };

  const isFilterActive = () =>
    open ||
    filterValues?.sublocationIds?.length ||
    filterValues?.rentalIds?.length ||
    filterValues?.prefixIds?.length ||
    filterValues?.severities?.length;

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      isFilterActive={isFilterActive}
      areChipsAvailable={false}>
      <FormProvider {...methods}>
        <section className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Prefix</label>
              <FormAutoMultiSelect
                name="prefixIds"
                menuItems={options?.prefixes || []}
                options={{
                  label: 'prefix',
                  disableByObjectTracker: true,
                  disableLabel: true
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Sublocation</label>
              <FormAutoMultiSelect
                name="sublocationIds"
                menuItems={options?.sublocations || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true
                }}
              />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Status</label>
              <FormMultiSelect
                name="severities"
                options={displayNameToName(enrichNameToId(options?.severities)) || []}
                onSelect={handleMultiSelect}
                trackById
              />
            </div>
            <div className={styles.block__cell}>
              <label className={styles.block__cell_label}>Rental Status</label>
              <FormMultiSelect
                name="rentalIds"
                options={options?.rentals || []}
                onSelect={handleMultiSelect}
                trackById
              />
            </div>
          </div>
        </section>
      </FormProvider>
    </FilterWrapper>
  );
}
