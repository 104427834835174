import {
  SET_ASSET_DASHBOARD_DATA,
  SET_ASSET_DASHBOARD_PREFILTER_DATA,
  SET_ASSET_DASHBOARD_PREFIXES_DATA,
  SET_ASSET_DASHBOARD_FIELDS_DATA,
  CLEAR_ASSET_FIELDS_DASHBOARD_DATA
} from 'constants/reduceConstants';

const initialState = {
  dashboardData: [],
  prefilter: {},
  prefixes: [],
  fields: []
};

const overviewReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ASSET_DASHBOARD_DATA:
      return { ...state, dashboardData: payload };
    case SET_ASSET_DASHBOARD_PREFILTER_DATA:
      return { ...state, prefilter: payload };
    case SET_ASSET_DASHBOARD_PREFIXES_DATA:
      return { ...state, prefixes: payload };
    case SET_ASSET_DASHBOARD_FIELDS_DATA:
      return { ...state, fields: payload };
    case CLEAR_ASSET_FIELDS_DASHBOARD_DATA:
      return initialState;
    default:
      return state;
  }
};

export default overviewReportReducer;
