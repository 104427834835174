import { isSuccessfulStatus } from 'helpers/AppHelpers';
import api from '../api';
import {
  SET_TICKETS_DASHBOARD_TAB,
  SET_TICKETS_DASHBOARD_BASE_STATISTICS,
  CLEAR_TICKETS_DASHBOARD_STATE,
  SET_TICKETS_DASHBOARD_TAB_CONFIG,
  SET_TICKETS_DASHBOARD_TAB_FILTER_CRITERIA,
  SET_TICKETS_DASHBOARD_TURNOVER_STATISTICS,
  CLEAR_TICKETS_DASHBOARD_TAB_STATE,
  SET_TICKETS_DASHBOARD_FLOW_STATISTICS
} from '../constants/reduceConstants';

export const getDashboardConfig = (query) => (dispatch) =>
  api.ticketsDashboard
    .getDashboardConfigApi(query)
    .then((res) => {
      dispatch({ type: SET_TICKETS_DASHBOARD_TAB_CONFIG, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateTabConfig = (data) => (dispatch) =>
  api.ticketsDashboard
    .updateTabConfigApi(data)
    .then((res) => {
      if (isSuccessfulStatus(res.status)) {
        dispatch({ type: SET_TICKETS_DASHBOARD_TAB_CONFIG, payload: res.data });
      }
      return res.data;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const updateSectionConfig = (data) => () =>
  api.ticketsDashboard
    .updateSectionConfigApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err?.request?.response);
      return errors;
    });

export const getFilterCriteria = () => (dispatch) =>
  api.ticketsDashboard
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_TICKETS_DASHBOARD_TAB_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getAssetOptions = (q) => () =>
  api.ticketsDashboard
    .getAssetOptionsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getBaseStatistics = () => (dispatch) =>
  api.ticketsDashboard
    .getBaseStatisticsApi()
    .then((res) => {
      dispatch({ type: SET_TICKETS_DASHBOARD_BASE_STATISTICS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getTurnoverStatistics = () => (dispatch) =>
  api.ticketsDashboard
    .getTurnoverStatisticsApi()
    .then((res) => {
      dispatch({ type: SET_TICKETS_DASHBOARD_TURNOVER_STATISTICS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getFlowStatistics = () => (dispatch) =>
  api.ticketsDashboard
    .getFlowStatisticsApi()
    .then((res) => {
      dispatch({ type: SET_TICKETS_DASHBOARD_FLOW_STATISTICS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getBasicStatisticCsvResource = (data) => () =>
  api.ticketsDashboard
    .getBasicStatisticCsvResourceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getTurnoverStatisticCsvResource = (data) => () =>
  api.ticketsDashboard
    .getTurnoverStatisticCsvResourceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getFlowStatisticCsvResource = (data) => () =>
  api.ticketsDashboard
    .getFlowStatisticCsvResourceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setTab = (payload) => (dispatch) => {
  dispatch({ type: SET_TICKETS_DASHBOARD_TAB, payload });
};

export const clearTabState = (payload) => (dispatch) => {
  dispatch({ type: CLEAR_TICKETS_DASHBOARD_TAB_STATE, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_TICKETS_DASHBOARD_STATE });
};
