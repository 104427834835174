import React from 'react';
import styles from './Details.module.scss';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';

export default function AddressInfo() {
  const { address1, address2, city, state, latitude, longitude, zip } = useSelector(
    selectSingleProjectData()
  );

  const addressString = `${city || ''}${city && state?.id ? ', ' : ''}${state?.name || ''} ${
    zip || ''
  }`;

  const handleFindLocationByAddress = () => {
    const url = `https://maps.google.com/maps?q=loc:${(address1 || '') + ' ' + (address2 || '')} ${
      addressString || ''
    }`;
    window.open(url, '_blank');
  };
  const handleFindLocationByCoordinates = () => {
    const url = `https://www.google.com/maps/place/${latitude},${longitude}/@${latitude},${longitude},13z`;
    window.open(url, '_blank');
  };

  return (
    <section className={styles.address}>
      <div className={styles.address__position}>
        <h3>Address</h3>
        {(address1 || address2 || addressString.trim()) && (
          <div>
            {!!address1 && <p>{address1}</p>}
            {!!address2 && <p>{address2}</p>}
            {!!addressString.trim() && <p>{addressString}</p>}
            {!!addressString.trim().length && (
              <span onClick={handleFindLocationByAddress}>Find address on Google Maps</span>
            )}
          </div>
        )}
      </div>
      <div className={styles.address__position}>
        <h3>Geo Coordinates</h3>
        {(latitude || longitude) && (
          <div>
            {!!latitude && <p>Latitude: {latitude}</p>}
            {!!longitude && <p>Longitude: {longitude}</p>}
            {!!latitude && !!longitude && (
              <span onClick={handleFindLocationByCoordinates}>Find location on Google Maps</span>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
