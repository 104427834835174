import React, { useState } from 'react';
import styles from './styles.module.scss';

import StyledLink from 'components/StyledLink';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function AccordionWrapper({ link, title, count, children }) {
  const [open, setOpen] = useState(false);

  const handleIconClick = () => setOpen(!open);

  return (
    <div className={styles.accordion}>
      <Accordion expanded={open}>
        <AccordionSummary
          classes={{ root: styles.accordion__summary }}
          expandIcon={<ExpandMoreIcon onClick={handleIconClick} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className={styles.accordion__content}>
            <h2>{title}</h2>

            <StyledLink bold to={link}>
              {count}
            </StyledLink>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.accordion__details }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
