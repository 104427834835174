import React, { useEffect, useState } from 'react';
import styles from './components.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import {
  EXIT_WITHOUT_SAVING,
  UPDATE_USER,
  UPDATE_USER_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { USER_DETAILED_PATH } from 'constants/routeConstants';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { getBackendErrors } from 'helpers/AppHelpers';

import { useHistory, useParams } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserActions, useUserSelector } from 'hooks/UserManagement';
import { useFormContext } from 'react-hook-form';
import { useUserConfig } from 'hooks/useUserConfig';

export default function UserEditWrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const { userId } = useUserConfig();

  const { currentUser, unsavedFormData, departments } = useUserSelector();
  const {
    getUserAction,
    setUnsavedFormDataAction,
    getActiveLocationsAction,
    getUserRolesAction,
    getDepartmentsAction,
    updateUserAction,
    getUserConfigAction,
    getPrefixListAction,
    setUserEditTabAction,
    clearStateAction
  } = useUserActions();

  const { formState, reset, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (id) {
      getUserAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentUser?.id) {
      const { requestOrderRole } = currentUser;
      const orderRole = requestOrderRole?.name
        ? { ...requestOrderRole, id: requestOrderRole.name }
        : null;

      reset({ ...currentUser, requestOrderRole: orderRole });
    }
  }, [currentUser]);

  useEffect(() => {
    getActiveLocationsAction({ filters: { active: true } });
  }, []);

  useEffect(() => {
    getPrefixListAction();
  }, []);

  useEffect(() => {
    getUserRolesAction();
  }, []);

  useEffect(() => {
    getDepartmentsAction();
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const getBack = () => history.push(`${USER_DETAILED_PATH}/${id}`);

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const handleSuccess = (res) => res?.id === userId && getUserConfigAction();
  const handleError = (err) =>
    err?.errors &&
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));

  const closeModal = () => setModalData({ isOpened: false });
  const agreeModal = () => {
    switch (modalData.type) {
      case UPDATE_USER_TYPE: {
        closeModal();
        const data = getValues();
        if (data?.requestOrderRole?.name === '-') {
          data.requestOrderRole = null;
        }
        data.isSelectedAllDepartments = departments?.length === data?.departments?.length;
        updateUserAction(data, { skipRouting: false }).then(handleSuccess, handleError);
        break;
      }
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const validationRules = [
    { name: 'firstName', inputType: 'text', errorMessage: 'First Name field is required' },
    { name: 'lastName', inputType: 'text', errorMessage: 'Last Name field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    let tabToRedirect = '';

    validationRules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    if (getValues('userRole').role !== 'Admin') {
      if (isParameterInvalid(getValues('locations'), 'array')) {
        setError('locations', getErrorsProperties('Locations field is required'), {
          shouldFocus: true
        });
        isFormValid = false;
      }
    }

    !isFormValid && (tabToRedirect = 'general');

    if (getValues('userRole').role === 'Personnel manager') {
      if (isParameterInvalid(getValues('departments'), 'array')) {
        setError('departments', getErrorsProperties('Department field is required'), {
          shouldFocus: true
        });
        isFormValid = false;
        tabToRedirect === '' && (tabToRedirect = 'schedule');
      }
    }

    tabToRedirect && setUserEditTabAction(tabToRedirect);
    return isFormValid;
  };

  const handleSaveClick = () => {
    if (!validateForm()) return;
    setModalData(UPDATE_USER);
  };

  return (
    currentUser?.id === +id && (
      <section className={styles.wrapper}>
        <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
        <div className={styles.header}>
          {!isMobile && (
            <BreadcrumbsNav
              itemsArray={[
                {
                  path: `${USER_DETAILED_PATH}/${id}`,
                  name: currentUser.firstName + ' ' + currentUser.lastName
                },
                { name: 'Edit User' }
              ]}
              setDialogModalData={setModalData}
              formIsChanged={isDirty}
            />
          )}
          <div className={styles.header__block}>
            <h1>Edit User</h1>
            {isMobile && <BackButton onCancel={handleCancelClick} />}
          </div>
        </div>
        {children}
        <div className={styles.footer}>
          <MainButton text="cancel" type="secondary" action={handleCancelClick} />
          <MainButton
            text="Save"
            type="primary"
            action={handleSaveClick}
            isDisabled={isFormInvalid()}
          />
        </div>
      </section>
    )
  );
}
