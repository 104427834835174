import React, { useEffect, useState } from 'react';
import { truncateString } from 'helpers/AppHelpers';
import { TEAM_MEMBER_EDIT_PATH, TEAM_MEMBER_LIST_PATH } from 'constants/routeConstants';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import restoreIcon from 'assets/images/restore.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../selectors';
import { useUserConfig } from 'hooks/useUserConfig';
import DetailsHeader from 'components/DetailsComponents/Header';
import HeaderButton from 'components/DetailsComponents/HeaderButton';

const truncateLength = 33;

export default function Header({ onDelete, onRestore }) {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const { firstName, lastName, deleted } = currentTeamMember;
  const isTeamMemberDeleted = () => deleted;

  useEffect(() => {
    if (currentTeamMember?.id) {
      const { firstName, lastName } = currentTeamMember;
      const crumbName = firstName + ' ' + lastName + ' Profile';
      if (isAdminUser || isPersonnelManagerUser) {
        setBreadcrumbs([
          { name: 'Team list', path: TEAM_MEMBER_LIST_PATH },
          { name: truncateString(crumbName, truncateLength) }
        ]);
      }
    }
  }, [currentTeamMember]);

  const handleDeleteClick = () => onDelete();
  const handleRestoreClick = () => onRestore();

  const handleEditClick = () => history.push(`${TEAM_MEMBER_EDIT_PATH}/${currentTeamMember.id}`);
  const handleBackClick = () => history.push(TEAM_MEMBER_LIST_PATH);

  const title = `${truncateString(firstName + ' ' + lastName + ' Profile', truncateLength)}${
    isTeamMemberDeleted() ? ' (Deleted)' : ''
  }`;

  return (
    <DetailsHeader breadcrumbs={breadcrumbs} title={title}>
      {(isAdminUser || isPersonnelManagerUser) && !isTeamMemberDeleted() && (
        <HeaderButton onClick={handleEditClick}>
          <CreateIcon />
          {!isMobile && <span>Edit team member</span>}
        </HeaderButton>
      )}
      {(isAdminUser || isPersonnelManagerUser) && !isTeamMemberDeleted() && (
        <HeaderButton onClick={handleDeleteClick}>
          <DeleteIcon />
          {!isMobile && <span>Remove team member</span>}
        </HeaderButton>
      )}
      {(isAdminUser || isPersonnelManagerUser) && isTeamMemberDeleted() && (
        <HeaderButton onClick={handleRestoreClick}>
          <img src={restoreIcon} alt="restore" />
          {!isMobile && <span>Restore team member</span>}
        </HeaderButton>
      )}
      {isMobile && (isAdminUser || isPersonnelManagerUser) && (
        <HeaderButton onClick={handleBackClick}>
          <ChevronLeftIcon />
        </HeaderButton>
      )}
    </DetailsHeader>
  );
}
