import React, { useEffect, useState } from 'react';
import styles from './AutocompleteWithSelectAll.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClickAwayListener, Popper, TextField } from '@material-ui/core';
import {
  allKeyboardLatSymbols,
  getProjectName,
  getTeamMemberFullName,
  getUserFullName
} from 'helpers/AppHelpers';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AutocompleteChip from './components/AutocompleteChip';
import AutocompleteRenderOption from './components/AutocompleteRenderOption';
import clsx from 'clsx';

const OPTION_ALL_TITLE = 'Select All';
const autocompleteClasses = {
  inputRoot: styles.inputRoot,
  noOptions: styles.noOptions,
  tag: styles.tag,
  popper: styles.popper,
  popupIndicator: styles.popupIndicator
};

const MyPopper = (props) => (
  <Popper {...props} className={styles.popperStyles} placement="bottom-start" />
);

export default function AutocompleteWithSelectAll({
  name,
  value,
  labelType,
  options,
  onSelect,
  label,
  isDisabled,
  isInvalid,
  specialView,
  selectAll,
  hideTags,
  disableByObjectTracker,
  renderOptionLabel
}) {
  const allOption = { id: OPTION_ALL_TITLE, [label]: OPTION_ALL_TITLE };
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  const closeDropdown = () => setOpen(false);
  const toggleDropdown = () => setOpen(!open);

  useEffect(() => {
    if (!open && !!inputValue) setInputValue('');
  }, [open]);

  const handleOptionClick = (e, option, selectionType, processedOption) => {
    if (selectionType === 'remove-option' && e.type === 'keydown') return; // bug fix (DRT-8293)
    if (processedOption?.option[label] === OPTION_ALL_TITLE) {
      onSelect(
        name,
        selectionType === 'remove-option'
          ? []
          : disableByObjectTracker
          ? [...options].map(({ id }) => id)
          : [...options],
        selectionType,
        processedOption
      );
      return;
    }
    switch (selectionType) {
      case 'remove-option':
        onSelect(
          name,
          disableByObjectTracker
            ? value.filter((id) => id !== processedOption.option.id)
            : value.filter(({ id }) => id !== processedOption.option.id),
          selectionType,
          processedOption
        );
        break;
      case 'clear':
        onSelect(name, [], selectionType, processedOption);
        break;
      default:
        onSelect(
          name,
          disableByObjectTracker
            ? value.concat([processedOption.option.id])
            : value.concat([processedOption.option]),
          selectionType,
          processedOption
        );
        break;
    }
  };

  const getOptionLabel = (option) => {
    switch (labelType) {
      case 'project':
        return getProjectName(option);
      case 'teamMember':
        return getTeamMemberFullName(option);
      case 'user':
        return getUserFullName(option);
      default:
        return option[label];
    }
  };

  const getOptionLabelById = (optionId) => {
    if (!options?.length) return '';
    const needOption = options?.find(({ id }) => id === optionId) || {};

    switch (labelType) {
      case 'project':
        return getProjectName(needOption);
      case 'teamMember':
        return getTeamMemberFullName(needOption);
      case 'user':
        return getUserFullName(needOption);
      default:
        return needOption[label];
    }
  };

  const getSecondaryOptionLabel = (option) => {
    return disableByObjectTracker ? getOptionLabelById(option) : getOptionLabel(option);
  };

  const isCheckboxSelected = (option) =>
    option.id === OPTION_ALL_TITLE
      ? value.length === options.length
      : disableByObjectTracker
      ? value.includes(option.id)
      : value.map(({ id }) => id).includes(option.id);

  const handleInputChange = (e, value, reason) => {
    if (reason === 'reset') return;

    let interValue = e?.target?.value || value || '';
    interValue = interValue.replace(allKeyboardLatSymbols, '');
    setInputValue(interValue);
  };

  const handleChipDelete = (chipId) => {
    onSelect(
      name,
      disableByObjectTracker
        ? value.filter((el) => el !== chipId)
        : value.filter(({ id }) => id !== chipId),
      'chip-delete',
      chipId
    );
  };

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div
        className={clsx(
          styles.autocomplete,
          specialView && specialView,
          isDisabled && styles.disabled,
          isInvalid && styles.invalid
        )}>
        <Autocomplete
          open={open}
          value={value}
          classes={autocompleteClasses}
          multiple
          disableCloseOnSelect
          disableClearable={hideTags}
          onOpen={toggleDropdown}
          onClose={toggleDropdown}
          limitTags={1}
          id="autocomplete-select-all"
          onChange={handleOptionClick}
          options={selectAll ? [allOption, ...options] : [...options]}
          inputValue={inputValue}
          getOptionLabel={getOptionLabel}
          onInputChange={handleInputChange}
          noOptionsText={inputValue.length ? 'No matches' : 'No available items'}
          disabled={isDisabled}
          popupIcon={<ExpandMore />}
          getOptionSelected={isCheckboxSelected}
          PopperComponent={MyPopper}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option) => (
            <AutocompleteRenderOption
              label={getOptionLabel(option)}
              selected={isCheckboxSelected(option)}
              OptionLabelComponent={renderOptionLabel ? renderOptionLabel(option) : null}
            />
          )}
          renderTags={(_, getTagProps) =>
            !hideTags &&
            value?.length &&
            value.slice(0, 1).map((option, index) => (
              <div className={styles.tagWrapper} key={index}>
                <AutocompleteChip
                  chipId={disableByObjectTracker ? option : option.id}
                  label={getSecondaryOptionLabel(option)}
                  onDelete={handleChipDelete}
                  chipOptions={getTagProps({ index })}
                />
                {value?.length > 1 && <span>+{value?.length - 1}</span>}
              </div>
            ))
          }
        />
      </div>
    </ClickAwayListener>
  );
}
