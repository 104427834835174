import { useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { setTopPosition } from 'actions/commonActions';
import { useMobileViewport } from 'hooks/useMobileViewport';

const commonData = (state) => state.commonData;
const selectTopPosition = () => createSelector([commonData], (state) => state.topPosition);

export default function ScrollToPosition({ elem }) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();
  const topPosition = useSelector(selectTopPosition());

  useEffect(() => {
    if (topPosition) {
      const desktopElemPosition = elem.current.scrollTop + topPosition - 60 - 20;
      const mobileElemPosition = window.pageYOffset + topPosition - 60 - 38;
      !isMobile
        ? (elem.current.scrollTop = desktopElemPosition)
        : window.scrollTo({ top: mobileElemPosition, behavior: 'smooth' });
      dispatch(setTopPosition(0));
    }
  }, [topPosition]);

  return null;
}
