import React from 'react';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { ELLIPSIS_SYMBOL, getTableCellsCount, ROWS_LIMIT } from '../../../helpers';
import {
  RenderAssets,
  RenderBillOfLadings,
  RenderLocations,
  RenderLooseItems,
  RenderPicklists,
  RenderProjects,
  RenderTickets,
  RenderAssetReports,
  RenderLocationReports
} from './renders';

function EllipsisRow({ length }) {
  return (
    <StyledTableRow>
      {new Array(length).fill('').map((_, index) => (
        <StyledTableCell key={index}>{ELLIPSIS_SYMBOL}</StyledTableCell>
      ))}
    </StyledTableRow>
  );
}

const renderTableCells = (tableName, rows) => {
  switch (tableName) {
    case 'assets':
      return <RenderAssets rows={rows} />;
    case 'billOfLadings':
      return <RenderBillOfLadings rows={rows} />;
    case 'picklists':
    case 'leavelists':
      return <RenderPicklists rows={rows} />;
    case 'locations':
      return <RenderLocations rows={rows} />;
    case 'looseItems':
      return <RenderLooseItems rows={rows} />;
    case 'projects':
      return <RenderProjects rows={rows} />;
    case 'serviceTickets':
      return <RenderTickets rows={rows} />;
    case 'assetReports':
      return <RenderAssetReports rows={rows} />;
    case 'locationReports':
      return <RenderLocationReports rows={rows} />;
    default:
      return null;
  }
};

export default function TableSwitcher({ tableName, rows }) {
  return (
    <>
      {renderTableCells(tableName, rows)}
      {rows.length > ROWS_LIMIT && <EllipsisRow length={getTableCellsCount(tableName)} />}
    </>
  );
}
