import {
  SET_MOVED_LOOSE_ITEMS_DATA,
  CLEAR_MOVED_LOOSE_ITEMS_STATE
} from 'constants/reduceConstants';

const initialState = {
  movedLooseItems: []
};

const movedLooseItemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MOVED_LOOSE_ITEMS_DATA:
      return {
        ...state,
        movedLooseItems: payload
      };
    case CLEAR_MOVED_LOOSE_ITEMS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default movedLooseItemsReducer;
