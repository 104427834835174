import React from 'react';
import styles from './TablesModal.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';

import AssetTablesToggle from '../Tables/AssetTablesToggle';

export default function TablesModal({ open, setOpen }) {
  const closePopup = () => setOpen(false);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <section className={styles.wrapper}>
        <DialogHeader onClose={closePopup} title="History & Tickets"></DialogHeader>
        <div className={styles.content}>
          <AssetTablesToggle />
        </div>
      </section>
    </DialogWrapper>
  );
}
