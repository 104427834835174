import { isSuccessfulStatus, enqueueSuccessSnackbar } from 'helpers/AppHelpers';
import api from '../api';
import {
  SET_TRAININGS_FILTER,
  CLEAR_TRAININGS_STATE,
  SET_LOAD_MORE_FLAG,
  SET_TRAININGS_FILTER_CRITERIA
} from '../constants/reduceConstants';
import {
  SUCCESS_TRAININGS_WERE_UPDATED,
  SUCCESS_TRAINING_WAS_DELETED
} from 'constants/infoSnackbarData';

export const getTrainings = (query) => (dispatch) =>
  api.trainings
    .getTrainingsApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getFilterCriteria = () => (dispatch) =>
  api.trainings
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_TRAININGS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getGroups = (query) => () =>
  api.trainings
    .getGroupsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createGroup = (data) => () =>
  api.trainings
    .createGroupApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const updateGroup = (data) => () =>
  api.trainings
    .updateGroupApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const deleteGroup = (query) => () =>
  api.trainings
    .deleteGroupApi(query)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const createCertification = (data) => () =>
  api.trainings
    .createCertificationApi(data)
    .then((res) => {
      isSuccessfulStatus(res.status) && enqueueSuccessSnackbar(SUCCESS_TRAININGS_WERE_UPDATED);
      return res;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const updateCertification = (data) => () =>
  api.trainings
    .updateCertificationApi(data)
    .then((res) => {
      isSuccessfulStatus(res.status) && enqueueSuccessSnackbar(SUCCESS_TRAININGS_WERE_UPDATED);
      return res;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const deleteCertification = (q) => () =>
  api.trainings
    .deleteCertificationApi(q)
    .then((res) => {
      isSuccessfulStatus(res.status) && enqueueSuccessSnackbar(SUCCESS_TRAINING_WAS_DELETED);
      return res;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_TRAININGS_FILTER, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_TRAININGS_STATE });
};
