import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getRandomId } from 'helpers/AppHelpers';
import {
  TextReportTemplate,
  ChoiceReportTemplate,
  TitleReportTemplate,
  LineReportTemplate,
  TemplatesReportTemplate,
  ResourceReportTemplate,
  TableReportTemplate,
  SignatureReportTemplate
} from 'components/ReportTemplates/';
import { Button } from '@material-ui/core';
import { MainButton } from 'components/StyledComponents';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TitleIcon from '@material-ui/icons/Title';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PictureIcon from 'assets/images/picture.svg';
import TableIcon from 'assets/images/customTable.svg';
import SignatureIcon from 'assets/images/signature.svg';
import styles from './ReportTemplateEditor.module.scss';
import { useContainerRef } from 'hooks/useContainerRef';

function ReportConstructor({
  reportState,
  Breadcrumbs,
  setReportState,
  locationTemplates,
  backClickHandle,
  onSave,
  onPublish
}) {
  const containerRef = useContainerRef();

  const history = useHistory();
  const [draggable, setDraggable] = useState(false);
  const { name, reportType, generalReportFields, published } = reportState;

  const setReportTemplate = (template) => {
    setReportState({ ...reportState, generalReportFields: template });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((item, index) => {
      return { ...item, order: index };
    });
  };

  const handleDragStart = () => {
    containerRef.current.style.scrollBehavior = 'auto';
  };

  const handleDragEnd = (result) => {
    setDraggable(false);
    if (!result.destination) return;
    const items = reorder(generalReportFields, result.source.index, result.destination.index);
    setReportTemplate(items);
    containerRef.current.style.scrollBehavior = '';
  };

  const addComponent = (data) => {
    setReportTemplate(
      generalReportFields.concat({
        id: getRandomId(),
        generalReportFieldType: data,
        data: {
          compare: false,
          multiple: false,
          required: false,
          active: [1, 2, 3, 4, 7, 8, 9].includes(data.id)
        }
      })
    );
  };

  const onUpdateTemplate = (index, data) => {
    const templatesArr = [...generalReportFields];
    templatesArr[index] = data;
    setReportTemplate(templatesArr);
  };

  const getPresetTemplates = () => {
    const addedTemplates = generalReportFields
      .filter(({ generalReportFieldType: { fieldType } }) => fieldType === 'LocationTemplate')
      .map(({ templateId }) => templateId)
      .filter(Boolean);
    return (
      locationTemplates?.filter(({ templateId }) => !addedTemplates.includes(templateId)) || []
    );
  };
  const areLocationTemplatesAvailable = () => {
    const addedTemplates = generalReportFields
      .filter(({ generalReportFieldType: { fieldType } }) => fieldType === 'LocationTemplate')
      .map(({ templateId }) => templateId)
      .filter(Boolean);
    const templates =
      locationTemplates?.filter(({ templateId }) => !addedTemplates.includes(templateId)) || [];
    return templates.length >= 1;
  };

  const onAddPrestTemplates = (index, data) => {
    const stateCopy = [...generalReportFields];
    const templatesArr = data.map((item) => {
      return {
        id: getRandomId(),
        generalReportFieldType: { id: 1, fieldType: 'LocationTemplate' },
        active: false,
        data: { ...item.data },
        templateId: item.templateId
      };
    });
    stateCopy.splice(index, 1, ...templatesArr);
    setReportTemplate(stateCopy);
  };

  const onCopyTemplate = (index) => {
    setReportTemplate(
      generalReportFields.concat({
        ...generalReportFields[index],
        id: getRandomId(),
        data: { ...generalReportFields[index].data, active: true }
      })
    );
  };

  const onDeleteTemplate = (index) => {
    const templatesArr = [...generalReportFields];
    templatesArr.splice(index, 1);
    setReportTemplate(templatesArr);
  };

  const isPublishDisabled = () => {
    return (
      !generalReportFields.filter(
        ({ generalReportFieldType: { fieldType } }) =>
          fieldType === 'Text' ||
          fieldType === 'Choice' ||
          fieldType === 'LocationTemplate' ||
          fieldType === 'Resource' ||
          fieldType === 'Table'
      ).length ||
      generalReportFields.filter(
        ({ generalReportFieldType: { fieldType }, data: { active } }) =>
          (fieldType === 'Text' ||
            fieldType === 'Choice' ||
            fieldType === 'LocationTemplate' ||
            fieldType === 'Resource' ||
            fieldType === 'Table') &&
          active
      ).length
    );
  };

  const onBackClick = () => (published ? history.goBack() : backClickHandle(true));

  const isPictureTemplateAvailable = () =>
    generalReportFields.filter(
      ({ generalReportFieldType: { fieldType } }) => fieldType === 'Resource'
    ).length < 50;
  const isSignatureTemplateAvailable = () =>
    generalReportFields.filter(
      ({ generalReportFieldType: { fieldType } }) => fieldType === 'Signature'
    ).length < 5;

  return (
    <section className={styles.pageContainer}>
      <Breadcrumbs />
      <div className={styles.pageHeader}>
        <h1>{name || 'Report Editor'}</h1>
        <section className={styles.rowWrapper}>
          <label>Report type</label>
          <p>{reportType.name}</p>
        </section>
        {!!reportState.locations?.length && (
          <section className={styles.rowWrapper}>
            <label>Locations</label>
            <p>
              {reportState.locations.map(({ siteCode }, index) => (
                <span key={index}>
                  {siteCode}
                  {index < reportState.locations.length - 1 && `, `}
                </span>
              ))}
            </p>
          </section>
        )}
        {!!reportState.assetCategories?.length && (
          <section className={styles.rowWrapper}>
            <label>Categories</label>
            <p>
              {reportState.assetCategories.map(({ name }, index) => (
                <span key={index}>
                  {name}
                  {index < reportState.assetCategories.length - 1 && `, `}
                </span>
              ))}
            </p>
          </section>
        )}
        {!!reportState.assetPrefixes?.length && (
          <section className={styles.rowWrapper}>
            <label>Prefixes</label>
            <p>
              {reportState.assetPrefixes.map(({ prefix }, index) => (
                <span key={index}>
                  {prefix}
                  {index < reportState.assetPrefixes.length - 1 && `, `}
                </span>
              ))}
            </p>
          </section>
        )}
      </div>
      <section className={styles.mainInfoBlock}>
        <section className={styles.mainInfoBlock__container}>
          <section className={styles.templates}>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {generalReportFields
                      ?.sort((a, b) => a.order - b.order)
                      .map((template, index) => (
                        <Draggable
                          key={`${template.type}-${index}`}
                          draggableId={`${template.type}-${index}`}
                          isDragDisabled={!draggable}
                          index={index}>
                          {(provided) => (
                            <section
                              key={template.id}
                              className={styles.templateCell}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              {(() => {
                                switch (template.generalReportFieldType.fieldType) {
                                  case 'Text':
                                    return (
                                      <TextReportTemplate
                                        index={index}
                                        template={template}
                                        isLocationReport={!!reportState.locations?.length}
                                        dragIsActive={draggable}
                                        reportType={reportType.name}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                      />
                                    );
                                  case 'Choice':
                                    return (
                                      <ChoiceReportTemplate
                                        index={index}
                                        template={template}
                                        dragIsActive={draggable}
                                        reportType={reportType.name}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                      />
                                    );
                                  case 'Title':
                                    return (
                                      <TitleReportTemplate
                                        index={index}
                                        template={template}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                      />
                                    );
                                  case 'Line':
                                    return (
                                      <LineReportTemplate
                                        index={index}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                      />
                                    );
                                  case 'LocationTemplate':
                                    return (
                                      <TemplatesReportTemplate
                                        index={index}
                                        template={template}
                                        presetData={getPresetTemplates()}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onAdd={onAddPrestTemplates}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                      />
                                    );
                                  case 'Resource':
                                    return (
                                      <ResourceReportTemplate
                                        index={index}
                                        template={template}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                        isCopyAvailable={isPictureTemplateAvailable}
                                      />
                                    );
                                  case 'Table':
                                    return (
                                      <TableReportTemplate
                                        index={index}
                                        template={template}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                        reportType={reportType.name}
                                      />
                                    );
                                  case 'Signature':
                                    return (
                                      <SignatureReportTemplate
                                        index={index}
                                        template={template}
                                        dragIsActive={draggable}
                                        onDrag={setDraggable}
                                        onUpdate={onUpdateTemplate}
                                        onCopy={onCopyTemplate}
                                        onDelete={onDeleteTemplate}
                                        published={published}
                                        isCopyAvailable={isSignatureTemplateAvailable}
                                      />
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </section>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </section>
          {!published && (
            <section className={styles.templatesButtons}>
              <Button
                className={styles.addComponentButton}
                onClick={() => addComponent({ id: 8, fieldType: 'Table' })}>
                <img src={TableIcon} alt="table-alt" />
                Custom Table
              </Button>
              <Button
                className={styles.addComponentButton}
                onClick={() => addComponent({ id: 2, fieldType: 'Text' })}>
                <TextFieldsIcon />
                Text
              </Button>
              <Button
                className={styles.addComponentButton}
                onClick={() => addComponent({ id: 4, fieldType: 'Choice' })}>
                <RadioButtonCheckedIcon />
                Choice
              </Button>
              <Button
                className={styles.addComponentButton}
                onClick={() => addComponent({ id: 3, fieldType: 'Title' })}>
                <TitleIcon />
                Title
              </Button>
              <Button
                className={styles.addComponentButton}
                onClick={() => addComponent({ id: 5, fieldType: 'Line' })}>
                <RemoveIcon />
                Line
              </Button>
              {isPictureTemplateAvailable() && (
                <Button
                  className={styles.addComponentButton}
                  onClick={() => addComponent({ id: 7, fieldType: 'Resource' })}>
                  <img src={PictureIcon} alt="picture-alt" />
                  Add picture
                </Button>
              )}
              {reportType.name === 'Location' && areLocationTemplatesAvailable() && (
                <Button
                  className={styles.addComponentButton}
                  onClick={() => addComponent({ id: 1, fieldType: 'LocationTemplate' })}>
                  <ViewModuleIcon />
                  Templates
                </Button>
              )}
              {isSignatureTemplateAvailable() && (
                <Button
                  className={styles.addComponentButton}
                  onClick={() => addComponent({ id: 9, fieldType: 'Signature' })}>
                  <img src={SignatureIcon} alt="signature-alt" />
                  Signature
                </Button>
              )}
            </section>
          )}
        </section>
        <section className={styles.controlButtons}>
          <MainButton
            text={published ? 'Cancel' : 'Back'}
            action={onBackClick}
            type="secondary"
            size="popup"
          />
          {!published && <MainButton text="Save" action={onSave} type="primary" size="popup" />}
          <MainButton
            text={published ? 'Unpublish' : 'Publish'}
            action={onPublish}
            isDisabled={isPublishDisabled()}
            type="primary"
            size="popup"
          />
        </section>
      </section>
    </section>
  );
}

export default ReportConstructor;
