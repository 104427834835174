import React, { useEffect, useState } from 'react';
import styles from './LooseItemDashboard.module.scss';

import { MainButton } from 'components/StyledComponents';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import DashboardAddTablePopup from 'components/DashboardAddTablePopup/DashboardAddTablePopup';
import { MoveLooseItemsPopup } from 'components/Popups';
import { StyledButton } from 'components/Buttons';

import LooseItemDashboardTable from './components/DashboardTable/LooseItemDashboardTable';
import LooseItemDashboardControls from './components/LooseItemDashboardControls/LooseItemDashboardControls';

import { selectUserConfig } from '../../commonSelectors';
import { selectLooseItemDashboardConfig, selectLooseItemDashboardTablesData } from './selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  clearLooseItemDashboardData,
  getLooseItemDashboardConfig,
  getLooseItemDashboardTablesData,
  updateLooseItemDashboardConfig
} from 'actions/looseItemDashboardActions';
import { saveMovedItems } from 'actions/moveLooseItemsActions';

import { DELETE_TABLE } from 'constants/dialogPopupsData';
import { MOVED_LOOSE_ITEMS_PATH } from 'constants/routeConstants';
import { cloneObj, isAdminUser, isOperationsManagerUser } from 'helpers/AppHelpers';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import allSwitcherIcon from 'assets/images/tableSwitcherFilled.svg';

import { IconButton } from '@material-ui/core';

function LooseItemDashboard({
  userConfig,
  dashboardTablesData,
  dashboardConfig,
  getDashboardTablesDataAction,
  getDashboardConfigAction,
  updateDashboardConfigAction,
  saveMovedItemsAction,
  clearDashboardDataAction
}) {
  const history = useHistory();
  const isMobile = useMobileViewport();
  const { userRole } = userConfig;
  const [dashboardState, setDashboardState] = useState([]);
  const [openAddTablePopup, setOpenAddTablePopup] = useState(false);
  const [responseReceived, setResponseReceived] = useState(false);
  const [tablesList, setTablesList] = useState([]);
  const [configTablesData, setConfigTablesData] = useState([]);

  const [openMovePopup, setOpenMovePopup] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  useEffect(() => {
    getDashboardConfigAction().then((res) => {
      if (res) {
        getDashboardTablesDataAction().then((resp) => {
          if (resp) {
            setResponseReceived(true);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      clearDashboardDataAction();
    };
  }, []);

  useEffect(() => {
    if (dashboardTablesData.length && Object.keys(dashboardConfig).length) {
      // find categories with locations
      const tables = dashboardTablesData.filter((el) => el.locations.length !== 0);
      setTablesList(tables);
      // find categories with locations in the config
      const configTables = [
        ...dashboardConfig.userLooseItemDashboardConfigCategories.filter((el) =>
          tables.map(({ id }) => id).includes(el.looseItemCategory.id)
        )
      ];
      setConfigTablesData(configTables);
      filterTableData(tables, configTables);
    }
  }, [dashboardTablesData, dashboardConfig]);

  const filterTableData = (dashboardTables, dashboardConfig) => {
    if (!dashboardTables?.length) return;
    const sortingArray = dashboardConfig.map(({ looseItemCategory }) => looseItemCategory.id);
    const mutatedArray =
      dashboardTables
        ?.filter(({ id }) =>
          dashboardConfig
            .filter(({ isHidden }) => !isHidden)
            .map(({ looseItemCategory }) => looseItemCategory.id)
            .includes(id)
        )
        .sort((a, b) => sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id)) || [];
    setDashboardState(mutatedArray);
  };

  const onDashboardViewChange = (deleteTableArray) => {
    setResponseReceived(false);
    const deleted = deleteTableArray
      .filter(({ isHidden }) => !isHidden)
      .map(({ looseItemCategory }) => looseItemCategory.id);
    const sortingArray = deleteTableArray.map(({ looseItemCategory }) => looseItemCategory.id);
    const newTablesData = tablesList
      .filter(({ id }) => deleted.includes(id))
      .sort((a, b) => sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id));
    updateDashboardConfigAction({
      userLooseItemDashboardConfigCategories: deleteTableArray
    }).then((res) => {
      if (res) {
        setResponseReceived(true);
      }
    });
    setConfigTablesData(deleteTableArray);
    setDashboardState(newTablesData);
  };

  const deleteTableHandle = (tableId) => {
    setDialogModalData({ ...DELETE_TABLE, isOpened: true, tableIdToDelete: tableId });
  };

  const applyPopupHandle = (selected, categoryId) => {
    const configCopy = [...configTablesData];
    const index = configCopy.findIndex(
      ({ looseItemCategory }) => looseItemCategory.id === categoryId
    );
    if (configCopy[index]?.selectedLooseItemIds?.length) {
      configCopy[index].selectedLooseItemIds = [...selected];
    } else {
      configCopy[index].selectedTentPartIds = [...selected];
    }
    onDashboardViewChange(configCopy);
  };

  const onAgree = () => {
    const deleteTableArray = [...configTablesData];
    deleteTableArray.find(
      ({ looseItemCategory }) => looseItemCategory.id === dialogModalData.tableIdToDelete
    ).isHidden = true;
    onDashboardViewChange(deleteTableArray);
    setDialogModalData({ isOpened: false });
  };

  const onDismiss = () => setDialogModalData({ isOpened: false });

  const isNeedToShowText = responseReceived;

  const handleCommonSwitcherClick = () => {
    const configCopy = cloneObj(configTablesData);

    const allTablesReversed = configCopy.every(
      ({ isLocationRowView }) => isLocationRowView === true
    );
    const shouldReverse = !allTablesReversed;

    const updatedConfig = configCopy.map((table) => ({
      ...table,
      isLocationRowView: shouldReverse
    }));
    onDashboardViewChange(updatedConfig);
  };

  const switchTableHandle = (id) => {
    const configCopy = cloneObj(configTablesData);
    const index = configCopy.findIndex(({ looseItemCategory }) => looseItemCategory.id === id);
    configCopy[index].isLocationRowView = !configCopy[index].isLocationRowView;
    onDashboardViewChange(configCopy);
  };

  const getReversedValue = (tableId) =>
    configTablesData?.find(({ looseItemCategory }) => looseItemCategory.id === tableId)
      ?.isLocationRowView;

  const getSelectedValues = (tableId) => {
    const category = configTablesData.find(
      ({ looseItemCategory }) => looseItemCategory.id === tableId
    );
    const { selectedTentPartIds, selectedLooseItemIds } = category;
    return selectedTentPartIds?.length ? selectedTentPartIds : selectedLooseItemIds;
  };

  const saveMovedLooseItems = (items) => {
    saveMovedItemsAction(items);
    history.push(MOVED_LOOSE_ITEMS_PATH);
  };

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      <DashboardAddTablePopup
        isMobile={isMobile}
        open={openAddTablePopup}
        setOpen={setOpenAddTablePopup}
        tablesList={tablesList}
        deletedTablesList={configTablesData}
        onViewChange={onDashboardViewChange}
      />

      {openMovePopup && (
        <MoveLooseItemsPopup
          open={openMovePopup}
          setOpen={setOpenMovePopup}
          screen="Dashboard"
          onMove={saveMovedLooseItems}
        />
      )}

      <section className={styles.pageHeader}>
        <h1>{isMobile ? 'Dashboard' : 'Loose Items Dashboard'}</h1>
        <div className={styles.pageHeader__controls}>
          {isMobile && (
            <IconButton onClick={handleCommonSwitcherClick} className={styles.controls__switcher}>
              <img src={allSwitcherIcon} alt="" />
            </IconButton>
          )}
          {isMobile && (
            <MainButton
              isMobile={isMobile}
              text="+"
              type="primary"
              action={() => setOpenAddTablePopup(true)}
            />
          )}
          {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && !isMobile && (
            <StyledButton
              label="Move loose item"
              onClick={() => setOpenMovePopup(true)}
              classes={styles.move_btn}
            />
          )}
        </div>
      </section>
      {!isMobile && (
        <section className={styles.controls}>
          <IconButton onClick={handleCommonSwitcherClick} className={styles.controls__switcher}>
            <img src={allSwitcherIcon} alt="" />
          </IconButton>
          <MainButton
            text="Configuration"
            type="primary"
            action={() => setOpenAddTablePopup(true)}
          />
        </section>
      )}
      {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && isMobile && (
        <StyledButton
          label="Move loose item"
          onClick={() => setOpenMovePopup(true)}
          classes={styles.move_btn}
        />
      )}
      {dashboardState.length ? (
        dashboardState.map((tableData) => (
          <section key={tableData.id}>
            <LooseItemDashboardControls
              isMobile={isMobile}
              tableData={tableData}
              onSwitch={switchTableHandle}
              onDelete={deleteTableHandle}
              onApply={applyPopupHandle}
              selected={getSelectedValues(tableData.id)}
              isTableReversed={getReversedValue(tableData.id)}
            />
            <section className={styles.tableWrapper}>
              <LooseItemDashboardTable
                isMobile={isMobile}
                tableData={tableData}
                isTableReversed={getReversedValue(tableData.id)}
                selectedItems={getSelectedValues(tableData.id)}
              />
            </section>
          </section>
        ))
      ) : (
        <section className={styles.emptyDashboardBlock}>
          {isNeedToShowText && <h3>Please, add the table to the dashboard.</h3>}
        </section>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  dashboardTablesData: selectLooseItemDashboardTablesData(),
  dashboardConfig: selectLooseItemDashboardConfig()
});

const mapDispatchToProps = {
  getDashboardTablesDataAction: getLooseItemDashboardTablesData,
  getDashboardConfigAction: getLooseItemDashboardConfig,
  updateDashboardConfigAction: updateLooseItemDashboardConfig,
  saveMovedItemsAction: saveMovedItems,
  clearDashboardDataAction: clearLooseItemDashboardData
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LooseItemDashboard);
