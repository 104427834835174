import api from '../api';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const updateLooseItemCategory = (params) => () => {
  return api.looseItemCategory.updateCategoryApi(params).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteLooseItemCategory = (id) => () => {
  return api.looseItemCategory.deleteCategoryApi(id).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};
