import React, { useCallback, useState } from 'react';
import styles from './SearchBar.module.scss';

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { reformatInputValue } from 'helpers/AppHelpers';
import { SEARCH_PATH } from 'constants/routeConstants';
import { ReactComponent as QRScanIcon } from 'assets/images/qrScan.svg';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  bar: {
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '&.Mui-focused fieldset': {
        border: '1px solid #fff',
        borderRadius: '8px'
      }
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0',
      '& button': {
        color: '#fff',
        marginRight: '5px'
      }
    }
  }
}));

export function SearchBar({ sidebarOpen, onToggle, openScanner }) {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const [value, setValue] = useState('');

  const classes = useStyles();

  const changeInput = useCallback(
    (event) => {
      const value = reformatInputValue(event.target.value, 150);
      setValue(value);
    },
    [setValue]
  );

  const keyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && value) {
        history.push(`${SEARCH_PATH}?searchQuery=${value}`);
        setValue('');
      }
    },
    [history, setValue, value]
  );

  const clearValue = useCallback(() => setValue(''), [setValue]);

  const handleOpenQRScan = useCallback(() => openScanner(true), [openScanner]);

  const env = process.env.REACT_APP_ENVIRONMENT || 'local';
  const isProdEnv = env === 'production';
  const plateTitle = isProdEnv ? '' : `${env} env`;

  return (
    <div className={styles.search}>
      <div className={styles.search__icon}>
        <IconButton onClick={onToggle} className={styles.search__icon_toggle}>
          {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
      <div className={styles.search__field}>
        <TextField
          className={clsx(styles.search__field_input, classes.bar)}
          type="text"
          placeholder="Search"
          variant="outlined"
          onChange={changeInput}
          onKeyDown={keyDown}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={clearValue} className={styles.clear}>
                  {value ? <CancelRoundedIcon fontSize="small" /> : <SearchIcon fontSize="small" />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
      {sidebarOpen && (
        <div className={styles.search__controls}>
          <IconButton onClick={handleOpenQRScan} className={styles.search__controls_btn}>
            <QRScanIcon />
          </IconButton>
        </div>
      )}

      {isMobile && !isProdEnv && (
        <div className={styles.environment}>
          <div className={styles.environment__title}>{plateTitle}</div>
        </div>
      )}
    </div>
  );
}
