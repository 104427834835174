import styles from './Layout.module.scss';
import React from 'react';
import { Input } from '../Input';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FILES_LIMIT } from '../../helpers';

export default function Layout({ input, upFiles, onChange, previews, dropzoneProps }) {
  const deleteFile = (fileId) => {
    const filteredFiles = upFiles.filter((resource) => resource.id !== fileId);
    onChange(filteredFiles);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(upFiles.length >= FILES_LIMIT && styles.deprecatedInput)}
        {...dropzoneProps}>
        {upFiles.length < FILES_LIMIT ? (
          input
        ) : (
          <>
            <label className={clsx(styles.inputLabel, styles.deprecatedInput)}>
              <Input />
            </label>
            <p>Upload limit exceeded</p>
          </>
        )}
      </div>

      <div
        className={clsx(
          styles.previews,
          (!!upFiles?.length || !!previews?.length) && styles.topSpace
        )}>
        {!!upFiles.length &&
          upFiles.map((file) => (
            <div className={styles.fileWrapper} key={file.id}>
              <span>{file.name}</span>
              <IconButton className={styles.deleteButton} onClick={() => deleteFile(file.id)}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          ))}

        {previews}
      </div>
    </div>
  );
}
