import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import TeamMemberEditWrapper from './components/TeamMemberEditWrapper';
import Form from './components/Form';

export default function TeamMemberEdit() {
  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      homeAddress1: '',
      homeAddress2: '',
      phoneNumber: '',
      airport: '',
      notes: '',
      clearance: null,
      hireDate: null,
      departments: [],
      capacity: [],
      licenses: [],
      projects: [],
      skillsets: [],
      status: [],
      idNumber: '',
      isSelectedAllReadOnlyDepartments: false
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <TeamMemberEditWrapper>
        <Form />
      </TeamMemberEditWrapper>
    </FormProvider>
  );
}
