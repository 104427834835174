import React from 'react';
import styles from './FieldsUpdate.module.scss';
import { IconButton } from '@material-ui/core';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { FormLabel } from 'components/FormComponents';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { tabs } from '../helpers';

export function AssetFields({ fields, onDelete }) {
  const tabName = tabs.fieldsUpdate;

  const getFieldInputType = ({ type }) => (type === 'String' ? 'text' : 'quantity');
  const getFieldMaxLength = ({ type, constraints }) => {
    if (type === 'String' && constraints?.maxLength) return constraints.maxLength;

    if (type === 'String') return 60;

    return null;
  };

  return (
    <div className={styles.form__fields}>
      {!!fields?.length &&
        fields.map((field, index) => (
          <div className={styles.asset_field_row} key={field.id}>
            <FormLabel>{field?.displayName || ''}</FormLabel>
            <div className={styles.asset_field_row__controls}>
              <FormInputText
                name={`${tabName}.assetFields[${index}].value`}
                classes={styles.asset_field_row__controls_input}
                options={{
                  max: getFieldMaxLength(field),
                  type: getFieldInputType(field),
                  focus: field?.type !== 'String'
                }}
              />

              <IconButton
                className={styles.asset_field_row__controls_clear}
                onClick={() => onDelete(index)}>
                <CancelRoundedIcon className={styles.clearIcon} />
              </IconButton>
            </div>
          </div>
        ))}
    </div>
  );
}
