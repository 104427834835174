const NO_ERROR = '';

const MOVE_QUANTITY_ERROR = 'The “Add QTY” value must not exceed “Available QTY(BAA*)” value';
const MOVE_QUANTITY_IS_EMPTY_ERROR = 'The “Add QTY” value is required';
const BUY_AHEAD_ACCOUNT_ERROR =
  'The “From BAA” value must not exceed “Available QTY(BAA)” value in the brackets';
const BUY_AHEAD_ACCOUNT_MORE_THAN_MOVE_ERROR = '“From BAA*” value must not exceed “Add QTY”';
const DESTINATION_ERROR =
  'The “Current location(Sublocation)” value must be different from destination in picklist value';
const DESTINATION_IS_EMPTY_ERROR = 'The “Current Location(Sublocation)” value is required';
const LOOSE_ITEM_IS_EMPTY_ERROR = 'The “Loose Items” value is required';

const WARNING_TEXT = 'Loose items from Buy Ahead Account will be moved with Add QTY loose items';

export const checkMoveQuantity = (quantity, moveQty) => {
  if (!moveQty) return MOVE_QUANTITY_IS_EMPTY_ERROR;
  return +moveQty <= +quantity ? NO_ERROR : MOVE_QUANTITY_ERROR;
};

export const isBaaQtyMoreThanMove = (moveBuyAheadAccount, moveQuantity) => {
  return moveBuyAheadAccount > moveQuantity;
};
export const checkBuyAheadAccount = (byAheadAccount, moveBuyAheadAccount, moveQuantity) => {
  return +moveBuyAheadAccount > +byAheadAccount
    ? BUY_AHEAD_ACCOUNT_ERROR
    : isBaaQtyMoreThanMove(moveBuyAheadAccount, moveQuantity)
    ? BUY_AHEAD_ACCOUNT_MORE_THAN_MOVE_ERROR
    : NO_ERROR;
};

export const checkDestination = (currentLocation, currentSublocation, destination, sublocation) => {
  if (currentSublocation?.id && sublocation?.id) {
    return !(currentLocation.id === destination.id && currentSublocation?.id === sublocation.id)
      ? NO_ERROR
      : DESTINATION_ERROR;
  }
  if (currentLocation?.id === destination?.id) {
    return currentSublocation?.id || sublocation?.id ? NO_ERROR : DESTINATION_ERROR;
  } else {
    return NO_ERROR;
  }
};

export const checkIsLocationEmpty = (location) => {
  return location?.id ? NO_ERROR : DESTINATION_IS_EMPTY_ERROR;
};

export const checkLooseItem = (looseItem) => {
  return looseItem?.id ? NO_ERROR : LOOSE_ITEM_IS_EMPTY_ERROR;
};

export const canLooseItemsBeMoved = (move, moveBaa, quantity, baa) => {
  return +move - +moveBaa > +quantity - +baa;
};
export const checkRowForWarnings = (el) => {
  const move = el?.moveQuantity || 0;
  const moveBaa = el?.moveByAheadAccount || 0;
  const quantity = el?.locationLooseItem?.quantity || 0;
  const baa = el?.locationLooseItem?.byAheadAccount || 0;

  return canLooseItemsBeMoved(move, moveBaa, quantity, baa) ? WARNING_TEXT : NO_ERROR;
};
