import React from 'react';

import PaginationWrapper from './PaginationWrapper';
import PageSelector from './PageSelector';
import LimitSelector from './LimitSelector';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useDispatch } from 'react-redux';

import { updateUserConfig } from 'actions/commonActions';

export function SimplePagination({
  rowsNumber,
  currentPage,
  selectedLimit,
  totalPages,
  tableName,
  onChangeLimit,
  onChangePage,
  withOption100,
  withOptionAll,
  hideLimitSelector,
  hidePageSelector
}) {
  const isMobile = useMobileViewport();

  const dispatch = useDispatch();
  const { getUpdatedByTablesConfigCopy, isAllLimitEnabled, getTableLimit } = useUserConfig();

  const isAllSelected = () =>
    isAllLimitEnabled(tableName) && getTableLimit(tableName) === 100 && !isMobile;

  const isLimitSelectorAvailable =
    !hideLimitSelector && !(rowsNumber <= 10 && currentPage === 1 && totalPages === 1) && tableName;
  const isPageSelectorAvailable = !hidePageSelector && totalPages !== 1 && !isAllSelected();

  const selectPage = (page, param) => onChangePage(page, param);

  const selectLimit = (limit) => {
    const limitValue = limit === 'All' ? 100 : limit;
    const isAllValue = limit === 'All';

    const configCopy = getUpdatedByTablesConfigCopy(tableName, limitValue, isAllValue);

    dispatch(updateUserConfig(configCopy)).then(() => {
      if (selectedLimit === 100 && isAllValue) {
        onChangePage(currentPage === 1 ? currentPage + 1 : 1, 'AUTOLOAD');
      } else {
        onChangeLimit();
      }
    });
  };

  return (
    <PaginationWrapper isAllSelected={isAllSelected()}>
      {isLimitSelectorAvailable && (
        <LimitSelector
          selectedLimit={selectedLimit}
          onChange={selectLimit}
          withOption100={withOption100}
          withOptionAll={withOptionAll}
          isAllSelected={isAllSelected()}
        />
      )}
      {isPageSelectorAvailable && (
        <PageSelector currentPage={currentPage} totalPages={totalPages} onChange={selectPage} />
      )}
    </PaginationWrapper>
  );
}
