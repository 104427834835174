import React from 'react';
import styles from './NotificationCenterContent.module.scss';

import CommonRecipients from './Common';

import { tabs } from '../../helpers';

import { useNotificationCenterSelector } from 'hooks/NotificationCenter';

export default function NotificationCenterContent() {
  const { selectedTab } = useNotificationCenterSelector();

  return (
    <div className={styles.content}>
      {selectedTab === tabs.assetReport && <CommonRecipients />}

      {selectedTab === tabs.generalReport && <CommonRecipients />}

      {selectedTab === tabs.looseItems && <CommonRecipients />}

      {selectedTab === tabs.ticketAndAssetTab && <CommonRecipients />}

      {selectedTab === tabs.requestOrder && <CommonRecipients />}

      {selectedTab === tabs.locationReport && <CommonRecipients />}

      {selectedTab === tabs.teamMemberStatus && <CommonRecipients />}
    </div>
  );
}
