import {
  SET_CLOSED_SERVICE_TICKETS_LIST,
  SET_TICKETS_COUNTING,
  SET_TICKETS_FILTER_CRITERIA,
  SET_TICKETS_CREATION_FORM,
  SET_CURRENT_TICKET,
  CLEAR_TICKETS_STATE,
  SET_TICKET_LIST_FILTER,
  SET_TICKET_PREFILLED_ASSET
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  filterCriteria: {},
  creationForm: {},
  prefilledAsset: {},
  counting: {},
  closedServiceTicketsList: [],
  issueTypes: [],
  rootCauses: [],
  assignUsers: [],
  severities: [],
  currentTicket: {},
  usersListForAssign: []
};

const ticketsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TICKET_LIST_FILTER:
      return { ...state, filter: payload };
    case SET_CLOSED_SERVICE_TICKETS_LIST:
      return { ...state, closedServiceTicketsList: payload };
    case SET_TICKETS_COUNTING:
      return { ...state, counting: payload };
    case SET_TICKETS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_TICKETS_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_CURRENT_TICKET:
      return { ...state, currentTicket: payload };
    case SET_TICKET_PREFILLED_ASSET:
      return { ...state, prefilledAsset: payload };
    case CLEAR_TICKETS_STATE:
      return {
        ...initialState,
        filter: state.filter
      };
    default:
      return state;
  }
};

export default ticketsReducer;
