import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getShortedLooseItemsApi = (query) =>
  api.get(`/LooseItems/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemLocationsApi = (query) =>
  api.get(`/LocationLooseItems?${queryToString(query)}`).then((res) => res.data);

export const getShortedPicklistsApi = () => api.get('/ShortedPicklists').then((res) => res.data);

export const addLooseItemsToPicklistApi = (params) =>
  api.post('/PicklistLooseItems', params).then((res) => res);

export const editLooseItemOfPicklistApi = (params) =>
  api.put('/PicklistLooseItem', params).then((res) => res);

export const checkPicklistItemsLocationApi = (q) =>
  api.get(`/PicklistAssets/LocationCheck?${queryToString(q)}`).then((res) => res.data);
