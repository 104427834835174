import React from 'react';
import styles from './LooseItemDashboardControls.module.scss';
import { Button } from '@material-ui/core';

import switcherIcon from 'assets/images/tableSwitcher.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import LooseItemSelectionPopup from './LooseItemSelectionPopup/LooseItemSelectionPopup';

export default function LooseItemDashboardControls({
  isMobile,
  tableData,
  onSwitch,
  onDelete,
  onApply,
  selected,
  isTableReversed
}) {
  const looseItems = tableData.locations[0].looseItems;

  const switchTableHandle = () => onSwitch(tableData.id);
  const handleTableDelete = () => onDelete(tableData.id);
  const handlePopupApply = (selected) => onApply(selected, tableData.id);

  return (
    <section className={styles.aboveTable}>
      <h3>{tableData?.name || ''}</h3>
      <div className={styles.tableControls}>
        <Button onClick={switchTableHandle}>
          <img src={switcherIcon} alt="switcher" />
        </Button>
        <Button onClick={handleTableDelete}>
          <DeleteIcon />
        </Button>
        <LooseItemSelectionPopup
          isMobile={isMobile}
          looseItems={looseItems.map((el) => ({ ...el, isHidden: false }))}
          selectedValues={selected}
          onApply={handlePopupApply}
          isTableReversed={isTableReversed}
        />
      </div>
    </section>
  );
}
