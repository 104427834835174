import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getShortedLocationList, getSiteTypesList } from 'actions/locationsActions';
import { selectShortedLocationsListData, selectSiteTypesListData } from '../selectors';
import Multiselect from 'components/Multiselect/Multiselect';
import { Paper, Fade, Popper, Checkbox, Chip, IconButton, Backdrop } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MainButton } from 'components/StyledComponents';
import FilterIcon from 'assets/images/filter.svg';
import styles from './LocationsListFilter.module.scss';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import { sortByParam } from 'helpers/AppHelpers';

function LocationListFilter({
  isMobile,
  filter,
  onApply,
  siteTypesData,
  locationsData,
  openFilter,
  setOpenFilter,
  getSiteTypesListAction,
  getLocationListAction
}) {
  const { filters } = filter;
  const [anchorEl, setAnchorEl] = useState(null);
  const [chips, setChips] = useState([]);
  const [values, setValues] = useState({ ...filters });

  useEffect(() => {
    getSiteTypesListAction();
  }, []);

  useEffect(() => {
    getLocationListAction();
  }, []);

  const addChips = (inputFilter) => {
    const chipsArray = [];
    if (inputFilter.siteTypeIds.length) {
      inputFilter.siteTypeIds.forEach((item) => {
        const newChip = siteTypesData.find((option) => option.id === item);
        chipsArray.push({ ...newChip, key: 'siteTypeIds' });
      });
    }
    if (inputFilter.locationJobNumberIds.length) {
      inputFilter.locationJobNumberIds.forEach((item) => {
        const newChip = locationsData.find((option) => option.id === item);
        chipsArray.push({ ...newChip, key: 'locationJobNumberIds' });
      });
    }
    if (!inputFilter.active) {
      chipsArray.push({ id: 'active', name: 'Inactive', key: 'active' });
    }
    setChips(chipsArray);
  };

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && addChips(filters);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
    isMobile && addChips(filters);
  };

  const onFilterSelect = (e) => {
    let currentFilter;
    switch (e.target.name) {
      case 'locationType':
        currentFilter = {
          ...values,
          siteTypeIds: [...e.target.value]
        };
        break;
      case 'active':
        currentFilter = {
          ...values,
          active: !values.active
        };
        break;
      default:
        break;
    }
    setValues(currentFilter);
    isMobile && addChips(currentFilter);
  };

  const handleLocationSelect = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && addChips(updatedValues);
  };

  const applyFilter = () => {
    addChips(values);
    setOpenFilter(!openFilter);
    onApply(values);
  };

  const handleClearFilter = () => {
    const defaultState = { ...values, active: true, siteTypeIds: [], locationJobNumberIds: [] };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const handleChipDelete = (itemId, key) => {
    setChips(chips.filter((chip) => chip.id !== itemId));
    const filterObj =
      key === 'active'
        ? {
            ...values,
            active: itemId === 'active' ? !values.active : values.active
          }
        : {
            ...values,
            [key]: values[key].filter((id) => id !== itemId)
          };
    setValues(filterObj);
    if (!isMobile) {
      onApply(filterObj);
    }
  };

  useEffect(() => {
    if (siteTypesData.length) {
      addChips(filter.filters);
      setValues({ ...filter.filters });
    }
  }, [filter, siteTypesData]);

  return (
    <div className={styles.filterWrapper}>
      {!isMobile && <Backdrop open={openFilter} />}
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="" />
      </IconButton>

      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && <FilterHeader onBack={handleFilterClose} />}
              <section className={styles.formColumn}>
                <section className={styles.formCell}>
                  <label>Type</label>
                  <div className={`${styles.inputWrapper} ${isMobile ? styles.wFull : ''}`}>
                    <Multiselect
                      name="locationType"
                      valuesArray={values.siteTypeIds}
                      options={siteTypesData || []}
                      onFilterSelect={onFilterSelect}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Job Number</label>
                  <div className={`${styles.inputWrapper} ${isMobile ? styles.wFull : ''}`}>
                    <AutocompleteWithSelectAll
                      name="locationJobNumberIds"
                      label="locationJobNumber"
                      value={values?.locationJobNumberIds || []}
                      options={sortByParam(locationsData, 'locationJobNumber') || []}
                      onSelect={handleLocationSelect}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={`${styles.formCell} ${styles.checkboxCell}`}>
                  <label>Show inactive?</label>
                  <div className={styles.inputWrapper}>
                    <Checkbox
                      classes={{ root: styles.checkbox, checked: styles.checked }}
                      onChange={onFilterSelect}
                      name="active"
                      checked={!values.active}
                    />
                  </div>
                </section>
                {isMobile && (
                  <section className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={`${item.id}-${item.key}`}
                          label={item.locationJobNumber || item.name || ''}
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item.id, item.key)}
                        />
                      ))}
                  </section>
                )}
              </section>
              <section className={styles.buttonWrapper}>
                <ClearFilterButton onClick={handleClearFilter} />
                <div className={styles.primary}>
                  <MainButton
                    text="Cancel"
                    action={handleFilterClose}
                    type="secondary"
                    size="filter_popup"
                  />
                  <MainButton
                    text="Apply"
                    action={applyFilter}
                    type="primary"
                    size="filter_popup"
                  />
                </div>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>

      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={`${item.id}-${item.key}`}
                label={item.locationJobNumber || item.name || ''}
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item.id, item.key)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  siteTypesData: selectSiteTypesListData(),
  locationsData: selectShortedLocationsListData()
});

const mapDispatchToProps = {
  getSiteTypesListAction: getSiteTypesList,
  getLocationListAction: getShortedLocationList
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LocationListFilter);
