import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import VideosUpload from 'components/FilesUploader/VideosUpload/VideosUpload';

export default function FormVideosUpload({ name = 'resources' }) {
  const { control, setValue, getValues } = useFormContext();

  const handleChange = (files) => {
    const resources = getValues(name) || [];
    const otherFiles = resources?.filter((resource) => resource['resourceType']?.id !== 3) || [];
    const result = [...otherFiles, ...files];
    setValue(name, result, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <VideosUpload
          videos={value?.filter((resource) => resource['resourceType']?.id === 3) || []}
          onChange={handleChange}
        />
      )}
    />
  );
}
