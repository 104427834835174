import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_VIDEO_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import styles from './VideosUpload.module.scss';
import Layout from './components/Layout/Layout';
import { FILES_LIMIT, MAX_FILE_SIZE, FILE_MIME_TYPES } from './UploaderConfig';
import { Input } from './components/InputComponent/InputComponent';
import Preview from './components/Layout/Preview';

export default function VideosUpload({ videos, onChange, index }) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const getUploadParams = () => {
    if (getToken()) {
      return {
        url: UPLOAD_VIDEO_URL,
        headers: {
          authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
        }
      };
    }
  };

  const handleChangeStatus = (fileWithMeta, status, files) => {
    const { meta } = fileWithMeta;

    const uniqueFileArray = [
      ...videos,
      ...files?.map((file) => JSON.parse(file.xhr?.response || null))?.filter(Boolean)
    ].filter(
      (currentFile, index, filesArray) =>
        filesArray.findIndex((file) => file.id === currentFile.id) === index
    );

    if (!meta.type.match(/(mp4|mov|quicktime|webm)/)) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed:
                MP4, MOV, WEBM`,
        isOpened: true
      });
    } else if (meta.size > MAX_FILE_SIZE) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" exceeds maximum allowed upload size of 20MB`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (uniqueFileArray.length > FILES_LIMIT) {
      setPopupData({
        ...popupData,
        title: `Upload limit (${FILES_LIMIT}) exceeded`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (status === 'done') {
      onChange(uniqueFileArray, index);
      fileWithMeta.remove();
    }
  };

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className={styles.dropzoneWrapper}>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={Input}
          inputWithFilesContent={Input}
          LayoutComponent={(props) => (
            <Layout videos={videos} onChange={onChange} index={index} {...props} />
          )}
          PreviewComponent={Preview}
          maxSizeBytes={MAX_FILE_SIZE}
          accept={FILE_MIME_TYPES}
          classNames={{
            dropzone: styles.dropzone,
            input: styles.dropzone_input,
            inputLabelWithFiles: styles.dzu_inputLabel,
            inputLabel: styles.dzu_inputLabel
          }}
        />
      </section>
    </>
  );
}
