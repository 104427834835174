import React from 'react';
import styles from '../../components.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';

import CountingTable from './CountingTable';

export default function CountingModal({ open, setOpen }) {
  const closePopup = () => setOpen(false);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <section className={styles.modal}>
        <DialogHeader onClose={closePopup} title="Items Calculation" />
        <CountingTable />
      </section>
    </DialogWrapper>
  );
}
