import { useSelector } from 'react-redux';

import { selectLoadMoreFlag, selectUnsavedFormData } from 'pages/commonSelectors';

export function useCommonSelector() {
  const loadMoreFlag = useSelector(selectLoadMoreFlag());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    loadMoreFlag,
    unsavedFormData
  };
}
