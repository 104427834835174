import React from 'react';
import { IconButton } from '@material-ui/core';
import styles from './EditorForm.module.scss';
import fillColorIcon from 'assets/images/fillColorIcon.svg';
import { ChromePicker } from 'react-color';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';

export default function ColorPicker({ color, onChange }) {
  const trigger = (
    <IconButton className={styles.status_controls__fill}>
      <img src={fillColorIcon} alt="fill-color" />
    </IconButton>
  );
  const tooltip = <ChromePicker disableAlpha color={color} onChangeComplete={onChange} />;
  return <TooltipWrapper trigger={trigger} tooltip={tooltip} />;
}
