import React from 'react';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ShowPopupButton } from 'components/Buttons';

import TablesModal from './TablesModal';
import LocationTablesContent from './LocationTablesContent';

export default function LocationTables() {
  const isMobile = useMobileViewport();

  return !isMobile ? (
    <LocationTablesContent />
  ) : (
    <ShowPopupButton
      label="Assets"
      PopupComponent={(open, setOpen) => <TablesModal open={open} setOpen={setOpen} />}
    />
  );
}
