import { createSelector } from 'reselect';

const leaveListData = (state) => state.leaveListData;

export const selectLocationsData = () =>
  createSelector([leaveListData], (state) => state.locationList);

export const selectStatusesData = () =>
  createSelector([leaveListData], (state) => state.statusList);

export const selectProjects = () => createSelector([leaveListData], (state) => state.projectList);

export const selectFilterData = () => createSelector([leaveListData], (state) => state.filter);

export const selectSingleLeaveListData = () =>
  createSelector([leaveListData], (state) => state.currentLeaveList);

export const selectLeaveListAssetStatuses = () =>
  createSelector([leaveListData], (state) => state.leaveListAssetStatuses);

export const selectLocationListData = () =>
  createSelector([leaveListData], (state) => state.locationList);

export const selectTruckOrTrailers = () =>
  createSelector([leaveListData], (state) => state.truckOrTrailers);
