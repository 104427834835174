import React, { useState } from 'react';
import styles from './Buttons.module.scss';

import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function ShowPopupButton({ label, PopupComponent }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <>
      {open && PopupComponent(open, setOpen)}
      <Button className={styles.show_popup_button} onClick={handleClick}>
        {label}
        <ChevronRightIcon />
      </Button>
    </>
  );
}
