import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getCalendarDataApi = (query) =>
  api.get(`/AssetCalendar?${queryToString(query)}`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getAssetPrefixesListApi = (query) =>
  api.get(`/AssetPrefixes?${queryToString(query)}`).then((res) => res.data);

export const getProjectListApi = () => api.get('/ShortedProjects').then((res) => res.data);

export const getUserCalendarOptionsApi = () =>
  api.get('/UserAssetCalendarOptions').then((res) => res.data);

export const getAssetRentalStatusesApi = (query) =>
  api.get(`/AssetRentalStatuses?${queryToString(query)}`).then((res) => res.data);

export const updateUserCalendarOptionsApi = (params) =>
  api.put('/UserAssetCalendarOptions', params).then((res) => res.data);

export const updateAssetStatusApi = (params) =>
  api.put('/AssetRentalStatus', params).then((res) => res);

export const getCalendarSingleAssetApi = (id) =>
  api.get(`/AssetCalendar/AssetInfo?assetId=${id}`).then((res) => res.data);
