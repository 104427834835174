import React from 'react';
import styles from './Details.module.scss';

import SectionTitle from '../SectionTitle';
import FieldViewSwitcher from './FieldViewSwitcher';

import { useFormContext } from 'react-hook-form';

import { splitFieldsForRendering } from '../../../helpers';

export default function PrefixDetails() {
  const { getValues } = useFormContext();

  const splicedFields = splitFieldsForRendering(getValues('assetFields'));

  return (
    <div className={styles.wrapper}>
      <SectionTitle>{getValues('assetPrefix')?.prefixType?.name} Details</SectionTitle>
      <div className={styles.details}>
        {splicedFields.map((block, index) => (
          <div key={index} className={styles.details__block}>
            {block.map((item) => (
              <FieldViewSwitcher key={item.assetField.id} item={item} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
