import React, { useEffect, useState } from 'react';
import styles from './RandRDashboard.module.scss';

import Separator from 'components/Separator/Separator';
import { TransparentButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';

import Table from './Table';
import FilterModal from './Filter';

import {
  useTeamMemberDashboardActions,
  useTeamMemberDashboardSelector
} from 'hooks/TeamMemberDashboard';

import { fileDownload } from 'helpers/AppHelpers';

import csvIcon from 'assets/images/csvIcon.svg';

export default function RandRDashboard() {
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { RRDashboard, tabConfig } = useTeamMemberDashboardSelector();
  const { getCsvResourceAction, clearTabStateAction } = useTeamMemberDashboardActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'RRDashboard' });
    };
  }, []);

  const downloadCsv = (dashboardId) =>
    getCsvResourceAction({ dashboardId }).then((res) => fileDownload(res));

  const handleFilterButtonClick = (sectionId) => {
    const filterData = tabConfig?.items?.find(({ id }) => id === sectionId) || {};

    if (!('id' in filterData)) return;

    setFilterData(filterData);
    setOpen(true);
  };

  return (
    <DashboardWrapper>
      <FilterModal open={open} setOpen={setOpen} filter={filterData} />
      {!!RRDashboard?.length && (
        <div className={styles.statistics}>
          {RRDashboard.map((sectionData, index) => (
            <div key={sectionData.id} className={styles.statistics__dashboard}>
              <SectionHeader title={sectionData.name || ''} />

              <FilterWrapper>
                <FilterButton
                  onClick={() => handleFilterButtonClick(sectionData.id)}
                  isActive={!!sectionData?.department?.id}
                />
                {!!sectionData?.department?.id && (
                  <FilterChips
                    chips={[{ chipId: 100, label: sectionData?.department?.name || '' }]}
                  />
                )}
              </FilterWrapper>

              <Table data={sectionData} />

              {sectionData?.capacities?.length ? (
                <TransparentButton
                  label="Download CSV File"
                  className={styles.csvButton}
                  onClick={() => downloadCsv(sectionData.id)}>
                  <img src={csvIcon} alt="" />
                </TransparentButton>
              ) : null}

              {RRDashboard.length > 1 && index !== RRDashboard.length - 1 && <Separator />}
            </div>
          ))}
        </div>
      )}
    </DashboardWrapper>
  );
}
