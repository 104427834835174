import React, { useEffect, useRef, useState } from 'react';
import styles from './AssembledBOMList.module.scss';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import {
  ASSEMBLED_BOM_DETAILED_PATH,
  LOCATION_DETAILED_PATH,
  BOM_CONFIGURATION_DETAILED_PATH,
  ASSEMBLE_CREATE_PATH
} from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectUserConfig } from 'pages/commonSelectors';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectAssembleListData, selectAssembledTentsFilter } from './selectors';
import {
  clearAssembledLooseItemState,
  getAssembleList,
  setAssembleListFilter
} from 'actions/assembledLooseItemActions';
import { dateTimeFormat, formatDate, getPagesLimit } from 'helpers/AppHelpers';
import AssembledBOMListFilter from './FIlter/AssembledBOMListFilter';
import { ASSEMBLE_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import {
  getAppliedFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import { tableHeadConfig } from './tableConfig';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import clsx from 'clsx';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

function AssembledBOMList({
  userConfig,
  assembleList,
  filter,
  setFilter,
  getAssembleListAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isBasicRoleUser } = useUserConfig();
  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const emptyFilters = () => {
    return (
      filters.searchQuery === '' &&
      !filters.locationIds.length &&
      !filters.locationJobNumberIds.length &&
      !filters.statusNames.length &&
      !filters.looseItemConfigurationIds.length
    );
  };

  const updateTable = (changedFilter) => {
    setFilter(changedFilter);
    getAssembleListAction(changedFilter);
  };

  const { filters, pagination, sortRules, responseReceived } = filter;

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const emptyResponse = () => 'items' in assembleList && !assembleList?.items?.length;

  const getAssembles = (paginationChanged) => {
    getAssembleListAction({
      ...filter,
      pagination: {
        ...pagination,
        page: paginationChanged ? 1 : pagination.page,
        limit: getPagesLimit(userConfig, ASSEMBLE_LIST_TABLE_PARAMETER, isMobile)
      },
      responseReceived: false
    });
  };

  const setAssembles = () => {
    if (
      assembleList.pageNumber === 1 ||
      getPagesLimit(userConfig, ASSEMBLE_LIST_TABLE_PARAMETER, isMobile) !== 100
    ) {
      setTableState(assembleList?.items);
    } else {
      setTableState(tableState.concat(assembleList?.items));
    }
    setFilter({
      ...filter,
      pagination: {
        limit: assembleList.limit,
        page: assembleList.pageNumber,
        totalPages: assembleList.totalPages
      },
      responseReceived: true
    });
  };

  useEffect(() => {
    if ('tables' in userConfig) {
      if (!assembleList.totalPages && !('items' in assembleList)) {
        getAssembles();
      } else {
        setAssembles();
      }
    }
  }, [userConfig, assembleList]);

  const updateSearchParam = (value) => updateTable(getChangedBySearchFilter(filter, value));
  const onSortApply = (rowName) => updateTable(getChangedBySortFilter(filter, rowName));
  const onPageSelect = (page) => updateTable(getChangedByPageFilter(filter, page));
  const onApply = (resultFilter) => getAssembleListAction(getAppliedFilter(resultFilter));

  const isButtonAvailable = () => !isBasicRoleUser;
  return (
    <section
      className={clsx(
        styles.pageContainer,
        !isButtonAvailable() && isMobile && styles.fitContainer
      )}>
      <div className={styles.headerWrapper}>
        <SummaryHeader title="Assembled BOM list">
          {!isMobile && <DownloadCSVButton filter={filter} endpoint="AssembledItems/Serialized" />}
          {isButtonAvailable() && (
            <MainButton
              text="Create assemble"
              type="primary"
              action={() => history.push(ASSEMBLE_CREATE_PATH)}
            />
          )}
        </SummaryHeader>
        <div
          className={clsx(
            styles.tableControls,
            !isButtonAvailable() && isMobile && styles.fitControls
          )}>
          <AssembledBOMListFilter
            filter={filter}
            setFilter={setFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            onApply={onApply}
            isMobile={isMobile}
          />
          <SearchTextFieldRefactored
            inputValue={filters.searchQuery}
            validationRule={/[А-Яа-я]/g}
            updateSearchParam={updateSearchParam}
            rightSpace={isMobile}
          />
        </div>
      </div>
      <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={onSortApply}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <LinkComponent
                      path={`${ASSEMBLED_BOM_DETAILED_PATH}/${row.id}`}
                      name={row.name}
                      boldText={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <LinkComponent
                      path={`${BOM_CONFIGURATION_DETAILED_PATH}/${row.looseItemConfiguration.id}`}
                      name={row?.looseItemConfiguration?.name || ''}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <LinkComponent
                      path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                      name={row.location?.siteCode}
                      isRedirectAvailable={!row.location?.deleted}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.status?.displayName || ''}</StyledTableCell>
                  <StyledTableCell>{formatDate(row?.createdAtUtc, dateTimeFormat)}</StyledTableCell>
                  <StyledTableCell>
                    {row?.createdByUser?.firstName + ' ' + row?.createdByUser?.lastName}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationRefactored
        isMobile={isMobile}
        rows={tableState?.length}
        currentPage={pagination.page}
        totalPages={pagination.totalPages}
        selectAllOption={true}
        onPageSelect={onPageSelect}
        pageLimit={pagination.limit}
        hide={emptyResponse()}
        tableListParameter={ASSEMBLE_LIST_TABLE_PARAMETER}
        getTableData={() => getAssembles(true)}
        tableRef={tableRef}
      />
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  assembleList: selectAssembleListData(),
  filter: selectAssembledTentsFilter()
});

const mapDispatchToProps = {
  getAssembleListAction: getAssembleList,
  setFilter: setAssembleListFilter,
  clearStateAction: clearAssembledLooseItemState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssembledBOMList);
