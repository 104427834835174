import React from 'react';
import styles from './ErrorFallback.module.scss';

import { BlueButton } from 'components/Buttons';

export function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className={styles.fallback}>
      <h2 className={styles.fallback__header}>Oops! Something went wrong.</h2>
      <p className={styles.fallback__message}>
        Please refresh the page or click the button below to try again.
      </p>
      <BlueButton label="Try Again" onClick={resetErrorBoundary} />
    </div>
  );
}
