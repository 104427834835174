import React, { useEffect, useState } from 'react';
import styles from './EditRecipientPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import InputSearchWithCreation from 'components/InputSearchWithCreation/InputSearchWithCreation';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import DetailedRow from 'components/DetailsComponents/DetailedRow';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useNotificationCenterActions,
  useNotificationCenterSelector
} from 'hooks/NotificationCenter';

import { ERROR_EMAIL_EXIST, ERROR_INVALID_EMAIL } from 'constants/infoSnackbarData';
import {
  ADD_NOTIFICATION_RECIPIENT,
  EDIT_NOTIFICATION_RECIPIENT
} from 'constants/dialogPopupsData';

import { emailRegexp, enqueueErrorSnackbar } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { tabs } from '../../../../../helpers';
import { getLocationFieldLabel, getPopupHintText } from '../../helpers';

import clsx from 'clsx';

export default function EditRecipientPopup({ open, onClose, data, onUpdate }) {
  const [options, setOptions] = useState({});
  const [modalData, setModalData] = useState({});

  const { getPopupCreationFormAction, addRecipientAction, editRecipientAction, checkEmailAction } =
    useNotificationCenterActions();
  const { selectedTab } = useNotificationCenterSelector();

  const methods = useForm({
    defaultValues: {
      users: [],
      assetPrefixes: [],
      locations: [],
      notificationCenterTab: selectedTab,
      isSelectedAllLocations: false,
      isSelectedAllAssetPrefixes: false,
      isBAARecipient: false
    },
    mode: 'onChange'
  });
  const { control, reset, formState, setError, getValues, clearErrors, setValue } = methods;
  const { errors } = formState;

  useEffect(() => {
    getPopupCreationFormAction({ notificationCenterTab: selectedTab }).then((res) =>
      setOptions(res)
    );
  }, []);

  useEffect(() => {
    data?.id && reset(data);
  }, [open]);

  const { assetPrefixes, locations, users, requestOrderRoles } = options;

  const handleCancelClick = () => {
    clearErrors();
    reset();
    setModalData({});
    onClose();
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const validateForm = () => {
    let isFormValid = true;
    if (!getValues('users')?.length) {
      setError('users', getErrorsProperties('Field is required'));
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleApplyClick = () => {
    if (!data?.id && !validateForm()) return;

    setModalData(data?.id ? EDIT_NOTIFICATION_RECIPIENT : ADD_NOTIFICATION_RECIPIENT);
  };

  const isEmailAlreadyRecipient = async (userEmail) => {
    let isExist = false;

    const result = await checkEmailAction({ userEmail, notificationCenterTab: selectedTab });

    if (result?.['displayErrorPopup']) {
      enqueueErrorSnackbar(result?.message || '');
      isExist = true;
    }

    return isExist;
  };

  const selectOption = async (name, value, selectionType, isInvalid) => {
    if (await isEmailAlreadyRecipient(value.email)) return;

    isInvalid && clearErrors('users');

    if (selectionType === 'remove-option') {
      const newValue = getValues('users').filter(({ id }) => id !== value.id);
      setValue('users', newValue, { shouldDirty: true });
      return;
    }

    const newValue = [...getValues('users'), value];
    setValue('users', newValue, { shouldDirty: true });
  };

  const selectRole = ({ name, value }) => {
    setValue(name, value);
  };

  const addUser = async (value, options, creationType, isInvalid) => {
    if (await isEmailAlreadyRecipient(value.email)) return;

    isInvalid && clearErrors('users');

    const newValue = creationType === 'existed' ? value : { id: 0, email: value?.email || '' };
    const newUsers = [...getValues('users'), newValue];

    setValue('users', newUsers, { shouldDirty: true });
  };

  const checkSelectAll = (name, value) => {
    if (name === 'assetPrefixes') {
      setValue('isSelectedAllAssetPrefixes', value?.length === assetPrefixes.length);
    } else {
      setValue('isSelectedAllLocations', value?.length === locations.length);
    }
  };

  const refreshTable = () => {
    onUpdate();
    handleCancelClick();
  };

  const agreeModal = () => {
    if (data?.id) {
      const putData = { user: getValues(), notificationCenterTab: selectedTab };
      editRecipientAction(putData).then(refreshTable);
    } else {
      addRecipientAction(getValues()).then(refreshTable);
    }
  };

  const closeModal = () => setModalData({});

  const conditions = {
    areUserChipsAvailable: !data?.id,
    isLocationFieldAvailable: ![tabs.generalReport, tabs.teamMemberStatus].includes(selectedTab),
    isPrefixFieldAvailable: [tabs.assetReport, tabs.ticketAndAssetTab].includes(selectedTab),
    isBAACheckboxAvailable: selectedTab === tabs.looseItems,
    isRequestOrderRoleAvailable: selectedTab === tabs.requestOrder,
    isHintAvailable: ![tabs.generalReport, tabs.teamMemberStatus].includes(selectedTab)
  };

  return (
    <DialogWrapper open={open} onClose={handleCancelClick}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <FormProvider {...methods}>
        <div
          className={clsx(styles.dialog, selectedTab === tabs.teamMemberStatus && styles.shorten)}>
          <DialogHeader
            onClose={handleCancelClick}
            title={`${data?.id ? 'Edit' : 'Add'} Recipient`}
          />
          <div className={styles.form}>
            {data?.id ? (
              <DetailedRow label="Recipient email" value={data?.email || ''} />
            ) : (
              <div className={styles.form__email}>
                <label>Enter email address*</label>
                <div className={styles.form__email_input}>
                  <Controller
                    name="users"
                    control={control}
                    render={({ field: { value, name }, fieldState: { error } }) => (
                      <InputSearchWithCreation
                        name={name}
                        options={users || []}
                        value={value}
                        onSelect={(name, value, type) => selectOption(name, value, type, !!error)}
                        getOptionLabel={(option) => option.email}
                        customLabel="email"
                        max={50}
                        isInvalid={!!error?.message}
                        invalidError={error?.message}
                        validateManualInput={(email) => !emailRegexp.test(email)}
                        snackbarError={ERROR_INVALID_EMAIL}
                        sameItemExistError={ERROR_EMAIL_EXIST}
                        onAdd={(value, options, creationType) =>
                          addUser(value, options, creationType, !!error)
                        }
                        disableCreation={selectedTab === tabs.teamMemberStatus}
                        tipLabel={selectedTab === tabs.teamMemberStatus && 'Select from the list'}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {conditions.areUserChipsAvailable && (
              <FormChipsContainer
                fieldName="users"
                getLabel={(user) => user.email}
                getClasses={(chip) => !chip.id && styles.customEmail}
                deleteParameter="email"
              />
            )}

            {conditions.isBAACheckboxAvailable && (
              <div className={styles.form__checkbox}>
                <label>Is Buy Ahead Account recipient</label>
                <FormCheckbox name="isBAARecipient" />
              </div>
            )}

            {conditions.isRequestOrderRoleAvailable && (
              <div className={styles.form__row}>
                <label>Request Order Role</label>
                <div className={styles.form__row_input}>
                  <FormSelectInput
                    name="requestOrderRole"
                    onSelect={selectRole}
                    options={requestOrderRoles || []}
                  />
                </div>
              </div>
            )}

            {conditions.isLocationFieldAvailable && (
              <div className={styles.form__row}>
                <label>{getLocationFieldLabel(selectedTab)}</label>
                <div className={styles.form__row_input}>
                  <FormAutoMultiSelect
                    name="locations"
                    options={{
                      label: 'siteCode',
                      selectAll: true,
                      hideTags: false,
                      disableByObjectTracker: false,
                      disableLabel: true,
                      extraAction: checkSelectAll
                    }}
                    menuItems={locations || []}
                  />
                </div>
              </div>
            )}
            {conditions.isPrefixFieldAvailable && (
              <div className={styles.form__row}>
                <label>Prefix</label>
                <div className={styles.form__row_input}>
                  <FormAutoMultiSelect
                    name="assetPrefixes"
                    options={{
                      label: 'prefix',
                      selectAll: true,
                      hideTags: false,
                      disableByObjectTracker: false,
                      disableLabel: true,
                      extraAction: checkSelectAll
                    }}
                    menuItems={assetPrefixes || []}
                  />
                </div>
              </div>
            )}

            {conditions.isHintAvailable && <p>{getPopupHintText(selectedTab)}</p>}
          </div>
          <div className={styles.footer}>
            <MainButton text="Cancel" action={handleCancelClick} type="secondary" />
            <MainButton
              text="Apply"
              action={handleApplyClick}
              type="primary"
              isDisabled={isFormInvalid()}
            />
          </div>
        </div>
      </FormProvider>
    </DialogWrapper>
  );
}
