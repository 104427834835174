import React, { useEffect } from 'react';
import styles from './FlowStatistics.module.scss';

import Separator from 'components/Separator/Separator';
import { TransparentButton } from 'components/Buttons';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';

import FlowStatisticsChart from './Chart';
import FlowStatisticsFilter from './Filter';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { fileDownload } from 'helpers/AppHelpers';

import csvIcon from 'assets/images/csvIcon.svg';

export default function FlowStatistics() {
  const { flowStatistics, tabConfig } = useTicketsDashboardSelector();
  const { getFlowStatisticCsvResourceAction, clearTabStateAction } = useTicketsDashboardActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'flowStatistics' });
    };
  }, []);

  const getFilterData = (sectionId) => tabConfig?.items?.find(({ id }) => id === sectionId) || {};

  const downloadCsv = (dashboardId) => {
    getFlowStatisticCsvResourceAction({ dashboardId }).then((res) => fileDownload(res));
  };

  return (
    <DashboardWrapper>
      <div className={styles.statistics}>
        {flowStatistics.map((sectionData, index) => (
          <div key={sectionData.id} className={styles.statistics__dashboard}>
            <SectionHeader title={sectionData.name || ''} />
            <FlowStatisticsFilter filter={getFilterData(sectionData.id)} />
            <TransparentButton
              label="Download CSV File"
              className={styles.csvButton}
              onClick={() => downloadCsv(sectionData.id)}>
              <img src={csvIcon} alt="" />
            </TransparentButton>
            <div className={styles.statistics__dashboard_info}>
              <FlowStatisticsChart data={sectionData} />
            </div>
            {flowStatistics.length > 1 && index !== flowStatistics.length - 1 && <Separator />}
          </div>
        ))}
      </div>
    </DashboardWrapper>
  );
}
