import { useSelector } from 'react-redux';
import {
  selectBreakdownFilterData,
  selectFilterCriteriaData,
  selectMasterFilterData,
  selectTabSelectedData,
  selectTrackerResponseReceivedData,
  selectTrackerTableData
} from 'pages/TeamSchedule/ProjectSchedule/selectors';

export function useTrackerSelector() {
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const masterFilter = useSelector(selectMasterFilterData());
  const breakdownFilter = useSelector(selectBreakdownFilterData());

  const selectedTab = useSelector(selectTabSelectedData());
  const isMasterSelected = selectedTab === 'Master';

  const filter = isMasterSelected ? masterFilter : breakdownFilter;

  const trackerTableData = useSelector(selectTrackerTableData());

  const isResponseReceived = useSelector(selectTrackerResponseReceivedData());

  return {
    filterCriteria,
    masterFilter,
    breakdownFilter,
    filter,
    selectedTab,
    isMasterSelected,
    trackerTableData,
    isResponseReceived
  };
}
