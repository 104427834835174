import { createSelector } from 'reselect';

const reportListData = (state) => state.reportListData;

export const selectSingleGeneralReport = () =>
  createSelector([reportListData], (state) => state.currentGeneralReport);

export const selectCreationFormData = () =>
  createSelector([reportListData], (state) => state.creationForm);

export const selectActiveLocationsLocationReport = () =>
  createSelector([reportListData], (state) => state.activeLocationsLocationReport);

export const selectLocationReportsFilter = () =>
  createSelector([reportListData], (state) => state.locationReportFilter);

export const selectAssetReportsFilter = () =>
  createSelector([reportListData], (state) => state.assetReportFilter);

export const selectGeneralReportsFilter = () =>
  createSelector([reportListData], (state) => state.generalReportFilter);

export const selectReportCreationFormData = () =>
  createSelector([reportListData], (state) => state.reportTemplateCreationForm);

export const selectSingleReportTemplate = () =>
  createSelector([reportListData], (state) => state.reportTemplate);

export const selectReportDataFromSearch = () =>
  createSelector([reportListData], (state) => state.reportDataFromSearch);

export const selectActiveTemplate = () =>
  createSelector([reportListData], (state) => state.activeTemplate);

export const selectActiveReportTemplate = () =>
  createSelector([reportListData], (state) => state.activeReportTemplate);

export const selectComparePreselectData = () =>
  createSelector([reportListData], (state) => state.comparePreselectData);

export const selectCompareFieldsData = () =>
  createSelector([reportListData], (state) => state.compareFields);

export const selectReportComparedData = () =>
  createSelector([reportListData], (state) => state.reportComparedData);

export const selectFilterCriteriaData = () =>
  createSelector([reportListData], (state) => state.filterCriteria);
