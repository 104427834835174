import React from 'react';
import styles from './LimitSelector.module.scss';

import { MenuItem, Select } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function LimitSelector({
  selectedLimit,
  withOption100,
  withOptionAll,
  isAllSelected,
  onChange
}) {
  const isMobile = useMobileViewport();

  const changeLimit = (e) => {
    const limit = e.target.value;
    onChange(limit);
  };

  const rowsPerPageOptions = [
    10,
    25,
    50,
    withOption100 && 100,
    withOptionAll && !isMobile ? 'All' : null
  ].filter(Boolean);

  return (
    <div className={styles.selector}>
      <label>Rows per page:</label>
      <Select
        classes={{ root: styles.input }}
        value={isAllSelected ? 'All' : selectedLimit || ''}
        onChange={changeLimit}
        variant="outlined">
        {rowsPerPageOptions.length &&
          rowsPerPageOptions.map((option, ind) => (
            <MenuItem
              classes={{ root: styles.item__root, icon: styles.item__icon }}
              value={option}
              key={`${ind}_${option}`}>
              {option}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
