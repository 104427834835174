import React, { useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingSummary.module.scss';

import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { BILL_OF_LADING_TABLE_PARAMETER } from 'constants/configTableConstants';
import {
  BILL_OF_LADING_DETAILS_PATH,
  BILL_OF_LADING_EDIT_PATH,
  LEAVELIST_DETAILED_PATH,
  LOCATION_DETAILED_PATH,
  PICKLIST_DETAILED_PATH
} from 'constants/routeConstants';
import { allKeyboardLatSymbols, dateTimeFormat, formatDate } from 'helpers/AppHelpers';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { tableHeadConfig } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoLFilterData } from '../selectors';
import {
  clearBOLState,
  getBillOfLadingList,
  setBillOfLadingListFilter
} from 'actions/billOfLadingActions';
import DownloadBlankButton from '../commonComponents/DownloadBlankButton';
import BillOfLadingFilter from './Filter/BillOfLadingFilter';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';
import { NoDataTableRow, Pagination } from 'components/SummaryComponents';

function BillOfLadingSummary() {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isConfigReceived, getTableLimit } = useUserConfig();

  const query = new URLSearchParams(window.location.search);
  const searchValue = query.get('searchQuery');

  const filter = useSelector(selectBoLFilterData());

  const [tableState, setTableState] = useState([]);

  const getBillOfLadingsAction = (query) => dispatch(getBillOfLadingList(query));
  const setFilterAction = (tableFilter) => dispatch(setBillOfLadingListFilter(tableFilter));

  const generateInitialQuery = () => {
    const queryFilter = {
      ...filter,
      filters: { ...filter.filters, searchQuery: searchValue || '' },
      pagination: { ...pagination, limit: getTableLimit(BILL_OF_LADING_TABLE_PARAMETER) }
    };
    if (searchValue) {
      query.delete('searchQuery');
      history.replace({ search: query.toString() });
    }
    return queryFilter;
  };

  const getBillOfLadings = (query, isAutoload) => {
    setFilterAction(query);

    getBillOfLadingsAction(query).then((tableData) => {
      setBillOfLadings(tableData, query, isAutoload);
    });
  };

  const setBillOfLadings = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getBillOfLadings(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      dispatch(clearBOLState());
    };
  }, []);

  const findMatches = (value) => getBillOfLadings(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getBillOfLadings(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getBillOfLadings(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getBillOfLadings(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getBillOfLadings(
      getChangedByLimitFilter(filter, getTableLimit(BILL_OF_LADING_TABLE_PARAMETER))
    );

  const { filters, pagination, sortRules, responseReceived } = filter;

  const isTableEmpty =
    filters.searchQuery === '' &&
    !filters.picklistIds.length &&
    !filters.leavelistIds.length &&
    !filters.originLocationIds.length &&
    !filters.destinationLocationIds.length &&
    filters.createdFrom === null &&
    filters.createdTo === null;

  const handleCreateBolClick = () => history.push(BILL_OF_LADING_EDIT_PATH);

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <SummaryHeader title={isMobile ? 'BOL Summary' : 'Bill of Lading Summary'}>
          {!isMobile && <DownloadBlankButton />}
          <MainButton text="Create custom bol" type="primary" action={handleCreateBolClick} />
        </SummaryHeader>
        <div className={styles.header__controls}>
          <BillOfLadingFilter isMobile={isMobile} onApply={applyFilter} />
          <SearchTextFieldRefactored
            inputValue={filters.searchQuery}
            validationRule={allKeyboardLatSymbols}
            updateSearchParam={findMatches}
            rightSpace={isMobile}
          />
        </div>
      </div>
      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState.length
              ? tableState.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <LinkComponent
                        name={row?.id || ''}
                        path={`${BILL_OF_LADING_DETAILS_PATH}/${row?.id}`}
                        boldText
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row?.freightBill || ''}</StyledTableCell>
                    <StyledTableCell>
                      {row?.picklist?.id ? (
                        <LinkComponent
                          name={row?.picklist?.name || ''}
                          path={`${PICKLIST_DETAILED_PATH}/${row?.picklist?.id}`}
                          isRedirectAvailable={!!row?.picklist?.id}
                        />
                      ) : row?.leavelist?.id ? (
                        <LinkComponent
                          name={row?.leavelist?.name || ''}
                          path={`${LEAVELIST_DETAILED_PATH}/${row?.leavelist?.id}`}
                          isRedirectAvailable={!!row?.leavelist?.id}
                        />
                      ) : (
                        'Custom BOL'
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <LinkComponent
                        name={row?.originLocation || ''}
                        path={`${LOCATION_DETAILED_PATH}/${row?.originLocationItem?.id}`}
                        isRedirectAvailable={
                          !row.originLocationItem?.deleted && !!row.originLocationItem?.id
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <LinkComponent
                        name={row?.destinationLocation || ''}
                        path={`${LOCATION_DETAILED_PATH}/${row?.destinationLocationItem?.id}`}
                        isRedirectAvailable={
                          !row.destinationLocationItem?.deleted &&
                          !!row?.destinationLocationItem?.id
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row?.locationJobNumber || ''}</StyledTableCell>
                    <StyledTableCell>
                      {row?.createdByUser?.firstName + ' ' + row?.createdByUser?.lastName}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDate(row?.createdAtUtc, dateTimeFormat)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : responseReceived && <NoDataTableRow isTableFiltered={!isTableEmpty} />}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        selectedLimit={pagination.limit}
        totalPages={pagination.totalPages}
        tableRef={tableRef}
        tableName={BILL_OF_LADING_TABLE_PARAMETER}
        onChangeLimit={changeLimit}
        onChangePage={changePage}
        withOptionAll
      />
    </section>
  );
}

export default BillOfLadingSummary;
