import { createSelector } from 'reselect';

const activeLocationsData = (state) => state.locationsData;

export const selectActiveLocationsListData = () =>
  createSelector([activeLocationsData], (state) => state.activeLocationsList);

export const selectMapFilterCriteriaData = () =>
  createSelector([activeLocationsData], (state) => state.mapFilterCriteria);

export const selectMapAdvancedPrefilterData = () =>
  createSelector([activeLocationsData], (state) => state.mapAdvancedPrefilter);

export const selectNearestLocationsData = () =>
  createSelector([activeLocationsData], (state) => state.nearestLocationsData);

const locationsData = (state) => state.locationsData;

export const selectStatesListData = () =>
  createSelector([locationsData], (state) => state.statesList);
