import React, { useEffect } from 'react';
import styles from './NotificationCenterTabs.module.scss';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import { TOGGLE_BUTTONS, defaultTab } from '../../helpers';

import {
  useNotificationCenterActions,
  useNotificationCenterSelector
} from 'hooks/NotificationCenter';

export default function NotificationCenterTabs() {
  const { selectedTab } = useNotificationCenterSelector();
  const { setTabAction } = useNotificationCenterActions();

  useEffect(() => {
    if (selectedTab === '') {
      clickTab(defaultTab);
    }
  }, []);

  const clickTab = (tab) => tab && setTabAction(tab);

  return (
    <div className={styles.tabs}>
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        onChange={clickTab}
        selectedTab={selectedTab}
        classes={styles.tabs__bar}
      />
    </div>
  );
}
