import {
  SET_CURRENT_USER,
  CLEAR_USER_LIST_STATE,
  SET_USER_ROLE_LIST,
  SET_ACTIVE_LOCATIONS_USER,
  SET_USER_LIST_FILTER,
  SET_USER_LIST_FILTER_CRITERIA,
  SET_USER_DEPARTMENTS_LIST,
  SET_USER_EDIT_TAB,
  SET_USER_PREFIX_LIST
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: ''
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  currentUser: {},
  userRoleList: [],
  activeLocations: [],
  departments: [],
  prefixList: [],
  filter: { ...defaultFilter },
  filterCriteria: {},
  userEditTab: 'general'
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload
      };
    case SET_USER_ROLE_LIST:
      return {
        ...state,
        userRoleList: payload
      };
    case SET_USER_DEPARTMENTS_LIST:
      return {
        ...state,
        departments: payload
      };
    case SET_USER_PREFIX_LIST:
      return { ...state, prefixList: payload };
    case SET_ACTIVE_LOCATIONS_USER:
      return {
        ...state,
        activeLocations: payload
      };
    case SET_USER_LIST_FILTER:
      return {
        ...state,
        filter: payload
      };
    case SET_USER_LIST_FILTER_CRITERIA:
      return {
        ...state,
        filterCriteria: payload
      };
    case SET_USER_EDIT_TAB:
      return {
        ...state,
        userEditTab: payload
      };
    case CLEAR_USER_LIST_STATE:
      return {
        ...initialState,
        filter: getClearedFilterByUnmount(state.filter)
      };
    default:
      return state;
  }
};

export default userReducer;
