import styles from '../AssembleCreate.module.scss';
import SelectInput from 'components/SelectInput/SelectInput';
import { TextField } from '@material-ui/core';
import React from 'react';
import InputSearchWithMultiselect from '../../../../../components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { allKeyboardLatSymbols, reformatInputValue } from '../../../../../helpers/AppHelpers';

function PageForm({
  values,
  looseItemsConfigurationList,
  locationList,
  validationErrors,
  quantityList,
  validatedFields,
  setValues,
  validateForm
}) {
  const onInput = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    validateForm(key, '');
    if (value) {
      if (key === 'name' || key === 'notes') {
        value = reformatInputValue(value, 1000, allKeyboardLatSymbols);
      }
    }
    setValues({ ...values, [key]: value });
  };
  const onSelect = (selectedItem) => {
    setValues({
      ...values,
      [selectedItem.name]:
        selectedItem.name === 'quantity' ? selectedItem.value.value : selectedItem.value
    });
    validateForm(selectedItem.name, '');
  };
  const onLocationSelect = (name, value) => {
    setValues({ ...values, [name]: value, sublocation: null });
    validateForm(name, '');
  };
  const onSublocationSelect = (name, value) => setValues({ ...values, [name]: value });

  return (
    <div className={styles.pageForm}>
      <div className={styles.firstBlock}>
        <div className={styles.firstRow}>
          <label className={styles.rowLabel}>Configuration*</label>
          <div className={styles.inputWrapper} ref={validatedFields.looseItemConfiguration}>
            <SelectInput
              name="looseItemConfiguration"
              value={values?.looseItemConfiguration?.id || ''}
              menuItems={looseItemsConfigurationList || []}
              onSelect={onSelect}
              isInvalid={!!validationErrors.looseItemConfiguration}
            />
            {!!validationErrors.looseItemConfiguration && (
              <span>{validationErrors.looseItemConfiguration}</span>
            )}
          </div>
        </div>
        <div className={styles.secondRow}>
          <label className={styles.rowLabel}>Available amount*</label>
          <div className={styles.inputWrapper} ref={validatedFields.quantity}>
            <SelectInput
              name="quantity"
              value={values?.quantity || ''}
              menuItems={quantityList || []}
              onSelect={onSelect}
              isInvalid={!!validationErrors.quantity}
              disabled={!values?.looseItemConfiguration?.id}
            />
            {!!validationErrors.quantity && <span>{validationErrors.quantity}</span>}
          </div>
        </div>
      </div>
      <div className={styles.secondBlock}>
        <div className={styles.formRow}>
          <label className={styles.rowLabel}>Assembly name*</label>
          <div className={styles.inputWrapper}>
            <TextField
              name="name"
              className={`${styles.textInput} ${validationErrors.name ? styles.error : ''}`}
              variant="outlined"
              value={values?.name || ''}
              onChange={onInput}
              ref={validatedFields.name}
            />
            {!!validationErrors.name && <span>{validationErrors.name}</span>}
          </div>
        </div>
        <div className={styles.formRow}>
          <label className={styles.rowLabel}>Location*</label>
          <div className={styles.inputWrapper} ref={validatedFields.location}>
            <InputSearchWithMultiselect
              name="location"
              defaultValue={values?.location?.id || ''}
              options={locationList || []}
              onFilterSelect={onLocationSelect}
              multiselect={false}
              isInvalid={!!validationErrors.location}
            />
            {!!validationErrors.location && <span>{validationErrors.location}</span>}
          </div>
        </div>
        <div className={styles.formRow}>
          <label className={styles.rowLabel}>Sublocation</label>
          <div className={styles.inputWrapper}>
            <InputSearchWithMultiselect
              name="sublocation"
              defaultValue={values?.sublocation?.id || ''}
              options={values?.location?.sublocations || []}
              onFilterSelect={onSublocationSelect}
              multiselect={false}
              disabled={!values?.location?.id}
            />
          </div>
        </div>
        <div className={styles.notesRow}>
          <label className={styles.rowLabel}>Notes</label>
          <div className={styles.multiTextInput}>
            <TextField
              name="notes"
              multiline
              rows={4}
              variant="outlined"
              value={values.notes || ''}
              onChange={onInput}
            />
            <span className={styles.multiTextInput__counter}>
              {values?.notes ? values.notes.length : 0}
              /1000
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageForm;
