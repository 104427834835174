import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import { FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { FormProvider, useForm } from 'react-hook-form';

import { isSuccessfulStatus } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

import { generateChips } from './helpers';

import FilterDurations from '../../FilterBlocks/Durations';

export default function TurnoverStatisticsFilter({ filter }) {
  const methods = useForm({
    defaultValues: {
      assets: [],
      locations: [],
      prefixes: [],
      rootCauses: [],
      ticketTypes: [],
      durations: [
        { dateFrom: null, dateTo: null },
        { dateFrom: null, dateTo: null }
      ]
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch, setError, formState } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const [open, setOpen] = useState(false);
  const [chips, setChips] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);

  const { filterCriteria } = useTicketsDashboardSelector();
  const {
    getDashboardConfigAction,
    updateSectionConfigAction,
    getTurnoverStatisticsAction,
    getAssetOptionsAction
  } = useTicketsDashboardActions();

  const getAssetOptions = (prefixIds) =>
    getAssetOptionsAction({ filters: { prefixIds } }).then((res) =>
      setAssetOptions(res?.items || [])
    );

  useEffect(() => {
    if (open) {
      filter?.prefixes?.length && getAssetOptions(filter.prefixes.map(({ id }) => id));
      reset(filter);
    }
  }, [open]);

  useEffect(() => {
    if (filterCriteria?.['locations']?.length) {
      reset(filter);
      createChips(filter);
    }
  }, [filter, filterCriteria]);

  const { locations, prefixes, rootCauses, ticketTypes } = filterCriteria;

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      assets: [],
      locations: [],
      prefixes: [],
      rootCauses: [],
      ticketTypes: [],
      durations: [
        { dateFrom: null, dateTo: null, endDateAsCurrent: false },
        { dateFrom: null, dateTo: null, endDateAsCurrent: false }
      ]
    };
    reset(defaultState);
  };

  const closeFilter = () => setOpen(false);
  const toggleFilter = (value) => setOpen(value || !open);

  const validateForm = () => {
    const values = getValues();
    const { durations } = values;

    let isFormValid = true;

    if (!durations?.[0]?.dateFrom) {
      setError('durations[0].dateFrom', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    if (!durations?.[0]?.dateTo) {
      setError('durations[0].dateTo', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    if (
      (durations?.[1]?.dateFrom && !durations?.[1]?.dateTo) ||
      (!durations?.[1]?.dateFrom && durations?.[1]?.dateTo)
    ) {
      if (!durations?.[1]?.dateFrom)
        setError('durations[1].dateFrom', getErrorsProperties('Field is required'));
      if (!durations?.[1]?.dateTo)
        setError('durations[1].dateTo', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const applyFilter = async () => {
    if (!validateForm()) return;

    const values = getValues();

    const response = await updateSectionConfigAction(values);

    if (!isSuccessfulStatus(response?.status)) return;

    closeFilter();

    getDashboardConfigAction({ dashboardType: values.dashboardType }).then(() => {
      getTurnoverStatisticsAction();
    });
  };

  const createChips = (data) => setChips(generateChips(data));

  const refreshAssetsValue = () => {
    if (!getValues('assets')?.length) return;

    if (!getValues('prefixes').length) {
      setValue('assets', []);
      return;
    }

    const prefixIds = getValues('prefixes').map(({ id }) => id);
    const filteredAssets = getValues('assets').filter(({ assetPrefix }) =>
      prefixIds.includes(assetPrefix.id)
    );

    setValue('assets', filteredAssets);
  };

  const handlePrefixSelect = (_, value) => {
    refreshAssetsValue();

    if (!value?.length) return;

    getAssetOptions(value?.map(({ id }) => id) || []);
  };

  const prefixesWatcher = watch('prefixes');

  const isFilterActive = () =>
    open ||
    chips?.length ||
    filter?.durations?.[0]?.dateFrom ||
    filter?.durations?.[0]?.dateTo ||
    filter?.durations?.[1]?.dateFrom ||
    filter?.durations?.[1]?.dateTo;

  return (
    <FilterWrapper
      open={open}
      transparentBackDrop={false}
      disableChipDelete
      onClick={toggleFilter}
      onApply={applyFilter}
      onCancel={closeFilter}
      onClear={clearFilter}
      onBack={closeFilter}
      isApplyDisabled={isFormInvalid}
      chips={chips}
      isFilterActive={isFilterActive}>
      <FormProvider {...methods}>
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <FormLabel>Location</FormLabel>
              <FormAutoMultiSelect
                name="locations"
                menuItems={locations || []}
                options={{
                  label: 'siteCode',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Prefix</FormLabel>
              <FormAutoMultiSelect
                name="prefixes"
                menuItems={prefixes || []}
                options={{
                  label: 'prefix',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  extraAction: handlePrefixSelect
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Asset</FormLabel>
              <FormAutoMultiSelect
                name="assets"
                menuItems={assetOptions || []}
                options={{
                  label: 'drCode',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  isDisabled: !prefixesWatcher?.length
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Ticket Type</FormLabel>
              <FormAutoMultiSelect
                name="ticketTypes"
                menuItems={ticketTypes || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Root Causes</FormLabel>
              <FormAutoMultiSelect
                name="rootCauses"
                menuItems={rootCauses || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
          </div>
          <div className={styles.block}>
            <FilterDurations />
          </div>
        </div>
      </FormProvider>
    </FilterWrapper>
  );
}
