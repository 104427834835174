import React from 'react';
import notFoundIcon from 'assets/images/notFound.svg';
import styles from './NotFound.module.scss';

function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.mainBlock}>
        <img src={notFoundIcon} alt="not_found" />
        <label>Page not found</label>
        <span>
          The page you are looking for does not exist or another error has occurred
        </span>
      </div>
    </div>
  );
}

export default NotFound;
