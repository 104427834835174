import React from 'react';
import styles from './components.module.scss';
import { MainButton } from 'components/StyledComponents';
import { useUserConfig } from 'hooks/useUserConfig';
import { usePrefixSelector } from 'hooks/Prefix';
import { ASSET_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';

export default function Footer() {
  const history = useHistory();
  const { isBasicRoleUser } = useUserConfig();

  const { currentPrefix } = usePrefixSelector();
  const { prefixType } = currentPrefix;

  const handelAddAssetsClick = () => {
    history.push(`${ASSET_EDIT_PATH}?activePrefixId=${currentPrefix.id}`);
  };

  return (
    <div className={styles.footer}>
      {!isBasicRoleUser && (
        <MainButton
          text={`Add asset in ${prefixType?.name}`}
          action={handelAddAssetsClick}
          type="primary"
          width="long"
        />
      )}
    </div>
  );
}
