import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTicketTypesApi = (query) =>
  api.get(`/TicketTypes?${queryToString(query)}`).then((res) => res.data);

export const getCreationFormApi = () =>
  api.get('/TicketType/CreationForm').then((res) => res.data);

export const getSingleTicketTypeApi = (query) =>
  api.get(`/TicketType?${queryToString(query)}`).then((res) => res.data);

export const createTicketTypeApi = (params) =>
  api.post('/TicketType', params).then((res) => res.data);

export const updateTicketTypeApi = (params) =>
  api.put('/TicketType', params).then((res) => res.data);
