import { useSelector } from 'react-redux';

import {
  selectIsDataLoadedData,
  selectSearchQueryData,
  selectSearchResultsData
} from 'pages/Search/selectors';

export function useSearchSelector() {
  const searchQuery = useSelector(selectSearchQueryData());

  const searchResults = useSelector(selectSearchResultsData());

  const isDataLoaded = useSelector(selectIsDataLoadedData());

  return {
    searchQuery,
    searchResults,
    isDataLoaded
  };
}
