import React from 'react';
import { Checkbox, TableHead, TableRow } from '@material-ui/core';
import { StyledTableCell } from 'components/StyledComponents';
import styles from './RequsetTableHead.module.scss';
import clsx from 'clsx';
import { emptyValue } from 'helpers/AppHelpers';

function RequestTableHead({ handleAllCheckbox, isAllChecked, permissions, isTableNotEmpty }) {
  const {
    isCheckboxColumnAvailable,
    isQTYColumnAvailable,
    isETAColumnAvailable,
    isEditColumnAvailable,
    isDeleteColumnAvailable,
    isStatusColumnAvailable
  } = permissions;

  return (
    <TableHead>
      <TableRow>
        {isCheckboxColumnAvailable && isTableNotEmpty && (
          <StyledTableCell rowSpan={2} className={clsx(styles.checkboxHeadCell, styles.commonCell)}>
            <Checkbox
              classes={{ root: styles.checkbox, checked: styles.checked }}
              onChange={handleAllCheckbox}
              value={'selectAll'}
              checked={isAllChecked}
            />
          </StyledTableCell>
        )}
        <StyledTableCell rowSpan={2} className={clsx(styles.typeHeadCell, styles.commonCell)}>
          Entity Type
        </StyledTableCell>
        <StyledTableCell rowSpan={2} className={clsx(styles.nameHeadCell, styles.commonCell)}>
          Entity Name
        </StyledTableCell>
        <StyledTableCell rowSpan={2} className={clsx(styles.quantityHeadCell, styles.commonCell)}>
          QTY
        </StyledTableCell>
        <StyledTableCell
          colSpan={2}
          className={clsx(styles.transportationHeadCell, styles.commonCell)}>
          Transportation
        </StyledTableCell>
        <StyledTableCell colSpan={2} className={clsx(styles.purchasingHeadCell, styles.commonCell)}>
          Purchasing
        </StyledTableCell>
        {isQTYColumnAvailable && (
          <StyledTableCell
            colSpan={2}
            className={clsx(styles.qtySecondHeadCell, styles.commonCell)}>
            QTY
          </StyledTableCell>
        )}
        {isETAColumnAvailable && (
          <StyledTableCell rowSpan={2} className={clsx(styles.etaHeadCell, styles.commonCell)}>
            ETA
          </StyledTableCell>
        )}
        {isStatusColumnAvailable && (
          <StyledTableCell rowSpan={2} className={clsx(styles.statusHeadCell, styles.commonCell)}>
            Status
          </StyledTableCell>
        )}
        <StyledTableCell rowSpan={2} className={clsx(styles.notesHeadCell, styles.commonCell)}>
          Notes
        </StyledTableCell>
        <StyledTableCell rowSpan={2} className={clsx(styles.fileHeadCell, styles.commonCell)}>
          File
        </StyledTableCell>
        <StyledTableCell rowSpan={2} className={clsx(styles.picHeadCell, styles.commonCell)}>
          Pic.
        </StyledTableCell>
        {isEditColumnAvailable && (
          <StyledTableCell rowSpan={2} className={clsx(styles.editHeadCell, styles.commonCell)}>
            {emptyValue}
          </StyledTableCell>
        )}
        {isDeleteColumnAvailable && (
          <StyledTableCell rowSpan={2} className={clsx(styles.editHeadCell, styles.commonCell)}>
            {emptyValue}
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow>
        <StyledTableCell className={clsx(styles.transportationCostSubHeadCell, styles.commonCell)}>
          Cost
        </StyledTableCell>
        <StyledTableCell
          className={clsx(styles.transportationTimelineSubHeadCell, styles.commonCell)}>
          Timeline
        </StyledTableCell>
        <StyledTableCell className={clsx(styles.purchasingCostSubHeadCell, styles.commonCell)}>
          Cost
        </StyledTableCell>
        <StyledTableCell className={clsx(styles.purchasingTimelineSubHeadCell, styles.commonCell)}>
          Timeline
        </StyledTableCell>
        {isQTYColumnAvailable && (
          <>
            <StyledTableCell
              className={clsx(styles.transportationQTYSubHeadCell, styles.commonCell)}>
              Transport
            </StyledTableCell>
            <StyledTableCell className={clsx(styles.purchasingQTYSubHeadCell, styles.commonCell)}>
              Purchase
            </StyledTableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

export default RequestTableHead;
