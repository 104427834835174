import {
  SET_TM_DASHBOARD_TAB,
  SET_TM_DASHBOARD_TAB_CONFIG,
  SET_TM_DASHBOARD_R_AND_R_DASHBOARD,
  SET_TM_DASHBOARD_LEVELS_DASHBOARD,
  SET_TM_DASHBOARD_SNAPSHOT_DASHBOARD,
  SET_TM_DASHBOARD_FILTER_CRITERIA,
  CLEAR_TM_DASHBOARD_STATE,
  CLEAR_TM_DASHBOARD_TAB_STATE
} from 'constants/reduceConstants';

const initialState = {
  tab: '',
  tabConfig: {},
  filterCriteria: {},
  RRDashboard: [],
  levelsDashboard: [],
  snapshotDashboard: []
};

const tmDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TM_DASHBOARD_TAB:
      return { ...state, tab: payload };
    case SET_TM_DASHBOARD_TAB_CONFIG:
      return { ...state, tabConfig: payload };
    case SET_TM_DASHBOARD_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_TM_DASHBOARD_R_AND_R_DASHBOARD:
      return { ...state, RRDashboard: payload };
    case SET_TM_DASHBOARD_LEVELS_DASHBOARD:
      return { ...state, levelsDashboard: payload };
    case SET_TM_DASHBOARD_SNAPSHOT_DASHBOARD:
      return { ...state, snapshotDashboard: payload };
    case CLEAR_TM_DASHBOARD_TAB_STATE:
      return { ...state, tabConfig: {}, [payload.stateName]: [] };
    case CLEAR_TM_DASHBOARD_STATE:
      return initialState;
    default:
      return state;
  }
};

export default tmDashboardReducer;
