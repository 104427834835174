import React, { useEffect, useState } from 'react';
import { cloneObj, getRandomId, reformatInputValue } from 'helpers/AppHelpers';
import {
  MainButton,
  StyledSwitch,
  StyledTableCell,
  StyledTableRow
} from 'components/StyledComponents/';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';

export default function TableReportTemplate({
  index,
  template,
  dragIsActive,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  reportType,
  published
}) {
  const { data } = template;
  const { active, compare, required } = data;

  const [state, setState] = useState({
    rows: [{ fieldId: getRandomId(), text: '' }],
    columns: [{ fieldId: getRandomId(), text: '' }]
  });
  const { rows, columns } = state;

  useEffect(() => {
    if (!active) {
      setState({
        rows: [{ fieldId: getRandomId(), text: '' }],
        columns: [{ fieldId: getRandomId(), text: '' }]
      });
      return;
    }
    if (data.rows?.length) {
      setState({
        rows: data.rows.map((el) => ({ ...el, fieldId: getRandomId() })),
        columns: data.rows[0].columns.map((el) => ({ ...el, fieldId: getRandomId() }))
      });
    }
  }, [active]);

  const handleSwitchClick = (e) => {
    onUpdate(index, {
      ...template,
      data: { ...template.data, [e.target.name]: !template.data[e.target.name] }
    });
  };

  const onInput = (event) => {
    let value = event.target.value;

    value = reformatInputValue(value, 500);

    onUpdate(index, {
      ...template,
      data: { ...template.data, text: value }
    });
  };

  const onSave = () => {
    const rowsValue = rows.map((el) => ({ ...el, columns }));

    if (data.text) {
      onUpdate(index, { ...template, data: { ...template.data, rows: rowsValue, active: false } });
    } else {
      onDelete(index);
    }
  };

  const onAddField = (type) => {
    setState({ ...state, [type]: [...state[type], { fieldId: getRandomId(), text: '' }] });
  };

  const onFieldInput = (event, index, type) => {
    let value = event.target.value;

    value = reformatInputValue(value, 500);

    const newState = cloneObj(state);
    newState[type][index].text = value;
    setState(newState);
  };

  const onDeleteField = (i, type) =>
    setState({ ...state, [type]: state[type].filter((_, index) => i !== index) });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result, type) => {
    if (!result.destination) return;
    const items = reorder(state[type], result.source.index, result.destination.index);
    setState({ ...state, [type]: items });
  };

  const isSaveDisabled = () =>
    !data.text?.length ||
    rows.map(({ text }) => text).includes('') ||
    columns.map(({ text }) => text).includes('');

  return (
    <section className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Custom Table</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <TextField
              placeholder="Enter the table’s title*"
              multiline
              name="text"
              className={styles.textInput}
              variant="outlined"
              value={data.text || ''}
              onChange={onInput}
            />

            <div className={styles.constructor}>
              <div className={styles.constructor__block}>
                <div className={styles.constructor__block_header}>Rows</div>
                <DragDropContext onDragEnd={(res) => onDragEnd(res, 'rows')}>
                  <Droppable droppableId={`droppableRows-${index}`}>
                    {(provided) =>
                      !!rows.length && (
                        <div
                          className={styles.constructor__block_body}
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          {rows.map((row, index) => (
                            <Draggable
                              key={`${row.fieldId}`}
                              draggableId={`${row.fieldId}`}
                              index={index}>
                              {(provided) => (
                                <div
                                  key={row.fieldId}
                                  className={styles.constructor__field}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className={styles.constructor__field_drag}>
                                    <MenuIcon className={styles.dragIcon} />
                                  </div>
                                  <div className={styles.constructor__field_input}>
                                    <TextField
                                      placeholder="Enter the row’s title*"
                                      name="text"
                                      className={styles.textInput}
                                      variant="outlined"
                                      value={row.text || ''}
                                      onChange={(e) => onFieldInput(e, index, 'rows')}
                                    />
                                  </div>
                                  <div className={styles.constructor__field_delete}>
                                    <IconButton
                                      className={rows.length <= 1 && styles.disabled}
                                      onClick={() => onDeleteField(index, 'rows')}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )
                    }
                  </Droppable>
                </DragDropContext>
                <div className={styles.constructor__block_footer}>
                  <MainButton
                    text="+"
                    isMobile
                    type="primary"
                    isDisabled={rows.length >= 25}
                    action={() => onAddField('rows')}
                  />
                  <span>Add a new row</span>
                </div>
              </div>
              <div className={styles.constructor__separate}></div>
              <div className={styles.constructor__block}>
                <div className={styles.constructor__block_header}>Columns</div>
                <DragDropContext onDragEnd={(res) => onDragEnd(res, 'columns')}>
                  <Droppable droppableId={`droppableColumns-${index}`}>
                    {(provided) =>
                      !!columns.length && (
                        <div
                          className={styles.constructor__block_body}
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          {columns.map((col, index) => (
                            <Draggable
                              key={`${col.fieldId}`}
                              draggableId={`${col.fieldId}`}
                              index={index}>
                              {(provided) => (
                                <div
                                  className={styles.constructor__field}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className={styles.constructor__field_drag}>
                                    <MenuIcon className={styles.dragIcon} />
                                  </div>
                                  <div className={styles.constructor__field_input}>
                                    <TextField
                                      placeholder="Enter the column’s title*"
                                      name="text"
                                      className={styles.textInput}
                                      variant="outlined"
                                      value={col.text || ''}
                                      onChange={(e) => onFieldInput(e, index, 'columns')}
                                    />
                                  </div>
                                  <div className={styles.constructor__field_delete}>
                                    <IconButton
                                      className={columns.length <= 1 && styles.disabled}
                                      onClick={() => onDeleteField(index, 'columns')}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )
                    }
                  </Droppable>
                </DragDropContext>

                <div className={styles.constructor__block_footer}>
                  <MainButton
                    text="+"
                    isMobile
                    type="primary"
                    isDisabled={columns.length >= 15}
                    action={() => onAddField('columns')}
                  />
                  <span>Add a new column</span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.activeTemplate__controls}>
            <div>
              {reportType === 'Location' && (
                <StyledSwitch
                  name="compare"
                  label="Compare reports"
                  checked={compare}
                  handleChange={handleSwitchClick}
                />
              )}
              <StyledSwitch
                name="required"
                label="Required"
                checked={required}
                handleChange={handleSwitchClick}
              />
            </div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={isSaveDisabled()}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div className={clsx(styles.inactiveTemplate__controls, dragIsActive && styles.hidden)}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              <button>
                <FileCopyIcon onClick={() => onCopy(index)} />
              </button>
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div>
              <b>
                {data.text || ''}
                {required && '*'}
              </b>
            </div>
            <div className={styles.inactiveCustomTable}>
              <TableContainer className={styles.inactiveCustomTable__table}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell></StyledTableCell>
                      {data.rows[0].columns.map(({ text }, i) => (
                        <StyledTableCell key={i}>{text || ''}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data.rows.map(({ text, columns }, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{text || ''}</StyledTableCell>
                        {columns.map((_, index) => (
                          <StyledTableCell key={index}>
                            <TextField
                              className={styles.textInput}
                              variant="outlined"
                              disabled={true}
                            />
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
    </section>
  );
}
