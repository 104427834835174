import React, { useEffect, useState } from 'react';
import styles from './List.module.scss';

import { ListItem, CollapsibleListItem } from './Item';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { buildBar } from '../helpers';

export function List({ sidebarOpen, onLinkClick }) {
  const isMobile = useMobileViewport();

  const [listSelection, setListSelection] = useState({});
  const [barItems, setBarItems] = useState([]);

  const config = useUserConfig();
  const { isConfigReceived } = config;

  useEffect(() => {
    if (isConfigReceived) {
      setBarItems(() => buildBar(config, isMobile));
    }
  }, [isConfigReceived, isMobile]);

  useEffect(() => {
    if (!sidebarOpen) {
      setListSelection({});
    }
  }, [sidebarOpen]);

  const handleCollapseClick = (index) => {
    setListSelection({
      ...listSelection,
      [index]: !listSelection[index]
    });
  };

  if (!isConfigReceived) {
    return null;
  }

  const getActivePaths = (screen) => screen.collapseItems.flatMap(({ paths }) => paths);

  return (
    <div className={styles.list}>
      {barItems.map((screen) => (
        <React.Fragment key={screen.id}>
          {screen?.collapseItems?.length ? (
            <CollapsibleListItem
              label={screen.displayName}
              icon={screen.icon}
              onClick={() => handleCollapseClick(screen.id)}
              open={listSelection[screen.id]}
              paths={getActivePaths(screen)}>
              {screen.collapseItems.map((item) => (
                <ListItem
                  key={item.id}
                  paths={item.paths}
                  inCollapse
                  label={item.displayName}
                  to={item.to}
                  onClick={onLinkClick}
                />
              ))}
            </CollapsibleListItem>
          ) : (
            <ListItem
              label={screen.displayName}
              icon={screen.icon}
              paths={screen.paths}
              to={screen.to}
              onClick={onLinkClick}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
