import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import { formatDate } from 'helpers/AppHelpers';

function CommonDetailsForm({ isMobile, data }) {
  return (
    <AccordionWrapper title="Common Details" isMobile={isMobile}>
      <div className={styles.details}>
        {data.map((el, index) => (
          <div className={styles.details__block} key={index}>
            {el.map((row) => (
              <div key={row.id} className={styles.details__block_item}>
                <label>{row.label}:</label>
                <span>{row?.type === 'date' ? formatDate(row.value) : row.value}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </AccordionWrapper>
  );
}

export default CommonDetailsForm;
