import { useSelector } from 'react-redux';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import {
  selectCreateCategoriesData,
  selectCreateLocationsData,
  selectLooseItemFieldsData,
  selectSingleItemData,
  selectSelectedColumnsData,
  selectFilterData,
  selectFilterCriteriaData
} from 'hooks/LooseItems/selectors';

export function useLooseItemSelector() {
  const unsavedFormData = useSelector(selectUnsavedFormData());

  const currentLooseItem = useSelector(selectSingleItemData());

  const locationList = useSelector(selectCreateLocationsData());
  const categoryList = useSelector(selectCreateCategoriesData());
  const looseItemFields = useSelector(selectLooseItemFieldsData());

  const customColumns = useSelector(selectSelectedColumnsData());

  const filter = useSelector(selectFilterData());

  const filterCriteria = useSelector(selectFilterCriteriaData());

  return {
    filter,
    filterCriteria,
    unsavedFormData,
    locationList,
    categoryList,
    looseItemFields,
    customColumns,
    currentLooseItem
  };
}
