import React, { useEffect, useState } from 'react';
import styles from './SidebarContent.module.scss';

import Separator from 'components/Separator/Separator';
import StyledLink from 'components/StyledLink';

import {
  useActiveLocationsMapActions,
  useActiveLocationsMapSelector
} from 'hooks/ActiveLocationsMap';

import { SidebarError } from './Error/Error';
import AccordionWrapper from './Accordion/AccordionWrapper';

import { generateLooseItemSummaryLink } from '../helpers';

export function LocationLooseItems({ location }) {
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState('');
  const [looseItems, setLooseItems] = useState([]);

  const { filter } = useActiveLocationsMapSelector();
  const { getLocationLooseItemsAction } = useActiveLocationsMapActions();

  useEffect(() => {
    getLocationLooseItemsAction({ filters: { ...filter.filters, locationId: location.id } }).then(
      (res) => {
        if (res?.looseItems?.length) {
          setLooseItems(res.looseItems);
        } else {
          setError('Failed to load data. Please try refreshing the page.');
        }
        setResponseReceived(true);
      }
    );
  }, [location]);

  if (!responseReceived) {
    return null;
  }

  if (error) {
    return (
      <div className={styles.content}>
        <SidebarError message={error} />
      </div>
    );
  }

  const getTotalLink = (looseItemId) =>
    generateLooseItemSummaryLink(filter.filters, {
      looseItemId,
      locationId: location.id
    });

  return (
    <div className={styles.content}>
      {looseItems.map(({ looseItem, count: totalCount, slicedLooseItems }, index) => (
        <React.Fragment key={looseItem.id}>
          <AccordionWrapper
            count={totalCount}
            title={looseItem.name}
            link={getTotalLink(looseItem.id)}>
            <div className={styles.counts}>
              {slicedLooseItems.map(({ status, count }) => (
                <div key={status.name} className={styles.counts__row}>
                  <label>{status?.displayName || ''}: </label>
                  {count ? (
                    <StyledLink
                      to={generateLooseItemSummaryLink(filter.filters, {
                        looseItemId: looseItem.id,
                        statusName: status.name,
                        locationId: location.id
                      })}>
                      {count}
                    </StyledLink>
                  ) : (
                    0
                  )}
                </div>
              ))}
            </div>
          </AccordionWrapper>

          {index + 1 !== looseItems.length && <Separator />}
        </React.Fragment>
      ))}
    </div>
  );
}
