import React, { useEffect, useState } from 'react';
import styles from './BillOfLadingFilter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';

import { formatDate } from 'helpers/AppHelpers';

import { useDispatch, useSelector } from 'react-redux';
import { getBOLFilterCriteria } from 'actions/billOfLadingActions';
import { selectBoLFilterData } from '../../selectors';
import { getChips, getValuesAfterChipDeleting } from './helpers';
import moment from 'moment';

export default function BillOfLadingFilter({ isMobile, onApply }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  const [chips, setChips] = useState([]);

  const filter = useSelector(selectBoLFilterData());

  useEffect(() => {
    dispatch(getBOLFilterCriteria()).then((res) => setOptions(res));
  }, []);

  useEffect(() => {
    if (open) {
      setValues(filter.filters);
      isMobile && createChips(filter.filters);
    }
  }, [open]);

  useEffect(() => {
    if (options?.['originLocations']?.length || options?.['destinationLocations']?.length) {
      setValues(filter.filters);
      createChips(filter.filters);
    }
  }, [filter, options]);

  const { originLocations, destinationLocations, picklists, leavelists } = options;

  const handleClearClick = () => {
    const defaultState = {
      ...values,
      picklistIds: [],
      leavelistIds: [],
      originLocationIds: [],
      destinationLocationIds: [],
      createdFrom: null,
      createdTo: null
    };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const handleBackClick = () => {
    isMobile && createChips(filter.filters);
    setOpen(false);
  };
  const handleIconClick = (value) => setOpen(value || !open);

  const handleSelect = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };
  const handleDateChange = (date, name) => {
    const updatedValues = { ...values, [name]: date ? formatDate(date) : null };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const handleClickApply = () => {
    onApply(values);
    createChips(values);
    setOpen(false);
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);
    const newValues = getValuesAfterChipDeleting(values, chip);
    setValues(newValues);
    if (!isMobile) {
      onApply(newValues);
    }
  };

  const createChips = (inputFilter) => {
    const chipsArray = getChips(inputFilter, options);
    setChips(chipsArray);
  };

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      onChipDelete={handleChipDelete}>
      <section className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Destination</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="destinationLocationIds"
                label="siteCode"
                value={values?.destinationLocationIds || []}
                options={destinationLocations || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Origin Location</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="originLocationIds"
                label="siteCode"
                value={values?.originLocationIds || []}
                options={originLocations || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Picklist Name</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="picklistIds"
                label="name"
                value={values?.picklistIds || []}
                options={picklists || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Leavelist Name</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="leavelistIds"
                label="name"
                value={values?.leavelistIds || []}
                options={leavelists || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Created from</label>
            <div className={styles.block__cell_input}>
              <StyledDatePicker
                name="createdFrom"
                value={values?.createdFrom || null}
                onChange={handleDateChange}
                min={moment().add(-20, 'year')}
                max={values?.createdTo || moment().add(10, 'year')}
                placement={isMobile && 'top'}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Created to</label>
            <div className={styles.block__cell_input}>
              <StyledDatePicker
                name="createdTo"
                value={values?.createdTo || null}
                onChange={handleDateChange}
                min={values?.createdFrom || moment().add(-20, 'year')}
                max={moment().add(10, 'year')}
                placement={isMobile && 'top'}
              />
            </div>
          </div>
        </div>
      </section>
    </FilterWrapper>
  );
}
