import api from '../api';
import {
  CLEAR_ASSET_FIELDS_DASHBOARD_DATA,
  SET_ASSET_DASHBOARD_DATA,
  SET_ASSET_DASHBOARD_PREFIXES_DATA,
  SET_ASSET_DASHBOARD_PREFILTER_DATA,
  SET_ASSET_DASHBOARD_FIELDS_DATA
} from '../constants/reduceConstants';

export const getPrefixes = (query) => (dispatch) =>
  api.overviewReport
    .getPrefixesApi(query)
    .then((res) => dispatch({ type: SET_ASSET_DASHBOARD_PREFIXES_DATA, payload: res }))
    .catch((err) => console.log(err));

export const getFields = (query) => (dispatch) =>
  api.overviewReport
    .getFieldsApi(query)
    .then((res) => dispatch({ type: SET_ASSET_DASHBOARD_FIELDS_DATA, payload: res }))
    .catch((err) => console.log(err));

export const getDashboardData = (data) => (dispatch) =>
  api.overviewReport.getDashboardDataApi(data).then(
    (res) => {
      dispatch({ type: SET_ASSET_DASHBOARD_DATA, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      // dispatchSnackbar(dispatch, ERROR_CREATE, errors);
      return errors;
    }
  );

export const getUserAssetFieldDashboardConfig = () => (dispatch) =>
  api.overviewReport
    .getUserAssetFieldDashboardConfigApi()
    .then((res) => {
      dispatch({ type: SET_ASSET_DASHBOARD_PREFILTER_DATA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const setPrefilterData = (payload) => (dispatch) => {
  api.overviewReport
    .setPrefilterDataApi(payload)
    .then((res) => dispatch({ type: SET_ASSET_DASHBOARD_PREFILTER_DATA, payload: res }))
    .catch((err) => console.log(err));
};

export const clearAssetDashboardState = () => (dispatch) => {
  dispatch({ type: CLEAR_ASSET_FIELDS_DASHBOARD_DATA });
};
