import React from 'react';
import styles from './TeamMemberHistory.module.scss';

export default function FullTimeTag() {
  return (
    <div className={styles.tag}>
      <span>FTE</span>
    </div>
  );
}
