import {
  SET_TICKETS_DASHBOARD_TAB,
  SET_TICKETS_DASHBOARD_TAB_CONFIG,
  SET_TICKETS_DASHBOARD_BASE_STATISTICS,
  CLEAR_TICKETS_DASHBOARD_STATE,
  SET_TICKETS_DASHBOARD_TAB_FILTER_CRITERIA,
  SET_TICKETS_DASHBOARD_TURNOVER_STATISTICS,
  CLEAR_TICKETS_DASHBOARD_TAB_STATE,
  SET_TICKETS_DASHBOARD_FLOW_STATISTICS
} from 'constants/reduceConstants';

const initialState = {
  tab: '',
  tabConfig: {},
  filterCriteria: {},
  baseStatistics: [],
  turnoverStatistics: [],
  flowStatistics: []
};

const ticketsDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TICKETS_DASHBOARD_TAB:
      return { ...state, tab: payload };
    case SET_TICKETS_DASHBOARD_TAB_CONFIG:
      return { ...state, tabConfig: payload };
    case SET_TICKETS_DASHBOARD_BASE_STATISTICS:
      return { ...state, baseStatistics: payload };
    case SET_TICKETS_DASHBOARD_TAB_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_TICKETS_DASHBOARD_TURNOVER_STATISTICS:
      return { ...state, turnoverStatistics: payload };
    case SET_TICKETS_DASHBOARD_FLOW_STATISTICS:
      return { ...state, flowStatistics: payload };
    case CLEAR_TICKETS_DASHBOARD_TAB_STATE:
      return { ...state, [payload.stateName]: [] };
    case CLEAR_TICKETS_DASHBOARD_STATE:
      return initialState;
    default:
      return state;
  }
};

export default ticketsDashboardReducer;
