import { useLocation } from 'react-router-dom';

export const useSearchParams = (keys) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  return keys.reduce((acc, key) => {
    const values = searchParams.getAll(key);
    acc[key] = values.length > 1 ? values : values[0];
    return acc;
  }, {});
};
