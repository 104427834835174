import React, { useEffect } from 'react';

import ProjectScheduleWrapper from './components/ProjectScheduleWrapper';
import Header from './components/Header';
import TableControls from './components/TableControls/TableControls';
import ProjectScheduleTable from './components/Table/ProjectScheduleTable';
import SchedulePagination from './components/Pagination/SchedulePagination';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { BREAKDOWN_ROWS_COUNT, getEmptyFilters } from './helpers';

import { TEAM_MEMBER_TRACKER_TABLE_PARAMETER } from 'constants/configTableConstants';
import { ROOT_PATH } from 'constants/routeConstants';

export default function ProjectSchedule() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const {
    getTrackerDataAction,
    getTrackerConfigDataAction,
    setFilterAction,
    setResponseReceivedAction,
    clearStateAction
  } = useTrackerActions();
  const { selectedTab, filter, isMasterSelected } = useTrackerSelector();

  const { getTableLimit, isConfigReceived } = useUserConfig();

  useEffect(() => {
    if (!isConfigReceived) return;
    if (!selectedTab) return;

    setResponseReceivedAction(false);

    getTrackerConfigDataAction({ isMaster: isMasterSelected }).then((configValues) => {
      const limit = isMasterSelected
        ? getTableLimit(TEAM_MEMBER_TRACKER_TABLE_PARAMETER)
        : BREAKDOWN_ROWS_COUNT;

      const filterValues = {
        ...filter,
        filters: { ...filter.filters, ...configValues, columnPage: 1 },
        pagination: { ...filter.pagination, limit }
      };

      const isFilterEmpty = getEmptyFilters(filterValues);

      if (!isMasterSelected && isFilterEmpty) {
        setFilterAction(filterValues);
        setResponseReceivedAction(true);
      } else {
        getTrackerDataAction(filterValues, { isMasterSelected });
      }
    });
  }, [isConfigReceived, selectedTab]);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <ProjectScheduleWrapper>
      <Header />
      <TableControls />
      <ProjectScheduleTable />
      <SchedulePagination />
    </ProjectScheduleWrapper>
  );
}
