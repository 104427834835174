import { createSelector } from 'reselect';

const addBomToPicklistData = (state) => state.addBomToPicklistData;

export const selectActivePicklistData = () =>
  createSelector([addBomToPicklistData], (state) => state.activePicklist);

export const selectConfigurationsData = () =>
  createSelector([addBomToPicklistData], (state) => state.configurations);

export const selectLooseItemsData = () =>
  createSelector([addBomToPicklistData], (state) => state.looseItems);
