import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './ReportTemplateList.module.scss';

import {
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  Pagination,
  TableWrapper,
  TableHeadComponent,
  NoDataTableRow
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import StyledLink from 'components/StyledLink';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { StyledButton } from 'components/Buttons';

import { IconButton, Table, TableBody } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useReportTemplatesActions, useReportTemplatesSelector } from 'hooks/ReportTemplates';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { dateTimeFormat, formatDate } from 'helpers/AppHelpers';

import { REPORT_TEMPLATE_EDITOR, ROOT_PATH } from 'constants/routeConstants';
import { REPORT_TEMPLATE_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  tableHeadConfig
} from './helpers';
import FilterModal from './Filter';
import { DELETE_REPORT_TEMPLATE } from 'constants/dialogPopupsData';

export function ReportTemplateList() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const [chips, setChips] = useState([]);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, reportTypes } = useReportTemplatesSelector();
  const {
    getTemplatesAction,
    setFilterAction,
    getReportTypesAction,
    removeTemplateAction,
    copyTemplateAction,
    clearStateAction
  } = useReportTemplatesActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const getTemplates = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getTemplatesAction(parseQuery(query)).then((tableData) => {
        setTemplates(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getTemplatesAction]
  );

  const setTemplates = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getReportTypesAction().then((types) => {
      const resultFilter = generateInitialQuery({ getTableLimit, isAllLimitEnabled, filter });
      getTemplates(resultFilter);
      setChips(generateChips(types, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    if (isMobile) {
      history.push(ROOT_PATH);
    }
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getTemplates(getChangedBySearchFilter(filter, value)),
    [filter, getTemplates]
  );
  const sortTable = useCallback(
    (rowName) => getTemplates(getChangedBySortFilter(filter, rowName)),
    [filter, getTemplates]
  );
  const applyFilter = useCallback(
    (values) => {
      getTemplates(getChangedByApplyFilter(filter, values));
      setChips(generateChips(reportTypes, values));
    },
    [filter, reportTypes, getTemplates]
  );
  const changeLimit = useCallback(
    () =>
      getTemplates(getChangedByLimitFilter(filter, getTableLimit(REPORT_TEMPLATE_TABLE_PARAMETER))),
    [filter, getTableLimit, getTemplates]
  );
  const changePage = useCallback(
    (page, param) => getTemplates(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getTemplates]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const closeModal = useCallback(() => setModalData({}), []);
  const agreeModal = useCallback(() => {
    removeTemplateAction({ id: modalData.templateId }).then(() => {
      setModalData({});
      getTemplates(filter);
    });
  }, [filter, modalData, getTemplates, removeTemplateAction]);

  const addTemplate = useCallback(() => history.push(REPORT_TEMPLATE_EDITOR), [history]);

  const openFilterModal = useCallback(() => setOpen(true), []);

  const deleteTemplate = useCallback((templateId) => {
    setModalData({
      ...DELETE_REPORT_TEMPLATE,
      isOpened: true,
      templateId
    });
  }, []);

  const copyTemplate = useCallback((id) => copyTemplateAction(id), []);

  const MainButton = () => (
    <StyledButton
      label="Add new report template"
      onClick={addTemplate}
      classes={styles.main_button}
    />
  );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Report Template List">
          {!isMobile && <MainButton />}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={open || !!chips?.length} />
            <FilterChips chips={chips} onDelete={deleteChip} />
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.template_row}>
                  <StyledTableCell className={styles.nameCell}>
                    <StyledLink bold to={`${REPORT_TEMPLATE_EDITOR}/${row.id}`}>
                      {row.name || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.typeCell}>
                    {row.reportType.displayName}
                  </StyledTableCell>
                  <StyledTableCell className={styles.lastUpdateCell}>
                    {row.updatedAtUtc ? formatDate(row.updatedAtUtc, dateTimeFormat) : ''}
                    {row.lastUpdate}
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    {row.published ? 'Published' : 'Unpublished'}
                  </StyledTableCell>
                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.controls}>
                      <IconButton
                        onClick={() => copyTemplate(row.id)}
                        className={styles.icon_button}>
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteTemplate(row.id)}
                        className={styles.icon_button}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!filters?.searchQuery || !!chips?.length} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={REPORT_TEMPLATE_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      {open && <FilterModal open={open} setOpen={setOpen} onApply={applyFilter} />}
    </SummaryWrapper>
  );
}
