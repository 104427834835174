export const tableHeadConfig = [
  { id: 1, label: 'BOL ID', isSortable: true, parameter: 'id' },
  { id: 2, label: 'Freight Bill', isSortable: true, parameter: 'freightBill' },
  { id: 3, label: 'Picklist/Leavelist Name', isSortable: true, parameter: 'picklist.name' },
  { id: 4, label: 'Origin Location', isSortable: true, parameter: 'originLocation' },
  { id: 5, label: 'Destination', isSortable: true, parameter: 'destinationLocation' },
  { id: 6, label: 'Job Number', isSortable: true, parameter: 'locationJobNumber' },
  { id: 7, label: 'Created by', isSortable: true, parameter: 'createdByUser.email' },
  { id: 8, label: 'Created date', isSortable: true, parameter: 'createdAtUtc' }
];
