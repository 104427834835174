import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const StyledRadio = withStyles({
  root: {
    color: '#507A8E',
    '&$checked': {
      color: '#507A8E'
    }
  },
  checked: {}
})(Radio);

export default StyledRadio;
