import React, { useRef, useState } from 'react';
import styles from './Content.module.scss';

import { StyledTableCell } from 'components/StyledComponents';
import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PopperWrapper from './PopperWrapper';

export default function UnitsHeadCell() {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const hide = () => setOpen(false);

  const iconClick = () => setOpen(!open);

  return (
    <StyledTableCell>
      <PopperWrapper open={open} setOpen={setOpen} iconRef={iconRef}>
        <div className={styles.content}>
          <div className={styles.content__row}>
            <b>Units Available</b> is a <b>sum</b> of <b>owned assets</b> that have following
            characteristic:
          </div>
          <div className={styles.content__row}>
            Rental status: <b>Available</b>
          </div>
          <div className={styles.content__row}>
            Status: <b>Operational / Non-Critical</b>
          </div>
          <div className={styles.content__row}>
            Site type: <b>Storage Yard</b>
          </div>
        </div>
      </PopperWrapper>

      <div className={styles.wrapper} onBlur={hide} tabIndex="0">
        Units Available
        <IconButton ref={iconRef} onClick={iconClick}>
          <InfoOutlinedIcon />
        </IconButton>
      </div>
    </StyledTableCell>
  );
}
