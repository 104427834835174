import React from 'react';

import DetailsHeader from 'components/DetailsComponents/Header';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

import { useAddBomSelector } from 'hooks/AddBomToPicklist';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { PICKLIST_DETAILED_PATH } from 'constants/routeConstants';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function Header({ onBack, setModalData, isDirty }) {
  const isMobile = useMobileViewport();

  const { activePicklist } = useAddBomSelector();
  const { id, name } = activePicklist;

  const crumbs = [
    {
      path: `${PICKLIST_DETAILED_PATH}/${id}`,
      name: `Picklist ${name}`
    },
    { name: 'Add BOM to Picklist' }
  ];

  const CrumbsComponent = !isMobile && (
    <BreadcrumbsNav itemsArray={crumbs} setDialogModalData={setModalData} formIsChanged={isDirty} />
  );

  return (
    <DetailsHeader BreadCrumbsComponent={CrumbsComponent} title="Add BOM to Picklist">
      {isMobile && (
        <HeaderButton onClick={onBack}>
          <ChevronLeftIcon />
        </HeaderButton>
      )}
    </DetailsHeader>
  );
}
