import api from '../api';

export const getCategoryListApi = () =>
  api.get(`/AssetCategories`).then((res) => res.data);

export const getSingleCategoryApi = (id) =>
  api.get(`/AssetCategory?id=${id}`).then((res) => res.data);

export const createCategoryApi = (params) =>
  api.post(`/AssetCategory`, params).then((res) => res.data);

export const updateCategoryApi = (params) =>
  api.put(`/AssetCategory`, params).then((res) => res.data);

export const deleteCategoryApi = (id) =>
  api.delete(`/AssetCategory?id=${id}`).then((res) => res.data);
