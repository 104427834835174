import React, { useEffect, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectLocationsData, selectProjects } from '../selectors';
import { setUnsavedFormData } from 'actions/commonActions';
import {
  checkLeaveList,
  clearLeaveListState,
  createLeaveList,
  getLocations,
  getProjects
} from 'actions/leaveListActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { getBackendErrors } from 'helpers/AppHelpers';
import {
  CREATE_LEAVELIST,
  EXIT_WITHOUT_SAVING,
  NEW_LEAVELIST_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import styles from './LeaveListEdit.module.scss';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import { LEAVELIST_SUMMARY_PATH } from 'constants/routeConstants';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import { FormProvider, useForm } from 'react-hook-form';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';

function LeaveListEdit({
  locationList,
  getLocationListAction,
  projectList,
  getProjectListAction,
  createLeaveListAction,
  unsavedFormData,
  setUnsavedFormDataAction,
  checkLeaveListAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const containerRef = useRef(null);
  const routeUrl = useRef(null);

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const methods = useForm({
    defaultValues: {
      name: '',
      pocName: '',
      pocNumber: '',
      location: {},
      sublocation: null,
      project: {}
    },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, clearErrors, setError, watch } = methods;
  const { isDirty, errors } = formState;
  const changeFormValue = (name, value) => setValue(name, value, { shouldDirty: true });

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    getProjectListAction({ includeUserCheck: true });
    getLocationListAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const onInput = (key, value) => {
    errors[key]?.message && clearErrors(key);
    changeFormValue(key, value);
  };

  const selectLocation = (name, value) => {
    changeFormValue(name, value);
    changeFormValue('pocName', value?.pocName || '');
    changeFormValue('pocNumber', value?.pocNumber || '');
    errors?.location?.message && clearErrors(name);
  };

  const selectSearchOption = (name, value) => changeFormValue(name, value);

  const selectProject = (name, value) => {
    changeFormValue(name, value);
    changeFormValue('location', {});
    changeFormValue('sublocation', null);
    errors?.project?.message && clearErrors(name);
    errors?.location?.message && clearErrors('location');
  };

  const errorProperties = [
    { name: 'name', inputType: 'text', errorMessage: 'Name field is required' },
    { name: 'project', inputType: 'object', errorMessage: 'Project Number field is required' },
    { name: 'location', inputType: 'object', errorMessage: 'Location field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues()[name], inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;

  const handleErrors = (err) => {
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    containerRef.current.scrollIntoView();
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case NEW_LEAVELIST_TYPE: {
        const data = getValues();
        if (data?.sublocation?.name === '-') {
          data.sublocation = null;
        }
        closeModal();
        createLeaveListAction(data).then((err) => err?.errors && handleErrors(err));
        break;
      }
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl.current);
        break;
      default:
        break;
    }
  };

  const getBack = () => history.push(LEAVELIST_SUMMARY_PATH);

  const closeModal = () => setDialogModalData({ isOpened: false });

  const onCancel = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const onCreate = () => {
    if (!validateForm()) {
      return;
    }
    checkLeaveListAction({
      project: { ...getValues().project },
      location: { ...getValues().location }
    }).then((res) => {
      if (res) {
        setDialogModalData({ ...CREATE_LEAVELIST, title: `${res.title}`, isOpened: true });
      }
    });
  };

  const getSublocations = () => {
    if (!getValues()?.location?.id) return [];

    const sublocations =
      locationList?.find(({ id }) => id === getValues().location.id)?.sublocations || [];
    const areSublocationsExist = !!sublocations?.length;

    return areSublocationsExist ? sublocations : [];
  };
  const filterLocationsByProjectId = (options) => {
    if (!getValues()?.project?.id) return [];
    return options.filter(({ projectId }) => projectId === getValues().project.id);
  };
  const enrichLocationOptions = () => {
    if (
      locationList?.length &&
      getValues()?.id &&
      !locationList.map(({ id }) => id).includes(getValues().id)
    ) {
      return locationList.concat({ ...getValues()?.location }).sort((a, b) => a.id > b.id);
    } else return locationList;
  };

  const setSelectedRoute = (path) => (routeUrl.current = path);

  const projectWatcher = watch('project');
  const locationWatcher = watch('location');

  return (
    <section className={styles.pageContainer} ref={containerRef}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      <div>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={[{ name: 'Create Leavelist' }]}
            setDialogModalData={setDialogModalData}
            formIsChanged={isDirty}
            setRouteUrl={setSelectedRoute}
          />
        )}
        <div className={styles.headerBlock}>
          <h1>Create Leavelist</h1>
          {isMobile && <BackButton onCancel={onCancel} />}
        </div>
        <FormProvider {...methods}>
          <section className={styles.formWrapper}>
            <section className={styles.formRow}>
              <label>Name*</label>
              <FormInputText name="name" onChange={onInput} max={40} />
            </section>
            <section className={styles.formRow}>
              <label>Project Number*</label>
              <FormSearchInput
                name="project"
                options={projectList || []}
                onSelect={selectProject}
              />
            </section>
            <section className={styles.formRow}>
              <label>Location*</label>
              <FormSearchInput
                name="location"
                options={filterLocationsByProjectId(enrichLocationOptions()) || []}
                onSelect={selectLocation}
                isDisabled={!projectWatcher?.id}
              />
            </section>
            <section className={styles.formRow}>
              <label>Sublocation</label>
              <FormSearchInput
                clearable
                name="sublocation"
                options={getSublocations() || []}
                onSelect={selectSearchOption}
                isDisabled={!locationWatcher?.id}
              />
            </section>
            <section className={styles.formRow}>
              <label>POC Name</label>
              <FormInputText name="pocName" onChange={onInput} max={40} />
            </section>
            <section className={styles.formRow}>
              <label>POC Number</label>
              <FormInputText name="pocNumber" onChange={onInput} max={40} />
            </section>
          </section>
        </FormProvider>
      </div>
      <div className={styles.footerControls}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton text="create" type="primary" action={onCreate} isDisabled={isFormInvalid()} />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  locationList: selectLocationsData(),
  projectList: selectProjects(),
  unsavedFormData: selectUnsavedFormData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getLocationListAction: getLocations,
  getProjectListAction: getProjects,
  createLeaveListAction: createLeaveList,
  checkLeaveListAction: checkLeaveList,
  clearStateAction: clearLeaveListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LeaveListEdit);
