import React from 'react';
import styles from './Details.module.scss';
import Subtitle from 'components/DetailsComponents/Subtitle';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';

export default function StuffInfo() {
  const { keyPersonnel, vendors } = useSelector(selectSingleProjectData());

  return (
    <section className={styles.stuff}>
      <div className={styles.stuff__block}>
        <Subtitle>Personnel</Subtitle>
        {keyPersonnel && (
          <span onClick={() => window.open(keyPersonnel)}>Link to Personnel sheet</span>
        )}
      </div>
      <div className={styles.stuff__block}>
        <Subtitle>Vendors</Subtitle>
        {keyPersonnel && <span onClick={() => window.open(vendors)}>Link to Vendors sheet</span>}
      </div>
    </section>
  );
}
