import React, { useEffect, useRef } from 'react';
import styles from './Content.module.scss';
import csvIcon from 'assets/images/csvIcon.svg';
import { MainButton, StickyTableCell, StyledTableCell } from 'components/StyledComponents';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectDashboardFieldsData } from '../selectors';
import CategoryRowsBlock from './CategoryRowsBlock';
import TotalRowsBlock from './TotalRowsBlock';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import { useHistory } from 'react-router-dom';
import { ASSETS_LIST_PATH } from 'constants/routeConstants';
import { refFileDownload } from 'helpers/AppHelpers';
import { useUserConfig } from 'hooks/useUserConfig';
import UnitsHeadCell from './UnitsHeadCell';
import { setTopPosition } from 'actions/commonActions';

function Content({
  isMobile,
  precondition,
  setPrecondition,
  dashboardData,
  setAssetsPrefilterAction,
  setTopPositionAction,
  prefilter
}) {
  const { userId } = useUserConfig();
  const history = useHistory();
  const tableRef = useRef(null);

  useEffect(() => {
    if (!precondition) {
      const elemRectData = tableRef.current?.getBoundingClientRect();
      setTopPositionAction(elemRectData?.y + (isMobile ? -100 : 0));
    }
  }, [precondition]);

  const changeParametersClickHandle = () => {
    setPrecondition(true);
  };

  const getTableHeadData = () => {
    return dashboardData.prefixSlicedByAssets[0].assetPrefixes[0].assetFields.map(
      ({ displayName }) => displayName
    );
  };

  const getTableBodyData = () => {
    return dashboardData.prefixSlicedByAssets;
  };

  const getTotalAvailable = () => {
    return dashboardData.totalAvailable;
  };

  const getTotalRent = () => {
    return dashboardData.totalRent;
  };

  const headerPrefixClickHandle = (prefixId) => {
    setAssetsPrefilterAction({
      prefixIds: [prefixId],
      rentalIds: [1],
      siteTypeIds: [1],
      severities: ['Operational', 'NonCritical']
    });
    history.push(ASSETS_LIST_PATH);
  };

  const downloadClickHandle = () => {
    refFileDownload('AssetFieldDashboard/Serialized', { ...prefilter, userId });
  };

  return (
    <section className={styles.pageContainer}>
      <section className={styles.pageHeader}>
        <h1>Overview Report</h1>
        <div className={styles.pageHeader__controls}>
          {!isMobile && (
            <button className={styles.downloadButton} onClick={() => downloadClickHandle()}>
              <img src={csvIcon} alt="" />
              Download CSV file
            </button>
          )}
          <MainButton
            text="Change parameters"
            action={changeParametersClickHandle}
            type="primary"
          />
        </div>
      </section>
      <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StickyTableCell>Prefix</StickyTableCell>
              <UnitsHeadCell />
              {getTableHeadData().map((name, index) => (
                <StyledTableCell key={index}>{name}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getTableBodyData().map((el, index) => (
              <CategoryRowsBlock key={index} data={el} onPrefixClick={headerPrefixClickHandle} />
            ))}
            <TotalRowsBlock available={getTotalAvailable()} rent={getTotalRent()} />
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}
const mapStateToProps = createStructuredSelector({
  dashboardData: selectDashboardFieldsData()
});

const mapDispatchToProps = {
  setAssetsPrefilterAction: setAssetsPrefilter,
  setTopPositionAction: setTopPosition
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Content);
