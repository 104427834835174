import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import InputSearchWithCreation from 'components/InputSearchWithCreation/InputSearchWithCreation';

import { getRandomId } from 'helpers/AppHelpers';

export default function FormSearchWithCreation({ name, menuItems, options }) {
  const { control, clearErrors, setValue, getValues } = useFormContext();

  const {
    getLabel,
    customLabel,
    max,
    tipLabel,
    disableCreation,
    disableErrorMessage,
    extraAction,
    OptionComponent
  } = options;

  const selectOption = (name, value, selectionType, isInvalid) => {
    isInvalid && clearErrors(name);

    if (selectionType === 'remove-option') {
      const data = [...getValues(name)].filter(({ name }) => name !== value.name);
      setValue(name, data, { shouldDirty: true });
    } else {
      const data = [...getValues(name), value];
      setValue(name, data, { shouldDirty: true });
    }

    extraAction && extraAction();
  };

  const addItem = (value, options, creationType, name, isInvalid) => {
    isInvalid && clearErrors(name);

    const getNewOption = () => ({
      ...value,
      ...options,
      name: value?.name || '',
      keyId: getRandomId()
    });

    const option = creationType === 'existed' ? value : getNewOption();
    const data = [...getValues(name), option];
    setValue(name, data, { shouldDirty: true });

    extraAction && extraAction();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, name }, fieldState: { error } }) => (
        <InputSearchWithCreation
          name={name}
          options={menuItems || []}
          value={value}
          onSelect={(name, value, type) => selectOption(name, value, type, !!error)}
          getOptionLabel={getLabel}
          customLabel={customLabel}
          max={max}
          isInvalid={!!error}
          invalidError={disableErrorMessage === undefined ? error?.message : null}
          tipLabel={tipLabel}
          disableCreation={disableCreation !== undefined ? disableCreation : false}
          onAdd={(value, options, creationType) =>
            addItem(value, options, creationType, name, !!error)
          }
          OptionComponent={OptionComponent}
        />
      )}
    />
  );
}
