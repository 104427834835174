import React, { useEffect, useRef, useState } from 'react';
import styles from './TicketRootCausesSummary.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton } from 'components/Buttons';

import { IconButton, Table, TableBody } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useRootCausesActions, useRootCausesSelector } from 'hooks/RootCauses';
import { useHistory } from 'react-router-dom';

import { joinWithComma, pluck } from 'helpers/AppHelpers';
import {
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';

import { TICKET_ROOT_CAUSES_TABLE_PARAMETER } from 'constants/configTableConstants';

import { tableConfig } from './tableConfig';
import { TICKET_ROOT_CAUSE_EDIT_PATH } from 'constants/routeConstants';

const defaultFilter = {
  filters: {
    searchQuery: ''
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

export default function TicketRootCausesSummary() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const history = useHistory();

  const [tableState, setTableState] = useState([]);

  const { filter } = useRootCausesSelector();
  const { getRootCausesAction, setFilterAction, clearStateAction } = useRootCausesActions();

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();

  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filters, pagination, responseReceived, sortRules } = filter;

  const generateInitialQuery = () => ({
    filters: { ...defaultFilter.filters },
    pagination: {
      page:
        getTableLimit(TICKET_ROOT_CAUSES_TABLE_PARAMETER) === 100 &&
        isAllLimitEnabled(TICKET_ROOT_CAUSES_TABLE_PARAMETER)
          ? defaultFilter.pagination.page
          : pagination?.page || defaultFilter.pagination.page,
      limit: getTableLimit(TICKET_ROOT_CAUSES_TABLE_PARAMETER)
    },
    sortRules: sortRules || defaultFilter.sortRules,
    responseReceived: false
  });

  const getRootCauses = (query, isAutoload) => {
    setFilterAction(query);

    getRootCausesAction(query).then((tableData) => {
      setRootCauses(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setRootCauses = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getRootCauses(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = (value) => getRootCauses(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getRootCauses(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getRootCauses(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const changeLimit = () =>
    getRootCauses(
      getChangedByLimitFilter(filter, getTableLimit(TICKET_ROOT_CAUSES_TABLE_PARAMETER))
    );

  const createRootCause = () => history.push(TICKET_ROOT_CAUSE_EDIT_PATH);
  const editRootCause = (id) => history.push(`${TICKET_ROOT_CAUSE_EDIT_PATH}/${id}`);

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title={isMobile ? 'Ticket Root Causes' : 'Asset Ticket Root Causes'}>
          {!isMobile && (
            <StyledButton
              label="Add new root cause"
              onClick={createRootCause}
              classes={styles.main_button}
            />
          )}
        </SummaryPageHeader>
        <SummaryTableControls onlyChild>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && (
        <StyledButton
          label="Add new root cause"
          onClick={createRootCause}
          classes={styles.main_button}
        />
      )}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.ticket_root_cause_table_row}>
                  <StyledTableCell className={styles.nameCell}>{row?.name || ''}</StyledTableCell>
                  <StyledTableCell className={styles.descriptionCell}>
                    {row?.description || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.activeCell}>
                    {row?.active ? 'Active' : 'Not Active'}
                  </StyledTableCell>
                  <StyledTableCell className={styles.prefixesCell}>
                    {row?.isSelectedAllAssetPrefixes
                      ? 'All Selected'
                      : joinWithComma(pluck(row.assetPrefixes, 'prefix'))}
                  </StyledTableCell>
                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.controls}>
                      <IconButton
                        onClick={() => editRootCause(row.id)}
                        className={styles.icon_button}>
                        <CreateIcon />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={TICKET_ROOT_CAUSES_TABLE_PARAMETER}
        tableRef={tableRef}
      />
    </SummaryWrapper>
  );
}
