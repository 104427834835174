import React from 'react';
import styles from './RequestOrderForm.module.scss';

import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

import { enrichNameToId, getAddress, getProjectName, yesterdayDate } from 'helpers/AppHelpers';

import { useFormContext } from 'react-hook-form';
import { useRequestOrderSelector } from 'hooks/RequestOrder';

export default function RequestOrderGeneralDetails() {
  const { formState, setValue, clearErrors, getValues, watch } = useFormContext();
  const { errors } = formState;

  const { creationFormData } = useRequestOrderSelector();
  const { urgencyLevels, endUsers, locations } = creationFormData;

  const handleUrgencySelect = (selectedItem) => {
    setValue(selectedItem.name, selectedItem.value);
    !!getValues('deadlineAtUtc') && setValue('deadlineAtUtc', null);
    errors?.urgencyLevel?.message && clearErrors('urgencyLevel');
  };

  const handleUserSelect = (name, value) => {
    setValue(name, value);
    setValue('endEmail', value?.email || '');
    errors?.endEmail?.message && clearErrors('endEmail');
  };

  const handleLocationSelect = (name, value) => {
    setValue(name, value);
    setValue('project', getProjectName(value?.project || {}));
    setValue('deliveryAddress', getAddress(value?.address1 || '', value?.address2 || ''));
    errors?.location?.message && clearErrors('location');
  };

  const urgencyWatcher = watch('urgencyLevel');

  return (
    <div className={styles.form}>
      <div className={styles.form__block}>
        <div className={styles.form__block_row}>
          <label>Urgency level*</label>
          <FormSelectInput
            name="urgencyLevel"
            options={enrichNameToId(urgencyLevels)}
            onSelect={handleUrgencySelect}
          />
        </div>
        {urgencyWatcher?.displayName === 'Urgent' && (
          <div className={styles.form__block_row}>
            <label>Deadline</label>
            <FormDatePicker name="deadlineAtUtc" min={yesterdayDate} />
          </div>
        )}
        <div className={styles.form__block_row}>
          <label>End User</label>
          <FormSearchInput name="endUser" options={endUsers} onSelect={handleUserSelect} />
        </div>
        <div className={styles.form__block_row}>
          <label>End User Email</label>
          <FormInputText name="endEmail" options={{ max: 150 }} />
        </div>
        <div className={styles.form__block_row}>
          <label>Phone Number</label>
          <FormInputText name="endUserNumber" options={{ max: 50 }} />
        </div>
        <div className={styles.form__block_row}>
          <label>Delivery Location*</label>
          <FormSearchInput name="location" options={locations} onSelect={handleLocationSelect} />
        </div>
        <div className={styles.form__block_row}>
          <label>Project Number</label>
          <FormInputText name="project" options={{ max: 200 }} />
        </div>
        <div className={styles.form__block_row}>
          <label>Delivery Address</label>
          <FormInputText name="deliveryAddress" options={{ max: 200 }} />
        </div>
      </div>
      <div className={styles.form__block}>
        <div className={styles.form__block_row}>
          <label>Notes</label>
          <div className={styles.textarea}>
            <FormTextArea name="notes" options={{ max: 500 }} />
          </div>
        </div>
      </div>
    </div>
  );
}
