import React, { useEffect } from 'react';
import styles from './TicketTypeForm.module.scss';

import { FormLabel } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { useTicketTypesSelector } from 'hooks/TicketTypes';
import { useFormContext } from 'react-hook-form';

import { enrichNameToId } from 'helpers/AppHelpers';

const PrefixFieldOption = (option) => {
  const isSelectAll = option.prefix.toLowerCase() === 'select all';

  return (
    <div className={styles.option}>
      <div className={styles.option__prefix}>{option?.prefix || ''}</div>
      {!isSelectAll && (
        <div className={styles.option__description}>{option?.description || ''}</div>
      )}
    </div>
  );
};

export default function TicketTypeForm() {
  const { getValues, setValue, watch } = useFormContext();

  const { creationForm } = useTicketTypesSelector();
  const { severities, assetPrefixes } = creationForm;

  const handleMultiSelect = (name, value) => setValue(name, value);

  const prefixesWatcher = watch('assetPrefixes');

  useEffect(() => {
    if (!assetPrefixes?.length) return;
    setValue(
      'isSelectedAllAssetPrefixes',
      getValues('assetPrefixes')?.length === assetPrefixes?.length
    );
  }, [prefixesWatcher]);

  return (
    <div className={styles.form}>
      <div className={styles.form__row}>
        <FormLabel required>Name</FormLabel>
        <FormInputText name="name" options={{ max: 40 }} />
      </div>
      <div className={styles.form__row}>
        <FormLabel>Severity</FormLabel>
        <FormMultiSelect
          name="severities"
          options={enrichNameToId(severities) || []}
          onSelect={handleMultiSelect}
        />
      </div>
      <div className={styles.form__description}>
        <FormLabel>Description</FormLabel>
        <FormTextArea name="description" options={{ max: 255 }} />
      </div>
      <div className={styles.form__checkbox}>
        <FormLabel>Active</FormLabel>
        <FormCheckbox name="active" />
      </div>
      <div className={styles.form__checkbox}>
        <FormLabel>Reopening</FormLabel>
        <FormCheckbox name="reopening" />
      </div>
      <div className={styles.form__prefixes}>
        <FormLabel>Prefixes</FormLabel>
        <FormAutoMultiSelect
          name="assetPrefixes"
          menuItems={assetPrefixes || []}
          options={{
            label: 'prefix',
            disableByObjectTracker: false,
            disableLabel: true,
            selectAll: true,
            hideTags: true,
            sortAfterSelecting: true
          }}
          classes={styles.form__prefixes_field}
          renderOptionLabel={PrefixFieldOption}
        />
      </div>
      <FormChipsContainer fieldName="assetPrefixes" label="prefix" deleteParameter="id" />
    </div>
  );
}
