import React from 'react';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ShowPopupButton } from 'components/Buttons';

import CountingModal from './CountingModal';
import CountingTable from './CountingTable';

export default function AssetsCounting() {
  const isMobile = useMobileViewport();

  return !isMobile ? (
    <CountingTable />
  ) : (
    <ShowPopupButton
      label="Items Calculation"
      PopupComponent={(open, setOpen) => <CountingModal open={open} setOpen={setOpen} />}
    />
  );
}
