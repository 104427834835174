import React from 'react';
import styles from './SearchResultsWrapper.module.scss';

import Header from './Header';
import SearchControl from './Control';

export default function SearchResultsWrapper({ children }) {
  return (
    <div className={styles.wrapper}>
      <Header />
      <SearchControl />
      {children}
    </div>
  );
}
