import React, { useEffect, useRef, useState } from 'react';
import styles from './AssetsTable.module.scss';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { ASSET_DETAILED_PATH } from 'constants/routeConstants';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useLocationSelector } from '../../../../../hooks/useLocationSelector';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { LOCATION_DETAILS_ASSETS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { useLocationActions } from '../../../../../hooks/useLocationActions';
import { useParams } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';
import {
  getChangedByApplyFilter,
  getChangedByPageFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { tableHeadConfig } from './tableConfig';
import { dateTimeFormat, enrichNameToId, formatDate } from 'helpers/AppHelpers';
import StatusIndicator from 'components/StatusIndicator';
import TooltipComponent from '../components/TooltipComponent/TooltipComponent';
import SortableBlock from 'components/SortableBlock/SortableBlock';
import SelectInput from 'components/SelectInput/SelectInput';
import CheckIcon from '@material-ui/icons/Check';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import { Filter } from './Filter';

export default function AssetsTable() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const { id } = useParams();
  const { getTableLimit } = useUserConfig();

  const [rental, setRental] = useState({});
  const [selected, setSelected] = useState([]);

  const { assetsList, rentalStatuses, assetsListFilter } = useLocationSelector();
  const { getAssets, updateRentalStatusesAction } = useLocationActions();

  const emptyResponse = () =>
    !assetsList?.items?.length && assetsListFilter?.pagination?.page === assetsList?.pageNumber;

  const getQuery = (tableParam) => ({
    filters: { ...assetsListFilter.filters, locationIds: [+id], isLocation: true },
    pagination: { ...assetsListFilter.pagination, limit: getTableLimit(tableParam) },
    isDataReceived: false,
    sortRules: []
  });

  useEffect(() => {
    getAssets(getQuery(LOCATION_DETAILS_ASSETS_TABLE_PARAMETER));
  }, []);

  const applyFilter = (values) => getAssets(getChangedByApplyFilter(assetsListFilter, values));
  const sortColumn = (name) => getAssets(getChangedBySortFilter(assetsListFilter, name));
  const getUpdatedByLimitFilter = () => ({
    ...assetsListFilter,
    pagination: {
      ...assetsListFilter.pagination,
      page: 1,
      limit: getTableLimit(LOCATION_DETAILS_ASSETS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });
  const getUpdatedByRentalFilter = () => ({
    ...assetsListFilter,
    pagination: {
      ...assetsListFilter.pagination,
      page: assetsListFilter.pagination.limit === 100 ? 1 : assetsListFilter.pagination.page
    },
    isDataReceived: false
  });

  const changePage = (page, param) => {
    getAssets(getChangedByPageFilter(assetsListFilter, page), param);
  };

  const handleApplyClick = () => {
    updateRentalStatusesAction({ assets: selected, rental: rental }).then((res) => {
      if (res.status === 200) {
        getAssets(getUpdatedByRentalFilter());
        setRental({});
        setSelected([]);
      }
    });
  };

  const handleRentalSelect = (selectedItem) => setRental({ rentalStatus: selectedItem.value });

  const checkIsAssetAvailable = ({ isInPicklist }) => isInPicklist?.name === 'No';
  const areCheckboxesAvailable = () => !!assetsList?.items?.filter(checkIsAssetAvailable).length;
  const isAssetAvailable = (isInPicklist) => isInPicklist?.name === 'No';

  const handleClickHeadCheckbox = () => {
    if (selected?.length) {
      setSelected([]);
    } else {
      setSelected(assetsList.items.filter(checkIsAssetAvailable));
    }
  };

  const handleClickSingleCheckbox = (e, row) => {
    if (e.target.checked) {
      setSelected(selected.concat([row]));
    } else {
      setSelected(selected.filter(({ id }) => id !== row.id));
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.header__actions}>
          <Filter onApply={applyFilter} />
          {!!selected.length && (
            <div className={styles.header__actions_field}>
              <SelectInput
                name="rentalStatus"
                value={rental?.rentalStatus?.name || ''}
                menuItems={enrichNameToId(rentalStatuses) || []}
                onSelect={handleRentalSelect}
              />
              <Button
                className={styles.header__actions_field_status_button}
                onClick={handleApplyClick}>
                {isMobile ? <CheckIcon /> : <span>Apply</span>}
              </Button>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className={styles.controls}>
            <DownloadCSVButton
              endpoint="Assets/Serialized"
              filter={{
                ...assetsListFilter,
                filters: { ...assetsListFilter.filters, locationIds: [+id] },
                pagination: null,
                includeVerification: true
              }}
            />
          </div>
        )}
      </div>

      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHead>
            <TableRow>
              {!!assetsList?.items?.length && areCheckboxesAvailable() && (
                <StyledTableCell className={styles.checkboxHead}>
                  <CustomCheckbox
                    name="selectAll"
                    value={
                      selected.length === assetsList?.items?.filter(checkIsAssetAvailable).length
                    }
                    onChange={handleClickHeadCheckbox}
                  />
                </StyledTableCell>
              )}
              {tableHeadConfig.map((el) => (
                <StyledTableCell key={el.id} className={styles[el.styleName]}>
                  {el?.isSortable ? (
                    <SortableBlock
                      label={el.label}
                      parameter={el.parameter}
                      sortRules={assetsListFilter.sortRules}
                      onSortApply={sortColumn}
                    />
                  ) : (
                    <>{el.label}</>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assetsList?.items?.length && assetsListFilter?.isDataReceived ? (
              assetsList.items.map((row, index) => (
                <StyledTableRow key={index}>
                  {areCheckboxesAvailable() && (
                    <StyledTableCell className={styles.checkboxCell}>
                      {isAssetAvailable(row?.isInPicklist) && (
                        <CustomCheckbox
                          name={row.id}
                          value={!!selected?.find(({ id }) => id === row.id)}
                          onChange={(name, v, event) => handleClickSingleCheckbox(event, row)}
                        />
                      )}
                    </StyledTableCell>
                  )}
                  <StyledTableCell className={styles.assetCell}>
                    <LinkComponent
                      name={row?.drCode || ''}
                      path={`${ASSET_DETAILED_PATH}/${row.id}`}
                      boldText
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.sublocationCell}>
                    {row?.lastLocationHistory?.sublocation?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    <div className={styles.status}>
                      <StatusIndicator status={row.severity?.name} />
                      {row?.['currentInspectionHistory']?.inspectionStatus?.name ===
                        'NeedToBeInspected' && <TooltipComponent isMobile={isMobile} />}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className={styles.rentalCell}>
                    {row?.rental?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.lastVerifiedCell}>
                    {!!row['lastVerificationHistory'] &&
                      row['lastVerificationHistory']?.inspectionStatus?.name === 'Verified' &&
                      formatDate(
                        row['lastVerificationHistory']?.['inspectionDateAtUtc'],
                        dateTimeFormat
                      )}
                  </StyledTableCell>
                  <StyledTableCell className={styles.verifiedByCell}>
                    {!!row['lastVerificationHistory']?.['inspectedByUser'] &&
                      row['lastVerificationHistory']?.inspectionStatus?.name === 'Verified' &&
                      `${row['lastVerificationHistory']['inspectedByUser'].firstName} ${row['lastVerificationHistory']['inspectedByUser'].lastName}`}
                  </StyledTableCell>
                  <StyledTableCell className={styles.noteCell}>
                    {row?.shortNote || ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                {!!assetsListFilter?.isDataReceived && (
                  <StyledTableCell colSpan={1000} className={styles.empty}>
                    No available data in the table.
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={assetsList?.items?.length}
          currentPage={assetsListFilter?.pagination?.page}
          totalPages={assetsListFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={assetsListFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={LOCATION_DETAILS_ASSETS_TABLE_PARAMETER}
          getTableData={() => getAssets(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
