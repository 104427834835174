import { useSelector } from 'react-redux';

import {
  selectCategoriesData,
  selectCustomFieldsData,
  selectFormValuesData,
  selectLooseItemsData
} from 'pages/BatchUpdates/LooseItem/selectors';

export function useBatchItemUpdatesSelector() {
  const categories = useSelector(selectCategoriesData());
  const customFields = useSelector(selectCustomFieldsData());
  const looseItems = useSelector(selectLooseItemsData());

  const formState = useSelector(selectFormValuesData());

  return {
    categories,
    customFields,
    looseItems,
    formState
  };
}
