import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getNotificationCenterDataApi = (query) =>
  api.get(`/NotificationCenter?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = (q) =>
  api.get(`/NotificationCenter/FilterCriteria?${queryToString(q)}`).then((res) => res.data);

export const getPopupCreationFormApi = (q) =>
  api.get(`/NotificationCenter/CreationForm?${queryToString(q)}`).then((res) => res.data);

export const checkEmailApi = (query) =>
  api.get(`/NotificationCenter/UserCheck?${queryToString(query)}`).then((res) => res.data);

export const addRecipientApi = (data) =>
  api.post(`/NotificationCenter`, data).then((res) => res.data);

export const editRecipientApi = (data) =>
  api.put(`/NotificationCenter`, data).then((res) => res.data);

export const deleteRecipientApi = (query) =>
  api.delete(`/NotificationCenter?${queryToString(query)}`).then((res) => res.data);
