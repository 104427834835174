import React from 'react';
import styles from './components.module.scss';
import CreateIcon from '@material-ui/icons/Create';

export default function EditButton({ title, onClick }) {
  const handleClick = () => onClick();

  return (
    <button className={styles.edit_button} onClick={handleClick}>
      <CreateIcon />
      <span>{title}</span>
    </button>
  );
}
