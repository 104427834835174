import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';

const Accordion = withStyles({
  root: {
    border: '1px solid #dcdcdc',
    borderRadius: '8px',
    backgroundColor: '#f7f7f7',
    boxShadow: 'none',
    marginBottom: '20px',
    overflow: 'hidden',
    '&:last-child': {
      borderRadius: '8px',
      margin: '0 0 20px'
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: '0 0 20px',
      '&:last-child': {
        margin: '0 0 20px'
      }
    }
  },
  expanded: {}
})(MuiAccordion);

export default Accordion;
