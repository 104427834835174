import { useDispatch } from 'react-redux';

import { setUnsavedFormData } from 'actions/commonActions';

import {
  clearState,
  getCreationForm,
  createAssets,
  updateAssets,
  toggleScreen
} from 'actions/createBatchOfAssetsActions';

export function useCreateAssetsBatchActions() {
  const dispatch = useDispatch();

  const getCreationFormAction = () => dispatch(getCreationForm());

  const createAssetsAction = (data) => dispatch(createAssets(data));
  const updateAssetsAction = (data) => dispatch(updateAssets(data));

  const toggleScreenAction = (data) => dispatch(toggleScreen(data));

  const clearStateAction = () => dispatch(clearState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  return {
    getCreationFormAction,
    createAssetsAction,
    updateAssetsAction,
    toggleScreenAction,
    setUnsavedFormDataAction,
    clearStateAction
  };
}
