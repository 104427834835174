import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import styles from '../Components.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';
import React from 'react';
import LinkComponent from 'components/LinkComponent/LinkComponent';

export default function LooseItemsTable({ tableBodyData, cells }) {
  const canDisplayCell = (rowId, rowIndex) =>
    !rowId ||
    rowIndex === 0 ||
    !(tableBodyData[rowIndex]?.location?.id === tableBodyData[rowIndex - 1]?.location?.id);

  const getColumnSpan = (rowLocationId) =>
    tableBodyData.filter(({ location }) => location.id === rowLocationId).length;

  return (
    <div>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {cells.map((cellData) => (
                <StyledTableCell key={cellData.value}>
                  {cellData.value}
                  {cellData.footnote && (
                    <span className={styles.footnote}>{cellData.footnote}</span>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyData?.length ? (
              tableBodyData.map((rowData, rowIndex) => (
                <StyledTableRow key={rowData.id}>
                  <StyledTableCell>
                    <LinkComponent
                      path={`${LOCATION_DETAILED_PATH}/${rowData.location?.id}`}
                      name={rowData.location?.siteCode}
                      isRedirectAvailable={!rowData.location?.deleted}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{rowData?.sublocation?.name || ''}</StyledTableCell>
                  <StyledTableCell>{rowData?.notes || ''}</StyledTableCell>
                  <StyledTableCell>
                    {rowData?.status?.displayName || ''}
                    {rowData?.status?.displayName === 'In Picklist' &&
                      ` (${rowData?.picklist?.name})`}
                    {rowData?.status?.displayName === 'In Leavelist' &&
                      ` (${rowData?.leavelist?.name})`}
                  </StyledTableCell>
                  <StyledTableCell>{rowData?.minimumQuantity || 0}</StyledTableCell>
                  <StyledTableCell>{rowData?.byAheadAccount || 0}</StyledTableCell>
                  <StyledTableCell>{rowData.quantity || 0}</StyledTableCell>
                  {canDisplayCell(rowData.id, rowIndex) && (
                    <StyledTableCell
                      rowSpan={rowData?.location?.id && getColumnSpan(rowData.location.id)}
                      className={styles.merged}>
                      {rowData.locationQuantity || 0}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={10} className={styles.emptyTable}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
