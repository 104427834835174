import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

export default function LineReportTemplate({
  index,
  dragIsActive,
  onDrag,
  onCopy,
  onDelete,
  published
}) {
  return (
    <section
      className={`${styles.templateWrapper} ${styles.inactive} ${styles.lineTemplate}`}>
      <section className={styles.inactiveTemplate}>
        {!published && (
          <div className={styles.dragIcon}>
            <MenuIcon onMouseMove={() => onDrag(true)} />
          </div>
        )}
        {!published && (
          <div
            className={`${styles.inactiveTemplate__controls} ${
              dragIsActive ? styles.hidden : ''
            }`}>
            <button>
              <FileCopyIcon onClick={() => onCopy(index)} />
            </button>
            <button onClick={() => onDelete(index)}>
              <DeleteIcon />
            </button>
          </div>
        )}
        <div className={styles.inactiveTemplate__body}>
          <div>
            <hr />
          </div>
        </div>
      </section>
    </section>
  );
}
