import React, { useEffect, useState } from 'react';
import styles from './PageTable.module.scss';
import { Button, TextField } from '@material-ui/core';
import { StyledTableCell } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { reformatNumberValue, enqueueErrorSnackbar } from 'helpers/AppHelpers';
import clsx from 'clsx';
import { ERROR_BAA_MUST_NOT_EXCEED_QTY } from 'constants/infoSnackbarData';

function ByAheadAccountCell({
  name,
  value,
  index,
  isCellEditable,
  setCellEditable,
  isEditAvailable,
  rowQuantity,
  onUpdate
}) {
  const [open, setOpen] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isCellEditable && !open) return;
    if (isCellEditable) {
      setOpen(true);
      setCount(value);
    } else {
      setOpen(false);
      setIsInvalid(false);
    }
  }, [isCellEditable]);

  const validateCount = () => count <= rowQuantity;

  const handleAccept = () => {
    if (!validateCount()) {
      setIsInvalid(true);
      enqueueErrorSnackbar(ERROR_BAA_MUST_NOT_EXCEED_QTY);
      return;
    }
    onUpdate(count);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setCellEditable('');
    setIsInvalid(false);
  };

  const onInput = (event) => {
    const inputValue = reformatNumberValue(event.target.value);
    setCount(inputValue);
    isInvalid && setIsInvalid(false);
  };
  const handleCellClick = () => isEditAvailable && setCellEditable(`${name}-${index}`);
  const handleFocus = (event) => event.target.select();

  return (
    <StyledTableCell
      align="left"
      className={clsx(
        styles.baaCell,
        isEditAvailable && styles['item-part-cell-editable'],
        isInvalid && styles['item-part-cell-error']
      )}>
      {!open ? (
        <div
          className={clsx(styles['item-part-td'], styles['item-part-td-baa'])}
          onClick={handleCellClick}>
          {value}
        </div>
      ) : (
        <div className={clsx(styles['item-part-td'], styles['item-part-td-baa'])}>
          <TextField
            name={name}
            className={styles.textInput}
            variant="outlined"
            value={count}
            onChange={onInput}
            onFocus={handleFocus}
          />
          <div className={styles.helpers}>
            <Button onClick={handleClose} className={styles.alert}>
              <CloseIcon />
            </Button>
            <Button onClick={handleAccept} className={styles.success}>
              <CheckIcon />
            </Button>
          </div>
        </div>
      )}
    </StyledTableCell>
  );
}

export default ByAheadAccountCell;
