import api from '../api';
import { browserHistory } from 'browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_CATEGORY_LIST,
  SET_CURRENT_CATEGORY,
  SET_ACTIVE_CATEGORY,
  CLEAR_CATEGORY_LIST_STATE,
  CLEAR_ACTIVE_CATEGORY,
  SET_CATEGORY_LIST_SEARCH_VALUE
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_CATEGORY,
  SUCCESS_DELETE_CATEGORY,
  SUCCESS_UPDATE_CATEGORY
} from '../constants/infoSnackbarData';
import { CATEGORY_DETAILED_PATH, CATEGORY_LIST_PATH } from '../constants/routeConstants';

export const getCategoryList = () => (dispatch) => {
  api.category
    .getCategoryListApi()
    .then((res) => dispatch({ type: SET_CATEGORY_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const setSearchValue = (value) => (dispatch) => {
  dispatch({ type: SET_CATEGORY_LIST_SEARCH_VALUE, payload: value });
};

export const getSingleCategory = (id) => (dispatch) => {
  api.category
    .getSingleCategoryApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_CATEGORY, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(CATEGORY_LIST_PATH);
    });
};

export const createCategory = (data) => () => {
  return api.category.createCategoryApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_CATEGORY);
      browserHistory.push(`${CATEGORY_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateCategory = (data) => () => {
  return api.category.updateCategoryApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_CATEGORY);
      browserHistory.push(`${CATEGORY_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const deleteCategory = (id) => () => {
  return api.category.deleteCategoryApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_CATEGORY);
      browserHistory.push(CATEGORY_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const clearCategoryList = () => (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_LIST_STATE });
};

export const setActiveCategory = (category) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_CATEGORY, payload: category });
};

export const clearActiveCategory = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_CATEGORY });
};
