import React from 'react';

import { useSearchSelector } from 'hooks/Search';

import { areAllArraysEmpty, NO_MATCHES_MESSAGE, TYPE_SOMETHING_MESSAGE } from '../../../helpers';

import InfoText from '../InfoText';

export default function ConditionalRender({ children }) {
  const { isDataLoaded, searchResults } = useSearchSelector();

  const isResponseEmpty = areAllArraysEmpty(searchResults);

  if (isDataLoaded) {
    return isResponseEmpty ? <InfoText text={NO_MATCHES_MESSAGE} /> : children;
  } else {
    return isDataLoaded === null ? <InfoText text={TYPE_SOMETHING_MESSAGE} /> : null;
  }
}
