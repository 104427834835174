import { TICKETS_LIST_PATH } from 'constants/routeConstants';
import { formatDate } from 'helpers/AppHelpers';

export const dashboardTypes = {
  baseStatistics: 'TicketsBaseStatistic',
  ticketTurnover: 'AverageTicketTurnover',
  flowStatistic: 'TicketFlowStatistic'
};

export const dashboardLabels = {
  baseStatistics: 'Ticket Basic Statistic',
  ticketTurnover: 'Average Ticket Turnover',
  flowStatistic: 'Ticket Flow Statistic'
};

export const TOGGLE_BUTTONS = [
  { id: 1, value: dashboardTypes.baseStatistics, label: dashboardLabels.baseStatistics },
  { id: 2, value: dashboardTypes.ticketTurnover, label: dashboardLabels.ticketTurnover },
  { id: 3, value: dashboardTypes.flowStatistic, label: dashboardLabels.flowStatistic }
];

export const defaultTab = dashboardTypes.baseStatistics;

export const getColumnName = (dateFrom, dateTo) =>
  formatDate(dateFrom) + ' — ' + formatDate(dateTo);

export const colors = [
  { color: 'rgba(126, 195, 126, 1)', ticketSeverityName: 'Operational' },
  { color: 'rgba(225, 205, 128, 1)', ticketSeverityName: 'NonCritical' },
  { color: 'rgba(218, 152, 152, 1)', ticketSeverityName: 'Critical' },
  { color: 'rgba(12, 12, 12, 0.5)', ticketSeverityName: 'BeyondRepair' }
];

export const radios = [
  { id: 1, displayName: 'Show Opened Tickets', name: 'Show Opened Tickets', value: true },
  { id: 2, displayName: 'Show Closed Tickets', name: 'Show Closed Tickets', value: false }
];

export const generateCellUrl = (params) => {
  const queryParams = new URLSearchParams(params);

  return `${TICKETS_LIST_PATH}?${queryParams.toString()}`;
};
