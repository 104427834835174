import React, { useEffect, useState } from 'react';
import Selector from './Selector/Selector';
import Content from './Content/Content';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  clearAssetDashboardState,
  getDashboardData,
  getUserAssetFieldDashboardConfig,
  setPrefilterData
} from 'actions/overviewReportActions';
import { selectPrefilterData } from './selectors';
import { useMobileViewport } from 'hooks/useMobileViewport';

function OverviewReport({
  clearStateAction,
  prefilter,
  getUserDashboardOptionsAction,
  getDashboardDataAction,
  setPrefilterAction
}) {
  const isMobile = useMobileViewport();
  const [precondition, setPrecondition] = useState(null);

  useEffect(() => {
    getUserDashboardOptionsAction().then((res) => {
      if (Object.values(res).flat().length) {
        getTableData(res, 'enter');
      } else {
        setPrecondition(true);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const getTableData = (data, type) => {
    getDashboardDataAction(data).then((res) => {
      if (res) {
        setPrecondition(false);
        !type && setPrefilterAction(data);
      }
    });
  };

  return (
    <>
      {precondition === true ? (
        <Selector prefilter={prefilter} onGenerate={getTableData} />
      ) : (
        precondition === false && (
          <Content
            isMobile={isMobile}
            precondition={precondition}
            setPrecondition={setPrecondition}
            prefilter={prefilter}
          />
        )
      )}
    </>
  );
}
const mapStateToProps = createStructuredSelector({
  prefilter: selectPrefilterData()
});

const mapDispatchToProps = {
  getUserDashboardOptionsAction: getUserAssetFieldDashboardConfig,
  getDashboardDataAction: getDashboardData,
  setPrefilterAction: setPrefilterData,
  clearStateAction: clearAssetDashboardState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OverviewReport);
