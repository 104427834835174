export const getChangedBySearchFilter = (filter, value) => ({
  ...filter,
  filters: { ...filter.filters, searchQuery: value },
  pagination: { ...filter.pagination, page: 1 },
  responseReceived: false
});
export const getChangedBySortFilter = (filter, rowName) => ({
  ...filter,
  sortRules: rowName,
  pagination: { ...filter.pagination, page: 1 },
  responseReceived: false
});
export const getChangedByPageFilter = (filter, page) => ({
  ...filter,
  pagination: { ...filter.pagination, page },
  responseReceived: false
});
export const getAppliedFilter = (filter) => ({
  ...filter,
  pagination: { ...filter.pagination, page: 1 },
  responseReceived: false
});
export const getChangedByApplyFilter = (filter, values) => ({
  ...filter,
  filters: { ...filter.filters, ...values },
  pagination: { ...filter.pagination, page: 1 },
  responseReceived: false
});
export const getChangedByLimitFilter = (filter, limit) => ({
  ...filter,
  pagination: { ...filter.pagination, limit, page: 1 },
  responseReceived: false
});
