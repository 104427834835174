import React, { useState } from 'react';
import styles from './AddBomForm.module.scss';

import Controls from './Controls';
import Table from './Table';
import AssetsTable from './AssetsTable/AssetsTable';

export default function Form() {
  const [currentTab, setCurrentTab] = useState({ active: false });

  return (
    <div className={styles.form}>
      <Controls currentTab={currentTab} setCurrentTab={setCurrentTab}>
        {currentTab?.type === 'loose_items' && <Table />}
        {currentTab?.type === 'assets' && <AssetsTable />}
      </Controls>
    </div>
  );
}
