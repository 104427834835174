import React, { useEffect, useState } from 'react';
import styles from './LooseItemSelectionPopup.module.scss';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper
} from '@material-ui/core';
import { MainButton } from 'components/StyledComponents';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function LooseItemSelectionPopup({
  isMobile,
  looseItems,
  selectedValues,
  onApply,
  isTableReversed
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const onOpen = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (open) {
      setSelected(selectedValues);
    }
  }, [open]);

  const handleAllClick = () => {
    if (selected?.length === looseItems.length) {
      setSelected([looseItems[0].id]);
    } else {
      setSelected(looseItems.map(({ id }) => id));
    }
  };

  const onChange = (checkedId) => {
    let data = [...selected];
    if (data.includes(checkedId)) {
      data = data.filter((id) => id !== checkedId);
    } else {
      data.push(checkedId);
    }
    if (!data.length) {
      data.push(looseItems[0].id);
    }
    setSelected(data);
  };
  const onCancel = () => {
    if (open) {
      setOpen(false);
    }
  };
  const handleClickApply = () => {
    onApply(selected);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SettingsIcon />
      </Button>
      <Popper
        className={styles.popperContainer}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <ClickAwayListener onClickAway={onCancel}>
              <Paper classes={{ root: styles.selectorContainer }}>
                {isMobile && (
                  <section className={styles.popupHeader}>
                    <h1>{isTableReversed ? 'Add Column' : 'Add Row'}</h1>
                    <Button className={styles.closeButton} onClick={onCancel}>
                      <ChevronLeftIcon />
                    </Button>
                  </section>
                )}
                <div className={styles.itemsBlock}>
                  <div className={styles.itemsRow} onClick={handleAllClick}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          classes={{ root: styles.checkbox, checked: styles.checked }}
                          checked={selected?.length === looseItems.length}
                        />
                      }
                      label=""
                    />
                    <label className={styles.item_label}>Select All</label>
                  </div>
                  {looseItems.map((el) => (
                    <div key={el.id} className={styles.itemsRow} onClick={() => onChange(el.id)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            classes={{ root: styles.checkbox, checked: styles.checked }}
                            checked={selected.includes(el.id)}
                          />
                        }
                        label=""
                      />
                      <label className={styles.item_label}>{el.name}</label>
                    </div>
                  ))}
                </div>
                <div className={styles.popup_buttons}>
                  <MainButton text="Cancel" action={onCancel} type="secondary" size="info_popup" />
                  <MainButton
                    text="Apply"
                    action={handleClickApply}
                    type="primary"
                    size="info_popup"
                  />
                </div>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </>
  );
}
