import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    padding: '8px 16px',
    backgroundColor: '#f7f7f7',
    '&:not(:last-child)': {
      borderRight: '1px solid #dcdcdc'
    },
    boxSizing: 'border-box'
  },
  body: {
    fontSize: 14,
    fontFamily: 'Montserrat, sans-serif',
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }
}))(TableCell);

export const StickyTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    padding: '8px 16px',
    backgroundColor: '#f7f7f7',
    left: 0,
    position: 'sticky',
    borderRight: '1px solid #dcdcdc',
    zIndex: 1
  },
  body: {
    fontSize: 14,
    fontFamily: 'Montserrat, sans-serif',
    padding: '8px 16px',
    maxWidth: '800px',
    whiteSpace: 'nowrap',
    left: 0,
    position: 'sticky',
    zIndex: 1
  }
}))(TableCell);

export default StyledTableCell;
