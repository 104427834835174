import React from 'react';
import styles from './SearchTables.module.scss';

import { displayOrder, getSectionTitle } from '../../../helpers';

import { useSearchSelector } from 'hooks/Search';

import TableSwitcher from './TableSwitcher';
import TableWrapper from './TableWrapper';
import Title from './Title';
import SeeResultsLink from './SeeResultsLink';

export default function SearchTables() {
  const { searchResults } = useSearchSelector();

  return (
    <div className={styles.wrapper}>
      {displayOrder.map(
        (property) =>
          !!searchResults?.[property]?.length && (
            <div key={property} className={styles.container}>
              <Title title={getSectionTitle(property)} />
              <TableWrapper tableName={property}>
                <TableSwitcher tableName={property} rows={searchResults[property]} />
              </TableWrapper>
              <SeeResultsLink tableName={property} />
            </div>
          )
      )}
    </div>
  );
}
