import api from '../api';
import { queryToString } from '../../helpers/AppHelpers';

export const getSinglePrefixApi = (id) => api.get(`/AssetPrefix?id=${id}`).then((res) => res.data);

export const getPrefixTypesApi = () => api.get('/AssetPrefixTypes').then((res) => res.data);

export const getAssetFieldsApi = () => api.get('/AssetFields').then((res) => res.data);

export const getAssetFieldsDeletePermissionsApi = (assetPrefixId, assetFieldId) =>
  api
    .get(
      `/Prefix/AssetFieldDeletePermissions?assetPrefixId=${assetPrefixId}&assetFieldId=${assetFieldId}`
    )
    .then((res) => res.data);

export const createPrefixApi = (params) => api.post(`/AssetPrefix`, params).then((res) => res.data);

export const updatePrefixApi = (params) => api.put(`/AssetPrefix`, params).then((res) => res.data);

export const deletePrefixApi = (id) => api.delete(`/AssetPrefix?id=${id}`).then((res) => res.data);

export const deletePrefixFieldApi = (query) =>
  api.delete(`/AssetField?${queryToString(query)}`).then((res) => res);

export const updatePrefixFieldApi = (params) => api.put(`/AssetField`, params).then((res) => res);
