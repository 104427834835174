import { getRandomId } from 'helpers/AppHelpers';

const mapItemToChip = (data, name) => {
  return data.map((el) => {
    return { displayName: el[name] || '', chipId: getRandomId() };
  });
};

export const generateChips = (data) => {
  const { assets, locations, prefixes, rootCauses, ticketTypes } = data;

  let chipsArray = [];

  if (assets?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(assets, 'drCode'));
  }
  if (locations?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(locations, 'siteCode'));
  }
  if (prefixes?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(prefixes, 'prefix'));
  }
  if (rootCauses?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(rootCauses, 'name'));
  }
  if (ticketTypes?.length) {
    chipsArray = chipsArray.concat(mapItemToChip(ticketTypes, 'name'));
  }

  const openedObj = {
    chipId: getRandomId(),
    displayName: data?.opened ? 'Show Opened Tickets' : 'Show Closed Tickets'
  };
  chipsArray.push(openedObj);

  return chipsArray;
};
