import api from '../api';

export const getQRCodesGeneratorCreationFormApi = () =>
  api.get('/Assets/QRCodes/CreationForm').then((res) => res.data);

export const getReportQRCodesGeneratorCreationFormApi = () =>
  api.get('/GeneralReports/QRCodes/CreationForm').then((res) => res.data);

export const generateQRCodesApi = (params) =>
  api.post(`/Assets/QRCodes`, params).then((res) => res);

export const generateReportQRCodesApi = (params) =>
  api.post(`/GeneralReports/QRCodes`, params).then((res) => res);
