import React, { useEffect, useState } from 'react';
import styles from './BOMConfigurations.module.scss';
import { Button, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectConfigurationsData, selectSearchValueData } from './selectors';
import {
  clearConfigurationState,
  getConfigurationList,
  setSearchValue
} from 'actions/looseItemConfigurationActions';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StyledTableCell,
  StyledTableRow,
  MainButton
} from 'components/StyledComponents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import {
  LOOSE_ITEM_DETAILED_PATH,
  BOM_CONFIGURATION_DETAILED_PATH,
  BOM_CONFIGURATION_EDIT_PATH
} from 'constants/routeConstants';
import { isAdminUser, isOperationsManagerUser, reformatInputValue } from 'helpers/AppHelpers';
import { selectUserConfig } from '../../commonSelectors';
import SearchTextField from 'components/SearchTextField/SearchTextField';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useMobileViewport } from 'hooks/useMobileViewport';

const NEED_SEARCH_STRING_LENGTH = 2;

function BOMConfigurations({
  userConfig,
  configurationList,
  getConfigurationsAction,
  searchValue,
  setSearchValueAction,
  clearConfigurationStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const { userRole } = userConfig;

  const [configurationsData, setConfigurationsData] = useState([]);
  const [expanded, setExpanded] = useState([]);

  let loadFlag = false;

  useEffect(() => {
    if (!configurationList.length) {
      getConfigurationsAction().then((res) => {
        if (res?.length) {
          setConfigurationsData(res);
        }
        loadFlag = true;
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      clearConfigurationStateAction();
    };
  }, []);

  useEffect(() => {
    if (!configurationList?.length) return;

    if (searchValue.length < NEED_SEARCH_STRING_LENGTH) {
      setExpanded([]);
      setConfigurationsData(configurationList);
    } else {
      const search = searchValue.toLowerCase();

      const filteredCategories = configurationList.map((elem) => {
        const looseItems = elem.looseItems?.filter((item) =>
          item.name?.toLowerCase().includes(search)
        );
        const assetPrefixes = elem.assetPrefixes?.filter((item) =>
          item.prefix?.toLowerCase().includes(search)
        );

        return {
          ...elem,
          looseItems,
          assetPrefixes
        };
      });

      const newStateExpanded = filteredCategories.map(
        (category) => !!(category?.looseItems?.length || category?.assetPrefixes?.length)
      );

      setExpanded(newStateExpanded);
      setConfigurationsData(filteredCategories);
    }
  }, [searchValue, configurationList]);

  const onInput = (event) => {
    const value = reformatInputValue(event.target.value, 20, /[^A-Za-z0-9 ]/g);
    setSearchValueAction(value);
  };

  const onClearField = () => {
    setSearchValueAction('');
    setExpanded([]);
    setConfigurationsData(configurationList);
  };

  const handleToggleOne = (index) => {
    const newStateExpanded = [...expanded];
    newStateExpanded[index] = !newStateExpanded[index];
    setExpanded(newStateExpanded);
  };

  const shouldShowHint = () =>
    searchValue?.length > 1 && !expanded.some((el) => el === true) && configurationList?.length;

  const handleEditClick = (id) => history.push(`${BOM_CONFIGURATION_EDIT_PATH}/${id}`);

  return (
    <section className={styles.pageContainer}>
      <section className={styles.headerWrapper}>
        <div className={styles.pageHeader}>
          <h1>BOM configuration</h1>
          {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && (
            <MainButton
              isMobile={isMobile}
              text={isMobile ? '+' : 'Add new configuration'}
              action={() => history.push(BOM_CONFIGURATION_EDIT_PATH)}
              type="primary"
            />
          )}
        </div>
        <div className={styles.searchPanel}>
          <SearchTextField
            value={searchValue}
            onFieldInput={onInput}
            onClearField={onClearField}
            shouldShowHint={shouldShowHint}
            disableRightMargin={true}
          />
        </div>
      </section>
      {configurationsData.length ? (
        configurationsData.map((configuration, index) => (
          <Accordion key={index} expanded={!!expanded[index]}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  className={styles.accordionIcon}
                  onClick={() => handleToggleOne(index)}
                />
              }>
              <div className={styles.accordionTitle}>
                <LinkComponent
                  path={`${BOM_CONFIGURATION_DETAILED_PATH}/${configuration.id}`}
                  name={configuration.name}
                />
              </div>
              {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && (
                <Button
                  className={styles.accordionButton}
                  onClick={() => handleEditClick(configuration.id)}>
                  Edit
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {configuration?.looseItems?.length || configuration?.assetPrefixes?.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="customized table" className={styles.table}>
                    <TableBody>
                      {configuration.looseItems?.map((row) => (
                        <StyledTableRow key={`looseItem-${row.name}`}>
                          <StyledTableCell className={styles.firstCell}>
                            <LinkComponent
                              path={`${LOOSE_ITEM_DETAILED_PATH}/${row.id}`}
                              name={row.name}
                            />
                          </StyledTableCell>
                          <StyledTableCell className={styles.rowQuantity}>
                            {row.quantity}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}

                      {configuration.assetPrefixes?.map((row) => (
                        <StyledTableRow key={`assetPrefix-${row.prefix}`}>
                          <StyledTableCell className={styles.firstCell}>
                            {row.prefix}
                          </StyledTableCell>
                          <StyledTableCell className={styles.rowQuantity}>
                            {row.quantity}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer component={Paper}>
                  <Table aria-label="customized table" className={styles.table}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          {configurationList[index] && 'No matches'}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <section className={styles.emptyResponseBlock}>
          {loadFlag && <span>No available data</span>}
        </section>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  configurationList: selectConfigurationsData(),
  userConfig: selectUserConfig(),
  searchValue: selectSearchValueData()
});

const mapDispatchToProps = {
  getConfigurationsAction: getConfigurationList,
  setSearchValueAction: setSearchValue,
  clearConfigurationStateAction: clearConfigurationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BOMConfigurations);
