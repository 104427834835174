export const prepareData = (data) => {
  const common1Part = [
    { id: 1, label: 'Freight Bill', value: data?.freightBill || '' },
    { id: 2, label: 'Date of Shipment', value: data?.dateOfShipment || '', type: 'date' },
    { id: 3, label: 'Name of Carrier/DOT#', value: data?.nameOFCarrierOrDOTNumber || '' }
  ];
  const common2Part = [
    { id: 4, label: 'Driver Phone Number', value: data?.cellPhone || '' },
    { id: 5, label: 'Truck Number', value: data?.truckNumber || '' },
    { id: 6, label: 'Project Number', value: data?.jobNumber || '' }
  ];
  const common = [common1Part, common2Part];

  const location = [
    { id: 1, label: 'Origin Location', value: data?.originLocation || '' },
    { id: 2, label: 'Company Name', value: data?.originCompanyName || '' },
    { id: 3, label: 'Address', value: data?.originAddress || '' },
    { id: 4, label: 'Address 2', value: data?.originAddress2 || '' },
    { id: 5, label: 'City', value: data?.originCity || '' },
    { id: 6, label: 'ZIP', value: data?.originZIP || '' },
    { id: 7, label: 'State', value: data?.originState || '' },
    { id: 8, label: 'Point of Contact', value: data?.originPointOfContact || '' },
    { id: 9, label: 'POC Phone', value: data?.originPOCPhone || '' }
  ];
  const destination = [
    { id: 1, label: 'Destination', value: data?.destinationLocation || '' },
    { id: 2, label: 'Company Name', value: data?.destinationCompanyName || '' },
    { id: 3, label: 'Address', value: data?.destinationAddress || '' },
    { id: 4, label: 'Address 2', value: data?.destinationAddress2 || '' },
    { id: 5, label: 'City', value: data?.destinationCity || '' },
    { id: 6, label: 'ZIP', value: data?.destinationZIP || '' },
    { id: 7, label: 'State', value: data?.destinationState || '' },
    { id: 8, label: 'Point of Contact', value: data?.destinationPointOfContact || '' },
    { id: 9, label: 'POC Phone', value: data?.destinationPOCPhone || '' }
  ];

  const vehicle = {
    typeOfVehicle:
      data?.typeOfVehicle?.displayName === 'Other'
        ? data?.otherVehicle || ''
        : data?.typeOfVehicle?.displayName || '',
    instructions: data?.specialInstructions || ''
  };

  const deliveryItems = {
    deficiencies: data?.deficienciesUponDelivery || '',
    tableData: data?.billOfLadingItems || [],
    totalWeight: data?.totalWeight
  };

  const responsible = [
    {
      id: 1,
      name: { value: data?.shippedBy || '', label: 'Shipped by' },
      date: { value: data?.shippedDate || '', label: 'Date' },
      phone: { value: data?.shippedPhoneNumber || '', label: 'Phone Number' },
      signature: { value: data?.shippedSignature || null, param: 'shippedSignature' },
      popup: {
        label: 'Shipped by',
        values: {
          shippedBy: data?.shippedBy || '',
          shippedDate: data?.shippedDate || null,
          shippedPhoneNumber: data?.shippedPhoneNumber || '',
          shippedSignatureBase64: ''
        },
        params: ['shippedBy', 'shippedDate', 'shippedPhoneNumber', 'shippedSignatureBase64']
      }
    },
    {
      id: 2,
      name: { value: data?.receivedBy || '', label: 'Received by' },
      date: { value: data?.receivedDate || '', label: 'Date' },
      phone: { value: data?.receivedPhoneNumber || '', label: 'Phone Number' },
      signature: { value: data?.receivedSignature || null, param: 'receivedSignature' },
      popup: {
        label: 'Received by',
        values: {
          receivedBy: data?.receivedBy || '',
          receivedDate: data?.receivedDate || null,
          receivedPhoneNumber: data?.receivedPhoneNumber || '',
          receivedSignatureBase64: ''
        },
        params: ['receivedBy', 'receivedDate', 'receivedPhoneNumber', 'receivedSignatureBase64']
      }
    },
    {
      id: 3,
      name: { value: data?.driverName || '', label: 'Driver Name' },
      date: { value: data?.driverDate || '', label: 'Date' },
      phone: { value: data?.driverPhoneNumber || '', label: 'Phone Number' },
      signature: { value: data?.driverSignature || null, param: 'driverSignature' },
      popup: {
        label: 'Driver Name',
        values: {
          driverName: data?.driverName || '',
          driverDate: data?.driverDate || null,
          driverPhoneNumber: data?.driverPhoneNumber || '',
          driverSignatureBase64: ''
        },
        params: ['driverName', 'driverDate', 'driverPhoneNumber', 'driverSignatureBase64']
      }
    }
  ];

  return { common, location, destination, vehicle, deliveryItems, responsible };
};

export const isElementAvailable = (bol, { isAdminUser, isEntityCreatorUser }) => {
  const { picklist, leavelist, createdByUser } = bol;

  const isBoLPicklist = !!picklist?.id;
  const isBoLLeavelist = !!leavelist?.id;

  if (isAdminUser) return true;

  if (isBoLPicklist) return isEntityCreatorUser(picklist?.['createdByUser']?.email || '');
  if (isBoLLeavelist) return isEntityCreatorUser(leavelist?.['createdByUser']?.email || '');

  return isEntityCreatorUser(createdByUser?.email || '');
};
