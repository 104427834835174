import React, { useState } from 'react';
import { StyledTableCell } from 'components/StyledComponents';
import styles from './RequestTableBody.module.scss';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import SelectInput from 'components/SelectInput/SelectInput';
import { itemStatuses } from 'pages/RequestOrder/helpers';

function StatusCell({ statusValue, rowData, onApply, isCanBeEditable }) {
  const [values, setValues] = useState({});

  const handleOpen = () => {
    if (!isCanBeEditable) return;
    setValues({ open: true, value: { id: statusValue.name, ...statusValue } });
  };

  const handleClick = (param) => {
    if (param === 'accept') {
      onApply({ ...rowData, status: values.value }, 'single');
    }
    setValues({});
  };

  const onStatusSelect = ({ value }) => {
    setValues({ ...values, value });
  };

  return (
    <StyledTableCell className={styles.statusCell}>
      {!values.open ? (
        <div
          className={clsx(
            styles.commonBlock,
            styles.statusBlock,
            isCanBeEditable && styles.pointer
          )}
          onClick={handleOpen}>
          {statusValue?.displayName || ''}
        </div>
      ) : (
        <section className={styles.statusBlock}>
          <SelectInput
            name="status"
            value={values?.value?.id || ''}
            menuItems={itemStatuses || []}
            onSelect={onStatusSelect}
          />
          <div className={styles.helpers}>
            <Button onClick={handleClick} className={styles.alert}>
              <CloseIcon />
            </Button>
            <Button onClick={() => handleClick('accept')} className={styles.success}>
              <CheckIcon />
            </Button>
          </div>
        </section>
      )}
    </StyledTableCell>
  );
}

export default StatusCell;
