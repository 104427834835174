import { createSelector } from 'reselect';

const logsData = (state) => state.billOfLadingLogsData;
const uploadLogsData = (state) => state.uploadBoLLogsData;

export const selectFilterData = () => createSelector([logsData], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([logsData], (state) => state.filterCriteria);

export const selectCreationFormData = () =>
  createSelector([logsData], (state) => state.creationForm);

export const selectConfigurationData = () =>
  createSelector([uploadLogsData], (state) => state.configuration);
