import { useDispatch } from 'react-redux';

import {
  getDashboardConfig,
  updateTabConfig,
  getFilterCriteria,
  getBaseStatistics,
  getTurnoverStatistics,
  setTab,
  clearState,
  updateSectionConfig,
  getAssetOptions,
  getFlowStatistics,
  clearTabState,
  getBasicStatisticCsvResource,
  getTurnoverStatisticCsvResource,
  getFlowStatisticCsvResource
} from 'actions/ticketsDashboardActions';

export function useTicketsDashboardActions() {
  const dispatch = useDispatch();

  const getDashboardConfigAction = (q) => dispatch(getDashboardConfig(q));
  const updateTabConfigAction = (data) => dispatch(updateTabConfig(data));

  const updateSectionConfigAction = (data) => dispatch(updateSectionConfig(data));

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const getBaseStatisticsAction = () => dispatch(getBaseStatistics());

  const getAssetOptionsAction = (q) => dispatch(getAssetOptions(q));

  const getTurnoverStatisticsAction = () => dispatch(getTurnoverStatistics());

  const getFlowStatisticsAction = () => dispatch(getFlowStatistics());

  const setTabAction = (tab) => dispatch(setTab(tab));

  const getBasicStatisticCsvResourceAction = (data) => dispatch(getBasicStatisticCsvResource(data));
  const getTurnoverStatisticCsvResourceAction = (data) =>
    dispatch(getTurnoverStatisticCsvResource(data));
  const getFlowStatisticCsvResourceAction = (data) => dispatch(getFlowStatisticCsvResource(data));

  const clearTabStateAction = (data) => dispatch(clearTabState(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    getDashboardConfigAction,
    updateTabConfigAction,
    updateSectionConfigAction,
    getFilterCriteriaAction,
    getBaseStatisticsAction,
    getAssetOptionsAction,
    getTurnoverStatisticsAction,
    getFlowStatisticsAction,
    setTabAction,
    getBasicStatisticCsvResourceAction,
    getTurnoverStatisticCsvResourceAction,
    getFlowStatisticCsvResourceAction,
    clearTabStateAction,
    clearStateAction
  };
}
