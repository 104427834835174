import React from 'react';

import clsx from 'clsx';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import SelectInput from 'components/SelectInput/SelectInput';

import { isTeamMemberUser } from 'helpers/AppHelpers';
import { getSublocations, enrichLocationOptions } from '../../utils/locations';

import styles from './LooseItemsTable.module.scss';

const AssembledStatus = 'Assembled';
const DisassembledStatus = 'Disassembled';

const LooseItemsTable = ({
  tableState,
  setTableState,
  locationList,
  values,
  setValues,
  isFirstTableView,
  isStatus,
  userRole,
  isOwnAssembly
}) => {
  const isFirstNameCell = (index) => {
    return !(index !== 0 && tableState[index]?.id === tableState[index - 1]?.id);
  };

  const getCellNumber = (rowId) => {
    return tableState.filter(({ id }) => id === rowId)?.length;
  };

  const onSelect = (name, value, index) => {
    const newLocation = { ...value };
    const newArray = [...tableState];
    newArray[index][name] = newLocation;
    if (name === 'location') {
      newArray[index].sublocation = null;
    }
    setTableState(newArray);
    if (values.active) setValues({ active: false, isDisabled: false });
  };

  const onSublocationSelect = (item, index) => {
    const newArray = [...tableState];
    newArray[index] = { ...newArray[index], sublocation: item.value };
    setTableState(newArray);
  };

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Loose item</StyledTableCell>
            {isFirstTableView() ? (
              <>
                <StyledTableCell>Required quantity</StyledTableCell>
                <StyledTableCell>Point of origin(Sublocation)</StyledTableCell>
                <StyledTableCell>Selected quantity</StyledTableCell>
                <StyledTableCell>
                  From BAA<span className={styles.footnote}>[1]</span>
                </StyledTableCell>
              </>
            ) : (
              <>
                <StyledTableCell>
                  Released quantity(BAA<span className={styles.footnote}>[1]</span>)
                </StyledTableCell>
                <StyledTableCell>Point of origin(Sublocation)</StyledTableCell>
                <StyledTableCell>Point of destination</StyledTableCell>
                <StyledTableCell>Sublocation</StyledTableCell>
              </>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableState.map((row, index) => (
            <React.Fragment key={row.id}>
              {isFirstTableView() ? (
                <StyledTableRow>
                  {isFirstNameCell(index) && (
                    <>
                      <StyledTableCell rowSpan={getCellNumber(row.id)} className={styles.nameCell}>
                        {row?.name || ''}
                      </StyledTableCell>
                      <StyledTableCell
                        rowSpan={getCellNumber(row.id)}
                        className={styles.quantityCell}>
                        {row?.requiredQuantity || 0}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell className={styles.originCell}>
                    {isStatus(AssembledStatus) ? (
                      <>{row?.pointOfOrigin || ''}</>
                    ) : (
                      <>
                        {row?.location?.siteCode || ''}
                        {row?.sublocation?.id ? '(' + row.sublocation?.name + ')' : ''}
                      </>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={styles.selectedQtyCell}>
                    {row?.selectedQuantity || 0}
                  </StyledTableCell>
                  <StyledTableCell className={styles.selectedBaaCell}>
                    {row?.selectedByAheadAccount || 0}
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell className={styles.nameCell}>{row?.name || ''}</StyledTableCell>
                  <StyledTableCell className={styles.quantityCell}>
                    {row?.requiredQuantity || 0}
                    {row?.requiredBaaQuantity ? '(' + row.requiredBaaQuantity + ')' : ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.originCell}>
                    {row?.pointOfOrigin || ''}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.locationCell,
                      isStatus(DisassembledStatus) && styles.noSpace
                    )}>
                    {isStatus(DisassembledStatus) &&
                    !isTeamMemberUser(userRole) &&
                    isOwnAssembly() ? (
                      <InputSearchWithMultiselect
                        name="location"
                        defaultValue={row?.location?.id || ''}
                        options={enrichLocationOptions(locationList, row.location) || []}
                        onFilterSelect={onSelect}
                        index={index}
                        multiselect={false}
                        tableView={true}
                      />
                    ) : (
                      <>{row?.location?.siteCode || ''}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.sublocationCell,
                      isStatus(DisassembledStatus) && styles.noSpace
                    )}>
                    {isStatus(DisassembledStatus) &&
                    !isTeamMemberUser(userRole) &&
                    isOwnAssembly() ? (
                      <SelectInput
                        name="sublocation"
                        value={row?.sublocation?.id || ''}
                        menuItems={getSublocations(locationList, row?.location?.id) || []}
                        onSelect={onSublocationSelect}
                        index={index}
                        tableView={true}
                      />
                    ) : (
                      <>{row?.sublocation?.name || ''}</>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LooseItemsTable;
