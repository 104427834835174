import { useDispatch } from 'react-redux';
import {
  setSearchQuery,
  searchMatches,
  clearState,
  setDataLoadedFlag
} from 'actions/searchResultsActions';

export function useSearchActions() {
  const dispatch = useDispatch();

  const setSearchQueryAction = (str) => dispatch(setSearchQuery(str));

  const setDataLoadedFlagAction = (flag) => dispatch(setDataLoadedFlag(flag));

  const searchMatchesAction = (query) => dispatch(searchMatches(query));

  const clearStateAction = () => dispatch(clearState());

  return {
    setSearchQueryAction,
    searchMatchesAction,
    setDataLoadedFlagAction,
    clearStateAction
  };
}
