import React from 'react';
import styles from './FilterComponents.module.scss';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

export default function ChipsComponent({
  label,
  disableDelete,
  disableSeparateChipDelete,
  chips,
  onChipDelete,
  enableForMobile
}) {
  const handleChipDelete = (item) => onChipDelete(item);

  return (
    <section className={clsx(styles.chips, enableForMobile && styles.enableForMobile)}>
      {!!chips?.length &&
        chips.map((item) => (
          <Chip
            key={item.chipId || item.id}
            label={item[label]}
            deleteIcon={<CloseIcon />}
            className={styles.chips__chip}
            onDelete={
              disableDelete
                ? null
                : disableSeparateChipDelete
                ? disableSeparateChipDelete(item)
                  ? null
                  : () => handleChipDelete(item)
                : () => handleChipDelete(item)
            }
          />
        ))}
    </section>
  );
}
