import { createSelector } from 'reselect';

const categoryData = (state) => state.categoryData;
const prefixData = (state) => state.prefixData;

export const selectCategoryListData = () =>
  createSelector([categoryData], (state) => state.categoryList);

export const selectSingleCategoryData = () =>
  createSelector([categoryData], (state) => state.currentCategory);

export const selectActiveCategoryData = () =>
  createSelector([categoryData], (state) => state.activeCategory);

export const selectSearchValueData = () =>
  createSelector([categoryData], (state) => state.searchValue);

export const selectSinglePrefixData = () =>
  createSelector([prefixData], (state) => state.currentPrefix);

export const selectPrefixTypesData = () =>
  createSelector([prefixData], (state) => state.prefixTypes);

export const selectAssetFieldsData = () =>
  createSelector([prefixData], (state) => state.assetFields);
