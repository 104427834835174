import React from 'react';

import BatchItemUpdatesWrapper from './components/Wrapper';
import BatchItemUpdatesForm from './components/Form';

import { FormProvider, useForm } from 'react-hook-form';
import { useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';

export default function BatchLooseItemUpdates() {
  const { formState } = useBatchItemUpdatesSelector();

  const methods = useForm({
    defaultValues: formState,
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <BatchItemUpdatesWrapper>
        <BatchItemUpdatesForm />
      </BatchItemUpdatesWrapper>
    </FormProvider>
  );
}
