import React, { useEffect, useState } from 'react';
import styles from './ProjectListFilter.module.scss';
import FilterIcon from 'assets/images/filter.svg';
import { Backdrop, Checkbox, Chip, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import { MainButton } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import { getLocationList } from 'actions/projectActions';
import { useDispatch } from 'react-redux';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import { sortByParam } from 'helpers/AppHelpers';

function ProjectListFilter({ isMobile, filter, onApply, openFilter, setOpenFilter }) {
  const dispatch = useDispatch();
  const { filters } = filter;

  const [anchorEl, setAnchorEl] = useState(null);
  const [chips, setChips] = useState([]);
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState({ ...filters });

  useEffect(() => {
    dispatch(getLocationList({ filters: { active: true, includeUserCheck: true } })).then((res) => {
      setOptions(res?.items || []);
    });
  }, []);

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && addChips(filters);
  };

  const clickCheckbox = () => {
    const currentFilter = { ...values, active: !values.active };
    setValues(currentFilter);
    isMobile && addChips(currentFilter);
  };

  const addChips = (inputFilter) => {
    const chipsArray = [];
    if (inputFilter?.locationIds?.length) {
      inputFilter.locationIds.forEach((item) => {
        const newChip = options.find((option) => option.id === item);
        chipsArray.push({ ...newChip, key: 'locationIds', chipName: newChip?.siteCode || '' });
      });
    }
    if (inputFilter?.locationJobNumberIds?.length) {
      inputFilter.locationJobNumberIds.forEach((item) => {
        const newChip = options.find((option) => option.id === item);
        chipsArray.push({
          ...newChip,
          key: 'locationJobNumberIds',
          chipName: newChip?.locationJobNumber || ''
        });
      });
    }
    if (!inputFilter?.active) {
      chipsArray.push({ id: 'active', name: 'Inactive', key: 'active', chipName: 'Inactive' });
    }
    setChips(chipsArray);
  };

  const applyFilter = () => {
    addChips(values);
    setOpenFilter(!openFilter);
    onApply(values);
  };

  const handleClearFilter = () => {
    const defaultState = { ...values, active: true, locationIds: [], locationJobNumberIds: [] };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const handleChipDelete = (item) => {
    setChips(chips.filter((chip) => chip.id !== item.id));
    const finalResult = {
      ...values,
      active: item.key === 'active' ? !values.active : values.active,
      locationIds:
        item.key === 'locationIds'
          ? values.locationIds.filter((id) => id !== item.id)
          : values.locationIds,
      locationJobNumberIds:
        item.key === 'locationJobNumberIds'
          ? values.locationJobNumberIds.filter((id) => id !== item.id)
          : values.locationJobNumberIds
    };
    setValues(finalResult);
    if (!isMobile) {
      onApply(finalResult);
    }
  };

  const closeFilterHandle = () => {
    setOpenFilter(!openFilter);
    isMobile && addChips(filters);
  };

  const selectOption = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && addChips(updatedValues);
  };

  useEffect(() => {
    if (options?.length) {
      addChips(filter.filters);
      setValues({ ...filter.filters });
    }
  }, [filter, options]);

  return (
    <div className={styles.filterWrapper}>
      {!isMobile && <Backdrop open={openFilter} />}
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="" />
      </IconButton>

      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && <FilterHeader onBack={closeFilterHandle} />}
              <section className={styles.formColumn}>
                <section className={styles.formCell}>
                  <label>Location</label>
                  <div className={styles.inputWrapper}>
                    <AutocompleteWithSelectAll
                      name="locationIds"
                      label="siteCode"
                      value={values?.locationIds || []}
                      options={options || []}
                      onSelect={selectOption}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Job Number</label>
                  <div className={styles.inputWrapper}>
                    <AutocompleteWithSelectAll
                      name="locationJobNumberIds"
                      label="locationJobNumber"
                      value={values?.locationJobNumberIds || []}
                      options={sortByParam(options, 'locationJobNumber') || []}
                      onSelect={selectOption}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={styles.checkboxCell}>
                  <label>Show inactive?</label>
                  <div className={styles.inputWrapper}>
                    <Checkbox
                      classes={{ root: styles.checkbox, checked: styles.checked }}
                      onChange={clickCheckbox}
                      name="active"
                      checked={!values.active}
                    />
                  </div>
                </section>
                {isMobile && (
                  <section className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={`${item.id}-${item.key}`}
                          label={item.chipName}
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                  </section>
                )}
                <section className={styles.buttonWrapper}>
                  <ClearFilterButton onClick={handleClearFilter} />
                  <div className={styles.primary}>
                    <MainButton
                      text="Cancel"
                      action={closeFilterHandle}
                      type="secondary"
                      size="filter_popup"
                    />
                    <MainButton
                      text="Apply"
                      action={applyFilter}
                      type="primary"
                      size="filter_popup"
                    />
                  </div>
                </section>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>

      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={`${item.id}-${item.key}`}
                label={item.chipName}
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

export default ProjectListFilter;
