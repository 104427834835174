import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getRandomId, reformatInputValue } from 'helpers/AppHelpers';
import { MainButton, StyledSwitch } from 'components/StyledComponents/';
import { enrichNameToId } from 'helpers/AppHelpers';
import { Checkbox, TextField } from '@material-ui/core';
import SelectInput from 'components/SelectInput/SelectInput';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

const MAX_NUMBER_OF_QUESTIONS = 50;

const severities = [
  { id: 1, displayName: 'Operational', name: 'Operational' },
  { id: 2, displayName: 'Non-Critical', name: 'NonCritical' },
  { id: 3, displayName: 'Critical', name: 'Critical' },
  { id: 4, displayName: 'Beyond repair', name: 'BeyondRepair' }
];

export default function ChoiceReportTemplate({
  index,
  template,
  dragIsActive,
  reportType,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  published
}) {
  const { data } = template;
  const { active, multiple, required, isComment } = data;
  const [validationErrors, setValidationErrors] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const updateOptions = (options) => {
    onUpdate(index, {
      ...template,
      data: { ...template.data, options }
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(data.options, result.source.index, result.destination.index);
    updateOptions(items);
  };

  const handleSwitchClick = (e) => {
    onUpdate(index, {
      ...template,
      data: { ...template.data, [e.target.name]: !template.data[e.target.name] }
    });
  };

  const onInput = (event) => {
    let value = event.target.value;

    value = reformatInputValue(value, 1000);

    onUpdate(index, {
      ...template,
      data: { ...template.data, text: value }
    });
  };

  const onAddOption = () => {
    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        options: (template.data?.options || []).concat({
          id: getRandomId().toString().slice(7),
          value: '',
          createTicket: {
            needCreation: false,
            severity: {}
          }
        })
      }
    });
  };

  const onUpdateOption = (i, data) => {
    const optionsArr = [...template.data.options];
    optionsArr[i] = data;
    setValidationErrors({ ...validationErrors, [data.id]: false });
    updateOptions(optionsArr);
  };

  const onDeleteOption = (i) => {
    const optionsArr = [...data.options];
    optionsArr.splice(i, 1);
    updateOptions(optionsArr);
  };

  const isSaveDisabled = () =>
    !data.options?.filter(({ text }) => text).length ||
    !data.text?.length ||
    Object.values(validationErrors).filter(Boolean).length;

  const onSave = () => {
    if (data.text) {
      if (reportType === 'Asset') {
        const validationData = data.options
          .filter(({ createTicket: { needCreation } }) => needCreation)
          .map(({ id, createTicket: { severity } }) => {
            return { id, value: !severity.name };
          })
          .reduce((obj, item) => ((obj[item.id] = item.value), obj), {});
        setValidationErrors(validationData);
        if (validationData && Object.values(validationData).filter(Boolean).length) return;
      }

      onUpdate(index, {
        ...template,
        data: {
          ...template.data,
          options: template.data?.options?.filter(({ text }) => text),
          active: false
        }
      });
    } else {
      onDelete(index);
    }
  };

  return (
    <section className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Choice</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <TextField
              placeholder="Enter the question text"
              name="text"
              className={styles.textInput}
              variant="outlined"
              value={data.text || ''}
              onChange={onInput}
            />
            <section className={styles.selectOptions}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`droppableOptions-${index}`}>
                  {(provided) => (
                    <div
                      className={styles.optionsBlock}
                      ref={provided.innerRef}
                      {...provided.droppableProps}>
                      {!!data.options?.length &&
                        data.options.map((option, index) => (
                          <Draggable
                            key={`${option.id}-${index}`}
                            draggableId={`${option.id}-${index}`}
                            index={index}>
                            {(provided) => (
                              <section
                                key={option.id}
                                className={styles.selectOptions__option}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <MenuIcon className={styles.dragIcon} />
                                <SelectOption
                                  key={index}
                                  index={index}
                                  data={option}
                                  isInvalid={validationErrors[option.id]}
                                  reportType={reportType}
                                  multiple={multiple}
                                  onUpdate={onUpdateOption}
                                  onDelete={onDeleteOption}
                                />
                              </section>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className={styles.selectOptions__addButton}>
                <MainButton
                  text="+"
                  action={onAddOption}
                  type="primary"
                  size="navigation"
                  isDisabled={data.options?.length > MAX_NUMBER_OF_QUESTIONS}
                />
                <span>Add a new option</span>
              </div>
            </section>
          </div>

          <div className={styles.activeTemplate__controls}>
            <div>
              <StyledSwitch
                name="multiple"
                label="Multiple answer options"
                checked={multiple}
                handleChange={handleSwitchClick}
              />
              <StyledSwitch
                name="required"
                label="Required"
                checked={required}
                handleChange={handleSwitchClick}
              />
              <StyledSwitch
                name="isComment"
                label="Comments available"
                checked={isComment}
                handleChange={handleSwitchClick}
              />
            </div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={isSaveDisabled()}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div
              className={`${styles.inactiveTemplate__controls} ${
                dragIsActive ? styles.hidden : ''
              }`}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              <button>
                <FileCopyIcon onClick={() => onCopy(index)} />
              </button>
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div>
              <b>
                {data.text || ''}
                {required ? '*' : ''}
              </b>
            </div>
            <div className={styles.inactiveOptions}>
              {!!data.options?.length &&
                data.options.map((option, index) => (
                  <p key={index}>
                    {multiple ? (
                      <CheckBoxOutlineBlankIcon className={styles.selectIcon} />
                    ) : (
                      <RadioButtonUncheckedIcon className={styles.selectIcon} />
                    )}
                    {option.text}
                  </p>
                ))}
            </div>
          </div>
        </section>
      )}
    </section>
  );
}

function SelectOption({ index, multiple, data, isInvalid, reportType, onUpdate, onDelete }) {
  const onInput = (event) => {
    let value = event.target.value;
    value = reformatInputValue(value, 500);
    onUpdate(index, { ...data, text: value });
  };
  const createTicketCheck = () => {
    onUpdate(index, {
      ...data,
      createTicket: {
        needCreation: !data.createTicket?.needCreation,
        severity: {}
      }
    });
  };
  const onSelect = (e) => {
    onUpdate(index, {
      ...data,
      createTicket: {
        ...data.createTicket,
        severity: e.value
      }
    });
  };
  return (
    <>
      {multiple ? (
        <CheckBoxOutlineBlankIcon className={styles.selectIcon} />
      ) : (
        <RadioButtonUncheckedIcon className={styles.selectIcon} />
      )}
      <TextField
        placeholder="Enter option text"
        name="text"
        className={styles.textInput}
        variant="outlined"
        value={data.text || ''}
        onChange={onInput}
      />
      {reportType === 'Asset' && (
        <div className={styles.ticketBlock}>
          {!!data.text?.length && (
            <>
              <label>Create a ticket</label>
              <Checkbox
                classes={{ root: styles.checkbox, checked: styles.checked }}
                onChange={createTicketCheck}
                value={data.createTicket?.needCreation}
                checked={!!data.createTicket?.needCreation}
              />
            </>
          )}
          {data.createTicket?.needCreation && (
            <>
              <label>Severity</label>
              <SelectInput
                value={data.createTicket?.severity?.name || ''}
                menuItems={enrichNameToId(severities) || []}
                onSelect={onSelect}
                isInvalid={isInvalid}
              />
              {isInvalid && <span className={styles.validationError}>Field is required</span>}
            </>
          )}
        </div>
      )}
      <DeleteIcon className={styles.deleteIcon} onClick={() => onDelete(index)} />
    </>
  );
}
