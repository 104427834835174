import { useDispatch } from 'react-redux';

import {
  getTrainings,
  setFilter,
  getFilterCriteria,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  createCertification,
  updateCertification,
  deleteCertification,
  clearState
} from 'actions/trainingsActions';

export function useTrainingsActions() {
  const dispatch = useDispatch();

  const getTrainingsAction = (f) => dispatch(getTrainings(f));

  const setFilterAction = (data) => dispatch(setFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const getGroupsAction = (q) => dispatch(getGroups(q));
  const createGroupAction = (data) => dispatch(createGroup(data));
  const updateGroupAction = (data) => dispatch(updateGroup(data));
  const deleteGroupAction = (query) => dispatch(deleteGroup(query));

  const createCertificationAction = (data) => dispatch(createCertification(data));
  const updateCertificationAction = (data) => dispatch(updateCertification(data));
  const deleteCertificationAction = (q) => dispatch(deleteCertification(q));

  const clearStateAction = () => dispatch(clearState());

  return {
    getTrainingsAction,
    getFilterCriteriaAction,
    setFilterAction,
    getGroupsAction,
    createGroupAction,
    updateGroupAction,
    deleteGroupAction,
    createCertificationAction,
    updateCertificationAction,
    deleteCertificationAction,
    clearStateAction
  };
}
