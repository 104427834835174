import { useSelector } from 'react-redux';
import {
  selectAssetRentalStatuses,
  selectAssetsListData,
  selectAssetsListFilterData,
  selectClosedServiceTicketsListData,
  selectClosedTicketsListFilterData,
  selectLocationLooseItems,
  selectLocationLooseItemsFilter,
  selectOpenedTicketsListFilterData,
  selectSelectedLocationDetailsTabData,
  selectServiceTicketsListData,
  selectSingleLocationData
} from '../selectors';

export function useLocationSelector() {
  const closedTicketsList = useSelector(selectClosedServiceTicketsListData());
  const closedTicketsListFilter = useSelector(selectClosedTicketsListFilterData());

  const openedTicketsList = useSelector(selectServiceTicketsListData());
  const openedTicketsListFilter = useSelector(selectOpenedTicketsListFilterData());

  const looseItemsList = useSelector(selectLocationLooseItems());
  const looseItemsFilter = useSelector(selectLocationLooseItemsFilter());

  const assetsList = useSelector(selectAssetsListData());
  const assetsListFilter = useSelector(selectAssetsListFilterData());

  const rentalStatuses = useSelector(selectAssetRentalStatuses());

  const currentLocation = useSelector(selectSingleLocationData());

  const selectedTab = useSelector(selectSelectedLocationDetailsTabData());

  return {
    closedTicketsList,
    closedTicketsListFilter,
    openedTicketsList,
    openedTicketsListFilter,
    looseItemsList,
    looseItemsFilter,
    assetsList,
    assetsListFilter,
    rentalStatuses,
    currentLocation,
    selectedTab
  };
}
