import React from 'react';
import styles from './TicketComponents.module.scss';

export default function Subhead({ required, children }) {
  return (
    <h3 className={styles.subhead}>
      {children}
      {required ? '*' : ''}
    </h3>
  );
}
