import { MAX_TEAM_MEMBERS_ALLOWED } from 'hooks/MicrosoftCalendar/useCalendarActions';

export const NEW_CATEGORY_TYPE = 'NEW_CATEGORY_TYPE';
export const UPDATE_CATEGORY_TYPE = 'UPDATE_CATEGORY_TYPE';

export const NEW_PREFIX_TYPE = 'NEW_PREFIX_TYPE';
export const UPDATE_PREFIX_TYPE = 'UPDATE_CATEGORY_TYPE';

export const NEW_CLIENT_TYPE = 'NEW_CLIENT_TYPE';
export const UPDATE_CLIENT_TYPE = 'UPDATE_CLIENT_TYPE';

export const NEW_LOCATION_TYPE = 'NEW_LOCATION_TYPE';
export const UPDATE_LOCATION_TYPE = 'UPDATE_LOCATION_TYPE';

export const NEW_PROJECT_TYPE = 'NEW_PROJECT_TYPE';
export const UPDATE_PROJECT_TYPE = 'UPDATE_PROJECT_TYPE';
export const SAVE_REPORT_TEMPLATE_TYPE = 'SAVE_REPORT_TEMPLATE_TYPE';
export const PUBLISH_REPORT_TEMPLATE_TYPE = 'PUBLISH_REPORT_TEMPLATE_TYPE';

export const SAVE_REPORT_TYPE = 'SAVE_REPORT_TYPE';
export const SUBMIT_REPORT_TYPE = 'SUBMIT_REPORT_TYPE';
export const REMOVE_REPORT_TYPE = 'REMOVE_REPORT_TYPE';
export const EXIST_REPORT_TYPE = 'EXIST_REPORT_TYPE';
export const REPORT_HAS_BEEN_CLOSED_TYPE = 'REPORT_HAS_BEEN_CLOSED_TYPE';

export const NEW_ASSET_TYPE = 'NEW_ASSET_TYPE';
export const UPDATE_ASSET_TYPE = 'UPDATE_ASSET_TYPE';
export const RESTORE_ASSET_TYPE = 'RESTORE_ASSET_TYPE';

export const NEW_TICKET_TYPE = 'NEW_TICKET_TYPE';
export const UPDATE_TICKET_TYPE = 'UPDATE_TICKET_TYPE';
export const RE_OPEN_TICKET_TYPE = 'RE_OPEN_TICKET_TYPE';
export const CLOSE_TICKET_TYPE = 'CLOSE_TICKET_TYPE';
export const STOP_TICKET_TYPE = 'STOP_TICKET_TYPE';

export const WITHOUT_SAVING_TYPE = 'WITHOUT_SAVING_TYPE';
export const WITHOUT_SAVING_BY_NAV_TYPE = 'WITHOUT_SAVING_BY_NAV_TYPE';
export const DOWNLOAD_FILE_TYPE = 'DOWNLOAD_FILE_TYPE';

export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';

export const LOCATION_UPDATE_TYPE = 'LOCATION_UPDATE_TYPE';
export const FIELDS_UPDATE_TYPE = 'FIELDS_UPDATE_TYPE';
export const ADD_ASSET_TICKET_TYPE = 'ADD_ASSET_TICKET_TYPE';
export const UPDATE_ASSETS_STATUS_TYPE = 'UPDATE_ASSETS_STATUS_TYPE';
export const MARK_INSPECTED_ASSETS_TYPE = 'MARK_INSPECTED_ASSETS_TYPE';
export const VERIFICATE_LOCATIONS_TYPE = 'VERIFICATE_LOCATIONS_TYPE';
export const VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS_TYPE =
  'VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS_TYPE';

export const LOGOUT_TYPE = 'LOGOUT_TYPE';

export const CANCEL_THE_MOVING_LOOSE_ITEMS_TYPE = 'CANCEL_THE_MOVING_LOOSE_ITEMS_TYPE';
export const MOVE_LOOSE_ITEMS_TYPE = 'MOVE_LOOSE_ITEMS_TYPE';

export const UPDATE_TYPE_OF_ASSET_TICKET_TYPE = 'UPDATE_TYPE_OF_ASSET_TICKET_TYPE';
export const CREATE_TYPE_OF_ASSET_TICKET_TYPE = 'CREATE_TYPE_OF_ASSET_TICKET_TYPE';
export const UPDATE_ASSET_TICKET_ROOT_CAUSE_TYPE = 'UPDATE_ASSET_TICKET_ROOT_CAUSE_TYPE';
export const CREATE_ASSET_TICKET_ROOT_CAUSE_TYPE = 'CREATE_ASSET_TICKET_ROOT_CAUSE_TYPE';

export const UPDATE_LOOSE_ITEM_TYPE = 'UPDATE_LOOSE_ITEM_TYPE';

export const NEW_TENT_CONFIGURATION_TYPE = 'NEW_TENT_CONFIGURATION_TYPE';
export const UPDATE_TENT_CONFIGURATION_TYPE = 'UPDATE_TENT_CONFIGURATION_TYPE';

export const CREATE_ASSEMBLE_TYPE = 'CREATE_ASSEMBLE_TYPE';
export const REMOVE_ASSEMBLE_TYPE = 'REMOVE_ASSEMBLE_TYPE';

export const NEW_PICKLIST_TYPE = 'NEW_PICKLIST_TYPE';
export const UPDATE_PICKLIST_TYPE = 'UPDATE_PICKLIST_TYPE';
export const PICKLIST_ASSETS_LOCATION_CHECK_TYPE = 'PICKLIST_ASSETS_LOCATION_CHECK_TYPE';
export const DELETE_PICKLIST_ASSET_TYPE = 'DELETE_PICKLIST_ASSET_TYPE';
export const DELETE_ASSETS_FROM_THE_PICKLIST_TYPE = 'DELETE_ASSETS_FROM_THE_PICKLIST_TYPE';
export const DELETE_FILE_FROM_PICKLIST_ASSET_TYPE = 'DELETE_FILE_FROM_PICKLIST_ASSET_TYPE';
export const PICKLIST_COMPLETED_STATUS_TYPE = 'PICKLIST_COMPLETED_STATUS_TYPE';

export const NEW_LEAVELIST_TYPE = 'NEW_LEAVELIST_TYPE';
export const DELETE_ASSETS_FROM_THE_LEAVE_LIST_TYPE = 'DELETE_ASSETS_FROM_THE_LEAVE_LIST_TYPE';
export const DELETE_LEAVELIST_ASSET_TYPE = 'DELETE_LEAVELIST_ASSET_TYPE';
export const LEAVELIST_COMPLETED_STATUS_TYPE = 'LEAVELIST_COMPLETED_STATUS_TYPE';

export const CREATE_TEAM_MEMBER_TYPE = 'CREATE_TEAM_MEMBER_TYPE';
export const UPDATE_TEAM_MEMBER_TYPE = 'UPDATE_TEAM_MEMBER_TYPE';
export const DELETE_TEAM_MEMBER_TYPE = 'DELETE_TEAM_MEMBER_TYPE';
export const RESTORE_TEAM_MEMBER_TYPE = 'RESTORE_TEAM_MEMBER_TYPE';
export const RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER_TYPE =
  'RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER_TYPE';
export const DELETE_TEAM_MEMBER_PARAMETER_TYPE = 'DELETE_TEAM_MEMBER_PARAMETER_TYPE';

export const CREATE_NEW_ITEM_TYPE = 'CREATE_NEW_ITEM_TYPE';
export const THE_LOOSE_ITEM_ALREADY_EXIST_TYPE = 'THE_LOOSE_ITEM_ALREADY_EXIST_TYPE';

export const CREATE_REQUEST_ORDER_TYPE = 'CREATE_REQUEST_ORDER_TYPE';
export const DELETE_REQUEST_ORDER_TYPE = 'DELETE_REQUEST_ORDER_TYPE';
export const APPROVE_CANCELLING_REQUEST_ORDER_TYPE = 'APPROVE_CANCELLING_REQUEST_ORDER_TYPE';
export const DELETE_REQUEST_ORDER_ENTITY_TYPE = 'DELETE_REQUEST_ORDER_ENTITY_TYPE';

export const CANCEL_LAST_OPERATION_TYPE = 'CANCEL_LAST_OPERATION_TYPE';
export const SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR_TYPE =
  'SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR_TYPE';

export const ADD_ITEMS_TO_PICKLIST_TYPE = 'ADD_ITEMS_TO_PICKLIST_TYPE';
export const EDIT_ITEM_OF_PICKLIST_TYPE = 'EDIT_ITEM_OF_PICKLIST_TYPE';

export const ADD_LOOSE_ITEMS_TO_PICKLIST_TYPE = 'ADD_LOOSE_ITEMS_TO_PICKLIST_TYPE';
export const ADD_LOOSE_ITEMS_TO_LEAVELIST_TYPE = 'ADD_LOOSE_ITEMS_TO_LEAVELIST_TYPE';
export const EDIT_ITEM_OF_ENTITY_TYPE = 'EDIT_ITEM_OF_ENTITY_TYPE';

export const CREATE_BOL_TYPE = 'CREATE_BOL_TYPE';
export const UPDATE_BOL_TYPE = 'UPDATE_BOL_TYPE';
export const DELETE_BOL_TYPE = 'DELETE_BOL_TYPE';
export const DELETE_BOL_SIGNATURE_TYPE = 'DELETE_BOL_SIGNATURE_TYPE';
export const SOME_ITEMS_DONT_HAVE_WEIGHT_TYPE = 'SOME_ITEMS_DONT_HAVE_WEIGHT_TYPE';

export const DELETE_SIGNATURE_TYPE = 'DELETE_SIGNATURE_TYPE';

export const ADD_BOM_TO_PICKLIST_TYPE = 'ADD_BOM_TO_PICKLIST_TYPE';

export const PICKLIST_ITEMS_LOCATION_CHECK_TYPE = 'PICKLIST_ITEMS_LOCATION_CHECK_TYPE';

export const DELETE_NOTIFICATION_RECIPIENT_TYPE = 'DELETE_NOTIFICATION_RECIPIENT_TYPE';

export const DELETE_CERTIFICATION_TYPE = 'DELETE_CERTIFICATION_TYPE';
export const CONFIRM_DELETE_CERTIFICATION_TYPE = 'CONFIRM_DELETE_CERTIFICATION_TYPE';

export const CREATE_BOL_LOG_TYPE = 'CREATE_BOL_LOG_TYPE';
export const UPDATE_BOL_LOG_TYPE = 'UPDATE_BOL_LOG_TYPE';

export const UPLOAD_BOL_LOGS_TYPE = 'UPLOAD_BOL_LOGS_TYPE';

export const ADD_ASSETS_PICKLIST_TYPE = 'ADD_ASSETS_PICKLIST_TYPE';
export const ADD_ASSETS_LEAVELIST_TYPE = 'ADD_ASSETS_LEAVELIST_TYPE';

export const CREATE_NEW_CATEGORY = {
  type: NEW_CATEGORY_TYPE,
  title: 'Do you want to create a new category?'
};
export const UPDATE_CATEGORY = {
  type: UPDATE_CATEGORY_TYPE,
  title: 'Do you want to update a category?'
};
export const DELETE_CATEGORY = {
  title:
    'Are you sure you want to delete this category? All prefixes and assets that belong to this category will be deleted too.'
};

export const CREATE_NEW_PREFIX = {
  type: NEW_PREFIX_TYPE,
  title: 'Do you want to create a new prefix?',
  isOpened: true
};
export const UPDATE_PREFIX = {
  type: UPDATE_PREFIX_TYPE,
  title: 'Do you want to update a prefix?',
  isOpened: true
};
export const DELETE_PREFIX = {
  title:
    'Are you sure you want to delete this prefix? All assets that belong to this prefix will be deleted too.'
};

export const CREATE_NEW_CLIENT = {
  type: NEW_CLIENT_TYPE,
  title: 'Do you want to create a client?'
};
export const UPDATE_CLIENT = {
  type: UPDATE_CLIENT_TYPE,
  title: 'Do you want to update a client?'
};
export const DELETE_CLIENT = {
  title: 'Are you sure you want to delete this client?'
};

export const CREATE_NEW_LOCATION = {
  type: NEW_LOCATION_TYPE,
  title: 'Do you want to create a location?',
  isOpened: true
};
export const UPDATE_LOCATION = {
  type: UPDATE_LOCATION_TYPE,
  title: 'Do you want to update a location?',

  isOpened: true
};
export const DELETE_LOCATION = {
  title: 'Are you sure you want to delete this location?',
  isOpened: true
};

export const CREATE_NEW_PROJECT = {
  type: NEW_PROJECT_TYPE,
  title: 'Do you want to create a project?'
};
export const UPDATE_PROJECT = {
  type: UPDATE_PROJECT_TYPE,
  title: 'Do you want to update a project?'
};
export const DELETE_PROJECT = {
  title: 'Are you sure you want to delete this project?'
};

export const SAVE_REPORT = {
  type: SAVE_REPORT_TYPE,
  title: 'Are you sure you want to save this report?'
};

const WARNING_PREFIX = 'The report is being edited by other users. ';
export const SUBMIT_REPORT = (hasConnectedUsers) => ({
  type: SUBMIT_REPORT_TYPE,
  title: `${hasConnectedUsers ? WARNING_PREFIX : ''}Are you sure you want to submit this report?`
});
export const REMOVE_REPORT = (hasConnectedUsers) => ({
  type: REMOVE_REPORT_TYPE,
  title: `${hasConnectedUsers ? WARNING_PREFIX : ''}Are you sure you want to remove the report?`
});
export const REPORT_HAS_BEEN_CLOSED = {
  type: REPORT_HAS_BEEN_CLOSED_TYPE,
  hideCancelButton: true,
  agreeText: 'Ok'
};
export const EXIST_REPORT = (userName) => {
  return {
    type: EXIST_REPORT_TYPE,
    title: `The report for selected location is already created and edited by user ${userName}. Do you want to create a new report with the same location or edit an existing one?`,
    agreeText: 'Create New',
    cancelText: 'Edit Existing'
  };
};
export const SAVE_REPORT_TEMPLATE = {
  type: SAVE_REPORT_TEMPLATE_TYPE,
  title: 'Do you want to save the template?'
};
export const PUBLISH_REPORT_TEMPLATE = {
  type: PUBLISH_REPORT_TEMPLATE_TYPE,
  title: 'Do you want to publish the template?'
};
export const DELETE_REPORT_TEMPLATE = {
  title: 'Are you sure you want to delete this report template?'
};
export const RESET_FILTER_PARAMS = {
  title: 'Do you want to reset the parameters?',
  isOpened: true
};

export const CREATE_NEW_ASSET = {
  type: NEW_ASSET_TYPE,
  title: 'Do you want to create an asset?'
};
export const UPDATE_ASSET = {
  type: UPDATE_ASSET_TYPE,
  title: 'Do you want to update an asset?'
};
export const DELETE_ASSET = {
  title: 'Are you sure you want to delete this asset?',
  isOpened: true
};
export const RESTORE_ASSET = {
  type: RESTORE_ASSET_TYPE,
  title: 'Do you want to restore the asset?',
  isOpened: true
};

export const UPDATE_USER = {
  type: UPDATE_USER_TYPE,
  title: 'Do you want to update a user?',
  isOpened: true
};
export const DELETE_USER = {
  title: 'Are you sure you want to delete this user?'
};

export const CREATE_NEW_TICKET = {
  type: NEW_TICKET_TYPE,
  title: 'Do you want to create a ticket?',
  isOpened: true
};
export const UPDATE_TICKET = {
  type: UPDATE_TICKET_TYPE,
  title: 'Do you want to update this ticket?',
  isOpened: true
};
export const DELETE_TICKET = {
  title: 'Are you sure you want to delete this ticket?',
  isOpened: true
};

export const RE_OPEN_TICKET = {
  type: RE_OPEN_TICKET_TYPE,
  title: 'Do you want to re-open this ticket?',
  isOpened: true
};

export const CLOSE_TICKET = {
  type: CLOSE_TICKET_TYPE,
  title: 'Do you want to close this ticket?',
  isOpened: true
};
export const STOP_TICKET = {
  type: STOP_TICKET_TYPE,
  title: 'Do you want to stop reopening of the ticket?',
  isOpened: true
};

export const LOCATION_UPDATE = {
  type: LOCATION_UPDATE_TYPE,
  title: 'Do you want to update assets location?'
};

export const FIELDS_UPDATE = {
  type: FIELDS_UPDATE_TYPE,
  title: 'Do you want to update assets field(s)?'
};

export const MARK_INSPECTED_ASSETS = {
  type: MARK_INSPECTED_ASSETS_TYPE,
  title: 'Do you want to mark assets as inspected?'
};

export const VERIFICATE_LOCATIONS = {
  type: VERIFICATE_LOCATIONS_TYPE,
  title: 'Do you want to verify the location?'
};
export const VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS = {
  type: VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS_TYPE
};

export const ADD_ASSET_TICKET = {
  type: ADD_ASSET_TICKET_TYPE,
  title: 'Do you want to create tickets?'
};
export const UPDATE_ASSETS_STATUS = {
  type: UPDATE_ASSETS_STATUS_TYPE,
  title: 'Do you want to update asset(s) status?',
  isOpened: true
};

export const CANCEL_THE_MOVING_LOOSE_ITEMS = {
  type: CANCEL_THE_MOVING_LOOSE_ITEMS_TYPE,
  title: 'Do you want to cancel the moving of the loose items?'
};
export const MOVE_LOOSE_ITEMS = {
  type: MOVE_LOOSE_ITEMS_TYPE,
  title: "The loose items' location will be changed. Are you sure you want to move the loose items?"
};

export const EXIT_WITHOUT_SAVING = {
  type: WITHOUT_SAVING_TYPE,
  title: 'Do you want to go back without saving?',
  isOpened: true
};

export const EXIT_WITHOUT_SAVING_BY_NAV = {
  type: WITHOUT_SAVING_BY_NAV_TYPE,
  title: 'Do you want to go back without saving?'
};

export const DOWNLOAD_FILE = {
  type: DOWNLOAD_FILE_TYPE,
  title: 'Are you sure you want to download the file?',
  agreeText: 'Download'
};

export const DELETE_TABLE = {
  title: 'Are you sure you want to delete the table?'
};

export const LOGOUT = {
  type: LOGOUT_TYPE,
  title: 'Are you sure you want to logout?'
};

export const UPDATE_LOOSE_ITEM = {
  type: UPDATE_LOOSE_ITEM_TYPE,
  title: 'Do you want to update a loose item?'
};
export const DELETE_LOOSE_ITEM = {
  title: 'Are you sure you want to delete this item?'
};

export const CREATE_NEW_TENT_CONFIGURATION = {
  type: NEW_TENT_CONFIGURATION_TYPE,
  title: 'Do you want to create a configuration?'
};
export const UPDATE_TENT_CONFIGURATION = {
  type: UPDATE_TENT_CONFIGURATION_TYPE,
  title: 'Do you want to update a configuration?'
};
export const DELETE_TENT_CONFIGURATION = {
  title: 'Are you sure you want to delete this configuration?'
};

export const CREATE_ASSEMBLE = {
  type: CREATE_ASSEMBLE_TYPE,
  title: 'Do you want to pack an assemble?'
};
export const REMOVE_ASSEMBLE = {
  type: REMOVE_ASSEMBLE_TYPE,
  title: 'Do you want to remove an assemble?'
};

export const CREATE_NEW_PICKLIST = {
  type: NEW_PICKLIST_TYPE,
  title: 'Do you want to create a picklist?'
};
export const UPDATE_PICKLIST = {
  type: UPDATE_PICKLIST_TYPE,
  title: 'Do you want to update a picklist?'
};
export const PICKLIST_ASSETS_LOCATION_CHECK = {
  type: PICKLIST_ASSETS_LOCATION_CHECK_TYPE,
  isOpened: true
};
export const DELETE_PICKLIST = {
  title: 'Are you sure you want to delete this picklist?'
};
export const DELETE_PICKLIST_ASSET = {
  type: DELETE_PICKLIST_ASSET_TYPE,
  title: 'Are you sure you want to delete this item from the picklist?'
};
export const DELETE_ASSETS_FROM_THE_PICKLIST = {
  type: DELETE_ASSETS_FROM_THE_PICKLIST_TYPE,
  title: 'Are you sure you want to delete the selected item(s) from the picklist?'
};
export const DELETE_FILE_FROM_PICKLIST_ASSET = {
  type: DELETE_FILE_FROM_PICKLIST_ASSET_TYPE,
  title: 'Do you want to delete the attachment?'
};
export const PICKLIST_COMPLETED_STATUS = {
  type: PICKLIST_COMPLETED_STATUS_TYPE,
  title: 'Do you want to complete the picklist?'
};

export const CREATE_LEAVELIST = {
  type: NEW_LEAVELIST_TYPE
};
export const DELETE_LEAVELIST = {
  title: 'Are you sure you want to delete this leavelist?'
};
export const DELETE_ASSETS_FROM_THE_LEAVE_LIST = {
  type: DELETE_ASSETS_FROM_THE_LEAVE_LIST_TYPE,
  title: 'Are you sure you want to delete the selected item(s) from the leavelist?'
};
export const DELETE_LEAVELIST_ASSET = {
  type: DELETE_LEAVELIST_ASSET_TYPE,
  title: 'Are you sure you want to delete this item from the leavelist?'
};
export const LEAVELIST_COMPLETED_STATUS = {
  type: LEAVELIST_COMPLETED_STATUS_TYPE,
  title: 'Do you want to complete the leavelist?'
};

export const CREATE_TEAM_MEMBER = {
  type: CREATE_TEAM_MEMBER_TYPE,
  title: 'Are you sure you want to create the team member?',
  isOpened: true
};
export const UPDATE_TEAM_MEMBER = {
  type: UPDATE_TEAM_MEMBER_TYPE,
  title: 'Are you sure you want to update the team member?',
  isOpened: true
};
export const DELETE_TEAM_MEMBER = {
  type: DELETE_TEAM_MEMBER_TYPE,
  title: 'Are you sure you want to delete this team member?'
};
export const DELETE_TEAM_MEMBER_PARAMETER = {
  type: DELETE_TEAM_MEMBER_PARAMETER_TYPE,
  title:
    'This parameter is used in the other team member profiles. Are you sure you want to delete it from all profiles?'
};
export const RESTORE_TEAM_MEMBER = {
  type: RESTORE_TEAM_MEMBER_TYPE,
  title: 'Are you sure you want to restore this team member?'
};
export const RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER = {
  type: RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER_TYPE,
  title: 'Team Member with this email was removed. Do you want to restore them?',
  isOpened: true
};

export const CREATE_NEW_ITEM = {
  type: CREATE_NEW_ITEM_TYPE,
  title: 'Do you want to create a new item?'
};
export const THE_LOOSE_ITEM_ALREADY_EXIST = {
  type: THE_LOOSE_ITEM_ALREADY_EXIST_TYPE,
  title:
    'The loose item with such name already exists. Do you want to add a set quantity of new item to existed one?',
  isOpened: true
};

export const CREATE_REQUEST_ORDER = {
  type: CREATE_REQUEST_ORDER_TYPE,
  title: 'Do you want to create a new request?',
  isOpened: true
};
export const DELETE_REQUEST_ORDER = {
  type: DELETE_REQUEST_ORDER_TYPE,
  title: 'Are you sure you want to cancel this request?'
};
export const APPROVE_CANCELLING_REQUEST_ORDER = {
  type: APPROVE_CANCELLING_REQUEST_ORDER_TYPE,
  title: 'Do you want to close the request?'
};
export const DELETE_REQUEST_ORDER_FROM_SUMMARY = {
  title: 'Do you want to delete the request?'
};
export const DELETE_REQUEST_ORDER_ENTITY = {
  type: DELETE_REQUEST_ORDER_ENTITY_TYPE,
  title: 'Do you want to delete entity?'
};
export const SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR = (count) => ({
  type: SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR_TYPE,
  title:
    count > MAX_TEAM_MEMBERS_ALLOWED
      ? `Do you want to export team members' statuses to your Microsoft calendar? The events of the first ${MAX_TEAM_MEMBERS_ALLOWED} team members will be exported in total.`
      : "Do you want to export team members' statuses to your Microsoft calendar?"
});
export const CANCEL_LAST_OPERATION = {
  type: CANCEL_LAST_OPERATION_TYPE,
  title:
    'The system will cancel the last schedule updates. Do you want to cancel previous scheduling?'
};

export const DELETE_EMPLOYMENT_STATUS = {
  title: 'Do you want to delete the status?'
};
export const ADD_ITEMS_TO_PICKLIST = {
  type: ADD_ITEMS_TO_PICKLIST_TYPE
};
export const EDIT_ITEMS_OF_PICKLIST = {
  type: EDIT_ITEM_OF_PICKLIST_TYPE
};

export const PICKLIST_ITEMS_LOCATION_CHECK = {
  type: PICKLIST_ITEMS_LOCATION_CHECK_TYPE,
  isOpened: true
};

export const CREATE_BILL_OF_LADING = {
  type: CREATE_BOL_TYPE,
  title: 'Do you want to create a Bill of Lading?'
};
export const UPDATE_BILL_OF_LADING = {
  type: UPDATE_BOL_TYPE,
  title: 'Do you want to update a Bill of Lading?'
};
export const DELETE_BILL_OF_LADING = {
  type: DELETE_BOL_TYPE,
  title: 'Are you sure to remove this Bill of Lading?'
};
export const DELETE_BOL_SIGNATURE = {
  type: DELETE_BOL_SIGNATURE_TYPE,
  title: 'Do you want to delete the signature?'
};
export const SOME_ITEMS_DONT_HAVE_WEIGHT = {
  type: SOME_ITEMS_DONT_HAVE_WEIGHT_TYPE,
  title: `Some item(s) doesn’t have weight. Do you want to proceed?`
};

export const DELETE_LOOSE_ITEM_CATEGORY = {
  title:
    'This category can be used in other loose items. Are you sure you want to delete it from all loose items?',
  isOpened: true
};

export const DELETE_SIGNATURE = {
  type: DELETE_SIGNATURE_TYPE,
  title: 'Do you want to delete the signature?',
  isOpened: true
};

export const ADD_BOM_TO_PICKLIST = {
  type: ADD_BOM_TO_PICKLIST_TYPE,
  isOpened: true
};

export const UPDATE_LOOSE_ITEMS_BATCH_UPDATES = {
  title: 'Do you want to update loose items?',
  isOpened: true
};

export const UPDATE_BATCH_OF_ASSETS_UPDATES = {
  title: 'Do you want to create Batch Assets with selected parameters?',
  isOpened: true
};

export const ADD_NOTIFICATION_RECIPIENT = {
  title: 'Do you want to add Notification Recipient?',
  isOpened: true
};
export const EDIT_NOTIFICATION_RECIPIENT = {
  title: 'Do you want to edit Notification Recipient?',
  isOpened: true
};

export const DELETE_NOTIFICATION_RECIPIENT = {
  type: DELETE_NOTIFICATION_RECIPIENT_TYPE,
  title: 'Do you want to delete Notification Recipient?',
  isOpened: true
};

export const DELETE_TRAINING_GROUP = {
  title: 'Do you want to delete Group?'
};

export const DELETE_CERTIFICATION = {
  type: DELETE_CERTIFICATION_TYPE,
  title: 'Do you want to delete certification?'
};
export const CONFIRM_DELETE_CERTIFICATION = {
  type: CONFIRM_DELETE_CERTIFICATION_TYPE,
  title:
    'This parameter is used in the other team member profiles. Are you sure you want to delete it from all profiles?'
};

export const CREATE_ASSET_TICKET_TYPE = {
  type: CREATE_TYPE_OF_ASSET_TICKET_TYPE,
  title: 'Do you want to create a ticket type?',
  isOpened: true
};
export const UPDATE_ASSET_TICKET_TYPE = {
  type: UPDATE_TYPE_OF_ASSET_TICKET_TYPE,
  title: 'Do you want to update a ticket type?',
  isOpened: true
};

export const CREATE_ASSET_TICKET_ROOT_CAUSE = {
  type: CREATE_ASSET_TICKET_ROOT_CAUSE_TYPE,
  title: 'Do you want to create a ticket root cause?',
  isOpened: true
};
export const UPDATE_ASSET_TICKET_ROOT_CAUSE = {
  type: UPDATE_ASSET_TICKET_ROOT_CAUSE_TYPE,
  title: 'Do you want to update a ticket root cause?',
  isOpened: true
};

export const CREATE_BOL_LOG = {
  type: CREATE_BOL_LOG_TYPE,
  title: `Do you want to add BOL's log(s)?`,
  isOpened: true
};
export const UPDATE_BOL_LOG = {
  type: UPDATE_BOL_LOG_TYPE,
  title: `Do you want to update BOL's log(s)?`,
  isOpened: true
};
export const DELETE_BOL_LOG = {
  title: 'Do you want to delete the BOL’s log?'
};

export const UPLOAD_BOL_LOGS = {
  type: UPLOAD_BOL_LOGS_TYPE,
  title: 'Do you want to add BOL’s log?',
  isOpened: true
};

export const ADD_ASSETS_PICKLIST = {
  type: ADD_ASSETS_PICKLIST_TYPE
};
export const ADD_ASSETS_LEAVELIST = {
  type: ADD_ASSETS_LEAVELIST_TYPE
};

export const DELETE_PREFIX_FIELD = {
  title: 'The selected field has value in the Asset(s). Do you want to delete it?'
};
