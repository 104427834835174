import React from 'react';
import styles from './Counting.module.scss';

import CountingRow from './CountingRow';

export default function Counting({ counts }) {
  return counts?.length ? (
    <div className={styles.counting}>
      {counts.map(({ color, label, count, boldLabel, link }, index) => (
        <CountingRow
          key={index}
          color={color}
          label={label}
          count={count}
          boldLabel={boldLabel}
          link={link}
        />
      ))}
    </div>
  ) : null;
}
