import React from 'react';
import styles from './CustomDetails.module.scss';

import TitleSection from './TitleSection';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import Signature from 'components/Signature/Signature/Signature';

export default function SignatureSection({ field, index, onSave, onBlur, onFocus }) {
  const { data } = field;
  const { required, textFields } = data;

  return (
    <div className={styles.signature}>
      <TitleSection>
        Signature
        {required && '*'}
      </TitleSection>
      {!!textFields?.length && (
        <div className={styles.fields}>
          {textFields.map(({ text }, fieldIndex) => (
            <div className={styles.fields__input} key={fieldIndex}>
              <label>{text || ''}</label>
              <FormInputText
                name={`generalReportFields[${index}].data.textFields[${fieldIndex}].value`}
                options={{ max: 500, onBlur, onFocus }}
              />
            </div>
          ))}
          <div className={styles.fields__input}>
            <Signature
              name={`generalReportFields[${index}].data.resource`}
              onApplyTriggered={onSave}
            />
          </div>
        </div>
      )}
    </div>
  );
}
