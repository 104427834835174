import React from 'react';
import styles from './Form.module.scss';

import { useFormContext } from 'react-hook-form';

import { General } from './Blocks/General';
import { Files } from './Blocks/Files';
import { Location } from './Blocks/Location';
import { Notes } from './Blocks/Notes';
import { Contents } from './Blocks/Contents';
import { License } from './Blocks/License';
import { Fields } from './Blocks/Fields';

const FORBIDDEN = ['Containable'];
const AVAILABLE_FOR_LICENSE = ['Vehicle', 'Trailer'];

export function Form() {
  const { watch } = useFormContext();

  const prefixWatcher = watch('assetPrefix');
  const fieldsWatcher = watch('assetFields');

  const isContentAllowed = !FORBIDDEN.includes(prefixWatcher?.prefixType?.name);
  const isLicenseAllowed = AVAILABLE_FOR_LICENSE.includes(prefixWatcher?.prefixType?.name);

  return (
    <div className={styles.content}>
      <General />
      {!!fieldsWatcher?.length && <Fields />}
      {isLicenseAllowed && <License />}
      <Files />
      <Location />
      {isContentAllowed && <Contents />}
      <Notes />
    </div>
  );
}
