import React, { useEffect, useState } from 'react';
import styles from './EditCapacitiesPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';

import { useDispatch } from 'react-redux';

import {
  getCapacitiesCreationForm,
  getTeamMemberCapacities,
  updateTeamMemberCapacities
} from 'actions/teamMemberActions';

import AddCapacityField from './AddCapacityField';
import FieldsContainer from './FieldsContainer';

import { pluck, sortByParam } from 'helpers/AppHelpers';

export function EditCapacitiesPopup({ open, setOpen, onApply }) {
  const dispatch = useDispatch();

  const closePopup = () => setOpen(false);

  const [fields, setFields] = useState([]);
  const [capacities, setCapacities] = useState([]);

  useEffect(() => {
    if (!open) return;

    dispatch(getCapacitiesCreationForm()).then((list) => {
      if (!!list && Array.isArray(list)) {
        dispatch(getTeamMemberCapacities()).then((selected) => {
          if (!!selected && Array.isArray(selected)) {
            setFields(selected);
            setCapacities(list);
          }
        });
      }
    });
  }, [open]);

  const handleApply = () => {
    closePopup();
    dispatch(updateTeamMemberCapacities(fields)).then(() => {
      onApply && onApply();
      closePopup();
    });
  };

  const addCapacities = (items) => {
    const selected = [...fields, ...items];
    setFields(selected);

    const selectedIds = pluck(selected, 'id');
    setCapacities(capacities.filter(({ id }) => !selectedIds.includes(id)));
  };

  const handleRemoveCapacity = (field) => {
    const sortedCapacities = sortByParam([...capacities, field], 'name');
    setCapacities(sortedCapacities);
  };

  return (
    !!fields?.length && (
      <DialogWrapper open={open} onClose={closePopup}>
        <div className={styles.popup}>
          <DialogHeader title="Edit Capacity Displaying" onClose={closePopup} />

          <FieldsContainer fields={fields} setFields={setFields} onRemove={handleRemoveCapacity} />

          <AddCapacityField options={capacities} onAdd={addCapacities} />

          <div className={styles.footer}>
            <MainButton text="Cancel" action={closePopup} type="secondary" />
            <MainButton text="Apply" action={handleApply} type="primary" />
          </div>
        </div>
      </DialogWrapper>
    )
  );
}
