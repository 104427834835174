import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import LocationEditWrapper from './components/LocationEditWrapper';
import LocationGeneralDetails from './components/General';
import LocationPositionDetails from './components/Position';
import LocationFiles from './components/Files';
import LocationNotes from './components/Notes';
import LocationProjectInformation from './components/ProjectInformation';
import LocationSublocations from './components/Sublocations';
import DisplayOnDailyCheckbox from './components/DisplayOnDailyCheckbox';

export default function LocationEdit() {
  const methods = useForm({
    defaultValues: {
      active: true,
      addressAndCoordinatesNotMatched: false,
      address1: '',
      address2: '',
      city: '',
      description: '',
      hasFoodService: false,
      isBaseCamp: false,
      latitude: null,
      locationJobNumber: '',
      longitude: null,
      notes: '',
      onSiteReport: true,
      pocName: '',
      pocNumber: '',
      project: null,
      reportRecipients: [],
      reorderTriggerRecipients: [],
      resources: [],
      siteCode: '',
      siteType: {},
      state: null,
      status: {},
      sublocations: [],
      ticketRecipients: [],
      zip: ''
    }
  });

  return (
    <FormProvider {...methods}>
      <LocationEditWrapper>
        <LocationGeneralDetails />
        <LocationPositionDetails />
        <LocationSublocations />
        <LocationFiles />
        <LocationNotes />
        <DisplayOnDailyCheckbox />
        <LocationProjectInformation />
      </LocationEditWrapper>
    </FormProvider>
  );
}
