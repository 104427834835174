import React from 'react';
import styles from './StatusMarker.module.scss';
import { emptyValue } from 'helpers/AppHelpers';

export default function StatusMarker({ color }) {
  return (
    <div style={{ backgroundColor: color }} className={styles.marker}>
      {emptyValue}
    </div>
  );
}
