import { enrichNameToId, getRandomId } from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id || obj?.id === 0) {
      return {
        ...obj,
        key: key,
        displayName: obj[name] || '',
        chipId: getRandomId()
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const { billOfLadings, locationsWithSublocations, picklistAssetStatuses, sublocations } = options;

  let chipsArray = [];

  if (inputFilter?.billOfLadingIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(billOfLadings, inputFilter.billOfLadingIds, 'billOfLading', 'freightBill')
    );
  }
  if (inputFilter?.originLocationIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        locationsWithSublocations,
        inputFilter['originLocationIds'],
        'location',
        'siteCode'
      )
    );
  }
  if (inputFilter?.originLocationJobNumberIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        locationsWithSublocations,
        inputFilter['originLocationJobNumberIds'],
        'jobNumber',
        'locationJobNumber'
      )
    );
  }
  if (inputFilter?.originSublocationIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(sublocations, inputFilter['originSublocationIds'], 'sublocation', 'name')
    );
  }
  if (inputFilter?.picklistAssetStatusNames?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        enrichNameToId(picklistAssetStatuses),
        inputFilter['picklistAssetStatusNames'],
        'status',
        'displayName'
      )
    );
  }
  if (inputFilter?.trackAndTrailerAssetDrCodes?.length) {
    const drCodes = inputFilter.trackAndTrailerAssetDrCodes.map((str) => ({
      key: 'drCode',
      displayName: str,
      chipId: getRandomId()
    }));

    chipsArray = chipsArray.concat(drCodes);
  }

  let isTwoDatesSelected = false;
  if (inputFilter?.pickUpDateFrom && inputFilter?.pickUpDateTo) {
    isTwoDatesSelected = true;
    chipsArray.push({
      key: 'dateFromTo',
      displayName: inputFilter.pickUpDateFrom + ' - ' + inputFilter.pickUpDateTo,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.pickUpDateFrom && !isTwoDatesSelected) {
    chipsArray.push({
      key: 'pickUpDateFrom',
      displayName: inputFilter.pickUpDateFrom,
      chipId: getRandomId()
    });
  }
  if (inputFilter?.pickUpDateTo && !isTwoDatesSelected) {
    chipsArray.push({
      key: 'pickUpDateTo',
      displayName: inputFilter.pickUpDateTo,
      chipId: getRandomId()
    });
  }

  if (inputFilter?.isAssetsOnly) {
    chipsArray.push({
      key: 'isAssetsOnly',
      displayName: 'Show Assets only',
      chipId: getRandomId()
    });
  }
  if (inputFilter?.isLooseItemsOnly) {
    chipsArray.push({
      key: 'isLooseItemsOnly',
      displayName: 'Show Loose Items only',
      chipId: getRandomId()
    });
  }

  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'billOfLading':
      return {
        ...values,
        billOfLadingIds: values.billOfLadingIds.filter((id) => id !== chip.id)
      };
    case 'location':
      return {
        ...values,
        originLocationIds: values.originLocationIds.filter((id) => id !== chip.id)
      };
    case 'jobNumber':
      return {
        ...values,
        originLocationJobNumberIds: values.originLocationJobNumberIds.filter((id) => id !== chip.id)
      };
    case 'sublocation':
      return {
        ...values,
        originSublocationIds: values.originSublocationIds.filter((id) => id !== chip.id)
      };
    case 'status':
      return {
        ...values,
        picklistAssetStatusNames: values.picklistAssetStatusNames.filter((id) => id !== chip.id)
      };
    case 'drCode':
      return {
        ...values,
        trackAndTrailerAssetDrCodes: values.trackAndTrailerAssetDrCodes.filter(
          (str) => str !== chip.displayName
        )
      };
    case 'dateFromTo':
      return { ...values, pickUpDateFrom: null, pickUpDateTo: null };
    case 'pickUpDateFrom':
      return { ...values, pickUpDateFrom: null };
    case 'pickUpDateTo':
      return { ...values, pickUpDateTo: null };
    case 'isAssetsOnly':
      return { ...values, isAssetsOnly: false };
    case 'isLooseItemsOnly':
      return { ...values, isLooseItemsOnly: false };
    default:
      break;
  }
};
