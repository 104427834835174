import React from 'react';
import styles from '../components.module.scss';

import { dateTimeFormat, formatDate, getProjectName, getUserFullName } from 'helpers/AppHelpers';

import { usePicklistSelector } from 'hooks/Picklist';

import DetailedRow from 'components/DetailsComponents/DetailedRow';

export default function General() {
  const { currentPicklist } = usePicklistSelector();

  const {
    location,
    sublocation,
    project,
    pocName,
    pocNumber,
    status,
    createdByUser,
    createdAtUtc
  } = currentPicklist;

  const data = [
    { id: 1, label: 'Location', value: location?.siteCode || '' },
    { id: 2, label: 'Sublocation', value: sublocation?.name || '' },
    { id: 3, label: 'Project Number', value: getProjectName(project) },
    { id: 4, label: 'POC Name', value: pocName || '' },
    { id: 5, label: 'POC Number', value: pocNumber || '' },
    { id: 6, label: 'Status', value: status?.displayName || '' },
    { id: 7, label: 'Created by', value: getUserFullName(createdByUser) },
    { id: 8, label: 'Created', value: createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : '' }
  ];

  return (
    <section className={styles.general}>
      {data.map(({ id, label, value }) => (
        <DetailedRow key={id} label={label} value={value} disableFlex />
      ))}
    </section>
  );
}
