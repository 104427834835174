import React, { useCallback, useMemo } from 'react';
import styles from './ListItemBase.module.scss';

import { useLocation } from 'react-router-dom';

import { IconButton } from '@material-ui/core';

import openInNewTabIcon from 'assets/images/openInNewTabIcon.svg';

import { BASE_ADDRESS } from 'api/api';

import { ListItemBase } from './ListItemBase';

export function ListItem({ icon, label, to, paths, inCollapse, onClick }) {
  const location = useLocation();
  const currentPath = `/${location.pathname.split('/')[1]}`;

  const openNewTab = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(BASE_ADDRESS + to, '_blank');
    },
    [to]
  );

  const handleItemClick = () => onClick(to);

  const isActive = useMemo(() => {
    return paths.some((path) => path === currentPath);
  }, [currentPath, paths]);

  return (
    <ListItemBase
      icon={icon}
      label={label}
      isActive={isActive}
      onClick={handleItemClick}
      isInCollapse={inCollapse}>
      <IconButton className={styles.item__controls_btn} onClick={openNewTab}>
        <img src={openInNewTabIcon} alt="openInNew" />
      </IconButton>
    </ListItemBase>
  );
}
