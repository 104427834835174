import api from '../api';
import qs from 'qs';
import { queryToString } from '../../helpers/AppHelpers';

export const getProjectListApi = (query) =>
  api
    .get(`/Projects?${qs.stringify(query, { allowDots: true, indices: false })}`)
    .then((res) => res.data);

export const getSingleProjectApi = (id) => api.get(`/Project?id=${id}`).then((res) => res.data);

export const getStatesListApi = () => api.get('/UnitedStates').then((res) => res.data);

export const createProjectApi = (params) => api.post(`/Project`, params).then((res) => res.data);

export const updateProjectApi = (params) => api.put(`/Project`, params).then((res) => res.data);

export const deleteProjectApi = (id) => api.delete(`/Project?id=${id}`).then((res) => res.data);

export const updateProjectLocationsApi = (params) =>
  api.put(`/Locations`, params).then((res) => res);

export const getLocationListApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);
