import {
  SET_EMPLOYMENT_STATUSES,
  CLEAR_EMPLOYMENT_STATUSES_STATE
} from 'constants/reduceConstants';

const initialState = {
  statuses: []
};

const employmentStatusEditorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EMPLOYMENT_STATUSES:
      return { ...state, statuses: payload };
    case CLEAR_EMPLOYMENT_STATUSES_STATE:
      return initialState;
    default:
      return state;
  }
};

export default employmentStatusEditorReducer;
