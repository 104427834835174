import React, { useEffect } from 'react';
import { reformatInputValue, getRandomId, cloneObj } from 'helpers/AppHelpers';
import { MainButton, StyledSwitch } from 'components/StyledComponents/';
import { IconButton, TextField } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

export default function SignatureReportTemplate({
  index,
  template,
  dragIsActive,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  published,
  isCopyAvailable
}) {
  const { data } = template;
  const { active, required, textFields = [] } = data;

  useEffect(() => {
    if (!active) return;
    if (!textFields?.length) {
      onAddField();
    }
  }, [active]);

  const handleSwitchClick = (e) => {
    onUpdate(index, {
      ...template,
      data: { ...template.data, [e.target.name]: !template.data[e.target.name] }
    });
  };

  const onInput = (event, fieldIndex) => {
    let value = event.target.value;

    value = reformatInputValue(value, 500);

    const clonedFields = cloneObj(textFields);
    clonedFields[fieldIndex].text = value;

    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        textFields: clonedFields
      }
    });
  };

  const onSave = () => {
    onUpdate(index, { ...template, data: { ...template.data, active: false } });
  };

  const isAddButtonDisabled = () => textFields?.length >= 5;
  const isDeleteAvailable = () => textFields?.length >= 2;

  const onAddField = () => {
    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        textFields: [...textFields, { rowId: getRandomId(), text: '' }]
      }
    });
  };

  const onDeleteField = (fieldIndex) => {
    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        textFields: textFields.filter((_, i) => i !== fieldIndex)
      }
    });
  };

  const isSaveDisabled = () => textFields.map(({ text }) => text).includes('');

  return (
    <section className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Signature</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <div className={styles.signature}>
              <div className={styles.signature__body}>
                {!!textFields?.length &&
                  textFields.map(({ rowId, text }, i) => (
                    <div className={styles.signature__body_field} key={rowId}>
                      <TextField
                        name="text"
                        className={styles.textInput}
                        variant="outlined"
                        value={text || ''}
                        onChange={(e) => onInput(e, i)}
                      />
                      {isDeleteAvailable() && (
                        <IconButton
                          onClick={() => onDeleteField(i)}
                          className={styles.signature__body_delete}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  ))}
              </div>

              <div className={styles.signature__footer}>
                <MainButton
                  text="+"
                  isMobile
                  type="primary"
                  isDisabled={isAddButtonDisabled()}
                  action={onAddField}
                />
                <span>Add field for signature’s details</span>
              </div>
            </div>
          </div>

          <div className={styles.activeTemplate__controls}>
            <div>
              <StyledSwitch
                name="required"
                label="Required"
                checked={required}
                handleChange={handleSwitchClick}
              />
            </div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={isSaveDisabled()}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div
              className={`${styles.inactiveTemplate__controls} ${
                dragIsActive ? styles.hidden : ''
              }`}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              {isCopyAvailable() && (
                <button>
                  <FileCopyIcon onClick={() => onCopy(index)} />
                </button>
              )}
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div>
              <b>
                Signature
                {required && '*'}
              </b>
            </div>
            <div className={styles.inactiveSignature}>
              {!!textFields?.length &&
                textFields.map(({ text }, i) => (
                  <div className={styles.inactiveSignature__field} key={i}>
                    <label>{text || ''}</label>
                    <TextField className={styles.textInput} variant="outlined" disabled />
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}
    </section>
  );
}
