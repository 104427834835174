import React, { useEffect, useState } from 'react';
import styles from './LocationVerification.module.scss';

import MainButton from 'components/StyledComponents/MainButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import { FormLabel, FormMultipleAssetSelect } from 'components/FormComponents';

import {
  VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS,
  VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS_TYPE,
  VERIFICATE_LOCATIONS
} from 'constants/dialogPopupsData';

import { useBatchAssetUpdatesActions } from 'hooks/BatchAssetUpdates';
import { useUserConfig } from 'hooks/useUserConfig';

import { useFormContext } from 'react-hook-form';

import { pluck, removeDuplicates } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { getOtherTabNames, getUpdatedTabValues, verifiedStatus } from '../helpers';

import { tabs } from '../helpers';

export default function LocationVerification() {
  const tabName = tabs.locationVerification;

  const { setError, clearErrors, setValue, getValues, formState } = useFormContext();
  const getFieldName = (name) => `${tabName}.${name}`;

  const { errors } = formState;
  const isFormInvalid = () => !!errors?.[tabName]?.['location'] || !!errors?.[tabName]?.['assets'];

  const [locations, setLocations] = useState([]);

  const { getLocationsByAssetsAction, setAssetsInspectionStatusAction } =
    useBatchAssetUpdatesActions();

  const { isOperationsManagerUser } = useUserConfig();

  const [modalData, setModalData] = useState({});

  const getAssetLocations = (assets) =>
    getLocationsByAssetsAction(generateQueryForLocations(assets));

  const handleAssets = (assets) => {
    if (!assets?.length) {
      setLocations([]);
      setValue(getFieldName('location'), {});
    } else {
      getAssetLocations(assets).then((res) => {
        setLocations(res?.items || []);
        setValue(getFieldName('location'), {});
      });
    }
  };

  useEffect(() => {
    handleAssets(getValues(getFieldName('assets')));
  }, []);

  useEffect(() => {
    return () => {
      clearErrors(tabName);
    };
  }, []);

  const generateQueryForLocations = (codes) => {
    const locations = codes.map(
      ({ currentLocationId, lastLocationHistory }) =>
        currentLocationId || lastLocationHistory.location.id
    );
    const locationIds = removeDuplicates(locations);
    return {
      filters: {
        locationIds: locationIds,
        includeUserCheck: isOperationsManagerUser ? null : true
      }
    };
  };

  const handleAssetSelect = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
    errors?.[tabName]?.['assets']?.message && clearErrors(getFieldName('assets'));
    handleAssets(assets);
  };

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: getFieldName('assets'),
        errorMessage: 'Assets field is required',
        isInvalid: !getValues(getFieldName('assets'))?.length
      },
      {
        name: getFieldName('location'),
        errorMessage: 'Location field is required',
        isInvalid: !getValues(getFieldName('location'))?.id
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const selectLocation = ({ name, value }) => {
    errors?.[tabName]?.['location']?.message && clearErrors(getFieldName('location'));
    setValue(name, value);
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;

    const { assets, location } = getValues(tabName);

    const locationsOfAssets = removeDuplicates(pluck(assets, 'currentLocationId'));

    if (locationsOfAssets.length > 1) {
      const assetsWithOtherLocation = pluck(
        assets.filter(({ currentLocationId }) => currentLocationId !== location.id),
        'drCode'
      );

      const isAssetSingle = assetsWithOtherLocation.length === 1;
      const modalTitle = `${assetsWithOtherLocation.join(', ')} ${
        isAssetSingle ? 'asset' : 'assets'
      } ${isAssetSingle ? 'is' : 'are'} not from ${
        location.siteCode
      }. Do you need to update the location for this ${isAssetSingle ? 'asset' : 'assets'}?`;

      setModalData({
        ...VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS,
        title: modalTitle,
        isOpened: true
      });
    } else {
      setModalData({ ...VERIFICATE_LOCATIONS, isOpened: true });
    }
  };

  const generatePayload = ({ onlyLocationAssets }) => {
    const { assets, location } = getValues(tabName);

    const payloadAssets = onlyLocationAssets
      ? assets.filter(({ currentLocationId }) => currentLocationId === location.id)
      : assets;

    return { assets: payloadAssets, inspectionStatus: verifiedStatus, location };
  };

  const closeModal = () => setModalData({ isOpened: false });
  const clearForm = () => {
    setValue(getFieldName('assets'), []);
    setValue(getFieldName('location'), {});
    setLocations([]);
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, [], getValues()))
    );
  };

  const dismissModal = () => {
    closeModal();
    if (modalData.type === VERIFICATE_ASSETS_WITH_DIFFERENT_LOCATIONS_TYPE) {
      setAssetsInspectionStatusAction(generatePayload({ onlyLocationAssets: true })).then(
        clearForm
      );
    }
  };

  const agreeModal = () => {
    closeModal();
    setAssetsInspectionStatusAction(generatePayload({ onlyLocationAssets: false })).then(clearForm);
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={dismissModal} />
      <div className={styles.form}>
        <div className={styles.form__assets}>
          <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes*</FormLabel>
          <FormMultipleAssetSelect
            withQr
            name={getFieldName('assets')}
            onSelectTriggered={handleAssetSelect}
            queryOptions={{ includeUserCheck: true }}
          />
        </div>
        <div className={styles.form__location}>
          <FormLabel>Assign these assets to this location*</FormLabel>
          <FormSelectInput
            name={getFieldName('location')}
            onSelect={selectLocation}
            options={locations || []}
            isDisabled={!locations?.length}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <MainButton
          text="update"
          type="primary"
          isDisabled={isFormInvalid()}
          action={handleUpdateClick}
        />
      </div>
    </div>
  );
}
