import React from 'react';
import styles from './BaseStatisticsChart.module.scss';

import SimplePieChart from 'components/Charts/SimplePieChart/SimplePieChart';

import { getColumnName, colors, dashboardLabels } from '../../../helpers';

export default function BaseStatisticsChart({ data }) {
  const { durations, name } = data;

  const charts = durations.map(({ dateFrom, dateTo, severityWithCount, total }) => ({
    title: getColumnName(dateFrom, dateTo),
    series: severityWithCount.map(({ count }) => count),
    labels: severityWithCount.map(({ ticketSeverity }) => ticketSeverity.displayName),
    isDisplayed: total !== 0
  }));

  return (
    <div className={styles.charts}>
      {charts.map(({ title, series, labels, isDisplayed }) =>
        isDisplayed ? (
          <div key={title} className={styles.charts__block}>
            <SimplePieChart
              hideToolbar
              title={title}
              series={series}
              labels={labels}
              colors={colors.map(({ color }) => color)}
              fileName={dashboardLabels.baseStatistics + ' ' + name}
            />
          </div>
        ) : null
      )}
    </div>
  );
}
