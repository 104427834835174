import { createSelector } from 'reselect';

const billOfLadingData = (state) => state.billOfLadingData;

export const selectCurrentBillOfLadingData = () =>
  createSelector([billOfLadingData], (state) => state.currentBoL);

export const selectBoLFilterData = () =>
  createSelector([billOfLadingData], (state) => state.filter);

export const selectBoLCreationFormData = () =>
  createSelector([billOfLadingData], (state) => state.creationForm);
