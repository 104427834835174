import React from 'react';
import { Checkbox, TableBody } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import styles from './RequestTableBody.module.scss';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ETACell from './ETACell';
import clsx from 'clsx';
import StatusCell from './StatusCell';
import ImagesPopper from './Poppers/ImagesPopper';
import { FileListPopper } from 'components/Poppers';

function RequestTableBody({
  entitiesData,
  setOpen,
  setPopupData,
  checked,
  handleCheckbox,
  onDelete,
  onApply,
  permissions,
  isStatus
}) {
  const {
    isCheckboxColumnAvailable,
    isQTYColumnAvailable,
    isETAColumnAvailable,
    isETAEditable,
    isEditColumnAvailable,
    isDeleteColumnAvailable,
    isStatusColumnAvailable,
    isEditStatusColumnAvailable
  } = permissions;

  const handleEditClick = (row) => {
    setOpen(true);
    setPopupData({ entityType: 'single', ids: [row.id], rowData: row });
  };

  const handleDeleteClick = (rowId) => onDelete([rowId]);

  const shouldImagePopperDisplay = (resources) =>
    !!resources?.filter((resource) => resource.resourceType?.id === 1)?.length;
  const getImages = (resources) =>
    resources?.filter((resource) => resource.resourceType?.id === 1) || [];

  return (
    <TableBody>
      {entitiesData?.length ? (
        entitiesData.map((row) => (
          <StyledTableRow key={row.id}>
            {isCheckboxColumnAvailable && (
              <StyledTableCell className={styles.checkboxCell}>
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  onChange={(e) => handleCheckbox(e, row.id)}
                  value={row.id}
                  checked={!!checked?.find((id) => id === row.id)}
                />
              </StyledTableCell>
            )}
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.requestEntityBlock)}>
                {row['requestEntity']?.displayName || ''}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.expandableBlock, styles.entityNameBlock)}>
                {row['entityName'] || ''}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.quantityBlock)}>
                {row['quantity'] || 0}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.transportationCostBlock)}>
                {row['transportationCost'] || ''}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.transportationTimelineBlock)}>
                {row['transportationTimeline'] || ''}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.purchasingCostBlock)}>
                {row['purchasingCost'] || ''}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.purchasingTimelineBlock)}>
                {row['purchasingTimeline'] || ''}
              </div>
            </StyledTableCell>
            {isQTYColumnAvailable && (
              <>
                <StyledTableCell>
                  <div className={clsx(styles.commonBlock, styles.transportationQTYBlock)}>
                    {row['transportationQTY'] || ''}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={clsx(styles.commonBlock, styles.purchasingQTYBlock)}>
                    {row['purchasingQTY'] || ''}
                  </div>
                </StyledTableCell>
              </>
            )}
            {isETAColumnAvailable && (
              <ETACell
                etaValue={row['eta'] || ''}
                rowData={row}
                onApply={onApply}
                isCanBeEditable={isETAEditable}
              />
            )}
            {isStatusColumnAvailable && (
              <StatusCell
                statusValue={row?.status || {}}
                rowData={row}
                onApply={onApply}
                isCanBeEditable={isEditStatusColumnAvailable}
              />
            )}
            <StyledTableCell>
              <div className={styles.notesWrapper}>
                <div className={styles.expandableBlock}>{row['notes'] || ''}</div>
                {(isStatus('Approved') || isStatus('InDelivery') || isStatus('Closed')) && (
                  <>
                    <div className={styles.separator} />
                    <div className={styles.expandableBlock}>{row['notesAfterApproval'] || ''}</div>
                  </>
                )}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.fileBlock)}>
                <FileListPopper resources={row?.resources || []} />
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={clsx(styles.commonBlock, styles.picBlock)}>
                {shouldImagePopperDisplay(row?.resources) && (
                  <ImagesPopper images={getImages(row?.resources)} />
                )}
              </div>
            </StyledTableCell>
            {isEditColumnAvailable && (
              <StyledTableCell>
                <div className={styles.editBlock} onClick={() => handleEditClick(row)}>
                  <CreateIcon />
                </div>
              </StyledTableCell>
            )}
            {isDeleteColumnAvailable && (
              <StyledTableCell>
                <div className={styles.editBlock} onClick={() => handleDeleteClick(row.id)}>
                  <DeleteIcon />
                </div>
              </StyledTableCell>
            )}
          </StyledTableRow>
        ))
      ) : (
        <StyledTableRow>
          <StyledTableCell colSpan={100} className={styles.emptyTable}>
            No available items.
          </StyledTableCell>
        </StyledTableRow>
      )}
    </TableBody>
  );
}

export default RequestTableBody;
