import React from 'react';
import styles from './SearchTables.module.scss';

import { Link } from '@material-ui/core';

import { BASE_ADDRESS } from 'api/api';

import { getSectionPath } from '../../../helpers';

import { useSearchSelector } from 'hooks/Search';

export default function SeeResultsLink({ tableName }) {
  const { searchQuery } = useSearchSelector();

  const path = `${BASE_ADDRESS}${getSectionPath(tableName)}?searchQuery=${searchQuery}`;

  const handleClick = (e) => {
    e.preventDefault();
    window.open(path, '_blank');
  };

  return (
    <div className={styles.linkWrapper}>
      <Link href={path} className={styles.link} onClick={handleClick}>
        View Results...
      </Link>
    </div>
  );
}
