import api from '../api';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const getCreationForm = () => () => 
  api.setSchedulePopup
    .getCreationFormApi()
    .then((res) => res)
    .catch((err) => console.log(err));


export const getEvent = (id) => () =>
  api.setSchedulePopup
    .getEventApi(id)
    .then((res) => res)
    .catch((err) => console.log(err));

export const checkClearance = (data) => () =>
  api.setSchedulePopup
    .checkClearanceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createEvent = (data) => () =>
  api.setSchedulePopup.createEventApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const createEvents = (data) => () =>
  api.setSchedulePopup.createEventsApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const updateEvent = (data) => () =>
  api.setSchedulePopup.updateEventApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const deleteEvent = (query) => () =>
  api.setSchedulePopup.deleteEventApi(query).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
