import React, { useEffect } from 'react';
import AuthorizedView from './containers/AuthorizedView/AuthorizedView';
import './App.scss';
import Login from './containers/Login/Login';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { selectIsLoggedIn } from './containers/Login/selectors';
import { onAuth } from './actions/authActions';

function App({ isLoggedIn, onAuthAction }) {
  useEffect(() => {
    onAuthAction();
  });

  return <>{isLoggedIn !== null ? isLoggedIn ? <AuthorizedView /> : <Login /> : null}</>;
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn()
});

const mapDispatchToProps = {
  onAuthAction: onAuth
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
