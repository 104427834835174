import { useDispatch } from 'react-redux';

import {
  getLogs,
  getCreationForm,
  getFilterCriteria,
  getCsvResource,
  setFilter,
  getLog,
  createLog,
  updateLog,
  removeLog,
  getCsvTemplate,
  importCsv,
  uploadLogs,
  clearState,
  clearUploadLogsState
} from 'actions/billOfLadingLogsActions';

export default function useBillOfLadingLogsActions() {
  const dispatch = useDispatch();

  const getLogsAction = (q) => dispatch(getLogs(q));

  const getCreationFormAction = () => dispatch(getCreationForm());

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());
  const setFilterAction = (data) => dispatch(setFilter(data));

  const getCsvResourceAction = (q) => dispatch(getCsvResource(q));

  const getLogAction = (q) => dispatch(getLog(q));
  const createLogAction = (data) => dispatch(createLog(data));
  const updateLogAction = (data) => dispatch(updateLog(data));
  const removeLogAction = (id) => dispatch(removeLog(id));

  const getCsvTemplateAction = () => dispatch(getCsvTemplate());
  const importCsvAction = (file) => dispatch(importCsv(file));
  const uploadLogsAction = (data) => dispatch(uploadLogs(data));

  const clearStateAction = () => dispatch(clearState());
  const clearUploadLogsStateAction = () => dispatch(clearUploadLogsState());

  return {
    getLogsAction,
    getCreationFormAction,
    getFilterCriteriaAction,
    getCsvResourceAction,
    setFilterAction,
    getLogAction,
    createLogAction,
    updateLogAction,
    removeLogAction,
    getCsvTemplateAction,
    importCsvAction,
    uploadLogsAction,
    clearStateAction,
    clearUploadLogsStateAction
  };
}
