import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';

function VehicleInstructionsDetails({ isMobile, data }) {
  return (
    <AccordionWrapper title="Vehicle & Instructions" isMobile={isMobile}>
      <div className={styles.vertical}>
        <div className={styles.details}>
          <div className={styles.details__block}>
            <div className={styles.details__block_item}>
              <label>Type of vehicle:</label>
              <span>{data.typeOfVehicle}</span>
            </div>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.details__record}>
            <div className={styles.details__record_item}>
              <label>Special Instructions:</label>
              <span>{data.instructions}</span>
            </div>
          </div>
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default VehicleInstructionsDetails;
