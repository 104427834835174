import React, { useState } from 'react';
import styles from './Details.module.scss';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';
import { PhotoGallery } from 'components/PhotoGallery';
import { dateTimeFormat, emptyValue, fileDownload, formatDate } from 'helpers/AppHelpers';
import Subtitle from 'components/DetailsComponents/Subtitle';
import { DOWNLOAD_FILE } from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';

export default function FilesInfo() {
  const [dialogModalData, setDialogModalData] = useState({});

  const { resources, createdByUser, createdAtUtc, lastUpdatedByUser, modifiedAtUtc } = useSelector(
    selectSingleProjectData()
  );

  const getFiles = resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  const onFileDownload = (file) => {
    setDialogModalData({ ...DOWNLOAD_FILE, isOpened: true, selectedFile: file });
  };

  const closeModal = () => setDialogModalData({ isOpened: false });
  const onAgree = () => fileDownload(dialogModalData.selectedFile).then(() => closeModal());

  return (
    <section className={styles.container}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />

      <PhotoGallery resources={resources || []} />
      <div className={styles.editors}>
        {createdByUser?.firstName && (
          <p className={styles.editors__info}>
            Created {createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : emptyValue} by{' '}
            {createdByUser.firstName} {createdByUser.lastName}
          </p>
        )}
        {lastUpdatedByUser?.firstName && (
          <p className={styles.editors__info}>
            Last Modified {modifiedAtUtc ? formatDate(modifiedAtUtc, dateTimeFormat) : emptyValue}{' '}
            by {lastUpdatedByUser.firstName} {lastUpdatedByUser.lastName}
          </p>
        )}
      </div>
      <div className={styles.files}>
        <Subtitle>Files</Subtitle>
        <section className={styles.files__block}>
          {getFiles.length
            ? getFiles.map((file) => (
                <div
                  className={styles.files__block_file}
                  key={file.id}
                  onClick={() => onFileDownload(file)}>
                  {file.name}
                </div>
              ))
            : emptyValue}
        </section>
      </div>
    </section>
  );
}
