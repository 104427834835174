import React from 'react';
import styles from './Footer.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import { useFormContext } from 'react-hook-form';

export default function Footer({ onCancel, onCreate }) {
  const { formState } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  return (
    <div className={styles.footer}>
      <MainButton text="cancel" type="secondary" action={onCancel} />
      <MainButton text="create" type="primary" action={onCreate} isDisabled={isFormInvalid()} />
    </div>
  );
}
