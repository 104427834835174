import React from 'react';

import { useUserConfig } from 'hooks/useUserConfig';
import { useSelector } from 'react-redux';

import Departments from './Departments';
import Capacity from './Capacity';
import Skillset from './Skillset';
import Licenses from './Licenses';
import ReadOnlyDepartments from './ReadOnlyDepartments';

import { selectCurrentTeamMemberData } from '../../../selectors';

export default function Specifications() {
  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();
  const { hideReadOnlyProjectScheduleField } = useSelector(selectCurrentTeamMemberData());

  return (
    <section>
      <Departments />
      <Capacity />
      {(isAdminUser || isPersonnelManagerUser) && <Skillset />}
      {(isAdminUser || isPersonnelManagerUser) && <Licenses />}
      {!hideReadOnlyProjectScheduleField && <ReadOnlyDepartments />}
    </section>
  );
}
