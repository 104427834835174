import React from 'react';
import styles from './GeneralDetails.module.scss';
import { useFormContext } from 'react-hook-form';
import { formatDate } from 'helpers/AppHelpers';

export default function GeneralDetails() {
  const { getValues } = useFormContext();

  const data = [
    {
      id: 1,
      label: 'Report name',
      value: getValues('generalReportTemplate')?.name || ''
    },
    {
      id: 2,
      label: 'Date',
      value: getValues('createdAtUtc')
        ? formatDate(getValues('createdAtUtc'))
        : formatDate(new Date())
    }
  ];

  return (
    <div className={styles.general}>
      {data.map((el) => (
        <section key={el.id} className={styles.general_details}>
          <label>{el.label}</label>
          <b>{el.value}</b>
        </section>
      ))}
    </div>
  );
}
