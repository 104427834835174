import React from 'react';
import styles from './CustomDetails.module.scss';
import FormLabeledCheckbox from 'components/FormComponents/FormCheckbox/FormLabeledCheckbox';
import ChoiceHintText from './CustomFields/ChoiceHintText';
import SectionLabel from '../SectionLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import StyledRadio from 'components/StyledComponents/StyledRadio';
import clsx from 'clsx';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

export default function ChoiceSection({ fieldItem, index, onSave, onBlur, onFocus }) {
  const { setValue, getValues, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const optionsName = `generalReportFields[${index}].data.options`;

  const handleRadioChange = (event) => {
    const optionId = event.target.value;
    const values = getValues(optionsName) || [];
    const newValues = values.map((el) => {
      if (el.id === optionId) return { ...el, selected: true };
      return { ...el, selected: false, comment: '' };
    });
    setValue(optionsName, newValues);
    onSave && onSave();
    checkIfErrorExist();
  };

  const checkIfErrorExist = () => {
    if (errors?.generalReportFields?.[index]?.data?.options?.message) {
      clearErrors(optionsName);
    }
  };

  const isRadioCommentAvailable = (optionId, selectedId) =>
    fieldItem?.data?.['isComment'] && selectedId === optionId;
  const isCheckboxCommentAvailable = (name) => fieldItem?.data?.['isComment'] && !!getValues(name);

  const handleCheckboxClick = (event, optionIndex) => {
    if (!event.target.checked) {
      setValue(`${optionsName}[${optionIndex}].comment`, '');
    }
    onSave && onSave();
    checkIfErrorExist();
  };

  const getGroupValue = () => {
    const options = getValues(optionsName);

    const needId = options.find(({ selected }) => selected)?.id;

    if (needId) {
      return needId;
    } else {
      setValue(`${optionsName}[0].selected`, true);
      return options[0].id;
    }
  };

  return (
    <div className={styles.choice_wrapper}>
      <div
        className={clsx(
          styles.choice_section,
          !!errors?.generalReportFields?.[index]?.data?.options?.message && styles.error
        )}>
        <SectionLabel>
          {fieldItem.data.text}
          {fieldItem.data.required && '*'}
        </SectionLabel>
        {fieldItem.data.multiple ? (
          <div className={styles.choice_checkboxes}>
            {fieldItem.data.options.map((option, optionIndex) => (
              <React.Fragment key={option.id}>
                <div className={styles.checkbox_option}>
                  <FormLabeledCheckbox
                    name={`${optionsName}[${optionIndex}].selected`}
                    label={option.text}
                    extraAction={(e) => handleCheckboxClick(e, optionIndex)}
                  />
                  {option.createTicket?.needCreation && <ChoiceHintText />}
                </div>
                <Controller
                  name={`${optionsName}[${optionIndex}].selected`}
                  render={() =>
                    isCheckboxCommentAvailable(`${optionsName}[${optionIndex}].selected`) ? (
                      <div className={styles.comment}>
                        <FormInputText
                          name={`${optionsName}[${optionIndex}].comment`}
                          options={{ max: 65000, onBlur, onFocus }}
                          classes={styles.comment__input}
                        />
                      </div>
                    ) : null
                  }
                />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className={styles.choice_radios}>
            <FormControl component="fieldset">
              <Controller
                control={control}
                name={optionsName}
                render={({ field }) => (
                  <RadioGroup {...field} value={getGroupValue()} onChange={handleRadioChange}>
                    {fieldItem.data.options.map((option, optionIndex) => (
                      <React.Fragment key={option.id}>
                        <div className={styles.radio_option}>
                          <FormControlLabel
                            value={option.id}
                            control={<StyledRadio />}
                            label={<span className={styles.radio_label}>{option.text}</span>}
                          />
                          {option.createTicket?.needCreation && <ChoiceHintText />}
                        </div>
                        {isRadioCommentAvailable(option.id, getGroupValue()) && (
                          <div className={styles.comment}>
                            <FormInputText
                              name={`${optionsName}[${optionIndex}].comment`}
                              options={{ max: 65000, onBlur, onFocus }}
                              classes={styles.comment__input}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </div>
        )}
      </div>
      {!!errors?.generalReportFields?.[index]?.data?.options?.message && (
        <span className={styles.validation_error}>
          {errors.generalReportFields[index].data.options.message || ''}
        </span>
      )}
    </div>
  );
}
