export const defaultForm = {
  type: {},
  originLocation: '',
  destination: '',
  date: '',
  carrier: '',
  vendor: '',
  item: '',
  qty: '',
  package: '',
  pallet: ''
};
