import React, { useState } from 'react';
import styles from './Header.module.scss';
import Logo from 'assets/images/logo.svg';
import data from '../../../package.json';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import { setUnsavedFormData } from 'actions/commonActions';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import {
  BATCH_ASSET_UPDATES_PATH,
  HOME_PATH,
  LOCATION_REPORT_LIST_PATH,
  PERSONNEL_PROFILE_PATH,
  REQUEST_ORDER_EDIT_PATH
} from 'constants/routeConstants';
import { EXIT_WITHOUT_SAVING } from 'constants/dialogPopupsData';
import DialogPopup from '../DialogPopup/DialogPopup';
import QuickAccessButton from './QuickAccessButton';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { IconButton } from '@material-ui/core';

const Header = React.memo(function Header() {
  const isMobile = useMobileViewport();
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const { isTeamMemberUser } = useUserConfig();

  const unsavedFormData = useSelector(selectUnsavedFormData());

  const onProfileClick = () => {
    if (unsavedFormData) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true, wasClicked: 'profile' });
    } else {
      history.push(PERSONNEL_PROFILE_PATH);
    }
  };

  const handleRedirect = (path, pathToCompare) => {
    if (window.location.pathname === pathToCompare) {
      history.push(path);
      window.location.reload();
    } else {
      history.push(path);
    }
  };
  const handleAccessButtonClick = (path, comparingPath) => {
    if (unsavedFormData) {
      setDialogModalData({
        ...EXIT_WITHOUT_SAVING,
        isOpened: true,
        wasClicked: 'access-button',
        path,
        comparingPath
      });
    } else {
      handleRedirect(path, comparingPath);
    }
  };

  const onAgree = () => {
    switch (dialogModalData.wasClicked) {
      case 'profile':
        dispatch(setUnsavedFormData(false));
        history.push(PERSONNEL_PROFILE_PATH);
        break;
      case 'access-button':
        dispatch(setUnsavedFormData(false));
        handleRedirect(dialogModalData.path, dialogModalData.comparingPath);
        break;
      default:
        break;
    }
    setDialogModalData({ isOpened: false });
  };

  const onDismiss = () => setDialogModalData({ isOpened: false });

  const buttons = [
    {
      id: 1,
      title: 'Location Report',
      path: `${LOCATION_REPORT_LIST_PATH}?shouldOpenPopup=true`,
      pathToCompare: LOCATION_REPORT_LIST_PATH
    },
    {
      id: 2,
      title: 'Add ticket',
      path: `${BATCH_ASSET_UPDATES_PATH}?tab=addAssetTicket`,
      pathToCompare: BATCH_ASSET_UPDATES_PATH
    },
    {
      id: 3,
      title: 'Verify Location',
      path: `${BATCH_ASSET_UPDATES_PATH}?tab=locationVerification`,
      pathToCompare: BATCH_ASSET_UPDATES_PATH
    },
    {
      id: 4,
      title: 'Request Order',
      path: REQUEST_ORDER_EDIT_PATH,
      pathToCompare: REQUEST_ORDER_EDIT_PATH
    }
  ];

  const env = process.env.REACT_APP_ENVIRONMENT || 'local';
  const getEnvTitle = () => (env === 'production' ? '' : env + ' env');

  const handleLogoClick = () => history.push(HOME_PATH);

  return (
    <>
      {!isMobile && (
        <section className={styles.headerContainer}>
          <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />

          <IconButton className={styles.headerContainer__logo} onClick={handleLogoClick}>
            <img src={Logo} className={styles.headerContainer__logo_img} alt="" />
          </IconButton>

          <div className={styles.quick_buttons}>
            {buttons.map(({ id, title, path, pathToCompare }) => (
              <QuickAccessButton
                key={id}
                title={title}
                onClick={() => handleAccessButtonClick(path, pathToCompare)}
              />
            ))}
          </div>
          <span className={styles.headerTitle}>{getEnvTitle()}</span>
          <div className={styles.rightBlock}>
            <span className={styles.versionText}>v. {data.version}</span>
            {isTeamMemberUser && (
              <div className={styles.profileBlock} onClick={onProfileClick}>
                <ProfileIcon />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default Header;
