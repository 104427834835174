import React from 'react';
import styles from './LoaderSpinner.module.scss';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useAxiosLoader } from 'api/api.js';

const LoaderSpinner = () => {
  const [loading] = useAxiosLoader();
  return (
    <Backdrop className={styles.spinnerFullWindow} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoaderSpinner;
