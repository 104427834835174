import React from 'react';
import styles from './Form.module.scss';

import GeneralDetails from './GeneralDetails';
import FieldsDetails from './FieldsDetails';

export default function PrefixEditForm() {
  return (
    <div className={styles.form}>
      <GeneralDetails />
      <FieldsDetails />
    </div>
  );
}
