import { useDispatch } from 'react-redux';

import {
  setTab,
  clearState,
  getNotificationCenterData,
  getFilterCriteria,
  getPopupCreationForm,
  addRecipient,
  checkEmail,
  editRecipient,
  deleteRecipient
} from 'actions/notificationCenterActions';

export function useNotificationCenterActions() {
  const dispatch = useDispatch();

  const setTabAction = (tab) => dispatch(setTab(tab));

  const getTabContent = (q) => dispatch(getNotificationCenterData(q));

  const getFilterCriteriaAction = (query) => dispatch(getFilterCriteria(query));

  const getPopupCreationFormAction = (q) => dispatch(getPopupCreationForm(q));

  const addRecipientAction = (data) => dispatch(addRecipient(data));
  const editRecipientAction = (data) => dispatch(editRecipient(data));

  const deleteRecipientAction = (q) => dispatch(deleteRecipient(q));

  const checkEmailAction = (data) => dispatch(checkEmail(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    setTabAction,
    getTabContent,
    getFilterCriteriaAction,
    getPopupCreationFormAction,
    addRecipientAction,
    editRecipientAction,
    deleteRecipientAction,
    checkEmailAction,
    clearStateAction
  };
}
