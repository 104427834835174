import React from 'react';
import styles from '../Form.module.scss';

import { FormLabel, FormMultipleAssetSearch } from 'components/FormComponents';

import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import SectionTitle from '../SectionTitle';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';

export function Contents() {
  const { id } = useParams();
  const { getValues } = useFormContext();

  return (
    <div className={styles.general}>
      <SectionTitle>Contents</SectionTitle>

      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_column}>
            <FormLabel>
              Enter Asset DR Codes separated by spaces; codes must be valid DR Codes:
            </FormLabel>
            <FormMultipleAssetSearch
              hideTags
              name="contentIds"
              listName="contents"
              className={styles.searchField}
              getQueryOptions={(searchQuery) => ({
                pagination: { limit: 40, page: 1 },
                filters: {
                  isDeleted: false,
                  excludeAssetIds: [+id, +getValues('containedInAsset')?.id].filter(Boolean),
                  searchQuery
                }
              })}
            />
            <FormChipsContainer fieldName="contents" label="drCode" deleteParameter="id" />
            <p>
              Only change this field if you want to change the items in this container. If you want
              to keep the contents the same, leave this field as-is.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
