import { useDispatch } from 'react-redux';
import {
  getFilterCriteria,
  setFilter,
  getLocations,
  clearState,
  getLocationAssets,
  getLocationAssetsFields,
  getLocationLooseItems
} from 'actions/activeLocationsMapActions';

export function useActiveLocationsMapActions() {
  const dispatch = useDispatch();

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());
  const setFilterAction = (data) => dispatch(setFilter(data));

  const getLocationsAction = (q) => dispatch(getLocations(q));

  const getLocationLooseItemsAction = (q) => dispatch(getLocationLooseItems(q));
  const getLocationAssetsAction = (q) => dispatch(getLocationAssets(q));
  const getLocationAssetsFieldsAction = (q) => dispatch(getLocationAssetsFields(q));

  const clearStateAction = () => dispatch(clearState());

  return {
    getFilterCriteriaAction,
    setFilterAction,
    getLocationsAction,
    getLocationLooseItemsAction,
    getLocationAssetsAction,
    getLocationAssetsFieldsAction,
    clearStateAction
  };
}
