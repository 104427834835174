export const getOptions = (categories, colors, fileName, hideToolbar) => ({
  chart: {
    type: 'line',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    toolbar: {
      show: !hideToolbar,
      export: {
        csv: {
          filename: fileName
        },
        svg: {
          filename: fileName
        },
        png: {
          filename: fileName
        }
      }
    },
    zoom: {
      enabled: false
    }
  },
  colors,
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    markers: {
      width: 8,
      height: 8
    },
    itemMargin: {
      horizontal: 14,
      vertical: 1
    }
  },
  xaxis: {
    categories,
    tickPlacement: 'between'
  },
  stroke: {
    width: 3
  }
});
