import React from 'react';
import styles from './DialogStyles.module.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

export default function DialogHeader({ title, onClose, classes, children }) {
  return (
    <div className={styles.popupTitle}>
      <div className={clsx(!!classes && classes, styles.headerTitle)}>
        <h2>{title}</h2>
        <div className={styles.headerTitle__controls}>
          {children}
          <button onClick={onClose}>
            <ChevronLeftIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
