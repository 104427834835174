import { useSelector } from 'react-redux';

import {
  selectCreationFormData,
  selectCreationFormStateData,
  selectCreationOpenedFlagStateData,
  selectPreviewAssetsData
} from 'pages/AssetSummary/CreateBatchOfAssets/selectors';

import { selectUnsavedFormData } from 'pages/commonSelectors';

export function useCreateAssetsBatchSelector() {
  const creationForm = useSelector(selectCreationFormData());

  const previewAssets = useSelector(selectPreviewAssetsData());

  const formState = useSelector(selectCreationFormStateData());

  const isCreationOpened = useSelector(selectCreationOpenedFlagStateData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    creationForm,
    previewAssets,
    formState,
    isCreationOpened,
    unsavedFormData
  };
}
