import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import { FormLabel } from 'components/FormComponents';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';

import { FormProvider, useForm } from 'react-hook-form';

import { useUserSelector } from 'hooks/UserManagement';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: {
      roleIds: [],
      assignedLocationIds: [],
      userNotificationTypeIds: [],
      requestOrderRoleIds: [],
      readOnlyDepartmentIds: [],
      assignedDepartmentIds: []
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, filterCriteria } = useUserSelector();

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const { departments, locations, notificationTypes, requestOrderRoles, userRoles } =
    filterCriteria;

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      roleIds: [],
      assignedLocationIds: [],
      userNotificationTypeIds: [],
      requestOrderRoleIds: [],
      readOnlyDepartmentIds: [],
      assignedDepartmentIds: []
    };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>{`User's Role`}</FormLabel>
                <FormMultiSelect
                  trackById
                  name="roleIds"
                  options={userRoles || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Assigned Location</FormLabel>
                <FormAutoMultiSelect
                  name="assignedLocationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Assigned Notification</FormLabel>
                <FormMultiSelect
                  trackById
                  name="userNotificationTypeIds"
                  options={notificationTypes || []}
                  onSelect={handleMultiSelect}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Request order role</FormLabel>
                <FormMultiSelect
                  trackById
                  name="requestOrderRoleIds"
                  options={requestOrderRoles || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Read only right</FormLabel>
                <FormAutoMultiSelect
                  name="readOnlyDepartmentIds"
                  menuItems={departments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Assigned Department</FormLabel>
                <FormAutoMultiSelect
                  name="assignedDepartmentIds"
                  menuItems={departments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
