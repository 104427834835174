import React from 'react';
import styles from './FieldsDetails.module.scss';
import Subtitle from '../Subtitle';
import { Chip, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';
import { usePrefixSelector } from 'hooks/Prefix';

export default function FieldsDetails() {
  const { currentPrefix } = usePrefixSelector();
  const { prefixType, assetFields } = currentPrefix;

  return (
    <section className={styles.wrapper}>
      <Subtitle>{prefixType?.name + ' ' + 'Details'}</Subtitle>
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Fields</StyledTableCell>
              <StyledTableCell>Is Numeric?</StyledTableCell>
              <StyledTableCell>Show on Asset Summary</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!assetFields?.length &&
              assetFields.map((field) => (
                <StyledTableRow key={field.id}>
                  <StyledTableCell>
                    <Chip label={field.displayName || ''} className={styles.chip} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledCheckbox disabled={true} checked={field.isNumeric} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledCheckbox disabled={true} checked={field.isShowOnAssetSummary} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}
