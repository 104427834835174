import React, { useState } from 'react';
import styles from './FormMultipleServerSearch.module.scss';

import { useFormContext, Controller } from 'react-hook-form';

import ServerSearchFieldWithTags from 'components/ServerSearchFieldWithTags';

import clsx from 'clsx';
import { allKeyboardLatSymbols, getUserFullName, pluck } from 'helpers/AppHelpers';
import { searchUsers } from 'actions/commonActions';
import { useDispatch } from 'react-redux';

export default function FormMultipleUserSearch({ name, listName, className, getQueryOptions }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  const { control, clearErrors, setValue } = useFormContext();

  const selectOption = (users, isInvalid) => {
    isInvalid && clearErrors(name);
    setValue(name, pluck(users, 'id'));
    setValue(listName, users);
    !users?.length && setOptions([]);
  };

  const changeInput = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value === '') return;

    const filter = getQueryOptions(searchQuery);

    dispatch(searchUsers(filter)).then((res) => setOptions(res?.items || []));
  };

  return (
    <Controller
      name={listName}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(className, styles.input_wrapper)}>
          <ServerSearchFieldWithTags
            getLabel={(option) => getUserFullName(option)}
            fieldValue={value || []}
            options={options}
            onInputChange={changeInput}
            onSelect={(data) => selectOption(data, !!error)}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
