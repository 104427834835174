import React from 'react';
import styles from './SortableHeader.module.scss';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';

export const SortableHeader = ({
  displayName,
  sortKey,
  sortRules = [],
  onSort,
  canSelect = false,
  isSelected,
  onChange,
  shouldDisableCheckbox
}) => {
  const isSorted = sortRules?.includes(sortKey) || false;

  const isAscend = sortRules[0]
    ? sortRules[0].indexOf('-') >= 0 && sortRules[0].replace('-', '') === sortKey
    : false;

  const modifyRule = (name) => {
    if (!sortRules.length) return [name];
    if (sortRules[0] === sortKey) return [`-${name}`];
    return [name];
  };

  const sortTable = () => onSort(modifyRule(sortKey));

  return (
    <div className={styles.tableHeader}>
      <div className={styles.tableHeader__title_wrapper}>
        {canSelect && (
          <CustomCheckbox
            small
            isDisabled={shouldDisableCheckbox}
            sendEvent
            value={isSelected}
            onChange={onChange}
          />
        )}
        <span className={styles.tableHeader__title_wrapper_title}>{displayName}</span>
      </div>

      <div className={styles.tableHeader__controls}>
        <IconButton className={styles.tableHeader__controls_sort} onClick={sortTable}>
          <ExpandMore className={clsx(isSorted && styles.sorted, isAscend && styles.ascend)} />
        </IconButton>
      </div>
    </div>
  );
};
