import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import {
  getLocationsList,
  clearLocationsState,
  setLocationListFilter
} from 'actions/locationsActions';
import { selectLocationListFilter, selectLocationsListData } from './selectors';
import { Table, TableContainer, TableBody, Paper } from '@material-ui/core';
import LocationsListFilter from './Filter/LocationsListFilter';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import {
  LOCATION_DETAILED_PATH,
  LOCATION_EDIT_PATH,
  BATCH_ASSET_UPDATES_PATH
} from 'constants/routeConstants';
import styles from './LocationSummary.module.scss';
import { LOCATION_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { tableHeadConfig } from './tableConfig';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { useUserConfig } from 'hooks/useUserConfig';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import { useMobileViewport } from 'hooks/useMobileViewport';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';

function LocationSummary({
  filter,
  setFilterAction,
  locationsListData,
  getLocationsListAction,
  clearLocationStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isAdminUser, isConfigReceived, getTableLimit } = useUserConfig();

  const query = new URLSearchParams(window.location.search);
  const searchValue = query.get('searchQuery');

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const { filters, pagination, sortRules, responseReceived } = filter;

  const generateInitialQuery = () => {
    const queryFilter = {
      ...filter,
      filters: { ...filter.filters, searchQuery: searchValue || '' },
      pagination: { ...pagination, limit: getTableLimit(LOCATION_LIST_TABLE_PARAMETER) }
    };
    if (searchValue) {
      query.delete('searchQuery');
      history.replace({ search: query.toString() });
    }
    return queryFilter;
  };

  const setLocations = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  const getLocations = (query, isAutoload) => {
    setFilterAction(query);

    getLocationsListAction(query).then((tableData) => {
      setLocations(tableData, query, isAutoload);
    });
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getLocations(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearLocationStateAction();
    };
  }, []);

  const emptyResponse = () =>
    !locationsListData?.items?.length && pagination?.page === locationsListData?.pageNumber;

  const emptyFilters = () => {
    return (
      !locationsListData?.items?.length &&
      filters.searchQuery === '' &&
      filters.active === true &&
      !filters.siteTypeIds.length &&
      !filters.locationJobNumberIds.length &&
      pagination?.page === locationsListData?.pageNumber
    );
  };

  const updateSearchParam = (value) => getLocations(getChangedBySearchFilter(filter, value));
  const onSortApply = (rowName) => getLocations(getChangedBySortFilter(filter, rowName));
  const onPageSelect = (page, param) =>
    getLocations(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getLocations(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getLocations(getChangedByLimitFilter(filter, getTableLimit(LOCATION_LIST_TABLE_PARAMETER)));

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerWrapper}>
        <SummaryHeader title="Location Summary" buttonsNumber={isAdminUser ? 2 : 1}>
          {!isMobile && <DownloadCSVButton filter={filter} endpoint="Locations/Serialized" />}
          <MainButton
            text="Batch location update"
            action={() => history.push(BATCH_ASSET_UPDATES_PATH)}
            type="primary"
          />
          {isAdminUser && (
            <MainButton
              text="Add new location"
              action={() => history.push(LOCATION_EDIT_PATH)}
              type="primary"
            />
          )}
        </SummaryHeader>
        <div className={styles.tableControls}>
          <LocationsListFilter
            filter={filter}
            onApply={applyFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isMobile={isMobile}
          />
          <SearchTextFieldRefactored
            inputValue={filters.searchQuery}
            validationRule={/[А-Яа-я]/g}
            updateSearchParam={updateSearchParam}
            rightSpace={isMobile}
          />
        </div>
      </div>
      {!(isMobile && openFilter) && (
        <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
          <Table stickyHeader={!isMobile}>
            <TableHeadComponent
              config={tableHeadConfig}
              sortRules={sortRules}
              onSortApply={onSortApply}
            />
            <TableBody>
              {tableState?.length ? (
                tableState.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <LinkComponent
                        path={`${LOCATION_DETAILED_PATH}/${row.id}`}
                        name={row.siteCode}
                        boldText
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.city || ''}
                      {row.city && row.state && `, `}
                      {row.state || ''}
                    </StyledTableCell>
                    <StyledTableCell>{row.locationJobNumber || ''}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell>{row.siteType.name}</StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!(isMobile && openFilter) && (
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={tableState?.length}
          currentPage={pagination.page}
          totalPages={pagination.totalPages}
          selectAllOption={true}
          onPageSelect={onPageSelect}
          pageLimit={pagination.limit}
          hide={emptyResponse()}
          tableListParameter={LOCATION_LIST_TABLE_PARAMETER}
          getTableData={changeLimit}
          tableRef={tableRef}
        />
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  filter: selectLocationListFilter(),
  locationsListData: selectLocationsListData()
});

const mapDispatchToProps = {
  setFilterAction: setLocationListFilter,
  getLocationsListAction: getLocationsList,
  clearLocationStateAction: clearLocationsState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LocationSummary);
