import React from 'react';
import styles from './EditCellPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import { FormLabel, FormSelectInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { useForm, FormProvider } from 'react-hook-form';

export function EditCellPopup({ open, setOpen, options }) {
  const { field, popup, onSave, customOptions } = options;
  const { title } = popup;
  const { inputType, label, name, value, max, index, type, list } = field;

  const methods = useForm({
    defaultValues: { [name]: value },
    mode: 'onChange'
  });
  const { getValues, setValue } = methods;

  const closePopup = () => setOpen(false);
  const savePopup = () => {
    onSave({ [name]: getValues(name) }, index, customOptions);
    closePopup();
  };

  const selectOption = ({ name, value }) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader title={title} onClose={closePopup} classes={styles.title} />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.form}>
              <div className={styles.form__item}>
                {label && <FormLabel>{label}</FormLabel>}
                {inputType === 'manual' && (
                  <FormInputText name={name} options={{ max, type, full: !label }} />
                )}
                {inputType === 'select' && (
                  <FormSelectInput name={name} options={list} onSelect={selectOption} classes={styles.select} />
                )}
              </div>
            </div>
          </FormProvider>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton text="Save" action={savePopup} type="primary" />
        </div>
      </div>
    </DialogWrapper>
  );
}
