import { createSelector } from 'reselect';

const teamMembersData = (state) => state.teamMembersData;

export const selectCurrentTeamMemberData = () =>
  createSelector([teamMembersData], (state) => state.currentTeamMember);

export const selectCreationFormData = () =>
  createSelector([teamMembersData], (state) => state.creationForm);

export const selectFilterData = () => createSelector([teamMembersData], (state) => state.filter);

export const selectFilterCriteriaData = () => createSelector([teamMembersData], (state) => state.filterCriteria);
