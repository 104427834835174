import styles from './form.module.scss';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import React from 'react';

export default function Notes() {
  return (
    <>
      <h2>Notes</h2>
      <div className={styles.notes}>
        <FormTextArea name="notes" options={{ max: 1000 }} />
      </div>
    </>
  );
}
