import React from 'react';
import styles from './components.module.scss';

import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';

import clsx from 'clsx';

export default function LocationFiles() {
  return (
    <>
      <h3 className={styles.title}>Files</h3>
      <div className={styles.details}>
        <div className={styles.details__block}>
          <div className={clsx(styles.details__block_field, styles.alignTop)}>
            <label>Upload Photo</label>
            <FormImagesUpload name="resources" />
          </div>
        </div>
        <div className={styles.details__block}>
          <section className={clsx(styles.details__block_field, styles.alignTop)}>
            <label>Upload File</label>
            <FormFilesUpload name="resources" />
          </section>
        </div>
      </div>
    </>
  );
}
