import React from 'react';

import StyledLink from 'components/StyledLink';

import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';

export function AssetLocationCell({ asset }) {
  return (
    <StyledLink
      isRedirectAvailable={!asset.lastLocationHistory?.location?.deleted}
      to={`${LOCATION_DETAILED_PATH}/${asset.lastLocationHistory?.location?.id}`}>
      {asset.lastLocationHistory?.location?.siteCode || ''}
    </StyledLink>
  );
}
