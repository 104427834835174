import React, { useEffect, forwardRef, useState, useRef } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Checkbox,
  Slide,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { MainButton } from 'components/StyledComponents/';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './DashboardAddTablePopup.module.scss';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import BackButton from 'components/BackButton/BackButton';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DashboardAddTablePopup({
  isMobile,
  modalOpen,
  setModalOpen,
  dashboardConfig,
  onViewChange
}) {
  const [tablesListData, setTablesListData] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [addTables, setAddTables] = useState([]);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef?.current) containerRef.current.scrollIntoView();
  }, [modalOpen]);

  useEffect(() => {
    if (dashboardConfig.length) {
      setTablesListData(
        dashboardConfig
          .map((config, index) => {
            return { ...config, inputOrder: index };
          })
          .filter(({ prefixes }) => prefixes.length)
      );
      setSelectedTables(
        dashboardConfig?.filter(({ isHidden, prefixes }) => !isHidden && prefixes.length)
      );
    }
  }, [dashboardConfig]);

  const swap = (indexA, indexB) => {
    const arrayCopy = [...tablesListData];
    const temp = arrayCopy[indexA];
    arrayCopy[indexA] = arrayCopy[indexB];
    arrayCopy[indexB] = temp;
    setTablesListData(
      arrayCopy.map((table, index) => {
        return { ...table, order: index };
      })
    );
  };

  const isDeleteAvailable = (table) => {
    const filteredArray = tablesListData
      .map(({ categoryId }) => categoryId)
      .filter((id) => id === table.categoryId);
    return filteredArray.length > 1;
  };

  const upClickHandle = (tableIndex) => {
    if (tableIndex !== 0) {
      swap(tableIndex, tableIndex - 1);
    }
  };

  const downClickHandle = (tableIndex) => {
    if (tableIndex !== tablesListData.length - 1) {
      swap(tableIndex, tableIndex + 1);
    }
  };

  const deleteClickHandle = (index) => {
    const newArray = [...tablesListData];
    newArray.splice(index, 1);
    setTablesListData(
      newArray.map((table, index) => {
        return { ...table, order: index };
      })
    );
  };

  const onAgree = () => {
    const changeLog = tablesListData
      .filter(({ isHidden }) => !isHidden)
      .map(({ categoryId, order, inputOrder }) => {
        return { categoryId, outputOrder: order, inputOrder };
      });
    onViewChange(tablesListData, changeLog);
    setModalOpen(false);
  };

  const onDismiss = () => {
    setTablesListData(dashboardConfig.filter(({ prefixes }) => prefixes.length));
    setSelectedTables(dashboardConfig?.filter(({ isHidden }) => !isHidden));
    setModalOpen(false);
  };

  const onChange = (e, index) => {
    let config = [...tablesListData];
    if (e.target.name === 'all') {
      config =
        selectedTables.length === tablesListData.length
          ? tablesListData.map((table) => {
              return { ...table, isHidden: true };
            })
          : tablesListData.map((table) => {
              return { ...table, isHidden: false };
            });
    } else {
      config[index] = { ...config[index], isHidden: !config[index].isHidden };
    }
    setTablesListData(config);
    setSelectedTables(config?.filter(({ isHidden }) => !isHidden));
  };

  const onTableSelect = (_, value) => {
    setAddTables(value.map(({ id }) => id));
  };

  const handleAddTable = () => {
    const tablesToAdd = addTables.map((id, index) => {
      const dashboard = dashboardConfig.find(({ categoryId }) => categoryId === id);
      return {
        ...dashboard,
        prefixes:
          dashboard.prefixes?.map((prefix) => {
            return { ...prefix, isHidden: false };
          }) || [],
        subprefixes:
          dashboard.subprefixes?.map((prefix) => {
            return { ...prefix, isHidden: true };
          }) || [],
        order: tablesListData.length + index,
        isHidden: false
      };
    });
    setTablesListData(tablesListData.concat(tablesToAdd));
    setAddTables([]);
  };

  return (
    <Dialog
      open={modalOpen}
      classes={{
        paper: styles.popupContainer
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop
        }
      }}
      TransitionComponent={Transition}
      disableBackdropClick
      keepMounted
      onClose={() => setModalOpen(false)}>
      <DialogTitle
        classes={{
          root: styles.popupTitle
        }}>
        <div className={styles.headerTitle}>
          <h2>Add table</h2>
          {isMobile && <BackButton onCancel={onDismiss} />}
          {!isMobile && <p>Move the table using arrow buttons</p>}
        </div>
      </DialogTitle>

      <DialogContent
        classes={{
          root: styles.popupText
        }}>
        {isMobile && <div ref={containerRef} className={styles.newBlock}></div>}
        <FormControl classes={{ root: styles.formControl }}>
          <section className={styles.formCell}>
            <FormControlLabel
              control={
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  name="all"
                  onChange={onChange}
                  value={selectedTables.length === tablesListData.length}
                  checked={selectedTables.length === tablesListData.length}
                />
              }
              label="Select all"
            />
            <div className={styles.sortControls}>
              <span className={styles.upText}>Up</span>
              <span className={styles.downText}>Down</span>
            </div>
          </section>
          {tablesListData.map((table, tableIndex) => (
            <section key={tableIndex} className={styles.formCell}>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ root: styles.checkbox, checked: styles.checked }}
                    name={table.categoryId}
                    onChange={(e) => onChange(e, tableIndex)}
                    value={!table.isHidden}
                    checked={!table.isHidden}
                  />
                }
                label={table.categoryName}
              />
              <div className={styles.sortControls}>
                <span
                  onClick={() => upClickHandle(tableIndex)}
                  className={clsx(styles.upButton, tableIndex === 0 && styles.disabled)}>
                  <ExpandMore />
                </span>
                <span
                  onClick={() => downClickHandle(tableIndex)}
                  className={clsx(
                    styles.downButton,
                    tableIndex === tablesListData.length - 1 && styles.disabled
                  )}>
                  <ExpandMore />
                </span>
                <span onClick={() => deleteClickHandle(tableIndex)} className={styles.deleteButton}>
                  {isDeleteAvailable(table) && <DeleteIcon />}
                </span>
              </div>
            </section>
          ))}
        </FormControl>
      </DialogContent>

      <DialogActions
        classes={{
          root: styles.popupControls
        }}>
        <section className={styles.addTableBlock}>
          <InputSearchWithMultiselect
            name="locations"
            defaultValue={addTables || []}
            options={
              dashboardConfig
                ?.filter(({ prefixes }) => prefixes.length)
                ?.map((item) => {
                  return { ...item, id: item?.categoryId };
                })
                ?.filter(
                  (value, index, array) => array.findIndex((item) => item.id === value.id) === index
                ) || []
            }
            onFilterSelect={onTableSelect}
            multiselect={true}
            optionType="category"
          />
          <MainButton
            text="ADD"
            action={handleAddTable}
            type="primary"
            size="popup"
            isDisabled={!addTables.length}
          />
        </section>
        <section className={styles.buttonsBlock}>
          <MainButton text="Cancel" action={onDismiss} type="secondary" size="popup" />
          <MainButton text="Update dashboard" action={onAgree} type="primary" size="popup" />
        </section>
      </DialogActions>
    </Dialog>
  );
}

export default DashboardAddTablePopup;
