import React, { useState } from 'react';
import styles from './Form.module.scss';

import { useUserConfig } from 'hooks/useUserConfig';
import { useDispatch } from 'react-redux';

import ParameterEditPopup from 'components/ParameterPopups/ParameterEditPopup/ParameterEditPopup';

import { deletePrefixField, updatePrefixField } from 'actions/prefixActions';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { ASSETS_LIST_PATH } from 'constants/routeConstants';
import { queryToString } from 'helpers/AppHelpers';

import { BASE_ADDRESS } from 'api/api';

import { Link } from '@material-ui/core';

function ModalContent({ field }) {
  const path = `${BASE_ADDRESS}${ASSETS_LIST_PATH}?${queryToString({
    prefixIds: field?.prefixIds || []
  })}`;

  const handleClick = (e) => {
    e.preventDefault();
    window.open(path, '_blank');
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        Some Assets have value in the Prefix field. If you confirm, the field will be removed from
        Assets with its values.
      </div>
      <div className={styles.modal__content}>
        <Link href={path} className={styles.link} onClick={handleClick}>
          See results...
        </Link>
      </div>
      <div className={styles.modal__footer}>Do you want to delete field?</div>
    </div>
  );
}

export default function EditFieldPopup({ open, setOpen, data, onUpdate }) {
  const dispatch = useDispatch();
  const { isAdminUser } = useUserConfig();

  const [dialogModalData, setDialogModalData] = useState({});

  const handleSaveParameter = (param) => {
    dispatch(updatePrefixField(param)).then((res) => {
      if (res?.status === 200) {
        onUpdate({ action: 'UPDATE', field: res.data });
        setOpen(false);
      }
    });
  };
  const handleDeleteParameter = (param) => {
    dispatch(deletePrefixField({ id: param.id, allowDelete: false })).then((res) => {
      if (res?.status === 200) {
        onUpdate({ action: 'REMOVE', field: { id: param.id } });
        setOpen(false);
      }
      if (res?.status === 400) {
        setDialogModalData({
          agreeText: 'Yes',
          cancelText: 'No',
          isOpened: true,
          field: param,
          content: <ModalContent field={param} />
        });
      }
    });
  };

  const popupOptions = {
    title: 'Edit Prefix Field',
    field: {
      label: 'Field',
      maxLength: 60,
      errorMessage: 'This field is required'
    },
    buttons: {
      onUpdate: handleSaveParameter,
      onDelete: handleDeleteParameter,
      isDeleteAvailable: isAdminUser
    }
  };

  const agreeModal = () => {
    const { field } = dialogModalData;
    dispatch(deletePrefixField({ id: field.id, allowDelete: true })).then((res) => {
      if (res?.status === 200) {
        onUpdate({ action: 'REMOVE', field: { id: field.id } });
        setOpen(false);
        closeModal();
      }
    });
  };
  const closeModal = () => setDialogModalData({});

  return (
    open && (
      <>
        <ParameterEditPopup open={open} setOpen={setOpen} data={data} options={popupOptions} />
        <ConfirmationPopup data={dialogModalData} onAgree={agreeModal} onDismiss={closeModal} />
      </>
    )
  );
}
