import { allKeyboardLatSymbols } from 'helpers/AppHelpers';

// All IDs for dynamic inputs are pre-installed on backend:
export const ASSET_REPORT_VALIDATION_RULES = [
  {
    id: 29,
    name: 'Mileage',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 50
  },
  {
    id: 32,
    name: 'Remarks',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 1000
  }
];
