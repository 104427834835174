import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const AntSwitch = withStyles(() => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    margin: 2,
    color: '#fff',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#507A8E',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#E0E0E0',
        borderColor: '#E0E0E0'
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid #E0E0E0`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#E0E0E0',
    height: 14
  },
  checked: {}
}))(Switch);

const useStyles = makeStyles(() => ({
  switchLabel: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 14
  }
}));

export default function StyledSwitch({ name, label, checked, handleChange, reversed }) {
  const classes = useStyles();
  return (
    <FormGroup>
      <Typography component="div">
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          direction={reversed ? 'row-reverse' : 'row'}>
          <Grid className={classes.switchLabel} item>
            {label}
          </Grid>
          <Grid item>
            <AntSwitch name={name} checked={checked} onChange={handleChange} />
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
}
