import React from 'react';
import styles from '../Form.module.scss';

import { FormLabel, FormSearchInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

import { useFormContext } from 'react-hook-form';
import { useAssetSelector } from 'hooks/Asset';
import { useParams } from 'react-router-dom';

import SectionTitle from '../SectionTitle';

export function Location() {
  const { id } = useParams();

  const { setValue, watch } = useFormContext();

  const { creationForm } = useAssetSelector();
  const { locations } = creationForm;

  const locationWatcher = watch('lastLocationHistory.changeLocation');

  const setSublocation = (name, value) => setValue(name, value, { shouldDirty: true });

  const setLocation = (name, value) => {
    setSublocation('lastLocationHistory.changeSublocation', {});
    setValue(name, value, { shouldDirty: true });
  };

  return (
    <div className={styles.general}>
      <SectionTitle>Location</SectionTitle>

      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_row}>
            <FormLabel>Current Location</FormLabel>
            <FormInputText
              name="lastLocationHistory.location.siteCode"
              options={{ max: 100, isDisabled: true }}
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel required={!id}>Change location to</FormLabel>
            <FormSearchInput
              clearable={!!id}
              name="lastLocationHistory.changeLocation"
              options={locations || []}
              onSelect={setLocation}
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Sublocation</FormLabel>
            <FormSearchInput
              clearable
              name="lastLocationHistory.changeSublocation"
              options={locationWatcher?.sublocations || []}
              onSelect={setSublocation}
            />
          </div>
        </div>

        <div className={styles.form__block}>
          <div className={styles.form__block_column}>
            <FormLabel>Location Change Notes</FormLabel>
            <FormTextArea name="lastLocationHistory.changeNotes" options={{ max: 15000 }} />
            <p>
              The &quot;Location Change Notes&quot; field is used only if updating the location. If
              you want to keep the asset at the same location while updating the location notes for
              the asset, set the &quot;Change location to&quot; field to the current location and
              enter any applicable notes here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
