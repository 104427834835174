import {
  CLEAR_LOOSE_ITEMS_STATE,
  SET_CATEGORIES_FOR_LOOSE_ITEM_CREATE,
  SET_CATEGORIES_FOR_ITEMS_FILTER,
  SET_CURRENT_SINGLE_ITEM,
  SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE,
  SET_LOCATIONS_FOR_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_LIST,
  SET_STATUSES_FOR_ITEMS_FILTER,
  SET_TOTAL_QUANTITY,
  SET_TOTAL_BAA,
  SET_CHECKED_LOOSE_ITEMS,
  SET_LOOSE_ITEM_CATEGORY_FIELDS,
  SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS,
  SET_LOOSE_ITEMS_FOR_ITEMS_FILTER,
  RESET_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_FILTER_CRITERIA
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    categoryIds: [],
    looseItemIds: [],
    locationIds: [],
    locationJobNumberIds: [],
    statusNames: [],
    showWithBaa: false,
    showWithReorderTrigger: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  tableData: [],
  listFilter: { ...defaultFilter },
  filterCriteria: {},
  locationList: [],
  statusList: [],
  categoryList: [],
  looseItems: [],
  currentItem: {},
  createLocationList: [],
  createCategoryList: [],
  totalQuantity: null,
  totalBaa: null,
  checkedItems: [],
  looseItemFields: [],
  customColumns: []
};

const looseItemsAndPartsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOOSE_ITEMS_LIST:
      return { ...state, tableData: payload };
    case SET_LOOSE_ITEMS_FILTER:
      return { ...state, listFilter: payload || defaultFilter };
    case RESET_LOOSE_ITEMS_FILTER:
      return {
        ...state,
        listFilter: { ...state.listFilter, filters: { ...defaultFilter.filters, ...payload } }
      };
    case SET_LOOSE_ITEMS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_LOCATIONS_FOR_LOOSE_ITEMS_FILTER:
      return { ...state, locationList: payload };
    case SET_STATUSES_FOR_ITEMS_FILTER:
      return { ...state, statusList: payload };
    case SET_CATEGORIES_FOR_ITEMS_FILTER:
      return { ...state, categoryList: payload };
    case SET_LOOSE_ITEMS_FOR_ITEMS_FILTER:
      return { ...state, looseItems: payload };
    case SET_CURRENT_SINGLE_ITEM:
      return { ...state, currentItem: payload };
    case SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE:
      return { ...state, createLocationList: payload };
    case SET_CATEGORIES_FOR_LOOSE_ITEM_CREATE:
      return { ...state, createCategoryList: payload };
    case SET_LOOSE_ITEM_CATEGORY_FIELDS:
      return { ...state, looseItemFields: payload };
    case SET_TOTAL_QUANTITY:
      return { ...state, totalQuantity: payload };
    case SET_TOTAL_BAA:
      return { ...state, totalBaa: payload };
    case SET_CHECKED_LOOSE_ITEMS:
      return { ...state, checkedItems: payload };
    case SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS:
      return { ...state, customColumns: payload };
    case CLEAR_LOOSE_ITEMS_STATE:
      return {
        ...initialState,
        listFilter: getClearedFilterByUnmount(state.listFilter),
        totalQuantity: state.totalQuantity,
        totalBaa: state.totalBaa,
        checkedItems: state.checkedItems,
        customColumns: state.customColumns
      };
    default:
      return state;
  }
};

export default looseItemsAndPartsReducer;
