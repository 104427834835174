import React from 'react';
import styles from './InspectionStatisticTable.module.scss';

import { TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { Table, TableHead, TableBody } from '@material-ui/core';

import { useAssetsDashboardsSelector } from 'hooks/AssetsDashboards';

import {
  getColumnName,
  inspectionTableStatuses,
  inspectionStatusNames,
  generateCellUrl
} from '../../../helpers';

export default function InspectionStatisticTable({ data }) {
  const { tab } = useAssetsDashboardsSelector();
  const { durations, id } = data;

  const columns = durations.map(({ dateFrom, dateTo }) => getColumnName(dateFrom, dateTo));

  const getRowCounts = (statusName) => {
    const obj = {
      [inspectionStatusNames.inspected]: 'assetInspected',
      [inspectionStatusNames.needToBeInspected]: 'assetNeedToBeInspected',
      [inspectionStatusNames.notInspected]: 'notInspected',
      [inspectionStatusNames.total]: 'total'
    };

    return statusName ? durations.map((el) => el[obj[statusName]]) : [];
  };

  const transformedData = inspectionTableStatuses.map(({ name, displayName }) => ({
    status: displayName,
    counts: getRowCounts(name),
    columnName: name
  }));

  const generateUrlParams = (columnName, index) => ({
    dashboardId: id,
    dashboardType: tab,
    dashboardDurationId: durations[index].id,
    dashboardColumnName: columnName
  });

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Inspection Status</StyledTableCell>
              {columns.map((name, index) => (
                <StyledTableCell key={index}>{name}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {transformedData.map(({ status, counts, columnName }, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{status}</StyledTableCell>
                {counts.map((count, index) => (
                  <StyledTableCell key={index}>
                    <LinkComponent
                      name={count || 0}
                      isRedirectAvailable={count > 0}
                      path={generateCellUrl(generateUrlParams(columnName, index))}
                    />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
