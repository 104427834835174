import styles from './Layout.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export default function Preview(props) {
  const {
    meta: { percent, status, name }
  } = props;

  return (
    <>
      {status !== 'done' && status !== 'error_file_size' && status !== 'aborted' && (
        <div className={styles.previewWrapper}>
          <span className={status !== 'done' && styles.previewImageLoading}>{name}</span>

          <CircularProgress
            className={styles.previewImageSpinner}
            variant="determinate"
            value={Math.round(percent)}
          />
        </div>
      )}
    </>
  );
}
