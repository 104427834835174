import React from 'react';
import styles from './TicketComponents.module.scss';

import Subhead from 'components/DetailsComponents/Subhead';

import { useTicketSelector } from 'hooks/Ticket';

export function Description() {
  const { currentTicket } = useTicketSelector();
  const { description } = currentTicket;

  return (
    <div className={styles.description}>
      <Subhead>Description</Subhead>
      <label className={styles.description__info}>{description || ''}</label>
    </div>
  );
}
