import { useSelector } from 'react-redux';

import {
  selectFilterCriteriaData,
  selectCreationFormData,
  selectSingleTicketData,
  selectTicketFilter,
  selectTicketsCounting,
  selectPrefilledAssetData
} from 'pages/AssetTickets/AssetTicketSummary/selectors';

export default function useTicketSelector() {
  const filter = useSelector(selectTicketFilter());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const creationForm = useSelector(selectCreationFormData());

  const counting = useSelector(selectTicketsCounting());

  const currentTicket = useSelector(selectSingleTicketData());

  const prefilledAsset = useSelector(selectPrefilledAssetData());

  return {
    filter,
    filterCriteria,
    creationForm,
    prefilledAsset,
    counting,
    currentTicket
  };
}
