import React from 'react';
import FsLightbox from 'fslightbox-react';

export default function GalleryLightbox({ open, photos }) {
  return (
    <FsLightbox
      toggler={open}
      type="image"
      sources={photos.map(({ link }) => link)}
      customAttributes={photos.map(({ rotationAngle }) => ({
        style: { transform: `rotate(${rotationAngle}deg)` }
      }))}
      exitFullscreenOnClose={true}
      slide={1}
    />
  );
}
