import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import styles from './EditingToggle.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { reformatNumberValue } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function EditingToggle({ name, inputValue, onAccept, disabled }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleOpen = () => {
    setOpen(true);
    setValue(inputValue || 0);
  };
  const handleFocus = (event) => event.target.select();
  const onInput = (event) => setValue(reformatNumberValue(event.target.value));

  const handleClick = (param) => {
    if (param === 'accept') {
      onAccept(name, value || 0);
    }
    setOpen(false);
  };

  return !open ? (
    <div className={clsx(styles.editing, disabled && styles.editing_disabled)} onClick={handleOpen}>
      {inputValue}
    </div>
  ) : (
    <div className={styles.editing}>
      <TextField
        name={name}
        className={styles.textInput}
        variant="outlined"
        value={value}
        onChange={onInput}
        onFocus={handleFocus}
      />
      <div className={styles.helpers}>
        <Button onClick={handleClick} className={styles.alert}>
          <CloseIcon />
        </Button>
        <Button onClick={() => handleClick('accept')} className={styles.success}>
          <CheckIcon />
        </Button>
      </div>
    </div>
  );
}
