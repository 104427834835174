import React from 'react';
import styles from './AssetNotes.module.scss';

import Subtitle from 'components/DetailsComponents/Subtitle';

import { useAssetSelector } from 'hooks/Asset';

export default function AssetNotes() {
  const { currentAsset } = useAssetSelector();
  const { notes } = currentAsset;

  return (
    <div className={styles.wrapper}>
      <Subtitle>Notes</Subtitle>
      <div className={styles.notes}>{notes || ''}</div>
    </div>
  );
}
