import React from 'react';
import styles from './HeadRows.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { useTrackerSelector } from 'hooks/TeamMemberTracker';
import { reformatDateByOptions } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function DayRow() {
  const { trackerTableData } = useTrackerSelector();
  const { header } = trackerTableData;

  const getDays = ({ day }) => reformatDateByOptions(day, { weekday: 'short' });
  const data = header?.map(getDays) || [];

  return (
    <StyledTableRow>
      <StyledTableCell className={clsx(styles.initialCell, styles.day)}></StyledTableCell>
      {data.map((cell, index) => (
        <StyledTableCell key={index} className={styles.dayCell}>
          <div className={styles.dayBlock}>
            <span>{cell.toUpperCase()}</span>
          </div>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
