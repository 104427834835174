import React, { useRef, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectReportComparedData } from '../selectors';
import { getReportComparedData, getCompareDataSerialized } from 'actions/reportListActions';
import { formatDate, fullDateFormat } from 'helpers/AppHelpers';
import TablePagination from 'components/TablePagination/TablePagination';
import {
  MainButton,
  StyledTableCell,
  StickyTableCell,
  StyledTableRow
} from 'components/StyledComponents';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import csvIcon from 'assets/images/csvIcon.svg';
import styles from './CompareReports.module.scss';
import { setTopPosition } from 'actions/commonActions';

function ProjectList({
  isMobile,
  requestData,
  setPreconditionState,
  reportComparedData,
  getReportComparedDataAction,
  getCompareDataSerializedAction,
  setTopPositionAction
}) {
  const tableRef = useRef(null);
  const [initValues, setInitValues] = useState({});

  const { generalReportsSlicedByDate, dateFrom, dateTo, location, pageNumber, totalPages } =
    initValues;

  const { comparedGeneralReportFields } = requestData;

  useEffect(() => {
    if ('generalReportTemplate' in reportComparedData) {
      setInitValues(reportComparedData);
    }
  }, [reportComparedData]);

  useEffect(() => {
    setInitValues({});
  }, []);

  const getTotalValue = (index) => {
    return generalReportsSlicedByDate
      .flatMap((row) => +row.fields[index]?.value)
      .reduce((sum, cur) => sum + cur);
  };

  const onPageSelect = (page) => {
    getReportComparedDataAction({ ...requestData, pageNumber: page });
    const elemRectData = tableRef.current?.getBoundingClientRect();
    setTopPositionAction(elemRectData?.y + (isMobile ? -25 : 0));
  };

  const changeParametersClickHandle = () => {
    setPreconditionState(true);
  };

  const downloadClickHandle = () => {
    getCompareDataSerializedAction({ ...requestData, serializationType: 'Csv' }).then((res) => {
      const a = document.createElement('a');
      a.href = 'data:text/csv;charset=utf-8,' + encodeURI(res);
      a.target = '_blank';
      a.download = `comparedReports-${new Date().getTime()}.csv`;
      a.click();
    });
  };

  return (
    <section className={`${styles.pageContainer} ${styles.dataView}`}>
      <section className={styles.headerWrapper}>
        <div className={styles.pageHeader}>
          <h1>Compare reports data</h1>
          <div className={styles.pageHeader__controls}>
            {!isMobile && (
              <button className={styles.downloadButton} onClick={downloadClickHandle}>
                <img src={csvIcon} alt="" />
                Download CSV file
              </button>
            )}
            <MainButton
              text="Change parameters"
              action={changeParametersClickHandle}
              type="primary"
            />
          </div>
        </div>
      </section>
      {location?.id && (
        <>
          {!isMobile && (
            <section className={styles.pageTopBlock}>
              <p>
                <b>Location:</b>
                <span>{location?.siteCode}</span>
              </p>
              <p>
                <b>Duration:</b>
                <span>
                  {dateFrom ? formatDate(dateFrom) : ''}
                  {' - '}
                  {dateTo ? formatDate(dateTo) : ''}
                </span>
              </p>
            </section>
          )}
          <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
            <Table stickyHeader={!isMobile}>
              <TableHead>
                <TableRow>
                  {!isMobile ? (
                    <StickyTableCell className={styles.firstRow}>Date</StickyTableCell>
                  ) : (
                    <StyledTableCell className={styles.firstRow}>Date</StyledTableCell>
                  )}
                  {!!comparedGeneralReportFields.length &&
                    comparedGeneralReportFields.map((field, index) => (
                      <StyledTableCell align="left" key={index}>
                        {field.text}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {generalReportsSlicedByDate?.length ? (
                  <>
                    {generalReportsSlicedByDate.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        {!isMobile ? (
                          <StickyTableCell className={styles.rightBorder} align="left">
                            <div>{row.date ? formatDate(row.date, fullDateFormat) : ''}</div>
                          </StickyTableCell>
                        ) : (
                          <StyledTableCell className={styles.rightBorder} align="left">
                            <div>{row.date ? formatDate(row.date, fullDateFormat) : ''}</div>
                          </StyledTableCell>
                        )}
                        {row.fields?.map((item, itemIndex) => (
                          <StyledTableCell
                            className={styles.rightBorder}
                            key={itemIndex}
                            align="left">
                            <div>{parseInt(item.value) >= 0 ? item.value : `N/A`}</div>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                    <StyledTableRow>
                      {!isMobile ? (
                        <StickyTableCell className={styles.rightBorder} align="left">
                          <div>TOTAL</div>
                        </StickyTableCell>
                      ) : (
                        <StyledTableCell className={styles.rightBorder} align="left">
                          <div>TOTAL</div>
                        </StyledTableCell>
                      )}
                      {generalReportsSlicedByDate[0]?.fields.map((item, itemIndex) => (
                        <StyledTableCell
                          className={styles.rightBorder}
                          key={itemIndex}
                          align="left">
                          <div>
                            <b>{getTotalValue(itemIndex)}</b>
                          </div>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </>
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={26} className={styles.emptyResponseTable}>
                      No available data in the table for this month.
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            isMobile={isMobile}
            currentPage={pageNumber}
            totalPages={totalPages}
            hideLimitSelector={true}
            onPageSelect={onPageSelect}
          />
        </>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  reportComparedData: selectReportComparedData()
});

const mapDispatchToProps = {
  getReportComparedDataAction: getReportComparedData,
  getCompareDataSerializedAction: getCompareDataSerialized,
  setTopPositionAction: setTopPosition
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProjectList);
