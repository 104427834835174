import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './LicenseExpirationPopper.module.scss';
import { Fade, Paper, Popper } from '@material-ui/core';

export default function LicenseExpirationPopper({ isExpired }) {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => isExpired && setOpen(true);

  const handleMouseLeave = () => isExpired && setOpen(false);

  return (
    <>
      <Popper open={!!open} anchorEl={() => iconRef.current} placement="top-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} style={{ transformOrigin: 'right top' }} timeout={0}>
            <Paper className={styles.paper}>
              <div className={styles.container}>
                <span>The Training or Certificate is expired.</span>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div tabIndex="0">
        <span
          className={clsx(styles.statusIndicator, isExpired ? styles.Critical : styles.Transparent)}
          ref={iconRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}></span>
      </div>
    </>
  );
}
