import {
  NOTIFICATION_CENTER_ASSET_REPORT_TABLE_PARAMETER,
  NOTIFICATION_CENTER_GENERAL_REPORT_TABLE_PARAMETER,
  NOTIFICATION_CENTER_LOOSE_ITEMS_TABLE_PARAMETER,
  NOTIFICATION_CENTER_REQUEST_ORDER_TABLE_PARAMETER,
  NOTIFICATION_CENTER_LOCATION_REPORT_TABLE_PARAMETER,
  NOTIFICATION_CENTER_TEAM_MEMBER_STATUS_TABLE_PARAMETER,
  NOTIFICATION_CENTER_TICKET_AND_ASSET_UPDATE_TABLE_PARAMETER
} from 'constants/configTableConstants';

export const tabs = {
  assetReport: 'AssetReportTab',
  ticketAndAssetTab: 'TicketAndAssetUpdateTab',
  looseItems: 'LooseItemTab',
  generalReport: 'GeneralReportTab',
  requestOrder: 'RequestOrderTab',
  locationReport: 'LocationReportTab',
  teamMemberStatus: 'TeamMemberStatusTab'
};

export const TOGGLE_BUTTONS = [
  { id: 1, value: tabs.locationReport, label: 'Location Report' },
  { id: 2, value: tabs.assetReport, label: 'Asset Report' },
  { id: 3, value: tabs.generalReport, label: 'General Report' },
  { id: 4, value: tabs.ticketAndAssetTab, label: 'Ticket & Asset Update' },
  { id: 5, value: tabs.looseItems, label: 'Loose Items' },
  { id: 6, value: tabs.requestOrder, label: 'Request Order' },
  { id: 7, value: tabs.teamMemberStatus, label: 'Team Member Status' }
];

export const defaultTab = tabs.locationReport;

export const getTableParameter = (selectedTab) => {
  const params = {
    [tabs.assetReport]: NOTIFICATION_CENTER_ASSET_REPORT_TABLE_PARAMETER,
    [tabs.generalReport]: NOTIFICATION_CENTER_GENERAL_REPORT_TABLE_PARAMETER,
    [tabs.looseItems]: NOTIFICATION_CENTER_LOOSE_ITEMS_TABLE_PARAMETER,
    [tabs.requestOrder]: NOTIFICATION_CENTER_REQUEST_ORDER_TABLE_PARAMETER,
    [tabs.locationReport]: NOTIFICATION_CENTER_LOCATION_REPORT_TABLE_PARAMETER,
    [tabs.teamMemberStatus]: NOTIFICATION_CENTER_TEAM_MEMBER_STATUS_TABLE_PARAMETER,
    [tabs.ticketAndAssetTab]: NOTIFICATION_CENTER_TICKET_AND_ASSET_UPDATE_TABLE_PARAMETER
  };

  return params[selectedTab];
};
