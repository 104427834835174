import React from 'react';

import UserDetailsHeader from './components/Header';
import UserDetailsWrapper from './components/UserDetailsWrapper';
import UserDetailsInfo from './components/Details/Details';

export default function UserDetailed() {
  return (
    <UserDetailsWrapper>
      <UserDetailsHeader />
      <UserDetailsInfo />
    </UserDetailsWrapper>
  );
}
