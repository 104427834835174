import React, { useEffect, useState } from 'react';
import styles from './PhotoGallery.module.scss';
import expandIcon from 'assets/images/expandIcon.svg';
import dummyPicture from 'assets/images/dummyPicture.svg';
import { isEqual } from 'helpers/AppHelpers';
import GalleryLightbox from './GalleryLightbox';

export const PhotoGallery = ({ resources }) => {
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const sortedImages = resources?.filter((resource) => resource['resourceType']?.id === 1) || [];

    if (isEqual(sortedImages, photos)) return;
    setPhotos(sortedImages);
  }, [resources]);

  const handleOpen = () => photos?.length && setOpen(!open);

  return (
    <>
      <GalleryLightbox open={open} photos={photos} />
      <div className={styles.pictureBlock} onClick={handleOpen}>
        {photos.length ? (
          <>
            <img
              className={styles.galleryPicture}
              src={photos[0].link}
              alt=""
              style={{ transform: `rotate(${photos[0].rotationAngle}deg)` }}
            />
            <section className={styles.expandHint}>
              <img src={expandIcon} alt="" />
            </section>
            <p>1/{photos.length}</p>
          </>
        ) : (
          <img src={dummyPicture} className={styles.dummyPicture} alt="" />
        )}
      </div>
    </>
  );
};
