import { createSelector } from 'reselect';

const data = (state) => state.teamMemberHistoryData;

export const selectTMHistoryTableData = () => createSelector([data], (state) => state.tableList);

export const selectTMHistoryFilterData = () => createSelector([data], (state) => state.filter);

export const selectHistoryFilterCriteriaData = () => createSelector([data], (state) => state.filterCriteria);

export const selectHistorySecondaryFilterCriteriaData = () => createSelector([data], (state) => state.secondaryFilterCriteria);
