import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { UPLOAD_IMG_URL } from 'api/common';
import { getToken } from 'api/api';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { ALERT } from 'constants/infoPopupsData';
import styles from './ImagesUpload.module.scss';
import Layout from './components/Layout/Layout';
import { FILES_LIMIT, MAX_FILE_SIZE } from './UploaderConfig';
import { Input } from './components/InputComponent/InputComponent';
import Preview from './components/Layout/Preview';

const AVAILABLE_FORMATS = {
  general: {
    match: /(jpg|jpeg|png|gif|bmp|webp)/,
    formats: 'JPG, JPEG, PNG, GIF, BMP, WEBP'
  },
  createEntity: {
    match: /(jpg|jpeg|png|gif|bmp|webp)/,
    formats: 'JPG, JPEG, PNG, GIF, BMP, WEBP'
  }
};

export default function ImagesUpload({ images, onChange, index, formatType }) {
  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: ALERT,
    title: '',
    buttonText: 'Ok'
  });

  const getUploadParams = () => {
    if (getToken()) {
      return {
        url: UPLOAD_IMG_URL,
        headers: {
          authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
        }
      };
    }
  };

  const handleChangeStatus = (fileWithMeta, status, files) => {
    const { meta } = fileWithMeta;

    const uniqueFileArray = [
      ...images,
      ...files?.map((file) => JSON.parse(file.xhr?.response || null))?.filter(Boolean)
    ].filter(
      (currentFile, index, filesArray) =>
        filesArray.findIndex((file) => file.id === currentFile.id) === index
    );

    if (!meta.type.match(AVAILABLE_FORMATS[formatType || 'general'].match)) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" couldn't be uploaded. 
                Only files with following extensions are allowed: 
                ${AVAILABLE_FORMATS[formatType || 'general'].formats}`,
        isOpened: true
      });
    } else if (meta.size > MAX_FILE_SIZE) {
      setPopupData({
        ...popupData,
        title: `File "${meta.name}" exceeds maximum allowed upload size of 10MB`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (uniqueFileArray.length > FILES_LIMIT) {
      setPopupData({
        ...popupData,
        title: `Upload limit (${FILES_LIMIT}) exceeded`,
        isOpened: true
      });
      fileWithMeta.cancel();
    } else if (status === 'done') {
      onChange(uniqueFileArray, index);
      fileWithMeta.remove();
    }
  };

  return (
    <>
      <InfoPopup data={popupData} onClose={() => setPopupData({ ...popupData, isOpened: false })} />
      <section className={styles.dropzoneWrapper}>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent={Input}
          inputWithFilesContent={Input}
          LayoutComponent={(props) => (
            <Layout images={images} onChange={onChange} index={index} {...props} />
          )}
          PreviewComponent={Preview}
          maxSizeBytes={MAX_FILE_SIZE}
          accept="image/jpg,image/jpeg,image/png,image/gif,image/bmp,image/webp"
          classNames={{
            dropzone: styles.dropzone,
            input: styles.dropzone_input,
            inputLabelWithFiles: styles.dzu_inputLabel,
            inputLabel: styles.dzu_inputLabel
          }}
        />
      </section>
    </>
  );
}
