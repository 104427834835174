import React, { useEffect, useState } from 'react';
import styles from './Buttons.module.scss';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import AddColumnModal from 'components/AddColumnModal';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';

export default function AddColumnButton() {
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState([]);

  const { getLooseItemFieldsAction, setCustomColumnsAction } = useLooseItemActions();
  const { customColumns } = useLooseItemSelector();

  useEffect(() => {
    if (!open) return;
    getLooseItemFieldsAction({ disableRedux: true }).then((res) => setFields(res));
  }, [open]);

  const handleClick = () => setOpen(true);

  const applyClick = (selected) => setCustomColumnsAction(selected);

  return (
    <>
      {open && !!fields?.length && (
        <AddColumnModal
          open={open}
          setOpen={setOpen}
          items={fields}
          onApply={applyClick}
          selected={customColumns}
        />
      )}
      <Button className={styles.add_column_button} onClick={handleClick}>
        <AddIcon /> Add column
      </Button>
    </>
  );
}
