import React, { useRef, useState } from 'react';
import { Button, ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core';
import styles from './Poppers.module.scss';
import PendingOutlined from 'assets/images/moreDots.svg';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import GalleryLightbox from 'components/PhotoGallery/GalleryLightbox';

function ImagesPopper({ images }) {
  const elRef = useRef();
  const [open, setOpen] = useState(false);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState([]);

  const showPopper = () => setOpen(true);
  const hidePopper = () => setOpen(false);

  const handleImageClick = (image) => {
    setSelectedPhoto([image]);
    setIsGalleryOpen(!isGalleryOpen);
    hidePopper();
  };

  return (
    <div className={styles.buttonWrapper}>
      <GalleryLightbox open={isGalleryOpen} photos={selectedPhoto} />

      <Button ref={elRef} onClick={showPopper} className={styles.dotsButton}>
        <img src={PendingOutlined} alt="pending" />
      </Button>

      <Popper open={open} anchorEl={() => elRef.current} transition placement="top-end">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper classes={{ root: styles.mobileContainer }}>
              <ClickAwayListener onClickAway={hidePopper}>
                <div className={styles.images}>
                  {images.map((img, index) => (
                    <div
                      key={index}
                      className={styles.images__item}
                      style={{ transform: `rotate(${img.rotationAngle}deg)` }}
                      onClick={() => handleImageClick(img)}>
                      <img src={img.link} alt="" />
                      <div className={styles.images__item_icon}>
                        <FullscreenIcon />
                      </div>
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default ImagesPopper;
