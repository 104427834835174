import React from 'react';
import styles from '../Specifications.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../../selectors';
import SkillChip from '../SkillChip';

export default function ReadOnlyDepartments() {
  const { readOnlyDepartments } = useSelector(selectCurrentTeamMemberData());

  return (
    <div className={styles.specification}>
      <label>Read-only Project Schedules:</label>
      <div className={styles.specification__block}>
        {!!readOnlyDepartments.length &&
          readOnlyDepartments.map((item) => <SkillChip key={item.id} label={item?.name || ''} />)}
      </div>
    </div>
  );
}
