import React, { useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingLogs.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton, DownloadCSVButton, ImportCsvButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import ImportCsvPopup from 'components/ImportCsvPopup';
import { FileListPopper } from 'components/Poppers';

import { IconButton, Table, TableBody } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useBillOfLadingLogsActions, useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useHistory } from 'react-router-dom';

import {
  cloneObj,
  dateTimeFormat,
  fileDownload,
  formatDate,
  getUserFullName,
  isSuccessfulStatus
} from 'helpers/AppHelpers';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';

import { BILL_OF_LADING_LOG_EDIT_PATH, ROOT_PATH } from 'constants/routeConstants';
import { BOL_LOGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { DELETE_BOL_LOG } from 'constants/dialogPopupsData';

import {
  generateChips,
  generateInitialQuery,
  parseQuery,
  removeChip,
  tableHeadConfig
} from './helpers';
import FilterModal from './Filter';

export function BillOfLadingLogs() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const tableRef = useRef(null);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled, isAdminUser, isEntityCreatorUser } =
    useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useBillOfLadingLogsSelector();
  const {
    getLogsAction,
    getFilterCriteriaAction,
    getCsvResourceAction,
    setFilterAction,
    removeLogAction,
    getCsvTemplateAction,
    importCsvAction,
    clearStateAction
  } = useBillOfLadingLogsActions();

  const [tableState, setTableState] = useState([]);
  const [modalData, setModalData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [chips, setChips] = useState([]);

  const { filters, pagination, sortRules, responseReceived } = filter;

  const getLogs = (query, isAutoload) => {
    setFilterAction(query);

    const payload = cloneObj(query);
    payload.filters.createdUserList = [];

    getLogsAction(parseQuery(payload)).then((tableData) => {
      setLogs(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setLogs = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then(() => {
      const resultFilter = generateInitialQuery({ filter, getTableLimit, isAllLimitEnabled });
      getLogs(resultFilter);

      const options = {
        ...filterCriteria,
        users: filters?.createdUserList || []
      };
      setChips(generateChips(options, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = (value) => getLogs(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getLogs(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getLogs(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const changeLimit = () =>
    getLogs(getChangedByLimitFilter(filter, getTableLimit(BOL_LOGS_TABLE_PARAMETER)));
  const applyFilter = (values) => {
    getLogs(getChangedByApplyFilter(filter, values));

    const options = {
      ...filterCriteria,
      users: values?.createdUserList || []
    };
    setChips(generateChips(options, values));
  };

  const downloadCSV = () =>
    getCsvResourceAction({ filter }).then((file) => file?.link && fileDownload(file));

  const addBolLog = () => history.push(BILL_OF_LADING_LOG_EDIT_PATH);
  const editBolLog = (id) => history.push(`${BILL_OF_LADING_LOG_EDIT_PATH}/${id}`);

  const deleteRow = (deletedRowId) =>
    setModalData({ isOpened: true, ...DELETE_BOL_LOG, deletedRowId });

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    removeLogAction(modalData.deletedRowId).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        getLogs(filter);
        closeModal();
      }
    });
  };

  const openFilterModal = () => setOpenFilter(true);
  const openImportCsvPopup = () => setOpenImportPopup(true);

  const deleteChip = (chip) => applyFilter(removeChip(filters, chip));

  const importCsv = (resource) => importCsvAction({ resource });

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="BOL Shipped and Received Logs">
          <div className={styles.controls}>
            <ImportCsvButton label="Import BoL Log" onClick={openImportCsvPopup} />
            <DownloadCSVButton onClick={downloadCSV} />
            <StyledButton label="Add BoL Log" onClick={addBolLog} classes={styles.main_button} />
          </div>
        </SummaryPageHeader>
      </SummaryHeaderWrapper>

      <SummaryTableControls>
        <FilterWrapper>
          <FilterButton onClick={openFilterModal} isActive={openFilter || chips?.length} />
          <FilterChips chips={chips} onDelete={deleteChip} />
        </FilterWrapper>
        <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
      </SummaryTableControls>

      <TableWrapper ref={tableRef} enableHorizontalScroll>
        <Table className={styles.logs_table}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.logs_table_row}>
                  <StyledTableCell className={styles.idCell}>{row?.id || ''}</StyledTableCell>
                  <StyledTableCell className={styles.typeCell}>
                    {row?.type?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.locationCell}>
                    {row?.originLocation || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.destinationCell}>
                    {row?.destination || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.itemCell}>{row?.item || ''}</StyledTableCell>
                  <StyledTableCell className={styles.qtyCell}>{row?.qty || ''}</StyledTableCell>
                  <StyledTableCell className={styles.packageCell}>
                    {row?.package || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.palletCell}>
                    {row?.pallet || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.dateCell}>{row?.date || ''}</StyledTableCell>
                  <StyledTableCell className={styles.carrierCell}>
                    {row?.carrier || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.vendorCell}>
                    {row?.vendor || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.fileCell}>
                    <FileListPopper resources={row?.resources || []} />
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdByCell}>
                    {getUserFullName(row.createdByUser)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdDateCell}>
                    {formatDate(row?.createdAtUtc, dateTimeFormat) || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.row_controls}>
                      {(isAdminUser || isEntityCreatorUser(row.createdByUser.email)) && (
                        <IconButton
                          onClick={() => editBolLog(row.id)}
                          className={styles.icon_button}>
                          <CreateIcon />
                        </IconButton>
                      )}
                      {isAdminUser && (
                        <IconButton
                          onClick={() => deleteRow(row.id)}
                          className={styles.icon_button}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={BOL_LOGS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}

      {openImportPopup && (
        <ImportCsvPopup
          open={openImportPopup}
          setOpen={setOpenImportPopup}
          getTemplate={getCsvTemplateAction}
          onApply={importCsv}
        />
      )}
    </SummaryWrapper>
  );
}
