import React from 'react';
import styles from './components.module.scss';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

export default function DisplayOnDailyCheckbox() {
  return (
    <div className={styles.checkboxWrapper}>
      <div className={styles.checkboxWrapper__checkbox}>
        <FormCheckbox name="onSiteReport" />
        <label>Display on daily site report form</label>
      </div>
    </div>
  );
}
