import React, { forwardRef } from 'react';
import styles from './TableWrapper.module.scss';

import { Paper, TableContainer } from '@material-ui/core';

import clsx from 'clsx';

const TableWrapper = forwardRef((props, ref) => {
  return (
    <TableContainer
      ref={ref}
      className={clsx(styles.wrapper, props.enableHorizontalScroll && styles.horizontalScroll)}
      component={Paper}>
      {props.children}
    </TableContainer>
  );
});

TableWrapper.displayName = 'TableWrapper';

export default TableWrapper;
