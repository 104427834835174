import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions } from 'components/FilterComponentsV2';
import {
  FormDayPicker,
  FormLabel,
  FormMultipleUserSearch,
  FormSelectInput
} from 'components/FormComponents';

import { FormProvider, useForm } from 'react-hook-form';
import { useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';

export default function LogsFilter({ open, setOpen, onApply }) {
  const methods = useForm({
    defaultValues: {
      createdFrom: null,
      createdTo: null,
      createdUserIds: [],
      createdUserList: [],
      typeIds: []
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch } = methods;

  const { filter, filterCriteria } = useBillOfLadingLogsSelector();

  const { types } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      createdFrom: null,
      createdTo: null,
      createdUserIds: [],
      createdUserList: [],
      typeIds: []
    };
    reset(defaultState);
  };

  const selectType = ({ name, value }) => setValue(name, value);

  const createdFromWatcher = watch('createdFrom');
  const createdToWatcher = watch('createdTo');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>{`Log's Type`}</FormLabel>
                <FormSelectInput
                  name="typeIds"
                  clearable
                  arrayValue
                  options={types || []}
                  onSelect={selectType}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created by</FormLabel>
                <FormMultipleUserSearch
                  name="createdUserIds"
                  listName="createdUserList"
                  getQueryOptions={(searchQuery) => ({
                    pagination: { limit: 40, page: 1 },
                    filters: { shouldIncludeCurrentUser: true, searchQuery }
                  })}
                />
              </div>
            </div>

            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Created from</FormLabel>
                <FormDayPicker name="createdFrom" max={createdToWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created to</FormLabel>
                <FormDayPicker name="createdTo" min={createdFromWatcher} max={new Date()} />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
