import React from 'react';
import styles from './templates.module.scss';

export default function Title({ field, isRequired }) {
  return (
    <h1 className={styles.title}>
      {field.text}
      {isRequired && '*'}
    </h1>
  );
}
