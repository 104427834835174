import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import { ROOT_PATH } from '../constants/routeConstants';
import { useSelector } from 'react-redux';
import { selectUserConfig } from 'pages/commonSelectors';
import { isAdminUser, isPersonnelManagerUser } from 'helpers/AppHelpers';

function TeamScheduleGuard({ component }) {
  const history = useHistory();
  const { userRole, teamMember, teamMemberProjectsExist, departments } = useSelector(
    selectUserConfig()
  );

  const isTeamScheduleAvailable = () =>
    teamMember?.id ? teamMemberProjectsExist : !!departments?.length;
  const suitableRole =
    isAdminUser(userRole) || isPersonnelManagerUser(userRole) ? true : isTeamScheduleAvailable();

  useEffect(() => {
    if (userRole) {
      if (!suitableRole) {
        history.push(ROOT_PATH);
      }
    }
  }, [userRole]);

  return suitableRole ? component : null;
}

export default withRouter(TeamScheduleGuard);
