import {
  CLEAR_BILL_OF_LADING_STATE,
  RESET_BILL_OF_LADING_FILTER,
  SET_BILL_OF_LADING_CREATION_FORM,
  SET_BILL_OF_LADING_FILTER,
  SET_CURRENT_BILL_OF_LADING
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    picklistIds: [],
    leavelistIds: [],
    originLocationIds: [],
    destinationLocationIds: [],
    createdFrom: null,
    createdTo: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  currentBoL: {},
  filter: { ...defaultFilter },
  creationForm: { itemsForAdding: [] }
};

const billOfLadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_BILL_OF_LADING:
      return { ...state, currentBoL: payload };
    case SET_BILL_OF_LADING_FILTER:
      return { ...state, filter: payload };
    case RESET_BILL_OF_LADING_FILTER: {
      return { ...state, filter: { ...state.filter, filters: { ...defaultFilter, ...payload } } };
    }
    case SET_BILL_OF_LADING_CREATION_FORM: {
      return { ...state, creationForm: payload };
    }
    case CLEAR_BILL_OF_LADING_STATE:
      return {
        ...initialState,
        filter: getClearedFilterByUnmount(state.filter)
      };
    default:
      return state;
  }
};

export default billOfLadingReducer;
