import {
  checkBuyAheadAccount,
  checkIsLocationEmpty,
  checkLooseItem,
  checkMoveQuantity
} from './validation';

export const getRowLocations = (state, index, locations) => {
  const looseItemId = state[index]?.locationLooseItem?.looseItem?.id;
  const rowId = state[index]?.locationLooseItem?.id;
  if (!looseItemId) return [];

  const filterByLooseItemId = (el) => el?.locationLooseItem?.looseItem?.id === looseItemId;
  const filterLocationsByLooseItemId = (el) => el?.looseItem?.id === looseItemId;
  const countOfLooseItems = state.filter(filterByLooseItemId).length;

  if (countOfLooseItems !== 1) {
    const usedLocationIds = state
      .filter(filterByLooseItemId)
      .map(({ locationLooseItem }) => locationLooseItem.id);
    return locations
      ?.filter(filterLocationsByLooseItemId)
      .filter(({ id }) => (id === rowId ? true : !usedLocationIds.includes(id)));
  }
  return locations?.filter(filterLocationsByLooseItemId);
};

export const getRowLooseItems = (state, index, looseItems, locations) => {
  const selectedItemId = state[index]?.locationLooseItem?.looseItem?.id;
  let usedItemIds = state.map((el) => el?.locationLooseItem?.looseItem?.id);

  usedItemIds = usedItemIds
    .filter((id) => id !== selectedItemId)
    .filter((elemId) => {
      const available = locations.filter(({ looseItem }) => looseItem.id === elemId);
      const selected = state.filter((el) => el?.locationLooseItem?.looseItem?.id === elemId);
      return available.length === selected.length;
    });

  return looseItems.filter(({ id }) => !usedItemIds.includes(id));
};

export const getFirstAvailableLocation = (looseItemId, state, locations) => {
  const usedLocationIds = state
    .filter((el) => el?.locationLooseItem?.looseItem?.id === looseItemId)
    .map(({ locationLooseItem }) => locationLooseItem.id);
  const all = locations.filter(({ looseItem }) => looseItem.id === looseItemId);
  const available = all.filter(({ id }) => !usedLocationIds.includes(id));
  return available[0];
};

export const checkTableRow = (el) => {
  return {
    looseItem: checkLooseItem(el?.locationLooseItem?.looseItem),
    location: checkIsLocationEmpty(el?.locationLooseItem?.location),
    moveQuantity: checkMoveQuantity(el?.locationLooseItem?.quantity, el?.moveQuantity),
    moveByAheadAccount: checkBuyAheadAccount(
      el?.locationLooseItem?.byAheadAccount || 0,
      el?.moveByAheadAccount,
      el?.moveQuantity
    )
  };
};
