import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useParams } from 'react-router-dom';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import TicketEditWrapper from './Wrapper';
import TicketEditForm from './TicketForm';

export default function TicketEdit() {
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      asset: {},
      assignedAtUtc: null,
      assignedToUser: null,
      description: '',
      isOpened: true,
      resources: [],
      rootCause: null,
      severity: {},
      ticketType: null,
      closedAtUtc: '',
      closedByUser: {},
      openedAtUtc: '',
      openedByUser: {},
      remarks: [],
      remarksMessage: '',
      narrowByCategories: [],
      assetPrefixes: [],
      looseItems: [],
      otherItemName: '',
      otherItems: [],
      reopenTicketFrequency: null,
      reopenOnSpecificDate: null
    },
    mode: 'onChange'
  });
  const { reset, setValue, formState } = methods;
  const { isDirty } = formState;

  const { getTicketAction, getCreationFromAction, clearStateAction } = useTicketActions();
  const { currentTicket, prefilledAsset } = useTicketSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { unsavedFormData } = useCommonSelector();

  useEffect(() => {
    if (id) {
      getTicketAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id) return;

    if (prefilledAsset?.id) {
      setValue('asset', prefilledAsset);
      getCreationFromAction({ assetId: prefilledAsset.id });
    } else {
      getCreationFromAction();
    }
  }, [id, prefilledAsset]);

  useEffect(() => {
    if (currentTicket?.id) {
      getCreationFromAction({
        assetId: currentTicket.asset.id,
        ticketId: currentTicket.id
      });
    }
  }, [currentTicket]);

  useEffect(() => {
    if (currentTicket?.id) {
      const { severity } = currentTicket;
      const severityItem = severity?.name ? { ...severity, id: severity.name } : null;
      reset({
        ...currentTicket,
        severity: severityItem,
        includeAssets: !!currentTicket?.assetPrefixes?.length,
        includeLooseItems: !!currentTicket?.looseItems?.length,
        includeOthers: !!currentTicket?.otherItems?.length
      });
    }
  }, [currentTicket]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <TicketEditWrapper>
        <TicketEditForm />
      </TicketEditWrapper>
    </FormProvider>
  );
}
