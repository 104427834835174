import React from 'react';
import styles from './templateFields.module.scss';

export default function TextInfo({ label, children }) {
  return (
    <section className={styles.text_info}>
      {label && <label>{label}</label>}
      <section className={styles.text_details}>
        <b>{children}</b>
      </section>
    </section>
  );
}
