import React, { forwardRef, useEffect, useState } from 'react';
import styles from './ImportFilesPopup.module.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, Fade } from '@material-ui/core';
import { MainButton } from '../StyledComponents';
import UploadFiles from '../UploadFiles/UploadFiles';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deserializeLooseItems, getTemplate } from 'actions/importFilesPopupActions';
import { fileDownload } from 'helpers/AppHelpers';
import downloadIcon from 'assets/images/downloadIcon.svg';

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={0} />;
});

function ImportFilesPopup({ open, setOpen, deserializeLooseItemsAction, getTemplateAction }) {
  const [values, setValues] = useState({});
  const [template, setTemplate] = useState({});

  useEffect(() => {
    getTemplateAction().then((res) => setTemplate(res?.data));
  }, []);

  const { resources } = values;

  const onClear = () => {
    setOpen(false);
    setValues({});
  };

  const onCancel = () => onClear();

  const onApply = () => {
    deserializeLooseItemsAction(values.resources[0]).then((res) => {
      if (res?.status === 200) {
        onClear();
      }
    });
  };

  const getFiles = resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  const onUpload = (files) => {
    const uniqueFilesArray = [...(resources?.length ? resources : []), ...files].filter(
      (currentFile, index, filesArray) =>
        filesArray.findIndex((file) => file.id === currentFile.id) === index
    );
    setValues({ ...values, resources: uniqueFilesArray });
  };

  const onDelete = (file) => {
    setValues({
      ...values,
      resources: resources.filter((resource) => resource.id !== file.id)
    });
  };

  const onFileDownload = () => {
    if (template) {
      fileDownload(template);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: styles.popupContainer
        }}
        BackdropProps={{
          classes: {
            root: styles.backDrop
          }
        }}
        TransitionComponent={Transition}
        disableBackdropClick
        keepMounted
        onClose={onCancel}>
        <DialogTitle
          classes={{
            root: styles.popupTitle
          }}>
          <div className={styles.headerTitle}>
            <h2>Add Loose Items</h2>
          </div>
        </DialogTitle>

        <DialogContent
          classes={{
            root: styles.popupText
          }}>
          <section className={styles.formWrapper}>
            <section className={styles.labelBlock}>
              <span>
                To create loose items using a CSV document, the document must be in a certain
                format.
              </span>
            </section>
            <section className={styles.uploadWrapper}>
              <label>Upload File</label>
              {resources?.length > 1 && <span>Allowed only 1 file (!)</span>}
              <UploadFiles
                uploadedFiles={getFiles}
                onUploadFile={onUpload}
                onDeleteFile={onDelete}
                formatType="looseItems"
              />
            </section>
            <section className={styles.labelBlock}>
              <span>An example of the document format can be found in the attachments.</span>
            </section>
            <section className={styles.labelBlock}>
              <span>
                To set several categories, just type categories names one by one and divide them by
                “/” example: Category 1/ Category 2.
              </span>
            </section>
            <section className={styles.labelBlock}>
              <span>
                To set field and add value to it, just type field name and put value in square
                brackets “[ ]”, example: field[value].
              </span>
            </section>
            <section className={styles.labelBlock}>
              <span>
                To set several fields and add value to them, just type fields one by one, divide them
                by “/” and put value in square brackets “[ ]” to each field, example:
                field1[value1]/field2[value2].
              </span>
            </section>
            <section className={styles.uploadWrapper}>
              <label>File example</label>
              <div className={styles.fileBlock} onClick={onFileDownload}>
                <img src={downloadIcon} alt="download" />
                <span className={styles.fileName}>{template?.name || ''}</span>
              </div>
            </section>
          </section>
        </DialogContent>

        <DialogActions
          classes={{
            root: styles.popupControls
          }}>
          <div className={styles.buttonBlock}>
            <MainButton text="Cancel" action={onCancel} type="secondary" size="popup" />
            <MainButton
              text="Create"
              type="primary"
              action={onApply}
              size="popup"
              isDisabled={!resources?.length || resources?.length > 1}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  getTemplateAction: getTemplate
});

const mapDispatchToProps = {
  deserializeLooseItemsAction: deserializeLooseItems
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ImportFilesPopup);
