import { useDispatch } from 'react-redux';

import {
  getLooseItems,
  getCategories,
  getCustomFields,
  updateLooseItems,
  clearState,
  saveFormState
} from 'actions/batchItemUpdatesActions';

export function useBatchItemUpdatesActions() {
  const dispatch = useDispatch();

  const getLooseItemsAction = (q) => dispatch(getLooseItems(q));
  const getCategoriesAction = (q) => dispatch(getCategories(q));
  const getCustomFieldsAction = (q) => dispatch(getCustomFields(q));

  const updateLooseItemsAction = (data) => dispatch(updateLooseItems(data));

  const saveFormStateAction = (values) => dispatch(saveFormState(values));

  const clearStateAction = () => dispatch(clearState());

  return {
    getLooseItemsAction,
    getCategoriesAction,
    getCustomFieldsAction,
    updateLooseItemsAction,
    saveFormStateAction,
    clearStateAction
  };
}
