import { createSelector } from 'reselect';

const searchData = (state) => state.searchData;

export const selectSearchQueryData = () =>
  createSelector([searchData], (state) => state.searchQuery);

export const selectSearchResultsData = () => createSelector([searchData], (state) => state.results);

export const selectIsDataLoadedData = () =>
  createSelector([searchData], (state) => state.isDataLoaded);
