import React, { useEffect } from 'react';
import styles from './components.module.scss';

import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useUserConfig } from 'hooks/useUserConfig';

import { SCHEDULE_TOGGLE_BUTTONS } from '../helpers';

const MASTER_CSV_ENDPOINT = 'TeamMemberTracker/Serialized';
const BREAKDOWN_CSV_ENDPOINT = 'TeamMemberTrackerBreakdown/Serialized';

export default function Header() {
  const {
    isAdminUser,
    isPersonnelManagerUser,
    isTeamMemberUser,
    isTeamMemberAttachedToTheProjects,
    isConfigReceived
  } = useUserConfig();

  const hasUserKeyRoles = () => isAdminUser || isPersonnelManagerUser;
  const isMasterDisabled =
    !hasUserKeyRoles() && isTeamMemberUser && isTeamMemberAttachedToTheProjects;

  const { setTabAction } = useTrackerActions();
  const { selectedTab, filter, isMasterSelected } = useTrackerSelector();

  useEffect(() => {
    if (isConfigReceived) {
      if (isMasterDisabled) {
        changeTab(null, SCHEDULE_TOGGLE_BUTTONS[1]);
      } else {
        changeTab(null, selectedTab || SCHEDULE_TOGGLE_BUTTONS[0]);
      }
    }
  }, [isConfigReceived]);

  const changeTab = (e, value) => {
    if (!value || value === selectedTab) return;
    setTabAction(value);
  };

  const csvEndPoint = isMasterSelected ? MASTER_CSV_ENDPOINT : BREAKDOWN_CSV_ENDPOINT;

  return (
    <div className={styles.header}>
      <h2>Project Schedule</h2>
      <div className={styles.header__controls}>
        <DownloadCSVButton endpoint={csvEndPoint} filter={filter} includeUserId />
        {!isMasterDisabled && (
          <ToggleButtonGroup
            className={styles.header__controls_bar}
            value={selectedTab}
            exclusive
            onChange={changeTab}>
            {SCHEDULE_TOGGLE_BUTTONS.map((elem, index) => (
              <ToggleButton key={index} classes={{ selected: styles.selected }} value={elem}>
                {elem}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </div>
    </div>
  );
}
