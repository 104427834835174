import React, { useEffect, useState } from 'react';
import styles from './AddBomControls.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import ToggleBar from 'components/ToggleBars/ToggleBar';

import { useFormContext } from 'react-hook-form';
import { useAddBomActions, useAddBomSelector } from 'hooks/AddBomToPicklist';

import { modifyLooseItems, modifyAssets } from '../../../helpers';

export default function Controls({ currentTab, setCurrentTab, children }) {
  const TOGGLE_BUTTONS = [
    { id: 1, value: 'loose_items', label: 'Loose Items' },
    { id: 2, value: 'assets', label: 'Assets' }
  ];
  const [load, setLoad] = useState(false);

  const onClickTab = (tab) => {
    setCurrentTab((prevState) => ({ ...prevState, type: tab }));
  };

  const { setValue, clearErrors, getValues, watch } = useFormContext();

  const { configurations } = useAddBomSelector();
  const { getConfigurationDetailsAction } = useAddBomActions();

  const isLooseItems = watch('looseItems').length > 0;
  const isAssetPrefixes = watch('assets').length > 0;

  useEffect(() => {
    if (isLooseItems && isAssetPrefixes) {
      setCurrentTab({ active: true, type: 'loose_items' });
    } else if (isLooseItems) {
      setCurrentTab({ active: false, type: 'loose_items' });
    } else if (isAssetPrefixes) {
      setCurrentTab({ active: false, type: 'assets' });
    } else {
      setCurrentTab({ active: false });
    }
  }, [isLooseItems, isAssetPrefixes]);

  const selectConfiguration = async (name, value) => {
    clearErrors();
    setValue(name, value);
    setLoad(true);
    const { looseItems, assetPrefixes } = await getConfigurationDetailsAction({
      id: value.id,
      isPicklist: true
    });
    setValue('looseItems', modifyLooseItems(looseItems));
    setValue('assets', modifyAssets(assetPrefixes));
    setLoad(false);
  };

  return (
    <div className={styles.controls}>
      <div className={styles.controls__row}>
        <label>Configuration Name*</label>
        <FormSearchInput
          name="configuration"
          onSelect={selectConfiguration}
          options={configurations}
        />
      </div>
      {currentTab.active && (
        <ToggleBar
          buttons={TOGGLE_BUTTONS}
          onChange={onClickTab}
          selectedTab={currentTab.type}
          classes={styles.controls__toggleBar}
        />
      )}
      {!load && !!getValues('configuration')?.id && (
        <>
          {isAssetPrefixes || isLooseItems ? (
            <>{children}</>
          ) : (
            <div className={styles.emptyBlock}>
              <span>No available BOM items.</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
