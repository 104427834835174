import React, { useMemo } from 'react';
import styles from './ListItemBase.module.scss';

import { ListItemBase } from './ListItemBase';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export function CollapsibleListItem({ icon, label, paths, onClick, open, children }) {
  const location = useLocation();
  const currentPath = `/${location.pathname.split('/')[1]}`;

  const isActive = useMemo(() => {
    return paths.some((path) => path === currentPath);
  }, [currentPath, paths]);

  return (
    <>
      <ListItemBase icon={icon} label={label} isActive={isActive} onClick={onClick} collapseItem>
        <IconButton className={styles.item__controls_btn} onClick={onClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemBase>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}
