import {
  SET_ASSETS_COUNTING,
  SET_CURRENT_ASSET,
  SET_ASSETS_FILTER_CRITERIA,
  SET_ASSETS_TABLE_FIELDS,
  SET_ASSET_INSPECTION_STATUSES,
  SET_ASSET_LOCATION_HISTORY_DATA,
  SET_INSPECTION_HISTORY_DATA,
  SET_VERIFICATION_HISTORY_DATA,
  SET_ASSETS_FILTER,
  SET_ASSET_CREATION_FORM_DATA,
  CLEAR_ASSETS_STATE,
  SET_ASSET_OPENED_TICKETS,
  SET_ASSET_TABLE_CUSTOM_COLUMNS,
  SET_ASSETS_PREFILTER,
  SET_ASSET_TABLES_SELECTED_TAB,
  SET_ASSET_LOCATION_HISTORY_FILTER,
  SET_ASSET_INSPECTION_HISTORY_FILTER,
  SET_ASSET_VERIFICATION_HISTORY_FILTER,
  SET_ASSET_OPENED_TICKETS_FILTER,
  SET_ASSET_CLOSED_TICKETS_FILTER,
  SET_ASSET_CLOSED_TICKETS,
  CLEAR_FULL_ASSETS_STATE,
  CHECK_ASSET_TABLE_CUSTOM_COLUMNS
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from '../helpers/AppHelpers';

const pagination = { page: 1, totalPages: 1, limit: 10 };

const getItems = (state, payload) => {
  const { data, isAutoload } = payload;
  return isAutoload ? { ...data, items: state.items.concat(data.items) } : data;
};

const initialState = {
  counting: {},
  currentAsset: {},
  assetsFilterCriteria: {},
  assetsTableFields: [],
  assetIspectionStatuses: [],
  assetsFilter: {},
  creationForm: {},
  customColumns: [],
  selectedTab: 'locationHistory',
  // Asset History
  locationHistoryData: {},
  locationHistoryFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  inspectionHistoryData: {},
  inspectionHistoryFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  verificationHistoryData: {},
  verificationHistoryFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  // Asset Tickets
  openedTicketsList: {},
  openedTicketsFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  closedTicketsList: {},
  closedTicketsFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false }
};

const assetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ASSETS_COUNTING:
      return {
        ...state,
        counting: payload
      };
    case SET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: payload
      };
    case SET_ASSETS_FILTER_CRITERIA:
      return {
        ...state,
        assetsFilterCriteria: payload
      };
    case SET_ASSETS_TABLE_FIELDS:
      return {
        ...state,
        assetsTableFields: payload
      };
    case SET_ASSET_OPENED_TICKETS:
      return {
        ...state,
        openedTicketsList: getItems(state.openedTicketsList, payload)
      };
    case SET_ASSET_CLOSED_TICKETS:
      return {
        ...state,
        closedTicketsList: getItems(state.closedTicketsList, payload)
      };
    case SET_ASSET_INSPECTION_STATUSES:
      return {
        ...state,
        assetIspectionStatuses: payload
      };

    case SET_ASSET_LOCATION_HISTORY_DATA:
      return {
        ...state,
        locationHistoryData: getItems(state.locationHistoryData, payload)
      };
    case SET_INSPECTION_HISTORY_DATA:
      return {
        ...state,
        inspectionHistoryData: getItems(state.inspectionHistoryData, payload)
      };
    case SET_VERIFICATION_HISTORY_DATA:
      return {
        ...state,
        verificationHistoryData: getItems(state.verificationHistoryData, payload)
      };
    case SET_ASSETS_FILTER:
      return {
        ...state,
        assetsFilter: payload
      };
    case SET_ASSETS_PREFILTER:
      return {
        ...state,
        assetsFilter: {
          ...state.assetsFilter,
          filters: { ...state.assetsFilter.filters, ...payload }
        }
      };
    case SET_ASSET_CREATION_FORM_DATA:
      return {
        ...state,
        creationForm: payload
      };
    case SET_ASSET_TABLE_CUSTOM_COLUMNS:
      return { ...state, customColumns: payload };
    case CHECK_ASSET_TABLE_CUSTOM_COLUMNS: {
      return {
        ...state,
        customColumns: state.customColumns.filter(({ id }) => !payload.includes(id))
      };
    }
    case SET_ASSET_TABLES_SELECTED_TAB:
      return { ...state, selectedTab: payload };
    case SET_ASSET_LOCATION_HISTORY_FILTER:
      return { ...state, locationHistoryFilter: payload };
    case SET_ASSET_INSPECTION_HISTORY_FILTER:
      return { ...state, inspectionHistoryFilter: payload };
    case SET_ASSET_VERIFICATION_HISTORY_FILTER:
      return { ...state, verificationHistoryFilter: payload };
    case SET_ASSET_OPENED_TICKETS_FILTER:
      return { ...state, openedTicketsFilter: payload };
    case SET_ASSET_CLOSED_TICKETS_FILTER:
      return { ...state, closedTicketsFilter: payload };
    case CLEAR_ASSETS_STATE:
      return {
        ...initialState,
        assetsFilter: state.assetsFilter,
        customColumns: state.customColumns,
        selectedTab: state.selectedTab,
        locationHistoryFilter: getClearedFilterByUnmount(state.locationHistoryFilter),
        inspectionHistoryFilter: getClearedFilterByUnmount(state.inspectionHistoryFilter),
        verificationHistoryFilter: getClearedFilterByUnmount(state.verificationHistoryFilter),
        openedTicketsFilter: getClearedFilterByUnmount(state.openedTicketsFilter),
        closedTicketsFilter: getClearedFilterByUnmount(state.closedTicketsFilter)
      };
    case CLEAR_FULL_ASSETS_STATE:
      return {
        ...initialState,
        assetsFilter: state.assetsFilter,
        customColumns: state.customColumns
      };
    default:
      return state;
  }
};

export default assetReducer;
