const SCHEDULE_BAR_KEYS = { master: 'Master', breakdown: 'Breakdown' };
export const SCHEDULE_TOGGLE_BUTTONS = [SCHEDULE_BAR_KEYS.master, SCHEDULE_BAR_KEYS.breakdown];

export const defaultFilterState = {
  projectIds: [],
  locationIds: [],
  teamMemberStatusIds: [],
  teamMemberCapacityIds: [],
  startDate: null,
  endDate: null,
  teamMemberLicenseIds: [],
  teamMemberSkillsetIds: [],
  employmentTypeIds: [],
  teamMemberIds: [],
  teamMemberDepartmentIds: [],
  clearanceTypeIds: [],
  driveStatusIds: [],
  doNotDrive: false,
  doDrive: false,
  columnLimit: 30,
  columnPage: 1
};

export const getCollectedState = (isMaster, res, tableState) => {
  return isMaster
    ? {
        ...res,
        header: tableState.header.concat(res.header),
        teamMembers: tableState.teamMembers.map(
          ({ isContractSCA, isExpired, employmentByDays, teamMember }) => {
            return {
              isContractSCA,
              isExpired,
              employmentByDays: employmentByDays.concat(
                res.teamMembers.find((el) => el.teamMember.id === teamMember.id).employmentByDays
              ),
              teamMember: { ...teamMember }
            };
          }
        )
      }
    : {
        ...res,
        header: tableState.header.concat(res.header),
        projects: tableState.projects.map(({ capacities, project, teamMembers, total }) => {
          return {
            project: { ...project },
            total: total.concat(res.projects.find((el) => el.project.id === project.id).total),
            teamMembers: teamMembers.map(
              ({ isContractSCA, isExpired, color, employmentByDays, teamMember }) => {
                return {
                  isContractSCA,
                  isExpired,
                  color,
                  employmentByDays: employmentByDays.concat(
                    res.projects
                      .find((el) => el.project.id === project.id)
                      .teamMembers.find((el) => el.teamMember.id === teamMember.id).employmentByDays
                  ),
                  teamMember: { ...teamMember }
                };
              }
            ),
            capacities: capacities.map(({ capacityByDate, capacity }) => {
              return {
                capacityByDate: capacityByDate.concat(
                  res.projects
                    .find((el) => el.project.id === project.id)
                    .capacities.find((el) => el.capacity.id === capacity.id).capacityByDate
                ),
                capacity: { ...capacity }
              };
            })
          };
        })
      };
};

export const getEmptyFilters = (filter) => {
  const { filters } = filter;
  return (
    !filters.projectIds.length &&
    !filters.locationIds.length &&
    !filters.teamMemberStatusIds.length &&
    !filters.teamMemberCapacityIds.length &&
    filters.startDate === null &&
    filters.endDate === null &&
    !filters.teamMemberLicenseIds.length &&
    !filters.teamMemberSkillsetIds.length &&
    !filters.employmentTypeIds.length &&
    !filters.teamMemberIds.length &&
    !filters.teamMemberDepartmentIds.length &&
    !filters.clearanceTypeIds.length &&
    !filters.doNotDrive &&
    !filters.doDrive
  );
};

export const BREAKDOWN_ROWS_COUNT = 1;
