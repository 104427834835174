import React from 'react';
import styles from '../Form.module.scss';

import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

import SectionTitle from '../SectionTitle';

export function Notes() {
  return (
    <div className={styles.general}>
      <SectionTitle>Notes</SectionTitle>

      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_column}>
            <FormTextArea name="notes" options={{ max: 15000 }} />
          </div>
        </div>
      </div>
    </div>
  );
}
