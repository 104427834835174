import { enrichNameToId, formatDate, getUniqueID } from 'helpers/AppHelpers';

const createChip = (key, label) => ({ chipId: getUniqueID(), key, label });
const getUsedItems = (ids, items) => items.filter(({ id }) => ids.includes(id));

const handleIdsFilter = (ids, options, getLabel, key) => {
  return getUsedItems(ids, options).map((el) => ({
    ...createChip(key, getLabel(el)),
    itemId: el.id
  }));
};

const handleNamesFilter = (names, options, key) => {
  return getUsedItems(names, enrichNameToId(options)).map((el) => ({
    ...createChip(key, el.displayName),
    itemName: el.name
  }));
};

const handleBooleanFilter = (key, label) => [createChip(key, label)];

const handleDateFilter = (dateFrom, dateTo, prefix) => {
  if (!dateFrom && !dateTo) return [];

  const isTwoDatesSelected = dateFrom && dateTo;

  const key = isTwoDatesSelected ? 'duration' : dateFrom ? 'dateFrom' : 'dateTo';
  const chipLabel = isTwoDatesSelected
    ? `${prefix} ${formatDate(dateFrom)} - ${formatDate(dateTo)}`
    : dateFrom
    ? `${prefix} from ${formatDate(dateFrom)}`
    : `${prefix} to ${formatDate(dateTo)}`;

  return [createChip(`${key}_${prefix}`, chipLabel)];
};

export { createChip, handleIdsFilter, handleNamesFilter, handleBooleanFilter, handleDateFilter };
