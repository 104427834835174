import React from 'react';
import styles from '../AssembleCreate.module.scss';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { reformatNumberValue } from 'helpers/AppHelpers';
import clsx from 'clsx';

function LooseItemsTable({
  tableState,
  configOptions,
  setTableState,
  tableValidationErrors,
  setTableValidationErrors,
  setValidationQuantityErrors,
  validationQuantityErrors,
  key
}) {
  const validateTable = (index, param) => {
    const looseItemId = tableState[index].id;
    const requiredQuantity = tableState[index].requiredQuantity || 0;
    let allQuantitySum = tableState
      .filter(({ id }) => id === looseItemId)
      .map((el) => el.selectedQuantity)
      .reduce((sum, cur) => sum + (cur ? cur : 0), 0);
    if (param === 'remove') allQuantitySum = allQuantitySum - tableState[index].selectedQuantity;
    const newArray = tableValidationErrors;
    newArray[looseItemId] =
      allQuantitySum > requiredQuantity
        ? { name: "Sum of selected quantity doesn't match the required." }
        : null;
    setTableValidationErrors(newArray);
  };

  const validateLocationQuantity = (index, param) => {
    const newArray = [];
    tableState.forEach((el, i) => {
      newArray[i] = newArray[i] = {
        quantity: (el?.selectedQuantity || 0) > (el?.quantity || 0) ? 'Exceeds maximum value.' : '',
        byAheadAccount:
          (el?.selectedQuantity || 0) < (el?.selectedByAheadAccount || 0)
            ? '“From BAA*” value must not exceed “Selected quantity”'
            : (el?.selectedByAheadAccount || 0) > (el?.byAheadAccount || 0)
            ? 'The “From BAA” value must not exceed “Available BAA QTY”.'
            : ''
      };
    });
    if (param === 'remove') newArray.splice(index, 1);
    setValidationQuantityErrors(newArray);
  };

  const onTableLocationSelect = (name, value, index) => {
    const newArray = [...tableState];
    newArray[index].fakeLocation = value;
    newArray[index].location = value.location;
    newArray[index].selectedQuantity = 0;
    newArray[index].quantity = value.quantity;
    if (value?.sublocation?.id) {
      newArray[index].sublocation = value.sublocation;
    } else {
      newArray[index].sublocation = null;
    }
    setTableState(newArray);
    validateTable(index, 'locationSelect');
    validateLocationQuantity(index);
  };

  const handleFocus = (event) => event.target.select();

  const onCellInput = (event, index) => {
    const key = event.target.name;

    const newArray = [...tableState];
    newArray[index][key] = +reformatNumberValue(event.target.value);

    setTableState(newArray);
    validateTable(index, 'onCellInput');
    validateLocationQuantity(index);
  };

  const onAddRowClick = (index) => {
    const newRow = { ...tableState[index], selectedQuantity: 0, selectedByAheadAccount: 0 };
    const rowId = tableState[index].id;
    const categoryLocations = tableState.filter(({ id }) => id === rowId);
    const initialCategoryLocations = configOptions.looseItems.find(
      (el) => el.id === rowId
    ).locations;
    const usedLocationsIds = categoryLocations.map(({ fakeLocation }) => fakeLocation.id);
    const uniqueLocations = initialCategoryLocations.filter(
      ({ id }) => !usedLocationsIds.includes(id)
    );
    const item = {
      ...configOptions.looseItems
        .find((el) => el.id === rowId)
        .locations.find(({ id }) => id === uniqueLocations[0].id)
    };

    newRow.location = { ...item.location };
    newRow.sublocation = item?.sublocation?.id ? { ...item.sublocation } : null;
    newRow.fakeLocation = { ...item };
    newRow.quantity = item.quantity;

    const newArray = [...tableState];
    newArray.splice(index + 1, 0, newRow);
    setTableState(newArray);
    validateTable(index, 'add');
    validateLocationQuantity(index, 'add');
  };

  const onRemoveRowClick = (index) => {
    const newArray = tableState.filter((el, i) => index !== i);
    setTableState(newArray);
    validateTable(index, 'remove');
    validateLocationQuantity(index, 'remove');
  };

  const getLocations = (rowId, selectedId) => {
    const filterByRowId = ({ id }) => id === rowId;

    const categoryLocations = tableState.filter(filterByRowId);
    const initialCategoryLocations =
      configOptions?.looseItems?.find((el) => el.id === rowId)?.locations || [];

    if (initialCategoryLocations?.length === 0) return [];
    if (categoryLocations?.length === initialCategoryLocations?.length) {
      return categoryLocations
        .filter(({ fakeLocation }) => fakeLocation.id === selectedId)
        .map(({ fakeLocation }) => fakeLocation);
    }

    const usedLocationsIds = categoryLocations.map(({ fakeLocation }) => fakeLocation.id);
    const uniqueLocations = initialCategoryLocations.filter(
      ({ id }) => !usedLocationsIds.includes(id)
    );
    return uniqueLocations.concat(initialCategoryLocations.find(({ id }) => id === selectedId));
  };

  const isFirstNameCell = (index) => {
    return !(index !== 0 && tableState[index].id === tableState[index - 1].id);
  };

  const getCellNumber = (rowId) => {
    return tableState.filter(({ id }) => id === rowId).length;
  };

  const isLastRow = (index) => {
    return tableState[index].id !== tableState[index + 1]?.id;
  };

  const isLastLocationInPart = (rowId) => {
    if (rowId && configOptions?.looseItems?.find((el) => el.id === rowId)) {
      const lengthLocations = configOptions.looseItems.find((el) => el.id === rowId).locations
        .length;
      const lengthTableState = tableState.filter(({ id }) => id === rowId).length;
      return lengthLocations !== lengthTableState;
    }
    return true;
  };

  const isSimpleRow = (rowId) => {
    return tableState.filter(({ id }) => id === rowId).length === 1;
  };

  return tableState.length ? (
    <TableContainer className={styles.tableContainer} component={Paper} key={key}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.firstHeadCell}>Loose item</StyledTableCell>
            <StyledTableCell className={styles.secondHeadCell}>Required quantity</StyledTableCell>
            <StyledTableCell className={styles.thirdHeadCell}>
              Point of origin(Sublocation)
            </StyledTableCell>
            <StyledTableCell className={styles.fourthHeadCell}>Available quantity</StyledTableCell>
            <StyledTableCell className={styles.fifthHeadCell}>Selected quantity</StyledTableCell>
            <StyledTableCell className={styles.moveBaaQtyHead}>
              From BAA<span className={styles.footnote}>[1]</span>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableState.map((row, index) => (
            <>
              <StyledTableRow key={row.id}>
                {isFirstNameCell(index) && (
                  <StyledTableCell
                    rowSpan={getCellNumber(row.id)}
                    className={clsx(
                      styles.firstRow,
                      tableValidationErrors[row.id]?.name && styles.redBackground
                    )}>
                    {row.name}
                    <>
                      {!!tableValidationErrors[row.id]?.name && (
                        <p className={styles.errorMessage}>{tableValidationErrors[row.id].name}</p>
                      )}
                    </>
                  </StyledTableCell>
                )}
                {isFirstNameCell(index) && (
                  <StyledTableCell
                    rowSpan={getCellNumber(row.id)}
                    className={clsx(
                      styles.secondRow,
                      tableValidationErrors[row.id]?.name && styles.redBackground
                    )}>
                    {row.requiredQuantity}
                  </StyledTableCell>
                )}
                <StyledTableCell
                  className={clsx(
                    styles.thirdRow,
                    (tableValidationErrors[row.id]?.name ||
                      validationQuantityErrors[index]?.quantity ||
                      validationQuantityErrors[index]?.byAheadAccount) &&
                      styles.redBackground
                  )}>
                  <InputSearchWithMultiselect
                    name="location"
                    defaultValue={row?.fakeLocation?.id || ''}
                    options={getLocations(row?.id, row?.fakeLocation?.id) || []}
                    onFilterSelect={onTableLocationSelect}
                    index={index}
                    multiselect={false}
                    tableView={true}
                    optionType="locationWithSublocation"
                  />
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(
                    styles.fourthRow,
                    (tableValidationErrors[row.id]?.name ||
                      validationQuantityErrors[index]?.quantity ||
                      validationQuantityErrors[index]?.byAheadAccount) &&
                      styles.redBackground
                  )}>
                  {row.quantity}
                  {!!row?.byAheadAccount && `${' (' + row.byAheadAccount + ')'}`}
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(
                    styles.fifthRow,
                    (tableValidationErrors[row.id]?.name ||
                      validationQuantityErrors[index]?.quantity ||
                      validationQuantityErrors[index]?.byAheadAccount) &&
                      styles.redBackground
                  )}>
                  <div className={styles.block}>
                    <div className={styles.fieldBlock}>
                      <TextField
                        name="selectedQuantity"
                        className={clsx(
                          styles.textInput,
                          styles.tableStyle,
                          !row?.location?.id && styles.noEditCell
                        )}
                        variant="outlined"
                        value={row?.selectedQuantity || 0}
                        onChange={(event) => onCellInput(event, index)}
                        onFocus={handleFocus}
                      />
                      {!!validationQuantityErrors[index]?.quantity && (
                        <span className={styles.errorMessage}>
                          {validationQuantityErrors[index].quantity}
                        </span>
                      )}
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(
                    styles.byAheadAccountRow,
                    (tableValidationErrors[row.id]?.name ||
                      validationQuantityErrors[index]?.quantity ||
                      validationQuantityErrors[index]?.byAheadAccount) &&
                      styles.redBackground
                  )}>
                  <div className={styles.block}>
                    <div className={styles.fieldBlock}>
                      <TextField
                        name="selectedByAheadAccount"
                        className={clsx(
                          styles.textInput,
                          styles.tableStyle,
                          !row?.location?.id && styles.noEditCell
                        )}
                        variant="outlined"
                        value={row?.selectedByAheadAccount || 0}
                        onChange={(event) => onCellInput(event, index)}
                        onFocus={handleFocus}
                      />
                      {!!validationQuantityErrors[index]?.byAheadAccount && (
                        <span className={styles.errorMessage}>
                          {validationQuantityErrors[index].byAheadAccount}
                        </span>
                      )}
                    </div>
                    <div className={styles.rowHelpers}>
                      {isLastRow(index) && isLastLocationInPart(row.id) && (
                        <AddCircleOutlineOutlinedIcon
                          fontSize="small"
                          className={styles.addIcon}
                          onClick={() => onAddRowClick(index)}
                        />
                      )}
                      {!isSimpleRow(row.id) && (
                        <CancelRoundedIcon
                          fontSize="small"
                          className={styles.clearIcon}
                          onClick={() => onRemoveRowClick(index)}
                        />
                      )}
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <> </>
  );
}

export default LooseItemsTable;
