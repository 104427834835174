import React, { useEffect, useState } from 'react';
import styles from './MoveLooseItems.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import { FormLabel, FormSearchInput } from 'components/FormComponents';
import { MainButton } from 'components/StyledComponents';

import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getLocations, getLooseItems, moveItems } from 'actions/moveLooseItemsActions';

import { getErrorsProperties } from 'helpers/ErrorValidator';

import { SCREENS } from './helpers';

/**
 * @typedef {Object} MoveLooseItemsPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {'Dashboard' | 'Summary' | 'Move Loose Items'} screen
 * @property {[]} movedItems
 * @property {() => void} onMove
 */

/**
 * @param {MoveLooseItemsPopupProps} props
 */

function MoveLooseItemsPopup({ open, setOpen, screen, movedItems, onMove }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState({});

  const methods = useForm({
    defaultValues: { looseItemsToAdd: [], locationToAdd: {}, moveLooseItems: movedItems },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, setError } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { locations, looseItems } = options;

  useEffect(() => {
    dispatch(getLocations({ filters: { active: true, includeUserCheck: true } })).then((res) =>
      setOptions((prev) => ({ ...prev, locations: res?.items || [] }))
    );
  }, []);

  useEffect(() => {
    dispatch(getLooseItems()).then((res) => setOptions((prev) => ({ ...prev, looseItems: res })));
  }, []);

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('looseItemsToAdd')?.length) {
      setError('looseItemsToAdd', getErrorsProperties('Loose Items field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const getPayload = () => {
    const data = getValues();
    const payload = {};

    payload.looseItemsToAdd = data.looseItemsToAdd;

    if (data?.locationToAdd?.id) {
      payload.locationToAdd = data.locationToAdd;
    }
    if (data?.moveLooseItems?.length) {
      payload.moveLooseItems = data.moveLooseItems;
    }

    return payload;
  };

  const closePopup = () => setOpen(false);
  const handleAddClick = () => {
    if (!validateForm()) return;

    dispatch(moveItems(getPayload())).then((res) => {
      if (!res?.length) return;

      onMove(res);
      closePopup();
    });
  };

  const selectOption = (name, value) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader
          title={`${screen === SCREENS.MOVE ? 'Add' : 'Move'} Loose Items`}
          onClose={closePopup}
        />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.form}>
              <div className={styles.form__row}>
                <FormLabel required>Loose Items</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemsToAdd"
                  menuItems={looseItems || []}
                  options={{
                    label: 'name',
                    selectAll: false,
                    disableByObjectTracker: false,
                    disableLabel: true
                  }}
                />
              </div>

              <div className={styles.form__row}>
                <FormLabel>Destination</FormLabel>
                <FormSearchInput
                  clearable
                  name="locationToAdd"
                  options={locations || []}
                  onSelect={selectOption}
                />
              </div>
            </div>
          </FormProvider>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton
            text={screen === SCREENS.MOVE ? 'Add' : 'Start moving'}
            action={handleAddClick}
            type="primary"
            isDisabled={isFormInvalid}
          />
        </div>
      </div>
    </DialogWrapper>
  );
}

MoveLooseItemsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
  movedItems: PropTypes.array,
  onMove: PropTypes.func.isRequired
};

MoveLooseItemsPopup.defaultProps = {
  open: false,
  movedItems: []
};

export { MoveLooseItemsPopup };
