import React, { useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Fade,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import styles from './FileActionsPopper.module.scss';
import PendingOutlined from 'assets/images/moreDots.svg';
import downloadIcon from 'assets/images/download.svg';
import deleteIcon from 'assets/images/delete.svg';

function FileActionsPopper({ asset, onDelete, onDownload, isUploadAvailable }) {
  const elRef = useRef();
  const [open, setOpen] = useState(false);

  const onShow = () => setOpen(true);

  const onHide = () => setOpen(false);

  const downloadFile = () => {
    onDownload(asset);
    onHide();
  };

  const deleteFile = () => {
    onDelete(asset);
    onHide();
  };

  return (
    <div className={styles.buttonWrapper}>
      <Button ref={elRef} onClick={(e) => onShow(e)} className={styles.dotsButton}>
        <img src={PendingOutlined} alt="pending" />
      </Button>

      <Popper
        open={open}
        anchorEl={() => elRef.current}
        transition
        placement={'bottom-end'}
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper classes={{ root: styles.mobileContainer }}>
              <ClickAwayListener onClickAway={onHide}>
                <MenuList autoFocusItem={open}>
                  <MenuItem onClick={downloadFile}>
                    <img src={downloadIcon} alt="download" />
                    Download
                  </MenuItem>
                  {isUploadAvailable() && (
                    <MenuItem onClick={deleteFile}>
                      <img src={deleteIcon} alt="delete" />
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default FileActionsPopper;
