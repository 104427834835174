import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getPrefixesApi = (query) =>
  api.get(`/AssetPrefixes?${queryToString(query)}`).then((res) => res.data);

export const getFieldsApi = (query) =>
  api.get(`/AssetFields?${queryToString(query)}`).then((res) => res.data);

export const getDashboardDataApi = (data) =>
  api.post('/AssetFieldDashboard', data).then((res) => res.data);

export const getUserAssetFieldDashboardConfigApi = () =>
  api.get('/User/AssetFieldDashboardsOptions').then((res) => res.data);

export const setPrefilterDataApi = (params) =>
  api.put('/User/AssetFieldDashboardsOptions', params).then((res) => res.data);
