import React, { useEffect } from 'react';

import { useBillOfLadingLogsActions, useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { BILL_OF_LADING_LOGS_PATH, ROOT_PATH } from 'constants/routeConstants';
import { FormProvider, useForm } from 'react-hook-form';

import Wrapper from './components/Wrapper';
import Form from './components/Form';

export function UploadLogsFromCSV() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const methods = useForm({
    defaultValues: {
      type: {},
      columns: [],
      resource: {}
    },
    mode: 'onChange'
  });
  const { setValue } = methods;

  const { configuration } = useBillOfLadingLogsSelector();
  const { clearUploadLogsStateAction, getCreationFormAction } = useBillOfLadingLogsActions();

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    if (configuration?.bolLogColumns?.length) {
      setValue('type', {}, { shouldDirty: true });
      setValue('columns', configuration?.bolLogColumns || [], { shouldDirty: true });
      setValue('resource', configuration?.resource || {}, { shouldDirty: true });
    }
  }, [configuration]);

  useEffect(() => {
    if (!configuration?.bolLogColumns?.length) {
      history.push(BILL_OF_LADING_LOGS_PATH);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      history.push(ROOT_PATH);
    }
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearUploadLogsStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
