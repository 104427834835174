import React, { useEffect, useState } from 'react';
import styles from './SearchField.module.scss';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { makeStyles } from '@material-ui/core/styles';

import { reformatInputValue } from 'helpers/AppHelpers';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid #999',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)'
      }
    }
  }
}));

export default function SearchField({ filterValue, inputPattern, onSearch, classes }) {
  const muiClasses = useStyles();

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const changeInput = (event) => {
    const value = reformatInputValue(event.target.value, 100, inputPattern);
    setValue(value);
    if (event.key === 'Enter' || (filterValue.length && !value)) {
      onSearch(value, 'input');
    }
  };

  const clearClick = () => {
    setValue('');
    onSearch('', 'clear');
  };

  return (
    <div className={clsx(classes && classes, styles.search)}>
      <TextField
        className={clsx(styles.search__input, muiClasses.searchBarStyle)}
        type="text"
        placeholder="Search"
        variant="outlined"
        onChange={changeInput}
        onKeyDown={changeInput}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value && (
            <IconButton onClick={clearClick} className={styles.clear}>
              <CancelRoundedIcon fontSize="small" />
            </IconButton>
          )
        }}
      />
    </div>
  );
}
