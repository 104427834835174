import React from 'react';
import styles from './Tabs.module.scss';

import {
  useTeamMemberDashboardActions,
  useTeamMemberDashboardSelector
} from 'hooks/TeamMemberDashboard';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import { TOGGLE_BUTTONS } from '../../helpers';

export default function DashboardTabs() {
  const { setTabAction } = useTeamMemberDashboardActions();
  const { tab } = useTeamMemberDashboardSelector();

  const clickTab = (tab) => setTabAction(tab);

  return (
    <ToggleBar
      buttons={TOGGLE_BUTTONS}
      onChange={clickTab}
      selectedTab={tab}
      classes={styles.bar}
    />
  );
}
