import React from 'react';
import styles from './AssetTablesToggle.module.scss';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useTicketActions } from 'hooks/Ticket';
import { useHistory } from 'react-router-dom';

import AssetLocationHistory from './Tables/History/AssetLocationHistory';
import AssetInspectionHistory from './Tables/History/AssetInspectionHistory';
import AssetVerificationHistory from './Tables/History/AssetVerificationHistory';

import AssetOpenedTickets from './Tables/Tickets/AssetOpenedTickets';
import AssetClosedTickets from './Tables/Tickets/AssetClosedTickets';

import ToggleBar from 'components/ToggleBars/ToggleBar';
import { MainButton } from 'components/StyledComponents';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import { TICKET_EDIT_PATH } from 'constants/routeConstants';

export default function AssetTablesToggle() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const {
    selectedTab,
    currentAsset,
    locationHistoryFilter,
    inspectionHistoryFilter,
    verificationHistoryFilter
  } = useAssetSelector();
  const { setTablesTabAction } = useAssetActions();
  const { setPrefilledAssetAction } = useTicketActions();

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'locationHistory', label: isMobile ? 'Location' : 'Location History' },
    {
      id: 2,
      value: 'verificationHistory',
      label: isMobile ? 'Verification' : 'Verification History'
    },
    { id: 3, value: 'inspectionHistory', label: isMobile ? 'Inspection' : 'Inspection History' },
    { id: 4, value: 'openedTickets', label: 'Opened Ticket' },
    { id: 5, value: 'closedTickets', label: 'Closed Ticket' }
  ];

  const changeTab = (tab) => setTablesTabAction(tab);

  const handleCreateTicketClick = () => {
    setPrefilledAssetAction(currentAsset);
    history.push(TICKET_EDIT_PATH);
  };

  const getCsvEndpoint = () => {
    const endpoints = {
      locationHistory: 'AssetLocationHistory/Serialized',
      inspectionHistory: 'AssetInspectionHistory/Serialized',
      verificationHistory: 'AssetInspectionHistory/Serialized'
    };
    return endpoints[selectedTab];
  };

  const getCsvFilter = () => {
    if (selectedTab === 'locationHistory') {
      return { ...locationHistoryFilter, pagination: null };
    }
    if (selectedTab === 'inspectionHistory') {
      return {
        filters: { ...inspectionHistoryFilter.filters, isVerification: false },
        pagination: null
      };
    }
    return {
      filters: { ...verificationHistoryFilter.filters, isVerification: true },
      pagination: null
    };
  };

  const areTicketsSelected = ['openedTickets', 'closedTickets'].includes(selectedTab);

  return (
    <div className={styles.wrapper}>
      {!isMobile && (
        <div className={styles.header}>
          {!areTicketsSelected && (
            <DownloadCSVButton endpoint={getCsvEndpoint()} filter={getCsvFilter()} />
          )}
          {!currentAsset?.deleted && areTicketsSelected && (
            <MainButton text="Create ticket" action={handleCreateTicketClick} type="primary" />
          )}
        </div>
      )}
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        selectedTab={selectedTab}
        onChange={changeTab}
        classes={styles.toggle}
      />
      {isMobile && !currentAsset?.deleted && areTicketsSelected && (
        <div className={styles.createTicketButton}>
          <MainButton text="Create ticket" action={handleCreateTicketClick} type="primary" />
        </div>
      )}

      {selectedTab === 'locationHistory' && <AssetLocationHistory />}

      {selectedTab === 'verificationHistory' && <AssetVerificationHistory />}

      {selectedTab === 'inspectionHistory' && <AssetInspectionHistory />}

      {selectedTab === 'openedTickets' && <AssetOpenedTickets />}

      {selectedTab === 'closedTickets' && <AssetClosedTickets />}
    </div>
  );
}
