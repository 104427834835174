import React, { useEffect, useState } from 'react';
import styles from './LocationUpdate.module.scss';

import { LOCATION_UPDATE } from 'constants/dialogPopupsData';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';
import { useFormContext } from 'react-hook-form';

import MainButton from 'components/StyledComponents/MainButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import {
  FormLabel,
  FormMultipleAssetSelect,
  FormSearchInput,
  FormTextArea
} from 'components/FormComponents';

import clsx from 'clsx';

import { getOtherTabNames, getUpdatedTabValues, tabs } from '../helpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

export default function LocationUpdate() {
  const tabName = tabs.locationUpdate;

  const { setError, clearErrors, setValue, getValues, formState, watch } = useFormContext();

  const getFieldName = (name) => `${tabName}.${name}`;

  const { errors } = formState;
  const isFormInvalid = () => !!errors?.[tabName]?.['assets'] || !!errors?.[tabName]?.['location'];

  const { creationForm } = useBatchAssetUpdatesSelector();
  const { getSublocationsAction, assignAssetsToLocationAction } = useBatchAssetUpdatesActions();

  const { locations } = creationForm;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    return () => {
      clearErrors(tabName);
    };
  }, []);

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: getFieldName('assets'),
        errorMessage: 'Assets field is required',
        isInvalid: !getValues(getFieldName('assets'))?.length
      },
      {
        name: getFieldName('location'),
        errorMessage: 'Location field is required',
        isInvalid: !getValues(getFieldName('location'))?.id
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;
    setModalData({ ...LOCATION_UPDATE, isOpened: true });
  };

  const clearForm = () => {
    setValue(getFieldName('assets'), []);
    setValue(getFieldName('location'), null);
    setValue(getFieldName('sublocation'), null);
    setValue(getFieldName('notes'), '');
    setValue(getFieldName('sublocations'), []);
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, [], getValues()))
    );
  };

  const closeModal = () => setModalData({});

  const agreeModal = () => {
    const values = getValues(tabName);

    assignAssetsToLocationAction(values).then((res) => {
      if (!isSuccessfulStatus(res.status)) return;

      clearForm();
      closeModal();
    });
  };

  const handleAssetSelect = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
    errors?.[tabName]?.['assets']?.message && clearErrors(getFieldName('assets'));
  };

  const selectSublocation = (name, value) => setValue(name, value);
  const selectLocation = (name, value) => {
    errors?.[tabName]?.['location']?.message && clearErrors(getFieldName('location'));
    setValue(name, value);
    getSublocationsAction(value.id).then((res) =>
      setValue(getFieldName('sublocations'), res || [])
    );
  };

  const sublocationsWatcher = watch(getFieldName('sublocations'));

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        <div className={clsx(styles.form__column, styles.assets)}>
          <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes*</FormLabel>
          <FormMultipleAssetSelect
            withQr
            name={getFieldName('assets')}
            onSelectTriggered={handleAssetSelect}
          />
        </div>
        <div className={styles.form__row}>
          <FormLabel>Assign these assets to this location*</FormLabel>
          <FormSearchInput
            name={getFieldName('location')}
            options={locations || []}
            onSelect={selectLocation}
          />
        </div>
        <div className={styles.form__row}>
          <FormLabel>Assign these assets to this sublocation</FormLabel>
          <FormSearchInput
            clearable
            name={getFieldName('sublocation')}
            options={sublocationsWatcher || []}
            onSelect={selectSublocation}
          />
        </div>
        <div className={clsx(styles.form__column, styles.notes)}>
          <FormLabel>Enter any applicable notes for this batch of assets</FormLabel>
          <FormTextArea name={getFieldName('notes')} options={{ max: 15000 }} />
        </div>
      </div>
      <div className={styles.footer}>
        <MainButton
          text="update"
          type="primary"
          isDisabled={!!isFormInvalid()}
          action={handleUpdateClick}
        />
      </div>
    </section>
  );
}
