import { getUniqueID } from 'helpers/AppHelpers';

export const generateChips = (options, filters) => {
  const filterHandlers = {
    categoryIds: handleFilterChips('looseItemCategories', 'name', options),
    looseItemIds: handleFilterChips('looseItems', 'name', options),
    locationIds: handleFilterChips('locations', 'siteCode', options),
    locationJobNumberIds: handleFilterChips('locations', 'locationJobNumber', options),
    sublocationIds: handleFilterChips('sublocations', 'name', options),
    statusNames: handleFilterNames('statuses', 'displayName', options),
    showWithBaa: handleBooleanFilter('Show with BAA qty'),
    showWithReorderTrigger: handleBooleanFilter('Show with reorder trigger')
  };

  let newChips = [];

  Object.entries(filterHandlers).forEach(([key, handler]) => {
    const chips = handler(filters[key], key);
    newChips = newChips.concat(chips);
  });

  return newChips.filter(Boolean);
};

const handleFilterChips = (dataKey, labelKey, filterCriteriaData) => (filterIds, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterIds?.length) return [];

  return filterIds.flatMap((id) => {
    const item = data.find((option) => option.id === id);
    if (!item?.id && item?.id !== 0) return [];
    return {
      itemId: item.id,
      label: item[labelKey],
      chipId: getUniqueID(),
      key
    };
  });
};

const handleFilterNames = (dataKey, labelKey, filterCriteriaData) => (filterNames, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterNames?.length) return [];

  return filterNames.flatMap((name) => {
    const item = data.find((option) => option.name === name);
    if (!item?.name) return [];
    return { itemName: item.name, label: item[labelKey], chipId: getUniqueID(), key };
  });
};

const handleBooleanFilter = (label) => (isActive, key) => {
  if (isActive) return [{ chipId: getUniqueID(), key, label }];
  return [];
};

export const parseParams = (params) => {
  const hasParams = Object.values(params).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== undefined && value !== null && value !== false;
  });

  if (!hasParams) return {};

  const parsedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (key.endsWith('Ids')) {
      if (Array.isArray(value)) {
        acc[key] = value.map(Number);
      } else if (value !== undefined) {
        acc[key] = [Number(value)];
      }
    } else if (key.startsWith('show')) {
      if (value === 'true') {
        acc[key] = true;
      }
    } else if (key === 'statusNames') {
      if (Array.isArray(value)) {
        acc[key] = value;
      } else if (value !== undefined) {
        acc[key] = [value];
      }
    } else if (key === 'searchQuery') {
      if (value !== undefined && value !== '') {
        acc[key] = value;
      }
    }

    return acc;
  }, {});
  return parsedParams;
};
