import { TEAM_MEMBERS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, doDriveId, doNotDriveId, getTeamMemberFullName } from 'helpers/AppHelpers';
import { createChip, handleBooleanFilter, handleIdsFilter } from 'helpers/ChipGenerator';
import { useSearchParams } from 'hooks/useSearchParams';

const defaultFilter = {
  filters: {
    searchQuery: '',
    teamMemberDepartmentIds: [],
    teamMemberIds: [],
    clearanceTypeIds: [],
    teamMemberLicenseIds: [],
    teamMemberCapacityIds: [],
    teamMemberStatusIds: [],
    teamMemberSkillsetIds: [],
    readOnlyTeamMemberDepartmentIds: [],
    showDeleted: false,
    driveStatusIds: [],
    doNotDrive: false,
    doDrive: false,
    dashboardId: '',
    dashboardField: '',
    dashboardType: '',
    dashboardDuration: null,
    dashboardProjectId: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useTeamMembersParams = () => {
  return useSearchParams([
    'capacityId',
    'dashboardId',
    'dashboardField',
    'dashboardType',
    'dashboardDuration',
    'dashboardProjectId'
  ]);
};

const generateInitialQuery = ({
  getTableLimit,
  isAllLimitEnabled,
  filter,
  dashboardId,
  dashboardField,
  dashboardType,
  dashboardDuration,
  dashboardProjectId,
  capacityId
}) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(TEAM_MEMBERS_TABLE_PARAMETER);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  // R&R Dashboard
  if (dashboardId && dashboardType && dashboardField && capacityId) {
    return setFilters({
      dashboardId,
      dashboardType,
      dashboardField,
      teamMemberCapacityIds: [+capacityId]
    });
  }

  // Staffing Levels Dashboard
  if (dashboardId && dashboardType && dashboardDuration && dashboardProjectId) {
    return setFilters({ dashboardId, dashboardType, dashboardDuration, dashboardProjectId });
  }

  // Staffing Availability Dashboard
  if (dashboardId && dashboardType && dashboardDuration && dashboardField) {
    return setFilters({ dashboardId, dashboardType, dashboardDuration, dashboardField });
  }

  const isAll = isAllLimitEnabled(TEAM_MEMBERS_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page },
    sortRules: filter?.sortRules || initialQuery.sortRules
  };
};

const generateChips = (criteria, filters = {}) => {
  const {
    clearanceTypes,
    readOnlyTeamMemberDepartments,
    teamMemberCapacities,
    teamMemberDepartments,
    teamMemberLicenses,
    teamMemberSkillsets,
    teamMemberStatuses,
    teamMembers
  } = criteria;

  const {
    teamMemberDepartmentIds,
    teamMemberIds,
    clearanceTypeIds,
    teamMemberLicenseIds,
    teamMemberCapacityIds,
    teamMemberStatusIds,
    teamMemberSkillsetIds,
    readOnlyTeamMemberDepartmentIds,
    showDeleted,
    doNotDrive,
    doDrive,
    dashboardId,
    dashboardField,
    dashboardType,
    dashboardDuration,
    dashboardProjectId
  } = filters;

  let newChips = [];

  const filterConfigs = [
    {
      ids: teamMemberDepartmentIds,
      options: teamMemberDepartments,
      getLabel: (el) => el.name,
      key: 'teamMemberDepartmentIds'
    },
    {
      ids: teamMemberIds,
      options: teamMembers,
      getLabel: (el) => getTeamMemberFullName(el),
      key: 'teamMemberIds'
    },
    {
      ids: clearanceTypeIds,
      options: clearanceTypes,
      getLabel: (el) => el.name,
      key: 'clearanceTypeIds'
    },
    {
      ids: teamMemberLicenseIds,
      options: teamMemberLicenses,
      getLabel: (el) => el.name,
      key: 'teamMemberLicenseIds'
    },
    {
      ids: teamMemberCapacityIds,
      options: teamMemberCapacities,
      getLabel: (el) => el.name,
      key: 'teamMemberCapacityIds'
    },
    {
      ids: teamMemberStatusIds,
      options: teamMemberStatuses,
      getLabel: (el) => el.name,
      key: 'teamMemberStatusIds'
    },
    {
      ids: teamMemberSkillsetIds,
      options: teamMemberSkillsets,
      getLabel: (el) => el.name,
      key: 'teamMemberSkillsetIds'
    },
    {
      ids: readOnlyTeamMemberDepartmentIds,
      options: readOnlyTeamMemberDepartments,
      getLabel: (el) => el.name,
      key: 'readOnlyTeamMemberDepartmentIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (showDeleted) {
    newChips = newChips.concat(handleBooleanFilter('showDeleted', 'Show removed Team Members'));
  }

  if (doNotDrive) {
    newChips = newChips.concat(handleBooleanFilter('doNotDrive', 'Do Not Drive'));
  }

  if (doDrive) {
    newChips = newChips.concat(handleBooleanFilter('doDrive', 'Do Drive'));
  }

  if (
    dashboardId &&
    dashboardType &&
    (dashboardField ||
      (dashboardDuration && dashboardProjectId) ||
      (dashboardDuration && dashboardField))
  ) {
    newChips = newChips.concat([createChip('filteredByDashboard', 'Filtered by Dashboard')]);
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;

  switch (key) {
    case 'showDeleted':
      newFilters[key] = false;
      break;
    case 'doNotDrive':
      newFilters[key] = false;
      newFilters.driveStatusIds = newFilters.driveStatusIds.filter((id) => id !== doNotDriveId);
      break;
    case 'doDrive':
      newFilters[key] = false;
      newFilters.driveStatusIds = newFilters.driveStatusIds.filter((id) => id !== doDriveId);
      break;
    case 'filteredByDashboard':
      newFilters.dashboardId = '';
      newFilters.dashboardType = '';
      newFilters.dashboardField = '';
      newFilters.dashboardDuration = '';
      newFilters.dashboardProjectId = '';
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = [
    'searchQuery',
    'teamMemberDepartmentIds',
    'teamMemberIds',
    'clearanceTypeIds',
    'teamMemberLicenseIds',
    'teamMemberCapacityIds',
    'teamMemberStatusIds',
    'teamMemberSkillsetIds',
    'readOnlyTeamMemberDepartmentIds',
    'showDeleted',
    'doNotDrive',
    'doDrive'
  ];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules?.length) {
    payload.sortRules = query.sortRules;
  }

  const applyFilters = (filters) => {
    Object.keys(filters).forEach((key) => {
      setFilters(key, filters[key]);
    });
  };

  const { dashboardId, dashboardType, dashboardField, dashboardDuration, dashboardProjectId } =
    query.filters || {};

  if (dashboardId && dashboardType && dashboardField) {
    applyFilters({ dashboardId, dashboardType, dashboardField });
  }

  if (dashboardId && dashboardType && dashboardDuration && dashboardProjectId) {
    applyFilters({ dashboardId, dashboardType, dashboardDuration, dashboardProjectId });
  }

  if (dashboardId && dashboardType && dashboardDuration && dashboardField) {
    applyFilters({ dashboardId, dashboardType, dashboardDuration, dashboardField });
  }

  return payload;
};

const tableHeadConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'lastName' },
  { id: 2, label: 'ID Number', isSortable: true, parameter: 'idNumber' },
  { id: 3, label: 'Department', isSortable: true, parameter: 'departments' },
  { id: 4, label: 'Read-only Schedule', isSortable: false },
  { id: 5, label: 'Capacity', isSortable: true, parameter: 'capacity.name' },
  { id: 6, label: 'Email', isSortable: false, parameter: null }
];

const DEPARTMENT_TOOLTIP_TEXT =
  'By selecting Department, lists of Capacity, Team Member, Clearance, Trainings & Certifications, Skillset will be narrowed.';

export {
  defaultFilter,
  useTeamMembersParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  tableHeadConfig,
  DEPARTMENT_TOOLTIP_TEXT
};
