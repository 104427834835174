import React, { useEffect } from 'react';
import styles from './components.module.scss';

import { useUserActions, useUserSelector } from 'hooks/UserManagement';

import { useParams } from 'react-router-dom';

export default function UserDetailsWrapper({ children }) {
  const { id } = useParams();

  const { currentUser } = useUserSelector();
  const { getUserAction, clearStateAction } = useUserActions();

  useEffect(() => {
    if (id) {
      getUserAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return <section className={styles.wrapper}>{currentUser?.id === +id && <>{children}</>}</section>;
}
