import React from 'react';
import styles from './components.module.scss';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

export default function Chips({ name, items, options }) {
  const { control, getValues } = useFormContext();
  const { fields, remove, move } = useFieldArray({
    control,
    name
  });

  const { label, extraAction } = options;

  const handleChipDelete = (i) => {
    remove(i);
    extraAction && extraAction();
  };

  const getLabel = (idIndex) => {
    const needId = getValues(name)[idIndex];
    return items?.find(({ id }) => id === needId)?.[label] || '';
  };

  const handleDrag = ({ oldIndex, newIndex }) => move(oldIndex, newIndex);

  const SortableItem = sortableElement(({ value }) => (
    <Chip
      label={getLabel(value)}
      deleteIcon={<CloseIcon />}
      className={styles.chip}
      onDelete={() => handleChipDelete(value)}
    />
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <section className={styles.chips}>{children}</section>;
  });

  return (
    !!fields?.length && (
      <SortableContainer onSortEnd={handleDrag} axis="xy" distance={1}>
        {fields.map((item, index) => (
          <SortableItem key={item.id} index={index} value={index} />
        ))}
      </SortableContainer>
    )
  );
}
