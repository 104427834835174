import React, { useState } from 'react';
import styles from './AssetFiles.module.scss';

import { PhotoGallery } from 'components/PhotoGallery';
import Subtitle from 'components/DetailsComponents/Subtitle';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { useAssetSelector } from 'hooks/Asset';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { dateTimeFormat, emptyValue, fileDownload, formatDate } from 'helpers/AppHelpers';

import { DOWNLOAD_FILE } from 'constants/dialogPopupsData';
import { USER_LOGS_PATH } from 'constants/routeConstants';

import AssetActions from './Actions';

export default function AssetFiles() {
  const isMobile = useMobileViewport();

  const [modalData, setModalData] = useState({});

  const { isAdminUser } = useUserConfig();

  const { currentAsset } = useAssetSelector();

  const {
    id,
    resources,
    createdByUser,
    createdAtUtc,
    lastUpdatedByUser,
    lastModifiedAtUtc,
    deleted,
    lastModifiedMessage
  } = currentAsset;

  const getFiles = resources?.filter((resource) => resource['resourceType']?.id === 2) || [];

  const onFileDownload = (file) => setModalData({ ...DOWNLOAD_FILE, isOpened: true, file });

  const agreeModal = () => fileDownload(modalData.file).then(() => closeModal());
  const closeModal = () => setModalData({ isOpened: false });

  const generateUrl = () => {
    const params = {
      createdAtUtc: lastModifiedAtUtc,
      entityId: id,
      userIds: [lastUpdatedByUser.id]
    };
    const queryParams = new URLSearchParams(params);

    return `${USER_LOGS_PATH}?${queryParams.toString()}`;
  };

  return (
    <div className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />

      {!deleted && <AssetActions />}
      <PhotoGallery resources={resources} />
      <div className={styles.editors}>
        {createdByUser?.firstName && (
          <p className={styles.editors__info}>
            Created {createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : ''} by{' '}
            {createdByUser.firstName} {createdByUser.lastName}
          </p>
        )}
        {lastUpdatedByUser?.firstName && (
          <p className={styles.editors__info}>
            Last Modified{' '}
            {lastModifiedAtUtc ? formatDate(lastModifiedAtUtc, dateTimeFormat) : emptyValue} by{' '}
            {lastUpdatedByUser.firstName} {lastUpdatedByUser.lastName}
            {!!lastModifiedMessage && ':'}
            {!!lastModifiedMessage && (
              <>
                <br />
                {lastModifiedMessage}
              </>
            )}
          </p>
        )}
        {!!lastModifiedMessage && isAdminUser && !isMobile && (
          <div className={styles.editors__see_in_logs}>
            <LinkComponent path={generateUrl()} name="See in Users Logs" />
          </div>
        )}
      </div>
      <div className={styles.files}>
        <Subtitle>Files</Subtitle>
        <div className={styles.files__info}>
          {!!getFiles.length &&
            getFiles.map((file) => (
              <div
                className={styles.files__info_item}
                key={file.id}
                onClick={() => onFileDownload(file)}>
                {file.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
