import api from '../api';
import qs from 'qs';

export const getActiveLocationsListApi = (query) =>
  api
    .get(`/Locations/Map?${qs.stringify(query, { allowDots: true, indices: false })}`)
    .then((res) => res.data);

export const getNearestMapLocationsApi = (params) =>
  api.post(`/Locations/Map/Nearest`, params).then((res) => res.data);

export const getMapFilterCriteriaApi = () =>
  api.get(`/Locations/MapFilterCriteria`).then((res) => res.data);
