import React from 'react';

import PrefixDetailsWrapper from './components/PrefixDetailsWrapper';
import Header from './components/Header';
import GeneralDetails from './components/GeneralDetails';
import DescriptionDetails from './components/DescriptionDetails';
import FieldsDetails from './components/FieldsDetails';
import Footer from './components/Footer';

export default function PrefixDetails() {
  return (
    <PrefixDetailsWrapper>
      <Header />
      <GeneralDetails />
      <DescriptionDetails />
      <FieldsDetails />
      <Footer />
    </PrefixDetailsWrapper>
  );
}
