import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import {
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { selectConfigurationCreationFormData } from '../../selectors';

import { pluck, removeDuplicates } from 'helpers/AppHelpers';

import { Add, Cancel, Close } from '@material-ui/icons';

import styles from './LooseItemsTable.module.scss';
import ErrorBlock from 'components/ErrorBlock';

const LooseItemsTable = () => {
  const [categories, setCategories] = useState([]);
  const [render, setRender] = useState(0);

  const { looseItemCategories, looseItems } = useSelector(selectConfigurationCreationFormData());

  const { control, formState, clearErrors, setValue, getValues } = useFormContext();
  const { errors } = formState;
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'looseItems'
  });

  const getFilteredLooseItems = (index) => {
    if (!looseItems?.length) return [];

    const rowLooseItem = getValues('looseItems')[index];
    const rowItemId = rowLooseItem?.id;
    const excludedItemIds = removeDuplicates(pluck(getValues('looseItems'), 'id').filter(Boolean));
    const excludedItemsWithoutRowItemId = excludedItemIds.filter((id) => id !== rowItemId);

    if (!categories?.length) {
      return looseItems.filter(({ id }) => !excludedItemsWithoutRowItemId.includes(id));
    } else {
      const categoryIds = pluck(categories, 'id');

      const options = looseItems
        .filter(({ id }) => !excludedItemsWithoutRowItemId.includes(id))
        .filter(
          ({ looseItemCategories }) =>
            !!looseItemCategories.filter(({ id }) => categoryIds.includes(id)).length
        );

      if (options?.length && rowItemId && !options.map(({ id }) => id).includes(rowItemId)) {
        return options.concat(rowLooseItem).sort((a, b) => a.id > b.id);
      } else return options;
    }
  };

  const onRemoveRow = (index) => {
    clearErrors(`looseItems[${index}].quantity`);

    if (fields.length < 1) return;

    if (fields?.length === 1) {
      update(index, { quantity: 1 });
    } else {
      remove(index);
    }
  };

  const handleSelect = (name, value) => {
    !!errors?.['empty_tables']?.message && clearErrors('empty_tables');
    setValue(name, { quantity: getValues(name)['quantity'], ...value });
    setRender(render + 1);
  };

  const onAddRow = () => append({ quantity: 1 });

  const categorySelect = (_, value) => setCategories(value);
  const deleteCategory = (itemId) => setCategories(categories.filter(({ id }) => id !== itemId));

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.controls__field}>
          <label>Narrow by Category</label>
          <InputSearchWithMultiselect
            name="categories"
            defaultValue={categories?.map(({ id }) => id) || []}
            options={looseItemCategories || []}
            onFilterSelect={categorySelect}
            multiselect
            shouldHideTags
          />
        </div>
        {!!categories.length && (
          <div className={styles.controls__chips}>
            {categories.map((category) => (
              <Chip
                key={category.id}
                label={category?.name || ''}
                deleteIcon={<Close />}
                className={styles.controls__chips_item}
                onDelete={() => deleteCategory(category.id)}
              />
            ))}
          </div>
        )}
      </div>
      <TableContainer component={Paper} className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Loose Item</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!fields?.length &&
              fields.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <FormSearchInput
                      name={`looseItems[${index}]`}
                      options={getFilteredLooseItems(index)}
                      onSelect={handleSelect}
                      classes={clsx(styles.select_input_style)}
                      clearable
                      disableError
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      errors?.looseItems?.[index]?.quantity?.message && styles.redBackground
                    )}>
                    <FormInputText
                      name={`looseItems[${index}].quantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true
                      }}
                      classes={clsx(styles.quantity_input_style)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Controller
                      name={`looseItems[${index}]`}
                      control={control}
                      render={({ field: { value } }) =>
                        fields.length > 1 || value?.id ? (
                          <IconButton onClick={() => onRemoveRow(index)}>
                            <Cancel />
                          </IconButton>
                        ) : null
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!errors?.['empty_tables']?.message && (
        <ErrorBlock errorText={errors['empty_tables'].message} />
      )}
      <div className={styles.footer}>
        <Button className={styles.add_button} onClick={onAddRow}>
          <Add />
          <span>Add</span>
        </Button>
      </div>
    </div>
  );
};

export default LooseItemsTable;
