import { useSelector } from 'react-redux';
import {
  selectActiveLocationsData,
  selectDepartmentsData,
  selectSingleUserData,
  selectUserEditTab,
  selectUserListFilter,
  selectUserListFilterCriteria,
  selectUserPrefixList,
  selectUserRoleListData
} from 'pages/UserManagement/UserList/selectors';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export function useUserSelector() {
  const currentUser = useSelector(selectSingleUserData());

  const filter = useSelector(selectUserListFilter());
  const filterCriteria = useSelector(selectUserListFilterCriteria());

  const userRoles = useSelector(selectUserRoleListData());
  const locations = useSelector(selectActiveLocationsData());
  const departments = useSelector(selectDepartmentsData());
  const prefixList = useSelector(selectUserPrefixList());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  const userEditTab = useSelector(selectUserEditTab());

  return {
    currentUser,
    filter,
    filterCriteria,
    userRoles,
    locations,
    departments,
    prefixList,
    unsavedFormData,
    userEditTab
  };
}
