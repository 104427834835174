import React from 'react';
import styles from './commonComponents.module.scss';

import { useFormContext } from 'react-hook-form';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

import { useReportSelector } from 'hooks/ReportList';

export default function AssignmentDetails({ onSave }) {
  const { setValue } = useFormContext();
  const { creationForm } = useReportSelector();
  const { users } = creationForm;

  const handleSelect = (name, value) => {
    setValue(name, value);
    onSave && onSave();
  };

  const handleDateSelect = () => {
    onSave && onSave();
  };

  return (
    <div className={styles.assignee}>
      <div className={styles.assignee_field}>
        <span>Assignee</span>
        <FormSearchInput
          name="assignedToUser"
          options={users || []}
          onSelect={handleSelect}
          classes={styles.assignee_field_classes}
          clearable
        />
      </div>
      <div className={styles.assignee_field}>
        <span>Due Date</span>
        <FormDatePicker name="assignedAtUtc" placement="top" extraAction={handleDateSelect} />
      </div>
    </div>
  );
}
