import { useDispatch } from 'react-redux';

import {
  getLeavelists,
  setLeaveListFilter,
  getFilterCriteria,
  clearLeaveListState
} from 'actions/leaveListActions';

export function useLeavelistActions() {
  const dispatch = useDispatch();

  const getLeavelistsAction = (q) => dispatch(getLeavelists(q));

  const setFilterAction = (data) => dispatch(setLeaveListFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const clearStateAction = () => dispatch(clearLeaveListState());

  return {
    getLeavelistsAction,
    setFilterAction,
    getFilterCriteriaAction,
    clearStateAction
  };
}
