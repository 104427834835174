import api from '../api';
import {
  SET_MANAGE_LOOSE_ITEMS_ACTIVE_ENTITY_DATA,
  CLEAR_MANAGE_LOOSE_ITEMS_STATE
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

export const getLocationLooseItems = (query) => () =>
  api.manageLooseItems
    .getLocationLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const checkLooseItemsLocation = (query) => () =>
  api.manageLooseItems
    .checkLooseItemsLocationApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const addLooseItems = (data) => () => {
  const apiFn = data?.picklist?.id
    ? api.manageLooseItems.addLooseItemsToPicklistApi
    : api.manageLooseItems.addLooseItemsToLeavelistApi;
  return apiFn(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        `The loose items are successfully added to the ${
          data?.picklist?.id
            ? 'picklist ' + data?.picklist?.name || ''
            : 'leavelist ' + data?.leavelist?.name || ''
        }`
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const editLooseItem = (data) => () => {
  const apiFn = data?.picklist?.id
    ? api.manageLooseItems.editLooseItemOfPicklistApi
    : api.manageLooseItems.editLooseItemOfLeavelistApi;

  return apiFn(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        `The loose items are edited successfully within the ${
          data?.picklist?.id
            ? 'picklist ' + data?.picklist?.name || ''
            : 'leavelist ' + data?.leavelist?.name || ''
        }`
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const setEntityData = (payload) => (dispatch) =>
  dispatch({ type: SET_MANAGE_LOOSE_ITEMS_ACTIVE_ENTITY_DATA, payload });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_MANAGE_LOOSE_ITEMS_STATE });
