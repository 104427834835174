import styles from './SummaryHeader.module.scss';
import React from 'react';
import clsx from 'clsx';

export default function SummaryHeader({ title, buttonsNumber, children }) {
  return (
    <div className={styles.headerWrapper}>
      <h1>{title}</h1>
      {children && (
        <div
          className={clsx(
            styles.headerWrapper__controls,
            buttonsNumber > 1 && styles.headerWrapper__fitControls
          )}>
          {children}
        </div>
      )}
    </div>
  );
}
