import React, { useRef, useState } from 'react';
import styles from './components.module.scss';

import DetailsHeader from 'components/DetailsComponents/Header';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import MenuPopper from 'components/MenuPopper/MenuPopper';
import { AddPicklistAssetPopup } from 'components/Popups';

import { useUserConfig } from 'hooks/useUserConfig';
import { usePicklistSelector } from 'hooks/Picklist';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';
import { useAddBomActions } from 'hooks/AddBomToPicklist';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  ADD_BOM_TO_PICKLIST_PATH,
  PICKLIST_EDIT_PATH,
  PICKLIST_SUMMARY_PATH
} from 'constants/routeConstants';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { Button, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import csvIcon from 'assets/images/csvIcon.svg';

import { refFileDownload, wait } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function PicklistDetailsHeader({
  onRemove,
  updateTable,
  onAddItems,
  onChangeStatus,
  isTableEmpty
}) {
  const [openPopup, setOpenPopup] = useState(false);

  const [open, setOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const anchorRef = useRef(null);

  const history = useHistory();
  const isMobile = useMobileViewport();
  const { currentPicklist } = usePicklistSelector();

  const { setActivePicklistAction } = useAddBomActions();

  const {
    isAdminUser,
    isOperationsManagerUser,
    isFieldTechUser,
    isEntityCreatorUser,
    isLocationInConfig
  } = useUserConfig();

  const { id, name, status, location, createdByUser } = currentPicklist;

  const isPicklistOpen = status?.name === 'Open';

  const isRemoveAvailable =
    isPicklistOpen && (isAdminUser || isEntityCreatorUser(createdByUser.email));

  const isEditAvailable = isAdminUser
    ? true
    : isOperationsManagerUser
    ? isEntityCreatorUser(createdByUser.email) || isLocationInConfig(location?.id)
    : isEntityCreatorUser(createdByUser.email);

  const areControlsAvailable = isAdminUser
    ? true
    : isOperationsManagerUser || isFieldTechUser
    ? isEntityCreatorUser(createdByUser.email) || isLocationInConfig(location?.id)
    : isEntityCreatorUser(createdByUser.email);

  const goBack = () => history.push(PICKLIST_SUMMARY_PATH);
  const editPicklist = () => history.push(`${PICKLIST_EDIT_PATH}/${id}`);

  const handleMenuClick = () => setOpen(!open);

  const downloadCsv = () => {
    refFileDownload('Picklist/Serialized', { picklistId: id });
    setDisabled(true);
    wait(15000).then(() => setDisabled(false));
  };

  const handleAddBomClick = () => {
    const data = { id: currentPicklist.id, name: currentPicklist?.name || '' };
    setActivePicklistAction(data);
    history.push(ADD_BOM_TO_PICKLIST_PATH);
  };

  const DetailsHelpers = () => (
    <div className={styles.controls}>
      {status?.name !== 'Completed' && (
        <>
          <Button className={styles.add_button} onClick={handleAddBomClick}>
            <span>Add bom</span>
          </Button>
          <Button className={styles.add_button} onClick={() => setOpenPopup(true)}>
            <span>Add assets</span>
          </Button>
          <Button className={styles.add_button} onClick={onAddItems}>
            <span>Add loose items</span>
          </Button>
          <Button
            className={styles.start_button}
            classes={{ disabled: styles.start_button__disabled }}
            disabled={isPicklistOpen && isTableEmpty}
            onClick={onChangeStatus}>
            <span>{isPicklistOpen ? 'Start delivery' : 'Complete'}</span>
          </Button>
        </>
      )}
    </div>
  );

  return (
    <>
      {openPopup && (
        <AddPicklistAssetPopup
          open={openPopup}
          setOpen={setOpenPopup}
          screen="Picklist Details"
          picklist={{ id, name }}
          onSuccess={updateTable}
        />
      )}
      <DetailsHeader breadcrumbs={[{ name }]} title={name}>
        {isMobile && (
          <>
            {isEditAvailable && isPicklistOpen && (
              <HeaderButton onClick={editPicklist}>
                <CreateIcon />
              </HeaderButton>
            )}
            {isRemoveAvailable && isPicklistOpen && (
              <HeaderButton onClick={onRemove}>
                <DeleteIcon />
              </HeaderButton>
            )}
            <HeaderButton onClick={goBack}>
              <ChevronLeftIcon />
            </HeaderButton>
          </>
        )}

        {!isMobile && (
          <>
            {areControlsAvailable && <DetailsHelpers />}
            <Button ref={anchorRef} className={styles.actions} onClick={handleMenuClick}>
              <MoreHorizIcon />
            </Button>
            <MenuPopper
              open={open}
              setOpen={setOpen}
              anchorRef={anchorRef}
              classes={styles.menu}
              placement="bottom-end">
              {isEditAvailable && isPicklistOpen && (
                <MenuItem onClick={editPicklist}>
                  <CreateIcon />
                  Edit picklist
                </MenuItem>
              )}
              {isRemoveAvailable && isPicklistOpen && (
                <MenuItem onClick={onRemove}>
                  <DeleteIcon />
                  Remove picklist
                </MenuItem>
              )}
              <MenuItem onClick={downloadCsv} className={clsx(isDisabled && styles.disabled)}>
                <img src={csvIcon} alt="" />
                Download CSV file
              </MenuItem>
            </MenuPopper>
          </>
        )}
      </DetailsHeader>
      {areControlsAvailable && isMobile && <DetailsHelpers />}
    </>
  );
}
