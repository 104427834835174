import React, { useEffect, useState } from 'react';
import styles from './AddColumnModal.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Separator from 'components/Separator/Separator';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { FormControlLabel, IconButton, TextField } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { reformatInputValue } from 'helpers/AppHelpers';

export default function AddColumnModal({
  open,
  setOpen,
  items,
  onApply,
  selected,
  label,
  separatorCondition
}) {
  const [searchValue, setSearchValue] = useState('');
  const [chosenItems, setChosenItems] = useState([]);

  const [options, setOptions] = useState([]);

  const clearModal = () => {
    setOptions([]);
    setChosenItems([]);
    setSearchValue('');
  };

  useEffect(() => {
    if (open) {
      setChosenItems(selected);
    }
  }, [open]);

  useEffect(() => {
    setOptions(items);
  }, [items]);

  useEffect(() => {
    const filteredOptions = searchValue
      ? items.filter((field) => field[label].toLowerCase().includes(searchValue.toLowerCase()))
      : items;
    setOptions(filteredOptions);
  }, [searchValue]);

  const closePopup = () => {
    setOpen(false);
    clearModal();
  };

  const handleApplyClick = () => {
    onApply(chosenItems);
    clearModal();
    closePopup();
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = reformatInputValue(inputValue, 50);
    setSearchValue(inputValue);
  };

  const handleFieldSelect = (field) => {
    if (chosenItems.find((item) => item.id === field.id)) {
      setChosenItems(chosenItems.filter((item) => item.id !== field.id));
    } else {
      setChosenItems([...chosenItems, field]);
    }
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <section className={styles.wrapper}>
        <DialogHeader onClose={closePopup} title="Add Column"></DialogHeader>
        <div className={styles.form}>
          <div className={styles.form__input}>
            <TextField
              name="value"
              variant="outlined"
              className={styles.form__input_field}
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <IconButton onClick={() => setSearchValue('')}>
                        <CancelRoundedIcon fontSize="small" className={styles.form__input_clear} />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
              inputProps={{
                autoComplete: 'off'
              }}
            />
          </div>
          <div className={styles.form__fields}>
            {options.length
              ? options.map((field) => (
                  <div className={styles.form__fields_option} key={field.id}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          name={field[label] || field.name}
                          value={!!chosenItems.find((column) => column.id === field.id)?.id}
                          onChange={() => handleFieldSelect(field)}
                        />
                      }
                      label={field[label] || field.name}
                    />
                    {separatorCondition && separatorCondition(field, options) && (
                      <Separator classes={styles.separator} />
                    )}
                  </div>
                ))
              : !!searchValue && <span className={styles.form__fields_empty}>No matches</span>}
          </div>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" size="popup" />
          <MainButton text="Apply" action={handleApplyClick} type="primary" size="popup" />
        </div>
      </section>
    </DialogWrapper>
  );
}
