import React, { useEffect, useState } from 'react';
import styles from './FormInputText.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { getSeverityColor } from 'helpers/AppHelpers';
import clsx from 'clsx';
import { Indicator } from 'components/Counting';
import { useDebounce } from 'use-debounce';
import { useAssetActions } from 'hooks/Asset';

export default function FormDrCodeAvailabilityInput({ name }) {
  const { control, setValue, getValues, clearErrors } = useFormContext();
  const { getAvailableDRCodeAction } = useAssetActions();

  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const handleInput = (e, isInvalid) => {
    const value = e.target.value;

    const { prefix } = getValues('assetPrefix');
    const digits = value.slice(prefix.length + 1);

    const formattedDigits = digits.replace(/\D/g, '');

    setInputValue(formattedDigits);

    isInvalid && clearErrors(name);

    const combinedValue = [prefix, '-', formattedDigits].join('');
    setValue(name, combinedValue, { shouldDirty: true });
  };

  const getQuery = (drCode) => {
    const prefix = getValues('assetPrefix');
    const assetId = getValues('id') || null;
    return { prefixId: prefix.id, drCode, assetId };
  };

  useEffect(async () => {
    if (!debouncedInputValue) return;

    const res = (await getAvailableDRCodeAction(getQuery(getValues(name)))) || {};

    setValue('drCodeStatus', {
      isAvailable: res?.isAvailable || false,
      status: res.isAvailable
        ? 'Available!'
        : res.isAvailable === false
        ? 'Already in use!'
        : 'Invalid Format'
    });
  }, [debouncedInputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <div className={clsx(styles.inputWrapper, styles.drCode)}>
          <TextField
            {...field}
            className={clsx(styles.textInput, styles.numberType, error && styles.error)}
            variant="outlined"
            value={value ?? ''}
            onChange={(e) => handleInput(e, !!error?.message)}
            type="text"
            inputProps={{ inputMode: 'text', autoComplete: 'off' }}
          />
          {!!error && <span>{error.message}</span>}

          <Controller
            name="drCodeStatus"
            control={control}
            render={({ field: { value } }) => (
              <div className={styles.indicator}>
                <span className={clsx(value.isAvailable ? styles.success : styles.error)}>
                  {value?.status || ''}
                </span>
                <Indicator
                  color={getSeverityColor(value.isAvailable ? 'Operational' : 'Critical')}
                />
              </div>
            )}
          />
        </div>
      )}
    />
  );
}
