import React from 'react';
import styles from './Card.module.scss';

import { useHistory } from 'react-router-dom';

import { ReactComponent as OpenNewTab } from 'assets/images/openInNewTabIcon.svg';

import { IconButton } from '@material-ui/core';

import { BASE_ADDRESS } from 'api/api';

export function Card({ screen }) {
  const { title, subtitle, icon, to } = screen;

  const history = useHistory();

  const clickCard = (to) => history.push(to);

  const openNewTab = (e, to) => {
    e.stopPropagation();
    window.open(BASE_ADDRESS + to, '_blank');
  };

  return (
    <div className={styles.card} onClick={() => clickCard(to)}>
      <div className={styles.card__controls}>
        <IconButton className={styles.card__controls_btn} onClick={(e) => openNewTab(e, to)}>
          <OpenNewTab />
        </IconButton>
      </div>

      <div className={styles.card__preview}>
        <div className={styles.card__preview_icon}>{icon}</div>
      </div>

      <div className={styles.card__info}>
        <div className={styles.card__info_title}>{title}</div>
        <div className={styles.card__info_subtitle}>{subtitle}</div>
      </div>
    </div>
  );
}
