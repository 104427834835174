import { useDispatch } from 'react-redux';
import {
  getDashboardConfig,
  updateTabConfig,
  updateSectionConfig,
  getFilterCriteria,
  getRandRDashboard,
  getLevelsDashboard,
  getSnapshotDashboard,
  setTab,
  clearTabState,
  clearState,
  getRandRDashboardCsvResource
} from 'actions/teamMemberDashboardActions.js';

export function useTeamMemberDashboardActions() {
  const dispatch = useDispatch();

  const getDashboardConfigAction = (q) => dispatch(getDashboardConfig(q));
  const updateTabConfigAction = (data) => dispatch(updateTabConfig(data));

  const updateSectionConfigAction = (data) => dispatch(updateSectionConfig(data));

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const getRandRDashboardAction = () => dispatch(getRandRDashboard());
  const getLevelsDashboardAction = () => dispatch(getLevelsDashboard());
  const getSnapshotDashboardAction = () => dispatch(getSnapshotDashboard());

  const setTabAction = (tab) => dispatch(setTab(tab));

  const getCsvResourceAction = (q) => dispatch(getRandRDashboardCsvResource(q));

  const clearTabStateAction = (data) => dispatch(clearTabState(data));
  const clearStateAction = (flag) => dispatch(clearState(flag));

  return {
    getDashboardConfigAction,
    updateTabConfigAction,
    updateSectionConfigAction,
    getFilterCriteriaAction,
    getRandRDashboardAction,
    getLevelsDashboardAction,
    getSnapshotDashboardAction,
    setTabAction,
    getCsvResourceAction,
    clearTabStateAction,
    clearStateAction
  };
}
