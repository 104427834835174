import React, { useEffect, useRef, useState } from 'react';
import styles from './UserList.module.scss';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableHeadComponent,
  TableWrapper
} from 'components/SummaryComponents';

import { Table, TableBody } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useUserActions, useUserSelector } from 'hooks/UserManagement';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';

import { USER_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import { USER_DETAILED_PATH } from 'constants/routeConstants';

import redLockIcon from 'assets/images/redLock.svg';
import blackLockIcon from 'assets/images/blackLock.svg';

import { userListColumns } from './tableConfig';
import FilterModal from './Filter';
import { generateChips } from './helpers';

import { cloneObj, getSeverityColor } from 'helpers/AppHelpers';

import clsx from 'clsx';
import { Indicator } from 'components/Counting';

export default function UserList() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const { filter, filterCriteria } = useUserSelector();
  const {
    setFilterAction,
    getUserListAction,
    getFilterCriteriaAction,
    updateUserAction,
    clearStateAction
  } = useUserActions();

  const { isConfigReceived, isEntityCreatorUser, getTableLimit } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { responseReceived, filters, pagination, sortRules } = filter;

  const generateInitialQuery = () => ({
    ...filter,
    pagination: { ...pagination, limit: getTableLimit(USER_LIST_TABLE_PARAMETER) }
  });

  const getUsers = (query, isAutoload) => {
    setFilterAction(query);

    getUserListAction(query).then((tableData) => {
      setUsers(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setUsers = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      getUsers(generateInitialQuery());
      setChips(generateChips(criteria, filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = (value) => getUsers(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getUsers(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getUsers(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => {
    getUsers(getChangedByApplyFilter(filter, values));
    setChips(generateChips(filterCriteria, values));
  };
  const changeLimit = () =>
    getUsers(getChangedByLimitFilter(filter, getTableLimit(USER_LIST_TABLE_PARAMETER)));

  const toggleBlockStatus = (user) =>
    updateUserAction({ ...user, locked: !user.locked }, { skipRouting: true }).then(
      (res) => res?.id && getUsers({ ...filter, responseReceived: false })
    );

  const openFilterModal = () => setOpenFilter(true);

  const deleteChip = (chip) => {
    const newFilters = cloneObj(filters);
    newFilters[chip.key] = newFilters[chip.key].filter((id) => id !== chip.itemId);
    applyFilter(newFilters);
  };

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="User List">
          {!isMobile && <DownloadCSVButton filter={filter} endpoint="Users/Serialized" />}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={userListColumns}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.user_table_row}>
                  <StyledTableCell className={styles.nameCell}>
                    <LinkComponent
                      boldText
                      path={`${USER_DETAILED_PATH}/${row.id}`}
                      name={row.lastName + ', ' + row.firstName}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.emailCell}>{row.email}</StyledTableCell>
                  <StyledTableCell className={styles.roleCell}>{row.userRole.role}</StyledTableCell>
                  <StyledTableCell className={styles.blockCell}>
                    <div className={clsx(isEntityCreatorUser(row.email) && styles.disabled)}>
                      <img src={row.locked ? redLockIcon : blackLockIcon} alt="Block" />
                      <span
                        onClick={() => !isEntityCreatorUser(row.email) && toggleBlockStatus(row)}
                        className={clsx(row.locked ? styles.locked : styles.unlocked)}>
                        {row.locked ? 'Unblock' : 'Block'}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className={styles.isNewCell}>
                    {row.isNew && <Indicator color={getSeverityColor('NonCritical')} />}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination.limit}
        tableName={USER_LIST_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
