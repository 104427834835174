import React, { useEffect, useState } from 'react';

import SelectLooseItemsPopup from 'components/SelectLooseItemsPopup';
import { AddButton } from 'components/Buttons';

import { useManageLooseItemsActions, useManageLooseItemsSelector } from 'hooks/ManageLooseItems';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { HOME_PATH } from 'constants/routeConstants';

import { pluck } from 'helpers/AppHelpers';

import Wrapper from './components/Wrapper';
import LooseItemsTable from './components/LooseItemsTable';

export default function ManageLooseItems() {
  const history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);

  const { entityData, isEditMode } = useManageLooseItemsSelector();
  const { getLocationLooseItemsAction, clearStateAction } = useManageLooseItemsActions();

  const { activeEntity, locationLooseItemDetails, isPicklist } = entityData;
  const entityId = activeEntity.id;
  const { id, moveQuantity, moveByAheadAccount, picklistAssetId, leavelistAssetId } =
    locationLooseItemDetails;

  const methods = useForm({
    defaultValues: {
      items: [],
      picklist: isPicklist ? {} : null,
      leavelist: isPicklist ? null : {},
      warnings: []
    },
    mode: 'onChange'
  });
  const { setValue, getValues } = methods;

  useEffect(() => {
    if (!activeEntity?.id) {
      history.push(HOME_PATH);
    }
  }, []);

  useEffect(() => {
    if (!isEditMode) return;

    const filters = {
      locationLooseItemIds: [id],
      ...(isPicklist
        ? { picklistId: entityId, picklistAssetId }
        : { leavelistId: entityId, leavelistAssetId })
    };

    getLocationLooseItemsAction({ filters }).then((res) => {
      if (Array.isArray(res) && !!res?.length && res.length === 1) {
        const item = res[0];
        const modifiedItem = {
          ...item,
          moveQuantity: item?.moveQuantity || moveQuantity || 0,
          moveByAheadAccount: item?.moveByAheadAccount || moveByAheadAccount || 0
        };
        setValue('items', [modifiedItem]);
      }
    });
  }, []);

  useEffect(() => {
    if (entityData?.activeEntity?.id) {
      setValue(isPicklist ? 'picklist' : 'leavelist', activeEntity);
    }
  }, [entityData]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const addLooseItems = async (items) => {
    const looseItemIds = pluck(items, 'id');

    const filters = {
      looseItemIds,
      ...(isPicklist ? { picklistId: entityId } : { leavelistId: entityId })
    };

    const locationData = await getLocationLooseItemsAction({ filters });

    if (!Array.isArray(locationData) || locationData.length === 0) return;

    const currentSelectedItems = getValues('items');

    const getAdditionalTableRows = (id) => {
      const dropdownLocationsForItem = locationData.filter(({ looseItem }) => looseItem.id === id);

      const currentSelectedItemIds = pluck(
        currentSelectedItems.filter(({ looseItem }) => looseItem.id === id),
        'id'
      );

      const filteredDropdownLocations = dropdownLocationsForItem.filter(
        ({ id }) => !currentSelectedItemIds.includes(id)
      );

      return { ...filteredDropdownLocations[0], locationLooseItems: dropdownLocationsForItem };
    };

    const additionalTableRows = looseItemIds.map(getAdditionalTableRows);

    const updatedSelectedItems = currentSelectedItems.concat(additionalTableRows);

    setValue('items', updatedSelectedItems, { shouldDirty: true });
  };

  const showPopup = () => setOpenPopup(true);

  return (
    <FormProvider {...methods}>
      {openPopup && (
        <SelectLooseItemsPopup
          open={openPopup}
          setOpen={setOpenPopup}
          onAdd={addLooseItems}
          queryOptions={{
            excludeLocationLooseItemIds: pluck(getValues('items'), 'id'),
            ...(isPicklist ? { picklistId: entityId } : { leavelistId: entityId })
          }}
        />
      )}
      <Wrapper>
        <LooseItemsTable />
        {!isEditMode && <AddButton onClick={showPopup} />}
      </Wrapper>
    </FormProvider>
  );
}
