import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getRootCausesApi = (query) =>
  api.get(`/RootCauses?${queryToString(query)}`).then((res) => res.data);

export const getCreationFormApi = () => api.get('/TicketType/CreationForm').then((res) => res.data);

export const getSingleRootCauseApi = (query) =>
  api.get(`/RootCause?${queryToString(query)}`).then((res) => res.data);

export const createRootCauseApi = (params) =>
  api.post('/RootCause', params).then((res) => res.data);

export const updateRootCauseApi = (params) => api.put('/RootCause', params).then((res) => res.data);
