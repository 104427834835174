import React from 'react';
import styles from './TicketComponents.module.scss';

import { FormLabel, FormRow } from 'components/FormComponents';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormVideosUpload from 'components/FormComponents/FormVideosUpload/FormVideosUpload';

import Subhead from './Subhead';

export function Attachments() {
  return (
    <div className={styles.section}>
      <Subhead>Attachments</Subhead>
      <div className={styles.container}>
        <div className={styles.container__block}>
          <FormRow type="file">
            <FormLabel>Photo</FormLabel>
            <FormImagesUpload />
          </FormRow>
          <FormRow type="file">
            <FormLabel>File</FormLabel>
            <FormFilesUpload />
          </FormRow>
        </div>

        <div className={styles.container__block}>
          <FormRow type="file">
            <FormLabel>Video</FormLabel>
            <FormVideosUpload />
          </FormRow>
        </div>
      </div>
    </div>
  );
}
