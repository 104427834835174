import React from 'react';
import styles from './Bar.module.scss';

import GroupedHorizontalBar from 'components/Charts/GroupedHorizontalBar/GroupedHorizontalBar';

import { getProjectName } from 'helpers/AppHelpers';

import { LEVELS_BAR_COLORS, getLevelsFileName } from '../../../../helpers';

export default function Bar({ dashboard, department }) {
  if (!('staffingLevelsProjects' in dashboard) || !dashboard?.staffingLevelsProjects?.length)
    return null;

  const { duration1ColumnName, duration2ColumnName, staffingLevelsProjects } = dashboard;

  const categories = staffingLevelsProjects?.map(({ project }) => getProjectName(project)) || [];

  const DURATIONS =
    duration1ColumnName && duration2ColumnName ? [duration1ColumnName, duration2ColumnName] : [];

  const counts = {
    duration1Counts: staffingLevelsProjects?.map(
      ({ staffingLevelsDurationValues }) => staffingLevelsDurationValues?.duration1Value || 0
    ),
    duration2Counts: staffingLevelsProjects?.map(
      ({ staffingLevelsDurationValues }) => staffingLevelsDurationValues?.duration2Value || 0
    )
  };

  const series =
    DURATIONS?.map((name, index) => ({ name, data: counts[`duration${index + 1}Counts`] })) || [];

  return (
    <div className={styles.bar}>
      <GroupedHorizontalBar
        series={series}
        categories={categories}
        fileName={getLevelsFileName(department?.name)}
        csvColumnName="Project"
        colors={LEVELS_BAR_COLORS}
      />
    </div>
  );
}
