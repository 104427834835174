import React from 'react';
import styles from './AddBomTable.module.scss';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Paper, TableContainer, Table, TableRow, TableHead, TableBody } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { getLocationWithSublocationName, getQtyWithBaaValue } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import {
  isBaaMoreThanAvailableBaa,
  isBaaMoreThanQty,
  isQuantityMoreThanAvailableQty,
  errorMessages,
  EMPTY_QUANTITY_COLUMN_ERROR_NAME
} from '../../../helpers';

import clsx from 'clsx';

import FieldValidationMessage from './FieldValidationMessage';

const FIELDS_PARAMETER = 'looseItems';

export default function ItemsTable() {
  const { control, getValues, clearErrors, formState, setError } = useFormContext();
  const { errors } = formState;
  const { fields } = useFieldArray({ control, name: FIELDS_PARAMETER });

  const isUniqueItem = (index) =>
    !(index !== 0 && fields[index]?.['looseItem']?.id === fields[index - 1]?.['looseItem']?.id);

  const getRowSpan = (index) =>
    fields.filter(({ looseItem }, i, array) => looseItem?.id === array[index]?.['looseItem']?.id)
      ?.length;

  const clearErrorIfPresent = (name) => errors?.[name] && clearErrors(name);
  const checkEmptyQuantityColumnError = () =>
    errors?.[EMPTY_QUANTITY_COLUMN_ERROR_NAME] && clearErrors(EMPTY_QUANTITY_COLUMN_ERROR_NAME);
  const checkBaaMoreThanAddQtyError = (qtyValue, index) => {
    const rowName = `${FIELDS_PARAMETER}[${index}]`;
    const row = getValues(rowName);
    const errorName = `${rowName}.selectedByAheadAccount`;
    const baaValue = row?.selectedByAheadAccount || 0;

    if (isBaaMoreThanQty(baaValue || 0, qtyValue || 0)) {
      setError(errorName, getErrorsProperties(errorMessages.baaMoreThanQty));
    } else {
      const isErrorExist =
        errors?.[FIELDS_PARAMETER]?.[index]?.['selectedByAheadAccount']?.message ===
        errorMessages.baaMoreThanQty;
      isErrorExist && clearErrors(errorName);
    }
  };

  const handleQuantityInput = (name, value, rowIndex) => {
    const availableQty = getValues(`${FIELDS_PARAMETER}[${rowIndex}]`)?.quantity;

    if (isQuantityMoreThanAvailableQty(value || 0, availableQty || 0)) {
      setError(name, getErrorsProperties(errorMessages.exceedsMaxValue));
    } else clearErrorIfPresent(name);

    checkEmptyQuantityColumnError();
    checkBaaMoreThanAddQtyError(value, rowIndex);
  };

  const handleBaaInput = (name, value, rowIndex) => {
    const values = getValues(`${FIELDS_PARAMETER}[${rowIndex}]`);

    const availableBaa = values?.byAheadAccount;
    const selectedQty = values?.selectedQuantity;

    if (isBaaMoreThanQty(value || 0, selectedQty || 0)) {
      setError(name, getErrorsProperties(errorMessages.baaMoreThanQty));
    } else if (isBaaMoreThanAvailableBaa(value || 0, availableBaa || 0)) {
      setError(name, getErrorsProperties(errorMessages.baaMoreThanAvailableBaa));
    } else clearErrorIfPresent(name);
  };

  return (
    fields.length > 0 && (
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Loose item</StyledTableCell>
              <StyledTableCell>Required QTY</StyledTableCell>
              <StyledTableCell>Location(Sublocation)</StyledTableCell>
              <StyledTableCell>Available QTY(BAA)</StyledTableCell>
              <StyledTableCell
                className={clsx(
                  errors?.[EMPTY_QUANTITY_COLUMN_ERROR_NAME]?.message && styles.invalid
                )}>
                Add QTY
              </StyledTableCell>
              <StyledTableCell>From BAA</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <StyledTableRow key={item.id}>
                {isUniqueItem(index) && (
                  <StyledTableCell rowSpan={getRowSpan(index)}>
                    {item?.looseItem?.name || ''}
                  </StyledTableCell>
                )}
                {isUniqueItem(index) && (
                  <StyledTableCell rowSpan={getRowSpan(index)}>
                    {item?.configRequiredQuantity || 0}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {getLocationWithSublocationName(item?.location, item?.sublocation)}
                </StyledTableCell>
                <StyledTableCell>
                  {getQtyWithBaaValue(item?.quantity, item?.byAheadAccount)}
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(
                    errors?.looseItems?.[index]?.selectedQuantity?.message && styles.invalid
                  )}>
                  <FormInputText
                    name={`${FIELDS_PARAMETER}[${index}].selectedQuantity`}
                    options={{
                      focus: true,
                      type: 'quantity',
                      disableError: true,
                      index,
                      extraAction: handleQuantityInput
                    }}
                    classes={styles.cell_input}
                  />
                  <FieldValidationMessage
                    errorText={errors?.looseItems?.[index]?.selectedQuantity?.message}
                  />
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(
                    errors?.looseItems?.[index]?.selectedByAheadAccount?.message && styles.invalid
                  )}>
                  <FormInputText
                    name={`${FIELDS_PARAMETER}[${index}].selectedByAheadAccount`}
                    options={{
                      focus: true,
                      type: 'quantity',
                      disableError: true,
                      index,
                      extraAction: handleBaaInput
                    }}
                    classes={styles.cell_input}
                  />
                  <FieldValidationMessage
                    errorText={errors?.looseItems?.[index]?.selectedByAheadAccount?.message}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
