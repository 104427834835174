import {
  SET_TENT_CONFIGURATION_LIST,
  SET_CURRENT_CONFIGURATION,
  SET_CONFIGURATION_CREATION_FORM,
  CLEAR_TENT_CONFIGURATIONS_STATE,
  SET_CONFIGURATION_LIST_SEARCH_VALUE
} from 'constants/reduceConstants';

const initialState = {
  tentConfigurationList: [],
  currentConfiguration: {},
  creationForm: {},
  searchValue: ''
};

const tentConfigurationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TENT_CONFIGURATION_LIST:
      return {
        ...state,
        tentConfigurationList: payload
      };
    case SET_CURRENT_CONFIGURATION:
      return {
        ...state,
        currentConfiguration: payload
      };
    case SET_CONFIGURATION_CREATION_FORM:
      return {
        ...state,
        creationForm: payload
      };
    case SET_CONFIGURATION_LIST_SEARCH_VALUE:
      return {
        ...state,
        searchValue: payload
      };
    case CLEAR_TENT_CONFIGURATIONS_STATE:
      return { ...initialState, searchValue: state.searchValue };
    default:
      return state;
  }
};

export default tentConfigurationReducer;
