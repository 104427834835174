import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getDashboardConfigApi = (query) =>
  api.get(`/User/TeamMemberDashboardsConfig?${queryToString(query)}`).then((res) => res.data);

export const updateTabConfigApi = (data) =>
  api.put('/User/TeamMemberDashboardsConfig', data).then((res) => res);

export const updateSectionConfigApi = (data) =>
  api.put('/User/TeamMemberDashboardConfig', data).then((res) => res);

export const getFilterCriteria = (q) =>
  api.get(`/TeamMemberDashboard/FilterCriteria?${queryToString(q)}`).then((res) => res.data);

export const getRandRDashboardApi = () =>
  api.get('/TeamMemberDashboard/RandRDashboards').then((res) => res.data);

export const getLevelsDashboardApi = () =>
  api.get('/TeamMemberDashboard/StaffingLevelsAvailabilityDashboards').then((res) => res.data);

export const getSnapshotDashboardApi = () =>
  api.get('/TeamMemberDashboard/StaffingSnapshotsDashboard').then((res) => res.data);

export const getRandRDashboardCsvResourceApi = (q) =>
  api
    .get(`/TeamMemberDashboard/RandRDashboard/Serialized?${queryToString(q)}`)
    .then((res) => res.data);
