import { useSelector } from 'react-redux';
import {
  selectActiveCategoryData,
  selectAssetFieldsData,
  selectCategoryListData,
  selectPrefixTypesData,
  selectSinglePrefixData
} from 'pages/PrefixCategoryList/selectors';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export function usePrefixSelector() {
  const currentPrefix = useSelector(selectSinglePrefixData());

  const activeCategory = useSelector(selectActiveCategoryData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  const prefixTypes = useSelector(selectPrefixTypesData());
  const categoryList = useSelector(selectCategoryListData());
  const assetFields = useSelector(selectAssetFieldsData());

  return {
    currentPrefix,
    activeCategory,
    prefixTypes,
    categoryList,
    assetFields,
    unsavedFormData
  };
}
