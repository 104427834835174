import React from 'react';
import styles from './Header.module.scss';

import FastBackwardIco from 'assets/images/fastBackwardIco.svg';
import FastForwardIco from 'assets/images/fastForwardIco.svg';

import { useHistory } from 'react-router-dom';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';

import { MainButton } from 'components/StyledComponents';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

const FastBackButton = ({ text }) => (
  <section>
    <img src={FastBackwardIco} alt="" /> {text}
  </section>
);
const FastForwardButton = ({ text }) => (
  <section>
    {text} <img src={FastForwardIco} alt="" />
  </section>
);

export default function AssetNavigationControls() {
  const history = useHistory();

  const { clearAllStateAction } = useAssetActions();
  const { currentAsset } = useAssetSelector();
  const { nextAsset, previousAsset } = currentAsset;

  const handleNextAssetClick = () => {
    clearAllStateAction();
    history.push(`${ASSET_DETAILED_PATH}/${nextAsset.id}`);
  };

  const handlePrevAssetClick = () => {
    clearAllStateAction();
    history.push(`${ASSET_DETAILED_PATH}/${previousAsset.id}`);
  };

  return (
    <div className={styles.navigation__buttons}>
      {previousAsset && (
        <MainButton
          text={<FastBackButton text={<span>{previousAsset.drCode}</span>} />}
          action={handlePrevAssetClick}
          type="primary"
          size="navigation"
        />
      )}
      {nextAsset && (
        <MainButton
          text={<FastForwardButton text={<span>{nextAsset.drCode}</span>} />}
          action={handleNextAssetClick}
          type="primary"
          size="navigation"
        />
      )}
    </div>
  );
}
