const BOL_TYPES = Object.freeze({
  custom: 'CUSTOM',
  picklist: 'PICKLIST',
  leavelist: 'LEAVELIST'
});

const generatePayload = (values, type, id) => {
  const isCustom = type === BOL_TYPES.custom;
  const isPicklist = type === BOL_TYPES.picklist;

  const assetsLabel = isCustom ? 'assets' : isPicklist ? 'picklistAssets' : 'leavelistAssets';
  const looseItemsLabel = isCustom
    ? 'looseItems'
    : isPicklist
    ? 'picklistLooseItems'
    : 'leavelistLooseItems';

  const { [assetsLabel]: assets, [looseItemsLabel]: looseItems, nonDratItems } = values;

  const payload = {
    ...(id && { billOfLadingId: id }),
    ...(assets && { [assetsLabel]: assets }),
    ...(looseItems && { [looseItemsLabel]: looseItems }),
    ...(nonDratItems && { nonDratItems })
  };

  return payload;
};

export { BOL_TYPES, generatePayload };
