import React from 'react';
import styles from './PaginationButtons.module.scss';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton } from '@material-ui/core';

export default function Previous({ onClick }) {
  return (
    <IconButton className={styles.page_button} onClick={onClick}>
      <NavigateBeforeIcon />
    </IconButton>
  );
}
