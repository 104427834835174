import React from 'react';
import styles from './InspectionStatisticCharts.module.scss';

import SimplePieChart from 'components/Charts/SimplePieChart/SimplePieChart';

import { getColumnName, inspectionColors, dashboardLabels } from '../../../helpers';

export default function InspectionStatisticCharts({ data }) {
  const { durations, name } = data;

  const charts = durations.map((duration) => ({
    title: getColumnName(duration.dateFrom, duration.dateTo),
    series: [duration.assetInspected, duration.assetNeedToBeInspected, duration.notInspected],
    labels: inspectionColors.map(({ status }) => status),
    isDisplayed: duration.total !== 0
  }));

  return (
    <div className={styles.charts}>
      {charts.map(({ title, series, labels, isDisplayed }) =>
        isDisplayed ? (
          <div key={title} className={styles.charts__block}>
            <SimplePieChart
              hideToolbar
              title={title}
              series={series}
              labels={labels}
              colors={inspectionColors.map(({ color }) => color)}
              fileName={dashboardLabels.inspection + ' ' + name}
            />
          </div>
        ) : null
      )}
    </div>
  );
}
