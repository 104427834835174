import api from '../api';
import {
  CLEAR_BILL_OF_LADING_STATE,
  RESET_BILL_OF_LADING_FILTER,
  SET_BILL_OF_LADING_CREATION_FORM,
  SET_BILL_OF_LADING_FILTER,
  SET_CURRENT_BILL_OF_LADING,
  SET_LOAD_MORE_FLAG
} from 'constants/reduceConstants';
import {
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  isSuccessfulStatus
} from 'helpers/AppHelpers';
import { ERROR_FIND_ASSET, SUCCESS_DELETE_BILL_OF_LADING } from 'constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import {
  BILL_OF_LADING_DETAILS_PATH,
  BILL_OF_LADING_SUMMARY_PATH
} from '../constants/routeConstants';

export const getSingleBillOfLading = (id) => (dispatch) => {
  return api.billOfLading
    .getSingleBillOfLadingApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_BILL_OF_LADING, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(BILL_OF_LADING_SUMMARY_PATH);
    });
};

export const getBillOfLadingList = (query) => (dispatch) => {
  return api.billOfLading
    .getBillOfLadingListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getBillOfLadingLocations = (query) => () =>
  api.billOfLading
    .getBillOfLadingLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getScannedAsset = (id) => () => {
  return api.asset
    .getAssetApi(id)
    .then((res) => res)
    .catch(() => enqueueErrorSnackbar(ERROR_FIND_ASSET));
};

export const getBillOfLadingLooseItems = (query) => () =>
  api.billOfLading
    .getBillOfLadingLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setBillOfLadingListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_BILL_OF_LADING_FILTER, payload: data });
};

export const getBillOfLadingCreationForm = (query) => (dispatch) =>
  api.billOfLading
    .getCreationFormApi(query)
    .then((res) => {
      if (res.status !== 200) return;
      dispatch({ type: SET_BILL_OF_LADING_CREATION_FORM, payload: res.data });
    })
    .catch((err) => console.log(err));

export const getBOLFilterCriteria = () => () =>
  api.billOfLading
    .getFilterCriteriaApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const createBOL = (data) => () => {
  return api.billOfLading.createBOLApi(data).then(
    (res) => {
      browserHistory.push(`${BILL_OF_LADING_DETAILS_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateBOL = (data, options) => (dispatch) => {
  return api.billOfLading.updateBOLApi(data).then(
    (res) => {
      options?.shouldUpdateEntity && dispatch({ type: SET_CURRENT_BILL_OF_LADING, payload: res });
      browserHistory.push(`${BILL_OF_LADING_DETAILS_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const deleteBOL = (id) => () => {
  return api.billOfLading.deleteBOLApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_BILL_OF_LADING);
      browserHistory.push(BILL_OF_LADING_SUMMARY_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const setBillOfLadingFilter = (data) => (dispatch) =>
  dispatch({ type: SET_BILL_OF_LADING_FILTER, payload: data });

export const resetBillOfLadingFilter = (data) => (dispatch) =>
  dispatch({ type: RESET_BILL_OF_LADING_FILTER, payload: data });

export const getBillOfLadingBlank = () => () =>
  api.billOfLading
    .getBillOfLadingBlankApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getBillOfLadingCsv = (query) => () =>
  api.billOfLading
    .getBillOfLadingCsvApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const convertItemsToBOLItems = (data) => () => {
  return api.billOfLading.convertItemsToBOLItemsApi(data).then(
    (res) => {
      return isSuccessfulStatus(res.status) ? res.data : [];
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return [];
    }
  );
};

export const clearBOLState = () => (dispatch) => dispatch({ type: CLEAR_BILL_OF_LADING_STATE });
