import { withStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const AccordionDetails = withStyles(() => ({
  root: {
    background: '#fff',
    padding: 0
  }
}))(MuiAccordionDetails);

export default AccordionDetails;
