import React from 'react';
import styles from './SignatureDetails.module.scss';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import SignatureForm from './SignatureForm';

import { Controller, useFormContext } from 'react-hook-form';

export default function SignatureDetails({ fields, append, remove, onSave, onBlur, onFocus }) {
  const { getValues } = useFormContext();

  const addSignature = () => {
    append({});
    onSave && onSave();
  };

  const isAddAvailable = () => {
    const templateSignaturesCount =
      getValues('generalReportFields').filter(
        ({ generalReportFieldType }) => generalReportFieldType.fieldType === 'Signature'
      ).length || 0;
    const customSignaturesCount = fields.length || 0;
    return templateSignaturesCount + customSignaturesCount < 5;
  };

  const removeSignature = (i) => {
    remove(i);
    onSave && onSave();
  };

  return (
    <section className={styles.signatures}>
      {!!fields?.length && (
        <div className={styles.signatures__items}>
          {fields.map((el, index) => (
            <SignatureForm
              key={el.id}
              index={index}
              onRemove={removeSignature}
              onSave={onSave}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          ))}
        </div>
      )}
      <div className={styles.signatures__add}>
        <Controller
          name="signatures"
          render={() =>
            isAddAvailable() ? (
              <>
                <span>Signature</span>
                <Button className={styles.signatures__add_button} onClick={addSignature}>
                  <AddIcon />
                </Button>
              </>
            ) : null
          }
        />
      </div>
    </section>
  );
}
