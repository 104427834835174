import React, { useState } from 'react';
import styles from './commonComponents.module.scss';
import Button from '@material-ui/core/Button';
import { fileDownload, wait } from 'helpers/AppHelpers';
import { useDispatch } from 'react-redux';
import { getArchiveResource } from 'actions/reportListActions';

export default function DownloadArchiveButton({ data }) {
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);

  const downloadArchive = () => {
    if (!data.length) return;
    const ids = data.filter(({ submitted }) => submitted).map(({ id }) => id);
    dispatch(getArchiveResource({ ids })).then((res) => fileDownload(res));
    setDisabled(true);
    wait(7000).then(() => setDisabled(false));
  };

  return (
    <Button className={styles.download_button} onClick={downloadArchive} disabled={isDisabled}>
      Download all
    </Button>
  );
}
