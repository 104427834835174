import styles from './Layout.module.scss';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export default function Preview(props) {
  const {
    meta: { percent, status, previewUrl }
  } = props;

  return (
    <>
      {status !== 'done' && status !== 'error_file_size' && status !== 'aborted' && (
        <div className={styles.previewWrapper}>
          <img
            className={clsx(styles.previewImage, styles.previewImageLoading)}
            src={previewUrl}
            alt=""
          />
          <CircularProgress
            className={styles.previewImageSpinner}
            variant="determinate"
            value={Math.round(percent)}
          />
        </div>
      )}
    </>
  );
}
