import { ASSETS_LIST_PATH } from 'constants/routeConstants';
import { formatDate } from 'helpers/AppHelpers';

export const dashboardTypes = {
  verification: 'AssetVerification',
  inspection: 'AssetInspection'
};

export const dashboardLabels = {
  verification: 'Asset Verification',
  inspection: 'Asset Inspection'
};

export const TOGGLE_BUTTONS = [
  { id: 1, value: dashboardTypes.verification, label: dashboardLabels.verification },
  { id: 2, value: dashboardTypes.inspection, label: dashboardLabels.inspection }
];

export const defaultTab = dashboardTypes.verification;

export const getColumnName = (dateFrom, dateTo) =>
  formatDate(dateFrom) + ' — ' + formatDate(dateTo);

export const generateCellUrl = (params) => {
  const queryParams = new URLSearchParams(params);

  return `${ASSETS_LIST_PATH}?${queryParams.toString()}`;
};

export const verificationStatusNames = {
  verified: 'assetVerified',
  notVerified: 'assetNotVerified',
  total: 'total'
};
export const verificationTableStatuses = [
  { name: verificationStatusNames.verified, displayName: 'Assets Verified' },
  { name: verificationStatusNames.notVerified, displayName: 'Assets Not Verified' },
  { name: verificationStatusNames.total, displayName: 'Total Assets' }
];
export const verificationColors = [
  { color: 'rgba(126, 195, 126, 1)', status: 'Verified' },
  { color: 'rgba(218, 152, 152, 1)', status: 'Not Verified' }
];

export const inspectionStatusNames = {
  inspected: 'assetInspected',
  needToBeInspected: 'assetNeedToBeInspected',
  notInspected: 'notInspected',
  total: 'total'
};
export const inspectionTableStatuses = [
  { name: inspectionStatusNames.inspected, displayName: 'Inspected' },
  { name: inspectionStatusNames.needToBeInspected, displayName: 'Need To Be Inspected' },
  { name: inspectionStatusNames.notInspected, displayName: 'Not Inspected' },
  { name: inspectionStatusNames.total, displayName: 'Total Assets' }
];
export const inspectionColors = [
  { color: 'rgba(126, 195, 126, 1)', status: 'Inspected' },
  { color: 'rgba(234, 195, 195, 1)', status: 'Need To Be Inspected' },
  { color: 'rgba(229, 229, 229, 1)', status: 'Not Inspected' }
];
