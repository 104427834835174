import {
  ASSET_REPORT_LIST_PATH,
  ASSETS_LIST_PATH,
  BILL_OF_LADING_SUMMARY_PATH,
  LEAVELIST_SUMMARY_PATH,
  LOCATION_REPORT_LIST_PATH,
  LOCATIONS_LIST_PATH,
  LOOSE_ITEMS_LIST_PATH,
  PICKLIST_SUMMARY_PATH,
  PROJECT_LIST_PATH,
  TICKETS_LIST_PATH
} from 'constants/routeConstants';

export const displayOrder = [
  'assets',
  'serviceTickets',
  'assetReports',
  'picklists',
  'leavelists',
  'billOfLadings',
  'locations',
  'looseItems',
  'locationReports',
  'projects'
];

export const NO_MATCHES_MESSAGE = 'No matches are found';
export const TYPE_SOMETHING_MESSAGE = 'Type something to search';

export const areAllArraysEmpty = (obj) => {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      return false;
    }
  }
  return true;
};

export const ROWS_LIMIT = 1;
export const getQuery = (str) => ({
  filters: { searchQuery: str },
  pagination: { limit: ROWS_LIMIT + 1, page: 1 }
});

export const ELLIPSIS_SYMBOL = '...';

export const cellsConfig = {
  assetReports: [
    { id: 'asset-report-key-1', name: 'ID' },
    { id: 'asset-report-key-2', name: 'DR-ID' },
    { id: 'asset-report-key-3', name: 'Location' },
    { id: 'asset-report-key-4', name: 'Created' },
    { id: 'asset-report-key-5', name: 'Submitted By' },
    { id: 'asset-report-key-6', name: 'Report name' },
    { id: 'asset-report-key-7', name: 'Report note' },
    { id: 'asset-report-key-8', name: 'PDF Report' }
  ],
  assets: [
    { id: 'asset-key-1', name: 'DR-ID' },
    { id: 'asset-key-2', name: 'Location' },
    { id: 'asset-key-3', name: 'Sublocation' },
    { id: 'asset-key-4', name: 'Op.' },
    { id: 'asset-key-5', name: 'Rental' },
    { id: 'asset-key-6', name: 'Type' },
    { id: 'asset-key-7', name: 'Short Note' }
  ],
  billOfLadings: [
    { id: 'bol-key-1', name: 'BOL ID' },
    { id: 'bol-key-2', name: 'Freight Bill' },
    { id: 'bol-key-3', name: 'Picklist/Leavelist Name' },
    { id: 'bol-key-4', name: 'Origin Location' },
    { id: 'bol-key-5', name: 'Destination' },
    { id: 'bol-key-6', name: 'Job Number' },
    { id: 'bol-key-7', name: 'Created by' },
    { id: 'bol-key-8', name: 'Created date' }
  ],
  leavelists: [
    { id: 'leavelist-key-1', name: 'Name' },
    { id: 'leavelist-key-2', name: 'Location' },
    { id: 'leavelist-key-3', name: 'Sublocation' },
    { id: 'leavelist-key-4', name: 'Project Number' },
    { id: 'leavelist-key-5', name: 'Status' },
    { id: 'leavelist-key-6', name: 'POC Name' },
    { id: 'leavelist-key-7', name: 'Created by' },
    { id: 'leavelist-key-8', name: 'Creation date' }
  ],
  locationReports: [
    { id: 'location-report-key-1', name: 'ID' },
    { id: 'location-report-key-2', name: 'Location' },
    { id: 'location-report-key-3', name: 'Job Number' },
    { id: 'location-report-key-4', name: 'Project Number' },
    { id: 'location-report-key-5', name: 'Created' },
    { id: 'location-report-key-6', name: 'Submitted By' },
    { id: 'location-report-key-7', name: 'Report name' },
    { id: 'location-report-key-8', name: 'Report note' },
    { id: 'location-report-key-9', name: 'PDF Report' }
  ],
  locations: [
    { id: 'location-key-1', name: 'Site code' },
    { id: 'location-key-2', name: 'Location' },
    { id: 'location-key-3', name: 'Job Number' },
    { id: 'location-key-4', name: 'Description' },
    { id: 'location-key-5', name: 'Type' }
  ],
  looseItems: [
    { id: 'loose-item-key-1', name: 'Loose Items' },
    { id: 'loose-item-key-2', name: 'Location' },
    { id: 'loose-item-key-3', name: 'Sublocation' },
    { id: 'loose-item-key-4', name: 'QTY' },
    { id: 'loose-item-key-5', name: 'BAA' },
    { id: 'loose-item-key-6', name: 'Status' }
  ],
  picklists: [
    { id: 'picklist-key-1', name: 'Name' },
    { id: 'picklist-key-2', name: 'Location' },
    { id: 'picklist-key-3', name: 'Sublocation' },
    { id: 'picklist-key-4', name: 'Project Number' },
    { id: 'picklist-key-5', name: 'Status' },
    { id: 'picklist-key-6', name: 'POC Name' },
    { id: 'picklist-key-7', name: 'Created by' },
    { id: 'picklist-key-8', name: 'Creation date' }
  ],
  projects: [
    { id: 'project-key-1', name: 'Project Name' },
    { id: 'project-key-2', name: 'Project Number' },
    { id: 'project-key-3', name: 'Contract №' },
    { id: 'project-key-4', name: 'Contract Date' },
    { id: 'project-key-5', name: 'Description' }
  ],
  serviceTickets: [
    { id: 'ticket-key-1', name: '#' },
    { id: 'ticket-key-2', name: 'Location' },
    { id: 'ticket-key-3', name: 'Asset' },
    { id: 'ticket-key-4', name: 'Ticket Type' },
    { id: 'ticket-key-5', name: 'Root Cause' },
    { id: 'ticket-key-6', name: 'Severity' },
    { id: 'ticket-key-7', name: 'Description' },
    { id: 'ticket-key-8', name: 'Opened by' },
    { id: 'ticket-key-9', name: 'Opened on' }
  ]
};

export const getTableCellsCount = (tableName) => cellsConfig[tableName].length;

const parameters = {
  assetReports: { title: 'Asset Report', path: ASSET_REPORT_LIST_PATH },
  assets: { title: 'Asset Summary', path: ASSETS_LIST_PATH },
  billOfLadings: { title: 'Bill of Lading Summary', path: BILL_OF_LADING_SUMMARY_PATH },
  leavelists: { title: 'Leavelist Summary', path: LEAVELIST_SUMMARY_PATH },
  locationReports: { title: 'Location Report', path: LOCATION_REPORT_LIST_PATH },
  locations: { title: 'Location Summary', path: LOCATIONS_LIST_PATH },
  looseItems: { title: 'Loose Items List', path: LOOSE_ITEMS_LIST_PATH },
  picklists: { title: 'Picklist Summary', path: PICKLIST_SUMMARY_PATH },
  projects: { title: 'Project Summary', path: PROJECT_LIST_PATH },
  serviceTickets: { title: 'Ticket Summary', path: TICKETS_LIST_PATH }
};

export const getSectionTitle = (tableName) => parameters[tableName].title;
export const getSectionPath = (tableName) => parameters[tableName].path;
