import {
  SET_LOCATION_LIST_FOR_CALENDAR,
  SET_ASSET_PREFIXES_LIST_FOR_CALENDAR,
  SET_PROJECT_LIST_FOR_CALENDAR,
  SET_ASSET_STATUSES_FOR_CALENDAR,
  CLEAR_CALENDAR_STATE,
  SET_USER_CALENDAR_OPTIONS,
  CLEAR_CALENDAR_ASSET_OPTIONS
} from 'constants/reduceConstants';

const initialState = {
  locationList: [],
  prefixList: [],
  assetRentalStatuses: [],
  projectList: [],
  userCalendarOptions: {}
};

const assetCalendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_CALENDAR_OPTIONS:
      return {
        ...state,
        userCalendarOptions: payload
      };
    case SET_LOCATION_LIST_FOR_CALENDAR:
      return {
        ...state,
        locationList: payload
      };
    case SET_ASSET_PREFIXES_LIST_FOR_CALENDAR:
      return {
        ...state,
        prefixList: payload
      };
    case SET_PROJECT_LIST_FOR_CALENDAR:
      return {
        ...state,
        projectList: payload
      };
    case SET_ASSET_STATUSES_FOR_CALENDAR:
      return {
        ...state,
        assetRentalStatuses: payload
      };
    case CLEAR_CALENDAR_ASSET_OPTIONS:
      return {
        ...state,
        assetsFromSearch: []
      };
    case CLEAR_CALENDAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default assetCalendarReducer;
