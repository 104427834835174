import React from 'react';
import styles from './ToggleBars.module.scss';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function TabPanel({ value, onChange, tabs }) {
  const handleChange = (e, v) => v !== value && onChange(v);

  return (
    <div className={styles.tabs}>
      <Tabs
        value={value}
        classes={{ root: styles.tabs__root, indicator: styles.tabs__indicator }}
        onChange={handleChange}>
        {tabs.map(({ id, label }) => (
          <Tab className={styles.tabs__item} key={id} label={label} />
        ))}
      </Tabs>
    </div>
  );
}
