import React, { useEffect, useRef, useState } from 'react';
import styles from './Wrapper.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import {
  CREATE_ASSET_TICKET_TYPE,
  CREATE_TYPE_OF_ASSET_TICKET_TYPE,
  UPDATE_ASSET_TICKET_TYPE,
  UPDATE_TYPE_OF_ASSET_TICKET_TYPE,
  EXIT_WITHOUT_SAVING,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { TICKET_TYPES_PATH } from 'constants/routeConstants';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTicketTypesActions } from 'hooks/TicketTypes';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import { getBackendErrors } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

export default function Wrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { createTicketTypeAction, updateTicketTypeAction } = useTicketTypesActions();

  const breadcrumbs = useRef([{ name: id ? 'Edit Ticket Type' : 'Create Ticket Type' }]);

  const { formState, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const closeModal = () => setModalData({});
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;
  const getBack = () => history.push(TICKET_TYPES_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const handleResponse = (err) => {
    if (!err?.errors) return;

    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case CREATE_TYPE_OF_ASSET_TICKET_TYPE:
        closeModal();
        createTicketTypeAction(getValues()).then(handleResponse);
        break;
      case UPDATE_TYPE_OF_ASSET_TICKET_TYPE:
        closeModal();
        updateTicketTypeAction(getValues()).then(handleResponse);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    setModalData(id ? UPDATE_ASSET_TICKET_TYPE : CREATE_ASSET_TICKET_TYPE);
  };

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('name')) {
      setError('name', getErrorsProperties('Name is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit' : 'Create'} Ticket Type</h1>
          {isMobile && <BackButton onCancel={handleBackClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid}
        />
      </div>
    </section>
  );
}
