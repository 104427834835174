import React from 'react';
import styles from './Buttons.module.scss';

import clsx from 'clsx';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function AddNewButton({ isDisabled, onClick }) {
  const isMobile = useMobileViewport();

  return (
    <Button className={clsx(styles.add_new_button, isDisabled && styles.disabled)} onClick={onClick}>
      <AddIcon />
      {!isMobile && 'Add new'}
    </Button>
  );
}
