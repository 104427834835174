import React, { useEffect, useState } from 'react';
import styles from './DashboardWrapper.module.scss';

import { MainButton } from 'components/StyledComponents';
import AddWidgetPopup from 'components/AddWidgetPopup';

import {
  useTeamMemberDashboardActions,
  useTeamMemberDashboardSelector
} from 'hooks/TeamMemberDashboard';

import { dashboardTypes } from '../../helpers';

export default function DashboardWrapper({ children }) {
  const [openWidget, setOpenWidget] = useState(false);

  const { tab, tabConfig } = useTeamMemberDashboardSelector();
  const {
    getRandRDashboardAction,
    getLevelsDashboardAction,
    getSnapshotDashboardAction,
    getDashboardConfigAction,
    updateTabConfigAction
  } = useTeamMemberDashboardActions();

  const actions = {
    [dashboardTypes.RR]: getRandRDashboardAction,
    [dashboardTypes.LEVELS]: getLevelsDashboardAction,
    [dashboardTypes.SNAPSHOT]: getSnapshotDashboardAction
  };

  useEffect(() => {
    getDashboardConfigAction({ dashboardType: tab }).then((res) => {
      if (res?.items?.length) {
        actions[tab]();
      } else {
        setOpenWidget(true);
      }
    });
  }, []);

  const openWidgetPopup = () => setOpenWidget(true);

  const handleWidgetsUpdate = (items) => {
    updateTabConfigAction({ items }).then(() => {
      actions[tab]();
    });
  };

  return (
    <div className={styles.wrapper}>
      <AddWidgetPopup
        open={openWidget}
        setOpen={setOpenWidget}
        data={tabConfig?.items || []}
        onApply={handleWidgetsUpdate}
        dashboardType={tab}
      />

      <div className={styles.settings}>
        <MainButton text="Config widget" action={openWidgetPopup} type="primary" />
      </div>

      {children}
    </div>
  );
}
