import React, { useEffect, useMemo, useState } from 'react';
import styles from './ConnectedUsers.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';

const DISPLAYED_USER_LIMITS = {
  DESKTOP: 5,
  MOBILE: 3
};

const getInitials = (names) =>
  names.map((name) => {
    return {
      name,
      initials: name
        .split(' ')
        .map((word) => word[0].toUpperCase())
        .join('')
    };
  });

export function ConnectedUsers({ users = [] }) {
  const isMobile = useMobileViewport();
  const [limit, setLimit] = useState(DISPLAYED_USER_LIMITS.DESKTOP);

  useEffect(() => {
    setLimit(isMobile ? DISPLAYED_USER_LIMITS.MOBILE : DISPLAYED_USER_LIMITS.DESKTOP);
  }, [isMobile]);

  const names = useMemo(() => {
    if (!users.length) return [];
    return getInitials(users);
  }, [users]);

  if (!names.length) return null;

  const displayedNames = names.slice(0, limit);
  const remainingUsers = users.length - limit;

  return (
    <div className={styles.users}>
      {displayedNames.map(({ name, initials }, index) => (
        <div key={index} className={styles.users__circle} title={name}>
          {initials}
        </div>
      ))}
      {remainingUsers > 0 && <div className={styles.users__circle}>+{remainingUsers}</div>}
    </div>
  );
}
