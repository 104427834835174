import React from 'react';
import styles from './Details.module.scss';

import { useUserSelector } from 'hooks/UserManagement';

import DetailedRow from 'components/DetailsComponents/DetailedRow';

export default function UserGeneralDetails() {
  const { currentUser } = useUserSelector();
  const { firstName, lastName, userRole, isSelectedAllLocations, locations, email } = currentUser;

  const data = [
    {
      id: 1,
      label: 'E-mail address',
      value: email || ''
    },
    { id: 2, label: 'First Name', value: firstName || '' },
    { id: 3, label: 'Last Name', value: lastName || '' },
    { id: 4, label: 'Role', value: userRole?.role || '' },
    {
      id: 5,
      label: 'Locations',
      value: isSelectedAllLocations
        ? 'All Selected'
        : locations?.map((el) => el.siteCode)?.join(', ') || '',
      isAvailable: !!currentUser.locations?.length
    }
  ];

  return (
    <div className={styles.details}>
      {data.map(
        ({ id, label, value, isAvailable = true }) =>
          isAvailable && <DetailedRow key={id} label={label} value={value} disableFlex />
      )}
    </div>
  );
}
