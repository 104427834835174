import styles from './SummaryPageHeader.module.scss';
import React from 'react';

export default function SummaryPageHeader({ title, children }) {
  return (
    <div className={styles.header}>
      <h1>{title}</h1>
      {children}
    </div>
  );
}
