import { useDispatch } from 'react-redux';
import {
  getSinglePrefix,
  deletePrefix,
  getPrefixTypes,
  getAssetFields,
  clearPrefixState,
  createPrefix,
  updatePrefix,
  getAssetFieldsDeletePermissions
} from 'actions/prefixActions';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import { getCategoryList } from 'actions/categoryActions';
import { setUnsavedFormData } from 'actions/commonActions';

export function usePrefixActions() {
  const dispatch = useDispatch();

  const getPrefixAction = (id) => dispatch(getSinglePrefix(id));
  const setAssetsPrefilterAction = (options) => dispatch(setAssetsPrefilter(options));
  const deletePrefixAction = (id) => dispatch(deletePrefix(id));

  const getCategoryListAction = (q) => dispatch(getCategoryList(q));
  const getPrefixTypesAction = (q) => dispatch(getPrefixTypes(q));
  const getAssetFieldsAction = (q) => dispatch(getAssetFields(q));

  const getCreationFormAction = () => {
    getCategoryListAction();
    getPrefixTypesAction();
    getAssetFieldsAction();
  };

  const createPrefixAction = (data) => dispatch(createPrefix(data));
  const updatePrefixAction = (data) => dispatch(updatePrefix(data));

  const getFieldsDeletePermissionsAction = (prefixId, chipId) =>
    dispatch(getAssetFieldsDeletePermissions(prefixId, chipId));

  const clearStateAction = () => dispatch(clearPrefixState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  return {
    getPrefixAction,
    setAssetsPrefilterAction,
    deletePrefixAction,
    getCreationFormAction,
    createPrefixAction,
    updatePrefixAction,
    getFieldsDeletePermissionsAction,
    setUnsavedFormDataAction,
    getAssetFieldsAction,
    clearStateAction
  };
}
