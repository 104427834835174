import { ASSET_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, getProjectName, pluck } from 'helpers/AppHelpers';
import { useSearchParams } from 'hooks/useSearchParams';
import {
  createChip,
  handleIdsFilter,
  handleNamesFilter,
  handleBooleanFilter
} from 'helpers/ChipGenerator';

const defaultFilter = {
  filters: {
    searchQuery: '',
    locationIds: [],
    siteTypeIds: [],
    sublocationIds: [],
    locationJobNumberIds: [],
    projectIds: [],
    lastLocationIds: [],
    everBeenOnLocationIds: [],
    prefixIds: [],
    rentalIds: [],
    equipmentIds: [],
    severities: [],
    grades: [],
    isNeedsToBeInspected: false,
    isNonOwned: false,
    isDeleted: false,
    dashboardId: '',
    dashboardType: '',
    dashboardDurationId: '',
    dashboardColumnName: ''
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useAssetSummaryParams = () => {
  return useSearchParams([
    'searchQuery',
    'dashboardType',
    'dashboardId',
    'dashboardDurationId',
    'dashboardColumnName',
    'prefixIds',
    'locationIds',
    'rentalIds',
    'severities',
    'isNonOwned',
    'locationJobNumberIds',
    'siteTypeIds',
    'projectIds'
  ]);
};

const generateInitialQuery = ({
  getTableLimit,
  isAllLimitEnabled,
  filter,
  searchQuery,
  dashboardType,
  dashboardId,
  dashboardDurationId,
  dashboardColumnName,
  ...otherParams
}) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(ASSET_LIST_TABLE_PARAMETER);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  if (dashboardType && dashboardId && dashboardDurationId && dashboardColumnName) {
    return setFilters({ dashboardType, dashboardId, dashboardDurationId, dashboardColumnName });
  }

  if (searchQuery) {
    return setFilters({ searchQuery });
  }

  const hasParams = Object.values(otherParams).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== undefined && value !== null && value !== false;
  });
  if (hasParams) {
    const parsedParams = Object.entries(otherParams).reduce((acc, [key, value]) => {
      if (key.endsWith('Ids')) {
        if (Array.isArray(value)) {
          acc[key] = value.map(Number);
        } else if (value !== undefined) {
          acc[key] = [Number(value)];
        }
      } else if (key.startsWith('is')) {
        if (value === 'true') {
          acc[key] = true;
        }
      } else if (key === 'severities') {
        if (Array.isArray(value)) {
          acc[key] = value;
        } else if (value !== undefined) {
          acc[key] = [value];
        }
      }

      return acc;
    }, {});
    return setFilters(parsedParams);
  }

  const isAll = isAllLimitEnabled(ASSET_LIST_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page }
  };
};

const generateChips = (options, filters = {}) => {
  const { equipments, prefixes, sublocations, locations, rentals, projects, siteTypes } = options;

  const {
    locationIds,
    locationJobNumberIds,
    sublocationIds,
    rentalIds,
    prefixIds,
    equipmentIds,
    projectIds,
    lastLocationIds,
    everBeenOnLocationIds,
    siteTypeIds,
    severities,
    grades,
    isNonOwned,
    isDeleted,
    isNeedsToBeInspected,
    dashboardType,
    dashboardId,
    dashboardDurationId,
    dashboardColumnName
  } = filters;

  let newChips = [];

  const idHandleConfigs = [
    { ids: locationIds, options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    {
      ids: locationJobNumberIds,
      options: locations,
      getLabel: (el) => el.locationJobNumber,
      key: 'locationJobNumberIds'
    },
    {
      ids: sublocationIds,
      options: sublocations,
      getLabel: (el) => el.name,
      key: 'sublocationIds'
    },
    { ids: rentalIds, options: rentals, getLabel: (el) => el.name, key: 'rentalIds' },
    { ids: prefixIds, options: prefixes, getLabel: (el) => el.prefix, key: 'prefixIds' },
    { ids: equipmentIds, options: equipments, getLabel: (el) => el.name, key: 'equipmentIds' },
    { ids: projectIds, options: projects, getLabel: (el) => getProjectName(el), key: 'projectIds' },
    {
      ids: lastLocationIds,
      options: locations,
      getLabel: (el) => el.siteCode,
      key: 'lastLocationIds'
    },
    {
      ids: everBeenOnLocationIds,
      options: locations,
      getLabel: (el) => el.siteCode,
      key: 'everBeenOnLocationIds'
    },
    { ids: siteTypeIds, options: siteTypes, getLabel: (el) => el.name, key: 'siteTypeIds' }
  ];

  idHandleConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (severities?.length) {
    newChips = newChips.concat(
      handleNamesFilter(severities, options?.severities || [], 'severities')
    );
  }
  if (filters?.grades?.length) {
    newChips = newChips.concat(handleNamesFilter(grades, options?.grades || [], 'grades'));
  }

  if (isNonOwned) {
    newChips = newChips.concat(handleBooleanFilter('isNonOwned', 'Non-owned'));
  }
  if (isDeleted) {
    newChips = newChips.concat(handleBooleanFilter('isDeleted', 'Deleted'));
  }
  if (isNeedsToBeInspected) {
    newChips = newChips.concat(
      handleBooleanFilter('isNeedsToBeInspected', 'Needs to be inspected')
    );
  }

  if (dashboardId && dashboardType && dashboardDurationId && dashboardColumnName) {
    newChips = newChips.concat([
      createChip(
        'filteredByAssetsDashboards',
        dashboardType === 'AssetVerification'
          ? 'Assets Verification Widget'
          : 'Assets Inspection Widget'
      )
    ]);
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip, criteria) => {
  const { locations } = criteria;

  const newFilters = cloneObj(filters);
  const { key, itemName, itemId } = deletedChip;

  switch (key) {
    case 'isNonOwned':
    case 'isDeleted':
    case 'isNeedsToBeInspected':
      newFilters[key] = false;
      break;
    case 'severities':
    case 'grades':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case 'filteredByAssetsDashboards':
      newFilters.dashboardType = '';
      newFilters.dashboardId = '';
      newFilters.dashboardDurationId = '';
      newFilters.dashboardColumnName = '';
      break;
    case 'locationIds': {
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);

      const sublocations = locations.find(({ id }) => id === itemId)?.sublocations;
      const subIds = pluck(sublocations, 'id');
      if (subIds?.length) {
        newFilters.sublocationIds = newFilters.sublocationIds.filter((id) => !subIds.includes(id));
      }

      break;
    }
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = [
    'searchQuery',
    'locationIds',
    'locationJobNumberIds',
    'sublocationIds',
    'rentalIds',
    'prefixIds',
    'equipmentIds',
    'projectIds',
    'lastLocationIds',
    'everBeenOnLocationIds',
    'siteTypeIds',
    'severities',
    'grades',
    'isNonOwned',
    'isDeleted',
    'isNeedsToBeInspected'
  ];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  if (
    query.filters?.dashboardType &&
    query.filters?.dashboardId &&
    query.filters?.dashboardDurationId &&
    query.filters?.dashboardColumnName
  ) {
    setFilters('dashboardType', query.filters.dashboardType);
    setFilters('dashboardId', query.filters.dashboardId);
    setFilters('dashboardDurationId', query.filters.dashboardDurationId);
    setFilters('dashboardColumnName', query.filters.dashboardColumnName);
  }

  return payload;
};

const getEditPopupOptions = (title) => ({ title });
const getShortNoteOptions = (options) => ({
  inputType: 'manual',
  type: 'string',
  name: 'shortNote',
  max: 255,
  ...options
});
const getAssetFieldOptions = (options) => ({
  inputType: 'manual',
  type: 'quantity',
  name: 'value',
  ...options
});
const getRentalOptions = (options) => ({ inputType: 'select', name: 'rental', ...options });

export {
  defaultFilter,
  useAssetSummaryParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  getEditPopupOptions,
  getShortNoteOptions,
  getAssetFieldOptions,
  getRentalOptions
};
