import React, { useState } from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import styles from './AssetsTable.module.scss';
import { EMPTY_QUANTITY_COLUMN_ERROR_NAME } from 'pages/PicklistAndLeavelist/AddBomToPicklist/helpers';
import clsx from 'clsx';

const AssetsTable = () => {
  const [renderCount, setRenderCount] = useState(1);

  const { control, getValues, setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const { fields } = useFieldArray({
    control,
    name: 'assets'
  });

  const isUniqueItem = (index) =>
    !(index !== 0 && fields[index]?.['assetPrefix']?.id === fields[index - 1]?.['assetPrefix']?.id);

  const getRowSpan = (index) =>
    fields.filter(
      ({ assetPrefix }, i, array) => assetPrefix?.id === array[index]?.['assetPrefix']?.id
    )?.length;

  const checkEmptyAssetColumnError = () =>
    errors?.[EMPTY_QUANTITY_COLUMN_ERROR_NAME] && clearErrors(EMPTY_QUANTITY_COLUMN_ERROR_NAME);

  const handleSelect = (name, value, index) => {
    checkEmptyAssetColumnError(name);
    const rowData = getValues(`assets[${index}]`);

    if (!value) {
      setValue(name, {
        ...rowData,
        drCode: null,
        location: null,
        sublocation: null,
        id: null,
        currentLocationId: null
      });
      setRenderCount(renderCount + 1);
      return;
    }

    const { drCode, location, id, currentLocationId } = value;
    setValue(name, { ...rowData, drCode, location, id, currentLocationId });
    setRenderCount(renderCount + 1);
  };

  const getPrefixAssetsOptions = (row = {}, index) => {
    const rowId = getValues(`assets[${index}]`)?.id;
    const { options } = row;

    if (!options?.length) return [];

    const selectedAssetIds =
      getValues('assets')
        ?.map(({ id }) => id)
        ?.filter(Boolean) || [];

    const excludedAssetIds = selectedAssetIds?.filter((id) => id !== rowId) || [];

    return options.filter(({ id }) => !excludedAssetIds.includes(id));
  };

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Prefix</StyledTableCell>
            <StyledTableCell>Required QTY</StyledTableCell>
            <StyledTableCell
              className={clsx(
                errors?.[EMPTY_QUANTITY_COLUMN_ERROR_NAME]?.message && styles.invalid
              )}>
              Assets
            </StyledTableCell>
            <StyledTableCell>Point of origin (Sublocation)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <StyledTableRow key={index}>
              {isUniqueItem(index) && (
                <StyledTableCell rowSpan={getRowSpan(index)} className={styles.prefixCell}>
                  {row?.assetPrefix?.prefix || ''}
                </StyledTableCell>
              )}
              {isUniqueItem(index) && (
                <StyledTableCell rowSpan={getRowSpan(index)} className={styles.qtyCell}>
                  {row.requiredQuantity}
                </StyledTableCell>
              )}
              <StyledTableCell className={styles.assetsCell}>
                <FormSearchInput
                  optionType="assetPrefixTypeWithLocation"
                  name={`assets[${index}]`}
                  onSelect={handleSelect}
                  options={getPrefixAssetsOptions(row, index)}
                  clearable
                  disableError
                  classes={styles.inputs__select}
                  index={index}
                />
              </StyledTableCell>
              <StyledTableCell className={styles.originCell}>
                <Controller
                  control={control}
                  name={`assets[${index}]`}
                  render={({ field: { value } }) => (
                    <>
                      {value?.location?.siteCode}
                      {value?.sublocation?.name && ` (${value?.sublocation?.name})`}
                    </>
                  )}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetsTable;
