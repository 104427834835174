import React from 'react';
import clsx from 'clsx';
import styles from '../../Dashboard.module.scss';
import { ASSETS_LIST_PATH } from 'constants/routeConstants';
import { StyledTableCell } from 'components/StyledComponents';
import { useHistory } from 'react-router-dom';

const severities = ['Operational', 'NonCritical', 'Critical', 'BeyondRepair', 'AllCounters'];
const severitiesForFilter = ['Operational', 'NonCritical', 'Critical', 'BeyondRepair'];

export default function CellContent({ row, prefix, setAssetsPrefilterAction }) {
  const history = useHistory();

  const getSingleCounter = (prefix, type) => {
    return prefix?.slicedBySeverities?.find(({ severity }) => severity === type);
  };

  const indicatorClickHandle = (locationData, severity) => {
    const preFilter = {
      locationIds: [locationData.locationId],
      prefixIds: [prefix['prefixId']],
      severities: severity === 'AllCounters' ? severitiesForFilter : [severity]
    };
    setAssetsPrefilterAction(preFilter);
    history.push(ASSETS_LIST_PATH);
  };

  return (
    <StyledTableCell scope="row" align="center">
      {severities.map((severity) => (
        <>
          {!!getSingleCounter(prefix, severity) && (
            <span
              onClick={() => indicatorClickHandle(row, severity)}
              className={clsx(styles.tableIndicator, styles[severity])}>
              {getSingleCounter(prefix, severity).count}
            </span>
          )}
        </>
      ))}
    </StyledTableCell>
  );
}
