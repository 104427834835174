import React from 'react';
import styles from './FilterComponents.module.scss';
import { MainButton } from '../StyledComponents';
import ClearFilterButton from '../ClearFilterButton/ClearFilterButton';

export default function FilterActions({ onCancel, onApply, onClear, isApplyDisabled }) {
  const handleCancelClick = () => onCancel();
  const handleClearClick = () => onClear();
  const handleApplyClick = () => onApply();

  return (
    <section className={styles.buttons}>
      <ClearFilterButton onClick={handleClearClick} />
      <div className={styles.primary}>
        <MainButton text="Cancel" action={handleCancelClick} type="secondary" size="filter_popup" />
        <MainButton
          text="Apply"
          action={handleApplyClick}
          type="primary"
          size="filter_popup"
          isDisabled={!!isApplyDisabled}
        />
      </div>
    </section>
  );
}
