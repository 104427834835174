import React from 'react';
import styles from '../../Dashboard.module.scss';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import PieChart from 'assets/images/pieChart.svg';
import CellContent from '../CellContent/CellContent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { ASSETS_LIST_PATH } from 'constants/routeConstants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import {
  getAssetDataForPieChart,
  getLegendDataForPieChart,
  getLocationDataForPieChart,
  getPrefixDataForPieChart
} from '../../helpers';

export default function DashboardTable({
  isMobile,
  tableData,
  i,
  setPieChartData,
  assetCategoryName,
  statuslessDashboardState,
  isTableReverse
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const setPrefilter = (filter) => dispatch(setAssetsPrefilter(filter));

  const getAssets = ({ slicedByLocations }) => {
    let data =
      slicedByLocations
        ?.flatMap(({ slicedByPrefixes }) => slicedByPrefixes)
        .filter(
          (asset, index, array) =>
            array.findIndex((filteredAsset) => filteredAsset.prefixId === asset.prefixId) === index
        ) || [];
    data = data.map((el) => ({
      ...el,
      slicedByLocations: getLocations(tableData)
        .flatMap(({ slicedByPrefixes }) => slicedByPrefixes)
        .filter(({ prefixId }) => prefixId === el.prefixId)
    }));
    return data;
  };

  const getLocations = ({ slicedByLocations }) => {
    let data = slicedByLocations?.filter(({ locationId }) => locationId) || [];
    data = data.filter((el) => !isEmptyLocation(i, el)).filter(Boolean);
    return data;
  };

  const getTotalCountersRow = ({ slicedByLocations }) => {
    const tableCounters = slicedByLocations
      .filter(({ locationId }) => locationId)
      .map(({ slicedByPrefixes }) =>
        slicedByPrefixes.map(({ slicedBySeverities }) => slicedBySeverities)
      );
    const totalCountersRow = tableCounters[0].map(() => [0]);

    tableCounters.forEach((row) => {
      row.forEach((cell, cellIndex) => {
        totalCountersRow[cellIndex] =
          totalCountersRow[cellIndex]?.concat(cell.map((item) => item?.count || 0)) || [];
      });
    });
    return totalCountersRow.map((array) => array.reduce((sum, current) => sum + +current || 0, 0));
  };

  const getReversedTotalCountersRow = () => {
    const tableCounters = getAssets(tableData).map(({ slicedByLocations }) =>
      slicedByLocations.map(({ slicedBySeverities }) => slicedBySeverities)
    );
    const totalCountersRow = tableCounters[0].map(() => [0]);
    tableCounters.forEach((row) => {
      row.forEach((cell, cellIndex) => {
        totalCountersRow[cellIndex] =
          totalCountersRow[cellIndex]?.concat(cell.map((item) => item?.count || 0)) || [];
      });
    });
    return totalCountersRow.map((array) => array.reduce((sum, current) => sum + +current || 0, 0));
  };

  const isEmptyLocation = (index, row) => {
    if (statuslessDashboardState?.length) {
      return !statuslessDashboardState[index]?.slicedByLocations
        ?.find(({ locationId }) => locationId === row.locationId)
        .slicedByPrefixes.map(({ slicedBySeverities }) => slicedBySeverities)
        .filter((el) => el.length).length;
    } else {
      return true;
    }
  };

  const pieChartOpenHandle = (row) => {
    if (statuslessDashboardState?.length) {
      setPieChartData({
        open: true,
        title: assetCategoryName + ' in ' + row.locationName,
        data: [...getLocationDataForPieChart(row)],
        legendData: [...getLegendDataForPieChart(row)]
      });
    }
  };

  const assetPieChartOpenHandle = (index, row) => {
    if (statuslessDashboardState?.length) {
      setPieChartData({
        open: true,
        title: row.prefixName,
        data: [...getAssetDataForPieChart(row)]
      });
    }
  };

  const prefixPieChartOpenHandle = (tableData, prefixIndex) => {
    setPieChartData({
      open: true,
      title: shouldTableReverse()
        ? assetCategoryName + ' in ' + getLocations(tableData)[prefixIndex].locationName
        : tableData.slicedByLocations[0].slicedByPrefixes[prefixIndex].prefixName,
      data: shouldTableReverse()
        ? [...getLocationDataForPieChart(getLocations(tableData)[prefixIndex])]
        : [...getPrefixDataForPieChart(tableData, prefixIndex)],
      legendData: shouldTableReverse()
        ? [...getLegendDataForPieChart(getLocations(tableData)[prefixIndex])]
        : []
    });
  };

  const headerPrefixClickHandle = (prefixId) => {
    setPrefilter({ prefixIds: [prefixId] });
    history.push(ASSETS_LIST_PATH);
  };

  const locationClickHandle = (assetCategory, locationData) => {
    const preFilter = {
      locationIds: [locationData.locationId],
      equipmentIds: [assetCategory.assetCategoryId]
    };
    setPrefilter(preFilter);
    history.push(ASSETS_LIST_PATH);
  };

  const shouldTableReverse = () => isTableReverse;

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}>
              {'\u2000'}
            </StyledTableCell>
            {shouldTableReverse() ? (
              <>
                {!!getLocations(tableData).length &&
                  getLocations(tableData).map((row, headerIndex) => (
                    <StyledTableCell key={headerIndex} align="center">
                      <span onClick={() => locationClickHandle(tableData, row)}>
                        {row['locationName']}
                      </span>
                    </StyledTableCell>
                  ))}
              </>
            ) : (
              <>
                {!!getAssets(tableData).length &&
                  getAssets(tableData).map(({ prefixId, prefixName }, headerIndex) => (
                    <StyledTableCell key={headerIndex} align="center">
                      <span onClick={() => headerPrefixClickHandle(prefixId)}>{prefixName}</span>
                    </StyledTableCell>
                  ))}
              </>
            )}
          </TableRow>
        </TableHead>

        {shouldTableReverse() ? (
          <TableBody>
            {getAssets(tableData)?.length ? (
              <>
                {getAssets(tableData).map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell
                      className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                      scope="row">
                      <div>
                        <span onClick={() => headerPrefixClickHandle(row.prefixId)}>
                          {row.prefixName}
                        </span>
                        <img
                          src={PieChart}
                          alt="marker"
                          onClick={() => assetPieChartOpenHandle(i, row)}
                        />
                      </div>
                    </StyledTableCell>
                    {row?.slicedByLocations?.map((prefix, i) => (
                      <CellContent
                        key={i}
                        row={row}
                        prefix={prefix}
                        setAssetsPrefilterAction={setPrefilter}
                      />
                    ))}
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell
                    className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                    scope="row">
                    <b>TOTALS</b>
                  </StyledTableCell>
                  {getReversedTotalCountersRow(tableData).map((cell, indx) => (
                    <StyledTableCell
                      key={indx}
                      className={styles.totalCell}
                      scope="row"
                      align="center">
                      <div>
                        <b>{cell}</b>
                        <img
                          src={PieChart}
                          alt="marker"
                          onClick={() => prefixPieChartOpenHandle(tableData, indx)}
                        />
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </>
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} className={styles.emptyResponseTable}>
                  No matches in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            {getLocations(tableData)?.length ? (
              <>
                {getLocations(tableData).map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell
                      className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                      scope="row">
                      <div>
                        <span onClick={() => locationClickHandle(tableData, row)}>
                          {row.locationName}
                        </span>
                        <img src={PieChart} alt="marker" onClick={() => pieChartOpenHandle(row)} />
                      </div>
                    </StyledTableCell>
                    {row?.slicedByPrefixes?.map((prefix, i) => (
                      <CellContent
                        key={i}
                        row={row}
                        prefix={prefix}
                        setAssetsPrefilterAction={setPrefilter}
                      />
                    ))}
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell
                    className={clsx(styles.firstRow, !isMobile && styles.stickyRow)}
                    scope="row">
                    <b>TOTALS</b>
                  </StyledTableCell>
                  {getTotalCountersRow(tableData).map((cell, indx) => (
                    <StyledTableCell
                      key={indx}
                      className={styles.totalCell}
                      scope="row"
                      align="center">
                      <div>
                        <b>{cell}</b>
                        <img
                          src={PieChart}
                          alt="marker"
                          onClick={() => prefixPieChartOpenHandle(tableData, indx)}
                        />
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </>
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} className={styles.emptyResponseTable}>
                  No matches in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
