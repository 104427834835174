import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getUserLogsApi = (query) =>
  api.get(`/HistoryEvents?${queryToString(query)}`).then((res) => res.data);

export const getHistoryEventTypesApi = () => api.get('/HistoryEventTypes').then((res) => res.data);

export const getUsersApi = (query) =>
  api.get(`/UsersForAssign?${queryToString(query)}`).then((res) => res.data);
