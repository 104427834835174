import React from 'react';
import styles from './Content.module.scss';

import AssetsTable from './AssetsTable';
import ClosedTicketsTable from './TicketTables/ClosedTicketsTable';
import OpenedTicketsTable from './TicketTables/OpenedTicketsTable';
import LooseItemsTable from './LooseItemsTable';

import { useLocationActions } from '../../../../hooks/useLocationActions';
import { useLocationSelector } from '../../../../hooks/useLocationSelector';

import ToggleBar from 'components/ToggleBars/ToggleBar';

const TOGGLE_BUTTONS = [
  { id: 1, value: 'locationAssets', label: 'Assets at the location' },
  { id: 2, value: 'closedTickets', label: 'Closed Ticket(s)' },
  { id: 3, value: 'openTickets', label: 'Opened Ticket(s)' },
  { id: 4, value: 'looseItems', label: 'Loose Items' }
];

export default function Content() {
  const { setLocationDetailsTabAction } = useLocationActions();
  const { selectedTab } = useLocationSelector();

  const changeTab = (tab) => setLocationDetailsTabAction(tab);

  return (
    <div className={styles.wrapper}>
      <ToggleBar selectedTab={selectedTab} buttons={TOGGLE_BUTTONS} onChange={changeTab} />

      {selectedTab === 'locationAssets' && <AssetsTable />}

      {selectedTab === 'closedTickets' && <ClosedTicketsTable />}

      {selectedTab === 'openTickets' && <OpenedTicketsTable />}

      {selectedTab === 'looseItems' && <LooseItemsTable />}
    </div>
  );
}
