import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import L from 'leaflet';
import { Marker, useMap } from 'react-leaflet';
import 'leaflet-routing-machine';

import redMarkerIcon from 'assets/images/markers/redmarker.svg';
import blueMarkerIcon from 'assets/images/markers/bluemarker.svg';
import greenMarkerIcon from 'assets/images/markers/greenmarker.svg';

const useStyles = makeStyles(() => ({
  markerNumber: {
    position: 'relative',
    top: '-32px',
    fontSize: '11px',
    width: '26px',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold'
  },
  routesContainer: {
    display: 'none'
  },
  pointMarker: {
    display: 'none'
  }
}));

L.NumberedDivIcon = L.Icon.extend({
  options: {
    iconUrl: '',
    number: '',
    iconAnchor: [13, 26],
    iconSize: [13, 26],
    numberClass: ''
  },

  createIcon: function () {
    const div = document.createElement('div');
    const img = this._createImg(this.options['iconUrl']);
    const numdiv = document.createElement('div');
    numdiv.setAttribute('class', this.options['numberClass']);
    numdiv.innerHTML = this.options['number'] || '';
    div.appendChild(img);
    div.appendChild(numdiv);
    this._setIconStyles(div, 'icon');
    return div;
  }
});

L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'redMarkerIcon',
  iconSize: [0, 0],
  iconAnchor: [0, 0],
  popupAnchor: [0, 0]
});

export function Routing({ destination, selectedLocation }) {
  const map = useMap();
  const classes = useStyles();

  useEffect(() => {
    if (!map || !selectedLocation?.lat || !selectedLocation?.lng) return;
    const routingControl = L.Routing.control({
      containerClassName: classes.routesContainer,
      waypoints: [
        L.latLng(destination.latitude, destination.longitude),
        L.latLng(selectedLocation.lat, selectedLocation.lng)
      ],
      fitSelectedRoutes: false,
      units: 'imperial'
    }).addTo(map);

    return () => map.removeControl(routingControl);
  }, [map, selectedLocation]);

  return null;
}

export default function AdvancedFilterMarkers({ inputData }) {
  const map = useMap();
  const classes = useStyles();
  const { location, nearestLocations } = inputData || {};
  const [selectedLocation, setSelectedLocation] = useState({});

  const markerBounds = [
    { lat: location.latitude, lng: location.longitude },
    ...nearestLocations.map(({ location: { latitude, longitude } }) => {
      return { lat: latitude, lng: longitude };
    })
  ];

  useEffect(() => {
    const { latitude, longitude } = nearestLocations[0]?.location || {};
    if (map && latitude && longitude) {
      map.fitBounds(markerBounds);
      setSelectedLocation({ lat: latitude || '', lng: longitude || '' });
    }
  }, [nearestLocations]);

  return (
    <>
      <Marker
        position={[location.latitude, location.longitude]}
        icon={new L.NumberedDivIcon({ iconUrl: greenMarkerIcon })}></Marker>
      {nearestLocations?.length &&
        nearestLocations.map((point, index) => (
          <Marker
            key={index}
            position={[point?.location?.latitude, point?.location?.longitude]}
            eventHandlers={{
              click: (e) => setSelectedLocation(e.latlng)
            }}
            icon={
              point?.location?.siteType?.name === 'Job Site'
                ? new L.NumberedDivIcon({
                    iconUrl: redMarkerIcon,
                    numberClass: classes.markerNumber,
                    number: index + 1
                  })
                : new L.NumberedDivIcon({
                    iconUrl: blueMarkerIcon,
                    numberClass: classes.markerNumber,
                    number: index + 1
                  })
            }></Marker>
        ))}
      <Routing destination={location} selectedLocation={selectedLocation} />
    </>
  );
}
