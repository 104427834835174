import React, { useState } from 'react';
import { StyledTableCell } from 'components/StyledComponents';
import styles from './RequestTableBody.module.scss';
import { allKeyboardLatSymbols, reformatInputValue } from 'helpers/AppHelpers';
import { Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';

function ETACell({ etaValue, rowData, onApply, isCanBeEditable }) {
  const [values, setValues] = useState({});

  const handleOpen = () => {
    if (!isCanBeEditable) return;
    setValues({ open: true, value: etaValue });
  };

  const handleClick = (param) => {
    if (param === 'accept') {
      onApply({ ...rowData, eta: values.value }, 'single');
    }
    setValues({});
  };

  const onInput = (event) => {
    const inputValue = reformatInputValue(event.target.value, 20, allKeyboardLatSymbols);
    setValues({ ...values, value: inputValue });
  };

  return (
    <StyledTableCell className={styles.etaCell}>
      {!values.open ? (
        <div
          className={clsx(styles.commonBlock, styles.etaBlock, isCanBeEditable && styles.pointer)}
          onClick={handleOpen}>
          {etaValue}
        </div>
      ) : (
        <section className={styles.etaBlock}>
          <TextField
            name="notes"
            className={styles.textInput}
            variant="outlined"
            value={values?.value || ''}
            onChange={onInput}
          />
          <div className={styles.helpers}>
            <Button onClick={handleClick} className={styles.alert}>
              <CloseIcon />
            </Button>
            <Button onClick={() => handleClick('accept')} className={styles.success}>
              <CheckIcon />
            </Button>
          </div>
        </section>
      )}
    </StyledTableCell>
  );
}

export default ETACell;
