import React, { useEffect } from 'react';

import CreateAssetsBatchWrapper from './components/Wrapper';
import CreateAssetsBatchForm from './components/Form';

import { FormProvider, useForm } from 'react-hook-form';
import {
  useCreateAssetsBatchActions,
  useCreateAssetsBatchSelector
} from 'hooks/CreateBatchOfAssets';

export default function BatchOfAssetsCreationForm() {
  const { formState } = useCreateAssetsBatchSelector();
  const { getCreationFormAction } = useCreateAssetsBatchActions();

  const methods = useForm({
    defaultValues: formState,
    mode: 'onChange'
  });

  useEffect(() => {
    getCreationFormAction();
  }, []);

  return (
    <FormProvider {...methods}>
      <CreateAssetsBatchWrapper>
        <CreateAssetsBatchForm />
      </CreateAssetsBatchWrapper>
    </FormProvider>
  );
}
