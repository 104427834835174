import {
  SET_ASSETS_DASHBOARDS_TAB,
  SET_ASSETS_DASHBOARDS_TAB_CONFIG,
  SET_ASSETS_DASHBOARDS_VERIFICATION_STATISTIC,
  SET_ASSETS_DASHBOARDS_FILTER_CRITERIA,
  SET_ASSETS_DASHBOARDS_INSPECTION_STATISTIC,
  CLEAR_ASSETS_DASHBOARDS_STATE,
  CLEAR_ASSETS_DASHBOARDS_TAB_STATE
} from 'constants/reduceConstants';

const initialState = {
  tab: '',
  tabConfig: {},
  filterCriteria: {},
  verificationStatistic: [],
  inspectionStatistic: []
};

const assetsDashboardsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ASSETS_DASHBOARDS_TAB:
      return { ...state, tab: payload };
    case SET_ASSETS_DASHBOARDS_TAB_CONFIG:
      return { ...state, tabConfig: payload };
    case SET_ASSETS_DASHBOARDS_VERIFICATION_STATISTIC:
      return { ...state, verificationStatistic: payload };
    case SET_ASSETS_DASHBOARDS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_ASSETS_DASHBOARDS_INSPECTION_STATISTIC:
      return { ...state, inspectionStatistic: payload };
    case CLEAR_ASSETS_DASHBOARDS_TAB_STATE:
      return { ...state, [payload.stateName]: [] };
    case CLEAR_ASSETS_DASHBOARDS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default assetsDashboardsReducer;
