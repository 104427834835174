import React from 'react';
import styles from './Controls.module.scss';

import { SidebarButton } from 'components/Buttons';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as QRReadIcon } from 'assets/images/qrRead.svg';

import projectData from '../../../../package.json';

export function Controls({ onQrClick, onLogout }) {
  const isMobile = useMobileViewport();

  return (
    <div className={styles.controls}>
      <SidebarButton label="QR for page" onClick={onQrClick}>
        <QRReadIcon />
      </SidebarButton>

      <SidebarButton label="Logout" onClick={onLogout}>
        <LogoutIcon />
      </SidebarButton>

      {open && isMobile && (
        <div className={styles.version}>
          <span className={styles.version__label}>Version {projectData.version}</span>
        </div>
      )}
    </div>
  );
}
