import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getDashboardConfigApi = (query) =>
  api.get(`/User/AssetInspectionDashboardsConfig?${queryToString(query)}`).then((res) => res.data);

export const updateTabConfigApi = (data) =>
  api.put('/User/AssetInspectionDashboardsConfig', data).then((res) => res);

export const updateSectionConfigApi = (data) =>
  api.put('/User/AssetInspectionDashboardConfig', data).then((res) => res);

export const getFilterCriteriaApi = () =>
  api.get('/AssetInspectionDashboards/FilterCriteria').then((res) => res.data);

export const getAssetOptionsApi = (query) =>
  api.get(`/Asset/Search?${queryToString(query)}`).then((res) => res.data);

export const getVerificationStatisticApi = () =>
  api.get('/Asset/VerificationDashboards').then((res) => res.data);

export const getInspectionStatisticApi = () =>
  api.get('/Asset/InspectionDashboards').then((res) => res.data);

export const getAssetsDashboardsCsvResourceApi = (q) =>
  api
    .get(`/AssetDashboards/SerializedAssetInspectionDashboard?${queryToString(q)}`)
    .then((res) => res.data);
