import React from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormInputText_v2 from 'components/FormComponents/FormInputText/FormInputText_v2';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { selectConfigurationCreationFormData } from '../../selectors';

import { pluck, removeDuplicates } from '../../../../../helpers/AppHelpers';

import { Add, Cancel } from '@material-ui/icons';

import styles from './AssetPrefixesTable.module.scss';
import ErrorBlock from 'components/ErrorBlock';

const AssetPrefixesTable = () => {
  const { assetPrefixes } = useSelector(selectConfigurationCreationFormData());

  const { control, formState, clearErrors, setValue, getValues } = useFormContext();
  const { errors } = formState;
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'assetPrefixes'
  });

  function getFilteredAssetPrefixes(index) {
    if (!assetPrefixes?.length) return [];

    const rowLooseItem = getValues('assetPrefixes')[index];
    const rowItemId = rowLooseItem?.id;
    const excludedItemIds = removeDuplicates(
      pluck(getValues('assetPrefixes'), 'id').filter(Boolean)
    );
    const excludedItemsWithoutRowItemId = excludedItemIds.filter((id) => id !== rowItemId);

    return assetPrefixes.filter(({ id }) => !excludedItemsWithoutRowItemId.includes(id));
  }

  const handleSelect = (name, value) => {
    !!errors?.['empty_tables']?.message && clearErrors('empty_tables');
    setValue(name, { quantity: getValues(name)['quantity'], ...value });
  };

  const onRemoveRow = (index) => {
    clearErrors(`assetPrefixes[${index}].quantity`);

    if (fields.length < 1) return;

    if (fields?.length === 1) {
      update(index, { quantity: 1 });
    } else {
      remove(index);
    }
  };

  const onAddRow = () => append({ quantity: 1 });

  return (
    <div className={styles.wrapper}>
      <TableContainer component={Paper} className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Prefix</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!fields?.length &&
              fields.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <FormSearchInput
                      name={`assetPrefixes[${index}]`}
                      options={getFilteredAssetPrefixes(index)}
                      onSelect={handleSelect}
                      classes={clsx(styles.select_input_style)}
                      clearable
                      disableError
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      errors?.assetPrefixes?.[index]?.quantity?.message && styles.redBackground
                    )}>
                    <FormInputText_v2
                      name={`assetPrefixes[${index}].quantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true
                      }}
                      classes={clsx(styles.quantity_input_style)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Controller
                      name={`assetPrefixes[${index}]`}
                      control={control}
                      render={({ field: { value } }) =>
                        fields.length > 1 || value?.id ? (
                          <IconButton onClick={() => onRemoveRow(index)}>
                            <Cancel />
                          </IconButton>
                        ) : null
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!errors?.['empty_tables']?.message && (
        <ErrorBlock errorText={errors['empty_tables'].message} />
      )}
      <div className={styles.footer}>
        <Button className={styles.add_button} onClick={onAddRow}>
          <Add />
          <span>Add</span>
        </Button>
      </div>
    </div>
  );
};

export default AssetPrefixesTable;
