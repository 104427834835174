import React from 'react';
import styles from './EditorForm.module.scss';
import EditorFormRow from './EditorFormRow';
import AddingStatusField from './AddingStatusField/AddingStatusField';

function EditorForm({ statuses }) {
  const isStatusNameExist = (statusName, id) => {
    const needStatus = statuses.find(({ name }) => name.trimEnd().toLowerCase() === statusName.trimEnd().toLowerCase());
    return !!(needStatus?.id && needStatus?.id !== id);
  };

  return (
    <section className={styles.formWrapper}>
      {statuses.map((el) => (
        <EditorFormRow key={el.id} status={el} isStatusNameExist={isStatusNameExist} />
      ))}
      <AddingStatusField isStatusNameExist={isStatusNameExist} />
    </section>
  );
}

export default EditorForm;
