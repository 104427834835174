export const initPieData = [
  { name: 'Operational', displayName: 'Operational', count: 0 },
  { name: 'NonCritical', displayName: 'Non-Critical', count: 0 },
  { name: 'Critical', displayName: 'Critical', count: 0 },
  { name: 'BeyondRepair', displayName: 'Beyond repair', count: 0 },
  { name: 'AllCounters', displayName: 'All Counters', count: 0 }
];

export const getFixedCounts = (data, index) => {
  if (data.length) {
    const initData = initPieData.map((item) => ({ ...item }));
    const mappedData =
      data[index]?.slicedByLocations
        ?.flatMap(({ slicedByPrefixes }) => slicedByPrefixes)
        .flatMap(({ slicedBySeverities }) => slicedBySeverities) || [];
    mappedData.forEach(({ severity, count }) => {
      const counterIndex = initData.findIndex(({ name }) => name === severity);
      initData[counterIndex].count += count;
    });
    return initData;
  }
};

export const getAllSeveritiesCounts = (data) => {
  const initData = initPieData.map((item) => ({ ...item }));
  const mappedData =
    data?.slicedByLocations
      ?.flatMap(({ slicedByPrefixes }) => slicedByPrefixes)
      .flatMap(({ slicedBySeverities }) => slicedBySeverities) || [];
  mappedData.forEach(({ severity, count }) => {
    const counterIndex = initData.findIndex(({ name }) => name === severity);
    initData[counterIndex].count += count;
  });
  return initData;
};

export const getLocationDataForPieChart = (row) => {
  const initData = initPieData.map((item) => ({ ...item }));
  const mappedData =
    row?.slicedByPrefixes.flatMap(({ slicedBySeverities }) => slicedBySeverities) || [];
  mappedData.forEach(({ severity, count }) => {
    const counterIndex = initData.findIndex(({ name }) => name === severity);
    initData[counterIndex].count += count;
  });
  return initData;
};

export const getAssetDataForPieChart = (row) => {
  const initData = initPieData.map((item) => ({ ...item }));
  const mappedData =
    row?.slicedByLocations.flatMap(({ slicedBySeverities }) => slicedBySeverities) || [];
  mappedData.forEach(({ severity, count }) => {
    const counterIndex = initData.findIndex(({ name }) => name === severity);
    initData[counterIndex].count += count;
  });
  return initData;
};

export const getLegendDataForPieChart = (row) => {
  return row?.slicedByPrefixes
    .filter(({ slicedBySeverities }) => slicedBySeverities.length)
    .map((el) => {
      const total = el.slicedBySeverities
        .map(({ count }) => count)
        .reduce((sum, current) => sum + current, 0);
      return { ...el, total };
    });
};

export const getPrefixDataForPieChart = (tableData, prefixIndex) => {
  const initData = initPieData.map((item) => ({ ...item }));
  const prefixes = tableData.slicedByLocations
    .flatMap((row) => row.slicedByPrefixes[prefixIndex])
    .filter(({ slicedBySeverities }) => slicedBySeverities.length)
    .flatMap(({ slicedBySeverities }) => slicedBySeverities);
  prefixes.forEach(({ severity, count }) => {
    const counterIndex = initData.findIndex(({ name }) => name === severity);
    initData[counterIndex].count += count;
  });
  return initData;
};
