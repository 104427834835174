import React from 'react';
import styles from './components.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import AccordionWrapper from '../../commonComponents/AccordionWrapper';

import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { selectBoLCreationFormData } from '../../selectors';

import { getAddress, removeDuplicates } from 'helpers/AppHelpers';

import { DRAT_COMPANY_NAME, originFieldNames, OTHER_LOCATION_ID } from '../helpers';
import { locationFormConfig } from './formConfigs';

function OriginLocationForm() {
  const { control, formState, getValues, setValue, clearErrors } = useFormContext();
  const { errors } = formState;

  const creationForm = useSelector(selectBoLCreationFormData());
  const { originLocations } = creationForm;

  const isFormInvalid =
    errors?.originCompanyName?.message ||
    errors?.originAddress?.message ||
    errors?.originCity?.message ||
    errors?.originZIP?.message ||
    errors?.originState?.message;

  const selectLocation = (name, value) => {
    if (!value) return;

    setValue(name, value);

    const { siteCode, address1, address2, city, zip, state } = value;

    setValue('originLocation', siteCode === 'Other' ? '' : siteCode || '');
    setValue('originAddress', getAddress(address1, address2));
    setValue('originAddress2', '');
    setValue('originCity', city || '');
    setValue('originZIP', zip || '');
    setValue('originState', state?.name || '');
    setValue('originCompanyName', siteCode === 'Other' ? '' : DRAT_COMPANY_NAME);
    setValue('originPointOfContact', '');
    setValue('originPOCPhone', '');

    originFieldNames.forEach((name) => clearErrors(name));
  };

  const getLocations = () => {
    const isCreationViaPicklist = !!getValues('picklist')?.id;

    if (!isCreationViaPicklist) return originLocations || [];

    if (!originLocations?.length) return [];

    const selectedLocationIds = removeDuplicates(
      getValues('billOfLadingItems')
        .map(({ originLocationId }) => originLocationId)
        .filter(Boolean)
    );
    selectedLocationIds.push(OTHER_LOCATION_ID);
    return originLocations.filter(({ id }) => selectedLocationIds.includes(id));
  };

  return (
    <AccordionWrapper title="Origin Location" isFormInvalid={isFormInvalid}>
      <div className={styles.location}>
        <div className={styles.location__block}>
          <p className={styles.location__label}>Origin</p>
          <div className={styles.location__block_row}>
            <label>Origin Location</label>
            <FormSearchInput
              name="originLocationItem"
              options={getLocations()}
              onSelect={selectLocation}
            />
          </div>
          <Controller
            control={control}
            name="originLocationItem"
            render={({ field: { value } }) =>
              value?.siteCode === 'Other' ? (
                <div className={styles.location__block_row}>
                  <label>Custom Location</label>
                  <FormInputText name="originLocation" options={{ max: 500 }} />
                </div>
              ) : null
            }
          />
          {locationFormConfig.map((row) => (
            <div key={row.id} className={styles.location__block_row}>
              <label>{row.label}</label>
              <FormInputText name={row.param} options={{ max: row.maxLength, rule: row.rule }} />
            </div>
          ))}
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default OriginLocationForm;
