import { useDispatch } from 'react-redux';

import { setUnsavedFormData } from 'actions/commonActions';
import {
  clearRequestOrdersState,
  createRequest,
  deleteRequestOrder,
  getCreationFormData,
  getRequestOrderList,
  setRequestOrderFilter,
  getFilterCriteria
} from 'actions/requestOrderActions';

export function useRequestOrderActions() {
  const dispatch = useDispatch();

  const getRequestOrdersAction = (q) => dispatch(getRequestOrderList(q));

  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());
  const setFilterAction = (filter) => dispatch(setRequestOrderFilter(filter));

  const deleteRequestOrderAction = (id) => dispatch(deleteRequestOrder(id));

  const getCreationFormDataAction = (q) => dispatch(getCreationFormData(q));

  const createRequestAction = (data) => dispatch(createRequest(data));

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const clearStateAction = () => dispatch(clearRequestOrdersState());

  return {
    getRequestOrdersAction,
    setFilterAction,
    getFilterCriteriaAction,
    deleteRequestOrderAction,
    getCreationFormDataAction,
    createRequestAction,
    setUnsavedFormDataAction,
    clearStateAction
  };
}
