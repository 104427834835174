import React from 'react';
import styles from './RequestOrderTableRow.module.scss';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { USER_DETAILED_PATH } from 'constants/routeConstants';

import { getUserFullName, joinWithComma, pluck } from 'helpers/AppHelpers';
import { NON_DRAT_USER_NAME } from '../../../helpers';

import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

export default function RequestOrderTableRow({ row, onEdit, onRemove }) {
  return (
    <StyledTableRow className={styles.tableRow}>
      <StyledTableCell className={styles.nameCell}>
        {row?.firstName ? (
          <LinkComponent path={`${USER_DETAILED_PATH}/${row?.id}`} name={getUserFullName(row)} />
        ) : (
          NON_DRAT_USER_NAME
        )}
      </StyledTableCell>
      <StyledTableCell className={styles.emailCell}>{row?.email || ''}</StyledTableCell>
      <StyledTableCell className={styles.userRoleCell}>{row?.userRole?.role || ''}</StyledTableCell>
      <StyledTableCell className={styles.requestOrderRoleCell}>
        {row?.requestOrderRole?.displayName || ''}
      </StyledTableCell>
      <StyledTableCell className={styles.locationCell}>
        {row?.isSelectedAllLocations
          ? 'All Selected'
          : joinWithComma(pluck(row?.locations || [], 'siteCode'))}
      </StyledTableCell>
      <StyledTableCell className={styles.editCell}>
        <div className={styles.controls}>
          {row?.firstName && (
            <IconButton className={styles.icon_button} onClick={() => onEdit(row)}>
              <CreateIcon />
            </IconButton>
          )}
          <IconButton className={styles.icon_button} onClick={() => onRemove(row.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}
