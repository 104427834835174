import React from 'react';
import styles from './Cells.module.scss';

import { Indicator } from 'components/Counting';

import { getSeverityColor } from 'helpers/AppHelpers';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export function AssetStatusCell({ asset }) {
  const { severity, currentInspectionHistory } = asset;

  const showIcon = currentInspectionHistory?.inspectionStatus?.name === 'NeedToBeInspected';

  return (
    <div className={styles.assetStatus}>
      <div className={styles.assetStatus__indicator}>
        <Indicator color={getSeverityColor(severity.name)} />
        {showIcon && <InfoOutlinedIcon />}
      </div>
    </div>
  );
}
