import { onlyNumbersRegexp, allKeyboardLatSymbols } from 'helpers/AppHelpers';

// All IDs for dynamic inputs are pre-installed on backend:
export const LOCATION_REPORT_VALIDATION_RULES = [
  {
    id: 1,
    name: 'Weather Conditions',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 255
  },
  {
    id: 2,
    name: 'DEPLOYED RESOURCES STAFF ON SITE',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 3,
    name: 'SUB CONTRACTORS PRESENT ON SITE',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 4,
    name: 'MATERIALS DELIVERED/REMOVED - diesil. fuiel, etc.',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 5,
    name: 'MATERIALS DELIVERED/REMOVED - Meals Served',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 255
  },
  {
    id: 6,
    name: 'DAILY ACTIVITIES & ADDITIONAL NOTES',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 7,
    name: 'DR ASSETS & EQUIPMENT ON SITE',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 8,
    name: 'DR-ID CODES',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 9,
    name: 'RENTAL ASSETS & EQUIPMENT ON SITE',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 10,
    name: 'ISO COMPLIANCE - Corrective Action Noted',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 11,
    name: 'ISO COMPLIANCE - Corrective Action Taken',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 12,
    name: 'SAFETY MEETING - Topic Discussed',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },
  {
    id: 13,
    name: 'SAFETY MEETING - Personnel in Attendance',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 65000
  },

  //--------------------- FOOD SERVICE ---------------------//
  {
    id: 14,
    name: 'FOOD SERVICE - BREAKFAST',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 15,
    name: 'FOOD SERVICE - LUNCH',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 16,
    name: 'FOOD SERVICE - DINNER',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 17,
    name: 'FOOD SERVICE - OVERNIGHT',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 18,
    name: 'FOOD SERVICE - BAGGED MEALS',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 19,
    name: 'FOOD SERVICE - SNACKS',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 20,
    name: 'FOOD SERVICE - MEALS ORDERED',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 21,
    name: 'FOOD SERVICE - MEALS PRODUCED',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 22,
    name: 'FOOD SERVICE - MEALS SERVED',
    regexp: allKeyboardLatSymbols,
    maxSymbols: 255
  },

  //--------------------- CAMP CAPACITY ---------------------//
  {
    id: 23,
    name: 'CAMP CAPACITY & OCCUPANCY - CAPACITY',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 24,
    name: 'CAMP CAPACITY & OCCUPANCY - OCCUPANCY',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 25,
    name: 'CAMP CAPACITY & OCCUPANCY - METHOD',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  },
  {
    id: 26,
    name: 'CAMP CAPACITY & OCCUPANCY - MEALS SERVED',
    regexp: onlyNumbersRegexp,
    maxSymbols: 255
  }
];
