import React, { useEffect } from 'react';

import { SummaryWrapper, SummaryHeader } from 'components/SummaryComponents';

import {
  useTeamMemberDashboardActions,
  useTeamMemberDashboardSelector
} from 'hooks/TeamMemberDashboard';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { ROOT_PATH } from 'constants/routeConstants';

import DashboardTabs from './components/Tabs';
import RandRDashboard from './components/RandRDashboard';
import StaffingLevelsDashboard from './components/StaffingLevelsDashboard';
import SnapshotDashboard from './components/SnapshotDashboard';

import { dashboardTypes, defaultTab } from './helpers';

export default function AssetsDashboards() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { tab } = useTeamMemberDashboardSelector();
  const { setTabAction, getFilterCriteriaAction, clearStateAction } =
    useTeamMemberDashboardActions();

  useEffect(() => {
    if (!tab) setTabAction(defaultTab);
  }, []);

  useEffect(() => {
    getFilterCriteriaAction();
  }, []);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <SummaryWrapper>
      <SummaryHeader title="Team Member Dashboard" />

      <DashboardTabs />

      {tab === dashboardTypes.RR && <RandRDashboard />}

      {tab === dashboardTypes.LEVELS && <StaffingLevelsDashboard />}

      {tab === dashboardTypes.SNAPSHOT && <SnapshotDashboard />}
    </SummaryWrapper>
  );
}
