import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function ScrollToTop({ elem }) {
  const { pathname } = useLocation();
  const isMobile = useMobileViewport();

  useEffect(() => {
    !isMobile ? (elem.current.scrollTop = 0) : window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
