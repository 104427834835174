import React from 'react';
import styles from '../Specifications.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../../selectors';
import SkillChip from '../SkillChip';

export default function Skillset() {
  const { skillsets } = useSelector(selectCurrentTeamMemberData());

  return (
    <div className={styles.specification}>
      <label>Skillset:</label>
      <div className={styles.specification__block}>
        {!!skillsets.length &&
          skillsets.map((item) => <SkillChip key={item.id} label={item.name || ''} />)}
      </div>
    </div>
  );
}
