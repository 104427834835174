import {
  SET_CREATE_BATCH_OF_ASSETS_CREATION_FORM,
  SET_PREVIEW_BATCH_OF_ASSETS,
  SET_BATCH_OF_ASSETS_FORM_STATE,
  CLEAR_CREATE_BATCH_OF_ASSETS_STATE,
  TOGGLE_CREATION_SCREEN
} from 'constants/reduceConstants';

const defaultFormState = {
  assetFields: [],
  assetPrefix: {},
  shortNote: '',
  grade: null,
  rental: {
    rentalStatus: {}
  },
  isNonOwned: false,
  qty: 1,
  purchasedAtUtc: null,
  labeled: true,
  purchasedFrom: '',
  lastLocationHistory: {
    location: {},
    sublocation: null
  },
  assetLicense: null
};

const initialState = {
  creationForm: {},
  previewAssets: [],
  formState: defaultFormState,
  isCreationOpened: true
};

const createBatchOfAssetsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CREATE_BATCH_OF_ASSETS_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_PREVIEW_BATCH_OF_ASSETS:
      return { ...state, previewAssets: payload };
    case SET_BATCH_OF_ASSETS_FORM_STATE:
      return { ...state, formState: payload };
    case TOGGLE_CREATION_SCREEN:
      return { ...state, isCreationOpened: !state.isCreationOpened };
    case CLEAR_CREATE_BATCH_OF_ASSETS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default createBatchOfAssetsReducer;
