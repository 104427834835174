import React from 'react';
import styles from './Details.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';

import { useFormContext } from 'react-hook-form';

import { MAX_FIELD_LENGTH } from '../../../helpers';

function CheckboxBlockRow({ label, children }) {
  return (
    <div className={styles.details__block_checkbox}>
      <label>{label}</label>
      {children}
    </div>
  );
}
function InputBlockRow({ label, children }) {
  return (
    <div className={styles.details__block_row}>
      <label>{label}</label>
      {children}
    </div>
  );
}

export default function FieldViewSwitcher({ item }) {
  const { getValues, setValue } = useFormContext();

  const { assetField } = item;
  const { displayName } = assetField;

  const fieldName = `assetFields[${getValues('assetFields').findIndex(
    (el) => el.assetField.id === assetField.id
  )}].value`;

  switch (assetField.type) {
    case 'String':
      return (
        <InputBlockRow label={displayName || ''}>
          <FormInputText
            name={fieldName}
            options={{ max: assetField?.constraints?.maxLength || MAX_FIELD_LENGTH }}
          />
        </InputBlockRow>
      );
    case 'Int':
    case 'Float':
      return (
        <InputBlockRow label={displayName || ''}>
          <FormInputText name={fieldName} options={{ type: 'quantity', focus: true }} />
        </InputBlockRow>
      );
    case 'DateTime':
      return (
        <InputBlockRow label={displayName || ''}>
          <FormDatePicker name={fieldName} />
        </InputBlockRow>
      );
    case 'Bool':
      return (
        <CheckboxBlockRow label={displayName || ''}>
          <FormCheckbox name={fieldName} />
        </CheckboxBlockRow>
      );
    case 'Enum':
      return (
        <InputBlockRow label={displayName || ''}>
          <FormSelectInput
            clearable
            name={fieldName}
            options={assetField?.['availableValues'] || []}
            onSelect={({ name, value }) => setValue(name, value, { shouldDirty: true })}
          />
        </InputBlockRow>
      );
    default:
      return null;
  }
}
