import React from 'react';
import styles from './BatchAssetUpdatesTabs.module.scss';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import { TOGGLE_BUTTONS, tabs } from '../helpers';

import { useUserConfig } from 'hooks/useUserConfig';
import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';

export default function BatchAssetUpdatesTabs() {
  const { isAdminUser, isOperationsManagerUser } = useUserConfig();

  const { selectedTab } = useBatchAssetUpdatesSelector();
  const { setTabAction } = useBatchAssetUpdatesActions();

  const shouldHideTab = (tabValue) =>
    tabValue === tabs.fieldsUpdate ? isAdminUser || isOperationsManagerUser : true;

  const clickTab = (tabValue) => tabValue && setTabAction(tabValue);

  return (
    <div className={styles.tabs}>
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        onChange={clickTab}
        hideTab={shouldHideTab}
        selectedTab={selectedTab}
        classes={styles.tabs__bar}
      />
    </div>
  );
}
