import { BASE_ADDRESS } from './api/api';

const CLIENT_ID = 'e1bbf135-b078-4a4d-8071-9e4e545a4620';
const CLOUD_INSTANCE = 'https://login.microsoftonline.com';
const TENANT_ID = '44d36f6f-4dce-447d-9184-0fd99fbf4f3a';

const REDIRECT_URI = BASE_ADDRESS;

const SCOPES = ['Calendars.ReadWrite'];

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: CLOUD_INSTANCE + '/' + TENANT_ID, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID or Name})
    redirectUri: REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = { scopes: SCOPES, prompt: 'select_account' };

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: GRAPH_ENDPOINT,
  calendarsEndpoint: '/calendars',
  eventsEndpoint: '/events'
};
