import React from 'react';
import styles from './HiddenSelectField.module.scss';
import { MenuItem, Select } from '@material-ui/core';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { useMobileViewport } from 'hooks/useMobileViewport';

const ITEM_HEIGHT = 37;
const ITEM_PADDING_TOP = 8;
const getMenuProps = (isMobile) => ({
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: isMobile ? 'calc(100% - 90px)' : 417,
      minWidth: isMobile ? 'calc(100% - 90px)' : 417
    }
  },
  getContentAnchorEl: () => null
});

export default function HiddenSelectField({
  open,
  setOpen,
  values,
  options,
  optionLabel,
  onChange,
  OptionComponent
}) {
  const isMobile = useMobileViewport();
  const handleSelect = (option) => onChange(option);

  const isOptionSelected = (id) => !!values.find((item) => item.id === id);

  return (
    <Select
      className={styles.select}
      classes={{ root: styles.select__root }}
      multiple
      open={open}
      value={values || []}
      MenuProps={getMenuProps(isMobile)}
      onClose={() => setOpen(!open)}>
      {options.length ? (
        options.map((option) =>
          OptionComponent ? (
            OptionComponent(option, setOpen, isOptionSelected(option.id), handleSelect)
          ) : (
            <MenuItem
              classes={{ root: styles.select__menu }}
              value={option.id}
              key={option.id}
              onClick={() => handleSelect(option)}>
              <CustomCheckbox name="active" value={isOptionSelected(option.id)} />
              <div className={styles.select__menu_option}>
                <span>{option[optionLabel]}</span>
              </div>
            </MenuItem>
          )
        )
      ) : (
        <MenuItem classes={{ root: styles.select__menu_empty }}>No available items</MenuItem>
      )}
    </Select>
  );
}
