import React, { useState } from 'react';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import { MainButton, StyledSwitch } from 'components/StyledComponents/';
import { enrichNameToId } from 'helpers/AppHelpers';
import { Checkbox, TextField } from '@material-ui/core';
import SelectInput from 'components/SelectInput/SelectInput';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ReportTemplates.module.scss';

const severities = [
  { id: 1, displayName: 'Operational', name: 'Operational' },
  { id: 2, displayName: 'Non-Critical', name: 'NonCritical' },
  { id: 3, displayName: 'Critical', name: 'Critical' },
  { id: 4, displayName: 'Beyond repair', name: 'BeyondRepair' }
];

export default function TextReportTemplate({
  index,
  template,
  isLocationReport,
  dragIsActive,
  reportType,
  onDrag,
  onUpdate,
  onCopy,
  onDelete,
  published
}) {
  const { data } = template;
  const { active, compare, required, isAutopopulate } = data;
  const [validationError, setValidationError] = useState(false);

  const handleSwitchClick = (e) => {
    onUpdate(index, {
      ...template,
      data: { ...template.data, [e.target.name]: !template.data[e.target.name] }
    });
  };

  const onInput = (event) => {
    let value = event.target.value;

    value = value.substr(0, 1000);
    value = value.replace(allKeyboardLatSymbols, '');

    onUpdate(index, {
      ...template,
      data: { ...template.data, text: value, value: '' }
    });
  };

  const createTicketCheck = () => {
    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        createTicket: {
          needCreation: !template.data.createTicket?.needCreation,
          severity: {}
        }
      }
    });
  };

  const onSelect = (e) => {
    setValidationError(false);
    onUpdate(index, {
      ...template,
      data: {
        ...template.data,
        createTicket: {
          ...template.data.createTicket,
          severity: e.value
        }
      }
    });
  };

  const onSave = () => {
    if (data.text) {
      if (
        reportType === 'Asset' &&
        template.data.createTicket?.needCreation &&
        !template.data.createTicket?.severity?.name
      ) {
        setValidationError(true);
        return;
      }
      onUpdate(index, { ...template, data: { ...template.data, active: false } });
    } else {
      onDelete(index);
    }
  };

  return (
    <section className={`${styles.templateWrapper} ${active ? styles.active : styles.inactive}`}>
      {active ? (
        <section className={styles.activeTemplate}>
          <div className={styles.activeTemplate__header}>
            <div>Text</div>
            <MenuIcon className={styles.dragIcon} onMouseMove={() => onDrag(true)} />
            <CloseIcon onClick={() => onDelete(index)} />
          </div>

          <div className={styles.activeTemplate__body}>
            <TextField
              placeholder="Enter the question text"
              multiline
              name="text"
              className={styles.textInput}
              variant="outlined"
              value={data.text || ''}
              onChange={onInput}
            />
            {reportType === 'Asset' && (
              <div className={styles.ticketBlock}>
                <label>Need ticket creation</label>
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  name="createTicket"
                  onChange={createTicketCheck}
                  value={data.createTicket?.needCreation}
                  checked={!!data.createTicket?.needCreation}
                />
                <label>Severity</label>
                <SelectInput
                  name="severity"
                  isInvalid={validationError}
                  value={data.createTicket?.severity?.name || ''}
                  menuItems={enrichNameToId(severities) || []}
                  onSelect={onSelect}
                  disabled={!data.createTicket?.needCreation}
                />
                {validationError && (
                  <span className={styles.validationError}>Field is required</span>
                )}
              </div>
            )}
          </div>

          <div className={styles.activeTemplate__controls}>
            <div>
              {isLocationReport && (
                <StyledSwitch
                  name="compare"
                  label="Compare reports"
                  checked={compare}
                  handleChange={handleSwitchClick}
                />
              )}
              <StyledSwitch
                name="required"
                label="Required"
                checked={required}
                handleChange={handleSwitchClick}
              />
              <StyledSwitch
                name="isAutopopulate"
                label="Autopopulate from previous reports"
                checked={isAutopopulate}
                handleChange={handleSwitchClick}
              />
            </div>
            <MainButton
              text="Save"
              action={onSave}
              type="primary"
              size="popup"
              isDisabled={!data?.text?.length || validationError}
            />
          </div>
        </section>
      ) : (
        <section className={styles.inactiveTemplate}>
          {!published && (
            <div className={styles.dragIcon}>
              <MenuIcon onMouseMove={() => onDrag(true)} />
            </div>
          )}
          {!published && (
            <div
              className={`${styles.inactiveTemplate__controls} ${
                dragIsActive ? styles.hidden : ''
              }`}>
              <button>
                <CreateIcon
                  onClick={() =>
                    onUpdate(index, {
                      ...template,
                      data: { ...template.data, active: true }
                    })
                  }
                />
              </button>
              <button>
                <FileCopyIcon onClick={() => onCopy(index)} />
              </button>
              <button onClick={() => onDelete(index)}>
                <DeleteIcon />
              </button>
            </div>
          )}
          <div className={styles.inactiveTemplate__body}>
            <div className={styles.textareaWrapper}>
              <div>
                <b>
                  {/* <span>{questionIndex}.</span>  */}
                  {data.text || ''}
                  {required && '*'}
                </b>
              </div>
              <div className={`${styles.multiTextInput} ${styles.textTemplateInput}`}>
                <TextField multiline rows={1} variant="outlined" value={''} disabled />
                <span>0/65000</span>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
}
