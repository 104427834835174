import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import BackButton from 'components/BackButton/BackButton';

import {
  CREATE_NEW_ASSET,
  EXIT_WITHOUT_SAVING,
  NEW_ASSET_TYPE,
  UPDATE_ASSET,
  UPDATE_ASSET_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { ASSET_DETAILED_PATH, ASSETS_LIST_PATH } from 'constants/routeConstants';

import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getBackendErrors } from 'helpers/AppHelpers';
import { focusErrorField, getErrorsProperties } from 'helpers/ErrorValidator';

import { getFieldsToValidate, preparePayload } from '../helpers';

export function Wrapper({ breadcrumbs, children }) {
  const history = useHistory();
  const { id } = useParams();
  const isMobile = useMobileViewport();

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();

  const { currentAsset } = useAssetSelector();
  const { createAssetAction, updateAssetAction } = useAssetActions();

  const { formState, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const closeModal = () => setModalData({});
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;
  const getBack = () => history.push(id ? `${ASSET_DETAILED_PATH}/${id}` : ASSETS_LIST_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const handleResponse = (err) => {
    if (!err?.errors) return;

    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
  };

  const agreeModal = () => {
    const helpers = { values: getValues(), id, currentAsset };

    switch (modalData.type) {
      case NEW_ASSET_TYPE:
        closeModal();
        createAssetAction(preparePayload(helpers)).then(handleResponse);
        break;
      case UPDATE_ASSET_TYPE:
        closeModal();
        updateAssetAction(preparePayload(helpers)).then(handleResponse);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    const modal = id ? UPDATE_ASSET : CREATE_NEW_ASSET;
    modal.isOpened = true;
    setModalData(modal);
  };

  const validateForm = () => {
    let isFormValid = true;
    let firstError;

    getFieldsToValidate(getValues()).forEach(
      ({ name, errorMessage, isInvalid, selector, shouldCheck = true }) => {
        if (isInvalid && shouldCheck) {
          setError(name, getErrorsProperties(errorMessage));
          isFormValid = false;
          if (!firstError) {
            firstError = selector;
          }
        }
      }
    );

    firstError && focusErrorField(firstError);

    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}

        <div className={styles.header__block}>
          <h1>{id ? 'Edit' : 'Add'} Asset</h1>
          {isMobile && <BackButton onCancel={handleBackClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'Save' : 'Add'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid}
        />
      </div>

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </section>
  );
}
