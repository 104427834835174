import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLooseItemsApi = (query) =>
  api.get(`/LooseItems/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const moveItemsApi = (params) =>
  api.post(`/LooseItems/Move`, params).then((res) => res.data);
