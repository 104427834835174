import {
  SET_BATCH_ITEMS_UPDATES_CATEGORIES,
  SET_BATCH_ITEMS_UPDATES_FIELDS,
  SET_BATCH_ITEMS_UPDATES_LOOSE_ITEMS,
  CLEAR_BATCH_ITEMS_UPDATES_STATE,
  SAVE_BATCH_ITEMS_UPDATES_FORM_STATE
} from 'constants/reduceConstants';

const initialState = {
  looseItems: [],
  customFields: [],
  categories: [],
  formState: {
    looseItems: [],
    customFields: [],
    looseItemCategories: []
  }
};

const batchItemUpdatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BATCH_ITEMS_UPDATES_CATEGORIES:
      return { ...state, categories: payload };
    case SET_BATCH_ITEMS_UPDATES_FIELDS:
      return { ...state, customFields: payload };
    case SET_BATCH_ITEMS_UPDATES_LOOSE_ITEMS:
      return { ...state, looseItems: payload };
    case SAVE_BATCH_ITEMS_UPDATES_FORM_STATE:
      return { ...state, formState: payload };
    case CLEAR_BATCH_ITEMS_UPDATES_STATE:
      return { ...initialState, formState: state.formState };
    default:
      return state;
  }
};

export default batchItemUpdatesReducer;
