import { SET_USER_LOGS_FILTER, CLEAR_USER_LOGS_STATE } from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const initialState = {
  filter: {}
};

const userLogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_LOGS_FILTER:
      return { ...state, filter: payload };
    case CLEAR_USER_LOGS_STATE:
      return {
        ...initialState,
        filter: getClearedFilterByUnmount(state.filter)
      };
    default:
      return state;
  }
};

export default userLogsReducer;
