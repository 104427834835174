export const statusTypes = [
  { id: 1, name: 'Active', booleanValue: true },
  { id: 2, name: 'Inactive', booleanValue: false }
];

export const getStatusType = (boolean) =>
  statusTypes.find(({ booleanValue }) => booleanValue === boolean);

const EMAIL_MAXIMUM_LENGTH = 50;

export const modifyRecipientsText = (str) =>
  str
    .replace(/[^A-Za-z0-9\-,@._ ]/g, '')
    .split(',')
    .map((item) => item.substring(0, EMAIL_MAXIMUM_LENGTH))
    .join(',');
