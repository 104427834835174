import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  selectLocationListData,
  selectSingleAssembleData,
  selectStatusListFilterData
} from '../selectors';
import {
  clearAssembledLooseItemState,
  deleteAssemble,
  getLocationsWithSublocations,
  getSingleAssemble,
  getStatusListForFilter,
  updateAssemble
} from 'actions/assembledLooseItemActions';
import { useHistory, useParams } from 'react-router-dom';
import styles from './AssembleDetailed.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import {
  formatDate,
  isAdminUser,
  isTeamMemberUser,
  dateTimeFormat,
  truncateString,
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  getUserFullName
} from 'helpers/AppHelpers';
import { Checkbox } from '@material-ui/core';
import MainButton from 'components/StyledComponents/MainButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ASSEMBLED_BOM_LIST_PATH } from 'constants/routeConstants';
import SelectInput from 'components/SelectInput/SelectInput';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { TRY_AGAIN_MESSAGE } from 'constants/infoSnackbarData';
import { REMOVE_ASSEMBLE } from 'constants/dialogPopupsData';
import DeleteIcon from '@material-ui/icons/Delete';
import { selectUserConfig } from '../../../commonSelectors';
import { useMobileViewport } from 'hooks/useMobileViewport';
import DetailedRow from 'components/DetailsComponents/DetailedRow';
import ToggleBar from '../../../../components/ToggleBars/ToggleBar';
import LooseItemsTable from './components/LooseItemsTable/LooseItemsTable';
import AssetsTable from './components/AssetsTable/AssetsTable';
import clsx from 'clsx';

const InAssemblyStatus = 'InAssembly';
const AssembledStatus = 'Assembled';
const DisassembledStatus = 'Disassembled';
const ClosedStatus = 'Closed';

function AssembleDetailed({
  userConfig,
  currentAssemble,
  locationList,
  getLocationsAction,
  getAssembleAction,
  statusList,
  getStatusListAction,
  updateAssembleAction,
  deleteAssembleAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const [values, setValues] = useState({ active: false, isDisabled: false });
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const [isTabAvailable, setIsTabAvailable] = useState(false);
  const [currentTab, setCurrentTab] = useState('loose_items');
  const [assetsTable, setAssetsTable] = useState([]);

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'loose_items', label: 'Loose Items' },
    { id: 2, value: 'assets', label: 'Assets' }
  ];

  const onClickTab = (tab) => {
    setCurrentTab(tab);
    setValues({ active: false, isDisabled: false });
  };

  const { userRole } = userConfig;

  useEffect(() => {
    if (!locationList?.length) {
      getLocationsAction({ filters: { active: true } });
    }
  }, [locationList]);

  const getItemRows = (items, multiplier) => {
    const newRows = [];
    if (items?.length) {
      items.forEach((el) => {
        let row = {};
        for (let i = 0; i < el.locations.length; i += 1) {
          row = {
            id: el.id,
            name: el.name,
            location: el.locations[i].location,
            sublocation: el.locations[i]?.sublocation,
            pointOfOrigin: el.locations[i]?.pointOfOrigin,
            selectedQuantity: el.locations[i].selectedQuantity,
            selectedByAheadAccount: el.locations[i]?.selectedByAheadAccount,
            requiredQuantity: el.requiredQuantity * (multiplier ? multiplier : 1)
          };
          newRows.push(row);
        }
      });
    }
    return newRows;
  };

  const getAssetRows = (items, multiplier = 1) => {
    const newRows = [];
    if (items?.length) {
      items.forEach((el) => {
        for (let i = 0; i < el.assets.length; i += 1) {
          newRows.push({
            ...el,
            ...el.assets[i],
            prefixId: el.id,
            assetId: el.assets[i].id,
            requiredQuantity: el.requiredQuantity * (multiplier ? multiplier : 1)
          });
        }
      });
    }
    return newRows;
  };

  const revertAssetRows = (rows) => {
    const result = [];

    rows.forEach((row) => {
      let existingItem = result.find((item) => item.id === row.prefixId);

      if (!existingItem) {
        existingItem = {
          id: row.prefixId,
          name: row.name,
          prefix: row.prefix,
          requiredQuantity: row.requiredQuantity,
          assets: []
        };
        result.push(existingItem);
      }

      existingItem.assets.push({
        id: row.assetId,
        drCode: row.drCode,
        currentLocationId: row.currentLocationId,
        pointOfOrigin: row.pointOfOrigin,
        location: row.location,
        newLocation: row.newLocation,
        newSublocation: row.newSublocation
      });
    });

    return result;
  };

  useEffect(() => {
    if (id) {
      getAssembleAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (!statusList?.length) {
      getStatusListAction();
    }
  }, [statusList]);

  useEffect(() => {
    if (currentAssemble?.id) {
      setBreadcrumbs([{ name: truncateString(currentAssemble.name, 25) }]);

      if (currentAssemble?.looseItems?.length > 0) {
        if (
          currentAssemble.status.name === InAssemblyStatus ||
          currentAssemble.status.name === AssembledStatus
        ) {
          const newTableState = getItemRows(currentAssemble.looseItems, currentAssemble.quantity);
          setTableState(newTableState);
        } else {
          const newValues = currentAssemble.looseItems.map((el) => {
            return { ...el, requiredQuantity: el.requiredQuantity * currentAssemble.quantity };
          });
          setTableState(newValues);
        }
        setCurrentTab('loose_items');
      }

      if (currentAssemble?.assetPrefixes?.length > 0) {
        const newTableState = getAssetRows(currentAssemble.assetPrefixes, currentAssemble.quantity);
        setAssetsTable(newTableState);

        setCurrentTab('assets');
      }

      if (currentAssemble?.looseItems?.length && currentAssemble?.assetPrefixes?.length) {
        setIsTabAvailable(true);
        setCurrentTab('loose_items');
      }
    }
  }, [currentAssemble]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const onChangeStatus = (status) => {
    const newLooseItems = isFirstTableView()
      ? currentAssemble.looseItems
      : [...tableState].map((el) => {
          return {
            ...el,
            requiredQuantity: el.requiredQuantity / currentAssemble.quantity
          };
        });
    const newAssets = revertAssetRows(assetsTable);
    updateAssembleAction({
      ...currentAssemble,
      status: { ...statusList.find((el) => el.name === status) },
      looseItems: newLooseItems,
      assetPrefixes: newAssets
    }).then((res) => {
      if (res.id) {
        const message = `The assemble ${currentAssemble?.name || ''} status changed from ${
          currentAssemble?.status?.displayName || ''
        } to ${status}`;
        enqueueSuccessSnackbar(message);
      } else {
        enqueueErrorSnackbar(TRY_AGAIN_MESSAGE);
      }
    });
  };

  const getButtons = () => {
    switch (currentAssemble?.status?.name) {
      case InAssemblyStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text={AssembledStatus}
                type="primary"
                action={() => onChangeStatus(AssembledStatus)}
              />
            )}
          </section>
        );
      case AssembledStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text="Disassemble"
                type="primary"
                action={() => onChangeStatus(DisassembledStatus)}
              />
            )}
          </section>
        );
      case DisassembledStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text="Close project"
                type="primary"
                action={() => onChangeStatus(ClosedStatus)}
              />
            )}
          </section>
        );
      case ClosedStatus:
        return <></>;
      default:
        return <></>;
    }
  };

  const isStatus = (status) => {
    return currentAssemble?.status?.name === status;
  };

  const onLocationSelect = (selectedItem) => {
    if (currentTab === 'loose_items') {
      const newLocation = { ...selectedItem.value };
      const newArray = [...tableState].map((el) => {
        return { ...el, location: newLocation, sublocation: null };
      });
      setTableState(newArray);
      setValues({
        ...values,
        [selectedItem.name]: selectedItem.value,
        isDisabled: true
      });
    } else {
      const newArray = assetsTable.map((el) => {
        return {
          ...el,
          newLocation: selectedItem.value,
          newSublocation: null
        };
      });
      setAssetsTable(newArray);
      setValues({
        ...values,
        [selectedItem.name]: selectedItem.value,
        isDisabled: true
      });
    }
  };

  const onAgree = () => {
    deleteAssembleAction(currentAssemble.id);
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const onChange = () => {
    setValues({ active: !values.active });
  };

  const isOwnAssembly = () => {
    return (
      isAdminUser(userRole) ||
      (!isAdminUser(userRole) && currentAssemble?.createdByUser?.email === userConfig.email)
    );
  };

  const isFirstTableView = () => isStatus(InAssemblyStatus) || isStatus(AssembledStatus);

  return (
    <section className={styles.pageContainer}>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={onDismiss}
        isMobile={isMobile}
      />
      {currentAssemble?.id === +id && (
        <>
          <div>
            {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
            <section className={styles.headerBlock}>
              {!isMobile && <h1>{currentAssemble.name}</h1>}
              <div className={styles.headerBlock__controls}>
                {(isStatus(InAssemblyStatus) || isStatus(ClosedStatus)) &&
                  !isTeamMemberUser(userRole) &&
                  isOwnAssembly() && (
                    <button
                      onClick={() => setDialogModalData({ ...REMOVE_ASSEMBLE, isOpened: true })}>
                      <DeleteIcon />
                      {!isMobile && <span>Remove assemble</span>}
                    </button>
                  )}
                {isMobile && (
                  <button onClick={() => history.push(ASSEMBLED_BOM_LIST_PATH)}>
                    <ChevronLeftIcon />
                  </button>
                )}
              </div>
            </section>
            <section className={styles.contentWrapper}>
              {isMobile && <h1>{currentAssemble.name}</h1>}

              <DetailedRow
                label="Location"
                value={currentAssemble?.location?.siteCode || ''}
                disableFlex
              />
              <DetailedRow
                label="Sublocation"
                value={currentAssemble?.sublocation?.name || ''}
                disableFlex
              />
              <DetailedRow
                label="Status"
                value={currentAssemble?.status?.displayName || ''}
                disableFlex
              />
              <DetailedRow
                label="Configuration"
                value={currentAssemble?.looseItemConfiguration?.name || ''}
                disableFlex
              />
              <DetailedRow label="Quantity" value={currentAssemble?.quantity || 0} disableFlex />
              <DetailedRow
                label="Created by"
                value={getUserFullName(currentAssemble?.createdByUser)}
                disableFlex
              />
              <DetailedRow
                label="Created"
                value={
                  currentAssemble?.createdAtUtc
                    ? formatDate(currentAssemble?.createdAtUtc, dateTimeFormat)
                    : ''
                }
                disableFlex
              />
              <DetailedRow label="Notes" value={currentAssemble?.notes || ''} disableFlex />
            </section>
            {isTabAvailable && (
              <ToggleBar
                buttons={TOGGLE_BUTTONS}
                onChange={onClickTab}
                selectedTab={currentTab}
                classes={styles.toggle}
              />
            )}
            {currentTab === 'loose_items' ? (
              <LooseItemsTable
                tableState={tableState}
                setTableState={setTableState}
                values={values}
                setValues={setValues}
                locationList={locationList}
                isFirstTableView={isFirstTableView}
                isStatus={isStatus}
                userRole={userRole}
                isOwnAssembly={isOwnAssembly}
              />
            ) : (
              <AssetsTable
                assetsTable={assetsTable}
                setAssetsTable={setAssetsTable}
                values={values}
                setValues={setValues}
                locationList={locationList}
                isStatus={isStatus}
                userRole={userRole}
                isOwnAssembly={isOwnAssembly}
              />
            )}
            <div className={clsx(styles.footer, currentTab === 'assets' && styles.footerSingle)}>
              {!!tableState.length && currentTab === 'loose_items' && (
                <label className={styles.hintLabel}>[1]Buy Ahead Account</label>
              )}
              {isStatus(DisassembledStatus) && !isTeamMemberUser(userRole) && isOwnAssembly() && (
                <section className={styles.tableFooter}>
                  <section className={styles.checkboxRow}>
                    <label>KEEP DESTINATION ALL THE SAME</label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        classes={{
                          root: styles.checkbox,
                          checked: styles.checked,
                          disabled: styles.disabled
                        }}
                        name="active"
                        onChange={onChange}
                        value={values?.active}
                        checked={!!values.active}
                        disabled={!!values.isDisabled}
                      />
                    </div>
                  </section>
                  <div className={styles.inputWrapper}>
                    <SelectInput
                      name="siteCode"
                      value={values.siteCode?.id || ''}
                      menuItems={locationList || []}
                      onSelect={onLocationSelect}
                      disabled={!values.active}
                    />
                  </div>
                </section>
              )}
            </div>
          </div>
          <>{getButtons()}</>
        </>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentAssemble: selectSingleAssembleData(),
  statusList: selectStatusListFilterData(),
  locationList: selectLocationListData(),
  userConfig: selectUserConfig()
});

const mapDispatchToProps = {
  getAssembleAction: getSingleAssemble,
  getLocationsAction: getLocationsWithSublocations,
  updateAssembleAction: updateAssemble,
  getStatusListAction: getStatusListForFilter,
  deleteAssembleAction: deleteAssemble,
  clearStateAction: clearAssembledLooseItemState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssembleDetailed);
