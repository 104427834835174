import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import MatchingLocation from 'components/ModalContents/MatchingLocation';

import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useManageLooseItemsActions, useManageLooseItemsSelector } from 'hooks/ManageLooseItems';

import {
  ADD_LOOSE_ITEMS_TO_PICKLIST_TYPE,
  ADD_LOOSE_ITEMS_TO_LEAVELIST_TYPE,
  EDIT_ITEM_OF_ENTITY_TYPE,
  EXIT_WITHOUT_SAVING,
  PICKLIST_ITEMS_LOCATION_CHECK,
  PICKLIST_ITEMS_LOCATION_CHECK_TYPE
} from 'constants/dialogPopupsData';
import { LEAVELIST_DETAILED_PATH, PICKLIST_DETAILED_PATH } from 'constants/routeConstants';
import { WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY } from 'constants/infoSnackbarData';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { enqueueWarningSnackbar, isSuccessfulStatus, pluck } from 'helpers/AppHelpers';

import { checkByAheadAccount, checkMoveQuantity, checkRowForWarnings } from '../../helpers';

export default function Wrapper({ children }) {
  const history = useHistory();
  const [modalData, setModalData] = useState({});

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();

  const { checkLooseItemsLocationAction, addLooseItemsAction, editLooseItemAction } =
    useManageLooseItemsActions();
  const { entityData, isEditMode } = useManageLooseItemsSelector();

  const { activeEntity, locationLooseItemDetails, isPicklist } = entityData;
  const entityPath = `${isPicklist ? PICKLIST_DETAILED_PATH : LEAVELIST_DETAILED_PATH}/${
    activeEntity?.id
  }`;

  const { getValues, setValue, formState, setError } = useFormContext();
  const { isDirty } = formState;

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const validateForm = () => {
    let isFormValid = true;

    const looseItems = getValues('items');

    looseItems.forEach((row, index) => {
      const moveQtyErrorMessage = checkMoveQuantity(row);
      const moveBaaErrorMessage = checkByAheadAccount(row);

      if (moveQtyErrorMessage) {
        setError(`items[${index}].moveQuantity`, getErrorsProperties(moveQtyErrorMessage));
        isFormValid = false;
      }

      if (moveBaaErrorMessage) {
        setError(`items[${index}].moveByAheadAccount`, getErrorsProperties(moveBaaErrorMessage));
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  const validateWarnings = () => {
    const rowsWithWarnings = [];
    const warnings = getValues('warnings');
    const looseItems = getValues('items');

    looseItems.forEach((row, index) => {
      if (checkRowForWarnings(row)) {
        rowsWithWarnings.push(`items[${index}]`);
      }
    });

    const shouldDisplayPopup = !warnings?.length && !!rowsWithWarnings?.length;

    if (shouldDisplayPopup) {
      setValue('warnings', rowsWithWarnings);
      enqueueWarningSnackbar(WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY);
    }

    return shouldDisplayPopup;
  };

  const handleBackClick = (item) => {
    if (isDirty || unsavedFormData) {
      setModalData({ ...EXIT_WITHOUT_SAVING, selectedItemPath: item.path });
    } else {
      history.push(item.path);
    }
  };

  const goToEntityDetails = () => handleBackClick({ path: entityPath });

  const handleCreateClick = () => {
    if (!validateForm()) return;

    if (validateWarnings()) return;

    const actionType = isEditMode
      ? EDIT_ITEM_OF_ENTITY_TYPE
      : isPicklist
      ? ADD_LOOSE_ITEMS_TO_PICKLIST_TYPE
      : ADD_LOOSE_ITEMS_TO_LEAVELIST_TYPE;
    const actionText = isEditMode ? 'edit items of' : 'add items to';
    const modalTitle = `Do you want to ${actionText} the ${isPicklist ? 'picklist' : 'leavelist'} ${
      activeEntity.name
    }?`;

    setModalData({
      isOpened: true,
      type: actionType,
      title: modalTitle
    });
  };

  const modifyItemForBackend = (el) => {
    const { moveQuantity, moveByAheadAccount, ...rest } = el;
    return {
      locationLooseItem: rest,
      moveByAheadAccount: moveByAheadAccount || null,
      moveQuantity
    };
  };

  const addItemsToEntity = (itemsToExclude = []) => {
    const excludedItemIds = pluck(itemsToExclude, 'id');
    const items = getValues('items')
      .filter(({ id }) => !excludedItemIds.includes(id))
      .map(modifyItemForBackend);

    if (!items?.length) return;

    const params = {
      [isPicklist ? 'createPicklistLooseItemDtos' : 'createLeavelistLooseItemDtos']: items,
      [isPicklist ? 'picklist' : 'leavelist']: activeEntity
    };

    addLooseItemsAction(params).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        history.push(entityPath);
      }
    });
  };

  const handleItemsLocationCheck = (res) => {
    if (!res?.locationLooseItems?.length) {
      addItemsToEntity();
      return;
    }

    const modalOptions = {
      ...PICKLIST_ITEMS_LOCATION_CHECK,
      itemsToExclude: res.locationLooseItems,
      content: (
        <MatchingLocation
          items={res.locationLooseItems}
          options={{
            itemType: 'looseItem',
            documentType: 'picklist',
            endpointType: res['isPicklistWithSublocation'] ? 'sublocation' : 'location'
          }}
        />
      )
    };
    setModalData(modalOptions);
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case EDIT_ITEM_OF_ENTITY_TYPE: {
        const { picklistAssetId, leavelistAssetId } = locationLooseItemDetails;
        const params = {
          [isPicklist ? 'createPicklistLooseItemDto' : 'createLeavelistLooseItemDto']:
            modifyItemForBackend(getValues('items')[0]),
          [isPicklist ? 'picklist' : 'leavelist']: activeEntity,
          ...(isPicklist ? { picklistAssetId } : { leavelistAssetId })
        };

        editLooseItemAction(params).then((res) => {
          if (isSuccessfulStatus(res.status)) {
            history.push(entityPath);
          }
        });
        break;
      }
      case ADD_LOOSE_ITEMS_TO_PICKLIST_TYPE: {
        const query = {
          picklistId: activeEntity.id,
          locationLooseItemIds: pluck(getValues('items'), 'id')
        };
        checkLooseItemsLocationAction(query).then(handleItemsLocationCheck);
        break;
      }
      case ADD_LOOSE_ITEMS_TO_LEAVELIST_TYPE:
      case PICKLIST_ITEMS_LOCATION_CHECK_TYPE:
        addItemsToEntity();
        break;
      default:
        closeModal();
        history.push(modalData.selectedItemPath);
        break;
    }
  };

  const closeModal = () => {
    if (modalData.type === PICKLIST_ITEMS_LOCATION_CHECK_TYPE) {
      addItemsToEntity(modalData.itemsToExclude);
    }
    setModalData({});
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <Header onLinkClick={handleBackClick} />
      {children}
      <Footer onCreate={handleCreateClick} onCancel={goToEntityDetails} />
    </div>
  );
}
