import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  clearComparePreselectData,
  getActiveLocationsLocationRepot,
  searchReportTemplateByLocationId,
  getCompareFields,
  getReportComparedData
} from 'actions/reportListActions';
import {
  selectComparePreselectData,
  selectActiveLocationsLocationReport,
  selectReportDataFromSearch,
  selectCompareFieldsData,
  selectReportComparedData
} from '../selectors';
import { formatDate } from 'helpers/AppHelpers';
import CompareReportsData from './CompareReportsData';
import { Chip } from '@material-ui/core';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { MainButton } from 'components/StyledComponents';
import SelectInput from 'components/SelectInput/SelectInput';
import { RESET_FILTER_PARAMS } from 'constants/dialogPopupsData';
import CloseIcon from '@material-ui/icons/Close';
import styles from './CompareReports.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import moment from 'moment/moment';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';

function ComapreReportsSelector({
  comparePreselectData,
  clearComparePreselectDataAction,
  activeLocations,
  getActiveLocationsLocationRepotAction,
  reportDataFromSearch,
  searchReportTemplateByLocationIdAction,
  compareFields,
  getCompareFieldsAction,
  reportComparedData,
  getReportComparedDataAction
}) {
  const isMobile = useMobileViewport();
  const [preconditionState, setPreconditionState] = useState(true);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const defaultValues = {
    location: {},
    generalReportTemplate: {},
    dateFrom: null,
    dateTo: null,
    comparedGeneralReportFields: [],
    pageNumber: 1
  };

  const [values, setValues] = useState(defaultValues);

  const getDataFromPrefilter = (data) => {
    if (preconditionState) {
      const { location, generalReportTemplate } = data;
      if (location?.id) {
        searchReportTemplateByLocationIdAction({ locationId: location.id });
      }
      if (generalReportTemplate?.id) {
        getCompareFieldsAction(generalReportTemplate.id);
      }
      setValues(data);
    }
  };

  useEffect(() => {
    if (!activeLocations.items?.length) {
      getActiveLocationsLocationRepotAction({
        filters: { includeReportDisplayCheck: true }
      });
    }
  }, [activeLocations]);

  useEffect(() => {
    if (
      comparePreselectData?.location?.id ||
      comparePreselectData?.generalReportTemplate?.id ||
      comparePreselectData?.dateFrom ||
      comparePreselectData?.dateTo ||
      comparePreselectData?.comparedGeneralReportFields?.length
    ) {
      getDataFromPrefilter(comparePreselectData);
    }
  }, [comparePreselectData]);

  const onSelect = (selectedItem) => {
    if (selectedItem.name === 'location') {
      setValues({
        ...values,
        location: selectedItem.value,
        generalReportTemplate: {},
        comparedGeneralReportFields: []
      });
      searchReportTemplateByLocationIdAction({ locationId: selectedItem.value.id });
    } else {
      setValues({
        ...values,
        generalReportTemplate: selectedItem.value,
        comparedGeneralReportFields: []
      });
      getCompareFieldsAction(selectedItem.value.id);
    }
  };

  const handleDateChange = (date, name) => {
    setValues({
      ...values,
      [name]: date ? formatDate(date) : null
    });
  };

  const selectField = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChipDelete = (item) => {
    setValues({
      ...values,
      comparedGeneralReportFields: values.comparedGeneralReportFields.filter(
        ({ id }) => id !== item.id
      )
    });
  };

  const onResetFilter = () => {
    setDialogModalData({ ...RESET_FILTER_PARAMS, isOpened: true });
  };

  const generateClickHandle = () => {
    getReportComparedDataAction(values);
    setPreconditionState(false);
  };

  const isResetDisabled = () => {
    return (
      !values.location?.id &&
      !values.generalReportTemplate?.id &&
      !values.dateFrom &&
      !values.dateTo &&
      !values.comparedGeneralReportFields?.length
    );
  };

  const isGenerateDisabled = () => {
    return (
      !values.location?.id ||
      !values.generalReportTemplate?.id ||
      !values.dateFrom ||
      !values.dateTo ||
      !values.comparedGeneralReportFields?.length
    );
  };

  const onAgree = () => {
    setValues({ ...defaultValues });
    clearComparePreselectDataAction({ ...defaultValues });
    setDialogModalData({ isOpened: false });
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  return (
    <>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={onDismiss}
        isMobile={isMobile}
      />
      {preconditionState === true ? (
        <section className={`${styles.pageContainer} ${styles.precondition}`}>
          <div className={styles.pageHeader}>
            <div className={styles.headerTitle}>
              <h1>Compare reports</h1>
              {isMobile && 'generalReportTemplate' in reportComparedData && (
                <BackButton onCancel={generateClickHandle} />
              )}
            </div>
          </div>
          <section className={styles.mainInfoBlock}>
            <section className={styles.mainInfoBlock__container}>
              <section className={`${styles.formCell} ${styles.select}`}>
                <label>Location*</label>
                <div className={styles.inputWrapper}>
                  <SelectInput
                    name="location"
                    value={values.location?.id || ''}
                    menuItems={activeLocations?.items || []}
                    onSelect={onSelect}
                  />
                </div>
              </section>
              <section className={`${styles.formCell} ${styles.select}`}>
                <label>Report name*</label>
                <div className={styles.inputWrapper}>
                  <SelectInput
                    name="generalReportTemplate"
                    value={values.generalReportTemplate?.id || ''}
                    menuItems={reportDataFromSearch || []}
                    onSelect={onSelect}
                    disabled={!values.location?.id}
                  />
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Duration*</label>
                <div className={styles.dateInputWrapper}>
                  <div className={styles.picker}>
                    <StyledDatePicker
                      name="dateFrom"
                      value={values?.dateFrom || null}
                      onChange={handleDateChange}
                      min={moment().add(-100, 'year')}
                      max={values?.dateTo || moment().add(10, 'year')}
                      placement="top"
                      customPositionClasses={styles.pickerFromPosition}
                    />
                  </div>
                  <div className={styles.picker}>
                    <StyledDatePicker
                      name="dateTo"
                      value={values?.dateTo || null}
                      onChange={handleDateChange}
                      min={values?.dateFrom || moment().add(-100, 'year')}
                      max={moment().add(10, 'year')}
                      placement="top"
                      customPositionClasses={styles.pickerToPosition}
                    />
                  </div>
                </div>
              </section>
              <section className={`${styles.formCell} ${styles.rowCell}`}>
                <label>Fields to compare*</label>
                <div className={styles.inputWrapper}>
                  <AutocompleteWithSelectAll
                    name="comparedGeneralReportFields"
                    label="text"
                    value={values.comparedGeneralReportFields || []}
                    options={compareFields || []}
                    onSelect={selectField}
                    isDisabled={!values.generalReportTemplate?.id}
                  />
                  <p>Select from the list</p>
                </div>
              </section>

              <section className={styles.chipsBlock}>
                {!!values.comparedGeneralReportFields?.length &&
                  values.comparedGeneralReportFields.map((item, index) => (
                    <Chip
                      classes={{ icon: styles.chipIcon }}
                      key={index}
                      label={item.text}
                      deleteIcon={<CloseIcon />}
                      className={styles.fieldsChip}
                      onDelete={() => handleChipDelete(item)}
                    />
                  ))}
              </section>
            </section>
            <section className={styles.controlButtons}>
              <MainButton
                text="Reset filter"
                action={onResetFilter}
                type="secondary"
                size="popup"
                isDisabled={isResetDisabled()}
              />
              <MainButton
                text="Generate"
                action={generateClickHandle}
                type="primary"
                size="popup"
                isDisabled={isGenerateDisabled()}
              />
            </section>
          </section>
        </section>
      ) : (
        preconditionState === false && (
          <CompareReportsData
            isMobile={isMobile}
            requestData={values}
            setPreconditionState={setPreconditionState}
          />
        )
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  comparePreselectData: selectComparePreselectData(),
  activeLocations: selectActiveLocationsLocationReport(),
  reportDataFromSearch: selectReportDataFromSearch(),
  compareFields: selectCompareFieldsData(),
  reportComparedData: selectReportComparedData()
});

const mapDispatchToProps = {
  clearComparePreselectDataAction: clearComparePreselectData,
  getActiveLocationsLocationRepotAction: getActiveLocationsLocationRepot,
  searchReportTemplateByLocationIdAction: searchReportTemplateByLocationId,
  getCompareFieldsAction: getCompareFields,
  getReportComparedDataAction: getReportComparedData
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ComapreReportsSelector);
