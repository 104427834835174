import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTrainingsApi = (query) =>
  api.get(`/ExternalTrainings?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = () =>
  api.get('/ExternalTrainings/FilterCriteria').then((res) => res.data);

export const getGroupsApi = (query) =>
  api.get(`/TeamMemberLicenses?${queryToString(query)}`).then((res) => res.data);

export const createGroupApi = (data) => api.post('/TeamMemberLicense', data).then((res) => res);

export const updateGroupApi = (data) => api.put('/TeamMemberLicense', data).then((res) => res);

export const deleteGroupApi = (query) =>
  api.delete(`/TeamMemberLicense?${queryToString(query)}`).then((res) => res);

export const createCertificationApi = (data) =>
  api.post('/TeamMemberLicense', data).then((res) => res);

export const updateCertificationApi = (data) =>
  api.put('/ExternalTraining', data).then((res) => res);

export const deleteCertificationApi = (query) =>
  api.delete(`/TeamMemberLicense?${queryToString(query)}`).then((res) => res);
