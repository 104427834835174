import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { dateFormat } from 'helpers/AppHelpers';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import pickerStyles from './styledComponents.module.scss';

const StyledDatePicker = withStyles({
  root: {
    width: '250px',
    background: '#f7f7f7',
    borderRadius: '8px',
    border: '1px solid transparent',
    padding: '8px 4px 8px 10px',
    boxSizing: 'border-box',
    '& .MuiInputBase-input': {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      color: '#000',
      lineHeight: '14px',
      fontSize: '14px',
      fontWeight: '400',
      padding: '0 !important'
    }
  }
})(KeyboardDatePicker);

const useStyles = makeStyles(() => ({
  small: {
    width: '120px'
  },
  errorBorder: {
    border: '1px solid #e92d2d'
  },
  errorMessage: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    position: 'relative',
    fontWeight: 400,
    fontSize: '10px',
    left: 0,
    bottom: '-2px',
    color: '#e92d2d'
  },
  pLeft: {
    paddingLeft: '16px'
  },
  full: {
    width: '100% !important'
  },
  disabled: {
    opacity: 0.5
  }
}));

const TextFieldComponent = (props) => <TextField {...props} disabled={true} />;

const DatePicker = ({
  name,
  views,
  format,
  selectedDate,
  handleDateChange,
  size,
  minDate,
  maxDate,
  disabled,
  validationErrors,
  isInvalid,
  labelFunc,
  renderDay
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StyledDatePicker
        disableToolbar
        variant="inline"
        className={clsx(
          size && classes[size],
          validationErrors && validationErrors[name] && classes.errorBorder,
          isInvalid && classes.errorBorder,
          disabled && classes.disabled,
          pickerStyles.picker
        )}
        format={format || dateFormat}
        id="date-picker-inline"
        views={views}
        disabled={disabled}
        value={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage=""
        maxDateMessage=""
        autoOk={true}
        onChange={(date) => handleDateChange(date, name)}
        labelFunc={labelFunc}
        renderDay={renderDay}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        TextFieldComponent={TextFieldComponent}
        {...(validationErrors && validationErrors[name] ? { error: true } : {})}
        {...(validationErrors && validationErrors[name]
          ? {
              helperText: <span className={classes.errorMessage}>{validationErrors[name]}</span>
            }
          : {})}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
