import React from 'react';
import styles from './FormInputText.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { reformatInputValue, reformatNumberValue } from 'helpers/AppHelpers';

export default function FormInputText({ name, onChange, max, rule, focus, type }) {
  const { control } = useFormContext();

  const handleInput = (e) => {
    let value = e.target.value;
    if (value) {
      value =
        type === 'quantity' ? reformatNumberValue(value) : reformatInputValue(value, max, rule);
    }
    onChange(name, value);
  };

  const handleFocus = (event) => event.target.select();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.inputWrapper}>
          <TextField
            className={clsx(styles.textInput, error && styles.error)}
            variant="outlined"
            {...field}
            onChange={handleInput}
            onFocus={focus && handleFocus}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
