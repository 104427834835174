import React from 'react';
import styles from './components.module.scss';
import AutocompleteCheckbox from './AutocompleteCheckbox';
import clsx from 'clsx';

export default function AutocompleteRenderOption({ label, selected, OptionLabelComponent }) {
  return (
    <div className={clsx(styles.render_option, selected && styles.selected)}>
      <AutocompleteCheckbox checked={selected} />
      {OptionLabelComponent ? OptionLabelComponent : <label>{label}</label>}
    </div>
  );
}
