import { useDispatch } from 'react-redux';
import {
  getUserList,
  clearState,
  deleteUser,
  getActiveLocations,
  getDepartments,
  getPrefixList,
  getRequestOrderRoles,
  getSingleUser,
  getUserRoleList,
  setUserEditTab,
  setUserListFilter,
  getFilterCriteria,
  updateUser
} from 'actions/userActions';
import { getUserConfig, setUnsavedFormData } from 'actions/commonActions';

export function useUserActions() {
  const dispatch = useDispatch();

  const getUserListAction = (q) => dispatch(getUserList(q));

  const getUserAction = (id) => dispatch(getSingleUser(id));
  const updateUserAction = (data, options) => dispatch(updateUser(data, options));
  const deleteUserAction = (id) => dispatch(deleteUser(id));

  const setFilterAction = (data) => dispatch(setUserListFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const clearStateAction = () => dispatch(clearState());

  const getActiveLocationsAction = (q) => dispatch(getActiveLocations(q));
  const getUserRolesAction = (q) => dispatch(getUserRoleList(q));
  const getDepartmentsAction = (q) => dispatch(getDepartments(q));
  const getRequestOrderRolesAction = (q) => dispatch(getRequestOrderRoles(q));
  const getPrefixListAction = (q) => dispatch(getPrefixList(q));

  const getUserConfigAction = () => dispatch(getUserConfig());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const setUserEditTabAction = (tab) => dispatch(setUserEditTab(tab));

  return {
    getUserAction,
    getUserListAction,
    updateUserAction,
    deleteUserAction,
    getFilterCriteriaAction,
    setFilterAction,
    getActiveLocationsAction,
    getUserRolesAction,
    getDepartmentsAction,
    setUnsavedFormDataAction,
    getUserConfigAction,
    getRequestOrderRolesAction,
    setUserEditTabAction,
    getPrefixListAction,
    clearStateAction
  };
}
