import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import MainButton from 'components/StyledComponents/MainButton';
import { SUCCESS, ALERT, BLOCK } from 'constants/infoPopupsData';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import blockedIco from 'assets/images/login/blocked.svg';
import styles from './InfoPopup.module.scss';

export default function InfoPopup({ data, onClose }) {
  return (
    <Dialog
      open={data.isOpened}
      classes={{
        paper: styles.popupContainer,
        scrollPaper: styles.backboardContainer
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop
        }
      }}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title">
      <DialogTitle
        classes={{
          root: styles.popupTitle
        }}
        id="alert-dialog-slide-title">
        {data.type && (
          <span
            className={`${styles.infoIcon} ${
              data.type === SUCCESS
                ? styles.success
                : data.type === ALERT
                ? styles.alert
                : ''
            }`}>
            {data.type === SUCCESS && <CheckCircleIcon />}
            {data.type === ALERT && <WarningIcon />}
            {data.type === BLOCK && <img src={blockedIco} alt="" />}
          </span>
        )}
        {data.title}
      </DialogTitle>
      <DialogActions
        classes={{
          root: styles.popupButton
        }}>
        <MainButton text={data.buttonText} action={onClose} type="primary" size="popup" />
      </DialogActions>
    </Dialog>
  );
}
