import React from 'react';
import styles from './components.module.scss';
import { MainButton } from 'components/StyledComponents';

export default function Footer({ onReset, onGenerate, isDisabled }) {
  const handleGenerateClick = () => onGenerate();
  const handleResetClick = () => onReset();

  return (
    <section className={styles.footer}>
      <MainButton text="Reset filter" action={handleResetClick} type="secondary" size="popup" />
      <MainButton
        text="Generate"
        action={handleGenerateClick}
        type="primary"
        size="popup"
        isDisabled={isDisabled}
      />
    </section>
  );
}
