import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import { FormDayPicker, FormLabel, FormSearchInput } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { FormProvider, useForm } from 'react-hook-form';

import {
  useTeamMemberDashboardSelector,
  useTeamMemberDashboardActions
} from 'hooks/TeamMemberDashboard';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

export default function Filter({ open, setOpen, filter }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: {
      department: {},
      projects: [],
      capacities: [],
      dates: [{ date: null }, { date: null }]
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, formState, setError, clearErrors } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { filterCriteria } = useTeamMemberDashboardSelector();
  const { getDashboardConfigAction, getLevelsDashboardAction, updateSectionConfigAction } =
    useTeamMemberDashboardActions();

  const { teamMemberDepartments, teamMemberCapacities, projects } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter);
    }
  }, [open]);

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('department')?.id) {
      setError('department', getErrorsProperties('Department field is required'));
      isFormValid = false;
    }
    if (!getValues('dates')?.[0]?.date) {
      setError('dates[0].date', getErrorsProperties('Duration 1 field is required'));
      isFormValid = false;
    }

    if (!getValues('dates')?.[1]?.date) {
      setError('dates[1].date', getErrorsProperties('Duration 2 field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const closePopup = () => setOpen(false);

  const applyFilter = async () => {
    if (!validateForm()) return;

    const values = getValues();

    const response = await updateSectionConfigAction(values);

    if (!isSuccessfulStatus(response?.status)) return;

    closePopup();

    getDashboardConfigAction({ dashboardType: values.dashboardType }).then(() => {
      getLevelsDashboardAction();
    });
  };

  const clearFilter = () =>
    reset({
      ...getValues(),
      department: {},
      projects: [],
      capacities: [],
      dates: [{ date: null }, { date: null }]
    });

  const checkError = (name) => errors?.[name]?.message && clearErrors(name);

  const selectDepartment = (name, value) => {
    checkError(name);
    setValue(name, value, { shouldDirty: true });
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel required>Department</FormLabel>
                <FormSearchInput
                  name="department"
                  options={teamMemberDepartments || []}
                  onSelect={selectDepartment}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Project</FormLabel>
                <FormAutoMultiSelect
                  name="projects"
                  menuItems={projects || []}
                  options={{
                    labelType: 'project',
                    disableByObjectTracker: false,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Capacity</FormLabel>
                <FormAutoMultiSelect
                  name="capacities"
                  menuItems={teamMemberCapacities || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: false,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel required>Duration 1</FormLabel>
                <FormDayPicker name="dates[0].date" showWeekPicker />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel required>Duration 2</FormLabel>
                <FormDayPicker name="dates[1].date" showWeekPicker />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions
          onClear={clearFilter}
          onCancel={closePopup}
          onApply={applyFilter}
          isApplyDisabled={isFormInvalid}
        />
      </div>
    </DialogWrapper>
  );
}
