import styles from './DialogStyles.module.scss';
import { Dialog, Fade } from '@material-ui/core';
import React, { forwardRef } from 'react';
import Slide from '@material-ui/core/Slide';

const PopupClasses = { paper: styles.popupContainer };
const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={0} />;
});

const ConfirmPopupClasses = { paper: styles.confirmPopupContainer };
const ConfirmPopupTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogWrapper({ open, onClose, isConfirmPopup, children }) {
  return (
    <Dialog
      open={open}
      classes={isConfirmPopup ? ConfirmPopupClasses : PopupClasses}
      BackdropProps={{ classes: { root: styles.backDrop } }}
      TransitionComponent={isConfirmPopup ? ConfirmPopupTransition : Transition}
      keepMounted
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}>
      {children}
    </Dialog>
  );
}
