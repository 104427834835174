import React from 'react';
import styles from './components.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import { responsibleForDeliveryFormConfig } from './formConfigs';
import clsx from 'clsx';
import { useMobileViewport } from 'hooks/useMobileViewport';

function ResponsibleForDelivery() {
  const isMobile = useMobileViewport();

  return (
    <AccordionWrapper title="Responsible for Delivery">
      <div className={styles.delivery}>
        {responsibleForDeliveryFormConfig.map(({ id, name, date, phone }) => (
          <div className={styles.delivery_container} key={id}>
            <div className={styles.block}>
              <div className={styles.block__row}>
                <label className={clsx(isMobile && styles.bold)}>{name.label}</label>
                <FormInputText name={name.param} options={{ max: name.max, rule: name.rule }} />
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.block__row}>
                <label>{date.label}</label>
                <FormDatePicker name={date.param} placement={date.position} />
              </div>
              <div className={styles.block__row}>
                <label>{phone.label}</label>
                <FormInputText name={phone.param} options={{ max: phone.max, rule: phone.rule }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </AccordionWrapper>
  );
}

export default ResponsibleForDelivery;
