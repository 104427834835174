import React from 'react';
import clsx from 'clsx';
import styles from './FilterComponents.module.scss';
import FilterIcon from '../../assets/images/filter.svg';
import { IconButton } from '@material-ui/core';

export default function FilterButton({ iconRef, onClick, onChangeColor }) {
  const handleFilterOpen = () => onClick();
  const shouldItLightUp = () => onChangeColor();

  return (
    <IconButton
      className={clsx(styles.wrapper__button, shouldItLightUp() && styles.wrapper__button_opened)}
      onClick={handleFilterOpen}
      component="span"
      ref={iconRef}>
      <img src={FilterIcon} alt="filter" />
    </IconButton>
  );
}
