import React from 'react';
import { BASE_ADDRESS } from 'api/api';
import styles from './LinkComponent.module.scss';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

export default function LinkComponent({ path, name, isRedirectAvailable = true, boldText }) {
  const history = useHistory();

  const handleLinkClick = (event) => {
    event.preventDefault();
    if (isRedirectAvailable) {
      history.push(path);
    }
  };

  return (
    <Link
      href={`${BASE_ADDRESS}${path}`}
      onClick={handleLinkClick}
      className={clsx(
        styles.rowName,
        isRedirectAvailable && styles.underline,
        !!boldText && styles.bold
      )}>
      {name}
    </Link>
  );
}
