import { useDispatch } from 'react-redux';

import {
  getLocationsByAssets,
  getAssetFields,
  getTicketTypes,
  getRootCauses,
  getSublocations,
  updateAssetsStatus,
  createTickets,
  updateAssetFields,
  assignAssetsToLocation,
  getCreationForm,
  setTab,
  clearState
} from 'actions/batchAssetUpdatesActions';
import { setAssetsInspectionStatus } from 'actions/assetActions';

export function useBatchAssetUpdatesActions() {
  const dispatch = useDispatch();

  const setTabAction = (tab) => dispatch(setTab(tab));

  const getCreationFormAction = () => dispatch(getCreationForm());

  const getLocationsByAssetsAction = (q) => dispatch(getLocationsByAssets(q));

  const getTicketTypesAction = (q) => dispatch(getTicketTypes(q));

  const getRootCausesAction = (q) => dispatch(getRootCauses(q));

  const createTicketsAction = (data) => dispatch(createTickets(data));

  const getSublocationsAction = (id) => dispatch(getSublocations(id));

  const assignAssetsToLocationAction = (data) => dispatch(assignAssetsToLocation(data));

  const getAssetFieldsAction = (q) => dispatch(getAssetFields(q));

  const setAssetsInspectionStatusAction = (data) => dispatch(setAssetsInspectionStatus(data));

  const updateAssetsStatusAction = (data) => dispatch(updateAssetsStatus(data));

  const updateFieldsAction = (data) => dispatch(updateAssetFields(data));

  const clearStateAction = (formValues) => dispatch(clearState(formValues));

  return {
    getCreationFormAction,
    setTabAction,
    getSublocationsAction,
    assignAssetsToLocationAction,
    getTicketTypesAction,
    getRootCausesAction,
    createTicketsAction,
    getLocationsByAssetsAction,
    setAssetsInspectionStatusAction,
    updateAssetsStatusAction,
    getAssetFieldsAction,
    updateFieldsAction,
    clearStateAction
  };
}
