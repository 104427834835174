import React, { useEffect } from 'react';

import { SummaryWrapper, SummaryHeader } from 'components/SummaryComponents';

import DashboardTabs from './components/Tabs';
import VerificationStatistic from './components/VerificationStatistic';
import InspectionStatistic from './components/InspectionStatistic';

import { useAssetsDashboardsActions, useAssetsDashboardsSelector } from 'hooks/AssetsDashboards';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { useHistory } from 'react-router-dom';

import { ROOT_PATH } from 'constants/routeConstants';

import { defaultTab, dashboardTypes } from './helpers';

export default function AssetsDashboards() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { tab } = useAssetsDashboardsSelector();
  const { setTabAction, getFilterCriteriaAction, clearStateAction } = useAssetsDashboardsActions();

  useEffect(() => {
    if (!tab) setTabAction(defaultTab);
  }, []);

  useEffect(() => {
    getFilterCriteriaAction();
  }, []);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <SummaryWrapper>
      <SummaryHeader title="Assets Verification & Inspection Dashboards" />

      <DashboardTabs />

      {tab === dashboardTypes.verification && <VerificationStatistic />}

      {tab === dashboardTypes.inspection && <InspectionStatistic />}
    </SummaryWrapper>
  );
}
