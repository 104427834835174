import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getAssetsListApi = (query) =>
  api.get(`/Assets?${queryToString(query)}`).then((res) => res.data);

export const getAssetsCountingApi = (query) =>
  api.get(`/Assets/Counting?${queryToString(query)}`).then((res) => res.data);

export const updateAssetFromListApi = (params) =>
  api.put(`/Assets`, params).then((res) => res.data);

export const getAssetApi = (id) => api.get(`/Asset?id=${id}`).then((res) => res.data);

export const createAssetApi = (params) => api.post(`/Asset`, params).then((res) => res.data);

export const updateAssetApi = (params) => api.put(`/Asset`, params).then((res) => res.data);

export const deleteAssetApi = (id) => api.delete(`/Asset?id=${id}`).then((res) => res.data);

export const getAssetsTableFieldsApi = (query) =>
  api.get(`/AssetFields?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = () => api.get(`/Assets/FilterCriteria`).then((res) => res.data);

export const getCreationFormApi = () => api.get(`/Asset/CreationForm`).then((res) => res.data);

export const getAssetInspectionStatusesApi = () =>
  api.get(`/InspectionStatuses`).then((res) => res.data);

export const setAssetInspectionStatusApi = (status) =>
  api.post(`/Asset/InspectionStatus`, status).then((res) => res.data);

export const setAssetsInspectionStatusApi = (status) =>
  api.post(`/Assets/InspectionStatus`, status).then((res) => res.data);

export const getLocationHistoryDataApi = (query) =>
  api.get(`/AssetLocationHistory?${queryToString(query)}`).then((res) => res.data);

export const getInspectionHistoryDataApi = (query) =>
  api.get(`/AssetInspectionHistory?${queryToString(query)}`).then((res) => res.data);

export const getVerificationHistoryDataApi = (query) =>
  api.get(`/AssetVerificationHistory?${queryToString(query)}`).then((res) => res.data);

export const getAvailableDRCodeApi = (query) =>
  api.get(`/Asset/DrCode?${queryToString(query)}`).then((res) => res.data);

export const searchUserByStringApi = (query) => api.get(`/User/Search?${queryToString(query)}`);

export const getProjectsApi = () => api.get('/ShortedProjects').then((res) => res.data);

export const getTicketsListApi = (query) =>
  api.get(`/ServiceTickets?${queryToString(query)}`).then((res) => res.data);

export const updateAssetFieldApi = (data) =>
  api.put('/Assets/AssetFields', data).then((res) => res.data);

// Batch Asset Update + Pick-/Leavelist details
export const searchAssetByStringApi = (query) => api.get(`/Asset/Search?${queryToString(query)}`);

export const searchAssetsByDRCodesApi = (query) =>
  api.get(`/Asset/SearchByDrCode?${queryToString(query)}`);
