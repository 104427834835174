import api from '../api';
import {
  CLEAR_EMPLOYMENT_STATUSES_STATE,
  SET_EMPLOYMENT_STATUSES
} from '../constants/reduceConstants';

export const getEmploymentStatuses = () => (dispatch) =>
  api.employmentStatusEditor
    .getEmploymentStatusesApi()
    .then((res) => dispatch({ type: SET_EMPLOYMENT_STATUSES, payload: res }))
    .catch((err) => console.log(err));

export const createEmploymentStatus = (data) => () =>
  api.employmentStatusEditor.createEmploymentStatusApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      // dispatchSnackbar(dispatch, ERROR_UPDATE, errors, true);
      return errors;
    }
  );

export const updateEmploymentStatus = (data) => () => {
  return api.employmentStatusEditor.updateEmploymentStatusApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      // dispatchSnackbar(dispatch, ERROR_UPDATE, errors, true);
      return errors;
    }
  );
};

export const deleteEmploymentStatus = (id) => () =>
  api.employmentStatusEditor
    .deleteEmploymentStatusApi(id)
    .then((res) => res)
    .catch((err) => console.log(err));

export const clearEmploymentStatusesState = () => (dispatch) => {
  dispatch({ type: CLEAR_EMPLOYMENT_STATUSES_STATE });
};
