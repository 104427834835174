import React, { useState } from 'react';

import DetailsHeader from 'components/DetailsComponents/Header';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import DialogPopup from 'components/DialogPopup/DialogPopup';

import CreateIcon from '@material-ui/icons/Create';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import restoreIcon from 'assets/images/restore.svg';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory, useParams } from 'react-router-dom';

import { ASSET_EDIT_PATH, ASSETS_LIST_PATH } from 'constants/routeConstants';
import { DELETE_ASSET, RESTORE_ASSET, RESTORE_ASSET_TYPE } from 'constants/dialogPopupsData';

import AssetTitle from './Title';
import AssetNavigation from './Navigation';

export default function AssetDetailsHeader() {
  const { id } = useParams();
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { isAdminUser } = useUserConfig();

  const { restoreAssetAction, deleteAssetAction } = useAssetActions();
  const { currentAsset } = useAssetSelector();
  const { deleted } = currentAsset;

  const [modalData, setModalData] = useState({});

  const editAsset = () => history.push(`${ASSET_EDIT_PATH}/${id}`);
  const deleteAsset = () => setModalData(DELETE_ASSET);
  const restoreAsset = () => setModalData(RESTORE_ASSET);
  const goBack = () => history.push(ASSETS_LIST_PATH);

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    switch (modalData.type) {
      case RESTORE_ASSET_TYPE:
        closeModal();
        restoreAssetAction({ ...currentAsset, deleted: false });
        break;
      default:
        deleteAssetAction(id);
        break;
    }
  };

  return (
    <DetailsHeader TitleComponent={<AssetTitle />} BreadCrumbsComponent={<AssetNavigation />}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      {!deleted && (
        <HeaderButton onClick={editAsset}>
          <CreateIcon />
          {!isMobile && <span>Edit this asset</span>}
        </HeaderButton>
      )}
      {isAdminUser && !deleted && (
        <HeaderButton onClick={deleteAsset}>
          <DeleteIcon />
          {!isMobile && <span>Remove this asset</span>}
        </HeaderButton>
      )}
      {isAdminUser && deleted && (
        <HeaderButton onClick={restoreAsset}>
          <img src={restoreIcon} alt="restore" />
          {!isMobile && <span>Restore this asset</span>}
        </HeaderButton>
      )}
      {isMobile && (
        <HeaderButton onClick={goBack}>
          <ChevronLeftIcon />
        </HeaderButton>
      )}
    </DetailsHeader>
  );
}
