import React from 'react';
import styles from './components.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import { Controller, useFormContext } from 'react-hook-form';
import { useLocationSelector } from 'hooks/Location';

import { areDatesAvailable, formatDate, getDatesLabel } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function LocationProjectInformation() {
  const { setValue, getValues } = useFormContext();

  const { projectsList } = useLocationSelector();

  const selectProject = (name, value) => setValue(name, value);

  const enrichProjectOptions = () => {
    if (
      projectsList?.length &&
      getValues('project')?.id &&
      !projectsList.map(({ id }) => id).includes(getValues('project')?.id)
    ) {
      return projectsList.concat(getValues('project')).sort((a, b) => a.id > b.id);
    } else return projectsList;
  };

  return (
    <>
      <h3 className={styles.title}>Add Project Information</h3>
      <div className={styles.details}>
        <div className={clsx(styles.details__block, styles.reduceGap)}>
          <div className={styles.details__block_field}>
            <label>Select project</label>
            <FormSearchInput
              name="project"
              options={enrichProjectOptions()}
              onSelect={selectProject}
            />
          </div>
          <Controller
            name="project"
            render={({ field: { value } }) => (
              <>
                <div className={styles.details__block_project}>
                  <label>Contract Date</label>
                  {value?.contractDate && <div>{formatDate(value.contractDate)}</div>}
                </div>
                <div className={styles.details__block_project}>
                  <label>Contract №</label>
                  {value?.contractNumber && <div>{value.contractNumber || ''}</div>}
                </div>
                <div className={styles.details__block_project}>
                  <label>Mobilization Date</label>
                  {value?.mobilizationDate && <div>{formatDate(value.mobilizationDate)}</div>}
                </div>
                <div className={styles.details__block_project}>
                  <label>Operational Dates</label>
                  {areDatesAvailable(value?.operationalDateFrom, value?.operationalDateTo) && (
                    <div>
                      {getDatesLabel(value['operationalDateFrom'], value['operationalDateTo'])}
                    </div>
                  )}
                </div>
                <div className={styles.details__block_project}>
                  <label>Demobilization Date</label>
                  {value?.demobilizationDate && <div>{formatDate(value.demobilizationDate)}</div>}
                </div>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
}
