import React from 'react';
import styles from './GeoMap.module.scss';
import MapPinIcon from 'assets/images/mapPinIcon.svg';

export default function SelectOnMapButton({ onClick }) {
  return (
    <button className={styles.map_button} onClick={onClick}>
      <img src={MapPinIcon} alt="" />
      Select on the map
    </button>
  );
}
