import React from 'react';
import styles from './FilterBlocks.module.scss';

import FormInputRadio from 'components/FormComponents/FormInputRadio/FormInputRadio';

import { useFormContext } from 'react-hook-form';

import { radios } from 'pages/AssetTickets/Dashboard/helpers';

export default function FilterRadios() {
  const { setValue } = useFormContext();

  const selectRadio = (_, option) => setValue('opened', option.value);

  return (
    <>
      <div className={styles.block__cell}>
        <FormInputRadio
          name="openedObj"
          options={radios}
          onSelectTriggered={selectRadio}
          classes={styles.radios}
        />
      </div>
    </>
  );
}
