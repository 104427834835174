import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import Header from './Header';
import Footer from './Footer';

import DialogPopup from 'components/DialogPopup/DialogPopup';

import { useBatchItemUpdatesActions } from 'hooks/BatchItemUpdates';
import { useFormContext } from 'react-hook-form';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { UPDATE_LOOSE_ITEMS_BATCH_UPDATES } from 'constants/dialogPopupsData';
import { AT_LEAST_ONE_FIELD_ERROR_MESSAGE, CONNECTED_FIELD_ERROR_NAMES } from '../../helpers';

export default function BatchItemUpdatesWrapper({ children }) {
  const { getValues, setError, reset } = useFormContext();

  const [modalData, setModalData] = useState({});

  const {
    getCustomFieldsAction,
    getCategoriesAction,
    getLooseItemsAction,
    updateLooseItemsAction,
    saveFormStateAction,
    clearStateAction
  } = useBatchItemUpdatesActions();

  useEffect(() => {
    getCustomFieldsAction();
  }, []);

  useEffect(() => {
    getCategoriesAction();
  }, []);

  useEffect(() => {
    getLooseItemsAction();
  }, []);

  useEffect(() => {
    return () => {
      saveFormStateAction(getValues());
    };
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const validateForm = () => {
    let isFormValid = true;
    const values = getValues();

    if (!values?.looseItems?.length) {
      setError('looseItems', getErrorsProperties('Loose items are required'));
      isFormValid = false;
    }
    if (!values?.looseItemCategories?.length && !values?.customFields?.length) {
      CONNECTED_FIELD_ERROR_NAMES.forEach((name) =>
        setError(name, getErrorsProperties(AT_LEAST_ONE_FIELD_ERROR_MESSAGE))
      );
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleSuccess = (res) => {
    res.status === 200 && reset();
    closeModal();
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => updateLooseItemsAction(getValues()).then(handleSuccess);

  const handleUpdateClick = () => {
    if (!validateForm()) return;

    setModalData(UPDATE_LOOSE_ITEMS_BATCH_UPDATES);
  };

  return (
    <div className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <Header />
      {children}
      <Footer onUpdate={handleUpdateClick} />
    </div>
  );
}
