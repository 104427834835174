import {
  SET_LOOSE_ITEMS_FOR_ADD_LOOSE_ITEMS_POPUP,
  CLEAR_ADD_LOOSE_ITEMS_POPUP_STATE,
  SET_LOCATIONS_FOR_ADD_LOOSE_ITEMS_POPUP,
  SET_PICKLISTS_FOR_ADD_LOOSE_ITEMS_POPUP
} from 'constants/reduceConstants';

const initialState = {
  looseItems: [],
  locations: [],
  listOfPicklist: []
};

const addLooseItemsPopupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOOSE_ITEMS_FOR_ADD_LOOSE_ITEMS_POPUP:
      return { ...state, looseItems: payload };
    case SET_PICKLISTS_FOR_ADD_LOOSE_ITEMS_POPUP:
      return { ...state, listOfPicklist: payload };
    case SET_LOCATIONS_FOR_ADD_LOOSE_ITEMS_POPUP:
      return { ...state, locations: payload };
    case CLEAR_ADD_LOOSE_ITEMS_POPUP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default addLooseItemsPopupReducer;
