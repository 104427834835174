import React from 'react';
import styles from './RequestOrderWrapper.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import { useFormContext } from 'react-hook-form';

export default function Header({ onClick }) {
  const { formState } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const handleClick = () => onClick();

  return (
    <section className={styles.footer}>
      <MainButton
        text="submit request"
        type="primary"
        action={handleClick}
        isDisabled={isFormInvalid()}
      />
    </section>
  );
}
