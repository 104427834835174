import React, { useState } from 'react';
import styles from './Wrapper.module.scss';

import Header from './Header';
import Footer from './Footer';

import { useFormContext } from 'react-hook-form';
import { useCreateAssetsBatchActions } from 'hooks/CreateBatchOfAssets';
import { useHistory } from 'react-router-dom';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import {
  EXIT_WITHOUT_SAVING_BY_NAV,
  UPDATE_BATCH_OF_ASSETS_UPDATES,
  WITHOUT_SAVING_BY_NAV_TYPE
} from 'constants/dialogPopupsData';
import { ASSETS_LIST_PATH } from 'constants/routeConstants';

export default function PreviewWrapper({ children }) {
  const history = useHistory();
  const [modalData, setModalData] = useState({});

  const { getValues } = useFormContext();

  const { updateAssetsAction, toggleScreenAction } = useCreateAssetsBatchActions();

  const handleApproveClick = () => setModalData(UPDATE_BATCH_OF_ASSETS_UPDATES);

  const agreeModal = () => {
    if (modalData.type === WITHOUT_SAVING_BY_NAV_TYPE) {
      history.push(ASSETS_LIST_PATH);
      return;
    }
    updateAssetsAction(getValues('assets')).then((res) => {
      if (![200, 201].includes(res.status)) {
        toggleScreenAction();
      }
    });
  };

  const closeModal = () => setModalData({});

  const goBack = () => toggleScreenAction();

  const handleCrumbClick = (crumb) => {
    if (crumb.name === 'Asset Summary') {
      setModalData({ ...EXIT_WITHOUT_SAVING_BY_NAV, isOpened: true });
    } else {
      goBack();
    }
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <Header onLinkClick={handleCrumbClick} />
      {children}
      <Footer onApprove={handleApproveClick} onBack={goBack} />
    </div>
  );
}
