import React, { useRef, useState } from 'react';
import styles from './Content.module.scss';

import { StickyTableCell } from 'components/StyledComponents';
import PopperWrapper from './PopperWrapper';
import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function TotalOnRentCell() {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const hide = () => setOpen(false);

  const iconClick = () => setOpen(!open);

  return (
    <StickyTableCell className={styles.totalTitle} colSpan={2}>
      <PopperWrapper open={open} setOpen={setOpen} iconRef={iconRef} size="large">
        <div className={styles.content}>
          <div className={styles.content__row}>
            <b>Total On Rent</b> is <b>Fields values sum</b> of <b>owned assets</b> that have
            following characteristic:
          </div>
          <div className={styles.content__row}>
            Rental status: <b>Rented</b>
          </div>
          <div className={styles.content__row}>
            Status: <b>Operational / Non-Critical / Critical</b>
          </div>
          <div className={styles.content__row}>
            Site type: <b>Storage Yard / Job Site</b>
          </div>
        </div>
      </PopperWrapper>

      <div className={styles.wrapper} onBlur={hide} tabIndex="0">
        TOTAL ON RENT
        <IconButton ref={iconRef} onClick={iconClick}>
          <InfoOutlinedIcon />
        </IconButton>
      </div>
    </StickyTableCell>
  );
}
