import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getDashboardConfigApi = (query) =>
  api.get(`/User/ServiceTicketsDashboardsConfig?${queryToString(query)}`).then((res) => res.data);

export const updateTabConfigApi = (data) =>
  api.put('/User/ServiceTicketsDashboardsConfig', data).then((res) => res);

export const updateSectionConfigApi = (data) =>
  api.put('/User/ServiceTicketsDashboardConfig', data).then((res) => res);

export const getFilterCriteriaApi = () =>
  api.get('/ServiceTicketDashboards/FilterCriteria').then((res) => res.data);

export const getBaseStatisticsApi = () =>
  api.get('/ServiceTicket/TicketsBaseStatisticDashboards').then((res) => res.data);

export const getAssetOptionsApi = (query) =>
  api.get(`/Asset/Search?${queryToString(query)}`).then((res) => res.data);

export const getTurnoverStatisticsApi = () =>
  api.get('/ServiceTicket/AverageTicketTurnoverDashboards').then((res) => res.data);

export const getFlowStatisticsApi = () =>
  api.get('/ServiceTicket/TicketFlowStatisticDashboards').then((res) => res.data);

export const getBasicStatisticCsvResourceApi = (q) =>
  api
    .get(`/ServiceTicket/SerializedTicketsBaseStatisticDashboard?${queryToString(q)}`)
    .then((res) => res.data);

export const getTurnoverStatisticCsvResourceApi = (q) =>
  api
    .get(`/ServiceTicket/SerializedAverageTicketTurnoverDashboard?${queryToString(q)}`)
    .then((res) => res.data);

export const getFlowStatisticCsvResourceApi = (q) =>
  api
    .get(`/ServiceTicket/SerializedTicketFlowStatisticDashboard?${queryToString(q)}`)
    .then((res) => res.data);
