import React, { useEffect } from 'react';
import styles from './EditEntityPopup.module.scss';
import { MainButton } from 'components/StyledComponents';
import { getDefaultState, getObjWithoutEmptyValues } from '../../../helpers';
import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { FormProvider, useForm } from 'react-hook-form';
import { FormLabel } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import { getErrorsProperties } from 'helpers/ErrorValidator';

function EditEntityPopup({
  open,
  setOpen,
  data,
  setData,
  onApply,
  permissions,
  shouldClearCheckedState,
  displayClearButton
}) {
  const methods = useForm({ defaultValues: {}, mode: 'onChange' });
  const { getValues, reset, setError, formState } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const {
    isQuantityFieldAvailable,
    isTransportCostFieldAvailable,
    isTransportTimelineFieldAvailable,
    isPurchaseCostFieldAvailable,
    isPurchaseTimelineFieldAvailable,
    isTransportQuantityFieldAvailable,
    isPurchaseQuantityFieldAvailable,
    isNoteFieldAvailable,
    isNoteAfterApprovalFieldAvailable,
    isFileFieldAvailable,
    isPictureFieldAvailable
  } = permissions;

  useEffect(() => {
    open && reset(data.rowData);
  }, [open]);

  const isSingleEntity = () => data?.entityType === 'single';

  const closeModal = () => {
    setOpen(false);
    setData({});
    reset({});
  };

  const validateForm = () => {
    let isFormValid = true;

    const qty = getValues('quantity');

    if (isQuantityFieldAvailable) {
      if (typeof qty !== 'number') {
        setError('quantity', getErrorsProperties('Field is required'));
        isFormValid = false;
      }
      if (typeof qty === 'number' && qty < 1) {
        setError('quantity', getErrorsProperties(`Can't be less than 1`));
        isFormValid = false;
      }
    }

    return isFormValid;
  };

  const handleApply = () => {
    if (!validateForm()) return;

    shouldClearCheckedState.current = false;
    if (isSingleEntity()) {
      onApply(getValues(), 'single');
    } else {
      const mapFunc = (el) => {
        if (isNoteAfterApprovalFieldAvailable) {
          return { ...el, notesAfterApproval: getValues('notesAfterApproval') };
        } else {
          return { ...el, ...getObjWithoutEmptyValues(getValues()) };
        }
      };
      const mappedData = data.checkedEntities.map(mapFunc);
      onApply(mappedData, 'multiple');
    }
    closeModal();
  };

  const handleClear = () => {
    shouldClearCheckedState.current = false;
    if (isSingleEntity()) {
      reset({ ...getValues(), ...getDefaultState() });
    } else {
      const data = [...data.checkedEntities.map((el) => ({ ...el, ...getDefaultState() }))];
      setData({ ...data, checkedEntities: data });
    }
  };

  return (
    <DialogWrapper open={open} onClose={closeModal}>
      <div className={styles.popup}>
        <FormProvider {...methods}>
          <DialogHeader onClose={closeModal} title="Set parameters" />
          <div className={styles.form}>
            {isQuantityFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel required>Entity Quantity</FormLabel>
                <FormInputText name="quantity" options={{ type: 'quantity', focus: true }} />
              </div>
            )}
            {isTransportTimelineFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Transportation Timeline</FormLabel>
                <FormInputText name="transportationTimeline" options={{ max: 20 }} />
              </div>
            )}
            {isTransportCostFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Transportation Cost</FormLabel>
                <FormInputText name="transportationCost" options={{ max: 20 }} />
              </div>
            )}

            {isTransportQuantityFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Transportation QTY</FormLabel>
                <FormInputText name="transportationQTY" options={{ max: 20 }} />
              </div>
            )}

            {isPurchaseTimelineFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Purchasing Timeline</FormLabel>
                <FormInputText name="purchasingTimeline" options={{ max: 20 }} />
              </div>
            )}
            {isPurchaseCostFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Purchasing Cost</FormLabel>
                <FormInputText name="purchasingCost" options={{ max: 20 }} />
              </div>
            )}
            {isPurchaseQuantityFieldAvailable && (
              <div className={styles.form__row}>
                <FormLabel>Purchasing QTY</FormLabel>
                <FormInputText name="purchasingQTY" options={{ max: 20 }} />
              </div>
            )}

            {isNoteFieldAvailable && (
              <div className={styles.form__column}>
                <FormLabel>Notes</FormLabel>
                <FormTextArea name="notes" options={{ max: 2000 }} />
              </div>
            )}

            {isNoteAfterApprovalFieldAvailable && (
              <div className={styles.form__column}>
                <FormLabel>Notes after Request Order approval</FormLabel>
                <FormTextArea name="notesAfterApproval" options={{ max: 2000 }} />
              </div>
            )}
            {isPictureFieldAvailable && (
              <div className={styles.form__upload}>
                <FormLabel>Picture</FormLabel>
                <FormImagesUpload />
              </div>
            )}
            {isFileFieldAvailable && (
              <div className={styles.form__upload}>
                <FormLabel>File</FormLabel>
                <FormFilesUpload />
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.footer__main}>
              <MainButton text="Cancel" action={closeModal} type="secondary" size="popup" />
              <MainButton
                text="Apply"
                action={handleApply}
                type="primary"
                size="popup"
                isDisabled={isFormInvalid}
              />
            </div>
            {displayClearButton && (
              <div className={styles.footer__extra}>
                <MainButton text="Clear" action={handleClear} type="extra" size="popup" />
              </div>
            )}
          </div>
        </FormProvider>
      </div>
    </DialogWrapper>
  );
}

export default EditEntityPopup;
