import React from 'react';
import styles from './styles.module.scss';

import WarningIcon from '@material-ui/icons/Warning';

export const SidebarError = ({ message }) => (
  <div className={styles.error}>
    <div className={styles.error__icon}>
      <WarningIcon />
    </div>
    <div className={styles.error__message}>{message}</div>
  </div>
);
