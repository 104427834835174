import { createSelector } from 'reselect';

const batchData = (state) => state.batchItemUpdatesData;

export const selectLooseItemsData = () => createSelector([batchData], (state) => state.looseItems);

export const selectCustomFieldsData = () =>
  createSelector([batchData], (state) => state.customFields);

export const selectCategoriesData = () => createSelector([batchData], (state) => state.categories);

export const selectFormValuesData = () => createSelector([batchData], (state) => state.formState);
