import React from 'react';
import styles from './Details.module.scss';
import GeneralDetails from './GeneralDetails';
import StuffInfo from './StuffInfo';
import FilesInfo from './FilesInfo';
import AddressInfo from './AddressInfo';

export default function Details() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.left}>
        <GeneralDetails />
        <AddressInfo />
        <StuffInfo />
      </div>
      <div className={styles.right}>
        <FilesInfo />
      </div>
    </section>
  );
}
