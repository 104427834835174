import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styles from './InfoTooltip.module.scss';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import { useMobileViewport } from 'hooks/useMobileViewport';
import clsx from 'clsx';

export default function InfoTooltip({ text, classes }) {
  const isMobile = useMobileViewport();
  const trigger = (
    <div className={styles.trigger}>
      <InfoOutlinedIcon />
    </div>
  );

  const tooltip = (
    <div className={clsx(styles.tooltip, classes && classes)}>
      <span>{text || ''}</span>
    </div>
  );

  return (
    <TooltipWrapper
      trigger={trigger}
      tooltip={tooltip}
      options={{
        trigger: isMobile ? 'click' : 'hover',
        placement: isMobile ? 'bottom-start' : 'right-start'
      }}
    />
  );
}
