import React from 'react';
import styles from './TicketComponents.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import StatusIndicator from 'components/StatusIndicator';
import Subhead from 'components/DetailsComponents/Subhead';
import StyledLink from 'components/StyledLink';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';

export function General() {
  const { currentTicket } = useTicketSelector();

  const {
    openedByUser,
    openedAtUtc,
    ticketType,
    rootCause,
    asset,
    severity,
    assignedToUser,
    assignedAtUtc,
    notificationReminder,
    reopenTicketFrequency,
    reopenOnSpecificDate
  } = currentTicket;

  return (
    <div className={styles.general}>
      <Subhead>General Details</Subhead>

      <div className={styles.general__content}>
        <div className={styles.general__content_block}>
          <DetailedRow
            disableFlex
            label="Asset"
            value={
              <StyledLink to={`${ASSET_DETAILED_PATH}/${asset?.id}`}>
                {asset?.drCode || ''}
              </StyledLink>
            }
          />

          <DetailedRow
            disableFlex
            label="Opened By"
            value={openedByUser?.firstName ? getUserFullName(openedByUser) : ''}
          />

          <DetailedRow
            disableFlex
            label="Opened On"
            value={openedAtUtc ? formatDate(openedAtUtc, dateTimeFormat) : ''}
          />

          <DetailedRow disableFlex label="Ticket Type" value={ticketType?.name || ''} />

          <DetailedRow disableFlex label="Root Cause" value={rootCause?.name || ''} />

          <DetailedRow label="Severity" value={severity?.displayName || ''} alignCenter>
            <StatusIndicator status={severity?.name} leftSpace />
          </DetailedRow>

          {!!ticketType?.reopening && (
            <>
              <DetailedRow
                disableFlex
                label="Reopen Frequency"
                value={reopenTicketFrequency?.displayName || ''}
              />
              {reopenTicketFrequency?.name === 'OneTime' && (
                <DetailedRow
                  disableFlex
                  label="Reopen on specific date"
                  value={reopenOnSpecificDate ? formatDate(reopenOnSpecificDate) : null}
                />
              )}
            </>
          )}
        </div>

        <div className={styles.general__content_block}>
          <DetailedRow
            disableFlex
            label="Assignee"
            value={assignedToUser?.firstName ? getUserFullName(assignedToUser) : ''}
          />
          <DetailedRow
            disableFlex
            label="Due Date"
            value={assignedAtUtc ? formatDate(assignedAtUtc) : ''}
          />
          {assignedToUser?.firstName && assignedAtUtc && (
            <DetailedRow disableFlex label="Notification Reminder" disableColon>
              <div className={styles.checkbox}>
                <StyledCheckbox disabled checked={!!notificationReminder} />
              </div>
            </DetailedRow>
          )}
        </div>
      </div>
    </div>
  );
}
