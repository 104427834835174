import {
  SET_CATEGORY_LIST,
  SET_CURRENT_CATEGORY,
  CLEAR_PREFIX_STATE,
  CLEAR_CATEGORY_LIST_STATE,
  SET_ACTIVE_CATEGORY,
  CLEAR_ACTIVE_CATEGORY,
  SET_CATEGORY_LIST_SEARCH_VALUE
} from 'constants/reduceConstants';

const initialState = {
  categoryList: [],
  currentCategory: {},
  activeCategory: {},
  searchValue: ''
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: payload
      };
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: payload
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: { ...payload }
      };
    case CLEAR_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: {}
      };
    case SET_CATEGORY_LIST_SEARCH_VALUE:
      return {
        ...state,
        searchValue: payload
      };
    case CLEAR_CATEGORY_LIST_STATE:
    case CLEAR_PREFIX_STATE:
      return { ...initialState, searchValue: state.searchValue };
    default:
      return state;
  }
};

export default categoryReducer;
