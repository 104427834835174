export const tableHeadConfig = [
  { id: 1, label: 'ID', isSortable: false },
  { id: 2, label: 'DR-ID', isSortable: true, parameter: 'asset.drCode' },
  { id: 3, label: 'Location', isSortable: true, parameter: 'location.siteCode' },
  { id: 4, label: 'Created', isSortable: true, parameter: 'createdAtUtc' },
  { id: 5, label: 'Submitted By', isSortable: true, parameter: 'submittedByUser.firstName' },
  { id: 6, label: 'Report name', isSortable: true, parameter: 'generalReportTemplate.name' },
  { id: 7, label: 'Report note', isSortable: false },
  { id: 8, label: 'PDF Report', isSortable: false }
];
