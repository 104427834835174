import React from 'react';
import styles from './components.module.scss';

export default function Header() {
  return (
    <section className={styles.header}>
      <h1>Overview Report</h1>
    </section>
  );
}
