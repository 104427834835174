import React from 'react';
import styles from './MatchingLocation.module.scss';

// const asd = {
//   itemType: 'asset' || 'looseItem',
//   documentType: 'picklist' || 'leavelist',
//   endpointType: 'location' || 'sublocation'
// }; todo add propTypes

export default function MatchingLocation({ items, options }) {
  const { itemType, documentType, endpointType } = options;

  const isAsset = itemType === 'asset';
  const isPicklist = documentType === 'picklist';

  const getString = () => items.map((el) => (isAsset ? el.drCode : el.looseItem.name)).join(', ');

  const itemsStr = items?.length ? getString() : '';

  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        {isAsset ? 'Asset(s)' : 'Loose Item(s)'} has the same {endpointType} as the{' '}
        {isPicklist ? 'Picklist' : 'Leavelist'} has.
      </div>
      <div className={styles.modal__content}>
        <div className={styles.modal__content_label}>
          <b>{isAsset ? 'Asset(s)' : 'Loose Item(s)'}:</b> {itemsStr}.
        </div>
      </div>
      <div className={styles.modal__footer}>Do you want to add it?</div>
    </div>
  );
}
