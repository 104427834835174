import { useDispatch } from 'react-redux';

import {
  getTicketTypes,
  getTicketType,
  getCreationForm,
  createTicketType,
  updateTicketType,
  setFilter,
  clearState
} from 'actions/ticketTypesActions';

export function useTicketTypesActions() {
  const dispatch = useDispatch();

  const getTicketTypesAction = (f) => dispatch(getTicketTypes(f));

  const getTicketTypeAction = (q) => dispatch(getTicketType(q));

  const getCreationFormAction = () => dispatch(getCreationForm());

  const createTicketTypeAction = (data) => dispatch(createTicketType(data));
  const updateTicketTypeAction = (data) => dispatch(updateTicketType(data));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    getTicketTypesAction,
    getTicketTypeAction,
    getCreationFormAction,
    createTicketTypeAction,
    updateTicketTypeAction,
    setFilterAction,
    clearStateAction
  };
}
