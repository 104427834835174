import React from 'react';
import styles from './TicketComponents.module.scss';

import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

import Subhead from './Subhead';

export function Description() {
  return (
    <div className={styles.section}>
      <Subhead required>Description</Subhead>
      <div className={styles.container}>
        <div className={styles.container__block}>
          <FormTextArea name="description" options={{ max: 1000 }} />
        </div>
      </div>
    </div>
  );
}
