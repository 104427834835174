import React, { createRef, useEffect } from 'react';
import styles from './AddSignaturePopup.module.scss';

import { allKeyboardLatSymbols, formatDate } from 'helpers/AppHelpers';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import SignaturePanel from 'components/Signature/Panel/SignaturePanel';
import { MainButton } from 'components/StyledComponents';

import { FormProvider, useForm } from 'react-hook-form';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

function AddSignaturePopup({ open, setOpen, data, onApply }) {
  const signatureRef = createRef();

  const methods = useForm({
    defaultValues: {},
    mode: 'onChange'
  });
  const { getValues, setError, formState, reset } = methods;
  const { errors } = formState;

  useEffect(() => {
    if (open) {
      reset({
        ...data.values,
        [data.params[1]]: data.values[data.params[1]]
          ? data.values[data.params[1]]
          : formatDate(new Date())
      });
    }
  }, [open]);

  const handleCancelClick = () => setOpen(false);

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;
  const errorProperties = [
    { name: data.params[0], inputType: 'text', errorMessage: 'Field is required' },
    { name: data.params[1], inputType: 'date-required', errorMessage: 'Field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleApplyClick = () => {
    if (!validateForm()) {
      return;
    }
    onApply({
      ...getValues(),
      [data.params[3]]: signatureRef.current.isEmpty()
        ? null
        : signatureRef.current.getTrimmedCanvas().toDataURL('image/png')
    });
    setOpen(false);
  };

  return (
    <DialogWrapper open={open} onClose={handleCancelClick}>
      <section className={styles.dialog}>
        <DialogHeader onClose={handleCancelClick} title="Put Your Signature" />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.form}>
              <div className={styles.form__item}>
                <label>{data.label}*</label>
                <FormInputText
                  name={data.params[0]}
                  options={{ max: 100, rule: allKeyboardLatSymbols }}
                />
              </div>
              <div className={styles.form__item}>
                <label>Date*</label>
                <FormDatePicker name={data.params[1]} />
              </div>
              <div className={styles.form__item}>
                <label>Phone</label>
                <FormInputText
                  name={data.params[2]}
                  options={{ max: 100, rule: allKeyboardLatSymbols }}
                />
              </div>
            </div>
          </FormProvider>
          <div className={styles.signatureWrapper}>
            <SignaturePanel signatureRef={signatureRef} />
          </div>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={handleCancelClick} type="secondary" />
          <MainButton
            text="Apply"
            action={handleApplyClick}
            type="primary"
            isDisabled={isFormInvalid()}
          />
        </div>
      </section>
    </DialogWrapper>
  );
}

export default AddSignaturePopup;
