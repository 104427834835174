import React from 'react';
import styles from './TicketComponents.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import { pluck, joinWithComma } from 'helpers/AppHelpers';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import Subhead from 'components/DetailsComponents/Subhead';

export  function NecessaryItems() {
  const { currentTicket } = useTicketSelector();

  const { looseItems, assetPrefixes, otherItems } = currentTicket;

  const details = [
    {
      id: 1,
      label: 'Prefix',
      value: joinWithComma(pluck(assetPrefixes, 'prefix')),
      isAvailable: assetPrefixes?.length
    },
    {
      id: 2,
      label: 'Loose Item',
      value: joinWithComma(pluck(looseItems, 'name')),
      isAvailable: !!looseItems?.length
    },
    {
      id: 6,
      label: 'Non-Drat Item Name',
      value: joinWithComma(pluck(otherItems, 'entityName')),
      isAvailable: !!otherItems?.length
    }
  ];

  return !!looseItems?.length || !!assetPrefixes?.length || !!otherItems?.length ? (
    <div className={styles.necessaryItems}>
      <Subhead>Necessary Items</Subhead>

      <div className={styles.necessaryItems__info}>
        {details.map(({ id, label, value, isAvailable }) =>
          isAvailable ? <DetailedRow key={id} label={label} value={value} disableFlex /> : null
        )}
      </div>
    </div>
  ) : null;
}
