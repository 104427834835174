import api from '../api';

export const getEmploymentStatusesApi = () =>
  api.get('/TeamMemberEmploymentTypes').then((res) => res.data);

export const createEmploymentStatusApi = (params) =>
  api.post('/TeamMemberEmploymentType', params).then((res) => res);

export const updateEmploymentStatusApi = (params) =>
  api.put(`/TeamMemberEmploymentType`, params).then((res) => res.status);

export const deleteEmploymentStatusApi = (id) =>
  api.delete(`/TeamMemberEmploymentType?id=${id}`).then((res) => res.status);
