import React from 'react';
import styles from './Buttons.module.scss';

import { Button } from '@material-ui/core';
import clsx from 'clsx';

export default function BlueButton({ label, onClick, height, fullOnMobile, className, children }) {
  return (
    <Button
      style={{ height: height }}
      className={clsx(
        styles.blue_button,
        className && className,
        fullOnMobile && styles.fullOnMobile
      )}
      onClick={onClick}>
      {children}
      <span>{label}</span>
    </Button>
  );
}
