import React from 'react';
import styles from './SidebarButton.module.scss';

export function SidebarButton({ onClick, label, children }) {
  return (
    <button className={styles.sidebar_btn} onClick={onClick}>
      <div className={styles.sidebar_btn__icon}>{children}</div>
      <div className={styles.sidebar_btn__label}>{label}</div>
    </button>
  );
}
