import { dateTimeFormat, formatDate, getAssetFieldValue, getUserFullName } from './AppHelpers';

const getAssetCellTooltip = (asset) => asset?.drCode;

const getAssetStatusTooltip = (asset) =>
  asset.severity?.displayName +
  (asset?.currentInspectionHistory?.inspectionStatus?.name === 'NeedToBeInspected'
    ? '. Needs to be inspected'
    : '');

const getAssetLocationTooltip = (asset) => asset.lastLocationHistory?.location?.siteCode;

const getAssetSublocationTooltip = (asset) => asset.lastLocationHistory?.sublocation?.name;

const getAssetRentalTooltip = (asset) => asset.rental?.name || '';

const getAssetTypeTooltip = (asset) => asset.assetPrefix?.prefixType?.name || '';

const getAssetShortNoteTooltip = (asset) => asset.shortNote || '';

const getAssetFieldTooltip = (fields, columnId) => getAssetFieldValue(fields, columnId);

const getLocationTooltip = (item) => item?.location?.siteCode || '';

const getAssetTooltip = (item) => item?.asset?.drCode || '';

const getTicketTypeTooltip = (item) => item?.type || '';

const getTicketRootCauseTooltip = (item) => item?.rootCause || '';

const getSeverityDisplayNameTooltip = (item) => item?.severity?.displayName || '';

const getDescriptionTooltip = (item) => item?.description || '';

const getOpenedByUserTooltip = (item) => getUserFullName(item.openedByUser);

const getOpenedAtUtcTooltip = (item) =>
  item?.openedAtUtc ? formatDate(item.openedAtUtc, dateTimeFormat) : '';

const getClosedAtUtcTooltip = (item) =>
  item?.closedAtUtc ? formatDate(item.closedAtUtc, dateTimeFormat) : '';

const getReopenDateTooltip = (item) => (item?.reopenDate ? formatDate(item.reopenDate) : '');

export {
  getAssetStatusTooltip,
  getAssetCellTooltip,
  getAssetLocationTooltip,
  getAssetSublocationTooltip,
  getAssetRentalTooltip,
  getAssetTypeTooltip,
  getAssetShortNoteTooltip,
  getAssetFieldTooltip,
  getLocationTooltip,
  getAssetTooltip,
  getTicketTypeTooltip,
  getTicketRootCauseTooltip,
  getSeverityDisplayNameTooltip,
  getDescriptionTooltip,
  getOpenedByUserTooltip,
  getOpenedAtUtcTooltip,
  getClosedAtUtcTooltip,
  getReopenDateTooltip
};
