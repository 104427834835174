import { useSelector } from 'react-redux';

import { selectUserLogsFilterData } from 'pages/UserManagement/UserLogs/selectors';

export function useUserLogsSelector() {
  const filter = useSelector(selectUserLogsFilterData());

  return {
    filter
  };
}
