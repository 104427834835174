import { useDispatch } from 'react-redux';

import { setUnsavedFormData, setLoadMoreFlag, getUserConfig } from 'actions/commonActions';
import { onLogout } from 'actions/authActions';

export function useCommonActions() {
  const dispatch = useDispatch();

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getUserConfigAction = () => dispatch(getUserConfig());

  const setLoadMoreFlagAction = (bool) => dispatch(setLoadMoreFlag(bool));

  const logoutAction = () => dispatch(onLogout());

  return {
    getUserConfigAction,
    setLoadMoreFlagAction,
    setUnsavedFormDataAction,
    logoutAction
  };
}
