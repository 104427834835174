import { createSelector } from 'reselect';

const data = (state) => state.looseItemsAndPartsData;

export const selectTableData = () => createSelector([data], (state) => state.tableData);

export const selectFilterData = () => createSelector([data], (state) => state.listFilter);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

export const selectLocationsData = () => createSelector([data], (state) => state.locationList);

export const selectStatusesData = () => createSelector([data], (state) => state.statusList);

export const selectCategoriesData = () => createSelector([data], (state) => state.categoryList);

export const selectLooseItemsData = () => createSelector([data], (state) => state.looseItems);

export const selectLooseItemFieldsData = () =>
  createSelector([data], (state) => state.looseItemFields);

export const selectSingleItemData = () => createSelector([data], (state) => state.currentItem);

export const selectCreateLocationsData = () =>
  createSelector([data], (state) => state.createLocationList);

export const selectCreateCategoriesData = () =>
  createSelector([data], (state) => state.createCategoryList);

export const selectTotalQuantityData = () => createSelector([data], (state) => state.totalQuantity);
export const selectTotalBaaData = () => createSelector([data], (state) => state.totalBaa);
export const selectCheckedItemsData = () => createSelector([data], (state) => state.checkedItems);

export const selectSelectedColumnsData = () =>
  createSelector([data], (state) => state.customColumns);
