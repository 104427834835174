import React, { forwardRef, useCallback } from 'react';
import styles from './Templates.module.scss';

import { SnackbarContent, useSnackbar } from 'notistack';

import { Card } from '@material-ui/core';

import clsx from 'clsx';

export const SuccessTemplate = forwardRef(function SuccessTemplate({ id, message }, ref) {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Card className={clsx(styles.card, styles.success)} onClick={handleDismiss}>
        <div className={styles.snackbar}>
          <span>{message}</span>
        </div>
      </Card>
    </SnackbarContent>
  );
});
