import React, { useEffect, useRef, useState } from 'react';
import styles from './Common.module.scss';

import {
  Pagination,
  SearchField,
  ControlsWrapper,
  TableHeadComponent,
  TableWrapper,
  NoDataTableRow
} from 'components/SummaryComponents';
import { AddButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import SummaryWrapper from '../SummaryWrapper';

import CommonRecipientsFilter from './components/Filter';
import EditRecipientPopup from './components/EditRecipientPopup';
import DynamicTableRows from './components/DynamicTableRows';

import {
  useNotificationCenterActions,
  useNotificationCenterSelector
} from 'hooks/NotificationCenter';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { Table, TableBody } from '@material-ui/core';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { getTableConfig } from './helpers';
import { getTableParameter } from '../../../helpers';

import { DELETE_NOTIFICATION_RECIPIENT } from 'constants/dialogPopupsData';

export default function CommonRecipients() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const [openPopup, setOpenPopup] = useState(false);

  const [modalData, setModalData] = useState({});

  const [popupData, setPopupData] = useState({});

  const [filter, setFilter] = useState({
    filters: {
      searchQuery: '',
      locationIds: [],
      locationJobNumberIds: [],
      prefixIds: [],
      userRoleIds: [],
      departmentIds: [],
      notificationCenterTab: '',
      isBaaRecipient: false
    },
    pagination: { page: 1, limit: 10, totalPages: 1 },
    sortRules: [],
    responseReceived: false
  });
  const { filters, pagination, sortRules, responseReceived } = filter;

  const [tableState, setTableState] = useState([]);

  const { getTabContent, deleteRecipientAction } = useNotificationCenterActions();
  const { selectedTab } = useNotificationCenterSelector();
  const { isConfigReceived, getTableLimit } = useUserConfig();

  const generateInitialQuery = () => {
    return {
      ...filter,
      filters: { ...filters, notificationCenterTab: selectedTab },
      pagination: { ...pagination, limit: getTableLimit(getTableParameter(selectedTab)) }
    };
  };

  const setUsers = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilter(updatedFilter);
  };

  const getUsers = (query, isAutoload) => {
    setFilter(query);

    getTabContent(query).then((tableData) => {
      setUsers(tableData, query, isAutoload);
    });
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getUsers(generateInitialQuery());
  }, [isConfigReceived]);

  const isTableFiltered =
    filters.searchQuery !== '' ||
    !!filters?.locationIds?.length ||
    !!filters?.locationJobNumberIds?.length ||
    !!filters?.prefixIds?.length ||
    !!filters?.userRoleIds?.length ||
    !!filters?.requestOrderRoleIds?.length ||
    !!filters?.departmentIds?.length ||
    filters.isBaaRecipient;

  const findMatches = (value) => getUsers(getChangedBySearchFilter(filter, value));
  const sortColumn = (rowName) => getUsers(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getUsers(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getUsers(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getUsers(getChangedByLimitFilter(filter, getTableLimit(getTableParameter(selectedTab))));

  const handleAddUserClick = () => setOpenPopup(true);

  const updateTable = () => getUsers(filter);

  const editRecipient = (row) => {
    setPopupData(row);
    setOpenPopup(true);
  };

  const removeRecipient = (userId) => setModalData({ ...DELETE_NOTIFICATION_RECIPIENT, userId });

  const closePopup = () => {
    setPopupData({});
    setOpenPopup(false);
  };

  const agreeModal = () => {
    const deleteData = { userId: modalData.userId, notificationCenterTab: selectedTab };
    deleteRecipientAction(deleteData).then(updateTable);
    closeModal();
  };

  const closeModal = () => setModalData({});

  return (
    <SummaryWrapper>
      {openPopup && (
        <EditRecipientPopup
          open={openPopup}
          data={popupData}
          onClose={closePopup}
          onUpdate={updateTable}
        />
      )}
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      {isMobile && <AddButton onClick={handleAddUserClick}>Add user</AddButton>}
      <ControlsWrapper>
        <CommonRecipientsFilter filter={filter} onApply={applyFilter} />
        <div className={styles.controls}>
          <SearchField filterValue={filters.searchQuery} onSearch={findMatches} />
          {!isMobile && <AddButton onClick={handleAddUserClick}>Add user</AddButton>}
        </div>
      </ControlsWrapper>
      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={getTableConfig(selectedTab)}
            sortRules={sortRules}
            onSortApply={sortColumn}
          />
          <TableBody>
            {tableState?.length ? (
              <DynamicTableRows
                rows={tableState}
                onEdit={editRecipient}
                onRemove={removeRecipient}
              />
            ) : (
              responseReceived && <NoDataTableRow isTableFiltered={isTableFiltered} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <Pagination
        currentPage={pagination.page}
        totalPages={pagination.totalPages}
        selectedLimit={pagination.limit}
        rowsNumber={tableState?.length}
        tableRef={tableRef}
        withOptionAll
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        tableName={getTableParameter(selectedTab)}
      />
    </SummaryWrapper>
  );
}
