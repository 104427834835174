import { useDispatch } from 'react-redux';

import { getUserLogs, setFilter, getUsers, getHistoryEventTypes, clearState } from 'actions/userLogsActions';

export function useUserLogsActions() {
  const dispatch = useDispatch();

  const getUserLogsAction = (f) => dispatch(getUserLogs(f));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const getUsersAction = (q) => dispatch(getUsers(q));
  const getHistoryEventTypesAction = () => dispatch(getHistoryEventTypes());

  const clearStateAction = () => dispatch(clearState());

  return {
    getUserLogsAction,
    setFilterAction,
    getUsersAction,
    getHistoryEventTypesAction,
    clearStateAction
  };
}
