import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLooseItemDashboardConfigApi = () =>
  api.get(`/User/LooseItemDashboardsConfig`).then((res) => res.data);

export const updateLooseItemDashboardConfigApi = (data) =>
  api.put(`/User/LooseItemDashboardsConfig`, data).then((res) => res.data);

export const getLooseItemDashboardTablesDataApi = (query) =>
  api.get(`/LooseItemDashboards${queryToString(query)}`).then((res) => res.data);

export const getLooseItemsLocationsApi = (query) =>
  api.get(`/LocationLooseItems?${queryToString(query)}`).then((res) => res.data);

export const moveItemsApi = (params) => api.put(`/LooseItems/Move`, params).then((res) => res.data);
