import React, { useCallback, useEffect, useState } from 'react';
import styles from './ActiveLocationsMap.module.scss';

import Map from 'components/Map';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';

import {
  useActiveLocationsMapActions,
  useActiveLocationsMapSelector
} from 'hooks/ActiveLocationsMap';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getChangedByApplyFilter } from 'helpers/SummaryPagesHelpers';

import PopupContent from './PopupContent';
import Filter from './Filter';
import SidebarContent from './SidebarContent/Content';
import Header from './Header';
import {
  generateInitialQuery,
  generateChips,
  generateMarkers,
  parseQuery,
  removeChip,
  legend
} from './helpers';
import { LocationItemsPopup } from './SidebarContent/LocationItemsPopup/LocationItemsPopup';

export function ActiveLocationsMap() {
  const isMobile = useMobileViewport();

  const [sidebarData, setSidebarData] = useState({
    openSidebar: false,
    openPopup: false,
    type: '',
    location: {}
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [markers, setMarkers] = useState([]);

  const { isConfigReceived } = useUserConfig();
  const { setFilterAction, getFilterCriteriaAction, getLocationsAction } =
    useActiveLocationsMapActions();
  const { filter, filterCriteria } = useActiveLocationsMapSelector();
  const { filters } = filter;

  const getLocations = useCallback(
    (query) => {
      setFilterAction(query);

      getLocationsAction(parseQuery(query)).then((data) => {
        setLocations(data, query);
      });
    },
    [setFilterAction, getLocationsAction]
  );

  const setLocations = useCallback(
    (data, query) => {
      setMarkers(generateMarkers(data || []));

      const updatedFilter = { ...query, responseReceived: true };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  const closeSidebar = useCallback(
    () => setSidebarData({ openPopup: false, openSidebar: false, type: '', location: {} }),
    [setSidebarData]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const resultFilter = generateInitialQuery(filter);
      getLocations(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    closeSidebar();
  }, [isMobile]);

  const applyFilter = useCallback(
    (values) => {
      closeSidebar();
      getLocations(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, getLocations, filterCriteria, closeSidebar]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip, filterCriteria)),
    [applyFilter, filters, filterCriteria]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const handleItemsClick = useCallback(
    (type, location) =>
      setSidebarData({ openSidebar: !isMobile, openPopup: isMobile, type, location }),
    [setSidebarData]
  );

  return (
    <div className={styles.container}>
      {isMobile && <Header />}

      <Map.Wrapper
        markers={markers}
        popupContent={(info) => <PopupContent info={info} onClick={handleItemsClick} />}>
        <FilterWrapper className={styles.filter}>
          <FilterButton onClick={openFilterModal} isActive={openFilter || chips?.length} />
          {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
        </FilterWrapper>

        <Map.Legend className={styles.legend} markers={legend} />
      </Map.Wrapper>

      {sidebarData.openSidebar && !isMobile && (
        <Map.Sidebar>
          <SidebarContent
            type={sidebarData.type}
            location={sidebarData.location}
            onClose={closeSidebar}
          />
        </Map.Sidebar>
      )}

      {sidebarData.openPopup && isMobile && (
        <LocationItemsPopup
          open={sidebarData.openPopup}
          onClose={closeSidebar}
          type={sidebarData.type}
          location={sidebarData.location}
        />
      )}

      {openFilter && <Filter open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />}
    </div>
  );
}
