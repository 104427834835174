import { createSelector } from 'reselect';

const teamMemberTrackerData = (state) => state.teamMemberTrackerData;

export const selectFilterCriteriaData = () =>
  createSelector([teamMemberTrackerData], (state) => state.filterCriteria);

export const selectMasterFilterData = () =>
  createSelector([teamMemberTrackerData], (state) => state.masterFilterData);

export const selectBreakdownFilterData = () =>
  createSelector([teamMemberTrackerData], (state) => state.breakdownFilterData);

export const selectTrackerResponseReceivedData = () =>
  createSelector([teamMemberTrackerData], (state) => state.isResponseReceived);

export const selectTabSelectedData = () =>
  createSelector([teamMemberTrackerData], (state) => state.selectedToggleTab);

export const selectTrackerTableData = () =>
  createSelector([teamMemberTrackerData], (state) => state.trackerTableData);
