import React from 'react';
import styles from './Home.module.scss';

import * as routes from 'constants/routeConstants';
import { checkAccess, SIDEBAR_TITLES } from 'helpers/AccessCheck';

import { ReactComponent as DashboardIcon } from 'assets/images/dashboards.svg';
import { ReactComponent as AssetSummaryIcon } from 'assets/images/summaryAsset.svg';
import { ReactComponent as LooseItemsIcon } from 'assets/images/loostItems.svg';
import { ReactComponent as BatchUpdatesIcon } from 'assets/images/batchUpdates.svg';
import { ReactComponent as AssetTicketsIcon } from 'assets/images/ticketsAsset.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/reports_icon.svg';
import { ReactComponent as LocationManagementIcon } from 'assets/images/locationManagement.svg';
import { ReactComponent as PrefixListIcon } from 'assets/images/prefixList.svg';
import { ReactComponent as PicklistIcon } from 'assets/images/picklist.svg';
import { ReactComponent as LeavelistIcon } from 'assets/images/leavelist.svg';
import { ReactComponent as RequestOrderIcon } from 'assets/images/requestOrderIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/images/profileIcon.svg';

const TITLES = {
  DASHBOARD: 'Dashboard',
  ASSET_SUMMARY: 'Asset Summary',
  LOOSE_ITEMS: 'Loose Items',
  REQUEST_ORDER: 'Request Orders',
  ASSET_BATCH_UPDATES: 'Assets Batch Updates',
  ASSET_TICKETS: 'Asset Tickets',
  LOCATION_REPORTS: 'Location Reports',
  ASSET_REPORTS: 'Asset Reports',
  PICKLIST: 'Picklist',
  LEAVELIST: 'Leavelist',
  LOCATIONS: 'Locations',
  PREFIX_LIST: 'Prefix List',
  PERSONAL_PROFILE: 'Personal Profile'
};

const displayingOrder = [
  TITLES.DASHBOARD,
  TITLES.ASSET_SUMMARY,
  TITLES.LOOSE_ITEMS,
  TITLES.REQUEST_ORDER,
  TITLES.ASSET_BATCH_UPDATES,
  TITLES.ASSET_TICKETS,
  TITLES.LOCATION_REPORTS,
  TITLES.ASSET_REPORTS,
  TITLES.PICKLIST,
  TITLES.LEAVELIST,
  TITLES.LOCATIONS,
  TITLES.PERSONAL_PROFILE,
  TITLES.PREFIX_LIST
];

const getIconWithStyles = (IconComponent, isReportIcon = false) => (
  <IconComponent className={isReportIcon ? styles.reports_icon : styles.card_icon} />
);

const SCREENS_CONFIG = [
  {
    title: TITLES.DASHBOARD,
    subtitle: 'Look at the general assets dashboard to get the big picture about assets.',
    to: routes.DASHBOARD_PATH,
    titleKey: SIDEBAR_TITLES.ASSET_DASHBOARDS.GENERAL,
    icon: getIconWithStyles(DashboardIcon)
  },
  {
    title: TITLES.ASSET_SUMMARY,
    subtitle:
      'View the whole list of assets in the system. Filter them by locations, statuses, etc.',
    to: routes.ASSETS_LIST_PATH,
    titleKey: SIDEBAR_TITLES.ASSET.MAIN,
    icon: getIconWithStyles(AssetSummaryIcon)
  },
  {
    title: TITLES.LOOSE_ITEMS,
    subtitle:
      'View the list of loose items in the system. Filter them by locations, statuses, etc.',
    to: routes.LOOSE_ITEMS_LIST_PATH,
    titleKey: SIDEBAR_TITLES.LOOSE_ITEMS.MAIN,
    icon: getIconWithStyles(LooseItemsIcon)
  },
  {
    title: TITLES.REQUEST_ORDER,
    subtitle: 'Check the list of request orders, created by you and others.',
    to: routes.REQUEST_ORDER_SUMMARY_PATH,
    titleKey: SIDEBAR_TITLES.REQUEST_ORDERS.MAIN,
    icon: getIconWithStyles(RequestOrderIcon)
  },
  {
    title: TITLES.ASSET_BATCH_UPDATES,
    subtitle:
      'To save time, choose a batch of assets that you want to update and do it in one click.',
    to: routes.BATCH_ASSET_UPDATES_PATH,
    titleKey: SIDEBAR_TITLES.BATCH_UPDATES.ASSET,
    icon: getIconWithStyles(BatchUpdatesIcon)
  },
  {
    title: TITLES.ASSET_TICKETS,
    subtitle: 'Check the list of tickets, created by you and others.',
    to: routes.TICKETS_LIST_PATH,
    titleKey: SIDEBAR_TITLES.ASSET_TICKETS.SUMMARY,
    icon: getIconWithStyles(AssetTicketsIcon)
  },
  {
    title: TITLES.LOCATION_REPORTS,
    subtitle: 'Look at the locations’ reports, created by you and others.',
    to: routes.LOCATION_REPORT_LIST_PATH,
    titleKey: SIDEBAR_TITLES.REPORTS.LOCATION,
    icon: getIconWithStyles(ReportsIcon, true)
  },
  {
    title: TITLES.ASSET_REPORTS,
    subtitle: 'Look at the assets’ reports, created by you and others.',
    to: routes.ASSET_REPORT_LIST_PATH,
    titleKey: SIDEBAR_TITLES.REPORTS.ASSET,
    icon: getIconWithStyles(ReportsIcon, true)
  },
  {
    title: TITLES.PICKLIST,
    subtitle: 'Check all picklists in the system. Filter them by locations, statuses, etc.',
    to: routes.PICKLIST_SUMMARY_PATH,
    titleKey: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.PICKLIST_SUMMARY,
    icon: getIconWithStyles(PicklistIcon)
  },
  {
    title: TITLES.LEAVELIST,
    subtitle: 'Check all leavelists in the system. Filter them by locations, statuses, etc.',
    to: routes.LEAVELIST_SUMMARY_PATH,
    titleKey: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.LEAVELIST_SUMMARY,
    icon: getIconWithStyles(LeavelistIcon)
  },
  {
    title: TITLES.LOCATIONS,
    subtitle:
      'View the whole list of locations in the system. Filter them by types, job numbers, etc.',
    to: routes.LOCATIONS_LIST_PATH,
    titleKey: SIDEBAR_TITLES.LOCATION_MANAGEMENT.SUMMARY,
    icon: getIconWithStyles(LocationManagementIcon)
  },
  {
    title: TITLES.PERSONAL_PROFILE,
    subtitle: 'Check your team member profile and set your schedule.',
    to: routes.PERSONNEL_PROFILE_PATH,
    titleKey: SIDEBAR_TITLES.TEAM_SCHEDULE.PERSONAL_PROFILE,
    icon: getIconWithStyles(ProfileIcon)
  },
  {
    title: TITLES.PREFIX_LIST,
    subtitle: 'Look at the list of prefixes in the system and create a new one, if it’s needed.',
    to: routes.CATEGORY_LIST_PATH,
    titleKey: SIDEBAR_TITLES.PREFIX_LIST.MAIN,
    icon: getIconWithStyles(PrefixListIcon)
  }
];

const addUniqueIds = (items) => items.map((item, index) => ({ ...item, id: `item-${index + 1}` }));

const buildPanel = (userConfig, isMobile) => {
  const conditions = checkAccess({ userConfig, isMobile });

  const available = SCREENS_CONFIG.filter(
    ({ titleKey }) => conditions[titleKey] || !(titleKey in conditions)
  );

  const sorted = displayingOrder
    .map((name) => available.find(({ title }) => title === name))
    .filter(Boolean);

  const identified = addUniqueIds(sorted);

  return identified;
};

export { buildPanel };
