import React from 'react';
import styles from './LicenseDetails.module.scss';

import Subtitle from 'components/DetailsComponents/Subtitle';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

import { useAssetSelector } from 'hooks/Asset';

import { formatDate } from 'helpers/AppHelpers';

export default function LicenseDetails() {
  const { currentAsset } = useAssetSelector();
  const { assetPrefix, assetLicense } = currentAsset;

  const licenseDetails = [
    { id: 1, label: 'State', value: assetLicense?.['licenseState']?.name || '' },
    { id: 2, label: 'Plate Number', value: assetLicense?.['licensePlate'] || '' },
    {
      id: 3,
      label: 'Registration Expiration',
      value: assetLicense?.['registrationExpirationAtUtc']
        ? formatDate(assetLicense['registrationExpirationAtUtc'])
        : ''
    },
    {
      id: 4,
      label: 'DOT Registration Expiration',
      value: assetLicense?.['dotRegistrationExpirationAtUtc']
        ? formatDate(assetLicense['dotRegistrationExpirationAtUtc'])
        : ''
    },
    { id: 5, label: 'Make', value: assetLicense?.['make'] || '' },
    { id: 6, label: 'Model', value: assetLicense?.['model'] || '' },
    { id: 6, label: 'Model year', value: assetLicense?.['modelYear'] || '' }
  ];

  return (
    (assetPrefix?.prefixType?.id === 4 || assetPrefix?.prefixType?.id === 5) && (
      <div className={styles.wrapper}>
        <Subtitle>License Information</Subtitle>
        <div className={styles.license}>
          {licenseDetails.map(({ id, label, value }) => (
            <DetailedRow key={id} label={label} value={value} />
          ))}
        </div>
      </div>
    )
  );
}
