import React from 'react';
import styles from './VideoPlayer.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import BackButton from 'components/BackButton/BackButton';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function VideoPlayer({ open, setOpen, video }) {
  const isMobile = useMobileViewport();

  const closePlayer = () => setOpen(false);

  return (
    <DialogWrapper open={open} onClose={closePlayer}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {!isMobile ? (
            <IconButton className={styles.header__close} onClick={closePlayer}>
              <CloseIcon />
            </IconButton>
          ) : (
            <BackButton onCancel={closePlayer} />
          )}
        </div>
        <div className={styles.content}>
          <video src={video.link} controls autoPlay />
        </div>
      </div>
    </DialogWrapper>
  );
}
