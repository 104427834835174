import React, { useRef, useState } from 'react';
import styles from './LocationInfo.module.scss';
import { dateTimeFormat, fileDownload, formatDate } from 'helpers/AppHelpers';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { LOCATION_EDIT_PATH } from 'constants/routeConstants';
import AddIcon from '@material-ui/icons/Add';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory } from 'react-router-dom';
import { PhotoGallery } from 'components/PhotoGallery';
import { DOWNLOAD_FILE } from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { useLocationSelector } from '../../../hooks/useLocationSelector';

export default function ExtraInfo() {
  const anchorRef = useRef(null);

  const { currentLocation } = useLocationSelector();

  const history = useHistory();
  const { isAdminUser } = useUserConfig();

  const [openMenu, setOpenMenu] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const {
    resources,
    reportRecipients,
    reorderTriggerRecipients,
    createdByUser,
    createdAtUtc,
    lastUpdatedByUser,
    modifiedAtUtc
  } = currentLocation;

  const getFiles = resources?.filter((resource) => resource['resourceType']?.id === 2) || [];

  const onFileDownload = (file) => setDialogModalData({ ...DOWNLOAD_FILE, isOpened: true, file });

  const agreeModal = () => fileDownload(dialogModalData.file).then(() => closeModal());
  const closeModal = () => setDialogModalData({ isOpened: false });

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <section className={styles.extra}>
        {isAdminUser && (
          <section className={styles.extra__actionsMenu}>
            <Button ref={anchorRef} onClick={() => setOpenMenu(!openMenu)}>
              Actions
              <ExpandMore className={openMenu ? styles.opened : ''} />
            </Button>
            <Popper open={openMenu} anchorEl={anchorRef.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper classes={{ root: styles.menuContainer }}>
                    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                      <MenuList autoFocusItem={openMenu}>
                        <MenuItem onClick={() => history.push(LOCATION_EDIT_PATH)}>
                          <AddIcon />
                          Create new location
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </section>
        )}

        <PhotoGallery resources={resources} />
        <div className={styles.extra__creator}>
          {!!createdByUser?.firstName && (
            <p className={styles.extra__creator_info}>
              Created {createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : ''} by{' '}
              {createdByUser.firstName} {createdByUser.lastName}
            </p>
          )}
          {!!lastUpdatedByUser?.firstName && (
            <p className={styles.extra__creator_info}>
              Last Modified {modifiedAtUtc ? formatDate(modifiedAtUtc, dateTimeFormat) : ''} by{' '}
              {lastUpdatedByUser.firstName} {lastUpdatedByUser.lastName}
            </p>
          )}
        </div>
        <section className={styles.extra__info}>
          <h3>Files</h3>
          <div className={styles.extra__info_data}>
            {!!getFiles.length &&
              getFiles.map((file) => (
                <div
                  className={styles.extra__info_file}
                  key={file.id}
                  onClick={() => onFileDownload(file)}>
                  {file.name}
                </div>
              ))}
          </div>
        </section>
        <section className={styles.extra__info}>
          <h3>Report recipients</h3>
          <div className={styles.extra__info_data}>
            {!!reportRecipients.length &&
              reportRecipients.map((item) => <p key={item.id}>{item.email}</p>)}
          </div>
        </section>
        <section className={styles.extra__info}>
          <h3>Re-order trigger recipients</h3>
          <div className={styles.extra__info_data}>
            {!!reorderTriggerRecipients.length &&
              reorderTriggerRecipients.map((item) => <p key={item.id}>{item.email}</p>)}
          </div>
        </section>
      </section>
    </>
  );
}
