import { cloneObj, getProjectName } from 'helpers/AppHelpers';
import { handleBooleanFilter, handleIdsFilter, handleNamesFilter } from 'helpers/ChipGenerator';
import BlueMarker from 'assets/images/markers/bluemarker.svg';
import RedMarker from 'assets/images/markers/redmarker.svg';
import { ASSETS_LIST_PATH, LOOSE_ITEMS_LIST_PATH } from 'constants/routeConstants';

const defaultFilter = {
  filters: {
    locationIds: [],
    locationJobNumberIds: [],
    assetPrefixIds: [],
    assetFieldIds: [],
    projectIds: [],
    looseItemCategoryIds: [],
    looseItemIds: [],
    looseItemCustomFieldIds: [],
    rentalIds: [],
    siteTypeIds: [],
    severities: [],
    looseItemStatusNames: [],
    isNonOwned: false,
    showWithBaa: false,
    showWithReorderTrigger: false
  },
  responseReceived: false
};

const generateAssetSummaryLink = (filters, extra) => {
  const { prefixId, severityName, locationId } = extra;

  const initial = [];

  if (filters.siteTypeIds?.length) {
    filters.siteTypeIds.forEach((val) => initial.push(`siteTypeIds=${encodeURIComponent(val)}`));
  }

  if (filters.projectIds?.length) {
    filters.projectIds.forEach((val) => initial.push(`projectIds=${encodeURIComponent(val)}`));
  }

  if (filters.locationJobNumberIds?.length) {
    filters.locationJobNumberIds.forEach((val) =>
      initial.push(`locationJobNumberIds=${encodeURIComponent(val)}`)
    );
  }

  if (filters.rentalIds?.length) {
    filters.rentalIds.forEach((val) => initial.push(`rentalIds=${encodeURIComponent(val)}`));
  }

  if (filters.isNonOwned) {
    initial.push('isNonOwned=true');
  }

  if (prefixId) {
    initial.push(`prefixIds=${encodeURIComponent(prefixId)}`);
  }

  if (severityName) {
    initial.push(`severities=${encodeURIComponent(severityName)}`);
  }

  if (locationId) {
    initial.push(`locationIds=${encodeURIComponent(locationId)}`);
  }

  const queryParams = initial.join('&');

  return `${ASSETS_LIST_PATH}?${queryParams}`;
};

const generateLooseItemSummaryLink = (filters, extra) => {
  const { looseItemId, statusName, locationId } = extra;

  const initial = [];

  if (filters.locationJobNumberIds?.length) {
    filters.locationJobNumberIds.forEach((val) =>
      initial.push(`locationJobNumberIds=${encodeURIComponent(val)}`)
    );
  }

  if (filters.looseItemCategoryIds?.length) {
    filters.looseItemCategoryIds.forEach((val) =>
      initial.push(`categoryIds=${encodeURIComponent(val)}`)
    );
  }

  if (filters.looseItemStatusNames?.length) {
    filters.looseItemStatusNames.forEach((val) =>
      initial.push(`statusNames=${encodeURIComponent(val)}`)
    );
  }

  if (filters.showWithBaa) {
    initial.push('showWithBaa=true');
  }

  if (filters.showWithReorderTrigger) {
    initial.push('showWithReorderTrigger=true');
  }

  if (looseItemId) {
    initial.push(`looseItemIds=${encodeURIComponent(looseItemId)}`);
  }

  if (statusName) {
    initial.push(`statusNames=${encodeURIComponent(statusName)}`);
  }

  if (locationId) {
    initial.push(`locationIds=${encodeURIComponent(locationId)}`);
  }

  const queryParams = initial.join('&');

  return `${LOOSE_ITEMS_LIST_PATH}?${queryParams}`;
};

const generateInitialQuery = (filter) => ({
  ...defaultFilter,
  filters: { ...defaultFilter.filters, ...filter?.filters }
});

const generateChips = (criteria, filters = {}) => {
  let newChips = [];

  const idsConfig = [
    { options: criteria?.locations || [], getLabel: (el) => el.siteCode, key: 'locationIds' },
    {
      options: criteria?.locations || [],
      getLabel: (el) => el.locationJobNumber,
      key: 'locationJobNumberIds'
    },
    { options: criteria?.assetPrefixes || [], getLabel: (el) => el.prefix, key: 'assetPrefixIds' },
    {
      options: criteria?.assetFields || [],
      getLabel: (el) => el.displayName,
      key: 'assetFieldIds'
    },
    { options: criteria?.projects || [], getLabel: (el) => getProjectName(el), key: 'projectIds' },
    {
      options: criteria?.looseItemCategories || [],
      getLabel: (el) => el.name,
      key: 'looseItemCategoryIds'
    },
    { options: criteria?.looseItems || [], getLabel: (el) => el.name, key: 'looseItemIds' },
    {
      options: criteria?.looseItemFields || [],
      getLabel: (el) => el.name,
      key: 'looseItemCustomFieldIds'
    },
    { options: criteria?.siteType || [], getLabel: (el) => el.name, key: 'siteTypeIds' },
    { options: criteria?.rentalStatuses || [], getLabel: (el) => el.displayName, key: 'rentalIds' }
  ];

  const namesConfig = [
    { options: criteria?.ticketSeverities || [], key: 'severities' },
    { options: criteria?.looseItemLocationStatuses || [], key: 'looseItemStatusNames' }
  ];

  const booleanConfig = [
    { key: 'isNonOwned', label: 'Non-owned' },
    { key: 'showWithBaa', label: 'Show with BAA qty' },
    { key: 'showWithReorderTrigger', label: 'Show with reorder trigger' }
  ];

  idsConfig.forEach(({ key, options, getLabel }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleIdsFilter(filters[key], options, getLabel, key));
    }
  });

  namesConfig.forEach(({ key, options }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleNamesFilter(filters[key], options, key));
    }
  });

  booleanConfig.forEach(({ key, label }) => {
    if (filters?.[key]) {
      newChips = newChips.concat(handleBooleanFilter(key, label));
    }
  });

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemName, itemId } = deletedChip;

  switch (key) {
    case 'severities':
    case 'looseItemStatusNames':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case 'isNonOwned':
    case 'showWithBaa':
    case 'showWithReorderTrigger':
      newFilters[key] = false;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: {}
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = Object.keys(defaultFilter.filters);

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  return payload;
};

const generateMarkers = (locations = []) =>
  locations
    .map((el) => {
      const { latitude, longitude, siteType } = el;
      if (!latitude || !longitude || !siteType?.id) return null;

      return {
        position: [latitude, longitude],
        icon: siteType.id === 1 ? BlueMarker : RedMarker,
        info: el
      };
    })
    .filter(Boolean);

const legend = [
  { label: 'Job Site', icon: RedMarker },
  { label: 'Storage Yard', icon: BlueMarker }
];

export {
  defaultFilter,
  legend,
  generateInitialQuery,
  generateChips,
  generateMarkers,
  removeChip,
  parseQuery,
  generateAssetSummaryLink,
  generateLooseItemSummaryLink
};
