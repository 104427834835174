import React, { useEffect } from 'react';

import PaginationLayout from './PaginationLayout';
import PageSelector from './PageSelector';
import LimitSelector from './LimitSelector';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useDispatch, useSelector } from 'react-redux';

import { setTopPosition, updateUserConfig } from 'actions/commonActions';

import { selectLoadMoreFlag } from 'pages/commonSelectors';

export function Pagination({
  rowsNumber,
  currentPage,
  selectedLimit,
  totalPages,
  tableName,
  tableRef,
  onChangeLimit,
  onChangePage,
  withOption100,
  withOptionAll,
  hideLimitSelector,
  hidePageSelector
}) {
  const isMobile = useMobileViewport();
  const loadMoreFlag = useSelector(selectLoadMoreFlag());

  const dispatch = useDispatch();
  const { getUpdatedByTablesConfigCopy, isAllLimitEnabled, getTableLimit } = useUserConfig();

  const isAllSelected = () =>
    isAllLimitEnabled(tableName) && getTableLimit(tableName) === 100 && !isMobile;

  const isLimitSelectorAvailable =
    !hideLimitSelector && !(rowsNumber <= 10 && currentPage === 1 && totalPages === 1) && tableName;
  const isPageSelectorAvailable = !hidePageSelector && totalPages !== 1 && !isAllSelected();

  const scrollToTableHead = () => {
    if (isAllSelected()) return;
    const elemRectData = tableRef.current?.getBoundingClientRect();
    dispatch(setTopPosition(elemRectData?.y + (isMobile ? -100 : 0)));
  };

  const selectPage = (page, param) => {
    onChangePage(page, param);
    scrollToTableHead();
  };

  const selectLimit = (limit) => {
    const limitValue = limit === 'All' ? 100 : limit;
    const isAllValue = limit === 'All';

    const configCopy = getUpdatedByTablesConfigCopy(tableName, limitValue, isAllValue);
    dispatch(updateUserConfig(configCopy)).then(() => {
      if (selectedLimit === 100 && limit === 'All') {
        onChangePage(currentPage === 1 ? currentPage + 1 : 1, 'AUTOLOAD');
      } else {
        onChangeLimit();
      }

      if (limit !== 'All' || (limit === 'All' && currentPage !== 1)) {
        scrollToTableHead();
      }
    });
  };

  useEffect(() => {
    if (isAllSelected() && loadMoreFlag && currentPage < totalPages) {
      selectPage(currentPage + 1, 'AUTOLOAD');
    }
  }, [loadMoreFlag]);

  return (
    <PaginationLayout isAllSelected={isAllSelected()} loadMoreFlag={loadMoreFlag}>
      {isLimitSelectorAvailable && (
        <LimitSelector
          selectedLimit={selectedLimit}
          onChange={selectLimit}
          withOption100={withOption100}
          withOptionAll={withOptionAll}
          isAllSelected={isAllSelected()}
        />
      )}
      {isPageSelectorAvailable && (
        <PageSelector currentPage={currentPage} totalPages={totalPages} onChange={selectPage} />
      )}
    </PaginationLayout>
  );
}
