import React from 'react';
import styles from './SnapshotBar.module.scss';

import GroupedVerticalBar from 'components/Charts/GroupedVerticalBar/GroupedVerticalBar';

import NoDataView from '../../NoDataView';

import {
  getSnapshotFileName,
  getSnapshotBarWidth,
  SNAPSHOT_BAR_COLORS,
  SNAPSHOT_TOTAL_LABEL
} from '../../../helpers';

export default function SnapshotBar({ dashboard }) {
  const { department, durationColumnNames, staffingSnapshotsEmployments, total } = dashboard;

  if (!('department' in dashboard) || !department?.id)
    return <NoDataView label="Set filter data to generate Staffing Snapshot" />;

  if (total.every(({ durationValue }) => durationValue === 0))
    return <NoDataView label="No matches in the table." />;

  const getSeriesData = (index) => {
    if (!staffingSnapshotsEmployments) return {};

    return staffingSnapshotsEmployments
      .map(({ staffingSnapshotsDurationValues }) => staffingSnapshotsDurationValues[index])
      .map(({ durationValue }) => durationValue);
  };

  const getTotal = (index) => total?.[index]?.durationValue || 0;

  const series =
    durationColumnNames?.map((name, index) => ({
      name,
      data: [...getSeriesData(index), getTotal(index)]
    })) || [];

  const getCategories = () => {
    const arr = staffingSnapshotsEmployments?.map(({ employment }) => employment.name) || [];
    arr.push(SNAPSHOT_TOTAL_LABEL);
    return arr;
  };

  const CATEGORIES_LENGTH = getCategories()?.length;
  const DURATIONS_LENGTH = durationColumnNames?.length;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.bar}
        style={{ width: getSnapshotBarWidth(CATEGORIES_LENGTH, DURATIONS_LENGTH) }}>
        <GroupedVerticalBar
          series={series}
          categories={getCategories()}
          fileName={getSnapshotFileName(department?.name)}
          colors={SNAPSHOT_BAR_COLORS}
        />
      </div>
    </div>
  );
}
