import React, { useEffect, useState } from 'react';
import styles from './AssembledBOMListFilter.module.scss';
import FilterIcon from 'assets/images/filter.svg';
import { Backdrop, Chip, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { enrichNameToId } from 'helpers/AppHelpers';
import CloseIcon from '@material-ui/icons/Close';
import { MainButton } from 'components/StyledComponents';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  selectConfigurationListFilterData,
  selectLocationListFilterData,
  selectStatusListFilterData
} from '../selectors';
import {
  getConfigurationListForFilter,
  getLocationListForFilter,
  getStatusListForFilter
} from 'actions/assembledLooseItemActions';
import Multiselect from 'components/Multiselect/Multiselect';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import AutocompleteWithSelectAll from '../../../../components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';

function AssembledBOMListFilter({
  isMobile,
  filter,
  setFilter,
  onApply,
  openFilter,
  setOpenFilter,
  locationList,
  getLocationListAction,
  statusList,
  getStatusListAction,
  configurationList,
  getConfigurationListAction
}) {
  const { filters } = filter;
  const [chips, setChips] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState({ ...filters });
  const [key, setKey] = useState(new Date().getTime() + Math.random());

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && createChips(filters);
  };

  const handleFilterClose = () => {
    isMobile && createChips(filters);
    setOpenFilter(false);
  };

  const applyFilter = () => {
    const commonObj = { ...filter, filters: { ...values } };
    createChips(values);
    setOpenFilter(!openFilter);
    setFilter(commonObj);
    onApply(commonObj);
  };

  const handleClearFilter = () => {
    const defaultState = {
      ...values,
      locationIds: [],
      locationJobNumberIds: [],
      statusNames: [],
      looseItemConfigurationIds: [],
      searchQuery: ''
    };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const onFilterSelect = (e, value) => {
    let currentFilter = {};
    if (e?.target) {
      currentFilter = {
        ...values,
        [e.target.name]: [...e.target.value]
      };
    } else {
      currentFilter = {
        ...values,
        [e]: [...value.map(({ id }) => id)]
      };
    }
    setValues(currentFilter);
    isMobile && createChips(currentFilter);
  };

  const handleChipDelete = (item) => {
    const filterObj = {
      ...values,
      locationIds:
        item.key === 'location'
          ? values.locationIds.filter((id) => id !== item.id)
          : values.locationIds,
      statusNames:
        item.key === 'status'
          ? values.statusNames.filter((name) => name !== item.name)
          : values.statusNames,
      looseItemConfigurationIds:
        item.key === 'configuration'
          ? values.looseItemConfigurationIds.filter((id) => id !== item.id)
          : values.looseItemConfigurationIds,
      locationJobNumberIds:
        item.key === 'jobNumber'
          ? values.locationJobNumberIds.filter((id) => id !== item.id)
          : values.locationJobNumberIds
    };
    setValues(filterObj);
    setKey(new Date().getTime() + Math.random());
    setChips(
      chips.filter((chip) => {
        if (chip.key === item.key) {
          return chip.id !== item.id || chip.name !== item.name;
        } else {
          return true;
        }
      })
    );
    if (!isMobile) {
      setFilter({ ...filter, filters: { ...filterObj } });
      onApply({ ...filter, filters: { ...filterObj } });
    }
  };

  const createChips = (inputFilter) => {
    const chipsArray = [];
    if (inputFilter?.locationIds?.length) {
      chipsArray.push(
        ...inputFilter.locationIds.map((item) => {
          return {
            ...locationList.items?.find((option) => option.id === item),
            key: 'location'
          };
        })
      );
    }
    if (inputFilter?.statusNames?.length) {
      chipsArray.push(
        ...inputFilter.statusNames.map((item) => {
          return {
            ...statusList.find((option) => option.name === item),
            key: 'status'
          };
        })
      );
    }
    if (inputFilter?.looseItemConfigurationIds?.length) {
      chipsArray.push(
        ...inputFilter.looseItemConfigurationIds.map((item) => {
          return {
            ...configurationList.find((option) => option.id === item),
            key: 'configuration'
          };
        })
      );
    }
    if (inputFilter?.locationJobNumberIds?.length) {
      chipsArray.push(
        ...inputFilter.locationJobNumberIds.map((item) => {
          return {
            ...locationList.items?.find((option) => option.id === item),
            key: 'jobNumber'
          };
        })
      );
    }
    setChips(chipsArray);
  };

  const selectOption = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  useEffect(() => {
    if (!('items' in locationList)) {
      getLocationListAction({
        filters: {
          active: true,
          includeUserCheck: true
        }
      });
    }
  }, [locationList]);

  useEffect(() => {
    if (!statusList?.length) {
      getStatusListAction();
    }
  }, [statusList]);

  useEffect(() => {
    getConfigurationListAction();
  }, []);

  useEffect(() => {
    if (locationList?.items?.length && statusList?.length && configurationList?.length) {
      createChips(filter.filters);
      setValues({ ...filter.filters });
    }
  }, [filter, locationList, statusList, configurationList]);

  return (
    <div className={styles.filterWrapper}>
      {!isMobile && <Backdrop open={openFilter} />}
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="" />
      </IconButton>

      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && <FilterHeader onBack={handleFilterClose} />}
              <section className={styles.formColumn}>
                <section className={styles.formCell}>
                  <label>Location</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="locationIds"
                      defaultValue={values?.locationIds || []}
                      options={locationList?.items || []}
                      onFilterSelect={onFilterSelect}
                      multiselect={true}
                      refreshKey={key}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Status</label>
                  <div className={styles.inputWrapper}>
                    <Multiselect
                      name="statusNames"
                      valuesArray={values?.statusNames}
                      options={enrichNameToId(statusList) || []}
                      onFilterSelect={onFilterSelect}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Job Number</label>
                  <div className={styles.inputWrapper}>
                    <AutocompleteWithSelectAll
                      name="locationJobNumberIds"
                      label="locationJobNumber"
                      value={values?.locationJobNumberIds || []}
                      options={locationList?.items || []}
                      onSelect={selectOption}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Configurations</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="looseItemConfigurationIds"
                      defaultValue={values?.looseItemConfigurationIds || []}
                      options={configurationList || []}
                      onFilterSelect={onFilterSelect}
                      multiselect={true}
                      refreshKey={key}
                    />
                  </div>
                </section>
                {isMobile && (
                  <section className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={`${item.id}-${item.key}`}
                          label={
                            item.key === 'jobNumber'
                              ? item.locationJobNumber
                              : item.siteCode || item.displayName || item.name || ''
                          }
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                  </section>
                )}
              </section>
              <section className={styles.buttonWrapper}>
                <ClearFilterButton onClick={handleClearFilter} />
                <div className={styles.primary}>
                  <MainButton
                    text="Cancel"
                    action={handleFilterClose}
                    type="secondary"
                    size="filter_popup"
                  />
                  <MainButton
                    text="Apply"
                    action={applyFilter}
                    type="primary"
                    size="filter_popup"
                  />
                </div>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={`${item.id}-${item.key}`}
                label={
                  item.key === 'jobNumber'
                    ? item.locationJobNumber
                    : item.siteCode || item.displayName || item.name || ''
                }
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  locationList: selectLocationListFilterData(),
  statusList: selectStatusListFilterData(),
  configurationList: selectConfigurationListFilterData()
});

const mapDispatchToProps = {
  getLocationListAction: getLocationListForFilter,
  getStatusListAction: getStatusListForFilter,
  getConfigurationListAction: getConfigurationListForFilter
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssembledBOMListFilter);
