import React from 'react';
import styles from './templates.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { LOCATION_REPORT_VALIDATION_RULES } from '../../../LocationReportList/LocationReportEdit/validationRules';

export default function ReportTextField({ itemId, label, name, onBlur, onFocus }) {
  const max = LOCATION_REPORT_VALIDATION_RULES.find(({ id }) => id === itemId)?.maxSymbols || 0;

  const rule = LOCATION_REPORT_VALIDATION_RULES.find(({ id }) => id === itemId)?.regexp;

  return (
    <section className={styles.inputField}>
      {label && <label>{label}</label>}
      <FormInputText name={name} options={{ max, rule, onBlur, onFocus }} />
    </section>
  );
}
