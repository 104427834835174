export const getWeatherConditionsByCode = (code) => {
  switch (code) {
    case 0:
      return 'Clear sky';
    case 1:
      return 'Mainly clear';
    case 2:
      return 'Partly cloudy';
    case 3:
      return 'Overcast';
    case 45:
      return 'Fog';
    case 48:
      return 'Depositing rime fog';
    case 51:
      return 'Light Drizzle';
    case 53:
      return 'Moderate Drizzle';
    case 55:
      return 'Dense intensity Drizzle';
    case 56:
      return 'Light Freezing Drizzle';
    case 57:
      return 'Dense intensity Freezing Drizzle';
    case 61:
      return 'Slight Rain';
    case 63:
      return 'Moderate Rain';
    case 65:
      return 'Heavy intensity Rain';
    case 66:
      return 'Light Freezing Rain';
    case 67:
      return 'Heavy intensity Freezing Rain';
    case 71:
      return 'Slight Snow fall';
    case 73:
      return 'Moderate Snow fall';
    case 75:
      return 'Heavy intensity Snow fall';
    case 77:
      return 'Snow grains';
    case 80:
      return 'Slight Rain showers';
    case 81:
      return 'Moderate Rain showers';
    case 82:
      return 'Violent Rain showers';
    case 85:
      return 'Slight Snow showers';
    case 86:
      return 'Heavy Snow showers';
    case 95:
    case 96:
    case 99:
      return 'Thunderstorm';
    default:
      return '';
  }
};
