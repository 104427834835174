import React, { useRef, useEffect, useState } from 'react';
import ScrollToTop from 'helpers/ScrollToTop';

import mainLogo from 'assets/images/logo.svg';
import styles from './Login.module.scss';
import { MainButton } from 'components/StyledComponents';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAuthLink, onAuth } from 'actions/authActions';
import { selectAuthLink, selectIsSignOut } from './selectors';
import { ROOT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { BASE_ADDRESS } from 'api/api';
import { TOKEN_KEY } from 'constants/commonConstants';
import { useLocation } from 'react-router-dom';
import InfoPopup from 'components/InfoPopup/InfoPopup';
import { BLOCK } from 'constants/infoPopupsData';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Login({ onAuthAction, authLink, getAuthLinkAction }) {
  const mainContainer = useRef(null);

  const [popupData, setPopupData] = useState({
    isOpened: false,
    type: BLOCK,
    title: '',
    buttonText: 'Ok'
  });
  const history = useHistory();
  const query = useQuery();

  const token = query.get('AccessToken');
  const error = query.get('Error');
  const errorMessage = query.get('ErrorMessage');

  const [splash, setSplash] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 1500);
  });

  useEffect(() => {
    if (!('loginUrl' in authLink)) {
      const redirectToClientUri = `${BASE_ADDRESS}/login`;
      getAuthLinkAction({ redirectToClientUri: redirectToClientUri });
    }
  }, [authLink]);

  useEffect(() => {
    if (error && errorMessage) {
      setPopupData({
        ...popupData,
        title: errorMessage,
        isOpened: true
      });
    }
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
      onAuthAction(token);
      history.push(ROOT_PATH);
    }
  }, [error, errorMessage]);

  const onLogin = () => {
    const a = document.createElement('a');
    a.href = authLink.loginUrl;
    a.click();
  };

  return (
    <>
      <ScrollToTop elem={mainContainer} />
      <InfoPopup
        data={popupData}
        onClose={() => setPopupData({ ...popupData, isOpened: false })}
      />
      <section
        className={`${styles.host} ${splash ? styles.hidden : styles.visible}`}
        ref={mainContainer}>
        <div className={styles.block}>
          <img src={mainLogo} className={styles.logo} alt="logo" />
          <div className={styles.formBlock}>
            <label>Authentication</label>
            <div className={styles.controls}>
              <MainButton
                text="Sign In"
                action={onLogin}
                type="primary"
                size="veryLong"
              />
            </div>
          </div>
        </div>

        <div className={styles.splashBlock}>
          <div className={styles.splashLogoBlock}></div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  isSignOut: selectIsSignOut(),
  authLink: selectAuthLink()
});

const mapDispatchToProps = {
  onAuthAction: onAuth,
  getAuthLinkAction: getAuthLink
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Login);
