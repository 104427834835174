import React, { useRef, useState } from 'react';
import { StyledTableCell } from 'components/StyledComponents';
import styles from './BodyCells.module.scss';
import { Fade, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';

export default function CapacityDayCell({
  count,
  day,
  teamMembers,
  isFirstRow,
  projectName,
  capacityName
}) {
  const iconRef = useRef(null);
  const [open, setOpen] = useState(false);

  const hideModal = () => count !== 0 && setOpen(false);
  const showPopup = () => count !== 0 && setOpen(!open);

  return (
    <StyledTableCell
      key={day}
      className={clsx(styles.noPadding, isFirstRow && styles.biggerTopBorder)}>
      {open && (
        <Popper
          open={open}
          anchorEl={() => iconRef.current}
          placement="bottom-start"
          transition
          modifiers={{ hide: { enabled: true } }}
          className={styles.popperStyle}>
          {({ TransitionProps, placement }) => (
            <Fade
              {...TransitionProps}
              style={{ transformOrigin: placement === 'center bottom' }}
              timeout={0}>
              <Paper>
                <div className={styles.textPopperBlock}>
                  <div className={styles.crossButton} onClick={hideModal}>
                    x
                  </div>
                  <div className={styles.popperHeader}>
                    <span>
                      {projectName} {capacityName}
                    </span>
                  </div>
                  <div className={styles.popperContent}>
                    {teamMembers.map((el, index) => (
                      <div key={index} className={styles.row}>
                        {el.firstName + ' ' + el.lastName}
                      </div>
                    ))}
                  </div>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      <div
        ref={iconRef}
        className={clsx(styles.countBlock, count !== 0 && styles.pointer)}
        onClick={showPopup}
        onBlur={hideModal}
        tabIndex="0">
        <span className={clsx(count !== 0 && styles.underline)}>{count || 0}</span>
      </div>
    </StyledTableCell>
  );
}
