import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getTemplatesApi = (query) =>
  api.get(`/GeneralReportTemplates?${queryToString(query)}`).then((res) => res.data);

export const getTemplateApi = (id) =>
  api.get(`/GeneralReportTemplate?id=${id}`).then((res) => res.data);

export const getReportTypesApi = () => api.get('/GeneralReportTypes').then((res) => res.data);

export const removeTemplateApi = (query) =>
  api.delete(`/GeneralReportTemplate?${queryToString(query)}`).then((res) => res);
