import moment from 'moment';
import { dateFormat, formatDate, wait } from 'helpers/AppHelpers';
import { graphConfig } from '../../msConfig';

export const useEventActions = (callMsGraph) => {
  const getEmploymentName = (employmentData) =>
    employmentData.project && employmentData.project.jobNumber
      ? employmentData.project.jobNumber
      : employmentData['employment'].name;
  const getEventName = (fullName, employment) => `${fullName} - ${getEmploymentName(employment)}`;
  const getEventData = (eventName, employment) => ({
    subject: eventName,
    start: {
      dateTime: moment(employment.day).format(dateFormat),
      timeZone: 'Pacific Standard Time'
    },
    end: {
      dateTime: moment(employment.day).add(1, 'd').format(dateFormat),
      timeZone: 'Pacific Standard Time'
    },
    isAllDay: true,
    isReminderOn: false,
    showAs: 'free'
  });

  const addEvent = async (calendarId, eventData, depth = 0) => {
    const url = graphConfig.calendarsEndpoint + `/${calendarId}/events`;
    await callMsGraph()
      .post(url, eventData)
      .catch((err) => {
        const errors = JSON.parse(err?.request?.response);
        if (errors && depth < 5) {
          wait(depth * 500).then(() => addEvent(calendarId, eventData, depth + 1));
        }
      });
  };

  const updateEvent = async (calendarId, eventData, eventId, depth = 0) => {
    const url = graphConfig.calendarsEndpoint + `/${calendarId}/events/${eventId}`;
    await callMsGraph()
      .patch(url, eventData)
      .catch((err) => {
        const errors = JSON.parse(err?.request?.response);
        if (errors && depth < 5) {
          wait(depth * 500).then(() => updateEvent(calendarId, eventData, eventId, depth + 1));
        }
      });
  };

  const deleteEvent = async (calendarId, eventId, depth = 0) => {
    const url = graphConfig.calendarsEndpoint + `/${calendarId}/events/${eventId}`;
    await callMsGraph()
      .delete(url)
      .catch((err) => {
        const errors = JSON.parse(err?.request?.response);
        if (errors && depth < 5) {
          wait(depth * 500).then(() => deleteEvent(calendarId, eventId, depth + 1));
        }
      });
  };

  const shareEventsWithExistedCalendar = (calendarId, fullName, events, employment) => {
    const addedEvent =
      !!events?.length &&
      events.find(
        ({ start, subject }) =>
          formatDate(start.dateTime) === formatDate(employment.day) && subject.includes(fullName)
      );

    if (!employment['employment']?.id && !addedEvent?.id) return;

    if (!employment['employment']?.id && addedEvent?.id) {
      wait(300).then(() => deleteEvent(calendarId, addedEvent.id));
      return;
    }

    const eventName = getEventName(fullName, employment);
    const eventData = getEventData(eventName, employment);

    if (!addedEvent) {
      wait(300).then(() => addEvent(calendarId, eventData));
      return;
    }

    if (addedEvent?.subject !== eventName) {
      wait(300).then(() => updateEvent(calendarId, eventData, addedEvent.id));
    }
  };

  const shareEventsWithNewCalendar = (calendarId, fullName, employment) => {
    const eventName = getEventName(fullName, employment);
    const eventData = getEventData(eventName, employment);
    wait(300).then(() => addEvent(calendarId, eventData));
  };

  return { shareEventsWithNewCalendar, shareEventsWithExistedCalendar };
};
