import React from 'react';
import styles from './form.module.scss';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { allKeyboardLatSymbolsWithoutSpaces } from 'helpers/AppHelpers';

import { useTeamMemberSelector } from 'hooks/TeamMember';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

export default function GeneralDetails() {
  const { setValue } = useFormContext();

  const { creationFormData, currentTeamMember } = useTeamMemberSelector();
  const { clearances } = creationFormData;
  const { isUserLinked } = currentTeamMember;

  const handleClearanceSelect = (selectedItem) =>
    setValue(selectedItem.name, selectedItem.value, { shouldDirty: true });

  return (
    <>
      <h2>General Details</h2>
      <div className={clsx(styles.general, styles.disableMargin)}>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>ID Number</label>
            <FormInputText name="idNumber" options={{ max: 100, isDisabled: true }} />
          </div>
        </div>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>Hire Date</label>
            <div className={styles.general__block_input}>
              <FormDatePicker name="hireDate" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.general}>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>First Name*</label>
            <FormInputText name="firstName" options={{ max: 100 }} />
          </div>
          <div className={styles.general__block_row}>
            <label>Last Name*</label>
            <FormInputText name="lastName" options={{ max: 100 }} />
          </div>
          <div className={styles.general__block_row}>
            <label>E-mail address*</label>
            <FormInputText
              name="email"
              options={{
                max: 100,
                rule: allKeyboardLatSymbolsWithoutSpaces,
                isDisabled: !!isUserLinked
              }}
            />
          </div>
          <div className={styles.general__block_row}>
            <label>Clearance</label>
            <FormSelectInput
              clearable
              name="clearance"
              options={clearances || []}
              onSelect={handleClearanceSelect}
            />
          </div>
          <div className={clsx(styles.general__block_row, styles.checkbox)}>
            <label>Do Not Drive</label>
            <FormCheckbox name="doNotDrive" />
          </div>
        </div>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>Home address</label>
            <FormInputText name="homeAddress1" options={{ max: 100 }} />
          </div>
          <div className={styles.general__block_row}>
            <label></label>
            <FormInputText name="homeAddress2" options={{ max: 100 }} />
          </div>
          <div className={styles.general__block_row}>
            <label>Phone number</label>
            <FormInputText name="phoneNumber" options={{ max: 40 }} />
          </div>
          <div className={styles.general__block_row}>
            <label>Airport</label>
            <FormInputText name="airport" options={{ max: 40 }} />
          </div>
        </div>
      </div>
    </>
  );
}
