import api from '../api';

export const getConfigurationListApi = () =>
  api.get(`/LooseItemConfigurations`).then((res) => res.data);

export const createConfigurationApi = (params) =>
  api.post(`/LooseItemConfiguration`, params).then((res) => res.data);

export const updateConfigurationApi = (params) =>
  api.put(`/LooseItemConfiguration`, params).then((res) => res.data);

export const getSingleConfigurationApi = (id) =>
  api.get(`/LooseItemConfiguration?id=${id}`).then((res) => res.data);

export const getConfigurationCreationFormApi = () =>
  api.get('/LooseItemConfiguration/CreationForm').then((res) => res.data);

export const deleteConfigurationApi = (id) =>
  api.delete(`/LooseItemConfiguration?id=${id}`).then((res) => res.data);
