import { dateTimeFormat, formatDate } from 'helpers/AppHelpers';

export const getGeneralDetailsData = (currentLeaveList) => [
  { label: 'Location', value: currentLeaveList?.location?.siteCode || '' },
  { label: 'Sublocation', value: currentLeaveList?.sublocation?.name || '' },
  {
    label: 'Project Number',
    value:
      currentLeaveList?.project?.jobNumber && currentLeaveList?.project?.name
        ? currentLeaveList.project.jobNumber + ' ' + currentLeaveList.project.name
        : currentLeaveList?.project?.jobNumber || currentLeaveList?.project?.name || ''
  },
  { label: 'POC Name', value: currentLeaveList?.pocName || '' },
  { label: 'POC Number', value: currentLeaveList?.pocNumber || '' },
  { label: 'Status', value: currentLeaveList?.status?.displayName || '' },
  {
    label: 'Created by',
    value:
      currentLeaveList?.createdByUser?.firstName + ' ' + currentLeaveList?.createdByUser?.lastName
  },
  { label: 'Created', value: formatDate(currentLeaveList?.createdAtUtc, dateTimeFormat) }
];
