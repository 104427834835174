import React from 'react';
import styles from './components.module.scss';

import { allKeyboardLatSymbols } from 'helpers/AppHelpers';

import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormInputWithCreation from 'components/FormComponents/FormInputWithCreation/FormInputWithCreation';

export default function LocationSublocations() {
  return (
    <div className={styles.sublocations}>
      <div className={styles.sublocations__block}>
        <h3 className={styles.title}>Sublocations</h3>
        <div className={styles.items}>
          <div className={styles.items__field}>
            <label>Enter a comma-separated list of locations names</label>
            <FormInputWithCreation
              name="sublocations"
              hintText="After entering Sublocation press “Enter” button"
              modifyText={(str) => str.replace(allKeyboardLatSymbols, '').substring(0, 255)}
              transformItem={(str) => ({ name: str.trim() })}
            />
          </div>
          <FormChipsContainer fieldName="sublocations" />
        </div>
      </div>
    </div>
  );
}
