import React from 'react';

import { useNotificationCenterSelector } from 'hooks/NotificationCenter';

import GeneralReportTableRow from './GeneralReportTableRow';
import CommonTableRow from './CommonTableRow';
import LooseItemsTableRow from './LooseItemsTableRow';
import RequestOrderTableRow from './RequestOrderTableRow';
import LocationReportTableRow from './LocationReportTableRow';
import TeamMemberStatusTableRow from './TeamMemberStatusTableRow';

import { tabs } from '../../../../../helpers';

const tabComponentMap = {
  [tabs.looseItems]: LooseItemsTableRow,
  [tabs.generalReport]: GeneralReportTableRow,
  [tabs.requestOrder]: RequestOrderTableRow,
  [tabs.locationReport]: LocationReportTableRow,
  [tabs.teamMemberStatus]: TeamMemberStatusTableRow
};

export default function DynamicTableRows({ rows, onEdit, onRemove }) {
  const { selectedTab } = useNotificationCenterSelector();

  const SelectedComponent = tabComponentMap[selectedTab] || CommonTableRow;

  return rows.map((row) => (
    <SelectedComponent key={row.id} row={row} onEdit={onEdit} onRemove={onRemove} />
  ));
}
