import { isSuccessfulStatus } from 'helpers/AppHelpers';
import api from '../api';
import {
  SET_ASSETS_DASHBOARDS_TAB,
  SET_ASSETS_DASHBOARDS_TAB_CONFIG,
  SET_ASSETS_DASHBOARDS_VERIFICATION_STATISTIC,
  SET_ASSETS_DASHBOARDS_FILTER_CRITERIA,
  SET_ASSETS_DASHBOARDS_INSPECTION_STATISTIC,
  CLEAR_ASSETS_DASHBOARDS_STATE,
  CLEAR_ASSETS_DASHBOARDS_TAB_STATE
} from '../constants/reduceConstants';

export const getDashboardConfig = (query) => (dispatch) =>
  api.assetsDashboards
    .getDashboardConfigApi(query)
    .then((res) => {
      dispatch({ type: SET_ASSETS_DASHBOARDS_TAB_CONFIG, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateTabConfig = (data) => (dispatch) =>
  api.assetsDashboards
    .updateTabConfigApi(data)
    .then((res) => {
      if (isSuccessfulStatus(res.status)) {
        dispatch({ type: SET_ASSETS_DASHBOARDS_TAB_CONFIG, payload: res.data });
      }
      return res.data;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const updateSectionConfig = (data) => () =>
  api.assetsDashboards
    .updateSectionConfigApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err?.request?.response);
      return errors;
    });

export const getFilterCriteria = () => (dispatch) =>
  api.assetsDashboards
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_ASSETS_DASHBOARDS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getAssetOptions = (q) => () =>
  api.assetsDashboards
    .getAssetOptionsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getVerificationStatistic = () => (dispatch) =>
  api.assetsDashboards
    .getVerificationStatisticApi()
    .then((res) => {
      dispatch({ type: SET_ASSETS_DASHBOARDS_VERIFICATION_STATISTIC, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getInspectionStatistic = () => (dispatch) =>
  api.assetsDashboards
    .getInspectionStatisticApi()
    .then((res) => {
      dispatch({ type: SET_ASSETS_DASHBOARDS_INSPECTION_STATISTIC, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getAssetsDashboardsCsvResource = (q) => () =>
  api.assetsDashboards
    .getAssetsDashboardsCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setTab = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSETS_DASHBOARDS_TAB, payload });
};

export const clearTabState = (payload) => (dispatch) => {
  dispatch({ type: CLEAR_ASSETS_DASHBOARDS_TAB_STATE, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_ASSETS_DASHBOARDS_STATE });
};
