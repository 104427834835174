import React from 'react';
import styles from './components.module.scss';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import DeliveryItemsTable from './DeliveryItemsTable';
import { useFormContext } from 'react-hook-form';

function ItemsForDeliveryForm() {
  const { formState } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = 'billOfLadingItems' in errors;

  return (
    <AccordionWrapper title="Items for Delivery" isFormInvalid={isFormInvalid}>
      <div className={styles.items}>
        <DeliveryItemsTable />
        <div className={styles.record}>
          <label>Deficiencies upon delivery</label>
          <div className={styles.record__textarea}>
            <FormTextArea
              name="deficienciesUponDelivery"
              options={{ rule: allKeyboardLatSymbols, max: 1000 }}
            />
          </div>
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default ItemsForDeliveryForm;
