import React, { useEffect, useRef, useState } from 'react';
import styles from './components.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import {
  CREATE_TEAM_MEMBER,
  CREATE_TEAM_MEMBER_TYPE,
  EXIT_WITHOUT_SAVING,
  RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER,
  RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER_TYPE,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { TEAM_MEMBER_DETAILED_PATH, TEAM_MEMBER_LIST_PATH } from 'constants/routeConstants';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTeamMemberActions, useTeamMemberSelector } from 'hooks/TeamMember';

import { getBackendErrors, truncateString } from 'helpers/AppHelpers';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';

export default function TeamMemberEditWrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const { currentTeamMember, unsavedFormData } = useTeamMemberSelector();
  const {
    createTeamMemberAction,
    updateTeamMemberAction,
    restoreTeamMemberAction,
    setUnsavedFormDataAction,
    getTeamMemberAction,
    getCreationFormAction,
    clearStateAction
  } = useTeamMemberActions();

  const breadcrumbs = useRef([{ name: 'Create Team Member' }]);

  const { formState, getValues, reset, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (id) {
      getTeamMemberAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentTeamMember?.id) {
      reset(currentTeamMember);
    }
  }, [currentTeamMember]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  useEffect(() => {
    if (id && currentTeamMember?.id) {
      const { firstName, lastName } = currentTeamMember;
      breadcrumbs.current = [
        {
          path: `${TEAM_MEMBER_DETAILED_PATH}/${id}`,
          name: truncateString(firstName + ' ' + lastName, 33)
        },
        {
          name: 'Edit Team Member'
        }
      ];
    }
  }, [currentTeamMember]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const closeModal = () => setModalData({ isOpened: false });
  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const getBack = () =>
    history.push(id ? `${TEAM_MEMBER_DETAILED_PATH}/${id}` : TEAM_MEMBER_LIST_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const showRestorePopup = (errors) => {
    setModalData({
      ...RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER,
      deletedTeamMemberId: +errors.deletedTeamMemberId[0]
    });
  };
  const handleResponse = (err) => {
    if (!err?.errors) return;
    if ('deletedTeamMemberId' in err?.errors) {
      showRestorePopup(err.errors);
    } else {
      getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    }
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case CREATE_TEAM_MEMBER_TYPE:
        closeModal();
        createTeamMemberAction(getValues()).then(handleResponse);
        break;
      case UPDATE_TEAM_MEMBER_TYPE:
        updateTeamMemberAction(getValues()).then(handleResponse);
        closeModal();
        break;
      case RESTORE_TEAM_MEMBER_BY_NEW_TEAM_MEMBER_TYPE:
        restoreTeamMemberAction(modalData.deletedTeamMemberId);
        closeModal();
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;
    if (id) {
      setModalData(UPDATE_TEAM_MEMBER);
    } else {
      setModalData(CREATE_TEAM_MEMBER);
    }
  };

  const validationRules = [
    { name: 'firstName', inputType: 'text', errorMessage: 'First Name field is required' },
    { name: 'lastName', inputType: 'text', errorMessage: 'Last Name field is required' },
    { name: 'email', inputType: 'email-required', errorMessage: 'Email field is required' },
    { name: 'status', inputType: 'array', errorMessage: 'Employment field is required' },
    { name: 'departments', inputType: 'array', errorMessage: 'Department field is required' },
    { name: 'capacity', inputType: 'array', errorMessage: 'Capacity field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    validationRules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(
          name,
          name === 'email'
            ? getErrorsProperties(getValues('email') ? 'Invalid email' : 'Email field is required')
            : getErrorsProperties(errorMessage),
          { shouldFocus: true }
        );
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit Team Member' : 'Create Team Member'}</h1>
          {isMobile && <BackButton onCancel={handleBackClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
