import { useDispatch } from 'react-redux';

import {
  getSinglePicklist,
  getPicklists,
  setFilter,
  getFilterCriteria,
  clearState
} from 'actions/pickListActions';

export function usePicklistActions() {
  const dispatch = useDispatch();

  const getPicklistsAction = (q) => dispatch(getPicklists(q));
  const getPicklistAction = (id) => dispatch(getSinglePicklist(id));

  const setFilterAction = (data) => dispatch(setFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const clearStateAction = () => dispatch(clearState());

  return {
    getPicklistsAction,
    setFilterAction,
    getPicklistAction,
    getFilterCriteriaAction,
    clearStateAction
  };
}
