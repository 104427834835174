import {
  ASSET_REPORT_EDIT_PATH,
  GENERAL_REPORT_EDIT_PATH,
  LOCATION_REPORT_EDIT_PATH
} from 'constants/routeConstants';

const REPORT_TYPES = Object.freeze({
  ASSET: 'Asset',
  LOCATION: 'Location',
  GENERAL: 'General'
});

const getRootPath = (type) => {
  const paths = {
    [REPORT_TYPES.ASSET]: ASSET_REPORT_EDIT_PATH,
    [REPORT_TYPES.LOCATION]: LOCATION_REPORT_EDIT_PATH,
    [REPORT_TYPES.GENERAL]: GENERAL_REPORT_EDIT_PATH
  };

  return paths[type];
};

const generateUrl = (type, values) => {
  let url = `${getRootPath(type)}?generalReportTemplateId=${values.template.id}`;

  if (type === REPORT_TYPES.ASSET) {
    url = url + `&assetId=${values.asset.id}`;
  }

  if (type === REPORT_TYPES.LOCATION) {
    const { location } = values;
    const { isProject } = location;

    const prefix = isProject ? '&projectId=' : '&locationId=';
    url = url + prefix + '' + `${isProject ? location.project.id : location.location.id}`;
  }

  return url;
};

const generateExistedReportUrl = (reportId) => `${LOCATION_REPORT_EDIT_PATH}/${reportId}`;

export { REPORT_TYPES, generateUrl, generateExistedReportUrl };
