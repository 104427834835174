import { SUCCESS_CREATE_ROOT_CAUSE, SUCCESS_UPDATE_ROOT_CAUSE } from 'constants/infoSnackbarData';
import api from '../api';
import {
  SET_ROOT_CAUSES_FILTER,
  CLEAR_ROOT_CAUSES_STATE,
  SET_LOAD_MORE_FLAG,
  SET_ROOT_CAUSE_CREATION_FORM
} from 'constants/reduceConstants';
import { TICKET_ROOT_CAUSES_PATH } from 'constants/routeConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';
import { browserHistory } from 'browserHistory';

export const getRootCauses = (query) => (dispatch) =>
  api.ticketRootCauses
    .getRootCausesApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getRootCause = (q) => () =>
  api.ticketRootCauses
    .getSingleRootCauseApi(q)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(TICKET_ROOT_CAUSES_PATH);
    });

export const getCreationForm = () => (dispatch) =>
  api.ticketRootCauses
    .getCreationFormApi()
    .then((res) => {
      dispatch({ type: SET_ROOT_CAUSE_CREATION_FORM, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const createRootCause = (data) => () =>
  api.ticketRootCauses
    .createRootCauseApi(data)
    .then(() => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_ROOT_CAUSE);
      browserHistory.push(TICKET_ROOT_CAUSES_PATH);
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const updateRootCause = (data) => () =>
  api.ticketRootCauses
    .updateRootCauseApi(data)
    .then(() => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_ROOT_CAUSE);
      browserHistory.push(TICKET_ROOT_CAUSES_PATH);
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ROOT_CAUSES_FILTER, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_ROOT_CAUSES_STATE });
};
