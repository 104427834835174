import React from 'react';
import styles from './commonComponents.module.scss';
import SectionSubtitle from './SectionSubtitle';
import { Controller, useFormContext } from 'react-hook-form';
import { useUserConfig } from 'hooks/useUserConfig';
import { useParams } from 'react-router-dom';

export default function CreatorAndEditorsDetails({ reportType }) {
  const { id } = useParams();
  const { control, getValues } = useFormContext();
  const { userEmail, userFirstName, userLastName } = useUserConfig();

  return (
    <div className={styles.creator}>
      {reportType === 'location' ? (
        <div className={styles.creator_field}>
          <span>Created by</span>
          <b>
            {id
              ? getValues('createdByUser')?.firstName + ' ' + getValues('createdByUser')?.lastName
              : userFirstName + ' ' + userLastName}
          </b>
        </div>
      ) : (
        <SectionSubtitle>
          This report is to be completed by the Site Manager or a representative designated by the
          SM. All submissions should be performed NO LATER THAN 11:59PM EST for the day of the
          reporting period.
        </SectionSubtitle>
      )}
      <div className={styles.creator_field}>
        <span>Creator’s e-mail address</span>
        <b>{id ? getValues('createdByUser')?.email || '' : userEmail || ''}</b>
      </div>
      <Controller
        control={control}
        name="additionalContributors"
        render={({ field: { value } }) => (
          <div className={styles.creator_field}>
            <span>Additional Contributions by</span>
            <b>{value?.join(', ') || ''}</b>
          </div>
        )}
      />
    </div>
  );
}
