export const REQUIRED_LICENSE_PREFIX_TYPES = ['Vehicle', 'Trailer'];

export const MAX_FIELD_LENGTH = 60;

const defaultLicenseValue = {
  dotRegistrationExpirationAtUtc: null,
  hasInsuranceCert: true,
  licensePlate: '',
  licenseState: null,
  make: '',
  model: '',
  modelYear: 0,
  registrationExpirationAtUtc: null
};

export const isLicenseBlockAvailable = (prefixType) =>
  REQUIRED_LICENSE_PREFIX_TYPES.includes(prefixType);

export const getLicenseValue = (prefixType) => {
  return isLicenseBlockAvailable(prefixType) ? defaultLicenseValue : null;
};

export const modifyFields = (arr) =>
  arr.map((field) => ({ assetField: field, value: field?.value || null }));

export const splitFieldsForRendering = (fields) => {
  const maxCount = fields.length;
  const firstArray = fields?.slice(0, Math.ceil(maxCount / 2)).filter(Boolean);
  const secondArray = fields?.slice(Math.ceil(maxCount / 2), maxCount).filter(Boolean);

  return [firstArray, secondArray];
};

const validateField = (name, errorMessage, isInvalid) => ({ name, errorMessage, isInvalid });
export const getValidatedFields = (values) => {
  const { assetPrefix, rental, lastLocationHistory, qty } = values;

  return [
    validateField('assetPrefix', 'This field is required', !assetPrefix?.id),
    validateField('rental.rentalStatus', 'This field is required', !rental?.rentalStatus?.name),
    validateField(
      'lastLocationHistory.location',
      'This field is required',
      !lastLocationHistory?.location?.id
    ),
    validateField('qty', 'Can’t be less than "1" and more than "100"', qty < 1 || qty > 100)
  ].filter(({ isInvalid }) => isInvalid);
};
