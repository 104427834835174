import React from 'react';
import styles from './Calendar.module.scss';

import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, MenuItem, MenuList, Paper } from '@material-ui/core';

import downloadIcon from 'assets/images/pdf_download.svg';
import cancelActionIcon from 'assets/images/cancel.svg';
import setScheduleIcon from 'assets/images/setSchedule.svg';

import { useUserConfig } from 'hooks/useUserConfig';

export default function MoreButton({ onDownload, onSetSchedule, onCancelAction }) {
  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const trigger = (
    <Button className={styles.dotsButton}>
      <MoreHorizIcon />
    </Button>
  );

  const tooltip = (
    <Paper classes={{ root: styles.paper }}>
      <MenuList>
        <MenuItem onClick={onSetSchedule}>
          <img src={setScheduleIcon} alt="download_icon" />
          Set Schedule
        </MenuItem>
        <MenuItem onClick={onDownload}>
          <img src={downloadIcon} alt="download_icon" />
          Download
        </MenuItem>
        {(isAdminUser || isPersonnelManagerUser) && (
          <MenuItem onClick={onCancelAction}>
            <img src={cancelActionIcon} alt="cancel_action" />
            Cancel Action
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );

  return <TooltipWrapper tooltip={tooltip} trigger={trigger} />;
}
