import { createSelector } from 'reselect';

const ticketsDashboardData = (state) => state.ticketsDashboardData;

export const selectTabData = () => createSelector([ticketsDashboardData], (state) => state.tab);

export const selectTabConfigData = () =>
  createSelector([ticketsDashboardData], (state) => state.tabConfig);

export const selectBaseStatisticsData = () =>
  createSelector([ticketsDashboardData], (state) => state.baseStatistics);

export const selectFilterCriteriaData = () =>
  createSelector([ticketsDashboardData], (state) => state.filterCriteria);

export const selectTurnoverStatisticsData = () =>
  createSelector([ticketsDashboardData], (state) => state.turnoverStatistics);

export const selectFlowStatisticsData = () =>
  createSelector([ticketsDashboardData], (state) => state.flowStatistics);
