import React from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import clsx from 'clsx';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import styles from './AssetsTable.module.scss';

const AssetsTable = ({ configOptions }) => {
  const { control, setValue, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'assetPrefixes'
  });

  const watchedAssets = useWatch({
    control,
    name: 'assetPrefixes'
  });

  const getSelectedAssets = () => {
    const selectedAssets = getValues('assetPrefixes') || [];
    return selectedAssets
      .flatMap((row) => row.assets?.filter(Boolean).map((asset) => asset.id))
      .filter(Boolean);
  };

  const getFilteredAssetPrefixes = (index) => {
    const selectedAssets = getSelectedAssets();
    return configOptions.assetPrefixes[index]?.assets.filter(
      (asset) => !selectedAssets.includes(asset.id)
    );
  };

  const handleSelect = (name, value) => {
    setValue(name, value);
  };

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell className={styles.headerPrefix}>Prefix</StyledTableCell>
            <StyledTableCell className={styles.headerQTY}>Required QTY</StyledTableCell>
            <StyledTableCell className={styles.headerAssets}>Assets</StyledTableCell>
            <StyledTableCell className={styles.headerOrigin}>
              Point of origin (Sublocation)
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <StyledTableRow key={`assets-${row.id}`}>
              <StyledTableCell className={styles.rowPrefix}>{row.prefix}</StyledTableCell>
              <StyledTableCell className={styles.rowQTY}>{row.requiredQuantity}</StyledTableCell>
              <StyledTableCell
                style={{ gridTemplateRows: `repeat(${row.requiredQuantity}, 1fr)` }}
                className={styles.rowAssets}>
                {[...Array(row.requiredQuantity)].map((_, idx) => (
                  <FormSearchInput
                    key={`assetPrefixes[${index}-${idx}]`}
                    name={`assetPrefixes[${index}].assets[${idx}]`}
                    options={
                      watchedAssets[index].assets[idx]?.id
                        ? [
                            ...getFilteredAssetPrefixes(index, idx),
                            getValues(`assetPrefixes[${index}].assets[${idx}]`)
                          ].sort((a, b) => a.id - b.id)
                        : getFilteredAssetPrefixes(index, idx)
                    }
                    optionType="assetPrefixTypeWithLocation"
                    onSelect={(_, value) =>
                      handleSelect(`assetPrefixes[${index}].assets[${idx}]`, value)
                    }
                    classes={clsx(
                      styles.selectInput,
                      (idx + 1) % 2 === 0 ? styles.selectInputHighlighted : ''
                    )}
                    errorClasses={styles.errorMessage}
                    clearable
                    disableError
                  />
                ))}
              </StyledTableCell>
              <StyledTableCell
                style={{ gridTemplateRows: `repeat(${row.requiredQuantity}, 1fr)` }}
                className={styles.rowOrigin}>
                {getValues('assetPrefixes')[index]?.assets.map((asset, assetIndex) => (
                  <div
                    key={`origin-${assetIndex}`}
                    className={clsx(
                      styles.location,
                      (assetIndex + 1) % 2 === 0 ? styles.locationHighlighted : ''
                    )}>
                    {asset?.location?.siteCode}{' '}
                    {asset?.sublocation?.name && `(${asset?.sublocation?.name})`}
                  </div>
                ))}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetsTable;
