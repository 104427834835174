import React from 'react';
import PropTypes from 'prop-types';

import MarkerClusterGroup from 'react-leaflet-markercluster';

import { MapMarker } from './Marker';

/**
 * @typedef {Object} MarkerClusterProps
 * @property {[]} markers
 */

/**
 * @param {MarkerClusterProps} props
 */

export const MarkerCluster = ({ markers, popupContent }) => {
  return (
    <MarkerClusterGroup maxClusterRadius={20}>
      {markers.map(({ position, icon, info }, index) => (
        <MapMarker
          key={index}
          position={position}
          icon={icon}
          popupContent={popupContent}
          info={info}
        />
      ))}
    </MarkerClusterGroup>
  );
};

MarkerCluster.propTypes = {
  markers: PropTypes.array.isRequired
};
