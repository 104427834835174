import React from 'react';
import styles from '../LeaveListDetailed.module.scss';
import { Button } from '@material-ui/core';

export default function ChangeStatusButton({ isStatus, onChangeStatus, statuses }) {
  const handleClick = () => onChangeStatus();

  return (
    !isStatus('Completed') && (
      <Button
        className={styles.start_button}
        classes={{ disabled: styles.start_button__disabled }}
        onClick={handleClick}>
        <span>
          {isStatus(statuses.open)
            ? 'Start delivery'
            : isStatus(statuses.delivery)
            ? 'Complete'
            : ''}
        </span>
      </Button>
    )
  );
}
