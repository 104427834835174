import React, { useState } from 'react';
import styles from './FormMultipleAssetSelect.module.scss';

import { Controller, useFormContext } from 'react-hook-form';

import MultipleAssetSelectionField from 'components/MultipleAssetSelectionField/MultipleAssetSelectionField';
import { QRCodeScanner } from 'components/QR';
import { FormFieldError } from 'components/FormComponents';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useDispatch } from 'react-redux';

import {
  enqueueErrorSnackbar,
  getAssetIdFromUrl,
  getErrorFindAssetMessage
} from 'helpers/AppHelpers';

import { scanAsset } from 'actions/commonActions';

import { IconButton } from '@material-ui/core';

import qrCodeIco from 'assets/images/qrcode.svg';

import clsx from 'clsx';

export default function FormMultipleAssetSelect({
  name,
  classes,
  onSelectTriggered,
  withQr,
  queryOptions,
  index,
  validateScannedAsset,
  getSnackbarScanErrorMessage,
  disableErrorMessage
}) {
  const dispatch = useDispatch();
  const isMobile = useMobileViewport();

  const [openQR, setOpenQR] = useState(false);

  const { control, setValue, clearErrors, formState, getValues } = useFormContext();
  const { errors } = formState;

  const getUniqueAssets = (assetsArray) =>
    assetsArray.filter(
      (value, index, array) => array.findIndex((item) => item.drCode === value.drCode) === index
    );

  const addAssetsToField = (data) => {
    const selectedAssets = getValues(name) || [];
    const codes = getUniqueAssets(selectedAssets.concat(data));
    errors[name]?.message && clearErrors(name);
    setValue(name, codes);
    onSelectTriggered && onSelectTriggered(codes, { index });
  };

  const selectAsset = (codes) => {
    errors[name]?.message && clearErrors(name);
    const assets = getUniqueAssets(codes);
    setValue(name, assets);
    onSelectTriggered && onSelectTriggered(assets, { index });
  };

  const pasteAssets = (response) => {
    if (!response?.value?.length) return;
    addAssetsToField(response.value);
  };

  const handleQRScan = (url) => {
    const assetId = getAssetIdFromUrl(url);

    dispatch(scanAsset(assetId)).then((res) => {
      if (!res?.id) return;
      const { drCode, deleted } = res;
      if (deleted) {
        enqueueErrorSnackbar(getErrorFindAssetMessage(drCode));
        return;
      }

      const isAssetValid = validateScannedAsset ? validateScannedAsset(res) : true;

      if (isAssetValid) {
        addAssetsToField([res]);
      } else {
        enqueueErrorSnackbar(getSnackbarScanErrorMessage(res));
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      {openQR && withQr && (
        <QRCodeScanner open={openQR} setOpen={setOpenQR} onScan={handleQRScan} />
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <div className={clsx(classes && classes, styles.field)}>
            <MultipleAssetSelectionField
              name={name}
              value={value || []}
              onSelect={selectAsset}
              onPaste={pasteAssets}
              styleType={!isMobile && 'extended'}
              isInvalid={!!error}
              queryOptions={queryOptions}
            />
            {!!error && !disableErrorMessage && <FormFieldError>{error.message}</FormFieldError>}
            {withQr && (
              <IconButton onClick={() => setOpenQR(true)} className={styles.field__qr}>
                <img src={qrCodeIco} alt="qrcode" />
              </IconButton>
            )}
          </div>
        )}
      />
    </div>
  );
}
