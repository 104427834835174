import React from 'react';
import styles from '../Form.module.scss';

import { FormDayPicker, FormLabel, FormSelectInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { useFormContext } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';

import clsx from 'clsx';

import SectionTitle from '../SectionTitle';

export function Fields() {
  const isMobile = useMobileViewport();
  const { watch, setValue } = useFormContext();

  const prefixWatcher = watch('assetPrefix');
  const fieldsWatcher = watch('assetFields');

  const maxFieldCountPerColumn = Math.ceil(fieldsWatcher.length / 2);
  const height = isMobile
    ? 'auto'
    : maxFieldCountPerColumn * 34 + (maxFieldCountPerColumn - 1) * 20;

  const renderInputField = (field, index) => {
    switch (field.type) {
      case 'String':
        return (
          <FormInputText
            name={`assetFields[${index}].value`}
            options={{ max: field?.constraints?.maxLength || 60 }}
          />
        );
      case 'Int':
      case 'Float':
        return (
          <FormInputText
            name={`assetFields[${index}].value`}
            options={{ type: 'quantity', focus: true }}
          />
        );
      case 'DateTime':
        return <FormDayPicker name={`assetFields[${index}].value`} />;
      case 'Bool':
        return <FormCheckbox name={`assetFields[${index}].value`} />;
      case 'Enum':
        return (
          <FormSelectInput
            name={`assetFields[${index}].value`}
            options={field?.availableValues || []}
            onSelect={({ name, value }) => setValue(name, value, { shouldDirty: true })}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.general}>
      <SectionTitle>{prefixWatcher?.prefixType?.name || 'Container'} Details</SectionTitle>
      <div
        className={styles.fields}
        style={{
          minHeight: 34,
          height,
          maxHeight: 'fit-content'
        }}>
        {fieldsWatcher.map(({ assetField }, index) => (
          <div
            key={assetField.id}
            className={clsx(styles.fields__row, assetField.type === 'Bool' && styles.checkbox)}>
            <FormLabel>{assetField.displayName || ''}</FormLabel>
            {renderInputField(assetField, index)}
          </div>
        ))}
      </div>
    </div>
  );
}
