import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getListApi = (query) =>
  api.get(`/BolLogs?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = () => api.get('/BolLog/FilterCriteria').then((res) => res.data);

export const getCreationFormApi = (query) =>
  api.get(`/BolLog/CreationForm?${queryToString(query)}`).then((res) => res);

export const getCsvResourceApi = (q) =>
  api.get(`/BolLogs/Serialized?${queryToString(q)}`).then((res) => res.data);

export const getLogApi = (q) => api.get(`/BolLog?${queryToString(q)}`).then((res) => res.data);

export const createLogApi = (params) => api.post('/BolLogs', params).then((res) => res.data);

export const updateLogApi = (params) => api.put('/BolLog', params).then((res) => res.data);

export const removeLogApi = (id) => api.delete(`/BolLog?id=${id}`).then((res) => res);

export const getCsvTemplateApi = () => api.get('/BolLogs/Deserialized').then((res) => res);

export const importCsvApi = (file) => api.post('/BolLogs/Deserialized', file).then((res) => res);

export const uploadLogsApi = (data) => api.put('/BolLogs/Deserialized', data).then((res) => res);
