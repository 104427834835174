import { useDispatch } from 'react-redux';
import {
  clearTeamMemberTrackerState,
  getBreakdownTrackerData,
  getFilterCriteria,
  getMasterTrackerData,
  getTrackerConfigData,
  sendGroupNotification,
  setBreakdownFilterData,
  setMasterFilterData,
  setToggleTab,
  setTrackerDay,
  setTrackerFilter,
  setTrackerResponseReceived,
  setTrackerTableData,
  updateTrackerConfigData
} from 'actions/teamMemberTrackerActions';
import {
  cancelLastOperation,
  getPermissionToCancelLastOperation,
  getSingleTeamMember
} from 'actions/teamMemberActions';
import { getEvent } from 'actions/setSchedulePopupActions';

export function useTrackerActions() {
  const dispatch = useDispatch();

  const getTrackerConfigDataAction = (q) => dispatch(getTrackerConfigData(q));
  const updateTrackerConfigDataAction = (data, q) => dispatch(updateTrackerConfigData(data, q));

  const getMasterTrackerDataAction = (q) => dispatch(getMasterTrackerData(q));
  const getBreakdownTrackerDataAction = (q) => dispatch(getBreakdownTrackerData(q));

  const setResponseReceivedAction = (flag) => dispatch(setTrackerResponseReceived(flag));

  const setTrackerTableDataAction = (data) => dispatch(setTrackerTableData(data));
  const getTrackerDataAction = (q, options) => {
    const isNextPage = options?.isNextPage || false;
    const isMaster = options?.isMasterSelected;

    setResponseReceivedAction(false);

    return dispatch(isMaster ? getMasterTrackerData(q) : getBreakdownTrackerData(q)).then((res) => {
      if (res?.status !== 200) return;
      setFilterAction({
        filters: q.filters,
        pagination: {
          limit: res.data.limit,
          page: res.data.pageNumber,
          totalPages: res.data.totalPages
        }
      });
      !isNextPage && setTrackerTableDataAction(res.data);
      setResponseReceivedAction(true);
      return res.data;
    });
  };

  const setTabAction = (tab) => dispatch(setToggleTab(tab));

  const getFilterCriteriaAction = (q) => dispatch(getFilterCriteria(q));

  const setFilterAction = (values) => dispatch(setTrackerFilter(values));

  const setMasterFilterAction = (filter) => dispatch(setMasterFilterData(filter));
  const setBreakdownFilterAction = (filter) => dispatch(setBreakdownFilterData(filter));

  const sendGroupNotificationAction = (ids) => dispatch(sendGroupNotification(ids));

  const setDayNoteAction = (note) => dispatch(setTrackerDay(note));

  const getTeamMemberDetailsAction = (id, flag) => dispatch(getSingleTeamMember(id, flag));

  const getPermissionToCancelLastOperationAction = () =>
    dispatch(getPermissionToCancelLastOperation());
  const cancelLastOperationAction = () => dispatch(cancelLastOperation());

  const getEventAction = (q) => dispatch(getEvent(q));
  const clearStateAction = () => dispatch(clearTeamMemberTrackerState());

  return {
    getMasterTrackerDataAction,
    getTrackerConfigDataAction,
    updateTrackerConfigDataAction,
    getBreakdownTrackerDataAction,
    getTrackerDataAction,
    setTabAction,
    setMasterFilterAction,
    setBreakdownFilterAction,
    sendGroupNotificationAction,
    getFilterCriteriaAction,
    setDayNoteAction,
    getTeamMemberDetailsAction,
    setTrackerTableDataAction,
    getEventAction,
    setResponseReceivedAction,
    getPermissionToCancelLastOperationAction,
    cancelLastOperationAction,
    setFilterAction,
    clearStateAction
  };
}
