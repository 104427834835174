import React from 'react';
import styles from './components.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';

export default function RemoveButton({ title, onClick }) {
  const handleClick = () => onClick();

  return (
    <button className={styles.remove_button} onClick={handleClick}>
      <DeleteIcon />
      <span>{title}</span>
    </button>
  );
}
