import React, { useEffect, useState } from 'react';
import styles from './FieldsUpdate.module.scss';

import MainButton from 'components/StyledComponents/MainButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { FIELDS_UPDATE } from 'constants/dialogPopupsData';

import { pluck } from 'helpers/AppHelpers';
import { tabs, defaultTab } from '../helpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { getOtherTabNames, getUpdatedTabValues } from '../helpers';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useUserConfig } from 'hooks/useUserConfig';

import { FormLabel, FormMultipleAssetSelect } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { AssetFields } from './AssetFields';

export default function FieldsUpdate() {
  const tabName = tabs.fieldsUpdate;

  const { isConfigReceived, isAdminUser, isOperationsManagerUser } = useUserConfig();

  const { control, setError, getValues, setValue, clearErrors, formState, watch } =
    useFormContext();
  const getFieldName = (name) => `${tabName}.${name}`;

  const getFormValues = (name) => getValues(getFieldName(name));
  const setFormValue = (name, value) => setValue(getFieldName(name), value);

  const { fields, append, remove } = useFieldArray({
    control,
    name: getFieldName('assetFields')
  });

  const { errors } = formState;
  const isFormInvalid = () =>
    !!errors?.[tabName]?.['assets'] || !!errors?.[tabName]?.['assetFields'];

  const [assetFields, setAssetFields] = useState([]);

  const { creationForm } = useBatchAssetUpdatesSelector();
  const { getAssetFieldsAction, updateFieldsAction, setTabAction } = useBatchAssetUpdatesActions();

  const { locations } = creationForm;

  const [modalData, setModalData] = useState({});

  const updateFields = (codes, fieldsList) => {
    if (!codes.length) {
      setFormValue('assetFields', []);
      return;
    }

    const selectedFields = getFormValues('assetFields');
    const allFieldIds = pluck(fieldsList, 'id');

    const shouldDelete = selectedFields.some(({ id }) => !allFieldIds.includes(id));

    if (!shouldDelete) return;

    const idsToDelete = pluck(
      selectedFields.filter(({ id }) => !allFieldIds.includes(id)),
      'id'
    );

    const indexes = idsToDelete.map((id) => selectedFields.findIndex((field) => field.id === id));
    remove(indexes);
  };

  const getAssetsFields = (assets) => {
    if (!assets?.length) {
      setAssetFields([]);
      setFormValue('assetFields', []);
      return;
    }

    const query = {
      filters: {
        assetFieldTypeNames: ['Int', 'Float', 'String'],
        assetIds: assets.map(({ id }) => id)
      }
    };

    getAssetFieldsAction(query).then((res) => {
      updateFields(assets, res);
      setAssetFields(res);
    });
  };

  useEffect(() => {
    if (!isConfigReceived) return;
    if (isAdminUser || isOperationsManagerUser) return;

    setTabAction(defaultTab);
  }, [isConfigReceived]);

  useEffect(() => {
    getAssetsFields(getFormValues('assets'));
  }, []);

  useEffect(() => {
    return () => {
      clearErrors(tabName);
    };
  }, []);

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: getFieldName('assets'),
        errorMessage: 'Assets field is required',
        isInvalid: !getFormValues('assets')?.length
      },
      {
        name: getFieldName('assetFields'),
        errorMessage: 'Fields field is required',
        isInvalid: !getFormValues('assetFields')?.length
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;
    setModalData({ ...FIELDS_UPDATE, isOpened: true });
  };

  const handleAssetSelect = (codes) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, codes, getValues()))
    );
    errors?.[tabName]?.['assets']?.message && clearErrors(getFieldName('assets'));

    getAssetsFields(codes);
  };

  const getModifiedValues = () => {
    const newValues = getValues(tabName);
    newValues.assetFields = newValues.assetFields.map((item) => ({
      assetField: item,
      value: item?.value || null
    }));
    return newValues;
  };

  const clearForm = () => {
    setFormValue('assets', []);
    setFormValue('assetFields', []);
    setFormValue('filterLocationIds', []);
    setAssetFields([]);
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, [], getValues()))
    );
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    closeModal();
    updateFieldsAction(getModifiedValues()).then(clearForm);
  };

  const deleteField = (index) => remove(index);

  const handleFieldSelect = ({ selectionType, processedOption }) => {
    const { option } = processedOption;
    switch (selectionType) {
      case 'remove-option': {
        const selectedFields = getFormValues('assetFields');
        const index = selectedFields.findIndex(({ id }) => id === option.id);
        deleteField(index);
        break;
      }
      case 'clear':
        setFormValue('assetFields', []);
        break;
      default:
        append(option);
        break;
    }
  };

  const locationIdsWatcher = watch(getFieldName('filterLocationIds'));

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        <div className={styles.form__row}>
          <FormLabel>Narrow Assets by location</FormLabel>
          <FormAutoMultiSelect
            name={getFieldName('filterLocationIds')}
            menuItems={locations || []}
            options={{
              label: 'siteCode',
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__assets}>
          <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes*</FormLabel>
          <FormMultipleAssetSelect
            withQr
            name={getFieldName('assets')}
            onSelectTriggered={handleAssetSelect}
            queryOptions={{ locationIds: locationIdsWatcher }}
          />
        </div>
        <div className={styles.form__row}>
          <FormLabel>Fields*</FormLabel>
          <FormAutoMultiSelect
            name={getFieldName('assetFields')}
            options={{
              label: 'displayName',
              disableByObjectTracker: false,
              disableLabel: true,
              hideTags: true,
              isDisabled: !getFormValues('assets').length,
              onSelect: handleFieldSelect
            }}
            menuItems={assetFields || ''}
          />
        </div>
        <AssetFields fields={fields} onDelete={deleteField} />
      </div>
      <div className={styles.footer}>
        <MainButton
          text="update"
          type="primary"
          isDisabled={isFormInvalid()}
          action={handleUpdateClick}
        />
      </div>
    </section>
  );
}
