import React, { useRef } from 'react';
import styles from './Tables.module.scss';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';

import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';
import { ASSET_LOCATION_HISTORY_TABLE_PARAMETER } from 'constants/configTableConstants';

import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';
import { getChangedByPageFilter } from 'helpers/SummaryPagesHelpers';

import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';

import clsx from 'clsx';

export default function AssetLocationHistory() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const { getTableLimit } = useUserConfig();

  const { locationHistory, locationHistoryFilter } = useAssetSelector();
  const { getLocationHistory } = useAssetActions();

  const emptyResponse = () =>
    !locationHistory?.items?.length &&
    locationHistoryFilter?.pagination?.page === locationHistory?.pageNumber;

  const changePage = (page, param) =>
    getLocationHistory(getChangedByPageFilter(locationHistoryFilter, page), param);

  const getUpdatedByLimitFilter = () => ({
    ...locationHistoryFilter,
    pagination: {
      ...locationHistoryFilter.pagination,
      page: 1,
      limit: getTableLimit(ASSET_LOCATION_HISTORY_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  return (
    <>
      <TableContainer className={clsx(styles.table, styles.location)} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Sublocation</StyledTableCell>
              <StyledTableCell>Last Update</StyledTableCell>
              <StyledTableCell>Updated by</StyledTableCell>
              <StyledTableCell>Triggered by</StyledTableCell>
              <StyledTableCell>Last Verification</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationHistory?.items?.length ? (
              locationHistory.items.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <LinkComponent
                      name={row.location?.siteCode || ''}
                      path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                      isRedirectAvailable={!row.location?.deleted}
                      boldText
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.sublocation?.name || ''}</StyledTableCell>
                  <StyledTableCell>
                    {row['updatedAtUtc'] ? formatDate(row['updatedAtUtc']) : ''}
                  </StyledTableCell>
                  <StyledTableCell>{getUserFullName(row['changedByUser'])}</StyledTableCell>
                  <StyledTableCell>{row?.['triggerMessage'] || ''}</StyledTableCell>
                  <StyledTableCell>
                    {row?.lastVerificationDateAtUtc
                      ? formatDate(row.lastVerificationDateAtUtc, dateTimeFormat)
                      : ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={locationHistory?.items?.length}
          currentPage={locationHistoryFilter?.pagination?.page}
          totalPages={locationHistoryFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={locationHistoryFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={ASSET_LOCATION_HISTORY_TABLE_PARAMETER}
          getTableData={() => getLocationHistory(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
