import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../selectors';
import styles from './Details.module.scss';
import Subtitle from 'components/DetailsComponents/Subtitle';
import Info from '../Info';
import { emptyValue, formatDate } from 'helpers/AppHelpers';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

export default function GeneralDetails() {
  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const {
    firstName,
    lastName,
    email,
    clearance,
    homeAddress1,
    homeAddress2,
    phoneNumber,
    airport,
    idNumber,
    hireDate,
    doNotDrive
  } = currentTeamMember;

  const details = [
    [
      { id: 1, label: 'ID Number:', value: idNumber },
      { id: 2, label: 'First Name:', value: firstName },
      { id: 3, label: 'Last Name:', value: lastName },
      { id: 4, label: 'E-mail address:', value: email },
      { id: 5, label: 'Clearance:', value: clearance?.name },
      {
        id: 6,
        label: 'Do Not Drive',
        value: <CustomCheckbox value={!!doNotDrive} isDisabled />,
        isCheckbox: true
      }
    ],
    [
      { id: 7, label: 'Hire Date:', value: hireDate ? formatDate(hireDate) : '' },
      { id: 8, label: 'Home address:', value: homeAddress1 },
      { id: 9, label: emptyValue.repeat(16), value: homeAddress2 },
      { id: 10, label: 'Phone number:', value: phoneNumber },
      { id: 11, label: 'Airport:', value: airport }
    ]
  ];

  return (
    <section className={styles.wrapper}>
      <Subtitle>General Details</Subtitle>
      <div className={styles.general}>
        {details.map((block, index) => (
          <div className={styles.general__block} key={index}>
            {block.map(({ id, label, value, isCheckbox }) => (
              <Info key={id} label={label} value={value} isCheckbox={isCheckbox} />
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
