import api from '../api';
import {
  CLEAR_CREATE_BATCH_OF_ASSETS_STATE,
  SET_BATCH_OF_ASSETS_FORM_STATE,
  SET_CREATE_BATCH_OF_ASSETS_CREATION_FORM,
  SET_PREVIEW_BATCH_OF_ASSETS,
  TOGGLE_CREATION_SCREEN
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar, queryToString } from '../helpers/AppHelpers';
import { SUCCESS_CREATE_BATCH_OF_ASSETS } from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { ASSETS_LIST_PATH } from '../constants/routeConstants';

export const getCreationForm = (q) => (dispatch) => {
  api.createBatchOfAssets
    .getCreationFormApi(q)
    .then((res) => dispatch({ type: SET_CREATE_BATCH_OF_ASSETS_CREATION_FORM, payload: res }))
    .catch((err) => console.log(err));
};

export const createAssets = (data) => (dispatch) => {
  return api.createBatchOfAssets.createAssetsApi(data).then(
    (res) => {
      if ([200, 201].includes(res.status)) {
        dispatch({ type: SET_PREVIEW_BATCH_OF_ASSETS, payload: res?.data || [] });
        dispatch({ type: SET_BATCH_OF_ASSETS_FORM_STATE, payload: data });
        return res;
      }
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateAssets = (data) => () => {
  return api.createBatchOfAssets.updateAssetsApi(data).then(
    (res) => {
      if ([200, 201].includes(res.status)) {
        enqueueSuccessSnackbar(SUCCESS_CREATE_BATCH_OF_ASSETS);

        if (data?.[0]?.assetPrefix?.id) {
          const path = `${ASSETS_LIST_PATH}?${queryToString({
            prefixIds: [data[0].assetPrefix.id]
          })}`;
          browserHistory.push(path);
        } else {
          browserHistory.push(ASSETS_LIST_PATH);
        }
      }
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const toggleScreen = () => (dispatch) => {
  dispatch({ type: TOGGLE_CREATION_SCREEN });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_CREATE_BATCH_OF_ASSETS_STATE });
};
