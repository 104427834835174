import React from 'react';
import styles from './Counting.module.scss';

import clsx from 'clsx';

export default function Indicator({ color, className }) {
  return color ? (
    <span className={clsx(className, styles.indicator)} style={{ background: color }} />
  ) : null;
}
