import { createSelector } from 'reselect';

const createBatchOfAssetsData = (state) => state.createBatchOfAssetsData;

export const selectCreationFormData = () =>
  createSelector([createBatchOfAssetsData], (state) => state.creationForm);

export const selectPreviewAssetsData = () =>
  createSelector([createBatchOfAssetsData], (state) => state.previewAssets);

export const selectCreationFormStateData = () =>
  createSelector([createBatchOfAssetsData], (state) => state.formState);

export const selectCreationOpenedFlagStateData = () =>
  createSelector([createBatchOfAssetsData], (state) => state.isCreationOpened);
