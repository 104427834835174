import React from 'react';
import styles from './Notes.module.scss';
import { useSelector } from 'react-redux';
import { selectSingleProjectData } from '../../../selectors';
import Subtitle from 'components/DetailsComponents/Subtitle';

export default function Notes() {
  const { notes } = useSelector(selectSingleProjectData());

  return (
    <section className={styles.notes}>
      <Subtitle>Notes</Subtitle>
      <div className={styles.notes__block}>
        <span>{notes || ''}</span>
      </div>
    </section>
  );
}
