import React from 'react';
import styles from './Counting.module.scss';

import Indicator from './Indicator';
import StyledLink from 'components/StyledLink';

export default function CountingRow({ color, label, count, boldLabel, link }) {
  return (
    <div className={styles.row}>
      <div className={styles.row_label}>
        <Indicator color={color} />
        <span className={boldLabel && styles.bold}>{label}:</span>
      </div>
      <div className={styles.row_count}>
        {link ? (
          <StyledLink isRedirectAvailable={!!link} to={link}>
            {count}
          </StyledLink>
        ) : (
          <span>{count}</span>
        )}
      </div>
    </div>
  );
}
