import { useDispatch } from 'react-redux';
import {
  clearLocationState,
  deleteLocation,
  getAssetsFilterCriteria,
  getClosedTicketsList,
  getLocationAssetsList,
  getLocationLooseItems,
  getOpenedTicketsList,
  getRentalStatuses,
  getSingleLocation,
  setClosedTicketsListFilter,
  setLocationAssetsListFilter,
  setLocationDetailsTab,
  setLocationLooseItemsFilter,
  setOpenedTicketsListFilter,
  updateRentalStatuses
} from 'actions/locationsActions';

export function useLocationActions() {
  const dispatch = useDispatch();

  const getLocationAction = (id) => dispatch(getSingleLocation(id));
  const deleteLocationAction = (id) => dispatch(deleteLocation(id));

  const getClosedTicketsAction = (q, param) => dispatch(getClosedTicketsList(q, param));
  const setClosedTicketsFilterAction = (q) => dispatch(setClosedTicketsListFilter(q));

  const getOpenedTicketsAction = (q, param) => dispatch(getOpenedTicketsList(q, param));
  const setOpenedTicketsFilterAction = (q) => dispatch(setOpenedTicketsListFilter(q));

  const getLooseItemsAction = (q, param) => dispatch(getLocationLooseItems(q, param));
  const setLooseItemsFilterAction = (payload) => dispatch(setLocationLooseItemsFilter(payload));

  const getAssetsListAction = (q, param) => dispatch(getLocationAssetsList(q, param));
  const setAssetsListFilterAction = (payload) => dispatch(setLocationAssetsListFilter(payload));

  const getRentalStatusesAction = (data) => dispatch(getRentalStatuses(data));
  const updateRentalStatusesAction = (data) => dispatch(updateRentalStatuses(data));

  const setLocationDetailsTabAction = (data) => dispatch(setLocationDetailsTab(data));

  const clearStateAction = () => dispatch(clearLocationState());

  const getUpdatedFilter = (tableFilter, res) => ({
    ...tableFilter,
    pagination: { limit: res.limit, page: res.pageNumber, totalPages: res.totalPages },
    isDataReceived: true
  });

  const getAssets = (tableFilter, param) => {
    getAssetsListAction(tableFilter, param).then((res) => {
      const filterValues = getUpdatedFilter(tableFilter, res);
      setAssetsListFilterAction({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          sublocationIds: [],
          rentalIds: [],
          prefixIds: [],
          severities: []
        }
      });
    });
  };
  const getClosedTickets = (tableFilter, param) => {
    getClosedTicketsAction(tableFilter, param).then((res) => {
      setClosedTicketsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };
  const getOpenedTickets = (tableFilter, param) => {
    getOpenedTicketsAction(tableFilter, param).then((res) => {
      setOpenedTicketsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };
  const getLooseItems = (tableFilter, param) => {
    getLooseItemsAction(tableFilter, param).then((res) => {
      setLooseItemsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getAssetsFilterCriteriaAction = () => dispatch(getAssetsFilterCriteria());

  return {
    getLocationAction,
    deleteLocationAction,
    getClosedTicketsAction,
    setClosedTicketsFilterAction,
    getOpenedTicketsAction,
    setOpenedTicketsFilterAction,
    getLooseItemsAction,
    setLooseItemsFilterAction,
    getAssetsListAction,
    setAssetsListFilterAction,
    getRentalStatusesAction,
    updateRentalStatusesAction,
    clearStateAction,
    getLooseItems,
    getAssets,
    getClosedTickets,
    getOpenedTickets,
    setLocationDetailsTabAction,
    getAssetsFilterCriteriaAction
  };
}
