import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getSingleBillOfLadingApi = (id) =>
  api.get(`/BillOfLading?id=${id}`).then((res) => res.data);

export const getBillOfLadingListApi = (query) =>
  api.get(`/BillOfLadings?${queryToString(query)}`).then((res) => res.data);

export const getBillOfLadingLocationsApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getBillOfLadingLooseItemsApi = (query) =>
  api.get(`/LooseItems/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getCreationFormApi = (query) =>
  api.get(`/BillOfLading/CreationForm?${queryToString(query)}`).then((res) => res);

export const getFilterCriteriaApi = () =>
  api.get(`/BillOfLadings/FilterCriteria`).then((res) => res.data);

export const getBillOfLadingBlankApi = () =>
  api.get(`/BillOfLading/Template`).then((res) => res.data);

export const getBillOfLadingCsvApi = (query) =>
  api.get(`/BillOfLading/PdfResource?${queryToString(query)}`).then((res) => res.data);

export const createBOLApi = (params) => api.post('/BillOfLading', params).then((res) => res.data);

export const updateBOLApi = (params) => api.put('/BillOfLading', params).then((res) => res.data);

export const deleteBOLApi = (id) => api.delete(`/BillOfLading?id=${id}`).then((res) => res.data);

export const convertItemsToBOLItemsApi = (params) =>
  api.post('/BillOfLading/CreationForm', params).then((res) => res);
