import { BOL_LOGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, getUserFullName } from 'helpers/AppHelpers';
import { handleDateFilter, handleIdsFilter } from 'helpers/ChipGenerator';

const defaultFilter = {
  filters: {
    searchQuery: '',
    createdFrom: null,
    createdTo: null,
    createdUserIds: [],
    createdUserList: [],
    typeIds: []
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

export const generateInitialQuery = ({ filter, getTableLimit, isAllLimitEnabled }) => {
  const { filters, pagination, sortRules } = filter;

  const limit = getTableLimit(BOL_LOGS_TABLE_PARAMETER);

  return {
    filters: { ...defaultFilter.filters, ...filters },
    pagination: {
      limit,
      page:
        limit === 100 && isAllLimitEnabled(BOL_LOGS_TABLE_PARAMETER)
          ? defaultFilter.pagination.page
          : pagination?.page || defaultFilter.pagination.page
    },
    sortRules: sortRules || defaultFilter.sortRules,
    responseReceived: false
  };
};

export const tableHeadConfig = [
  { id: 1, label: 'ID', isSortable: true, parameter: 'id' },
  { id: 2, label: `Log's Type`, isSortable: true, parameter: 'type' },
  { id: 3, label: 'Origin Location', isSortable: true, parameter: 'originLocation' },
  { id: 4, label: 'Destination', isSortable: true, parameter: 'destination' },
  { id: 5, label: 'Item', isSortable: true, parameter: 'item' },
  { id: 6, label: 'QTY', isSortable: true, parameter: 'qty' },
  { id: 7, label: 'Package', isSortable: true, parameter: 'package' },
  { id: 8, label: 'Pallet', isSortable: true, parameter: 'pallet' },
  { id: 9, label: 'Date', isSortable: true, parameter: 'date' },
  { id: 10, label: 'Carrier', isSortable: true, parameter: 'carrier' },
  { id: 11, label: 'Vendor', isSortable: true, parameter: 'vendor' },
  { id: 12, label: 'File', isSortable: false },
  { id: 13, label: 'Created by', isSortable: true, parameter: 'createdByUser.lastName' },
  { id: 14, label: 'Created date', isSortable: true, parameter: 'createdAtUtc' },
  { id: 15, label: '', isSortable: false }
];

export const generateChips = (options, filters = {}) => {
  const { users, types } = options;

  const { typeIds, createdUserIds, createdFrom, createdTo } = filters;

  let newChips = [];

  const filterConfigs = [
    { ids: typeIds, options: types, getLabel: (el) => el.displayName, key: 'typeIds' },
    {
      ids: createdUserIds,
      options: users,
      getLabel: (el) => getUserFullName(el),
      key: 'createdUserIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  newChips = newChips.concat(handleDateFilter(createdFrom, createdTo, 'Created'));

  return newChips.filter(Boolean);
};

export const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;

  switch (key) {
    case 'duration_Created':
      newFilters.createdTo = null;
      newFilters.createdFrom = null;
      break;
    case 'dateFrom_Created':
      newFilters.createdFrom = null;
      break;
    case 'dateTo_Created':
      newFilters.createdTo = null;
      break;
    case 'createdUserIds':
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      newFilters.createdUserList = newFilters.createdUserList.filter(({ id }) => id !== itemId);
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

export const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  if (query.filters?.searchQuery) {
    payload.filters.searchQuery = query.filters.searchQuery;
  }

  if (query.filters?.createdFrom) {
    payload.filters.createdFrom = query.filters.createdFrom;
  }

  if (query.filters?.createdTo) {
    payload.filters.createdTo = query.filters.createdTo;
  }

  if (query.filters?.createdUserIds?.length) {
    payload.filters.createdUserIds = query.filters.createdUserIds;
  }

  if (query.filters?.typeIds?.length) {
    payload.filters.typeIds = query.filters.typeIds;
  }

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};
