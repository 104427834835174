import React from 'react';
import styles from './TooltipWrapper.module.scss';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function TooltipWrapper({ trigger, tooltip, options, open, setOpen }) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    visible: open,
    onVisibleChange: setOpen,
    placement: 'left-start',
    closeOnOutsideClick: true,
    closeOnTriggerHidden: true,
    ...options
  });

  return (
    <>
      <div ref={setTriggerRef}>{trigger}</div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps()} className={styles.tooltip}>
          {tooltip}
        </div>
      )}
    </>
  );
}
