import { graphConfig } from '../../msConfig';
import { removeCharacterZ, wait } from 'helpers/AppHelpers';
import { useEventActions } from './useEventActions';
import moment from 'moment';

const MIN_REQUEST_TIME = 400;
export const MAX_TEAM_MEMBERS_ALLOWED = 25;
const DEFAULT_CALENDAR_NAME = 'Team Members';

export const useCalendarActions = (callMsGraph, teamMembers) => {
  const { shareEventsWithNewCalendar, shareEventsWithExistedCalendar } = useEventActions(
    callMsGraph
  );

  const getCalendars = (depth = 0) => {
    callMsGraph()
      .get(graphConfig.calendarsEndpoint)
      .then(handleGetCalendarsResponse)
      .catch((err) => {
        const errors = JSON.parse(err?.request?.response);
        if (errors && depth < 3) {
          wait(depth * 500).then(() => getCalendars(depth + 1));
        }
      });
  };

  const handleGetCalendarsResponse = (res) => res.status === 200 && checkCalendars(res.data.value);

  const checkCalendars = (calendarsData) => {
    const membersList =
      teamMembers.length > MAX_TEAM_MEMBERS_ALLOWED
        ? teamMembers.slice(0, MAX_TEAM_MEMBERS_ALLOWED)
        : teamMembers;

    const foundCalendar = calendarsData.find((item) => item.name === DEFAULT_CALENDAR_NAME);

    if (foundCalendar?.id) {
      updateCalendar(foundCalendar.id, membersList);
    } else {
      createCalendar(membersList);
    }
  };

  const createCalendar = (members, depth = 0) => {
    const handleSuccess = (res) => {
      if (res.status !== 201) return;
      createEvents(res);
    };
    const handleFailure = (err) => {
      const errors = JSON.parse(err?.request?.response);
      if (errors && depth < 3) {
        wait(depth * 500).then(() => createCalendar(members, depth + 1));
      }
    };

    const createEvents = (res) => {
      members.forEach((member) => {
        const teamMemberFullName = member.teamMember.firstName + ' ' + member.teamMember.lastName;

        member.employmentByDays.forEach(
          (item) =>
            item['employment'] && shareEventsWithNewCalendar(res.data.id, teamMemberFullName, item)
        );
      });
    };

    callMsToCreateCalendar().then(handleSuccess).catch(handleFailure);
  };

  const updateCalendar = (calendarId, members, depth = 0) => {
    const handleFailure = (err) => {
      const errors = JSON.parse(err?.request?.response);
      if (errors && depth < 3) {
        wait(depth * 500).then(() => updateCalendar(calendarId, members, depth + 1));
      }
    };
    const handleSuccess = (res) => {
      if (res.status !== 200) return;
      handleEvents(res);
    };

    const handleEvents = (res) => {
      const events = res.data.value.filter(({ start }) => {
        const employmentStartDay = moment(removeCharacterZ(members[0].employmentByDays[0].day));
        return (
          moment(start.dateTime).isSame(employmentStartDay) ||
          moment(start.dateTime).isAfter(employmentStartDay)
        );
      });

      members.forEach((member) => {
        const teamMemberFullName = member.teamMember.firstName + ' ' + member.teamMember.lastName;

        member.employmentByDays.forEach((item) => {
          wait(400).then(() => {
            shareEventsWithExistedCalendar(calendarId, teamMemberFullName, events, item);
          });
        });
      });
    };

    callMsToGetAllEvents(calendarId).then(handleSuccess).catch(handleFailure);
  };

  const callMsToCreateCalendar = () => {
    const query = { name: DEFAULT_CALENDAR_NAME, isDefaultCalendar: true };
    return wait(MIN_REQUEST_TIME).then(() =>
      callMsGraph().post(graphConfig.calendarsEndpoint, query)
    );
  };
  const callMsToGetAllEvents = (calendarId) => {
    const url = graphConfig.calendarsEndpoint + `/${calendarId}/events?$top=10000`;
    return wait(MIN_REQUEST_TIME).then(() => callMsGraph().get(url));
  };

  return { getCalendars };
};
