import React, { useEffect } from 'react';
import styles from './BaseStatistics.module.scss';

import Separator from 'components/Separator/Separator';
import { TransparentButton } from 'components/Buttons';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';

import BaseStatisticsTable from './Table';
import BaseStatisticsChart from './Chart';
import BaseStatisticsFilter from './Filter';

import { useTicketsDashboardActions, useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { fileDownload } from 'helpers/AppHelpers';

import csvIcon from 'assets/images/csvIcon.svg';

export default function BaseStatistics() {
  const { baseStatistics, tabConfig } = useTicketsDashboardSelector();
  const { getBasicStatisticCsvResourceAction, clearTabStateAction } = useTicketsDashboardActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'baseStatistics' });
    };
  }, []);

  const getFilterData = (sectionId) => tabConfig?.items?.find(({ id }) => id === sectionId) || {};

  const downloadCsv = (dashboardId) => {
    getBasicStatisticCsvResourceAction({ dashboardId }).then((res) => fileDownload(res));
  };

  return (
    <DashboardWrapper>
      {!!baseStatistics?.length && (
        <div className={styles.statistics}>
          {baseStatistics.map((sectionData, index) => (
            <div key={sectionData.id} className={styles.statistics__dashboard}>
              <SectionHeader title={sectionData.name || ''} />
              <BaseStatisticsFilter filter={getFilterData(sectionData.id)} />
              <TransparentButton
                label="Download CSV File"
                className={styles.csvButton}
                onClick={() => downloadCsv(sectionData.id)}>
                <img src={csvIcon} alt="" />
              </TransparentButton>
              <div className={styles.statistics__dashboard_info}>
                <BaseStatisticsTable data={sectionData} />
                <BaseStatisticsChart data={sectionData} />
              </div>
              {baseStatistics.length > 1 && index !== baseStatistics.length - 1 && <Separator />}
            </div>
          ))}
        </div>
      )}
    </DashboardWrapper>
  );
}
