import React from 'react';
import styles from './SearchTextField.module.scss';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid #999',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)'
      }
    }
  }
}));

export default function SearchTextField({
  value,
  onFieldInput,
  onClearField,
  shouldShowHint,
  disableRightMargin
}) {
  const classes = useStyles();

  const onInput = (event) => onFieldInput(event);

  return (
    <div className={`${styles.searchPanel} ${disableRightMargin ? '' : styles.rightMargin}`}>
      <TextField
        className={`${styles.searchInput} ${classes.searchBarStyle}`}
        type="text"
        placeholder="Search"
        variant="outlined"
        onChange={onInput}
        onKeyDown={onInput}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value && (
            <IconButton aria-label="toggle password visibility" onClick={onClearField}>
              <CancelRoundedIcon fontSize="small" className={styles.clearIcon} />
            </IconButton>
          )
        }}
      />
      {shouldShowHint && (
        <span className={styles.searchMessage}>{shouldShowHint() ? 'No matches' : '\u2000'}</span>
      )}
    </div>
  );
}
