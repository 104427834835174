import React from 'react';
import styles from './PaginationButtons.module.scss';
import { IconButton } from '@material-ui/core';

export default function Ellipsis() {
  return (
    <>
      <IconButton className={styles.ellipsis_button}>...</IconButton>
      <div className={styles.page_divider}></div>
    </>
  );
}
