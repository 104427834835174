import React, { useState, useEffect } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styles from './SearchInputWithTags.module.scss';
import { ClickAwayListener } from '@material-ui/core';

const Tag = ({ label, onDelete, ...props }) => (
  <div className={styles.tag} {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
);

export default function SearchInputWithTags({
  displayName,
  getLabel,
  defaultInputValue,
  options,
  multiple,
  onInputChange,
  onSelect,
  isDisabled,
  isInvalid,
  fullWidth,
  shrinkedSize,
  key,
  index,
  specialView,
  disablePaste
}) {
  const [open, setOpen] = useState(false);
  const [inputValueState, setInputValueState] = useState(
    multiple ? defaultInputValue || '' : defaultInputValue.drCode || ''
  );

  useEffect(() => {
    if (defaultInputValue?.id) {
      setInputValueState(multiple ? defaultInputValue || '' : defaultInputValue.drCode || '');
    }
  }, [defaultInputValue]);

  const onChange = (e, value) => {
    onSelect(value, index);
    !multiple && setOpen(false);
    setInputValueState(multiple ? '' : value?.drCode || '');
  };

  const inputChangeHandle = (string) => {
    const arrayValue = string
      ?.toString()
      ?.toLowerCase()
      .replace(/[;,.]/g, ',')
      .split(',')
      .filter(Boolean)
      .map((el) => el.trimStart());
    if (arrayValue && arrayValue.length === 1) {
      const stringValue = string ? string?.replace(/[^0-9A-Za-z,. \\-]/g, '').substr(0, 30) : '';
      onInputChange(string, index);
      setInputValueState(stringValue);
      setOpen(true);
    } else {
      setInputValueState('');
    }
  };

  const onPaste = (e) => {
    if (disablePaste) {
      e.preventDefault();
    }
  };

  const onFilterOptions = (options) => options;

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl
  } = useAutocomplete({
    onChange: onChange,
    inputValue: inputValueState,
    onInputChange: (e) => inputChangeHandle(e?.target?.value),
    defaultValue: defaultInputValue,
    multiple: multiple,
    options: options,
    clearOnEscape: true,
    getOptionLabel: (option) => (getLabel ? getLabel(option) : option[displayName]),
    getOptionSelected: (option, value) => {
      return option.id === value.id;
    },
    filterOptions: onFilterOptions,
    disableCloseOnSelect: multiple,
    open: open
  });

  const handleClickAway = () => setOpen(false);

  return (
    <NoSsr>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div {...getRootProps()}>
            <div
              key={key}
              ref={setAnchorEl}
              onClick={() => setOpen(true)}
              className={`${styles.inputWrapper} ${focused ? 'focused' : ''} ${
                fullWidth ? styles.wFull : styles.w250
              } ${isInvalid ? styles.error : ''} ${specialView ? styles.specialView : ''}`}>
              {value &&
                multiple &&
                value?.map((option, index) => (
                  <Tag
                    key={index}
                    label={getLabel ? getLabel(option) : option[displayName]}
                    {...getTagProps({ index })}
                  />
                ))}
              <input {...getInputProps()} disabled={isDisabled} onPaste={onPaste} />
            </div>
          </div>
          {groupedOptions.length > 0 && open ? (
            <ul
              className={`${styles.listbox} ${shrinkedSize ? styles.shrinkedSize : ''}`}
              {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li key={index} {...getOptionProps({ option, index })}>
                  <span>{getLabel ? getLabel(option) : option[displayName]}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </ClickAwayListener>
    </NoSsr>
  );
}
