import {
  SET_TEAM_MEMBER_HISTORY_LIST,
  CLEAR_TEAM_MEMBER_HISTORY_STATE,
  SET_TEAM_MEMBER_HISTORY_FILTER,
  SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA,
  SET_TEAM_MEMBER_HISTORY_SECONDARY_FILTER_CRITERIA
} from 'constants/reduceConstants';

const defaultFilter = {
  filters: {
    searchQuery: '',
    teamMemberCapacityIds: [],
    timeOnProjectNames: [],
    teamMemberDepartmentIds: [],
    teamMemberIds: [],
    teamMemberStatusIds: [],
    projectIds: [],
    fromDayIn: null,
    toDayIn: null,
    fromDayOut: null,
    toDayOut: null,
    showAllHistory: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  tableList: [],
  filter: defaultFilter,
  filterCriteria: {},
  secondaryFilterCriteria: {}
};

const teamMemberHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAM_MEMBER_HISTORY_LIST:
      return { ...state, tableList: payload };
    case SET_TEAM_MEMBER_HISTORY_FILTER:
      return { ...state, filter: payload };
    case SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_TEAM_MEMBER_HISTORY_SECONDARY_FILTER_CRITERIA:
      return { ...state, secondaryFilterCriteria: payload };
    case CLEAR_TEAM_MEMBER_HISTORY_STATE:
      return {
        ...initialState,
        filter: {
          ...state.filter,
          pagination: defaultFilter.pagination,
          responseReceived: false
        }
      };
    default:
      return state;
  }
};

export default teamMemberHistoryReducer;
