import React from 'react';
import styles from './components.module.scss';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

export default function AddRowButton({ onClick, disabled }) {
  const handleClick = () => onClick({ name: 'add' });

  return (
    <Button className={clsx(styles.add, disabled && styles.add_disabled)} onClick={handleClick}>
      <AddIcon />
      <label>Add</label>
    </Button>
  );
}
