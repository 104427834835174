import React from 'react';
import styles from './TurnoverStatisticsTable.module.scss';

import { TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { Table, TableHead, TableBody } from '@material-ui/core';

import { useTicketsDashboardSelector } from 'hooks/TicketsDashboard';

import { pluck } from 'helpers/AppHelpers';

import { generateCellUrl, getColumnName } from '../../../helpers';
import clsx from 'clsx';

export default function TurnoverStatisticsTable({ data }) {
  const { tab } = useTicketsDashboardSelector();

  const { durations, severities, id } = data;

  const columns = durations.map(({ dateFrom, dateTo, total }) => ({
    name: getColumnName(dateFrom, dateTo),
    total
  }));

  const allSeverityCounts = durations.flatMap(({ severityWithCount }) => severityWithCount);

  const getRowCounts = (severityName) => {
    const isNameEqual = ({ ticketSeverity }) => ticketSeverity.name === severityName;
    const filteredSeverities = allSeverityCounts.filter(isNameEqual);

    return pluck(filteredSeverities, 'count');
  };

  const transformedData = severities.map((severity) => ({
    severity,
    counts: getRowCounts(severity.name)
  }));

  const generateUrlParams = (isTotal, index, severityName) => {
    const params = {
      dashboardId: id,
      dashboardType: tab,
      dashboardDurationId: durations[index].id
    };

    if (!isTotal) {
      params.severityName = severityName;
    }

    return params;
  };

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Severity</StyledTableCell>
              {columns.map(({ name }, index) => (
                <StyledTableCell key={index}>{name}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {transformedData.map(({ severity, counts }, index) => (
              <StyledTableRow key={index} className={styles[severity.name]}>
                <StyledTableCell>
                  <div className={styles.severityCell}>
                    {severity?.displayName || ''}
                    <div className={clsx(styles.indicator, styles[severity.name])} />
                  </div>
                </StyledTableCell>
                {counts.map((count, index) => (
                  <StyledTableCell key={index}>
                    <div className={clsx(styles.linkWrapper, styles[severity.name])}>
                      <LinkComponent
                        name={`${count || 0}${count > 0 ? ' days' : ''}`}
                        isRedirectAvailable={count > 0}
                        path={generateCellUrl(generateUrlParams(false, index, severity.name))}
                      />
                    </div>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
            <StyledTableRow className={styles.Total}>
              <StyledTableCell>TOTAL</StyledTableCell>
              {columns.map(({ total }, index) => (
                <StyledTableCell key={index}>
                  <LinkComponent
                    name={`${total || 0}${total > 0 ? ' days' : ''}`}
                    isRedirectAvailable={total > 0}
                    path={generateCellUrl(generateUrlParams(true, index))}
                  />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
