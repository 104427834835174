import React, { useEffect, useState, useRef } from 'react';
import styles from './AssetReportList.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import EditabilityToggle from 'components/EditabilityToggle/EditabilityToggle';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import { StyledButton, TransparentButton } from 'components/Buttons';
import { AddGeneralReportPopup } from 'components/Popups';
import GenerateReportQRCodesPopup from 'components/QR/GenerateReportQRCodesPopup';

import { IconButton, Table, TableBody } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useReportActions, useReportSelector } from 'hooks/ReportList';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { formatDate, fileDownload, cloneObj, getUserFullName } from 'helpers/AppHelpers';

import {
  ASSET_REPORT_EDIT_PATH,
  ASSET_DETAILED_PATH,
  LOCATION_DETAILED_PATH
} from 'constants/routeConstants';
import { ASSET_REPORTS_TABLE_PARAMETER } from 'constants/configTableConstants';

import fileIcon from 'assets/images/fileIcon.svg';
import { ReactComponent as QRReadIcon } from 'assets/images/qrRead.svg';

import { tableHeadConfig } from './tableHeadConfig';

import DownloadArchiveButton from '../commonComponents/DownloadArchiveButton';
import { generateChips, removeChip } from '../helpers';
import FilterModal from '../Filter';

const defaultFilter = {
  filters: {
    generalReportTypes: ['Asset'],
    locationJobNumberIds: [],
    locationIds: [],
    assignedUserIds: [],
    generalReportTemplateIds: [],
    dateFrom: '',
    dateTo: '',
    searchQuery: '',
    showAssigned: false,
    showNotSubmitted: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

export default function AssetReportList() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);
  const location = useLocation();

  const [openFilter, setOpenFilter] = useState(false);
  const [tableState, setTableState] = useState([]);
  const [openAddReportPopup, setAddReportPopup] = useState(false);
  const [chips, setChips] = useState([]);
  const [openQRPopup, setOpenQRPopup] = useState(false);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled, isBasicRoleUser } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const {
    setAssetReportsFilterAction,
    getReportsAction,
    updateReportAction,
    getPdfResourceAction,
    getFilterCriteriaAction,
    clearStateAction
  } = useReportActions();
  const { assetReportsFilter, filterCriteria } = useReportSelector();

  const query = new URLSearchParams(location.search);
  const searchValue = query.get('searchQuery');

  const { filters, pagination, sortRules, responseReceived } = assetReportsFilter;

  const generateInitialQuery = () => {
    return {
      filters: { ...defaultFilter.filters, ...filters, searchQuery: searchValue || '' },
      pagination: {
        page:
          getTableLimit(ASSET_REPORTS_TABLE_PARAMETER) === 100 &&
          isAllLimitEnabled(ASSET_REPORTS_TABLE_PARAMETER)
            ? defaultFilter.pagination.page
            : pagination?.page || defaultFilter.pagination.page,
        limit: getTableLimit(ASSET_REPORTS_TABLE_PARAMETER)
      },
      sortRules: sortRules || defaultFilter.sortRules,
      responseReceived: false
    };
  };

  const getReports = (query, isAutoload) => {
    setAssetReportsFilterAction(query);

    getReportsAction(query).then((tableData) => {
      setReports(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setReports = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setAssetReportsFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction({ generalReportType: 'Asset' }).then((criteria) => {
      getReports(generateInitialQuery());
      setChips(generateChips(criteria, filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const onFileDownload = (id) => {
    getPdfResourceAction(id).then((res) => {
      if (isMobile) {
        fileDownload(res).then(() => console.log());
      } else {
        window.open(res.link, '_blank');
      }
    });
  };

  const findMatches = (value) => getReports(getChangedBySearchFilter(assetReportsFilter, value));
  const sortTable = (rowName) => getReports(getChangedBySortFilter(assetReportsFilter, rowName));
  const changePage = (page, param) =>
    getReports(getChangedByPageFilter(assetReportsFilter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => {
    getReports(getChangedByApplyFilter(assetReportsFilter, values));
    setChips(generateChips(filterCriteria, values));
  };
  const changeLimit = () =>
    getReports(
      getChangedByLimitFilter(assetReportsFilter, getTableLimit(ASSET_REPORTS_TABLE_PARAMETER))
    );

  const handleCellUpdate = (name, value, index) => {
    const report = tableState[index];
    report[name] = value;
    updateReportAction(report).then((res) => {
      if (res.status !== 200) return;
      const tableData = cloneObj(tableState);
      tableData[index][name] = value;
      setTableState(tableData);
    });
  };

  const handleIdClick = ({ id, submitted }) => {
    if (submitted) {
      onFileDownload(id);
    } else {
      history.push(`${ASSET_REPORT_EDIT_PATH}/${id}`);
    }
  };

  const openFilterModal = () => setOpenFilter(true);

  const deleteChip = (chip) => applyFilter(removeChip(filters, chip));

  const openQrGenerator = () => setOpenQRPopup(true);

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Asset report">
          <div className={styles.header_controls}>
            {!isMobile && (
              <TransparentButton label="QR FOR REPORT" onClick={openQrGenerator}>
                <QRReadIcon />
              </TransparentButton>
            )}
            {!isMobile && (
              <StyledButton
                label="Add new report"
                onClick={() => setAddReportPopup(true)}
                classes={styles.main_button}
              />
            )}
          </div>
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <div className={styles.actions}>
            <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
            {!isMobile && <DownloadArchiveButton data={tableState.slice(-100) || []} />}
          </div>
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && (
        <StyledButton
          label="Add new report"
          onClick={() => setAddReportPopup(true)}
          classes={styles.main_button}
        />
      )}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={row.id} className={styles.asset_report_row}>
                  <StyledTableCell className={styles.idCell}>
                    <span onClick={() => handleIdClick(row)} className={styles.clickable_id}>
                      {row.id || ''}
                    </span>
                  </StyledTableCell>

                  <StyledTableCell className={styles.drCodeCell}>
                    <LinkComponent
                      name={row.asset?.drCode || ''}
                      path={`${ASSET_DETAILED_PATH}/${row.asset?.id}`}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.locationCell}>
                    <LinkComponent
                      name={row.location?.siteCode || ''}
                      path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                      isRedirectAvailable={!row.location?.deleted}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.createdAtCell}>
                    {row.createdAtUtc ? formatDate(row.createdAtUtc) : ''}
                  </StyledTableCell>

                  <StyledTableCell className={styles.submittedByCell}>
                    {getUserFullName(row?.submittedByUser)}
                  </StyledTableCell>

                  <StyledTableCell className={styles.templateCell}>
                    {row.generalReportTemplate?.name || ''}
                  </StyledTableCell>

                  <StyledTableCell className={styles.noteCell}>
                    <EditabilityToggle
                      name="note"
                      inputValue={row?.note || ''}
                      options={{
                        max: 250,
                        type: 'text',
                        index,
                        keyToDown: 'Enter',
                        multiline: true
                      }}
                      onAccept={handleCellUpdate}
                      isDisabled={!row.submitted}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.controls}>
                      {row.submitted ? (
                        <>
                          <IconButton
                            onClick={() => onFileDownload(row.id)}
                            className={styles.icon_button}>
                            <img src={fileIcon} alt="file" />
                          </IconButton>
                          {!isBasicRoleUser && (
                            <IconButton
                              onClick={() => history.push(`${ASSET_REPORT_EDIT_PATH}/${row.id}`)}
                              className={styles.icon_button}>
                              <CreateIcon />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <span
                          className={styles.editingSpan}
                          onClick={() => history.push(`${ASSET_REPORT_EDIT_PATH}/${row.id}`)}>
                          Editing
                        </span>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={ASSET_REPORTS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openAddReportPopup && (
        <AddGeneralReportPopup
          open={openAddReportPopup}
          setOpen={setAddReportPopup}
          reportType="Asset"
        />
      )}

      {openFilter && (
        <FilterModal
          open={openFilter}
          setOpen={setOpenFilter}
          onApply={applyFilter}
          filter={assetReportsFilter}
          reportType="Asset"
        />
      )}

      {openQRPopup && <GenerateReportQRCodesPopup open={openQRPopup} setOpen={setOpenQRPopup} />}
    </SummaryWrapper>
  );
}
