import React from 'react';
import styles from './RequestEntities.module.scss';

import clsx from 'clsx';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import { AddNewButton } from 'components/Buttons';

import { Controller, useFormContext } from 'react-hook-form';

import EntityChip from './EntityChip';

export default function RequestCustomEntities({ onAdd, index }) {
  const { control } = useFormContext();

  const addCustomField = (value) => onAdd({ entityName: value }, index);

  return (
    <>
      <div className={clsx(styles.entities__block_row, styles.larger)}>
        <label>Name*</label>
        <div className={styles.inputs}>
          <FormInputText
            name={`requestEntityData[${index}].customName`}
            options={{ max: 100 }}
            classes={styles.inputs__text}
          />
          <Controller
            control={control}
            name={`requestEntityData[${index}].customName`}
            render={({ field: { value }, fieldState: { error } }) => (
              <>
                <AddNewButton onClick={() => addCustomField(value)} isDisabled={!value?.length} />
                {!error && <span className={styles.tip}>Write to add a new field</span>}
              </>
            )}
          />
        </div>
      </div>
      <FormChipsContainer
        fieldName={`requestEntityData[${index}].requestOrderCustomEntities`}
        label="entityName"
        deleteParameter="entityName"
        ChipComponent={(chipId, chip, chipIndex) => (
          <EntityChip
            key={chipId}
            Chip={chip}
            quantityName={`requestEntityData[${index}].requestOrderCustomEntities[${chipIndex}.quantity]`}
            resourcesName={`requestEntityData[${index}].requestOrderCustomEntities[${chipIndex}.resources]`}
          />
        )}
        direction="column"
      />
    </>
  );
}
