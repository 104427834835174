import { REPORT_TEMPLATE_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj } from 'helpers/AppHelpers';
import { handleBooleanFilter, handleNamesFilter } from 'helpers/ChipGenerator';

const statuses = [
  { id: 1, name: 'Published', displayName: 'Published' },
  { id: 2, name: 'Unpublished', displayName: 'Unpublished' }
];

const defaultFilter = {
  filters: { searchQuery: '', generalReportTypeName: [], showPublished: null, statusIds: [] },
  pagination: { page: 1, totalPages: 1, limit: 10 },
  sortRules: [],
  responseReceived: false
};

const tableHeadConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Report Type', isSortable: true, parameter: 'reportType' },
  { id: 3, label: 'Last update', isSortable: true, parameter: 'updatedAtUtc' },
  { id: 4, label: 'Status', isSortable: true, parameter: 'published' },
  { id: 5, label: 'Actions', isSortable: false }
];

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter }) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(REPORT_TEMPLATE_TABLE_PARAMETER);

  const isAll = isAllLimitEnabled(REPORT_TEMPLATE_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    filters: { ...defaultFilter.filters, ...filter?.filters },
    pagination: { limit, page },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };
};

const generateChips = (reportTypes, filters = {}) => {
  const { generalReportTypeName, showPublished } = filters;

  let newChips = [];

  if (generalReportTypeName?.length) {
    newChips = newChips.concat(
      handleNamesFilter(generalReportTypeName, reportTypes, 'generalReportTypeName')
    );
  }

  if ([true, false].includes(showPublished)) {
    newChips = newChips.concat(
      handleBooleanFilter('showPublished', showPublished ? 'Published' : 'Unpublished')
    );
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemName } = deletedChip;

  switch (key) {
    case 'generalReportTypeName':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case 'showPublished':
      newFilters.showPublished = null;
      newFilters.statusIds = [];
      break;
    default:
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = ['searchQuery', 'generalReportTypeName'];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if ([true, false].includes(query.filters.showPublished)) {
    payload.filters.showPublished = query.filters.showPublished;
  }

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

export {
  defaultFilter,
  tableHeadConfig,
  statuses,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery
};
