import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useState } from 'react';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import DetailsHeader from 'components/DetailsComponents/Header';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { LOOSE_ITEM_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { useLooseItemSelector, useLooseItemActions } from 'hooks/LooseItems';
import { DELETE_LOOSE_ITEM } from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';

export default function LooseItemDetailsHeader() {
  const isMobile = useMobileViewport();
  const { isAdminUser } = useUserConfig();
  const history = useHistory();

  const [modalData, setModalData] = useState({});

  const { currentLooseItem } = useLooseItemSelector();
  const { deleteItemAction } = useLooseItemActions();

  const moveBack = () => history.goBack();
  const editPart = () => history.push(`${LOOSE_ITEM_EDIT_PATH}/${currentLooseItem.id}`);
  const removePart = () => setModalData({ ...DELETE_LOOSE_ITEM, isOpened: true });

  const closeModal = () => setModalData({ isOpened: false });

  const agreeModal = () => deleteItemAction(currentLooseItem.id);

  return (
    <DetailsHeader
      breadcrumbs={[{ name: currentLooseItem.name }]}
      title={currentLooseItem?.name || ''}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <HeaderButton onClick={editPart}>
        <CreateIcon />
        {!isMobile && <span>Edit item</span>}
      </HeaderButton>
      {isAdminUser && (
        <HeaderButton onClick={removePart}>
          <DeleteIcon />
          {!isMobile && <span>Remove item</span>}
        </HeaderButton>
      )}
      {isMobile && (
        <HeaderButton onClick={moveBack}>
          <ChevronLeftIcon />
        </HeaderButton>
      )}
    </DetailsHeader>
  );
}
