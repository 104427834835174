import React from 'react';
import styles from './ProjectScheduleFilter.module.scss';

import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import TooltipSelectingDepartment from 'components/InfoTooltip/InfoTooltip';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useFormContext } from 'react-hook-form';

import moment from 'moment/moment';
import { doDriveId, doNotDriveId, driveStatuses } from 'helpers/AppHelpers';

export default function ScheduleFilterForm() {
  const { watch, setValue, getValues } = useFormContext();
  const { getFilterCriteriaAction } = useTrackerActions();
  const { filterCriteria, isMasterSelected } = useTrackerSelector();
  const {
    projects,
    teamMemberStatuses,
    teamMemberCapacities,
    teamMembers,
    teamMemberDepartments,
    teamMemberLicenses,
    teamMemberSkillsets,
    employmentTypes,
    clearanceTypes
  } = filterCriteria;

  const startDateWatcher = watch('startDate');
  const endDateWatcher = watch('endDate');

  const handleDepartmentSelect = () => {
    getFilterCriteriaAction({
      isMaster: isMasterSelected,
      departmentIds: getValues('teamMemberDepartmentIds')
    });
    setValue('teamMemberIds', []);
    setValue('clearanceTypeIds', []);
    setValue('teamMemberLicenseIds', []);
    setValue('teamMemberCapacityIds', []);
    setValue('teamMemberSkillsetIds', []);
  };

  const handleDriveStatusSelect = (name, value) => {
    setValue('doDrive', value.includes(doDriveId));
    setValue('doNotDrive', value.includes(doNotDriveId));
  };

  return (
    <div className={styles.form}>
      <div className={styles.form__block}>
        <div className={styles.form__block_input}>
          <div className={styles.form__block_label}>
            Department
            <TooltipSelectingDepartment
              text="By selecting Department, lists of Capacity, Team Member, Clearance, Trainings &
        Certifications, Skillset will be narrowed."
            />
          </div>
          <FormAutoMultiSelect
            name="teamMemberDepartmentIds"
            menuItems={teamMemberDepartments || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true,
              extraAction: handleDepartmentSelect
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Employment</label>
          <FormAutoMultiSelect
            name="teamMemberStatusIds"
            menuItems={teamMemberStatuses || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Capacity</label>
          <FormAutoMultiSelect
            name="teamMemberCapacityIds"
            menuItems={teamMemberCapacities || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Team member</label>
          <FormAutoMultiSelect
            name="teamMemberIds"
            menuItems={teamMembers || []}
            options={{
              labelType: 'teamMember',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Project</label>
          <FormAutoMultiSelect
            name="projectIds"
            menuItems={projects || []}
            options={{
              labelType: 'project',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Drive Status</label>
          <FormAutoMultiSelect
            name="driveStatusIds"
            menuItems={driveStatuses || []}
            options={{
              label: 'displayName',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true,
              extraAction: handleDriveStatusSelect
            }}
          />
        </div>
      </div>
      <div className={styles.form__block}>
        <div className={styles.form__block_input}>
          <label>Start Date</label>
          <FormDatePicker
            name="startDate"
            min={moment().add(-100, 'year')}
            max={endDateWatcher || moment().add(100, 'year')}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>End Date</label>
          <FormDatePicker
            name="endDate"
            min={startDateWatcher || moment().add(-100, 'year')}
            max={moment().add(100, 'year')}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Trainings & Certifications</label>
          <FormAutoMultiSelect
            name="teamMemberLicenseIds"
            menuItems={teamMemberLicenses || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Skillset</label>
          <FormAutoMultiSelect
            name="teamMemberSkillsetIds"
            menuItems={teamMemberSkillsets || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Status</label>
          <FormAutoMultiSelect
            name="employmentTypeIds"
            menuItems={employmentTypes || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__block_input}>
          <label>Clearance</label>
          <FormAutoMultiSelect
            name="clearanceTypeIds"
            menuItems={clearanceTypes || []}
            options={{
              label: 'name',
              selectAll: false,
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
      </div>
    </div>
  );
}
