import React, { useEffect, useState } from 'react';
import styles from './AddAssetPopup.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import { FormLabel, FormMultipleAssetSelect, FormSearchInput } from 'components/FormComponents';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormInputWithCreation from 'components/FormComponents/FormInputWithCreation/FormInputWithCreation';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getLocations, addAssetsToLeavelist } from 'actions/addAssetPopupsActions';

import { ADD_ASSETS_LEAVELIST } from 'constants/dialogPopupsData';

import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

/**
 * @typedef {Object} AddLeavelistAssetPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {{id: number, name: string}} leavelist
 * @property {() => void} onSuccess
 */

/**
 * @param {AddLeavelistAssetPopupProps} props
 */

function AddLeavelistAssetPopup({ open, setOpen, leavelist, onSuccess }) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState({});
  const [options, setOptions] = useState({});

  const methods = useForm({
    defaultValues: {
      assets: [],
      customs: [],
      leavelist: {},
      location: {},
      sublocation: {},
      truck: {},
      isThirdPartyTruck: false,
      notes: ''
    },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, setError, clearErrors, watch, reset } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { locations } = options;

  useEffect(() => {
    dispatch(getLocations()).then((res) => setOptions((prev) => ({ ...prev, locations: res })));
  }, []);

  useEffect(() => {
    if (leavelist?.id) {
      reset({ ...getValues(), leavelist });
    }
  }, [leavelist]);

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('assets')?.length && !getValues('customs')?.length) {
      setError('assets', getErrorsProperties('At least one field is required'));
      setError('customs', getErrorsProperties('At least one field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const parsePayload = (data) => {
    const payload = {};

    payload.assets = [...data.assets, ...data.customs];
    payload.leavelist = data.leavelist;

    if (data.truck?.id) {
      payload.truck = data.truck;
    }
    if (data.isThirdPartyTruck) {
      payload.isThirdPartyTruck = data.isThirdPartyTruck;
    }
    if (data.notes) {
      payload.notes = data.notes;
    }

    return payload;
  };

  const closePopup = () => setOpen(false);
  const handleAddClick = () => {
    if (!validateForm()) return;

    setModalData({
      ...ADD_ASSETS_LEAVELIST,
      isOpened: true,
      title: `Do you want to add assets to the leavelist ${getValues('leavelist.name')}?`
    });
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    setModalData({});
    const parsedValues = parsePayload(getValues());
    dispatch(addAssetsToLeavelist(parsedValues)).then((res) => {
      if (res.status === 200) {
        onSuccess && onSuccess();
        closePopup();
      }
    });
  };

  const selectOption = (name, value) => setValue(name, value);
  const selectCheckbox = () => setValue('truck', {});
  const selectLocation = (name, value) => {
    setValue(name, value);
    setValue('sublocation', {});
  };

  const validateScannedAsset = ({ rental }) => rental?.rentalStatus?.name === 'Available';
  const getSnackbarScanErrorMessage = ({ drCode }) =>
    `Asset ${drCode} is not available for adding to leavelist`;

  const checkError = () =>
    (errors?.assets || errors?.customs) && clearErrors(['assets', 'customs']);

  const thirdPartyTruckWatcher = watch('isThirdPartyTruck');
  const locationWatcher = watch('location');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader
          title={isMobile ? 'Add assets' : 'Add assets to leavelist'}
          onClose={closePopup}
        />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.form}>
              <div className={styles.form__assets}>
                <FormLabel required>Enter Asset DR Codes; codes must be valid DR Codes</FormLabel>
                <FormMultipleAssetSelect
                  withQr
                  disableErrorMessage
                  name="assets"
                  queryOptions={{ leavelistId: leavelist?.id || null }}
                  validateScannedAsset={validateScannedAsset}
                  getSnackbarScanErrorMessage={getSnackbarScanErrorMessage}
                  onSelectTriggered={checkError}
                />
              </div>

              <div className={styles.form__customs}>
                <FormLabel required>Enter Non deployed resources owned Codes</FormLabel>
                <FormInputWithCreation
                  name="customs"
                  itemLabel="drCode"
                  disableErrorMessage
                  hintText="After entering code press “Enter” button"
                  modifyText={(str) => str.replace(allKeyboardLatSymbols, '').substring(0, 200)}
                  transformItem={(str) => ({ drCode: str.trim() })}
                  onKeyDownTriggered={checkError}
                />
              </div>

              <FormChipsContainer
                fieldName="customs"
                keyParam="drCode"
                label="drCode"
                deleteParameter="drCode"
              />

              <div className={styles.form__row}>
                <FormLabel>Destination</FormLabel>
                <FormSearchInput
                  clearable
                  name="location"
                  options={locations || []}
                  onSelect={selectLocation}
                />
              </div>

              <div className={styles.form__row}>
                <FormLabel>Sublocation</FormLabel>
                <FormSearchInput
                  clearable
                  name="sublocation"
                  options={locationWatcher?.sublocations || []}
                  onSelect={selectOption}
                  isDisabled={!locationWatcher?.id}
                />
              </div>

              <div className={styles.form__row}>
                <FormLabel>Truck or trailer</FormLabel>
                <FormServerAssetSearch
                  name="truck"
                  max={100}
                  onSelect={selectOption}
                  getLabel={(option) => option?.drCode || ''}
                  getQuery={(searchQuery) => ({
                    pagination: { limit: 39, page: 1 },
                    filters: { isDeleted: false, equipmentIds: [9, 10], searchQuery }
                  })}
                  isDisabled={!!thirdPartyTruckWatcher}
                />
              </div>
              <div className={styles.form__checkbox}>
                <FormLabel>Third party truck?</FormLabel>
                <FormCheckbox name="isThirdPartyTruck" onSelectTriggered={selectCheckbox} />
              </div>
              <div className={styles.form__notes}>
                <FormLabel>Notes</FormLabel>
                <FormTextArea name="notes" options={{ max: 1000 }} />
              </div>
            </div>
          </FormProvider>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton
            text="Add"
            action={handleAddClick}
            type="primary"
            isDisabled={isFormInvalid}
          />
          {(errors?.assets || errors?.customs) && (
            <span>{errors.assets.message || errors.customs.message}</span>
          )}
        </div>
      </div>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </DialogWrapper>
  );
}

AddLeavelistAssetPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  leavelist: PropTypes.object,
  onSuccess: PropTypes.func
};

export { AddLeavelistAssetPopup };
