import React, { useEffect, useState } from 'react';
import styles from './EditorPopup.module.scss';

import DialogHeader from 'components/DialogComponents/DialogHeader';
import DialogBody from 'components/DialogComponents/DialogBody';
import EditorForm from './EditorForm/EditorForm';
import DialogFooter from 'components/DialogComponents/DialogFooter';
import { MainButton } from 'components/StyledComponents';
import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmploymentStatusesData } from './selectors';
import {
  clearEmploymentStatusesState,
  getEmploymentStatuses
} from 'actions/employmentStatusEditorActions';

export default function EmploymentEditPopup({ open, setOpen }) {
  const dispatch = useDispatch();

  const [responseReceived, setReceived] = useState(false);

  const onClose = () => {
    dispatch(clearEmploymentStatusesState());
    setOpen(false);
    setReceived(false);
  };

  const statuses = useSelector(selectEmploymentStatusesData());

  useEffect(() => {
    if (open) {
      dispatch(getEmploymentStatuses()).then((res) => res && setReceived(true));
    }
  }, [open]);

  return (
    responseReceived && (
      <DialogWrapper open={open} onClose={onClose}>
        <div className={styles.popupWrapper}>
          <DialogHeader onClose={onClose} title="Edit status" />
          <DialogBody>
            <EditorForm statuses={statuses} />
          </DialogBody>
          <DialogFooter>
            <div className={styles.footer}>
              <MainButton text="Close" action={onClose} type="secondary" />
            </div>
          </DialogFooter>
        </div>
      </DialogWrapper>
    )
  );
}
