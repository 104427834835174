import React from 'react';
import styles from './Cells.module.scss';

import StyledLink from 'components/StyledLink';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

export function AssetCell({ canSelect, isSelected, onChange, asset }) {
  const { id, drCode } = asset;

  return (
    <div className={styles.asset}>
      <div className={styles.asset__select}>
        {canSelect && <CustomCheckbox small sendEvent value={isSelected} onChange={onChange} />}
      </div>
      <StyledLink bold to={`${ASSET_DETAILED_PATH}/${id}`}>
        {drCode || ''}
      </StyledLink>
    </div>
  );
}
