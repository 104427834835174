import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even) td': {
      backgroundColor: '#f7f7f7'
    },
    '&:nth-of-type(odd) td': {
      backgroundColor: '#fff'
    }
  }
}))(TableRow);

export default StyledTableRow;
