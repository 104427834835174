import React from 'react';

import DetailsHeader from 'components/DetailsComponents/Header';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

const TITLE = 'Add data to BOL Logs';
const CRUMBS = [{ name: TITLE }];

export function Header({ setModalData, isDirty }) {
  const CrumbsComponent = (
    <BreadcrumbsNav itemsArray={CRUMBS} setDialogModalData={setModalData} formIsChanged={isDirty} />
  );

  return <DetailsHeader BreadCrumbsComponent={CrumbsComponent} title={TITLE} />;
}
