import {
  CLEAR_TEAM_MEMBER_HISTORY_STATE,
  SET_LOAD_MORE_FLAG,
  SET_TEAM_MEMBER_HISTORY_FILTER,
  SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA,
  SET_TEAM_MEMBER_HISTORY_LIST,
  SET_TEAM_MEMBER_HISTORY_SECONDARY_FILTER_CRITERIA
} from 'constants/reduceConstants';
import api from 'api';

export const getTMHistoryData = (query) => (dispatch) => {
  return api.teamMemberHistory
    .getTMHistoryDataApi(query)
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBER_HISTORY_LIST, payload: res });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getTMHistoryUserConfig = () => () => {
  return api.teamMemberHistory
    .getTMHistoryUserConfigApi()
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getTMHistoryFilterCriteria = (q) => (dispatch) =>
  api.teamMemberHistory
    .getTMHistoryFilterCriteriaApi(q)
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
export const getTMHistorySecondaryFilterCriteria = (q) => (dispatch) =>
  api.teamMemberHistory
    .getTMHistoryFilterCriteriaApi(q)
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBER_HISTORY_SECONDARY_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateHistoryConfig = (data) => () => {
  const configModel = {
    departmentIds: data?.teamMemberDepartmentIds ? [...data.teamMemberDepartmentIds] : [],
    projectIds: data?.projectIds ? [...data.projectIds] : []
  };

  return api.teamMemberHistory
    .updateUserConfigApi(configModel)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const setTMHistoryListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER, payload: data });
};
export const setHistoryFilterCriteria = (payload) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA, payload });
};

export const clearTMHistoryState = () => (dispatch) =>
  dispatch({ type: CLEAR_TEAM_MEMBER_HISTORY_STATE });
