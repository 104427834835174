import React from 'react';

import { Wrapper } from './Wrapper';
import { MapLegend } from './Legend';
import { MapSidebar } from './Sidebar';

const Map = {
  Wrapper: function MapWrapper({ markers, popupContent, children }) {
    return (
      <Wrapper markers={markers} popupContent={popupContent}>
        {children}
      </Wrapper>
    );
  },
  Legend: function Legend({ className, markers }) {
    return <MapLegend className={className} markers={markers} />;
  },
  Sidebar: function Sidebar({ className, children }) {
    return <MapSidebar className={className}>{children}</MapSidebar>;
  }
};

export { Map };
