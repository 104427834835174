import React from 'react';
import styles from './form.module.scss';
import GeneralDetails from './GeneralDetails';
import ProfessionalDetails from './ProfessionalDetails';
import Notes from './Notes';

export default function Form() {
  return (
    <section className={styles.form}>
      <GeneralDetails />
      <ProfessionalDetails />
      <Notes />
    </section>
  );
}
