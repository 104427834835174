import {
  SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA,
  SET_LOOSE_ITEM_DASHBOARD_CONFIG,
  CLEAR_LOOSE_ITEM_DASHBOARD_STATE
} from 'constants/reduceConstants';

const initialState = {
  looseItemDashboardTablesData: [],
  looseItemDashboardConfig: {}
};

const looseItemDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA:
      return {
        ...state,
        looseItemDashboardTablesData: payload
      };
    case SET_LOOSE_ITEM_DASHBOARD_CONFIG:
      return {
        ...state,
        looseItemDashboardConfig: payload
      };
    case CLEAR_LOOSE_ITEM_DASHBOARD_STATE:
      return initialState;
    default:
      return state;
  }
};

export default looseItemDashboardReducer;
