import {
  SET_PROJECT_LIST,
  SET_CURRENT_PROJECT,
  CLEAR_PROJECT_LIST_STATE,
  SET_PROJECT_LIST_FILTER,
  SET_PROJECT_STATES_LIST,
  RESET_PROJECT_LIST_FILTER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    active: true,
    locationIds: [],
    locationJobNumberIds: []
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  projectList: [],
  projectFilter: { ...defaultFilter },
  projectsPrefilter: {},
  currentProject: {},
  states: []
};

const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROJECT_LIST:
      return { ...state, projectList: payload };
    case SET_PROJECT_LIST_FILTER:
      return { ...state, projectFilter: payload };
    case RESET_PROJECT_LIST_FILTER:
      return {
        ...state,
        projectFilter: { ...state.projectFilter, filters: { ...defaultFilter.filters, ...payload } }
      };
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: payload };
    case SET_PROJECT_STATES_LIST:
      return { ...state, states: payload };
    case CLEAR_PROJECT_LIST_STATE:
      return {
        ...initialState,
        projectFilter: getClearedFilterByUnmount(state.projectFilter)
      };
    default:
      return state;
  }
};

export default projectReducer;
