import {
  SET_IS_LOGGED_IN,
  SET_IS_SIGN_OUT,
  SET_AUTH_LINK
} from '../constants/reduceConstants';
import api from '../api';
import { TOKEN_KEY } from 'constants/commonConstants';

export const onAuth = () => (dispatch) => {
  const token = localStorage.getItem(TOKEN_KEY);
  dispatch({
    type: SET_IS_LOGGED_IN,
    payload: !!token
  });
};

export const getAuthLink = (query) => (dispatch) => {
  api.auth
    .getAuthLinkApi(query)
    .then((res) => dispatch({ type: SET_AUTH_LINK, payload: res }))
    .catch((err) => console.log(err));
};

export const onLogout = () => (dispatch) => {
  localStorage.removeItem(TOKEN_KEY);
  dispatch({
    type: SET_IS_LOGGED_IN,
    payload: false
  });
  dispatch({
    type: SET_IS_SIGN_OUT,
    payload: true
  });
};
