import {
  SET_REQUEST_ORDER_FILTER_DATA,
  CLEAR_REQUEST_ORDER_STATE,
  SET_REQUEST_ORDER_FILTER_CRITERIA_DATA,
  SET_REQUEST_ORDER_CREATION_FORM_DATA,
  SET_CURRENT_REQUEST_ORDER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from '../helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    endUserIds: [],
    entityNames: [],
    locationIds: [],
    looseItemIds: [],
    prefixIds: [],
    statusNames: [],
    dateFrom: null,
    dateTo: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  listFilter: defaultFilter,
  filterCriteria: {},
  currentRequest: {},
  creationForm: {}
};

const requestOrderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_REQUEST_ORDER:
      return { ...state, currentRequest: payload };
    case SET_REQUEST_ORDER_FILTER_DATA:
      return { ...state, listFilter: payload };
    case SET_REQUEST_ORDER_FILTER_CRITERIA_DATA:
      return { ...state, filterCriteria: payload };
    case SET_REQUEST_ORDER_CREATION_FORM_DATA:
      return { ...state, creationForm: payload };
    case CLEAR_REQUEST_ORDER_STATE:
      return {
        ...initialState,
        listFilter: getClearedFilterByUnmount(state.listFilter)
      };
    default:
      return state;
  }
};

export default requestOrderReducer;
