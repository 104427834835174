import React, { useState } from 'react';
import styles from './TooltipComponent.module.scss';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function TooltipComponent({ isMobile }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClick = () => isMobile && setOpen(!open);
  const handleTooltipBlur = () => isMobile && setOpen(false);
  const handleTooltipMouseEnter = () => !isMobile && setOpen(true);
  const handleTooltipMouseLeave = () => !isMobile && setOpen(false);

  return (
    <div className={styles.tooltipBlock}>
      <Tooltip
        open={open}
        title="Needs to be inspected"
        placement="right-end"
        classes={{ tooltip: styles.tooltipBlock__text }}>
        <InfoOutlinedIcon
          onClick={handleTooltipClick}
          onBlur={handleTooltipBlur}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
          tabIndex="0"
        />
      </Tooltip>
    </div>
  );
}
