import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import StyledLink from 'components/StyledLink';

import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';

import { LocationAssets } from '../Assets';
import { LocationLooseItems } from '../LooseItems';

function LocationItemsPopup({ open, onClose, location, type }) {
  return (
    <DialogWrapper open={open} onClose={onClose}>
      <div className={styles.popup}>
        <DialogHeader title="Location" onClose={onClose} />

        <StyledLink
          bold
          isRedirectAvailable={!location.deleted}
          to={`${LOCATION_DETAILED_PATH}/${location.id}`}>
          <span className={styles.location}>{location?.siteCode || ''}</span>
        </StyledLink>

        {type === 'ASSETS' && <LocationAssets location={location} />}

        {type === 'LOOSE_ITEMS' && <LocationLooseItems location={location} />}
      </div>
    </DialogWrapper>
  );
}

LocationItemsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

LocationItemsPopup.defaultProps = {
  open: false,
  location: {},
  type: ''
};

export { LocationItemsPopup };
