import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import { FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';

import { useAssetSelector } from 'hooks/Asset';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { enrichNameToId } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const [sublocationOptions, setSublocationsOptions] = useState([]);

  const methods = useForm({
    defaultValues: {
      locationIds: [],
      locationJobNumberIds: [],
      sublocationIds: [],
      rentalIds: [],
      prefixIds: [],
      severities: [],
      equipmentIds: [],
      projectIds: [],
      grades: [],
      lastLocationIds: [],
      everBeenOnLocationIds: [],
      siteTypeIds: [],
      isNonOwned: false,
      isDeleted: false,
      isNeedsToBeInspected: false,
      dashboardType: '',
      dashboardId: '',
      dashboardDurationId: '',
      dashboardColumnName: ''
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, filterCriteria } = useAssetSelector();

  const {
    equipments,
    prefixes,
    sublocations,
    severities,
    locations,
    grades,
    rentals,
    projects,
    siteTypes
  } = filterCriteria;

  const refreshSublocationOptions = (locationIds) => {
    if (locationIds?.length) {
      const options = sublocations.filter(({ parentLocationId }) =>
        locationIds.includes(parentLocationId)
      );
      setSublocationsOptions(options);
    } else {
      setSublocationsOptions([]);
    }
  };

  useEffect(() => {
    if (open) {
      reset(filter.filters);
      refreshSublocationOptions(filter.filters?.locationIds || []);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      locationIds: [],
      locationJobNumberIds: [],
      sublocationIds: [],
      rentalIds: [],
      prefixIds: [],
      severities: [],
      equipmentIds: [],
      grades: [],
      lastLocationIds: [],
      everBeenOnLocationIds: [],
      siteTypeIds: [],
      projectIds: [],
      isNonOwned: false,
      isDeleted: false,
      isNeedsToBeInspected: false,
      dashboardType: '',
      dashboardId: '',
      dashboardDurationId: '',
      dashboardColumnName: ''
    };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const refreshSublocationsValue = () => {
    if (!getValues('sublocationIds')?.length) return;

    if (!getValues('locationIds').length) {
      setValue('sublocationIds', []);
      return;
    }

    const locationIds = getValues('locationIds');
    const availableSublocationIds = locations
      .filter(({ id }) => locationIds.includes(id))
      .flatMap(({ sublocations }) => sublocations)
      .map(({ id }) => id);

    const filteredSublocations = getValues('sublocationIds').filter(({ id }) =>
      availableSublocationIds.includes(id)
    );

    setValue('sublocationIds', filteredSublocations);
  };

  const handleLocationSelect = (_, value) => {
    refreshSublocationsValue();

    refreshSublocationOptions(value);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel bold>Current Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations?.filter(({ active }) => active) || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleLocationSelect
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Location Type</FormLabel>
                <FormMultiSelect
                  trackById
                  name="siteTypeIds"
                  options={siteTypes || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Sublocation</FormLabel>
                <FormAutoMultiSelect
                  name="sublocationIds"
                  menuItems={sublocationOptions || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    isDisabled: !getValues('locationIds')?.length
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locations?.filter(({ active }) => active) || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Project</FormLabel>
                <FormAutoMultiSelect
                  name="projectIds"
                  menuItems={projects || []}
                  options={{
                    labelType: 'project',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Last Asset Location</FormLabel>
                <FormAutoMultiSelect
                  name="lastLocationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Previous Location</FormLabel>
                <FormAutoMultiSelect
                  name="everBeenOnLocationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel bold>Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="prefixIds"
                  menuItems={prefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Rental</FormLabel>
                <FormMultiSelect
                  trackById
                  name="rentalIds"
                  options={rentals || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Operational Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="severities"
                  options={enrichNameToId(severities) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Category</FormLabel>
                <FormAutoMultiSelect
                  name="equipmentIds"
                  menuItems={equipments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Grade</FormLabel>
                <FormMultiSelect
                  trackById
                  name="grades"
                  options={enrichNameToId(grades) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Needs to be inspected</FormLabel>
                <FormCheckbox name="isNeedsToBeInspected" />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Non-owned?</FormLabel>
                <FormCheckbox name="isNonOwned" />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show deleted</FormLabel>
                <FormCheckbox name="isDeleted" />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
