import React, { useRef, useEffect, useState } from 'react';
import styles from './ProjectEdit.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectProjectStatesData, selectSingleProjectData } from '../selectors';
import { setTopPosition, setUnsavedFormData } from 'actions/commonActions';
import {
  createProject,
  getProjectStatesList,
  getSingleProject,
  updateProject
} from 'actions/projectActions';
import MainButton from 'components/StyledComponents/MainButton';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import {
  CREATE_NEW_PROJECT,
  EXIT_WITHOUT_SAVING,
  NEW_PROJECT_TYPE,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { PROJECT_DETAILED_PATH, PROJECT_LIST_PATH } from 'constants/routeConstants';
import {
  getBackendErrors,
  allKeyboardLatSymbols,
  allKeyboardLatSymbolsWithoutSpaces
} from 'helpers/AppHelpers';
import {
  checkMandatoryParametersForGettingCoordinates,
  extractCoordinates,
  getAddressByGeoCoding,
  getCoordinatesByApi,
  handleEmptyCoordinates
} from 'helpers/GeoCodingHelpers';
import { FormProvider, useForm } from 'react-hook-form';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import clsx from 'clsx';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import moment from 'moment';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import GeoMap from 'components/GeoMap/GeoMap';
import SelectOnMapButton from 'components/GeoMap/SelectOnMapButton';

function ProjectEdit({
  currentProject,
  getProjectAction,
  createProjectAction,
  updateProjectAction,
  setUnsavedFormDataAction,
  unsavedFormData,
  statesList,
  getStatesListAction,
  setTopPositionAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const containerRef = useRef(null);
  const breadcrumbs = useRef([{ name: 'Create Project' }]);
  const savePressed = useRef(false);

  const [mapPreData, setMapPreData] = useState({});

  const [openMap, setOpenMap] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const methods = useForm({
    defaultValues: {
      active: true,
      onSiteReport: false,
      alternateEmail: '',
      alternatePhone: '',
      alternatePoc: '',
      contractDate: null,
      contractNumber: '',
      customer: '',
      customerEmail: '',
      customerPhone: '',
      customerPoc: '',
      demobilizationDate: null,
      description: '',
      jobNumber: '',
      keyPersonnel: '',
      mobilizationDate: null,
      name: '',
      notes: '',
      operationalDateFrom: null,
      operationalDateTo: null,
      resources: [],
      vendors: '',
      address1: '',
      address2: '',
      city: '',
      state: null,
      zip: '',
      latitude: null,
      longitude: null
    },
    mode: 'onChange'
  });
  const { getValues, setValue, formState, reset, setError, watch } = methods;
  const { isDirty, errors, isValid } = formState;

  useEffect(() => {
    if (id) {
      getProjectAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentProject?.id) {
      const { id, name } = currentProject;
      breadcrumbs.current = [
        { path: `${PROJECT_DETAILED_PATH}/${id}`, name },
        { name: 'Edit project' }
      ];
      reset(currentProject);
    }
  }, [currentProject]);

  useEffect(() => {
    getStatesListAction();
  }, []);

  const validatedFields = {
    name: useRef(null),
    jobNumber: useRef(null),
    customerEmail: useRef(null),
    alternateEmail: useRef(null)
  };

  const isFormInvalid = () => Object.values(errors).filter(Boolean).length;

  useEffect(() => {
    if (!isValid && savePressed.current) {
      const firstInvalidElem = validatedFields[Object.keys(errors).find((key) => errors[key])];

      if (firstInvalidElem) {
        const elemRectData = firstInvalidElem.current?.getBoundingClientRect();
        setTopPositionAction(elemRectData?.y);
      }

      savePressed.current = false;
    }
  }, [formState]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const errorProperties = [
    { name: 'name', inputType: 'text', errorMessage: 'Project Name field is required' },
    { name: 'jobNumber', inputType: 'text', errorMessage: 'Project Number field is required' },
    { name: 'customerEmail', inputType: 'email', errorMessage: 'Invalid email' },
    { name: 'alternateEmail', inputType: 'email', errorMessage: 'Invalid email' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues()[name], inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleErrors = (err) => {
    if (!err?.errors) return;

    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    containerRef.current.scrollIntoView();
  };

  const handleProjectAction = (actionFunction) => {
    if (checkMandatoryParametersForGettingCoordinates(getValues())) {
      getCoordinatesByApi(getValues()).then((locations) => {
        if (locations.length) {
          const [latitude, longitude] = extractCoordinates(locations);
          actionFunction({ ...getValues(), latitude, longitude }).then(handleErrors);
        } else {
          const [lat, lon] = handleEmptyCoordinates(getValues('latitude'), getValues('longitude'));
          actionFunction({ ...getValues(), latitude: lat, longitude: lon }).then(handleErrors);
        }
      });
    } else {
      const [lat, lon] = handleEmptyCoordinates(getValues('latitude'), getValues('longitude'));
      actionFunction({ ...getValues(), latitude: lat, longitude: lon }).then(handleErrors);
    }
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case NEW_PROJECT_TYPE:
        closeModal();
        handleProjectAction(createProjectAction);
        break;
      case UPDATE_PROJECT_TYPE:
        closeModal();
        handleProjectAction(updateProjectAction);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(dialogModalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const getBack = () =>
    id ? history.push(`${PROJECT_DETAILED_PATH}/${id}`) : history.push(PROJECT_LIST_PATH);

  const closeModal = () => setDialogModalData({ isOpened: false });

  const onCreate = () => {
    if (!validateForm()) {
      savePressed.current = true;
      return;
    }

    if (id) {
      setDialogModalData({ ...UPDATE_PROJECT, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_PROJECT, isOpened: true });
    }
  };

  const onCancel = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const operationalDateFromWatcher = watch('operationalDateFrom');
  const operationalDateToWatcher = watch('operationalDateTo');

  const selectState = (name, value) => setValue(name, value, { shouldDirty: true });

  const handleOpenMap = () => {
    const lat = getValues('latitude');
    const lng = getValues('longitude');
    setMapPreData({ lat, lng });
    setOpenMap(true);
  };

  const onPickData = (data) => {
    reset({
      ...getValues(),
      ...getAddressByGeoCoding(data, statesList, { coordinatesOnly: false })
    });
  };

  return (
    <section className={styles.pageContainer} ref={containerRef}>
      {openMap && (
        <GeoMap open={openMap} setOpen={setOpenMap} mapPreData={mapPreData} onSelect={onPickData} />
      )}
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={breadcrumbs.current}
          setDialogModalData={setDialogModalData}
          formIsChanged={isDirty}
        />
      )}

      <div className={styles.header}>
        <h1>{id ? 'Edit project' : 'Create project'}</h1>
        {isMobile && <BackButton onCancel={onCancel} />}
      </div>
      <FormProvider {...methods}>
        <label className={styles.generalLabel}>General Details</label>
        <div className={styles.detailsForm}>
          <div className={styles.formBlock}>
            <section className={styles.formRow} ref={validatedFields.name}>
              <label>Project Name*</label>
              <FormInputText name="name" options={{ max: 255, rule: allKeyboardLatSymbols }} />
            </section>
            <section className={styles.formRow} ref={validatedFields.jobNumber}>
              <label>Project Number*</label>
              <FormInputText name="jobNumber" options={{ max: 255 }} />
            </section>
            <section className={styles.formRow}>
              <label>Contract №</label>
              <FormInputText
                name="contractNumber"
                options={{ max: 127, rule: /[^A-Za-z0-9\-]/g }}
              />
            </section>
            <section className={styles.formRow}>
              <label>Contract Date</label>
              <FormDatePicker name="contractDate" />
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={styles.formRow}>
              <label>Description</label>
              <FormInputText
                name="description"
                options={{ max: 511, rule: allKeyboardLatSymbols }}
              />
            </section>
            <section className={styles.formRow}>
              <label>Mobilization Date</label>
              <FormDatePicker name="mobilizationDate" />
            </section>
            <section className={styles.formRow}>
              <label>Operational Dates</label>
              <div className={styles.datesWrapper}>
                <div className={styles.dateBlock}>
                  {isMobile && <label>From</label>}
                  <FormDatePicker
                    small
                    name="operationalDateFrom"
                    min={moment().add(-20, 'year')}
                    max={operationalDateToWatcher || moment().add(10, 'year')}
                  />
                </div>
                <div className={styles.dateBlock}>
                  {isMobile && <label>To</label>}
                  <FormDatePicker
                    small
                    name="operationalDateTo"
                    min={operationalDateFromWatcher || moment().add(-20, 'year')}
                    max={moment().add(10, 'year')}
                  />
                </div>
              </div>
            </section>
            <section className={styles.formRow}>
              <label>Demobilization Date</label>
              <FormDatePicker name="demobilizationDate" />
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={styles.checkboxRow}>
              <label>Is active?</label>
              <div className={clsx(styles.checkboxWrapper, styles.larger)}>
                <FormCheckbox name="active" />
              </div>
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={styles.checkboxRow}>
              <label>Display on daily site report form</label>
              <div className={clsx(styles.checkboxWrapper, styles.smaller)}>
                <FormCheckbox name="onSiteReport" />
              </div>
            </section>
          </div>
        </div>
        <div className={styles.detailsForm}>
          <div className={styles.formBlock}>
            <section className={styles.formRow}>
              <label>Address</label>
              <FormInputText name="address1" options={{ max: 255 }} />
            </section>
            <section className={styles.formRow}>
              <label></label>
              <FormInputText name="address2" options={{ max: 255 }} />
            </section>
            <section className={styles.formRow}>
              <label>City</label>
              <FormInputText name="city" options={{ max: 40 }} />
            </section>
            <section className={styles.formRow}>
              <label>State</label>
              <FormSearchInput name="state" options={statesList || []} onSelect={selectState} />
            </section>
            <section className={styles.formRow}>
              <label>Zip</label>
              <FormInputText name="zip" options={{ max: 18, rule: /[^0-9_ ]/g }} />
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={styles.formRow}>
              <label>Latitude</label>
              <FormInputText name="latitude" options={{ max: 18, rule: /[^0-9.\\-]/g }} />
            </section>
            <section className={styles.formRow}>
              <label>Longitude</label>
              <FormInputText name="longitude" options={{ max: 18, rule: /[^0-9.\\-]/g }} />
            </section>
            <section className={clsx(styles.formRow, styles.mapRow)}>
              <label></label>
              <SelectOnMapButton onClick={handleOpenMap} />
            </section>
          </div>
        </div>
        <label className={styles.generalLabel}>Notes</label>
        <div className={styles.simpleBlock}>
          <FormTextArea name="notes" options={{ max: 65000, rule: allKeyboardLatSymbols }} />
        </div>
        <div className={styles.detailsForm}>
          <div className={styles.formBlock}>
            <section className={styles.formRow}>
              <label>Customer</label>
              <FormInputText name="customer" options={{ max: 127, rule: allKeyboardLatSymbols }} />
            </section>
            <section className={styles.formRow}>
              <label>POC</label>
              <FormInputText
                name="customerPoc"
                options={{ max: 127, rule: allKeyboardLatSymbols }}
              />
            </section>
            <section className={styles.formRow}>
              <label>Phone</label>
              <FormInputText name="customerPhone" options={{ max: 20, rule: /[^0-9\-]/g }} />
            </section>
            <section className={styles.formRow} ref={validatedFields.customerEmail}>
              <label>Email</label>
              <FormInputText
                name="customerEmail"
                options={{ max: 255, rule: allKeyboardLatSymbolsWithoutSpaces }}
              />
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={styles.formRow}>
              <label>Alternate POC</label>
              <FormInputText
                name="alternatePoc"
                options={{ max: 127, rule: allKeyboardLatSymbols }}
              />
            </section>
            <section className={styles.formRow}>
              <label>Phone</label>
              <FormInputText name="alternatePhone" options={{ max: 20, rule: /[^0-9\-]/g }} />
            </section>
            <section className={styles.formRow} ref={validatedFields.alternateEmail}>
              <label>Email</label>
              <FormInputText
                name="alternateEmail"
                options={{ max: 255, rule: allKeyboardLatSymbolsWithoutSpaces }}
              />
            </section>
          </div>
        </div>
        <label className={styles.generalLabel}>Key Personnel</label>
        <div className={styles.simpleBlock}>
          <FormInputText
            name="keyPersonnel"
            options={{ max: 511, rule: allKeyboardLatSymbolsWithoutSpaces, full: true }}
          />
        </div>
        <label className={styles.generalLabel}>Vendors</label>
        <div className={styles.simpleBlock}>
          <FormInputText
            name="vendors"
            options={{ max: 511, rule: allKeyboardLatSymbols, full: true }}
          />
        </div>
        <label className={styles.generalLabel}>Files</label>
        <div className={styles.detailsForm}>
          <div className={styles.formBlock}>
            <section className={clsx(styles.formRow, styles.alignTop)}>
              <label>{isMobile ? 'Photo' : 'Upload Photo'}</label>
              <FormImagesUpload name="resources" format="createEntity" />
            </section>
          </div>
          <div className={styles.formBlock}>
            <section className={clsx(styles.formRow, styles.alignTop)}>
              <label>{isMobile ? 'Files' : 'Upload File'}</label>
              <FormFilesUpload name="resources" />
            </section>
          </div>
        </div>
      </FormProvider>
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={onCreate}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentProject: selectSingleProjectData(),
  unsavedFormData: selectUnsavedFormData(),
  statesList: selectProjectStatesData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getProjectAction: getSingleProject,
  createProjectAction: createProject,
  updateProjectAction: updateProject,
  getStatesListAction: getProjectStatesList,
  setTopPositionAction: setTopPosition
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProjectEdit);
