import api from '../api';
import {
  SET_ACTIVE_PICKLIST_ADD_BOM,
  CLEAR_STATE_ADD_BOM,
  SET_CONFIGURATION_LIST_ADD_BOM,
  SET_CONFIGURATION_DETAILS_ADD_BOM
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST } from 'constants/infoSnackbarData';

export const getConfigurations = (q) => (dispatch) => {
  api.addBom
    .getConfigurationsApi(q)
    .then((res) => dispatch({ type: SET_CONFIGURATION_LIST_ADD_BOM, payload: res || [] }))
    .catch((err) => console.log(err));
};

export const getConfigurationDetails = (q) => (dispatch) => {
  return api.addBom
    .getConfigurationDetailsApi(q)
    .then((res) => {
      dispatch({ type: SET_CONFIGURATION_DETAILS_ADD_BOM, payload: res || {} });
      return res;
    })
    .catch((err) => console.log(err));
};

export const addLooseItemsToPicklist = (data) => () => {
  return api.addBom.addLooseItemsToPicklistApi(data).then(
    (res) => {
      if ([200, 201].includes(res.status)) {
        enqueueSuccessSnackbar(
          SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST + ' ' + data?.picklist?.name || ''
        );
      }
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const setActivePicklist = (payload) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_PICKLIST_ADD_BOM, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_STATE_ADD_BOM });
};
