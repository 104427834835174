import React from 'react';
import styles from '../Specifications.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../../selectors';
import SkillChip from '../SkillChip';
import { formatDate } from 'helpers/AppHelpers';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function Licenses() {
  const isMobile = useMobileViewport();
  const { licenses } = useSelector(selectCurrentTeamMemberData());

  return (
    <div className={styles.specification}>
      <label>Trainings & Certifications:</label>
      <div className={styles.specification__licenses}>
        {!!licenses.length &&
          licenses.map((item) => (
            <div key={item.id} className={styles.specification__licenses_row}>
              <SkillChip label={item.name || ''} />
              <div className={styles.date}>
                {!isMobile && <label>Expiration Date</label>}
                {item?.expirationDate && <span>{formatDate(item?.expirationDate)}</span>}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
