import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentTeamMemberData } from '../../../selectors';
import styles from './Details.module.scss';
import Subtitle from 'components/DetailsComponents/Subtitle';
import Info from '../Info';

export default function ProfessionalDetails() {
  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const { status } = currentTeamMember;

  const details = [[{ id: 1, label: 'Employment', value: status[0]?.name }]];

  return (
    <section className={styles.wrapper}>
      <Subtitle>Professional Details</Subtitle>
      <div className={styles.general}>
        {details.map((block, index) => (
          <div className={styles.general__block} key={index}>
            {block.map(({ id, label, value }) => (
              <Info key={id} label={label} value={value} />
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
