import {
  CLEAR_NOTIFICATION_CENTER_STATE,
  SET_NOTIFICATION_CENTER_TAB
} from 'constants/reduceConstants';

const initialState = {
  selectedTab: ''
};

const pickListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATION_CENTER_TAB: {
      return { ...state, selectedTab: payload };
    }
    case CLEAR_NOTIFICATION_CENTER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default pickListReducer;
