import React from 'react';
import styles from './SortableBlock.module.scss';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

function SortableBlock({ label, parameter, sortRules, onSortApply }) {
  const isSorted = () => sortRules === parameter;
  const isAscend = () => sortRules.indexOf('-') >= 0 && sortRules.replace('-', '') === parameter;

  const handleSortClick = () => onSortApply(getSortRule(parameter));

  const getSortRule = (name) =>
    sortRules === name ? (sortRules.indexOf('-') >= 0 ? name : `-${name}`) : name;

  return (
    <div
      className={clsx(styles.sortHeader, isSorted() && styles.sorted, isAscend() && styles.ascend)}
      onClick={handleSortClick}>
      {label}
      <ExpandMore />
    </div>
  );
}

export default SortableBlock;
