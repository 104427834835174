import React, { useEffect, useRef, useState } from 'react';
import styles from './ProjectScheduleFilter.module.scss';

import { Backdrop, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import FilterIcon from 'assets/images/filter.svg';

import clsx from 'clsx';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useFormContext } from 'react-hook-form';

import { isEqual } from 'helpers/AppHelpers';

export default function ScheduleFilterWrapper({ open, setOpen, children }) {
  const iconRef = useRef();
  const [isFilterApplied, setFilterApplied] = useState(false);

  const { getValues } = useFormContext();

  const { getFilterCriteriaAction } = useTrackerActions();
  const { filter, selectedTab, isMasterSelected } = useTrackerSelector();

  const handleIconClick = () => {
    setOpen(!open);
    if (!isEqual(filter.filters.teamMemberDepartmentIds, getValues('teamMemberDepartmentIds'))) {
      getFilterCriteriaAction({
        isMaster: isMasterSelected,
        departmentIds: filter.filters.teamMemberDepartmentIds
      });
    }
  };

  useEffect(() => {
    if (open) {
      setFilterApplied(true);
    } else {
      const check = (el) => (el === null ? Boolean(el) : !!el?.length);
      setFilterApplied(!!Object?.values(filter.filters)?.filter(check)?.length);
    }
  }, [open, selectedTab, filter]);

  return (
    <div className={styles.filter}>
      {open && <Backdrop open={open} />}
      <IconButton
        className={clsx(styles.filter__button, isFilterApplied && styles.filter__button_opened)}
        onClick={handleIconClick}
        component="span"
        ref={iconRef}>
        <img src={FilterIcon} alt="" />
      </IconButton>

      <Popper open={open} anchorEl={() => iconRef.current} placement="right-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.container }}>{children}</Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
