import React, { useEffect, useState } from 'react';
import styles from './TicketComponents.module.scss';

import {
  FormDayPicker,
  FormLabel,
  FormRow,
  FormSearchInput,
  FormSelectInput,
  FormServerUserSearch
} from 'components/FormComponents';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import DetailedRow from 'components/DetailsComponents/DetailedRow';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';

import { useParams } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';
import { useFormContext } from 'react-hook-form';
import { useTicketActions, useTicketSelector } from 'hooks/Ticket';

import { dateTimeFormat, enrichNameToId, getDate, getUserFullName } from 'helpers/AppHelpers';

import Subhead from './Subhead';

export function General() {
  const { id } = useParams();
  const { watch, setValue, getValues, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const { isBasicRoleUser, isPersonnelManagerUser } = useUserConfig();

  const { creationForm } = useTicketSelector();
  const { ticketTypes, rootCauses, severities, reopenTicketFrequencies } = creationForm;
  const { getCreationFromAction } = useTicketActions();

  const [severityOptions, setSeverityOptions] = useState([]);

  useEffect(() => {
    if (!severities?.length) return;
    if (!ticketTypes?.length) return;

    const typeId = getValues('ticketType')?.id;
    const selectedTypeSeverities = ticketTypes.find(({ id }) => id === typeId)?.severities || [];
    setSeverityOptions(selectedTypeSeverities?.length ? selectedTypeSeverities : severities);
  }, [severities, ticketTypes]);

  const assetWatcher = watch('asset');
  const assigneeUserWatcher = watch('assignedToUser');
  const assigneeDateWatcher = watch('assignedAtUtc');
  const frequencyWatcher = watch('reopenTicketFrequency');
  const ticketTypeWatcher = watch('ticketType');

  const conditions = {
    detailedBlock: !!id,
    assetField: !id,
    isOpened: !id,
    assigneeBlock: !isBasicRoleUser && !isPersonnelManagerUser,
    notificationReminder: !!assigneeUserWatcher?.id && !!assigneeDateWatcher,
    reopenSettings: !isBasicRoleUser && !isPersonnelManagerUser && !!ticketTypeWatcher?.reopening
  };

  const checkFieldError = (name) => errors[name]?.message && clearErrors(name);

  const selectAsset = (name, value) => {
    checkFieldError(name);
    setValue(name, value);
    value?.id && getCreationFromAction({ assetId: value.id });
  };

  const resetTicketFrequencyValue = () => {
    setValue('reopenTicketFrequency', null);
    setValue('reopenOnSpecificDate', null);
  };

  const selectOption = (name, value) => setValue(name, value);
  const selectFrequency = ({ name, value }) => {
    setValue('reopenOnSpecificDate', null);
    setValue(name, value);
  };

  const selectSeverity = ({ name, value }) => {
    setValue(name, value);
    checkFieldError(name);
  };

  const selectType = (name, value) => {
    setValue(name, value);

    selectSeverity({
      name: 'severity',
      value:
        value?.severities?.length === 1
          ? { id: value.severities[0].name, ...value.severities[0] }
          : {}
    });
    setSeverityOptions(value?.severities?.length ? value.severities : severities);
    resetTicketFrequencyValue();

    setValue('description', value?.description || '');
  };

  return (
    <div className={styles.section}>
      <Subhead>General Details</Subhead>
      {conditions.detailedBlock && (
        <div className={styles.container}>
          <div className={styles.container__block}>
            <DetailedRow label="Asset" value={getValues('asset')?.drCode} />
            <DetailedRow label="Opened By" value={getUserFullName(getValues('openedByUser'))} />
            <DetailedRow
              label="Opened On"
              value={getDate(getValues('openedAtUtc'), dateTimeFormat)}
            />
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.container__block}>
          {conditions.assetField && (
            <FormRow>
              <FormLabel required>Asset</FormLabel>
              <FormServerAssetSearch
                name="asset"
                max={100}
                onSelect={selectAsset}
                getLabel={(option) => option?.drCode || ''}
                isDisabled={!!id}
                getQuery={(searchQuery) => ({
                  pagination: { limit: 39, page: 1 },
                  filters: { isDeleted: false, searchQuery }
                })}
              />
            </FormRow>
          )}
          <FormRow>
            <FormLabel>Ticket Type</FormLabel>
            <FormSearchInput
              clearable
              name="ticketType"
              options={ticketTypes || []}
              onSelect={selectType}
              isDisabled={!assetWatcher?.id}
            />
          </FormRow>
          <FormRow>
            <FormLabel>Root Cause</FormLabel>
            <FormSearchInput
              clearable
              name="rootCause"
              options={rootCauses || []}
              onSelect={selectOption}
              isDisabled={!assetWatcher?.id}
            />
          </FormRow>
          <FormRow>
            <FormLabel required>Severity</FormLabel>
            <FormSelectInput
              name="severity"
              options={enrichNameToId(severityOptions) || []}
              onSelect={selectSeverity}
            />
          </FormRow>
          {conditions.reopenSettings && (
            <>
              <FormRow>
                <FormLabel>Reopen Frequency</FormLabel>
                <FormSelectInput
                  clearable
                  name="reopenTicketFrequency"
                  options={reopenTicketFrequencies || []}
                  onSelect={selectFrequency}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Reopen on specific date</FormLabel>
                <FormDayPicker
                  name="reopenOnSpecificDate"
                  min={new Date()}
                  disabled={frequencyWatcher?.name !== 'OneTime'}
                />
              </FormRow>
            </>
          )}
        </div>

        <div className={styles.container__block}>
          {conditions.isOpened && (
            <FormRow type="checkbox">
              <FormLabel>{`Ticket's Status Open`}</FormLabel>
              <FormCheckbox name="isOpened" />
            </FormRow>
          )}
          {conditions.assigneeBlock && (
            <>
              <FormRow>
                <FormLabel>Assignee</FormLabel>
                <FormServerUserSearch
                  name="assignedToUser"
                  getLabel={(option) => getUserFullName(option)}
                  getQuery={(searchQuery) => ({
                    pagination: { limit: 39, page: 1 },
                    filters: { shouldIncludeCurrentUser: true, searchQuery }
                  })}
                  onSelect={selectOption}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Due Date</FormLabel>
                <FormDayPicker clearable name="assignedAtUtc" min={new Date()} />
              </FormRow>
            </>
          )}
          {conditions.assigneeBlock && conditions.notificationReminder && (
            <FormRow type="checkbox">
              <FormLabel>Notification Reminder</FormLabel>
              <FormCheckbox name="notificationReminder" />
            </FormRow>
          )}
        </div>
      </div>
    </div>
  );
}
