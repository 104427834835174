import { useDispatch } from 'react-redux';

import {
  setEntityData,
  getLocationLooseItems,
  clearState,
  checkLooseItemsLocation,
  addLooseItems,
  editLooseItem
} from 'actions/manageLooseItemsActions';

export function useManageLooseItemsActions() {
  const dispatch = useDispatch();

  const getLocationLooseItemsAction = (q) => dispatch(getLocationLooseItems(q));

  const checkLooseItemsLocationAction = (q) => dispatch(checkLooseItemsLocation(q));

  const addLooseItemsAction = (data) => dispatch(addLooseItems(data));
  const editLooseItemAction = (data) => dispatch(editLooseItem(data));

  const setEntityDataAction = (data) => dispatch(setEntityData(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    setEntityDataAction,
    getLocationLooseItemsAction,
    checkLooseItemsLocationAction,
    addLooseItemsAction,
    editLooseItemAction,
    clearStateAction
  };
}
