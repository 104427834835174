import React from 'react';
import styles from './FilterHeader.module.scss';

import BackButton from 'components/BackButton/BackButton';

import { useMobileViewport } from 'hooks/useMobileViewport';

export default function FilterHeader({ title, onBack }) {
  const isMobile = useMobileViewport();

  return (
    <section className={styles.header}>
      <h2>{title || 'Filters'}</h2>
      {isMobile && (
        <div className={styles.header__controls}>
          <BackButton onCancel={onBack} />
        </div>
      )}
    </section>
  );
}
