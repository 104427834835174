import React from 'react';

import ParameterEditPopup from '../ParameterEditPopup/ParameterEditPopup';

import { DELETE_LOOSE_ITEM_CATEGORY } from 'constants/dialogPopupsData';

import { useUserConfig } from 'hooks/useUserConfig';
import { useDispatch } from 'react-redux';

import { deleteLooseItemCategory, updateLooseItemCategory } from 'actions/looseItemCategoryActions';

export default function EditLooseItemCategoryPopup({ open, setOpen, data, onUpdate }) {
  const dispatch = useDispatch();
  const { isAdminUser } = useUserConfig();

  const handleSaveParameter = (param) => {
    dispatch(updateLooseItemCategory(param)).then((res) => {
      if (res?.status === 200) {
        onUpdate({ action: 'UPDATE', category: res.data });
        setOpen(false);
      }
    });
  };
  const handleDeleteParameter = (param) => {
    dispatch(deleteLooseItemCategory(param.id)).then((res) => {
      if (res?.status === 200) {
        onUpdate({ action: 'REMOVE', category: { id: param.id } });
        setOpen(false);
      }
    });
  };

  const popupOptions = {
    title: 'Edit Category',
    field: {
      label: 'Category',
      maxLength: 100,
      errorMessage: 'Category field is required'
    },
    buttons: {
      onUpdate: handleSaveParameter,
      onDelete: handleDeleteParameter,
      isDeleteAvailable: isAdminUser
    },
    modalData: {
      delete: DELETE_LOOSE_ITEM_CATEGORY
    }
  };

  return (
    open && <ParameterEditPopup open={open} setOpen={setOpen} data={data} options={popupOptions} />
  );
}
