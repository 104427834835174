import { enqueueErrorSnackbar } from 'helpers/AppHelpers';
import api from '../api';
import {
  SET_ACTIVE_LOCATIONS_MAP_FILTER_CRITERIA,
  SET_ACTIVE_LOCATIONS_MAP_FILTER,
  CLEAR_ACTIVE_LOCATIONS_MAP_STATE
} from 'constants/reduceConstants';
import { NO_LOCATIONS_ARE_FOUND } from 'constants/infoSnackbarData';

export const getLocations = (q) => () =>
  api.activeLocationsMap
    .getLocationsApi(q)
    .then((res) => {
      if (Array.isArray(res?.items) && !res?.items?.length) {
        enqueueErrorSnackbar(NO_LOCATIONS_ARE_FOUND);
      }
      return res?.items || [];
    })
    .catch((err) => console.log(err));

export const getLocationAssets = (q) => () =>
  api.activeLocationsMap
    .getLocationAssetsApi(q)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const getLocationAssetsFields = (q) => () =>
  api.activeLocationsMap
    .getLocationAssetsFieldsApi(q)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const getLocationLooseItems = (q) => () =>
  api.activeLocationsMap
    .getLocationLooseItemsApi(q)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    });

export const getFilterCriteria = () => (dispatch) =>
  api.activeLocationsMap
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_ACTIVE_LOCATIONS_MAP_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_LOCATIONS_MAP_FILTER, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_LOCATIONS_MAP_STATE });
};
