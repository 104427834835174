import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from '@material-ui/core';
import { BASE_ADDRESS } from 'api/api';
import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';
import styles from './MapComponent.module.scss';
import blueMarkerIcon from 'assets/images/markers/bluemarker.svg';
import redMarkerIcon from 'assets/images/markers/redmarker.svg';
import { generateMapMarker } from './helpers';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const blueMarker = generateMapMarker(blueMarkerIcon);
const redMarker = generateMapMarker(redMarkerIcon);

const useStyles = makeStyles(() => ({
  popupStyle: {
    opacity: 1,
    '& .leaflet-popup-content-wrapper': {
      borderRadius: '8px',
      width: '220px',
      minHeight: '160px'
    },
    '& .leaflet-popup-content': {
      display: 'flex',
      flexDirection: 'column',
      margin: '15px'
    },
    '& .leaflet-popup-close-button': {
      color: '#000 !important',
      margin: '15px 15px 0 0',
      padding: '0 !important',
      fontSize: '25px !important'
    }
  }
}));

export default function MapMarker({ el }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Marker
      position={[el.latitude, el.longitude]}
      icon={el.siteType.name === 'Job Site' ? redMarker : blueMarker}>
      <Popup className={classes.popupStyle}>
        <Link
          href={`${BASE_ADDRESS}${LOCATION_DETAILED_PATH}/${el.id}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`${LOCATION_DETAILED_PATH}/${el.id}`);
          }}
          className={styles.popup__title}>
          {el.siteCode}
        </Link>
        <span className={`${styles.popup__content} ${styles.subtitle}`}>{el.siteType.name}</span>
        <span className={`${styles.popup__content} ${styles.jobNumber}`}>
          <b>Job Number:</b> {el?.locationJobNumber}
        </span>
        <span className={`${styles.popup__content} ${styles.info}`}>
          {el.assetsCount || 0} items at this location
        </span>
        <span className={`${styles.popup__content} ${styles.directions}`}>
          Directions:{'\u2000'}
          <span
            onClick={() =>
              window.open(
                `https://www.google.com/maps/place/${el.latitude},${el.longitude}/@${el.latitude},${el.longitude},13z`,
                '_blank'
              )
            }>
            To here
          </span>
        </span>
      </Popup>
    </Marker>
  );
}
