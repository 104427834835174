import React from 'react';
import styles from './Details.module.scss';

import { useUserSelector } from 'hooks/UserManagement';

import DetailedRow from 'components/DetailsComponents/DetailedRow';

import { joinWithComma, pluck } from 'helpers/AppHelpers';

export default function RequestOrderInfo() {
  const { currentUser } = useUserSelector();
  const {
    isSelectedAllRequestOrderLocations,
    notificationRequestOrderLocations = [],
    requestOrderRole
  } = currentUser;

  return (
    <div className={styles.details}>
      <DetailedRow label="Request order role" value={requestOrderRole?.displayName || '-'} />

      <DetailedRow
        label="Notify by Location"
        value={
          isSelectedAllRequestOrderLocations
            ? 'All Selected'
            : joinWithComma(pluck(notificationRequestOrderLocations, 'siteCode'))
        }
        disableFlex
      />
    </div>
  );
}
