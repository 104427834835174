import React, { useEffect } from 'react';
import styles from './InspectionStatistic.module.scss';

import Separator from 'components/Separator/Separator';
import { TransparentButton } from 'components/Buttons';

import DashboardWrapper from '../DashboardWrapper';
import SectionHeader from '../SectionHeader';
import AssetsDashboardsFilter from '../Filter';

import InspectionStatisticTable from './Table';
import InspectionStatisticCharts from './Charts';

import { useAssetsDashboardsActions, useAssetsDashboardsSelector } from 'hooks/AssetsDashboards';

import { fileDownload } from 'helpers/AppHelpers';

import csvIcon from 'assets/images/csvIcon.svg';

export default function InspectionStatistic() {
  const { inspectionStatistic, tabConfig, tab } = useAssetsDashboardsSelector();
  const { getInspectionStatisticAction, getCsvResourceAction, clearTabStateAction } =
    useAssetsDashboardsActions();

  useEffect(() => {
    return () => {
      clearTabStateAction({ stateName: 'inspectionStatistic' });
    };
  }, []);

  const getFilterData = (sectionId) => tabConfig?.items?.find(({ id }) => id === sectionId) || {};

  const applyFilter = () => getInspectionStatisticAction();

  const downloadCsv = (dashboardId) =>
    getCsvResourceAction({ dashboardId, dashboardType: tab }).then((res) => fileDownload(res));

  return (
    <DashboardWrapper>
      {!!inspectionStatistic?.length && (
        <div className={styles.statistics}>
          {inspectionStatistic.map((sectionData, index) => (
            <div key={sectionData.id} className={styles.statistics__dashboard}>
              <SectionHeader title={sectionData.name || ''} />
              <AssetsDashboardsFilter
                filter={getFilterData(sectionData.id)}
                onApply={applyFilter}
              />
              <TransparentButton
                label="Download CSV File"
                className={styles.csvButton}
                onClick={() => downloadCsv(sectionData.id)}>
                <img src={csvIcon} alt="" />
              </TransparentButton>
              <div className={styles.statistics__dashboard_info}>
                <InspectionStatisticTable data={sectionData} />
                <InspectionStatisticCharts data={sectionData} />
              </div>
              {inspectionStatistic.length > 1 && index !== inspectionStatistic.length - 1 && (
                <Separator />
              )}
            </div>
          ))}
        </div>
      )}
    </DashboardWrapper>
  );
}
