import React from 'react';
import styles from './VerificationStatisticCharts.module.scss';

import SimplePieChart from 'components/Charts/SimplePieChart/SimplePieChart';

import { getColumnName, verificationColors, dashboardLabels } from '../../../helpers';

export default function VerificationStatisticCharts({ data }) {
  const { durations, name } = data;

  const charts = durations.map(({ dateFrom, dateTo, assetVerified, assetNotVerified, total }) => ({
    title: getColumnName(dateFrom, dateTo),
    series: [assetVerified, assetNotVerified],
    labels: verificationColors.map(({ status }) => status),
    isDisplayed: total !== 0
  }));

  return (
    <div className={styles.charts}>
      {charts.map(({ title, series, labels, isDisplayed }) =>
        isDisplayed ? (
          <div key={title} className={styles.charts__block}>
            <SimplePieChart
              hideToolbar
              title={title}
              series={series}
              labels={labels}
              colors={verificationColors.map(({ color }) => color)}
              fileName={dashboardLabels.verification + ' ' + name}
            />
          </div>
        ) : null
      )}
    </div>
  );
}
