import {
  ASSET_REPORT_LIST_PATH,
  GENERAL_REPORT_LIST_PATH,
  LOCATION_REPORT_LIST_PATH
} from 'constants/routeConstants';
import { formatDate } from 'helpers/AppHelpers';

export const MESSAGE_TYPES = Object.freeze({
  DELETE: 'ReportDelete',
  SUBMIT: 'ReportSubmit',
  UPDATE: 'ReportUpdate',
  CONNECT: 'ReportConnect'
});

export const getRootListPath = (reportType) => {
  switch (reportType) {
    case 'location':
      return LOCATION_REPORT_LIST_PATH;
    case 'general':
      return GENERAL_REPORT_LIST_PATH;
    case 'asset':
      return ASSET_REPORT_LIST_PATH;
  }
};

export const validateTextField = (data, fieldPath, setFieldError) => {
  if (!data.value) {
    setFieldError(`${fieldPath}.value`, 'Field is required');
  }
};

export const validateChoiceField = (data, fieldPath, setFieldError) => {
  if (data.options.every(({ selected }) => !selected)) {
    setFieldError(`${fieldPath}.options`, 'At least one option must be chosen');
  }
};

export const validateTableField = (data, fieldPath, setFieldError) => {
  data.rows.forEach(({ columns }, rIndex) => {
    columns.forEach((col, cIndex) => {
      if (!col.value) {
        setFieldError(`${fieldPath}.rows[${rIndex}].columns[${cIndex}].value`, 'Field is required');
      }
    });
  });
};

export const validateSignatureField = (data, fieldPath, setFieldError) => {
  data.textFields.forEach(({ value }, fIndex) => {
    if (!value) {
      setFieldError(`${fieldPath}.textFields[${fIndex}].value`, 'Field is required');
    }
  });
  if (!data?.resource?.id) {
    setFieldError(`${fieldPath}.resource`, 'Signature is required');
  }
};

export const validateLocationTemplateField = (data, fieldPath, setFieldError) => {
  data.templates.forEach((template, tIndex) => {
    if (template.type === 'Subtitle' || template.type === 'Title') return;
    if (!template.value) {
      setFieldError(`${fieldPath}.templates[${tIndex}].value`, 'Field is required');
    }
  });
};

export const validateAssetTemplateField = (data, fieldPath, setFieldError) => {
  data.templates.forEach((template, tIndex) => {
    if (!template.value) {
      setFieldError(`${fieldPath}.templates[${tIndex}].value`, 'Field is required');
    }
  });
};

export const validateQueryParams = (type, params) => {
  const { generalReportTemplateId, locationId, projectId, assetId } = params;

  if (type === 'asset') return assetId && generalReportTemplateId;

  if (type === 'location') return (locationId || projectId) && generalReportTemplateId;

  return !!generalReportTemplateId;
};

export const getCreationFormQueryByParams = (type, params) => {
  const { generalReportTemplateId, locationId, projectId, assetId } = params;

  if (!generalReportTemplateId) return {};

  if (type === 'asset') return { assetId, generalReportTemplateId };

  if (type === 'location') {
    const q = { generalReportTemplateId };
    if (locationId) {
      q.locationId = locationId;
    } else {
      q.projectId = projectId;
    }
    return q;
  }

  return { generalReportTemplateId };
};

export const getCreationFormQueryByReport = (type, report) => {
  const { generalReportTemplate, location, project, asset } = report;

  if (!generalReportTemplate.id) return {};

  if (type === 'asset')
    return { assetId: asset?.id || null, generalReportTemplateId: generalReportTemplate.id };

  if (type === 'location') {
    return {
      generalReportTemplateId: generalReportTemplate.id,
      locationId: location?.id || null,
      projectId: location?.id ? null : project.id
    };
  }

  return { generalReportTemplateId: generalReportTemplate.id };
};

export const getPresetValues = (type, response, getWeather, getValues) => {
  const { asset, generalReportTemplate } = response;
  const reportFields = generalReportTemplate?.generalReportFields.sort((a, b) => a.order - b.order);

  if (type === 'asset')
    return {
      ...getValues(),
      createdAtUtc: formatDate(new Date()),
      reportType: { ...generalReportTemplate?.reportType },
      asset,
      location: { ...asset.location },
      generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
      generalReportFields: [...reportFields]
    };

  if (type === 'location') {
    const values = {
      ...getValues(),
      createdAtUtc: formatDate(new Date()),
      reportType: { ...generalReportTemplate?.reportType },
      generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
      generalReportFields: [...reportFields]
    };

    if (response?.location?.id) {
      values.location = response.location;
      getWeather && getWeather(new Date(), response.location);
    } else {
      values.project = response.project;
      getWeather && getWeather(new Date(), response.project);
    }

    return values;
  }

  return {
    ...getValues(),
    createdAtUtc: formatDate(new Date()),
    reportType: { ...generalReportTemplate?.reportType },
    generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
    generalReportFields: [...reportFields]
  };
};

export const getLastSavedValue = (path, obj) => {
  const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');
  return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
};

export const extractIndexFromSignatureName = (str) => {
  const match = str.match(/\[(\d+)\]/);
  return match ? parseInt(match[1], 10) : null;
};
