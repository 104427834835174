import React from 'react';
import styles from './components.module.scss';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteSectionButton({ onClick }) {
  const handleClick = () => onClick();
  return (
    <Button className={styles.delete_button} onClick={handleClick}>
      <DeleteIcon />
      <label>Delete</label>
    </Button>
  );
}
