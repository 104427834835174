import {
  SET_ASSEMBLE_LIST,
  SET_LOCATION_LIST_FOR_CREATE_ASSEMBLE,
  CLEAR_ASSEMBLE_BOM_STATE,
  SET_CONFIGURATION_LIST_FOR_CREATE_ASSEMBLE,
  SET_ASSEMBLE_CONFIG_OPTIONS,
  SET_LOCATION_LIST_FOR_FILTER,
  SET_STATUS_LIST_FOR_FILTER,
  SET_CONFIGURATION_LIST_FOR_FILTER,
  SET_ASSEMBLE_BOM_FILTER,
  SET_CURRENT_ASSEMBLE
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from '../helpers/AppHelpers';

const initialState = {
  assembleList: [],
  locationList: [],
  looseItemConfigurationList: [],
  configOptions: {},
  locationListFilter: [],
  statusListFilter: [],
  configurationListFilter: [],
  assembleFilter: {
    filters: {
      searchQuery: '',
      locationIds: [],
      locationJobNumberIds: [],
      statusNames: [],
      looseItemConfigurationIds: []
    },
    pagination: {
      page: 1,
      totalPages: 1,
      limit: 10
    },
    sortRules: [],
    responseReceived: false
  },
  currentAssemble: {}
};

const assembleBOMReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ASSEMBLE_LIST:
      return {
        ...state,
        assembleList: payload
      };
    case SET_LOCATION_LIST_FOR_CREATE_ASSEMBLE:
      return {
        ...state,
        locationList: payload
      };
    case SET_CONFIGURATION_LIST_FOR_CREATE_ASSEMBLE:
      return {
        ...state,
        looseItemConfigurationList: payload
      };
    case SET_ASSEMBLE_CONFIG_OPTIONS:
      return {
        ...state,
        configOptions: payload
      };
    case SET_LOCATION_LIST_FOR_FILTER:
      return {
        ...state,
        locationListFilter: payload
      };
    case SET_STATUS_LIST_FOR_FILTER:
      return {
        ...state,
        statusListFilter: payload
      };
    case SET_CONFIGURATION_LIST_FOR_FILTER:
      return {
        ...state,
        configurationListFilter: payload
      };
    case SET_ASSEMBLE_BOM_FILTER:
      return {
        ...state,
        assembleFilter: payload
      };
    case SET_CURRENT_ASSEMBLE:
      return {
        ...state,
        currentAssemble: payload
      };
    case CLEAR_ASSEMBLE_BOM_STATE:
      return { ...initialState, assembleFilter: getClearedFilterByUnmount(state.assembleFilter) };
    default:
      return state;
  }
};

export default assembleBOMReducer;
